import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import MaterialLink from "@mui/material/Link";
import HomeOutlinedIcon
  from "@mui/icons-material/HomeOutlined";
import { styled } from "@mui/system";
import { styles } from "./styles";
import theme from "@/theme";
import { Typography } from "@mui/material";

const StyledBreadCrumbs = styled(Breadcrumbs)(styles);

interface IProps {
  items: IBreadcrumbItem[];
}

export interface IBreadcrumbItem {
  id: string | number,
  label?: string | number
  href?: string | undefined
}

/**
 * Компонент Хлебные крошки
 * @param items - массив объектов, каждый объект:
 * id элемента, label - содержимое для пользователя,
 * href - ссылка
 */
export const BreadcrumbsComponent = ({ items }: IProps) => {
  return (
    <StyledBreadCrumbs aria-label="breadcrumb">
      {items.map(({ id, href = "", label }, index) => (
        href ?
          <MaterialLink
            component={Link}
            key={id}
            sx={{
              color: theme.palette.blackAndWhite.gray,
              marginLeft: "-2px",
            }}
            underline="none"
            to={href}
          >
            {index === 0 ? <HomeOutlinedIcon
              sx={{ height: 17, mb: "-3px" }}/> : label}
          </MaterialLink> : <Typography key={id} sx={{
            color: theme.palette.blackAndWhite.gray,
            marginLeft: "-2px",
          }}>{label}</Typography>
      ))}
    </StyledBreadCrumbs>
  );
};
