import React, { useLayoutEffect } from "react";

import { useStores } from "@/hooks";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";

const ReportsPage: React.FC = () => {
  const { headerStore } = useStores();

  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.REPORTS);
  }, []);

  return <div>ReportsPage</div>;
};

export default ReportsPage;
