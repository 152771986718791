import React from "react";
import { useFormik } from "formik";
import { Box, Button, Divider, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react";
import { IAddCostProps, IFormValues } from "./types";
import { SWR_KEYS } from "@/const";
import { useStores } from "@/hooks";
import { FIELDS_NAME } from "./const";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { AxiosError } from "axios";

const AddRemark: React.FC<IAddCostProps> = observer(({ modalProps }) => {
  const { api, toastStore, modalStore, swrStore } = useStores();

  const mutate = swrStore.mutators[SWR_KEYS.getOffBudgetById(modalProps.id)];

  const initialValues = Object.assign(
    {
      [FIELDS_NAME.EXECUTOR]: null,
      [FIELDS_NAME.REMARKS]: null,
    },
    {
      [FIELDS_NAME.EXECUTOR]: modalProps.executor,
      [FIELDS_NAME.REMARKS]: modalProps.remarks,
    }
  );

  const formik = useFormik<IFormValues>({
    initialValues: initialValues,
    validateOnMount: true,
    onSubmit: (values) => {
      api.offBudget
        .editRemark(modalProps.id, {
          executor: Number(values.executor?.id),
          remarks: values.remarks,
        })
        .then(() => {
          mutate();
          modalStore.pop();
        })
        .catch((error: AxiosError) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        });
    },
  });

  const handleClose = () => {
    modalStore.pop();
  };

  const handleAddRemark = (e) => {
    formik.setFieldValue(FIELDS_NAME.REMARKS, e.target.value);
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Box>
        <Box p={3}>
          <Typography variant="h2">Добавить замечание</Typography>
        </Box>
        <Divider />

        <Box p={3}>
          <TextField
            multiline
            size="medium"
            minRows={5}
            value={formik.values[FIELDS_NAME.REMARKS]}
            onChange={handleAddRemark}
            label="Замечания по плану"
            sx={{
              "& .MuiInputBase-root": {
                width: "750px",
              },
            }}
          />
        </Box>

        <Divider />

        <Box p={3} display="flex" justifyContent="space-between">
          <Button variant="text" color="red" onClick={handleClose}>
            Отмена
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Сохранить
          </Button>
        </Box>
      </Box>
    </form>
  );
});

export default AddRemark;
