import React from "react";
import { useFormikContext } from "formik";
import { Grid, Stack, Typography } from "@mui/material";
import {
  FIELDS_NAME,
  IFormik
} from "@/pages/AddSeedArrival/const";
import {
  FastTextField,
  YearDatePicker
} from "@/components/Fields";
import {
  GerminationIcon,
  SeedsIcon,
  VarietyIcon
} from "@/components/icons";
import EnergySavingsLeafOutlinedIcon
  from "@mui/icons-material/EnergySavingsLeafOutlined";
import {
  CultivarAutocomplete,
  ReproductionAutocomplete,
  VarietyAutocomplete
} from "@/components/Autocompletes";
import { ICultivar } from "@/api/interfaces/responses";
import FastRegexTextField
  from "@/components/FastRegexTextField";

const Information = () => {
  const formik = useFormikContext<IFormik>();

  const handleChangeCultivar = (payload: ICultivar) => {
    void formik.setFieldValue(FIELDS_NAME.CULTIVAR, payload);
    void formik.setFieldValue(FIELDS_NAME.VARIETY, "");
  };

  const handleCultivarClear = () => {
    void formik.setFieldValue(FIELDS_NAME.CULTIVAR, "");
    void formik.setFieldValue(FIELDS_NAME.VARIETY, "");
  };

  return (
    <Stack direction="column" gap="20px">
      <Typography variant="h3">
        Сведения о качестве посевного материала
      </Typography>
      <Grid container spacing="20px">
        <Grid item md={6} xs={12}>
          <CultivarAutocomplete
            name={FIELDS_NAME.CULTIVAR}
            handleChange={handleChangeCultivar}
            onClear={handleCultivarClear}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <VarietyAutocomplete
            required={true}
            name={FIELDS_NAME.VARIETY}
            q={{
              cultivar: formik.values.cultivar?.id ?? undefined
            }}
            disabled={!formik.values.cultivar}
          />
        </Grid>
      </Grid>
      <YearDatePicker
        name={FIELDS_NAME.CROP_YEAR}
        label="Год урожая"
      />
      <Grid container spacing="20px">
        <Grid item md={6} xs={12}>
          <ReproductionAutocomplete name={FIELDS_NAME.REPRODUCTION} />
        </Grid>
        <Grid item md={6} xs={12}>
          <FastRegexTextField
            name={FIELDS_NAME.WEIGHT}
            label="Масса 1000 семян, г"
            regexType="decimal"
          />
        </Grid>
      </Grid>
      <FastRegexTextField
        name={FIELDS_NAME.VARIETAL_PURITY}
        label="Сортовая чистота, %"
        Icon={VarietyIcon}
        regexType="decimal"
      />
      <FastRegexTextField
        name={FIELDS_NAME.ENERGY_GERMINATION}
        label="Энергия прорастания, %"
        Icon={EnergySavingsLeafOutlinedIcon}
        regexType="decimal"
      />
      <FastRegexTextField
        name={FIELDS_NAME.GERMINATION}
        label="Всхожесть, %"
        Icon={GerminationIcon}
        regexType="decimal"
      />
      <FastRegexTextField
        name={FIELDS_NAME.SEED_PURITY}
        label="Чистота семян (сем.осн.культ), %"
        Icon={SeedsIcon}
        regexType="decimal"
      />
      <Grid container spacing="20px">
        <Grid item md={6} xs={12}>
          <FastRegexTextField
            name={FIELDS_NAME.HUMIDITY}
            label="Влажность, %"
            regexType="decimal"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FastTextField
            name={FIELDS_NAME.SEED_CLASS}
            label="Класс семян"
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Information;
