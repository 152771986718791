import { IFilter, IValue, TRangeDateFormatted } from "@/interfaces";
import {
  FiltersType
} from "@/components/Filters/interfaces";
import { Dispatch, SetStateAction } from "react";
import { SEED_ARRIVAL_STATUS_LIST } from "../const";

interface IFilterProps extends IFilter {
  handleScroll?: (
    event: React.UIEvent<HTMLElement, UIEvent>,
  ) => void,
  setValue?: Dispatch<SetStateAction<string>>
}

export const getFilters = (
  dateRange?: TRangeDateFormatted,
  gsuTypeValue?: IValue[],
  selectedGsuTypeValue?: IValue,
  handleScroll?: (
    event: React.UIEvent<HTMLElement, UIEvent>,
  ) => void,
  setValue?: Dispatch<SetStateAction<string>>,
  statusValue?: any,

): IFilterProps[] => {
  return [
    {
      name: "status",
      label: "Статус",
      type: FiltersType.autoComplete,
      selectedValue: statusValue,
      options: SEED_ARRIVAL_STATUS_LIST,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
    {
      name: "receipt_at",
      label: "Дата (период от и до)",
      type: FiltersType.datesRange,
      selectedValue: dateRange,
      value: '',
      style: { minWidth: "200px", flexGrow: 1 },
    },
    {
      name: "plot",
      label: "ГСУ",
      type: FiltersType.autoComplete,
      selectedValue: selectedGsuTypeValue,
      handleScroll: handleScroll,
      options: gsuTypeValue,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
      setValue: setValue
    },
  ];
};
