import yup from "@/utils/yup";

export const FIELDS_NAME = {
  NAME: "name",
  QUANTITY: "quantity",
  PRICE: "price"
} as const;

export interface IFormik {
  [FIELDS_NAME.NAME]: string,
  [FIELDS_NAME.QUANTITY]: number,
  [FIELDS_NAME.PRICE]: string,
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.NAME]: yup.string().trim().required(),
  [FIELDS_NAME.QUANTITY]: yup.number().required(),
  [FIELDS_NAME.PRICE]: yup.string().required(),
});

export const initialValues = {
  [FIELDS_NAME.NAME]: "",
  [FIELDS_NAME.QUANTITY]: "",
  [FIELDS_NAME.PRICE]: ""
};