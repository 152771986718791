import { styled } from "@mui/material/styles";
import {
  stepIconClasses,
  stepLabelClasses,
  Stepper as MuiStepper,
  svgIconClasses,
} from "@mui/material";

const stepperWidth = 200;

export const Root = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  width: "100%",
  height: "100%",
  overflowY: "auto",
  [theme.breakpoints.down("rg")]: {
    paddingBottom: theme.spacing(1),
    flexDirection: "column-reverse"
  }
}));

export const Stepper = styled(MuiStepper)(({ theme }) => ({
  height: 500,
  "& .MuiStepConnector-line": {
    height: "100%",
  },
  [`& .${stepIconClasses.text}`]: {
    fontSize: 12
  },
  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    color: theme.palette.blackAndWhite.black,
  },
  [`& .${stepLabelClasses.label}`]: {
    fontWeight: 500
  },
  [`& .${stepLabelClasses.labelContainer} .Mui-active`]: {
    color: theme.palette.primary.main
  }
}));

export const StepperWrap = styled("div")(({ theme }) => ({
  position: "fixed",
  height: `calc(100% - 70px)`,
  display: "flex",
  flexDirection: "column",
  width: stepperWidth,
  borderRight: `1px solid ${theme.palette.blackAndWhite.stroke}`,
}));

export const ContentWrap = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  maxWidth: `calc(750px + ${stepperWidth}px)`,
  paddingLeft: stepperWidth,
  [theme.breakpoints.down("rg")]: {
    paddingLeft: 0,
    maxWidth: "100%"
  }
}));