import { IColumn } from "@/apps/Table/interfaces";
import { CountryTextCell, DistinctTextCell, PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { ADDRESS_HEADER_LABELS, ADDRES_KEYS } from "../const";

import { TAddressKeys } from "../interfaces";
import { EditingCellsAddress } from "../templates/Address/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: ""
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const AddressColumns: IColumn<TAddressKeys>[] = [
  {
    label: ADDRESS_HEADER_LABELS.country__name,
    key: ADDRES_KEYS.country__name,
    contentHeader: ADDRESS_HEADER_LABELS.country__name,
    bodyCellProps: {
      render: CountryTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: ADDRESS_HEADER_LABELS.district__name,
    key: ADDRES_KEYS.district__name,
    contentHeader: ADDRESS_HEADER_LABELS.district__name,
    bodyCellProps: {
      render: DistinctTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: ADDRESS_HEADER_LABELS.locality_name,
    key: ADDRES_KEYS.locality_name,
    contentHeader: ADDRESS_HEADER_LABELS.locality_name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: ADDRESS_HEADER_LABELS.street_name,
    key: ADDRES_KEYS.street_name,
    contentHeader: ADDRESS_HEADER_LABELS.street_name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: ADDRESS_HEADER_LABELS.house,
    key: ADDRES_KEYS.house,
    contentHeader: ADDRESS_HEADER_LABELS.house,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: ADDRESS_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsAddress,
      styles: cellButtons,
    },
  },
];
