import React from "react";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { PAGE_SIZE, SWR_KEYS } from "@/const";
import {
  IMethodologyGroupingListItem
} from "@/api/interfaces/responses";
import { Box } from "@mui/system";
import theme from "@/theme";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    id: string | number;
    initialValue?: IMethodologyGroupingListItem[];
    onSubmit?: (value: IMethodologyGroupingListItem[]) => void;
  };
}

const SelectPlanningMethodologyGroups: React.FC<IProps> = ({
  handleClose,
  modalProps
}) => {
  const { api } = useStores();
  const handleAccept = (value: IMethodologyGroupingListItem[]) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handleClose();
  };

  const fetcher = (args) => {
    const { page, search } = args;
    return api.planning.getMethodologyGroupingList(modalProps.id,{
      page,
      page_size: PAGE_SIZE,
      search,
    });
  };

  const getLabel = (value: IMethodologyGroupingListItem): string => value.name;

  const renderControlLabel = (el: IMethodologyGroupingListItem) => {
    return (
      <Box>
        <Box mb="0.5">
          {el.name}
        </Box>
        <Box sx={{ color: theme.palette.blackAndWhite.gray }}>
          {el.variant_indicators.map(i => i.variant.severity).join(', ')}
        </Box>
      </Box>
    );
  };

  const props = {
    ...modalProps,
    key: SWR_KEYS.getMethodologyGroupingList(modalProps.id),
    title: "Признаки для группировки",
    saveButtonLabel: "Добавить",
    handleAccept,
    fetcher,
    getLabel: getLabel,
    renderControlLabel
  };

  return (
    <AddEntities<IMethodologyGroupingListItem>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectPlanningMethodologyGroups;
