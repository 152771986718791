export { };
import React from "react";
import { FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import {
  IProps,
  mapValueToForm,
  TForm,
  validationSchemaFirstStep,
  validationSchemaSecondStep
} from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import { resInterface } from "@/api/interfaces";
import useSWR from "swr";
import { observer } from "mobx-react";
import ModalStepper from "@/components/ModalStepper";
import BranchFormFirstStep
  from "./components/BranchFormSteps/first";
import BranchFormSecondStep
  from "./components/BranchFormSteps/second";

const baseInititialValues = {
  creator: "",
  editor: "",
  legal_address: "",
  actual_address: "",
  postal_address: "",
  is_active: "",
  name: "",
  full_name: "",
  short_name: "",
  region: null,
  staff_director: null,
  phones: [{ id: 0, phone: "", name: "" }],
  emails: [{ id: 0, email: "" }],
  comment: "",
  staffing: "",
  vacancies: "",
};

/**
 * Модалка с формой создания/редактирования филиала
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const Branch: React.FC<IProps> = ({
  handleClose,
  modalProps
}) => {
  const { branchId } = modalProps;
  const { api, toastStore, swrStore } = useStores();

  let branchData: resInterface.IBranchItem | undefined;
  if (branchId) {
    const SWR_KEY = SWR_KEYS.getBranchById(branchId);

    const { data } = useSWR(branchId ? SWR_KEY : null, () =>
      api.regulatoryInfo.getBranchById(branchId),
    );

    branchData = data;
  }

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const phones = values.phones.filter((i) => i.phone !== '').map((i) => ({ phone: i.phone, name: i?.name }));
    const emails = values.emails.filter((i) => i.email !== '').map((i) => ({ email: i.email }));
    const region = values?.region?.id
    const staff_director = values?.staff_director?.id
    const legal_address = values?.legal_address?.id ? values?.legal_address?.id : NaN
    const actual_address = values?.actual_address?.id ? values?.actual_address?.id : NaN
    const postal_address = values?.postal_address?.id ? values?.postal_address?.id : NaN
    const vacancies = values.vacancies ? values?.vacancies : NaN
    const staffing = values.staffing ? values.staffing : NaN

    const sendingData = {
      ...values,
      region,
      staff_director,
      legal_address,
      actual_address,
      postal_address,
      emails,
      phones,
      vacancies,
      staffing,
    };

    const promise = branchId
      ? api.regulatoryInfo.patchBranchById(branchId, sendingData)
      : api.regulatoryInfo.postBranch(sendingData);

    promise
      .then(() => { })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        if (branchId) {
          swrStore.mutators[SWR_KEYS.getBranchById(branchId)]
            ? swrStore.mutators[SWR_KEYS.getBranchById(branchId)]()
            : null;
        }
        swrStore.mutators[SWR_KEYS.getBranchList()]();
        handleClose();
      });
  };

  const values = branchData
    ? mapValueToForm(baseInititialValues, branchData)
    : baseInititialValues;

  const valuesFromModal = modalProps?.initialValues
    ? mapValueToForm(baseInititialValues, modalProps.initialValues)
    : values;

  const subtitle = modalProps.branchId
    ? "Редактировать запись"
    : "Добавить запись";


  return (
    <ModalStepper
      title="Филиал"
      subtitle={subtitle}
      initialValues={valuesFromModal}
      onSubmit={handleSubmit}
      handleClose={handleClose}
      enableReinitialize
    >
      <ModalStepper.Step
        validationSchema={validationSchemaFirstStep}
      >
        <BranchFormFirstStep
          {...modalProps}
        />
      </ModalStepper.Step>

      <ModalStepper.Step
        validationSchema={validationSchemaSecondStep}
      >
        <BranchFormSecondStep
          {...modalProps}
        />
      </ModalStepper.Step>
    </ModalStepper>
  );
};

export default observer(Branch);
