import React, { Fragment, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { EditIcon } from '@/components/icons';
import { useStores } from '@/hooks';

import { resInterface } from '@/api/interfaces';
import { VARIANT_MODAL } from '@/apps/Modals/const';
import { SWR_KEYS } from '@/const';
import { PERMISSION_CRUD, PERMISSIONS } from '@/premissions';

type TProps = {
  comPlanFactId: number;
  fullData: resInterface.IEffectivityList;
};

const EffectivityMenuItem: React.FC<TProps> = ({
  comPlanFactId,
  fullData,
}): JSX.Element => {
  const [anchorEl, setAnchorEl] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { modalStore, swrStore, userStore } = useStores();

  const mutateEffectivityList =
    swrStore.mutators[
      SWR_KEYS.getEffectivityList(Number(comPlanFactId))
    ];

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const onSubmit = () => {
    mutateEffectivityList();
  };

  const handleEdit = () => {
    modalStore.open(VARIANT_MODAL.ADD_EFFECTIVITY, {
      id: Number(fullData.id),
      com_plan_fact: comPlanFactId,
      year: fullData.year,
      title: 'Редактировать показатели эффективности',
      onSubmit: onSubmit,
      initialValues: {
        revenue: fullData.revenue,
        direct_expenses: fullData.direct_expenses,
        production_expenses: fullData.production_expenses,
        administrative_expenses:
          fullData.administrative_expenses,
        taxes: fullData.taxes,
        capital_investments: fullData.capital_investments,
      },
    });
  };

  const handleEditClick = (e) => {
    handleClose(e);
    handleEdit();
  };

  if (!fullData) return <Fragment />;

  const canEditEffectivness = userStore.isPermission(
    PERMISSIONS.extrabudgetaryComEffectiveness,
    PERMISSION_CRUD.change
  )

  if(!canEditEffectivness) {
    return <></>
  }

  return (
    <Box>
      <IconButton onClick={handleToggle}>
        <MoreVertIcon sx={{ fontSize: 20 }} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {canEditEffectivness && 
        <MenuItem onClick={handleEditClick}>
          <EditIcon />
          Редактировать
        </MenuItem>
        }
      </Menu>
    </Box>
  );
};

export default EffectivityMenuItem;
