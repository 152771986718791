import React from "react";
import { Box, Typography } from "@mui/material";
import LATable from "./LATable";
import {
  IPlotMainLocation
} from "@/api/interfaces/responses";
import { TextSnippet } from "@mui/icons-material";
import theme from "@/theme";

interface IProps {
  data: IPlotMainLocation[];
}

const LandsArea: React.FC<IProps> = ({ data }) => {
  return (
    <Box>
      <Typography mb={2} mt={2} variant="h3">
        Земельные участки
      </Typography>
      {
        data.length ? <LATable data={data}/> : (
          <Box p={3} display="flex" alignItems="center"
               justifyContent="center"
               flexDirection="column">
            <TextSnippet
              sx={{
                fontSize: 60,
                color: theme.palette.blackAndWhite.stroke,
              }}
            /><Typography variant="h2">Список земельных
            участков
            пуст</Typography></Box>
        )
      }
    </Box>
  );
};

export default LandsArea;
