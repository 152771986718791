import React, { Fragment } from "react";
import { Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";

import { Alert, Box, Button, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { IProps, TForm } from "../types";

import theme from "@/theme";
import { AddSignFieldArray } from "./AddSignFieldArray";

/**
 * Форма создания/редактирования
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const MethodologySignForm: React.FC<IProps> = ({
  modalProps,
}) => {
  const { modalStore } = useStores();
  const form = useFormikContext<TForm>();

  const title = modalProps.isEdit
    ? "Редактировать признак"
    : "Добавить признак";

  return (
    <Fragment>
      <Box p={3}>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "14px",
            color: theme.palette.blackAndWhite.gray,
          }}
        >
          Методика
        </Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <AddSignFieldArray modalProps={modalProps} />
        {form.errors?.non_field_errors &&
          <Alert
            severity="error">
            {form.errors?.non_field_errors}
          </Alert>
        }
        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={form.isSubmitting}
            variant="contained"
            color="primary"
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default MethodologySignForm;
