import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Divider, IconButton, Menu, Typography } from "@mui/material";

import { StyledMenuItem } from "@/components/StyledMenuItem";
import { DeleteIcon, EditIcon } from "@/components/icons";
import { buttonDotsStyle } from "@/apps/Table/style";
import theme from "@/theme";

type TProp = {
  handleDelete?: () => void;
  handleEdit?: () => void;
  isEdit?: boolean;
  isDelete?: boolean;
  colorDelete?: string;
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы с дропдауном с кнопками редактирования заявки
 * @param
 */
const Editing: React.FC<TProp> = ({
  handleDelete,
  handleEdit,
  isEdit = true,
  isDelete = true,
  colorDelete = theme.palette.text.primary
}): JSX.Element => {
  // const { api, modalStore } = useStores();
  // ссылка на компонент, определяющая открыт/закрыт дропдаун
  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  // функция закрытия компонента дропдауна
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const onDelete = (event: React.MouseEvent<HTMLElement>) => {
    handleClose(event);
    handleDelete && handleDelete();
  };

  if (!isEdit && !isDelete) return <></>;

  return (
    <Box>
      <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={isOpen} open={open} onClose={handleClose}>
        {isEdit ? (
          <>
            <StyledMenuItem
              onClick={(e) => {
                handleClose(e);
                handleEdit && handleEdit();
              }}
            >
              <EditIcon />
              Редактировать
            </StyledMenuItem>
          </>
        ) : null}
        {isDelete && isEdit ? <Divider /> : null}
        {isDelete ? (
          <StyledMenuItem onClick={onDelete} sx={{ color: colorDelete }}>
            <DeleteIcon color="error" />

            <Typography color="error">Удалить</Typography>
          </StyledMenuItem>
        ) : null}
      </Menu>
    </Box>
  );
};

export default Editing;
