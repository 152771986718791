import React from "react";
import {
  Field,
  FieldArray,
  useFormikContext
} from "formik";
import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { StyledBox } from "@/pages/AddField/styles";
import theme from "@/theme";
import {
  Add as AddIcon,
  Close as CloseIcon
} from "@mui/icons-material";
import { EmptyFIeld } from "@/components/icons";

interface IProps {
  name: string;
  handleClickDraw?: () => void;
  buttonLabel?: string;
  error?: boolean;
  disabled?: boolean
  helperText?: string;
}

const CoordinatesFieldArray: React.FC<IProps> = ({
  name,
  handleClickDraw,
  buttonLabel = "Нарисовать на карте",
  error = false,
  helperText = "",
  disabled
}) => {
  const formik = useFormikContext();
  const getErrorMessage = (error) => {
    if (typeof error === "string") {
      return error;
    } else {
      return "Введите правильные координаты";
    }
  };

  if (!formik) {
    throw new Error("CoordinatesFieldArray has to be used within <Formik>");
  }

  return (
    <FieldArray name={name}>
      {({ name, form, push, remove }) => {
        return (
          <FormControl component="fieldset" variant="standard">
            <FormLabel sx={{marginBottom: "10px"}}>
              <Typography variant="p14Medium" mb="20px">
                Координаты
              </Typography>
            </FormLabel>
            <FormGroup>
              <Stack direction="column" gap="20px">
                {form.values[name].map((_, index) => (
                  <Stack direction="row" alignItems="center" key={index}>
                    <StyledBox>
                      <Field name={`${name}.${index}.${0}`}>
                        {({ field, meta }) => (
                          <TextField
                            {...field}
                            type="number"
                            error={meta.touched && !!meta.error}
                            label={`Координата ${index + 1} - широта`}
                            fullWidth
                            size="small"
                          />
                        )}
                      </Field>
                      <Field name={`${name}.${index}.${1}`}>
                        {({ field, meta }) => (
                          <TextField
                            {...field}
                            type="number"
                            error={meta.touched && !!meta.error}
                            label={`Координата ${index + 1} - долгота`}
                            fullWidth
                            size="small"
                          />
                        )}
                      </Field>
                    </StyledBox>
                    <IconButton
                      sx={{ color: theme.palette.red.main }}
                      onClick={() => remove(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
            </FormGroup>
            <FormHelperText
              error={formik.touched[name] && !!formik.errors[name]}
            >
              {formik.touched[name] &&
                !!formik.errors[name] &&
                getErrorMessage(formik.errors[name])}
            </FormHelperText>
            <Stack mt="20px" gap={2} direction={"column"}>
              <Button
                disabled={disabled}
                variant="outlined"
                color="blue"
                startIcon={<AddIcon />}
                fullWidth
                onClick={() => push(["", ""])}
              >
                Добавить координаты
              </Button>
              {handleClickDraw && (
                <Button
                  variant="outlined"
                  color="blue"
                  startIcon={<EmptyFIeld />}
                  fullWidth
                  disabled={disabled}
                  onClick={handleClickDraw}
                >
                  {buttonLabel}
                </Button>
              )}
              <FormHelperText
                sx={{
                  display: error ? "block" : "none"
                }}
                id="draw_area_map"
              >
                {helperText}
              </FormHelperText>
            </Stack>
          </FormControl>
        );
      }}
    </FieldArray>
  );
};

export default CoordinatesFieldArray;
