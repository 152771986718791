import React, { useState } from "react";
import useSWR from "swr";
import Table from "@/apps/Table";
import {
  IVariety,
  IVarietyPlanPlot,
  ITrial
} from "@/api/interfaces/responses";
import { useStores } from "@/hooks";
import {
  TVarietyKeys
} from "@/apps/RightSidebar/templates/Planning/SecondLevel/components/VarietyTable/interfaces";
import {
  columns
} from "@/apps/RightSidebar/templates/Planning/SecondLevel/components/VarietyTable/models";
import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";
import Loader from "@/components/Loader";
import { Box } from "@mui/system";
import {
  SWR_KEYS,
  TRIAL_TYPE,
  VARIETY_TYPE
} from "@/const";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { Button } from "@mui/material";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";
import { TPlanningTrialType } from "@/api/types";

interface IProps {
  planPlotId: number;
  cultivarId: number;
  trialType: TPlanningTrialType;
  year: number | string;
}

const DistributionNonGroup: React.FC<IProps> = ({
  planPlotId,
  cultivarId,
  trialType,
  year
}) => {
  const {
    api,
    swrStore,
    modalStore
  } = useStores();
  const [page, setPage] = useState(1);

  const swrKey = SWR_KEYS.getVarietyPlanPlotList(null);

  const {
    data,
    isLoading,
    error,
    mutate
  } = useSWR(
    {
      page: page,
      pageSize: DEFAULT_SIZE_PAGE,
      plan_plot: planPlotId,
      variety_plan_plot_group__isnull: true
    },
    api.planning.getVarietyPlanPlot,
    {
      revalidateOnMount: true
    }
  );

  const handleAccept = (varieties: IVariety[]) => {
    const payload = varieties.map(el => ({
      variety: el.id,
      type: VARIETY_TYPE.REFERENCE,
      plan_plot: planPlotId
    }));
    api.planning.postVarietyPlanPlot(payload).then(() => {
      void mutate();
      modalStore.close();
    });
  };

  const handleAddNonGroup = () => {
    modalStore.open(VARIANT_MODAL.SELECT_VARIETIES, {
      title: "Выберите эталон",
      saveButtonLabel: "Добавить",
      currentCultivar: {
        id: cultivarId,
        name: cultivarId,
      }, onSubmit: handleAccept
    });
  };
  const handleAcceptTrial = (trials: ITrial[]) => {
    const payload = trials.map(el => ({
      trial: el.id,
      type: VARIETY_TYPE.TRIAL,
      plan_plot: planPlotId
    }));
    api.planning.postVarietyPlanPlot(payload).then(() => {
      void mutate();
      modalStore.close();
    });
  };
  const handleAddTrial = () => {
    modalStore.open(VARIANT_MODAL.SELECT_TRIALS, {
      cultivarId: cultivarId,
      year: year,
      trialType: trialType,
      onSubmit: handleAcceptTrial
    });
  };

  const renderButton = () => {
    if (trialType === TRIAL_TYPE.HP) {
      return (
        <Button
          color={"blue"}
          startIcon={<AddOutlinedIcon/>}
          variant={"outlined"}
          onClick={handleAddTrial}
        >
          Добавить испытание
        </Button>
      );
    }
    if (trialType === TRIAL_TYPE.OOS) {
      return (
        <>
          <Button
            color={"blue"}
            startIcon={<AddOutlinedIcon/>}
            variant={"outlined"}
            onClick={handleAddNonGroup}
          >
            Добавить эталон
          </Button>
          <Button
            color={"blue"}
            startIcon={<AddOutlinedIcon/>}
            variant={"outlined"}
            onClick={handleAddTrial}
          >
            Добавить испытание
          </Button>
        </>
      );
    }
    return null;
  };

  swrStore.addMutator(swrKey, mutate);

  return (
    <Box>
      <Box display="flex" gap={1} flexWrap="wrap">
        {renderButton()}
      </Box>
      <Box>
        <Table<IVarietyPlanPlot, TVarietyKeys>
          columns={columns}
          isLoading={isLoading}
          data={data}
          error={error}
          page={page}
          setPage={setPage}
          isFilters={false}
          renderLoader={() => <Loader size={40}/>}
        />
      </Box>
    </Box>
  );
};

export default DistributionNonGroup;
