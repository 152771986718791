import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import WeatherWorkForm from "./components/WeatherWorkForm";
import { IProps, mapFormToValue, mapValueToForm, TForm, validationSchema } from "./const";

/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const WeatherWork: React.FC<IProps> = ({ handleClose, modalProps }) => {
    const initialValues = modalProps.initialValues;
    const {workId, id} = modalProps;
    const baseInititialValues = {
        cultivar_field_work: workId,
        weather_condition: '',
    }

    const { api, toastStore, swrStore } = useStores();

    const handleSubmit = (
        values: TForm,
        helpers: FormikHelpers<FormikValues>,
    ) => {
        helpers.setSubmitting(true);

        const promise = id ? 
        api.agriculturalLabour.patchWeatherCultivarFieldWorkById(id,mapFormToValue(values))
         : api.agriculturalLabour.postWeatherCultivarFieldWork(mapFormToValue(values));

        promise
            .then(() => {
                handleClose();
            })
            .catch((error: AxiosError) => {
                const errors = error.response?.data;
                if (errors) {
                    helpers.setTouched(errors).then(() => {
                        helpers.setErrors(errors);
                    });
                } else {
                    const errorMessage = errorToString(error);
                    toastStore.createToast({
                        type: TOAST_TYPES.ALERT,
                        toastProps: {
                            message: errorMessage,
                            severity: "error",
                        },
                    });
                }
            })
            .finally(() => {
                helpers.setSubmitting(false);
                swrStore.mutators[SWR_KEYS.getWeatherCultivarFieldWork()]();
            });
    };

    const values = initialValues
        ? {
            ...baseInititialValues,
            ...mapValueToForm(initialValues),
        }
        : baseInititialValues;

    return (
        <Formik
            initialValues={values}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
            component={() => (
                <WeatherWorkForm modalProps={modalProps} handleClose={handleClose} />
            )}
        />
    );
};

export default WeatherWork;


