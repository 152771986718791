import React from "react";
import {
  FormControl,
  SelectChangeEvent,
  SelectProps
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ALLOCATION_TYPE, ALLOCATION_TITLE } from "@/const";

interface IProps extends SelectProps {
  label?: string;
  handleChange: (payload: SelectChangeEvent) => void;
  value: string | undefined;
}
/**
 * Селект с повторениями / alloction_number
 */

const AllocationSelect: React.FC<IProps> = ({
  label = "Повторение",
  handleChange,
  value,
  fullWidth,
  ...props
}) => {
  return (
    <FormControl size="small" variant="outlined" fullWidth={fullWidth}>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        onChange={handleChange}
        value={value}
        label={label}
        fullWidth={fullWidth}
        {...props}
      >
        <MenuItem value="">
          <em>Не выбрано</em>
        </MenuItem>
        <MenuItem value={ALLOCATION_TYPE[1]}>
          {ALLOCATION_TITLE[1]}
        </MenuItem>
        <MenuItem value={ALLOCATION_TYPE[2]}>
          {ALLOCATION_TITLE[2]}
        </MenuItem>
        <MenuItem value={ALLOCATION_TYPE[3]}>
          {ALLOCATION_TITLE[3]}
        </MenuItem>
        <MenuItem value={ALLOCATION_TYPE[4]}>
          {ALLOCATION_TITLE[4]}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default AllocationSelect;