import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import useSWR, { BareFetcher, SWRResponse } from "swr";
import { Box, Button } from "@mui/material";
import {
  Add,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import {
  useLocation,
  useSearchParams,
} from "react-router-dom";
import qs from "qs";

import Table from "@/apps/Table";
import Filters from "@/components/Filters";
import { CopyIcon } from "@/components/icons";
import theme from "@/theme";
import {
  useInfiniteScroll,
  useQuery,
  useRightSidebar,
  useStores,
} from "@/hooks";
import {
  ROUTES,
  ROUTES_PATHS,
} from "@/apps/AppRouter/const";
import {
  KEYS_SIDEBAR_VARIANT_MODULES,
  ORDER_TYPE,
  PAGE_SIZE,
  SWR_KEYS,
} from "@/const";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { isUndefined } from "@/utils/helpers";
import {
  IOrder,
  IOrderCultivar,
  IOrderPlot,
  IPaginatedResult,
} from "@/api/interfaces/responses";
import { IErrorResponse, IValue } from "@/interfaces";

import { columns } from "../models";
import {
  IOrderCultivarQueryParams,
  TCultivarKeys,
} from "../interfaces";
import { getFilters } from "../utils";
import {
  ADD_CULTIVAR_TEXT,
  EMPTY_LIST_CULTIVAR_TEXT,
  EMPTY_LIST_CULTIVAR_TEXT_WITH_FILTERS,
} from "../const";
import { styledButton } from "../style";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import useDebounce from "@/hooks/useDebounce";
import dayjs from "dayjs";

/**
 * Компонент, представляющий таблицу распределения культур.
 * @returns {ReactElement}
 */
const OrderCultivarTable = (): ReactElement => {
  const {
    api,
    swrStore,
    queryStringSidebarCollector,
    modalStore,
  } = useStores();
  const navigate = useNavigate();
  const { handleOpen } = useRightSidebar();
  const [, setSearchParams] = useSearchParams();
  const query = useQuery<IOrderCultivarQueryParams>();
  const [queryParams, setQueryParams] =
    useState<IOrderCultivarQueryParams>(query);

  const orderingArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orderingArray);
  const swrKeyCultivarList =
    SWR_KEYS.getOrderCultivarList();
  const swrKeyPlotList = SWR_KEYS.getOrderPlotList();

  const [searchOrder, setSearchOrder] = useState("");
  const dbouncedSearchOrderValue = useDebounce(
    searchOrder,
    500,
  );

  const {
    data,
    error,
    isLoading,
    mutate,
  }: SWRResponse<
    IPaginatedResult<IOrderCultivar>,
    IErrorResponse
  > = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      key: swrKeyCultivarList,
      search: queryParams.search,
      plot: queryParams.plot?.key,
      cultivar: queryParams.cultivar?.key,
      status: queryParams.status?.key,
      order: queryParams.order_number?.key,
    },
    api.regulatoryInfo.getOrderCultivarList as BareFetcher<
      IPaginatedResult<IOrderCultivar>
    >,
  );

  const fetcherPlots = (args) => {
    const { page, search } = args;

    return api.regulatoryInfo.getOrderPlotList({
      page,
      page_size: PAGE_SIZE,
      search,
    });
  };

  const getKeyPlots = (index: number) => {
    return {
      key: swrKeyPlotList,
      page: index + 1,
      search: queryParams.search_plot,
    };
  };

  const { data: plotData, handleScroll: handlePlotScroll } =
    useInfiniteScroll<IOrderPlot>(
      getKeyPlots,
      fetcherPlots,
      {
        revalidateFirstPage: false,
      },
    );

  const plots: IValue[] = plotData.map((el) => {
    return { label: el?.plot?.name, key: el?.plot?.id };
  });

  swrStore.addMutator(swrKeyCultivarList, mutate);

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleDownloadOrder = (payload: IOrder) =>
    api.regulatoryInfo.downloadOrder(
      payload.id,
      payload.name,
    );

  const handleCopyOrder = (from: number, to: number) => {
    return api.regulatoryInfo
      .copyOrder(from, to)
      .then(() => {
        mutate();
      });
  };

  const handleOpenCopyModal = () => {
    modalStore.open(VARIANT_MODAL.COPY_ORDER, {
      theme: THEME_MODAL.W_555,
      handleAccept: handleCopyOrder,
      isCrop: true,
    });
  };

  const handleOpenSelectOrderModal = () => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_ORDER, {
      theme: THEME_MODAL.W_555,
      fetcher: handleDownloadOrder,
      q: {
        order_type: ORDER_TYPE.CULTIVAR,
      },
    });
  };

  const handleChangeOrderBy = (value: TCultivarKeys[]) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  const location = useLocation();

  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {
    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent:
        KEYS_SIDEBAR_VARIANT_MODULES.ORDER_CULTIVAR,
      module_id: id,
    });

    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.ORDER_CULTIVAR,
      modalProps: {
        id,
      },
    });
    setSearchParams(str);
  };

  const handleOpenCultivarPage = () => {
    navigate({
      pathname: `${ROUTES_PATHS.planning}/${ROUTES.addCultivar}`,
    });
  };

  useEffect(() => {
    setQueryParams(query);
  }, [location.search]);

  const getKey = (index: number) => {
    return {
      key: SWR_KEYS.getOrderList(),
      page: index + 1,
      search: dbouncedSearchOrderValue,
      order_type: ORDER_TYPE.CULTIVAR,
    };
  };

  const fetcher = (args) => {
    const { page, search, order_type } = args;
    return api.regulatoryInfo.getOrderList({
      page,
      search,
      order_type,
    });
  };

  const {
    data: orderArr,
    handleScroll: handleOrderScroll,
  } = useInfiniteScroll<IOrder>(getKey, fetcher, {
    revalidateFirstPage: false,
  });

  const orders: IValue[] = orderArr.map(
    ({ number, id, order_date }) => {
      const label = `${number} - ${dayjs(order_date).format(
        "DD.MM.YYYY",
      )}`;
      return { label, key: id };
    },
  );

  const filters = getFilters(
    plots,
    handlePlotScroll,
    query.plot,
    query.status,
    query.order_number,
    orders,
    handleOrderScroll,
    setSearchOrder,
  );

  const isFilters: boolean =
    Object.keys(queryParams).length > 0;

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
          searchStyle={{ maxWidth: "180px" }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            flexWrap: "wrap",
          }}
        >
          <Button
            sx={styledButton(theme)}
            variant="outlined"
            startIcon={<FileDownloadOutlined />}
            onClick={handleOpenSelectOrderModal}
          >
            Документы
          </Button>
          <Button
            sx={styledButton(theme)}
            variant="outlined"
            startIcon={<CopyIcon />}
            onClick={handleOpenCopyModal}
          >
            Копировать приказ
          </Button>
          <Button
            sx={{
              whiteSpace: "nowrap",
              flexShrink: "0",
            }}
            variant="contained"
            startIcon={<Add />}
            onClick={handleOpenCultivarPage}
          >
            Добавить культуру
          </Button>
        </Box>
      </Box>

      <Table<IOrderCultivar, TCultivarKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={ADD_CULTIVAR_TEXT}
        textEmptyTableWithFilter={
          EMPTY_LIST_CULTIVAR_TEXT_WITH_FILTERS
        }
        textEmptyTable={EMPTY_LIST_CULTIVAR_TEXT}
        onRowClick={handleOpenRightSidebar}
      />
    </Fragment>
  );
};

export default OrderCultivarTable;
