import React from "react";
import { Box, Button, Typography } from "@mui/material";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { Formik, Form } from "formik";

import { useCurrentUser, useStores } from "@/hooks";
import {
  checkFieldError,
  checkTextErrorField
} from "@/utils/formikHelpers";
import { errorToString } from "@/utils/helpers";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { REGEX, SEO_TITLES } from "@/const";
import {
  PasswordField
} from "@/components/form/base/PasswordField";
import NavigateBeforeIcon
  from "@mui/icons-material/NavigateBefore";
import IconButton from "@mui/material/IconButton";
import AuthLayout
  from "../components/AuthLayout/AuthLayout";
import styles from "./index.module.scss";

const validationSchema = Yup.object().shape({
  new_password: Yup.string()
    .trim()
    .required("Введите пароль")
    .matches(
      REGEX.SOFT_PASS,
      "Пароль может содержать только латинские буквы, цифры и спецсимволы"
    )
    .matches(
      REGEX.HARD_PASS,
      "Пароль должен содержать не менее 12 знаков, включать хотя бы одну заглавную латинскую букву и одну цифру"
    ),
  repeat_password: Yup.string()
    .trim()
    .test("isMatch", "Пароли должны совпадать", function (value) {
      return value === this.parent.new_password;
    })
    .required("Введите пароль ещё раз"),
});

const PasswordResetRequest = () => {
  const [error, setError] = React.useState<string>("");

  const { api } = useStores();
  const { username, trialPassword } = useCurrentUser();
  const navigate = useNavigate();

  const handleSubmit = (values, formikHelpers) => {
    formikHelpers.setSubmitting(true);
    api.auth
      .postChangePassword({
        password: values.password as string,
        username: values.username as string,
        new_password: values.new_password
      })
      .then(() => {
        navigate(ROUTES_PATHS.passwordSuccess);
      })
      .catch((e) => {
        const error = errorToString(e);
        setError(error);
      })
      .finally(() => formikHelpers.setSubmitting(false));
  };

  console.log("USER", {
    username, trialPassword
  });

  if (!username || !trialPassword) {
    navigate(ROUTES_PATHS.login)
    return null
  }

  return (
    <AuthLayout title={SEO_TITLES.PASSWORD_RESET}>
      <div className={styles.root}>
        <div className={styles.head}>
          <IconButton href={ROUTES_PATHS.login}
                      sx={{ fontSize: 32 }}>
            <NavigateBeforeIcon fontSize="inherit"/>
          </IconButton>
          <Typography variant="h2">Изменение
            пароля</Typography>
        </div>
        <Box p={2}>
          <Typography fontSize={14} paddingBottom={2}>
            Поменяйте одноразовый пароль для входа в
            аккаунт. Надежный пароль
            содержит цифры, заглавные буквы и спец. символы.
          </Typography>
          <Formik initialValues={{
            username: username,
            password: trialPassword,
            new_password: "",
            repeat_password: ""
          }}
                  validationSchema={validationSchema}
                  validateOnBlur={true}
                  validateOnChange={false}
                  onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form noValidate>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "32px",
                    gap: "16px"
                  }}
                >
                  <PasswordField
                    id="outlined-size-small"
                    name="new_password"
                    label="Новый пароль"
                    fullWidth
                    size="small"
                    placeholder="Введите новый пароль"
                    error={checkFieldError(formik, "new_password")}
                    helperText={checkTextErrorField(formik, "new_password")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.new_password}
                    disabled={formik.isSubmitting}
                  />
                  <PasswordField
                    id="outlined-size-small"
                    name="repeat_password"
                    label="Повторите новый пароль"
                    fullWidth
                    size="small"
                    placeholder="Введите новый пароль"
                    error={checkFieldError(formik, "repeat_password")}
                    helperText={checkTextErrorField(formik, "repeat_password")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.repeat_password}
                    disabled={formik.isSubmitting}
                  />
                </Box>
                <div className={styles.footer}>
                  <div
                    className={styles.error}>{error}</div>
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={formik.isSubmitting}
                    >
                      Сохранить
                    </Button>
                  </Box>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </div>
    </AuthLayout>
  );
};

export default PasswordResetRequest;