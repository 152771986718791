import React, { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import theme from "@/theme";
import { CloseIcon } from "@/components/icons";
import {
  FIELD_NAME,
  ISubjectPhone,
  TForm,
} from "../../../types";

type TKey = "name" | "phone";

export const PhoneField: React.FC = () => {
  const form = useFormikContext<TForm>();
  const [phones, setPhones] = useState<ISubjectPhone[]>(
    form.values.phones,
  );

  useEffect(() => {
    setPhones(form.values.phones);
  }, [form.values.phones]);

  const handleAdd = () => {
    form.setFieldValue(FIELD_NAME.PHONES, [
      ...form.values.phones,
      {
        id: form.values.phones.length + 1,
        phone: "",
        name: "",
      },
    ]);
  };

  const deletePhone = (i: ISubjectPhone) => {
    if (form.values.phones.length > 1) {
      form.setFieldValue(
        FIELD_NAME.PHONES,
        form.values.phones.filter((a) => a.id !== i.id),
      );
    }
  };

  const handleBlur = (
    event: React.FocusEvent<
      HTMLInputElement | HTMLTextAreaElement
    >,
    index: number,
    key: TKey,
  ) => {
    event.preventDefault();
    const nextPhones = form.values.phones.map((c, i) => {
      if (i === index) {
        return {
          ...c,
          [key]: event.target.value,
        };
      } else {
        return c;
      }
    });
    form.setFieldValue(FIELD_NAME.PHONES, nextPhones);
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement
    >,
    index: number,
    key: TKey,
  ) => {
    event.stopPropagation();
    const nextPhones = form.values.phones.map((c, i) => {
      if (i === index) {
        return {
          ...c,
          [key]: event.target.value,
        };
      } else {
        return c;
      }
    });
    setPhones(nextPhones);
  };

  return (
    <>
      {form.values.phones.map((i, index) => {
        return (
          <Box
            key={i.id}
            display="flex"
            flexWrap="nowrap"
            justifyContent="space-between"
            flexDirection={"row"}
            gap={"5px"}
            sx={{
              border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
              borderRadius: "4px",
              padding: "10px",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <Grid container spacing={3}>
              <Grid item>
                <Field
                  name={`${FIELD_NAME.PHONES}.${index}.phone`}
                >
                  {({ field, meta }) => (
                    <FormControl>
                      <TextField
                        {...field}
                        size="small"
                        value={phones[index]?.phone}
                        onBlur={(event) =>
                          handleBlur(event, index, "phone")
                        }
                        label={`Телефон ${index + 1}`}
                        onChange={(e) =>
                          handleChange(e, index, "phone")
                        }
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        InputProps={{
                          sx: {
                            width: "210px",
                          },
                        }}
                      />
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item>
                <Field
                  name={`${FIELD_NAME.PHONES}.${index}.name`}
                >
                  {({ field, meta }) => (
                    <FormControl>
                      <TextField
                        {...field}
                        size="small"
                        sx={{ width: "auto" }}
                        value={phones[index]?.name}
                        onBlur={(event) =>
                          handleBlur(event, index, "name")
                        }
                        label={`Название ${index + 1}`}
                        onChange={(e) =>
                          handleChange(e, index, "name")
                        }
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        InputProps={{
                          sx: {
                            width: "210px",
                          },
                        }}
                      />
                    </FormControl>
                  )}
                </Field>
              </Grid>
            </Grid>
            <Button
              disableRipple
              disableTouchRipple
              disabled={phones.length === 1}
              sx={{
                minWidth: "25px",
                ml: "10px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => deletePhone(i)}
              startIcon={<CloseIcon />}
            />
          </Box>
        );
      })}
      {phones.length <= 1 && (
        <Button
          color="blue"
          size="large"
          startIcon={<AddOutlinedIcon />}
          variant="outlined"
          onClick={handleAdd}
          fullWidth
        >
          Добавить телефон
        </Button>
      )}
    </>
  );
};
