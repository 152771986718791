import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  IPlanning,
  ITrial
} from "@/api/interfaces/responses";
import { useStores } from "@/hooks";
import { PAGE_SIZE, SWR_KEYS, TRIAL_TYPE } from "@/const";
import { VARIANT_MODAL } from "../../const";
import AddEntities from "../AddEntities/AddEntities";
import {
  TVarietyType
} from "@/apps/RightSidebar/templates/Planning/SecondLevel/components/types";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    initialValue?: ITrial[];
    title?: string;
    branchID: number;
    planID: number;
    planPlotID?: number;
    plan: IPlanning;
    isStandard?: boolean;
    isIndicator?: boolean;
    handleSubmit?: () => void
    typeVariety?: TVarietyType;
  };
}

/**
 * Модалка добавления Участков ГСУ в деталке плана
 * @param handleClose
 * @param modalProps
 * @component
 */

const AddTrials: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const {
    modalStore,
    api,
    toastStore,
    swrStore,
  } = useStores();
  const {
    plan,
    typeVariety,
    isStandard,
    isIndicator,
    planPlotID,
    handleSubmit
  } = modalProps;
  const [isLoading, setIsLoading] = useState(false);

  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };

  const getLabel = (value: ITrial) => value.name;

  const mutatorVarietyList =
    swrStore.mutators[
    SWR_KEYS.getVarietyPlanPlotTrial(plan.id)
    ];



  const handleAccept = (values: ITrial[]) => {
    const payload = values.map((el) => ({
      plan: plan.trial_type === TRIAL_TYPE.PHYTO ? undefined :  plan.id,
      plan_plot: plan.trial_type === TRIAL_TYPE.PHYTO ? planPlotID :  undefined,
      variety: el?.statement_obj?.variety?.id,
      trial: el.id,
      type: typeVariety,
    }));
    setIsLoading(true);
    api.planning
      .postVarietyPlanPlot(payload)
      .then(() => {
        handleSubmit && handleSubmit()
        handleClose();
        mutatorVarietyList();
      })
      .catch((error) => {
        showToast(error)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  const handleAddClose = () => {
    modalStore.open(VARIANT_MODAL.ORIGINATORS, modalProps);
  };

  const fetcher = (args) => {
    const { page, search } = args;
    return api.trial.getTrialList({
      page,
      page_size: PAGE_SIZE,
      search: search,
      trial_type: plan.trial_type,
      cultivar: plan.cultivar.id,
      is_standard: !!isStandard,
      is_indicator: !!isIndicator,
      statement_status: 'accepted'
    });
  };

  const props = {
    title: "Добавить испытание",
    ...modalProps,
    q: { branch: modalProps.branchID },
    fetcher,
    key: uuidv4(),
    handleClose,
    handleAccept,
    handleAddClose,
    disabled: isLoading,
    getLabel: getLabel,
    saveButtonLabel: "Добавить",
  };
  return (
    <AddEntities<ITrial>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default AddTrials;
