import yup from "@/utils/yup";
import { Dayjs } from "dayjs";
import {
  ICultivarFieldVariety
} from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  REGION: "region",
  BRANCH: "branch",
  CULTIVAR: "cultivar",
  YEAR: "year",
  PLAN: "plan"
} as const;

export const initialValues = {
  [FIELDS_NAME.REGION]: null,
  [FIELDS_NAME.BRANCH]: null,
  [FIELDS_NAME.CULTIVAR]: null,
  [FIELDS_NAME.PLAN]: null,
  [FIELDS_NAME.YEAR]: null,
};

export interface IValue {
  id: number,
  label: string
}

export interface IFormik {
  [FIELDS_NAME.REGION]: IValue | null,
  [FIELDS_NAME.BRANCH]: IValue | null,
  [FIELDS_NAME.CULTIVAR]: IValue | null,
  [FIELDS_NAME.PLAN]: IValue | null,
  [FIELDS_NAME.YEAR]: Dayjs | null | any,
}

export const serializeValues = (values?:ICultivarFieldVariety): IFormik => ({
  region: null,
  branch: values?.plan ? {
    id: values.plan.branch.id,
    label: values.plan.branch.name,
  } : null,
  cultivar: values?.plan ? {
    id: values.plan.cultivar.id,
    label: values.plan.cultivar.name,
  } : null,
  plan: values?.plan ? {
    id: values.plan.id,
    label: `План ${values.plan.id}`,
  } : null,
  year: null,
});

export const serializeFormToValues = (values: IFormik) => ({
  plan: values.plan?.id as number,
});

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.CULTIVAR]: yup.mixed().nullable(),
  [FIELDS_NAME.YEAR]: yup.mixed().nullable(),
  [FIELDS_NAME.REGION]: yup.mixed().nullable(),
  [FIELDS_NAME.BRANCH]: yup.mixed().nullable(),
  [FIELDS_NAME.PLAN]: yup.mixed().required(),
});
