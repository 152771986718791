import React, { useEffect, useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { Stack } from "@mui/system";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import Autocomplete from "@/components/Autocomplete";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME, IFormik } from "../const";
import {
  IAgriculturalLabourWork
} from "@/api/interfaces/responses";
import useSWR from "swr";
import { IOffBudget } from "@/api/interfaces/requests";

interface IProps {
  handleClose: () => void;
  modalProps: {
    id: number;
    cultivarId: number;
    initialValues?: IFormik;
  };
}

const AddWorkForm: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const { api, swrStore, rightSidebarStore } = useStores();
  const { id } = rightSidebarStore.sidebarProps;
  const KEY_SWR = SWR_KEYS.getOffBudgetById(id);

  const { data: planData } = useSWR<IOffBudget>(
    id ? KEY_SWR : null,
    () => api.offBudget.getOffBudgetPlan(id),
  );

  const [search, setSearch] = useState("");
  const formik = useFormikContext();

  const swrKey = SWR_KEYS.getWorkList();
  const getKey = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    search: search ?? undefined,
    tech_map_plan_cultivar: modalProps.cultivarId,
    tech_map_plan_branch: planData?.branch.id,
  });

  const { data, handleScroll, mutate, isEmpty } =
    useInfiniteScroll(
      getKey,
      api.agriculturalLabour.getWorkList,
      {
        revalidateFirstPage: false,
      },
    );

  const handleChange = (event, child) => {
    formik.setFieldValue(FIELDS_NAME.WORK, child);
  };

  const getLabel = (el: IAgriculturalLabourWork) => el.name;

  useEffect(() => {
    swrStore.addMutator(swrKey, mutate);
  }, []);

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <Field name={FIELDS_NAME.WORK}>
          {({ field, meta }) => (
            <FormControl required>
              <Autocomplete
                {...field}
                getLabel={getLabel}
                onChange={handleChange}
                label="Технологическая операция"
                required
                data={data}
                handleScroll={handleScroll}
                search={setSearch}
                error={meta.touched && !!meta.error}
              />

              {isEmpty && (
                <Alert sx={{ mt: 2 }} severity="warning">
                  Справочник тех. операций не заполнен по
                  данной культуре
                </Alert>
              )}

              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field name={FIELDS_NAME.FUEL_PRICE}>
          {({ field, meta }) => (
            <TextField
              {...field}
              type="number"
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              label={"Цена за л ГСМ"}
              fullWidth
              size="small"
              required
            />
          )}
        </Field>
      </Stack>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default AddWorkForm;
