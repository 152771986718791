import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  IBranchMainTrial
} from "@/api/interfaces/responses";

interface IProps {
  data: IBranchMainTrial[]
}

const rowTitle = {
  hp: "ХП",
  oos: "ООС",
  phyto: "Фито",
  lab: "Лабораторные",
  total: "Итого"
}

const VTTable: React.FC<IProps> = ({data}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell colSpan={3} align="center">
              Выполнено
            </TableCell>
            <TableCell/>
            <TableCell colSpan={3} align="center">
              В процессе
            </TableCell>
            <TableCell/>
            <TableCell colSpan={3} align="center">
              Не выполнено
            </TableCell>
            <TableCell/>
            <TableCell colSpan={1} align="right">
              Итого
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell/>
            <TableCell align="center">Всего</TableCell>
            <TableCell align="center">Бюджет</TableCell>
            <TableCell align="center">Платные</TableCell>
            <TableCell/>

            <TableCell align="center">Всего</TableCell>
            <TableCell align="center">Бюджет</TableCell>
            <TableCell align="center">Платные</TableCell>
            <TableCell/>

            <TableCell align="center">Всего</TableCell>
            <TableCell align="center">Бюджет</TableCell>
            <TableCell align="center">Платные</TableCell>
            <TableCell/>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const sx = { fontWeight: row.type === "total" ? 700 : 400 }
            return (
              <TableRow key={row.type}>
                <TableCell component="th" scope="row" sx={sx}>{rowTitle[row.type] || "-"}</TableCell>
                <TableCell
                  align="center"
                  sx={sx}
                >
                  {row.completed.total}
                </TableCell>
                <TableCell
                  align="center"
                  sx={sx}
                >
                  {row.completed.budget}
                </TableCell>
                <TableCell
                  align="center"
                  sx={sx}
                >
                  {row.completed.paid}
                </TableCell>
                <TableCell/>
                <TableCell
                  align="center"
                  sx={sx}
                >
                  {row.execution.total}
                </TableCell>
                <TableCell
                  align="center"
                  sx={sx}
                >
                  {row.execution.budget}
                </TableCell>
                <TableCell
                  align="center"
                  sx={sx}
                >
                  {row.execution.paid}
                </TableCell>
                <TableCell/>
                <TableCell
                  align="center"
                  sx={sx}
                >
                  {row.aborted.total}
                </TableCell>
                <TableCell
                  align="center"
                  sx={sx}
                >
                  {row.aborted.budget}
                </TableCell>
                <TableCell
                  align="center"
                  sx={sx}
                >
                  {row.aborted.paid}
                </TableCell>
                <TableCell/>
                <TableCell align="right" sx={sx}>
                  {row.total}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VTTable;
