import theme from "@/theme";
import { TVarietyType } from "./types";

// VARIETY

export const VARIETY_KEYS = {
  type: "type",
  code: "code",
  name: "name",
  censusTaker: "census_taker",
  editing: "editing"
} as const;

export const VARIETY_HEADER_LABELS = {
  type: "Тип",
  code: "Код",
  name: "Сорт",
  censusTaker: "Переписчик",
};

export const VARIETY_TYPES: { [key in TVarietyType]: string } = {
  standart: "Стандарт",
  trial: "Испытание",
  similar: "Похожий",
  reference: "Эталон",
}

export const VARIETY_TYPES_COLOR: { [key in TVarietyType]: string } = {
  standart: theme.palette.green.main,
  trial: theme.palette.another.violet,
  similar: theme.palette.green.main,
  reference: theme.palette.green.main,
}

export const VARIETY_TYPES_BG_COLOR = theme.palette.backgroundColors.grey

export const EMPTY_LIST_VARIETY_TEXT = "Еще не создано ни одной записи";
export const ADD_VARIETY_TEXT = "Добавить запись";
export const EMPTY_LIST_VARIETY_TEXT_WITH_FILTERS = "По указанным параметрам записи не найдены";

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
  print: "Печать",
}

export const VARIETY_ENTITY_NAME = "сорт";
