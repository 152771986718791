import yup from "@/utils/yup";
import { Dayjs } from "dayjs";

export const FIELD_NAME = {
  NAME: "name",
  NUMBER: "number",
  RECEIPT_DATE: "receipt_date",
  FILES: "files"
} as const;


export interface IFormik  {
  [FIELD_NAME.NAME]: string,
  [FIELD_NAME.NUMBER]: string,
  [FIELD_NAME.RECEIPT_DATE]: Dayjs | string | null,
  [FIELD_NAME.FILES]: File[]
}

export const initialValues = {
  [FIELD_NAME.NAME]: "",
  [FIELD_NAME.NUMBER]: "",
  [FIELD_NAME.RECEIPT_DATE]: null,
  [FIELD_NAME.FILES]: []
};

export const validationSchema = yup.object().shape({
  [FIELD_NAME.FILES]: yup.array().min(1, 'Выберите файл')
})