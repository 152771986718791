import { IColumn } from "@/apps/Table/interfaces";
import { TIndicatorsTypeKeys } from "../interfaces";
import {
  INDICATORS_KEYS,
  INDICATORS_LABELS,
} from "../const";
import { PointerTextCell } from "../cells";
import {
  cellButtonsMaterialType,
  cellPointText,
  headerCellStyle,
} from "../style";
import {
  EditingCellsIndicator,
  IndicatorTypeCell,
} from "../templates/Indicators/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const indicatorsColumns: IColumn<TIndicatorsTypeKeys>[] =
  [
    {
      label: INDICATORS_LABELS.type_trial,
      key: INDICATORS_KEYS.type_trial,
      contentHeader: INDICATORS_LABELS.type_trial,
      bodyCellProps: {
        render: IndicatorTypeCell,
        styles: cellPointText,
      },
      headerCellProps: {
        styles: headerCellStyle,
      },
    },
    {
      label: INDICATORS_LABELS.name,
      key: INDICATORS_KEYS.name,
      contentHeader: INDICATORS_LABELS.name,
      bodyCellProps: {
        render: PointerTextCell,
        styles: cellPointText,
      },
      headerCellProps: {
        styles: headerCellStyle,
      },
    },
    {
      key: "editing",
      label: INDICATORS_LABELS.editing,
      contentHeader: COLUMN_EDITING.label,
      bodyCellProps: {
        render: EditingCellsIndicator,
        styles: cellButtonsMaterialType,
      },
    },
  ];
