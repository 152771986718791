import React, { ReactElement, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Table from "@/apps/Table";
import { observer } from "mobx-react";
import {  useQuery, useStores } from "@/hooks";
import { IBudgetaryTrailCalc, IPaginatedResult} from "@/api/interfaces/responses";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { SWR_KEYS } from "@/const";
import { IErrorResponse } from "@/interfaces";
import useSWR, { SWRResponse } from "swr";
import { isUndefined } from "swr/_internal";
import { IPropsSidebar, TBudgetaryTrailCalcKey } from "../../interfaces";
import AddIcon from "@mui/icons-material/Add";
import qs from "qs";
import Filters from "@/components/Filters";
import { Box, Button, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { getFilters } from "./utils";
import { BudgetaryTrailCalcColumns } from "../../models/budgetaryTrailCalc";
import { TYPES_ARRAY } from "../Methodology/cells";


/**
 * Компонент, представляющий таблицу сортов.
 * @returns {ReactElement}
 */
const BudgetaryTrailCalcTable: React.FC<IPropsSidebar> = ({
  handleOpenRightSidebar,
}): ReactElement => {
  const { api, swrStore, modalStore } = useStores(); //modalStore, queryStringSidebarCollector,
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();
  const [selectedType, setSelectedType] = useState("");


  // переменная которая показывает применены ли фильтры
  const isFilters: boolean = Object.keys(query).length > 0;
  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering) ? query.ordering : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] = useState<string[]>(orgeringArray);

  const {
    data,
    error,
    isLoading,
    mutate,
  }: SWRResponse<IPaginatedResult<IBudgetaryTrailCalc>, IErrorResponse> = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: query.search,
      type_of_trial: query?.type,
      year_of_trial: query?.year,
      key: SWR_KEYS.getBudgetaryTrailCalc()
    },
    api.trial.getBudgetaryTrailCalc,
  );

  const renderTypeMenuItems = TYPES_ARRAY.map((el) => {
    return (
      <MenuItem key={el.name} value={el.name}>
        <Box
          display={"flex"}
          gap={"10px"}
          alignItems={"center"}
        >
          <ListItemIcon>{el.icon}</ListItemIcon>
          <Typography color={el.textColor}>
            {el.label}
          </Typography>
        </Box>
      </MenuItem>
    );
  });

  const handleTypeSelectChange = (event) => {
    const queryParams = qs.stringify(
      { ...query, type: event.target.value, page: 1 },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setSelectedType(event.target.value);
  };

  const handleClearTypeSelect = () => {
    const queryParams = qs.stringify(
      { ...query, type: undefined },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setSelectedType("");
  };

  const filters = getFilters(
    query.year,
    handleTypeSelectChange,
    handleClearTypeSelect,
    renderTypeMenuItems,
    selectedType,
  );

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleOpenCreateModal = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.BUDGETARY_TRIAL_CALC, { 
      theme: THEME_MODAL.W_555 
    });
  };

  const handleChangeOrderBy = (value) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  swrStore.addMutator(SWR_KEYS.getBudgetaryTrailCalc(), mutate);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters filters={filters} defaultSearch={query.search} />

        <Button
          sx={{
            whiteSpace: "nowrap",
            flexShrink: "0",
          }}
          variant='contained'
          startIcon={<AddIcon />}
          onClick={handleOpenCreateModal}
        >
          Добавить запись
        </Button>
      </Box>

      <Table<IBudgetaryTrailCalc, TBudgetaryTrailCalcKey>
        columns={BudgetaryTrailCalcColumns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={"Добавить запись"}
        textEmptyTableWithFilter={"Не найдено ни одной записи"}
        textEmptyTable={"Еще не создано ни одной записи"}
        onRowClick={handleOpenRightSidebar}
        emptyTableAddEntity={handleOpenCreateModal}
      />
    </>
  );
};

export default observer(BudgetaryTrailCalcTable);
