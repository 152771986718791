import React from "react";
import {
  PLANNING_TRIALS_TYPES,
  PLANNING_TRIALS_TYPES_COLOR
} from "@/pages/Planning/const";
import { Stack, Typography } from "@mui/material";
import { TPlanningTrialType } from "@/api/types";
import {
  HpIcon,
  OSIcon,
  LabIcon,
  PhytoIcon
} from "@/components/icons";

interface IProps {
  trialType: TPlanningTrialType;
}

const icons = {
  hp: <HpIcon sx={{fontSize: 20}} color="inherit"/>,
  oos: <OSIcon sx={{fontSize: 20}} color="inherit"/>,
  phyto: <PhytoIcon sx={{fontSize: 20}} color="inherit"/>,
  lab: <LabIcon sx={{fontSize: 20}} color="inherit"/>,
};

const TrialBlock: React.FC<IProps> = ({ trialType }) => {
  const trialTypeText = trialType
    ? PLANNING_TRIALS_TYPES[trialType]
    : "Неизвестно";
  const trialTypeIcon = trialType
    ? icons[trialType]
    : null;
  const trialTypeColors = trialType
    ? PLANNING_TRIALS_TYPES_COLOR[trialType]
    : "initial";

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{color: trialTypeColors}}
    >
      {trialTypeIcon}
      <Typography
        variant="body2"
        color="inherit"
      >
        {trialTypeText}
      </Typography>
    </Stack>
  );
};

export default TrialBlock;