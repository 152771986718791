import { TCostFundingSource } from "@/api/types";
import { FIELDS_NAME } from "./const";

export interface IFormValues {
  [FIELDS_NAME.EXPENSE]: {
    id: number;
    label: string;
  };
  [FIELDS_NAME.COM_CULTIVAR]: number;
  [FIELDS_NAME.YEAR]: number | null;
  [FIELDS_NAME.SUM]: string | null;
  [FIELDS_NAME.FUNDING_SOURCE]: TCostFundingSource;
}

export const FUNDING_SOURCE: { [key in TCostFundingSource]: string } = {
  central_apparatus: "central_apparatus",
  branch: "branch",
  customer: "customer",
} as const

export const FUNDING_SOURCE_NAMES: { [key in TCostFundingSource]: string } = {
  central_apparatus: "Центральный аппарат",
  branch: "Филиал",
  customer: "Заказчик",
} as const

export interface IAddCostProps {
  handleClose: () => void;
  modalProps: {
    id: number | string;
    title: string;
    isEdit?: boolean;
    onSubmit: () => void;
    type: 'string';
    costId?: number | string;
    initialValue?: IFormValues;
    expenseName?: string;
    isAddYear?: boolean;
  };
}

