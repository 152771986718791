import React, { Fragment } from "react";
import { Form, useFormikContext } from "formik";
import {
  Box,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME, IForm } from "../const";
import { DatePickerField } from "@/components/Fields";
import theme from "@/theme";
import FastRegexTextField
  from "@/components/FastRegexTextField";
import {
  RateAutocomplete
} from "@/components/Autocompletes";

/**
 * Форма создания/редактирования показателя качество сорта
 * */

interface IProps {
  id?: number;
  handleClose: () => void;
}

const AddLabRateForm: React.FC<IProps> = ({
  id,
  handleClose,
}) => {
  const form = useFormikContext<IForm>();

  const title = id
    ? "Редактировать показатель качества сорта"
    : "Добавить показатель качества сорта";

  return (
    <Fragment>
      <Box p={3}>
        <Typography sx={{ fontWeight: 400, fontSize: 12 }}
                    color={theme.palette.blackAndWhite.gray}>Лабораторные испытания</Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider/>
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={3}>
          <DatePickerField name={FIELDS_NAME.DATE}
                           required/>
          <RateAutocomplete name={FIELDS_NAME.RATE} required/>
          <FastRegexTextField name={FIELDS_NAME.VALUE}
                              regexType="decimal"
                              label="Значение" required/>
        </Stack>

        <Divider/>
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={handleClose}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={form.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon/>}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default AddLabRateForm;
