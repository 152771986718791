import {
    FiltersType
  } from "@/components/Filters/interfaces";
  import { IFilter, IValue } from "@/interfaces";
import { Dispatch, SetStateAction } from "react";
  
  interface IFilterWithParams extends IFilter {
    handleScroll?: (event: React.UIEvent<HTMLElement, UIEvent>) => void;
  }
  
  export const MACHINERY_TYPE: IValue[] = [
    {
      key: "tractor",
      label: "Трактор",
    },
    {
      key: "combine",
      label: "Комбайн",
    },
    {
      key: "attachment",
      label: "Навесное оборудование",
    },
  ];
  
  export const getFilters = (
    gsuTypeValue?: IValue[],
    selectedGsuTypeValue?: IValue,
    handleScroll?: (
      event: React.UIEvent<HTMLElement, UIEvent>,
    ) => void,
    selectedType?: any,
    setValue?: Dispatch<SetStateAction<string>>
  ): IFilterWithParams[] => {
    return [
      {
        name: "type",
        label: "Тип",
        type: FiltersType.autoComplete,
        selectedValue: selectedType,
        value: "",
        options: MACHINERY_TYPE,
        style: { width: "230px", flexGrow: 1 },
      },
      {
        name: "gsu",
        label: "ГСУ",
        type: FiltersType.autoComplete,
        selectedValue: selectedGsuTypeValue,
        handleScroll: handleScroll,
        options: gsuTypeValue,
        value: "",
        style: { minWidth: "230px", flexGrow: 1 },
        setValue: setValue
      },
    ];
  };