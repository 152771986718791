import theme from "@/theme";
import { TPlanningStatus } from "@/api/types";

import { IValue } from "@/interfaces";

export const PLANNING_HP_KEYS = {
  order__number: "order__number",
  cultivar: "cultivar",
  order__order_date: "order__order_date",
  аpproval: "аpproval",
  status: "status",
  editing: "editing",
} as const;

export const PLANNING_HP_HEADER_LABELS = {
  order__number: "Приказ",
  order__order_date: "Дата приказа",
  cultivar: "Культуры",
  status: "Статус",
};

export const PLANNING_HP_STATUS: {
  [key in TPlanningStatus]: string;
} = {
  draft: "Черновик",
  approved: "Утверждено",
};

export const PLANNING_HP_STATUS_NAME: {
  [key in TPlanningStatus]: string;
} = {
  draft: "draft",
  approved: "approved",
} as const;

export const PLANNING_STATUS_COLOR: {
  [key in TPlanningStatus]: string;
} = {
  draft: theme.palette.blackAndWhite.gray,
  approved: theme.palette.green.main,
};

export const PLANNING_STATUS_ICON: {
  [key in TPlanningStatus]: string;
} = {
  draft: "draft",
  approved: "approved",
};

export const EMPTY_LIST_PLANING_HP_TEXT =
  "Еще не создано ни одного плана";
export const EMPTY_LIST_PLANING_HP_TEXT_WITH_FILTERS =
  "По указанным параметрам планы не найдены";
export const ADD_PLANING_HP_TEXT = "Добавить план";

export const PLANNING_STATUS_INPUT: IValue[] = [
  {
    key: "draft",
    label: "Черновик",
  },
  {
    key: "approved",
    label: "Утверждено",
  },
];

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};
