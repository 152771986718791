import React from "react";
import {
  DateCells,
  NameAuthorCells,
  NumberCells,
  StatusCells,
  TypeCells,
} from "@/pages/Statement/components/StatementCellComponents";
import { Box, Typography } from "@mui/material";
import { IAddress } from "@/api/interfaces/responses";
import dayjs from "dayjs";
import theme from "@/theme";

/**
 * @component poitText
 * * компонент ячейки таблицы типа c текстом и кружочком сбоку
 * @param
 */
const PointerTextCell = (_: any): JSX.Element => {
  if (typeof _ === "string" && !_.length) _ = undefined;
  return (
    <Box>
      <Typography fontSize={14}>{_ ?? "-"}</Typography>
    </Box>
  );
};

const NumTextCell = (_: any, cell: any): JSX.Element => {
  const { number } = cell;

  return (
    <Box>
      <Typography
        fontSize={14}
        color={theme.palette.blackAndWhite.gray}
      >
        {number ?? "-"}
      </Typography>
    </Box>
  );
};

const DistinctTextCell = (
  _: any,
  cell: IAddress,
): JSX.Element => {
  const { district } = cell;
  return (
    <Box>
      <Typography fontSize={14}>
        {district?.name ?? "-"}
      </Typography>
    </Box>
  );
};

const CountryTextCell = (
  _: any,
  cell: IAddress,
): JSX.Element => {
  const { country } = cell;
  return (
    <Box>
      <Typography fontSize={14}>
        {country?.name ?? "-"}
      </Typography>
    </Box>
  );
};

const OrderDateTextCell = (
  _: any,
  cell: any,
): JSX.Element => {
  const { order_date } = cell;
  const formattedDate =
    dayjs(order_date).format("DD.MM.YYYY");

  return (
    <Box>
      <Typography fontSize={14}>
        {formattedDate ?? "-"}
      </Typography>
    </Box>
  );
};

const DateTextCell = (_: any, cell: any): JSX.Element => {
  const { confirmation_date } = cell;
  const date = dayjs(confirmation_date);
  const formattedDate = date.isValid()
    ? date.format("DD.MM.YYYY")
    : "-";
  return (
    <Box>
      <Typography fontSize={14}>
        {formattedDate ?? "-"}
      </Typography>
    </Box>
  );
};

const CultivarTextCell = (
  _: any,
  cell: any,
): JSX.Element => {
  const { cultivar } = cell;
  return (
    <Box>
      <Typography fontSize={14}>
        {cultivar?.name ?? "-"}
      </Typography>
    </Box>
  );
};

export {
  DateCells,
  NameAuthorCells,
  NumberCells,
  StatusCells,
  TypeCells,
  PointerTextCell,
  DistinctTextCell,
  CountryTextCell,
  OrderDateTextCell,
  DateTextCell,
  CultivarTextCell,
  NumTextCell,
};
