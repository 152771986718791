import { resInterface } from "@/api/interfaces";
import yup from "@/utils/yup";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    expenseId?: number;
    initialValues?: resInterface.IExpense;
  };
  onSubmit?: () => void;
}

export interface TForm {
  [EXPENSE_FIELD_NAME.NAME]: string,
}

export enum EXPENSE_FIELD_NAME {
  NAME = 'name'
}


export const mapFormToValue = (values: TForm) => {
  return {
    [EXPENSE_FIELD_NAME.NAME]: values?.name || '',
  };
};


/*Validation of the form*/

export const validationSchema = yup.object().shape({
  [EXPENSE_FIELD_NAME.NAME]: yup.string().trim().required(),
});
