import yup from "@/utils/yup";

export const FIELDS_NAME = {
  COMMENTARY: "commentary",
} as const;

export const defaultValues = {
  [FIELDS_NAME.COMMENTARY]: "",
};

export interface IFormik {
  [FIELDS_NAME.COMMENTARY]: string;
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.COMMENTARY]: yup.string().trim().required(),
});