import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell} from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { AGRICULTURAL_MACHINERY_KEYS, AGRICULTURAL_MACHINERY_LABELS } from "../const";

import { TAgriculturalMachineryKeys} from "../interfaces";
import { EditingCellAgrCulMachinery, GsuCell, TypeCell } from "../templates/AgriculturalMachinery/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const AgrCulMachineryColumns: IColumn<TAgriculturalMachineryKeys>[] = [
  {
    label: AGRICULTURAL_MACHINERY_LABELS.identification_number,
    key: AGRICULTURAL_MACHINERY_KEYS.identification_number,
    contentHeader: AGRICULTURAL_MACHINERY_LABELS.identification_number,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: AGRICULTURAL_MACHINERY_LABELS.name,
    key: AGRICULTURAL_MACHINERY_KEYS.name,
    contentHeader: AGRICULTURAL_MACHINERY_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: AGRICULTURAL_MACHINERY_LABELS.type,
    key: AGRICULTURAL_MACHINERY_KEYS.type,
    contentHeader: AGRICULTURAL_MACHINERY_LABELS.type,
    bodyCellProps: {
      render: TypeCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: AGRICULTURAL_MACHINERY_LABELS.location,
    key: AGRICULTURAL_MACHINERY_KEYS.location__plot__name,
    contentHeader: AGRICULTURAL_MACHINERY_LABELS.location,
    bodyCellProps: {
      render: GsuCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: AGRICULTURAL_MACHINERY_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellAgrCulMachinery,
      styles: cellButtons,
    },
  },
];
