import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Tab } from "@mui/material";

import { ROUTES_PATHS } from "@/apps/AppRouter/const";

import { StyledTabs, tabStyles } from "./style";

import { AW_DOC_NAVS } from "../const";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";
import { useStores } from "@/hooks";

/**
 * хедер для таблиц сх работ
 * @returns React.FC
 */

const HeaderAgriculturalWorkDoc: React.FC = () => {
  const location = useLocation();
  const { userStore } = useStores()
  const canViewCultivarField = userStore.isPermission(PERMISSIONS.agriculturalLabourCultivarField, PERMISSION_CRUD.view);
  const canViewSeedArrival = userStore.isPermission(PERMISSIONS.agriculturalLabourSeedArrival, PERMISSION_CRUD.view);
  const canViewSeedCultivarFieldVariety = userStore.isPermission(PERMISSIONS.agriculturalLabourCultivarFieldVariety, PERMISSION_CRUD.view);
  const [value, setValue] = React.useState(location.pathname);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const tabs = [
    {
      id: ROUTES_PATHS.agriculturalWorkDoc,
      value: ROUTES_PATHS.agriculturalWorkDoc,
      label: AW_DOC_NAVS.landUsePlan,
      to: ROUTES_PATHS.agriculturalWorkDoc,
      canView: canViewCultivarField
    },
    {
      id: ROUTES_PATHS.agriculturalWorkDocSeedAccounting,
      value: ROUTES_PATHS.agriculturalWorkDocSeedAccounting,
      label: AW_DOC_NAVS.seedAccounting,
      to: ROUTES_PATHS.agriculturalWorkDocSeedAccounting,
      canView: canViewSeedArrival
    },
    {
      id: ROUTES_PATHS.cultivarFieldVarietyList,
      value: ROUTES_PATHS.cultivarFieldVarietyList,
      label: AW_DOC_NAVS.varietyTesting,
      to: ROUTES_PATHS.cultivarFieldVarietyList,
      canView: canViewSeedCultivarFieldVariety
    },
    {
      id: ROUTES_PATHS.cultivarFieldVarietyLabList,
      value: ROUTES_PATHS.cultivarFieldVarietyLabList,
      label: AW_DOC_NAVS.cultivarFieldVarietyLab,
      to: ROUTES_PATHS.cultivarFieldVarietyLabList,
      canView: canViewSeedCultivarFieldVariety
    },
    {
      id: ROUTES_PATHS.testingExpertReview,
      value: ROUTES_PATHS.testingExpertReview,
      label: AW_DOC_NAVS.testingExpertReview,
      to: ROUTES_PATHS.testingExpertReview,
      canView: canViewSeedCultivarFieldVariety
    },
  ];

  const filteredTabs = tabs.filter((tab => tab.canView));

  useEffect(() => {
    if (value !== location.pathname) {
      setValue(location.pathname);
    }
  }, [location.pathname]);

  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
    >
      {
        filteredTabs.map(tab => (
          <Tab key={tab.id} component={NavLink}
               value={tab.value}
               label={tab.label}
               to={tab.to}
               sx={tabStyles}
          />
        ))
      }
    </StyledTabs>
  );
};

export default HeaderAgriculturalWorkDoc;
