import { StoreContext } from '@/contexts/StoreProvider';
import { useContext } from 'react';
import { RootStore } from '@/services';

export const useStores = (): RootStore => {
    const rootStore = useContext(StoreContext);

    if (!rootStore) {
        throw new Error("useStores must be used within a StoreProvider");
    }

    return rootStore;
};
