import { Dayjs } from "dayjs";
import { IFile } from "@/components/FileDropzone/const";
import yup from "@/utils/yup";
import { TBranch, TRegion } from "@/api/interfaces/requests";

export const STEP_LABELS = {
  PLAN: "План",
  PRODUCTION: "Производство",
  EXPENSES: "Расходы",
  INVESTMENTS: "Инвестиции",
  FINANCE: "Финансы",
  EFFICIENCY: "Эффективность",
};

export const STEP_TITLES = {
  PLAN: "Документы",
  PRODUCTION: "Производственный план",
  EXPENSES: "Производственные расходы филиала",
  INVESTMENTS: "Инвестиционный план",
  FINANCE: "Финансовый план",
  EFFICIENCY: "Показатели эффективности",
};

export const PLAN_FIELDS_NAME = {
  YEAR: "year", // год плана
  REGION: "region", // регион плана
  BRANCH: "branch", // филиал плана
  NAME: "name", // название плана,
  FILE_LIST: "file_list", // документы плана
} as const;

export const validationSchema = yup.object().shape({
  [PLAN_FIELDS_NAME.YEAR]: yup.string().required(),
  [PLAN_FIELDS_NAME.REGION]: yup.mixed().required(),
  [PLAN_FIELDS_NAME.BRANCH]: yup.mixed().required(),
  [PLAN_FIELDS_NAME.NAME]: yup.string().required(),
  [PLAN_FIELDS_NAME.FILE_LIST]: yup.array().nullable(),
});

export interface IPlanForm {
  [PLAN_FIELDS_NAME.YEAR]?: Dayjs;
  [PLAN_FIELDS_NAME.REGION]?: TRegion;
  [PLAN_FIELDS_NAME.BRANCH]?: TBranch;
  [PLAN_FIELDS_NAME.NAME]: string;
  [PLAN_FIELDS_NAME.FILE_LIST]?: IFile[];
}

export const QS_KEYS = {
  INNER_TAB: "inner_tab",
} as const;

export const QS_ROUTS_FINANCE = {
  CULTURE: {
    path: "culture",
  },
  EXPENSES: {
    path: "expenses",
  },
} as const;

export const QS_ROUTS_PRODUCTION = {
  CULTURE: {
    path: "culture",
  },
  COST: {
    path: "cost",
  },
} as const;

export interface IEfficiencyItem {
  id: string;
  year: number;
  label: string;
  value: string;
}
