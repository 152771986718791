import React, {
  ReactElement,
  useEffect,
  useMemo,
  useState
} from "react";
import useSWR, { SWRResponse } from "swr";
import AddIcon from "@mui/icons-material/Add";
import qs from "qs";
import {
  useLocation,
  useSearchParams
} from "react-router-dom";
import { Box, Button } from "@mui/material";

import Table from "@/apps/Table";
import {
  THEME_MODAL,
  VARIANT_MODAL
} from "@/apps/Modals/const";
import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";
import Filters from "@/components/Filters";

import { TStatemenKeys } from "../interfaces";
import {
  IPaginatedResult,
  IStatementList,
  IUserShort
} from "@/api/interfaces/responses";
import { IErrorResponse, IValue } from "@/interfaces";
import {
  IStatementQueryParams
} from "@/api/interfaces/requests";

import {
  useInfiniteScroll,
  useQuery,
  useStores
} from "@/hooks";

import { columns } from "../models";

import { isUndefined } from "@/utils/helpers";
import { getFilters } from "../utils";

import {
  KEYS_SIDEBAR_VARIANT_MODULES,
  PAGE_SIZE,
  SWR_KEYS
} from "@/const";
import {
  ADD_STATEMENT_TEXT,
  EMPTY_LIST_STATEMENT_TEXT,
  EMPTY_LIST_STATEMENT_TEXT_WITH_FILTERS,
  USERS_KEY
} from "../const";
import useRightSidebar from "@/hooks/useRightSidebar";
import { observer } from "mobx-react";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";

/**
 * Компонент, представляющий таблицу заявлений.
 * @returns {ReactElement}
 */
const StatementTable = (): ReactElement => {
  const {
    api,
    modalStore,
    queryStringSidebarCollector,
    swrStore,
    userStore
  } =
    useStores();
  const { handleOpen } = useRightSidebar();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const query = useQuery<IStatementQueryParams>();
  const [queryParams, setQueryParams] = useState<IStatementQueryParams>(query);
  // переменная которая показывает применены ли фильтры
  const isFilters: boolean = Object.keys(queryParams).length > 0;
  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering) ? query.ordering : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] = useState<string[]>(orgeringArray);
  const {
    data,
    error,
    isLoading,
    mutate
  }: SWRResponse<IPaginatedResult<IStatementList>, IErrorResponse> = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      date_registration_range_after: queryParams.date_range_after,
      date_registration_range_before: queryParams.date_range_before,
      search: queryParams.search,
      executor: queryParams.executor?.key,
      status: queryParams.status?.key,
      key: SWR_KEYS.getStatements()
    },
    api.statement.getStatements
  );

  const fetcher = (args) => {
    const { page, search } = args;
    return api.user.getUsers({
      page,
      page_size: PAGE_SIZE,
      search
    });
  };

  const getKey = (index: number) => {
    return {
      key: USERS_KEY,
      page: index + 1,
      search: queryParams.search_user
    };
  };

  const {
    data: usersData,
    handleScroll
  } = useInfiniteScroll<IUserShort>(
    getKey,
    fetcher,
    {
      revalidateFirstPage: false
    }
  );

  const users: IValue[] = usersData.map((el) => {
    return { label: el.full_name, key: el.id };
  });

  const filters = getFilters(
    [queryParams.date_range_after, queryParams.date_range_before],
    users,
    handleScroll,
    query.status,
    query.executor
  );
  const handleOpenModal = () => {
    modalStore.open(VARIANT_MODAL.CREATE_STATEMENT, {
      theme: THEME_MODAL.W_555
    });
  };

  const handleDownloadStatement = () => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_STATEMENT, { theme: THEME_MODAL.W_555 });
  };

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" }
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value: TStatemenKeys[]) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" }
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   *
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */
  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number
  ) => {
    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent: KEYS_SIDEBAR_VARIANT_MODULES.STATEMENT,
      module_id: id
    });

    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.STATEMENT,
      modalProps: {
        id
      }
    });
    setSearchParams(str);
  };

  useEffect(() => {
    setQueryParams(query);
  }, [location.search]);

  swrStore.addMutator(SWR_KEYS.getStatements(), mutate);

  const isAddStatement = userStore.isPermission(
    PERMISSIONS.registrationStatement,
    PERMISSION_CRUD.add
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px"
        }}
      > <Box sx={{
        display: "flex",
        alignItems: "center",
        gap: "15px",
        flexWrap: "wrap",
      }}>
        <Filters filters={filters}
                 defaultSearch={query.search}/>
        <Button
          color="blue"
          onClick={handleDownloadStatement}
          variant="outlined"
          startIcon={<FileDownloadOutlinedIcon/>}
        >
          Загрузить заявки
        </Button>
      </Box>

        {isAddStatement && (
          <Button
            sx={{
              whiteSpace: "nowrap",
              flexShrink: "0"
            }}
            variant="contained"
            startIcon={<AddIcon/>}
            onClick={handleOpenModal}
          >
            Добавить заявку
          </Button>
        )}
      </Box>

      <Table<IStatementList, TStatemenKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={ADD_STATEMENT_TEXT}
        isPermissionAdd={isAddStatement}
        textEmptyTableWithFilter={EMPTY_LIST_STATEMENT_TEXT_WITH_FILTERS}
        textEmptyTable={EMPTY_LIST_STATEMENT_TEXT}
        onRowClick={handleOpenRightSidebar}
        emptyTableAddEntity={handleOpenModal}
      />
    </>
  );
};

export default observer(StatementTable);
