import React from "react";

import { FieldArray } from "formik";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Grid, Stack } from "@mui/material";
import theme from "@/theme";
import { CloseIcon } from "@/components/icons";
import { DEPARTMENT_FIELD_NAME} from "../../const";
import { v4 as uuidv4 } from "uuid";
import FastRegexTextField from "@/components/FastRegexTextField";


export const PhoneField: React.FC = () => {
  return (
    <>
      <FieldArray name={DEPARTMENT_FIELD_NAME.PHONES}>
        {({ name, form, push, remove }) => {
          return (
            <Stack>
              {form.values[name].map((i, index) => {
                return (
                  <Box
                    key={i.id}
                    display="flex"
                    flexWrap="nowrap"
                    justifyContent="space-between"
                    flexDirection={"row"}
                    gap='5px'
                    sx={{
                      border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
                      borderRadius: "4px",
                      padding: "10px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <Grid container spacing={3} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                      <Grid item >
                        <FastRegexTextField
                          name={`${name}.${index}.phone`}
                          size="small"
                          label={`Телефон ${index + 1}`}
                          InputProps={{
                            sx: {
                              width: "210px",
                            },
                          }} />
                      </Grid>
                      <Grid item >
                        <FastRegexTextField 
                          name={`${name}.${index}.name`}
                          size="small"
                          label={`Название ${index + 1}`}
                          InputProps={{
                            sx: {
                              width: "210px",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      sx={{
                        minWidth: "25px",
                        ml: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => remove(index)}
                      startIcon={<CloseIcon />}
                    />
                  </Box>
                );
              })}

              <Button
                color='blue'
                size='large'
                startIcon={<AddOutlinedIcon />}
                variant='outlined'
                onClick={() => push({ email: '', name: '', id: uuidv4() })}
                fullWidth
              >
                Добавить телефон
              </Button>
            </Stack>
          )
        }}
      </FieldArray>
    </>
  );
};


