import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import AuthLayout from "@/pages/auth/components/AuthLayout/AuthLayout";

export const PasswordSuccess = () => {
  return (
    <AuthLayout>
      <Box>
        <Box style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0 36px"
        }}>
          <Box>
            <CheckCircleIcon
              sx={{ fontSize: 120, color: "#E6E6F4" }}/>
          </Box>
          <Typography fontWeight={500} variant="h2"
                      fontSize={24}>
            Ваш пароль был успешно изменен!
          </Typography>
          <Box marginTop={4}>
            <Link to={ROUTES_PATHS.login}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                size="large"
              >
                Продолжить
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};