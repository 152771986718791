import { TextField } from "@mui/material";
import { SearchIcon } from "@/components/icons";
import { InputAdornment } from "@mui/material";
import React from "react";
import theme from "@/theme";

interface SearchProps {
  onSearch: (value?: string) => void;
  value?: string;
}

/**
 * инпут для поля search
 * @param filter - информация о фильтре (имя, тип и тд.), в том числе коллбек на запрос следующей страницы
 * @param onChange - коллбек работающий в момент изменения данных
 * @returns
 */
const Search: React.FC<SearchProps> = ({ onSearch, value }) => {
  const handleChange = (event: { target: { value: string } }) => {
    const hasSearch =
      event?.target.value !== "" ? event?.target.value : undefined;

    onSearch(hasSearch);
  };

  return (
    <TextField
      value={value}
      placeholder="Найти справочник..."
      onChange={handleChange}
      fullWidth={true}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" sx={{ marginRight: "-5px" }}>
            <SearchIcon />
          </InputAdornment>
        ),
        sx: {
          height: "70px"
        }
      }}
      sx={{
        fieldset: {
          border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
          borderRadius: 0
        }
      }}
    />
  );
};

export default Search;
