import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { Box, Typography } from "@mui/material";
import { useStores, useQuery } from "@/hooks";
import ProductionTable from "./ProductionTable";
import Loader from "@/components/Loader";
import { isUndefined } from "@/utils/helpers";
import { SWR_KEYS } from "@/const";
import YearDatePicker from "@/components/YearDatePicker";

const ProductionActivity = () => {
  const { api } = useStores();
  const { id } = useParams();
  const query = useQuery<any>();

  const fetcher = ([id]) => {
    if (isUndefined(id)) return null;
    return api.regulatoryInfo.getBranchMainProductionById(id, {
      year: query?.year
    });
  };

  const key = SWR_KEYS.getBranchMainProductionById(id as string);

  const { data: renderData, error, isLoading } = useSWR(
    [id, query?.year, key],
    fetcher, {}
  );

  if (error) {
    return <div>Ошибка загрузки данных</div>;
  }

  return (
    <Box>
      <Typography mb={2} mt={2} variant="h3">
        Производственная деятельность
      </Typography>
      <YearDatePicker/>
      {
        (isLoading || !renderData) ? <Loader/> :
          <ProductionTable data={renderData.summary}/>
      }
    </Box>
  );
};

export default ProductionActivity;
