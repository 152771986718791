import { useContext } from "react";
import { StepperContext } from "../context";

const useStepperContext = () => {
  const stepperContext = useContext(StepperContext);

  if (!stepperContext) {
    throw new Error(
      "useStepperContext has to be used within <SteppertContext.Provider>"
    );
  }
  return stepperContext;
};

export default useStepperContext;