import {
  Box,
  Typography,
  Divider,
  Button,
  Chip,
  TextField,
} from "@mui/material";
import React, { ChangeEvent } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { useStores } from "@/hooks";
import {
  IUser,
  IUserShort,
} from "@/api/interfaces/responses";

import { observer } from "mobx-react";
import { FIELDS_NAME } from "./const";
import { SWR_KEYS } from "@/const";
import { useFormik } from "formik";
import { AxiosError } from "axios";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { IFormValues } from "./types";
import { OFFBUDGET_STATUS_ICON } from "@/pages/OffBudget/const";
import AddChip from "@/components/Chips/AddChip";

interface IProps {
  handleClose: () => void;
  modalProps: {
    id: number;
    executor?: IUserShort | null;
    title?: string;
    initialValue?: string;
    status?: string;
    onClose: () => void;
  };
}

const ReworkOBModal = ({
  modalProps,
  handleClose,
}: IProps) => {
  const {
    executor = null,
    title = "Отправить план на доработку",
    id,
    status,
  } = modalProps;

  const { api, modalStore, swrStore, toastStore } =
    useStores();

  const mutate =
    swrStore.mutators[
      SWR_KEYS.getOffBudgetById(modalProps.id)
    ];

  const initialValues = Object.assign(
    {
      [FIELDS_NAME.EXECUTOR]: null,
      [FIELDS_NAME.REMARKS]: null,
    },
    {
      executor: modalProps.executor,
      remarks: modalProps.initialValue
    },
  );

  const formik = useFormik<IFormValues>({
    initialValues: initialValues,
    validateOnMount: true,
    onSubmit: (values) => {
      api.offBudget
        .editRemark(id, {
          executor: Number(values.executor?.id),
          remarks: values.remarks as string,
          status: OFFBUDGET_STATUS_ICON.revision,
          
        })
        .then(() => {
          modalStore.pop();
        })
        .catch((error: AxiosError) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        })
        .finally(() => {
          mutate();
          modalStore.pop();
        });
    },
  });

  const handleChangeUser = () => {
    modalStore.close();
    const handleAccept = (value: IUser): void => {
      modalStore.close();
      modalStore.open(VARIANT_MODAL.REWORK_OB_MODAL, {
        executor: value,
        id,
        title,
        status,
        initialValue: formik.values[FIELDS_NAME.REMARKS],
      });
    };
    modalStore.open(VARIANT_MODAL.SELECT_OB_USERS, {
      executor,
      title,
      handleAccept,
    });
  };

  const handleAddRemark = (
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    formik.setFieldValue(
      FIELDS_NAME.REMARKS,
      e.target.value,
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {title}
        </Typography>
      </Box>
      <Divider />
      <Box p={3}>
        <Typography fontWeight={500}>
          Выберите сотрудника, которому отправите план на
          доработку
        </Typography>
        {executor?.full_name ? (
          <Chip
            label={executor.full_name}
            size="small"
            variant="outlined"
            onDelete={handleChangeUser}
          />
        ) : (
          <AddChip onClick={handleChangeUser} />
        )}
      </Box>

      <Box p={3}>
        <TextField
          multiline
          size="medium"
          value={formik.values[FIELDS_NAME.REMARKS]}
          minRows={5}
          onChange={handleAddRemark}
          label="Замечания по плану"
          sx={{
            "& .MuiInputBase-root": {
              width: "750px",
            },
          }}
        />
      </Box>

      <Divider />

      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          disabled={
            !formik.values[FIELDS_NAME.REMARKS]?.length &&
            !modalProps.initialValue?.length
          }
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          type="submit"
        >
          Отправить
        </Button>
      </Box>
    </form>
  );
};

export default observer(ReworkOBModal);
