import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider, ruRU } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import { HelmetProvider } from "react-helmet-async";
import {
  applyPolyfills,
  defineCustomElements,
} from "@garpix/garpix-web-components/loader";
import "@garpix/garpix-web-components/dist/garpix-web-components/garpix-web-components.css";
import { BrowserRouter } from "react-router-dom";
import { StoreProvider } from "./contexts";
import App from "./App";
import { RootStore } from "./services";
import theme from "./theme";
import { runtimeConfig } from "@/config";
import { TOKEN_KEYS } from "./const";
import { getCookie } from "./utils/cookie";
import Media from "@/apps/Respons";
import { SWRConfig } from "swr";

const container = document?.getElementById("root") || document?.body;

const API_URL = runtimeConfig.REACT_APP_API_DOMAIN;

export const initializeRootStore = async () => {
  const auth_token = getCookie(TOKEN_KEYS.AUTH_TOKEN_KEY);

  const axiosParams = auth_token
    ? {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      }
    : {};

  const rootStore = new RootStore(API_URL);

  try {
    const res = await rootStore.api.user?.getCurrentUser(
      undefined,
      axiosParams
    );
    if (res) {
      rootStore.userStore.setupUserDataFromReq(res.data);
    }
  } catch (error) {
    console.error("Error while fetching user data:", error);
  }

  return rootStore;
};

const hydrate = async () => {
  const rootStore = await initializeRootStore();

  const mainElement = (
    <StoreProvider store={rootStore}>
      <ThemeProvider theme={theme}>
        <SWRConfig value={{ provider: () => new Map() }}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={
              ruRU.components.MuiLocalizationProvider.defaultProps.localeText
            }
            adapterLocale="ru"
          >
            <Media.Responsive>
              <HelmetProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </HelmetProvider>
            </Media.Responsive>
          </LocalizationProvider>
        </SWRConfig>
      </ThemeProvider>
    </StoreProvider>
  );

  ReactDOM.hydrateRoot(container, mainElement);

  applyPolyfills().then(() => {
    defineCustomElements();
  });

  if (module.hot) {
    module.hot.accept();
  }
};

hydrate();
