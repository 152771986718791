import React, { useState } from "react";
import { useFormik } from "formik";
// import { useNavigate } from "react-router";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import { PasswordField } from "@/components/form/base/PasswordField";
// import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import Yup from "@/utils/yup";
import { checkFieldError, checkTextErrorField } from "@/utils/formikHelpers";
import { useStores } from "@/hooks";
import { errorToString } from "@/utils/helpers";
import { AxiosError } from "axios";
import theme from "@/theme";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";

/**
 * Validation schema using Yup for form validation.
 */
const validationSchema = Yup.object().shape({
  old_password: Yup.string().required("Введите старый пароль"),
  new_password: Yup.string()
    .notOneOf([Yup.ref("old_password")], "Пароли не должны совпадать")
    .required("Повторите новый пароль"), //Yup.string().required("Введите новый пароль"),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref("new_password")], "Пароли должны совпадать")
    .required("Повторите новый пароль")
});

/**
 * Interface for the ChangePass component's props.
 */
interface IChangePass {
  handleClose: () => void;
}

/**
 * ChangePass component for updating the password.
 * @param {IChangePass} props - Component props.
 * @returns {React.FC<IChangePass>} - Rendered component.
 */
const ChangePass: React.FC<IChangePass> = ({ handleClose }) => {
  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false);
  const { api, userStore, modalStore } = useStores();

  /**
   * Formik hook for managing form state and validation.
   */
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_new_password: ""
    },
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      setIsDisabledSaveBtn(true);
      api.user
        .postChangePassword({
          username: userStore.username,
          password: values.old_password,
          new_password: values.new_password
        })
        .then(() => {
          // initializeRootStore();
          // formikHelpers.resetForm();
          handleSuccessChangePass();
        })
        .catch(
          (
            e: AxiosError<{
              change_password?: boolean;
            }>
          ) => {
            const error = errorToString(e);
            formikHelpers.setFieldError("error", error);
          }
        )
        .finally(() => {
          formik.setSubmitting(false);
          setIsDisabledSaveBtn(false);
        });
    }
  });

  /**
   * Handles cancel action by closing the form and navigating to requests.
   */
  const handleCancel = () => {
    handleClose();
  };

  const handleSuccessChangePass = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.pop();
    modalStore.open(VARIANT_MODAL.SUCCESS_CHANGE_PASS, {
      theme: THEME_MODAL.W_555
    });
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Box>
        <Box p={3}>
          <Typography variant="h2" sx={{ lineHeight: "32px", }} component="h2">
            Изменить пароль
           </Typography>
        </Box>
        <Divider />
        <Box p={3}>
          <Stack spacing={3}>
            {["old_password", "new_password", "confirm_new_password"].map(
              (fieldName) => (
                <PasswordField
                  fullWidth
                  key={fieldName}
                  id={`outlined-adornment-${fieldName}`}
                  name={fieldName}
                  label={
                    fieldName === "old_password"
                      ? "Старый пароль"
                      : fieldName === "new_password"
                      ? "Новый пароль"
                      : "Повторите новый пароль"
                  }
                  size="small"
                  placeholder={
                    fieldName === "old_password"
                      ? "Введите старый пароль"
                      : fieldName === "new_password"
                      ? "Введите новый пароль"
                      : "Повторите новый пароль"
                  }
                  error={checkFieldError(formik, fieldName)}
                  helperText={checkTextErrorField(formik, fieldName)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[fieldName]}
                  disabled={formik.isSubmitting}
                />
              )
            )}
            {checkFieldError(formik, "error") && (
              <Box sx={{ color: theme.palette.error.darkRed }}>
                {checkFieldError(formik, "error")}
              </Box>
            )}
          </Stack>
        </Box>
        <Divider />
        <Box p={3} display="flex" justifyContent="space-between">
          <Button
            variant="text"
            color="red"
            onClick={handleCancel}
            disabled={formik.isSubmitting}
          >
            Отмена
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            disabled={!formik.isValid || isDisabledSaveBtn}
            startIcon={
              formik.isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <CheckIcon />
              )
            }
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ChangePass;
