import React, { useEffect, useState } from "react";

import { Field, useFormikContext } from "formik";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { EMPLOYEE_FIELD_NAME, IEmployeePhone, TForm } from "../../../const";
import { Box, Button, Grid, TextField, FormControl } from "@mui/material";
import theme from "@/theme";
import { CloseIcon } from "@/components/icons";

type TKey = "name" | "phone";

export const PhoneField: React.FC = () => {
  const form = useFormikContext<TForm>();
  const [phones, setPhones] = useState<IEmployeePhone[]>(form.values.phones);

  useEffect(() => {
    setPhones(form.values.phones);
  }, [form.values.phones]);

  const handleAdd = () => {
    form.setFieldValue(EMPLOYEE_FIELD_NAME.PHONES, [
      ...form.values.phones,
      { id: form.values.phones.length + 1, 
        phone: "", 
        name: "" 
      },
    ]);
  };

  const deletePhone = (i: IEmployeePhone) => {
    form.setFieldValue(
      EMPLOYEE_FIELD_NAME.PHONES,
      form.values.phones.filter((a) => a.id !== i.id),
    );
  };

  const handleBlur = (
    event: React.FocusEvent<
      HTMLInputElement | HTMLTextAreaElement
    >,
    index: number,
    key: TKey,
  ) => {
    event.preventDefault();
    const nextPhones = form.values.phones.map((c, i) => {
      if (i === index) {
        return {
          ...c,
          [key]: event.target.value,
        };
      } else {
        return c;
      }
    });
    form.setFieldValue(EMPLOYEE_FIELD_NAME.PHONES, nextPhones);
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement
    >,
    index: number,
    key: TKey,
  ) => {
    event.stopPropagation();
    const nextPhones = form.values.phones.map((c, i) => {
      if (i === index) {
        return {
          ...c,
          [key]: event.target.value,
        };
      } else {
        return c;
      }
    });
    setPhones(nextPhones);
  };

  return (
    <>
      {form.values.phones.map((i, index) => {
        return (
          <Box
            key={i.id}
            display="flex"
            flexWrap="nowrap"
            justifyContent="space-between"
            flexDirection={"row"}
            gap='5px'
            sx={{
              border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
              borderRadius: "4px",
              padding: "10px",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <Grid container spacing={3} sx={{display: 'flex', flexWrap: 'nowrap'}}>
              <Grid item >
                <Field
                  name={`${EMPLOYEE_FIELD_NAME.PHONES}.${index}.phone`}
                >
                  {({ field, meta }) => (
                    <FormControl>
                      <TextField
                        {...field}
                        size="small"
                        value={phones[index]?.phone}
                        onBlur={(event) =>
                          handleBlur(event, index, "phone")
                        }
                        label={`Телефон ${index + 1}`}
                        onChange={(e) =>
                          handleChange(e, index, "phone")
                        }
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        InputProps={{
                          sx: {
                            width: "210px",
                          },
                        }}
                      />
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item >
                <Field
                  name={`${EMPLOYEE_FIELD_NAME.PHONES}.${index}.name`}
                >
                  {({ field, meta }) => (
                    <FormControl>
                      <TextField
                        {...field}
                        size="small"
                        sx={{ width: "auto" }}
                        value={phones[index]?.name}
                        onBlur={(event) =>
                          handleBlur(event, index, "name")
                        }
                        label={`Название ${index + 1}`}
                        onChange={(e) =>
                          handleChange(e, index, "name")
                        }
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        InputProps={{
                          sx: {
                            width: "210px",
                          },
                        }}
                      />
                    </FormControl>
                  )}
                </Field>
              </Grid>
            </Grid>
            <Button
              sx={{
                minWidth: "25px",
                ml: "10px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => deletePhone(i)}
              startIcon={<CloseIcon />}
            />
          </Box>
        );
      })}

      <Button
        color='blue'
        size='large'
        startIcon={<AddOutlinedIcon />}
        variant='outlined'
        onClick={handleAdd}
        fullWidth
      >
        Добавить телефон
      </Button>
    </>
  );
};


