import React from "react";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { resInterface } from "@/api/interfaces";
import { IUniqueRemark } from "@/api/interfaces/responses";
import { PAGE_SIZE } from "@/const";
import {
  PERMISSION_CRUD,
  PERMISSIONS,
} from "@/premissions";

export interface IProps {
  _key: string;
  handleClose: () => void;
  modalProps: {
    id: number;
    remarkIds?: number[];
    remarkType: string;
    initialValue?: IUniqueRemark[];
    handleAccept: (values: IUniqueRemark[]) => void;
    fromAdd?: boolean;
    fromCancel?: boolean;
  };
}

const AddRemarks: React.FC<IProps> = ({
  _key,
  handleClose,
  modalProps,
}) => {
  const { modalStore, api, userStore } = useStores();

  const fetcher = (args) => {
    const { page, search, exclude_ids, first_ids } = args;
    return api.statement.getUniqueRemarks({
      remark_type: modalProps.remarkType,
      statement_id: modalProps.id,
      page,
      page_size: PAGE_SIZE,
      search: search,
      exclude_ids,
      first_ids
    });
  };

  const handleAdd = (
    payload: resInterface.IUniqueRemark[],
  ) => {
    modalStore.open(VARIANT_MODAL.REMARK, {
      handleAccept: (value: resInterface.IUniqueRemark) => {
        modalStore.setModalPropsByKey(_key, {
          ...modalProps,
          initialValue: [...payload, value],
        });
      },
      fromAdd: Boolean(modalProps.fromAdd),
      fromCancel: Boolean(modalProps.fromCancel),
    });
  };

  const getLabel = (el: IUniqueRemark) => {
    return el.name;
  };

  const isAddRemark = userStore.isPermission(
    PERMISSIONS.registrationRemark,
    PERMISSION_CRUD.add,
  );

  const props = {
    ...modalProps,
    addButtonLabel: "Добавить замечание",
    title: "Выберите замечания",
    isDisabledBtnAdd: isAddRemark,
    tooltipText: "У вас недостаточно прав",
    handleAdd,
    fetcher,
    getLabel,
    q: {
      exclude_ids: (Array.isArray(modalProps.remarkIds) && modalProps.remarkIds.length) ? modalProps.remarkIds.toString() : undefined,
      first_ids: modalProps?.initialValue
        ? modalProps.initialValue
            .map((el) => el.id)
            .toString()
        : undefined,
    },
  };

  return (
    <AddEntities<resInterface.IUniqueRemark>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default AddRemarks;
