import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell} from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { PLOT_KEYS, PLOT_LABELS } from "../const";

import { TPlotKey } from "../interfaces";
import { StaffCell } from "../templates/Departments/cells";
import { BranchCell } from "../templates/Districts/cells";
import { DistrictCell, EditingCellPlot, StatusCell } from "../templates/Plot/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const PlotColumns: IColumn<TPlotKey>[] = [
  {
    label: PLOT_LABELS.name,
    key: PLOT_KEYS.name,
    contentHeader: PLOT_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: PLOT_LABELS.branch,
    key: PLOT_KEYS.branch,
    contentHeader: PLOT_LABELS.branch,
    bodyCellProps: {
      render: BranchCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: PLOT_LABELS.district,
    key: PLOT_KEYS.district,
    contentHeader: PLOT_LABELS.district,
    bodyCellProps: {
      render: DistrictCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: PLOT_LABELS.staff_director,
    key: PLOT_KEYS.staff_director,
    contentHeader: PLOT_LABELS.staff_director,
    bodyCellProps: {
      render: StaffCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: PLOT_LABELS.status,
    key: PLOT_KEYS.status,
    contentHeader: PLOT_LABELS.status,
    bodyCellProps: {
      render: StatusCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: PLOT_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellPlot,
      styles: cellButtons,
    },
  },
];
