import {
  apiInterface,
  reqInterface,
  resInterface
} from "@/api/interfaces";
import AbstractApi from "../../abstract";
import {
  IAgreement,
  IPaginatedResult
} from "@/api/interfaces/responses";
import { TPlanningTrialType } from "@/api/types";
import { getCookie } from "@/utils/cookie";
import { TOKEN_KEYS } from "@/const";
import { QueryParams } from "@/api/abstract/types";
import {
  TRemarkStatus
} from "@/apps/RightSidebar/components/Remarks/interfaces";
import { IDistribution } from "@/api/interfaces/requests";

class StatementApi implements apiInterface.IStatementApi {
  ctx: AbstractApi;

  constructor(ctx: AbstractApi) {
    this.ctx = ctx;
  }

  getStatements = async (
    params: QueryParams<unknown> | undefined,
  ): Promise<
    IPaginatedResult<resInterface.IStatementList>
  > => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IStatementList>
    >("/registration/statement/", {
      ...params,
    });
    return res.data;
  };

  getSubjects = async (params: reqInterface.ISubjectList) => {
    const res = await this.ctx.get<
      reqInterface.ISubjectList,
      IPaginatedResult<resInterface.ISubject>
    >("/registration/subject/", params);

    return res.data;
  };

  getSubjectById = async (id: number) => {
    const res = await this.ctx.get<unknown, resInterface.ISubjectFull>(
      `/registration/subject/${id}/`
    );
    return res.data;
  };

  postSubject = async (params: reqInterface.ISubject) => {
    const res = await this.ctx.post<
      reqInterface.ISubject,
      resInterface.ISubjectFull
    >(`/registration/subject/`, params);
    return res.data;
  };

  updateSubject = async (
    id: number,
    params?: Partial<reqInterface.ISubject>
  ) => {
    const res = await this.ctx.patch<
      Partial<reqInterface.ISubject>,
      resInterface.ISubjectFull
    >(`/registration/subject/${id}/`, params);
    return res.data;
  };

  deleteSubject = async (
    id: number | string
  ) => {
    const res = await this.ctx.delete<
    unknown,
    Promise<resInterface.ISubjectFull>
    >(`/registration/subject/${id}`);
    return res.data;
  };

  addStatement = async (
    param: reqInterface.ICreateState,
  ) => {
    const res = await this.ctx.post<
      reqInterface.ICreateState,
      resInterface.IStatement
    >("/registration/statement/", param);
    return res.data;
  };

  getStatementById = async (id: number) => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IStatement
    >(`/registration/statement/${id}/`);
    return res.data;
  };

  patchStatementById = async (
    id: number,
    params?: Partial<reqInterface.IStatementReq>,
  ) => {
    const res = await this.ctx.patch<
      Partial<reqInterface.IStatementReq>,
      resInterface.IStatement
    >(`/registration/statement/${id}/`, params);
    return res.data;
  };

  deleteStatementById = async (
    id: number | string,
    params: Partial<reqInterface.IStatementReq>,
  ) => {
    const res = await this.ctx.delete<
      Partial<reqInterface.IStatementReq>,
      resInterface.IStatement
    >(`/registration/statement/${id}`, params);
    return res.data;
  };

  checkStatementValidityById = async (
    id: number | string,
  ) => {
    const res = await this.ctx.post<
      unknown,
      resInterface.IStatement
    >(`/registration/statement/${id}/validation_sign/`, {});
    return res.data;
  };

  addStatementFileById = async (
    id: number,
    params: FormData,
  ) => {
    const res = await this.ctx.post<
      FormData,
      resInterface.IStatementFile
    >(`/registration/statement/${id}/add_file/`, params);
    return res.data;
  };
  deleteStatementFile = async (
    statementId: string | number,
    fileId: string | number,
  ) => {
    await this.ctx.delete(
      `/registration/statement/${statementId}/delete_file/${fileId}/`,
    );
  };

  postCopyStatement = async (
    id: string | number,
    data: { type: string; registration_date: string },
  ) => {
    const res = await this.ctx.post<
      unknown,
      resInterface.IStatement
    >(
      `/registration/statement/${id}/copy_statement/`,
      data,
    );
    return res.data;
  };

  getRemarksByStatementId = async (
    params,
  ): Promise<IPaginatedResult<resInterface.IRemark>> => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IRemark>
    >(`/registration/remark_statement/`, params);
    return res.data;
  };

  patchRemarkStatus = async (
    id: string | number,
    status: TRemarkStatus,
  ) => {
    const res = await this.ctx.patch<
      unknown,
      resInterface.IRemark
    >(`/registration/remark_statement/${id}/`, { status });
    return res.data;
  };

  postRemarksByStatementId = async (
    remarks: number[],
    statement: number,
    status: string,
  ) => {
    const res = await this.ctx.post<
      unknown,
      resInterface.IRemark
    >(`/registration/remark_statement/`, {
      remarks,
      statement,
      status,
    });
    return res.data;
  };

  getUniqueRemarks = async (
    params,
  ): Promise<
    IPaginatedResult<resInterface.IUniqueRemark>
  > => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IUniqueRemark>
    >(`/registration/remark/`, params);
    return res.data;
  };

  deleteRemarkById = async (remarkId: string | number) => {
    await this.ctx.delete(
      `/registration/remark_statement/${remarkId}/`,
    );
  };

  getDownloadDocRegNotice = async (id: string | number) => {
    const res = await this.ctx.get<unknown, Blob>(
      `/registration/statement/${id}/download_registration_notice/`,
      {},
    );
    return res.data;
  };

  getTrialContext = async <T extends TPlanningTrialType>(
    id: string | number,
    type: T,
  ) => {
    const res = await this.ctx.get<
      {
        type_trial: T;
      },
      resInterface.TTrialContextRes<T>
    >(`/registration/statement/${id}/get_trial_context/`, {
      type_trial: type,
    });
    return res.data;
  };

  downloadFileFromBlob = async (
    id: number,
    endpoint: string,
    nameDoc: string,
    method = "GET",
  ) => {
    const accessToken = getCookie(
      TOKEN_KEYS.AUTH_TOKEN_KEY,
    );
    const url = `${this.ctx.MAIN_URL}/registration/statement/${id}/${endpoint}/`;

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();

        // Создаем URL для скачивания файла
        const fileUrl = URL.createObjectURL(blob);

        // Создаем ссылку на элемент <a> для скачивания файла
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = `${nameDoc}.docx`; // Устанавливаем имя файла для скачивания
        document.body.appendChild(link);

        // Кликаем на ссылку для скачивания файла
        link.click();

        // Удаляем ссылку на элемент <a>
        document.body.removeChild(link);

        // Освобождаем URL
        URL.revokeObjectURL(fileUrl);
        return true;
      } else {
        console.error(
          `Ошибка при скачивании файла. Статус: ${response.status}`,
        );
        return false;
      }
    } catch (error) {
      console.error("Произошла ошибка:", error);
      return false;
    }
  };

  getDuties = async (
    params,
  ): Promise<IPaginatedResult<resInterface.IDuty>> => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IDuty>
    >("/registration/duty/", {
      ...params,
    });
    return res.data;
  };

  getDutyById = async (
    id: string | number,
  ) => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IDuty
    >(`/registration/duty/${id}/`,);
    return res.data;
  };

  deleteDutyById = async (id: number | string) => {
    const res = await this.ctx.delete<unknown, resInterface.IDuty>(`/registration/duty/${id}/`)
    return res.data
  }

  postDuty = async (params: reqInterface.IDuty) => {
    const res = await this.ctx.post<reqInterface.IDuty, resInterface.IDuty>("/registration/duty/", params)
    return res.data
  }

  patchDuty = async (id: number | string, params: reqInterface.IDuty) => {
    const res = await this.ctx.patch<reqInterface.IDuty, resInterface.IDuty>(`/registration/duty/${id}/`, params)
    return res.data
  }
  getStatementDuties = async (
    params,
  ): Promise<
    IPaginatedResult<resInterface.IDutyStatement>
  > => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IDutyStatement>
    >("/registration/statement_duties/", {
      ...params,
    });
    return res.data;
  };
  patchStatementDutiesById = async (
    id: number,
    params?: Partial<resInterface.IDutyStatement>,
  ) => {
    const res = await this.ctx.patch<
      Partial<resInterface.IDutyStatement>,
      resInterface.IDutyStatement
    >(`/registration/statement_duties/${id}/`, params);
    return res.data;
  };
  deleteStatementDutiesById = async (
    id: number | string,
    params: Partial<resInterface.IDutyStatement>,
  ) => {
    const res = await this.ctx.delete<
      Partial<resInterface.IDutyStatement>,
      resInterface.IDutyStatement
    >(`/registration/statement_duties/${id}/`, params);
    return res.data;
  };
  addStatementDuties = async (params: {
    duty: number;
    statement: number;
  }): Promise<
    IPaginatedResult<resInterface.IDutyStatement>
  > => {
    const res = await this.ctx.post<
      unknown,
      IPaginatedResult<resInterface.IDutyStatement>
    >("/registration/statement_duties/", {
      ...params,
    });
    return res.data;
  };

  getDistributionList = async (
    params,
  ): Promise<
    IPaginatedResult<resInterface.IDistributionStatement>
  > => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IDistributionStatement>
    >("/registration/distribution_list/", {
      ...params,
    });
    return res.data;
  };

  getDistributionById = async (
    idDistribution: number,
  ): Promise<resInterface.IDistributionStatement> => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IDistributionStatement
    >(
      `/registration/distribution_list/${idDistribution}/`,
      {},
    );
    return res.data;
  };

  deleteDistributionById = async (
    id: number | string,
    params: Partial<resInterface.IDistributionStatement>,
  ) => {
    const res = await this.ctx.delete<
      Partial<resInterface.IDistributionStatement>,
      resInterface.IDistributionStatement
    >(`/registration/distribution_list/${id}`, params);
    return res.data;
  };

  postDistribution = async (
    params: Partial<IDistribution>,
  ) => {
    const res = await this.ctx.post<
      unknown,
      resInterface.IDistributionStatement
    >(`/registration/distribution_list/`, params);
    return res.data;
  };

  patchDistribution = async (
    id: number | string,
    params?: Partial<IDistribution>,
  ) => {
    const res = await this.ctx.patch<
      Partial<IDistribution>,
      resInterface.IDistributionStatement
    >(`/registration/distribution_list/${id}/`, params);
    return res.data;
  };

  getUnits = async (
    params?: Partial<reqInterface.IList>,
  ): Promise<IPaginatedResult<resInterface.IUnit>> => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IUnit>
    >(`/registration/unit/`, params);
    return res.data;
  };

  // организации

  getOrganizationList = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IOrganization>
    >("/registration/organization_discharge/", params);
    return res.data;
  };

  getOrganizationById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IOrganization
    >(`/registration/organization_discharge/${id}/`);
    return res.data;
  };

  postOrganization = async (
    params: reqInterface.IReqOrganization,
  ) => {
    const res = await this.ctx.post<
      unknown,
      resInterface.IOrganization
    >(`/registration/organization_discharge/`, params);
    return res.data;
  };

  patchOrganization = async (
    id: number,
    params: reqInterface.IReqOrganization,
  ) => {
    const res = await this.ctx.patch<
      unknown,
      resInterface.IOrganization
    >(
      `/registration/organization_discharge/${id}/`,
      params,
    );
    return res.data;
  };

  deleteOrganization = async (
    id: number | string,
  ) => {
    const res = await this.ctx.delete<
      unknown,
      resInterface.IOrganization
    >(`/registration/organization_discharge/${id}/`);
    return res.data;
  };

  getAmountsContextStatmentById = async (id: string | number,type_trial: string,year: string | number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IAmountsContextStatements
    >(`/registration/statement/${id}/get_amounts_context_statement/?type_trial=${type_trial}&year=${year}`);
    return res.data;
  };

  /* Agreement */

  getAgreementList  = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IAgreement>
    >("/registration/agreement/", params);

    return res.data;
  };
  getAgreementById = async (id: number) => {
    const res = await this.ctx.get<reqInterface.IList, resInterface.IAgreement>(
      `/registration/agreement/${id}/`
    );
    return res.data;
  };

  postAgreement  = async (
    params: reqInterface.IAgreementReq,
  ): Promise<IAgreement> => {
    const res = await this.ctx.post<
      reqInterface.IAgreementReq,
      IAgreement
    >("/registration/agreement/", params);
    return res.data;
  };

  patchAgreement = async (id: number | string, params: reqInterface.IAgreementReq) => {
    const res = await this.ctx.patch<
    reqInterface.IAgreementReq, IAgreement
    >(`/registration/agreement/${id}/`, params)

    return res.data
  };

  deleteAgreement = async (id: number) => {
    const res = await this.ctx.delete<unknown, Promise<IAgreement>>(
      `/registration/agreement/${id}/`
    );
    return res.data;
  };


  getListOfAgreement = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IListOfAgreement>
    >("/registration/agreement_type/list_for_agreements/", params);

    return res.data;
  };
  
  getAgreementType = async (params: reqInterface.IList) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IAgreementType>
    >("/registration/agreement_type/", params);

    return res.data;
  };

  addFileAgreement= async (id, params) => {
    const res = await this.ctx.post<
      FormData,
      resInterface.IOrderFile
    >(`/registration/agreement/${id}/add_file/`, params);

    return res.data;
  };
  deleteFileAgreement= async (id: number, file_id: any) => {
    const res = await this.ctx.delete<
      unknown,
      resInterface.IOrderFile
    >(
      `/registration/agreement/${id}/delete_file/${file_id}/`,
    );
    return res.data;
  };
}

export default StatementApi;
