import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import Autocomplete, {
  SearchFilterProps
} from "@/components/Autocomplete";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { ISubject } from "@/api/interfaces/responses";
import { ISubjectList } from "@/api/interfaces/requests";

interface IProps extends Partial<SearchFilterProps<ISubject>> {
  name: string
  handleChange?: (payload: ISubject) => void,
  q?: ISubjectList
  initialSearch?: string
}

/**
 * Автокомплит со списком субьектов
 * @param name - имя
 * @param q - квери параметры для запроса
 * @param handleChange - калбек при выборе
 * @param initialSearch - начальное значение поиска
 */

const SubjectAutocomplete: React.FC<IProps> = ({
  name,
  initialSearch = "",
  handleChange,
  q = {},
  ...props
}) => {
  const swrKey = SWR_KEYS.getSubjectList();
  const [search, setSearch] = useState(initialSearch);
  const formik = useFormikContext();
  const { api } = useStores();
  const getKey = (index: number) => {
    return {
      _key: swrKey,
      page: index + 1,
      search: search,
      ...q
    };
  };
  const {
    data,
    handleScroll,
  } = useInfiniteScroll(
    getKey,
    api.statement.getSubjects,
    {
      revalidateFirstPage: false,
    },
  );
  const onChange = (_, payload: ISubject) => {
    handleChange ? handleChange(payload) : formik.setFieldValue(name, payload);
  };

  const handleClear = () => {
    void formik.setFieldValue(name, null)
  }

  if (!formik) {
    throw new Error(
      "SubjectAutocomplete has to be used within <Formik>"
    );
  }

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Autocomplete
          <ISubject>
          label="Субъект"
          onClear={handleClear}
          {...field}
          {...props}
          onChange={onChange}
          search={setSearch}
          data={data}
          handleScroll={handleScroll}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
    </Field>
  );
};

export default SubjectAutocomplete;