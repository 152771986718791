import { IColumn } from "@/apps/Table/interfaces";
import { TDepartmentsKeys} from "../interfaces";
import { DEPARTMENT_KEYS, DEPARTMENT_LABELS } from "../const";
import { PointerTextCell } from "../cells";
import {cellButtonsMaterialType,cellPointText, headerCellStyle } from "../style";
import { EditingCellsDepartments, PhoneCell, StaffCell } from "../templates/Departments/cells";

export const COLUMN_EDITING = {
    value: "editing",
    label: "",
};

export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const DepartmentsColumns: IColumn<TDepartmentsKeys>[] = [
    {
        label: DEPARTMENT_LABELS.name,
        key: DEPARTMENT_KEYS.name,
        contentHeader: DEPARTMENT_LABELS.name,
        bodyCellProps: {
            render: PointerTextCell,
            styles: cellPointText,
        },
        headerCellProps: {
            styles: headerCellStyle,
        },
    },
    {
        label: DEPARTMENT_LABELS.staff_director__last_name,
        key: DEPARTMENT_KEYS.staff_director__last_name,
        contentHeader: DEPARTMENT_LABELS.staff_director__last_name,
        bodyCellProps: {
          render:StaffCell,
          styles: cellPointText,
        },
        headerCellProps: {
          styles: headerCellStyle,
        },
      },
    {
        label: DEPARTMENT_LABELS.phones,
        key: DEPARTMENT_KEYS.phones,
        contentHeader: DEPARTMENT_LABELS.phones,
        bodyCellProps: {
          render: PhoneCell,
          styles: cellPointText,
        },
        headerCellProps: {
          styles: headerCellStyle,
        },
        isSorting: false,
      },
      {
        key: "editing",
        label: DEPARTMENT_LABELS.editing,
        contentHeader: COLUMN_EDITING.label,
        bodyCellProps: {
          render: EditingCellsDepartments,
          styles: cellButtonsMaterialType,
        },
      },
];