import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Field, Form, Formik, FormikProps } from "formik";
import Yup from "@/utils/yup";
import { useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import {
  flattenData,
  isRomanNumeral,
} from "@/utils/helpers";

const FIELDS_SET = {
  comment: "comment",
  number: "number",
  rome_number: "rome_number",
};

/**
 * Interface for the LightZone component's props.
 */
interface ILightZone {
  handleClose: () => void;
  modalProps: { [key: string]: any };
}

/**
 * Validation schema using Yup for form validation.
 */
const validationSchema = Yup.object().shape({
  [FIELDS_SET.number]: Yup.number().required(),
  [FIELDS_SET.rome_number]: Yup.string()
    .nullable()
    .test(
      "is-roman-numeral",
      "Введите допустимую римскую цифру",
      (value) => isRomanNumeral(value),
    ),
  [FIELDS_SET.comment]: Yup.string().nullable(),
});
/**
 * Address component for updating the password.
 * @param {ILightZone} props - Component props.
 * @returns {React.FC<IAddress>} - Rendered component.
 */
const LightZone: React.FC<ILightZone> = ({
  handleClose,
  modalProps,
}) => {
  const { api, swrStore } = useStores();
  const [errors, setErrors] = useState<
    { path: string; value: string }[]
  >([]);

  const { initialValues: defaultValues, isChange } =
    modalProps;

  const initialValues = Object.assign(
    {
      [FIELDS_SET.comment]: "",
      [FIELDS_SET.number]: null,
      [FIELDS_SET.rome_number]: null,
    },
    defaultValues,
  );

  const mutateList =
    swrStore.mutators[SWR_KEYS.getLightZones()];

  const mutateDetail =
    swrStore.mutators[
      SWR_KEYS.getLightZoneById(defaultValues?.id)
    ];

  const handleCreate = (data, actions) => {
    if (isChange) {
      api.regulatoryInfo
        .updateLightZone(defaultValues.id, data)
        .then(() => {
          mutateDetail && mutateDetail();
          handleClose();
        })
        .catch((e) => {
          const error = flattenData(e.response?.data);
          setErrors(error);
        })
        .finally(() => {
          actions.setSubmitting(false);
          mutateDetail && mutateDetail();
          mutateList && mutateList();
        });
    } else {
      api.regulatoryInfo
        .postLightZone(data)
        .then(() => {
          mutateList && mutateList();
          handleClose();
        })
        .catch((e) => {
          const error = flattenData(e.response?.data);
          setErrors(error);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    }
  };

  const handleSubmit = (values, actions) => {
    const data = {
      ...values,
    };
    handleCreate(data, actions);
  };

  return (
    <Box>
      <Box p={3}>
        <Typography>Световые зоны</Typography>
        <Typography
          variant="h2"
          sx={{ paddingRight: "30px" }}
        >
          {isChange
            ? "Редактировать запись"
            : "Добавить запись"}
        </Typography>
      </Box>
      <Divider />
      <Stack direction={"column"}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(form: FormikProps<any>) => {
            return (
              <Form>
                <Stack direction={"column"} gap={2} p={2}>
                  <Field name={FIELDS_SET.number}>
                    {({ field, meta }) => (
                      <FormControl>
                        <TextField
                          {...field}
                          label={"Арабский номер"}
                          fullWidth
                          size="small"
                          required={true}
                          onChange={form.handleChange}
                          onBlur={field.handleBlur}
                          value={
                            form.values[FIELDS_SET.number]
                          }
                          disabled={meta.isSubmitting}
                          sx={{
                            width: "200px",
                          }}
                        />
                        <FormHelperText
                          error={
                            meta.touched && !!meta.error
                          }
                        >
                          {meta.touched && meta.error}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                  <Field name={FIELDS_SET.rome_number}>
                    {({ field, meta }) => (
                      <FormControl>
                        <TextField
                          {...field}
                          label={"Римский номер"}
                          fullWidth
                          size="small"
                          required={true}
                          onChange={form.handleChange}
                          onBlur={field.handleBlur}
                          value={
                            form.values[
                              FIELDS_SET.rome_number
                            ]
                          }
                          disabled={meta.isSubmitting}
                          sx={{
                            width: "200px",
                          }}
                        />
                        <FormHelperText
                          error={
                            meta.touched && !!meta.error
                          }
                        >
                          {meta.touched && meta.error}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                  <Field name={FIELDS_SET.comment}>
                    {({ field, meta }) => (
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          label={"Комментарий"}
                          inputProps={{ maxLength: 100 }}
                          fullWidth
                          multiline
                          minRows={4}
                          maxRows={10}
                          sx={{ minHeight: "150px" }}
                          onChange={form.handleChange}
                          onBlur={field.handleBlur}
                          value={
                            form.values[FIELDS_SET.comment]
                          }
                          disabled={meta.isSubmitting}
                        />
                        <FormHelperText
                          error={
                            meta.touched && !!meta.error
                          }
                        >
                          {meta.touched && meta.error}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                {errors.length ? (
                  <Stack gap={1} p={2} direction={"column"}>
                    {errors.map((el) => {
                      return (
                        <Alert
                          variant={"outlined"}
                          severity="error"
                        >
                          {el.path} {el.value}
                        </Alert>
                      );
                    })}
                  </Stack>
                ) : null}

                <Divider />

                <Box
                  p={3}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button color="red" onClick={handleClose}>
                    отмена
                  </Button>
                  <Button
                    disabled={form.isSubmitting}
                    variant="contained"
                    type="submit"
                    color="primary"
                    startIcon={<CheckIcon />}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Stack>
    </Box>
  );
};

export default LightZone;
