export const EFFECTIVITY_FIELD_NAMES = {
  revenue:
    'Выручка от реализации от продукции растениеводства',
  current_expenses: 'Текущие расходы',
  direct_expenses: 'Размер прямых затрат',
  production_expenses: 'Производственные расходы',
  administrative_expenses: 'Административные расходы',
  taxes: 'Налоги',
  capital_investments: 'Величина капитальных вложений',
  balance: 'Баланс прибыли/убытка от реализации(+/-)',
};

