import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  Stack,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import {
  IBranchMainDistrict
} from "@/api/interfaces/responses";
import theme from "@/theme";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";

interface IProps {
  district: IBranchMainDistrict;
}

const GSUCard: React.FC<IProps> = ({ district }) => {
  return (
    <Stack
      sx={{
        cursor: "pointer",
        mb: 1.2,
      }}
      direction={"column"}
    >
      <Box
        p={2}
        pb={0}
        sx={{
          width: "100%",
          height: "fit-content",
          borderRadius: "4px",
          border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
          transition: ".6s",
          "&:hover": {
            borderColor: theme.palette.primary.main,
          },
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1.2}
            mb={1.2}
          >
            <Typography
              variant="h4">{district.name}</Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={1.2}
          alignItems="center"
        >
          <List sx={{
            m: "24px 0", width: "100%", "li + li": {
              borderTop: `1px solid ${theme.palette.blackAndWhite.stroke}`
            }
          }}>
            {district.plots.map((item, index) => (
              <ListItem
                key={item.id}
                sx={{
                  padding: 0,
                  display: "flex",
                  width: "100%",
                }}
              >
                <Box pl={2} width="52px">
                  <Typography variant="body2"
                              sx={{ color: theme.palette.blackAndWhite.gray }}>
                    {index + 1}
                  </Typography>
                </Box>
                <Box p={2}>
                  <Typography variant="body2">
                    <MuiLink underline="none"
                             component={Link}
                             to={`${ROUTES_PATHS.gsu}/${item.id}/?tab=generalInfo`}>
                      {item.name}
                    </MuiLink>
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Stack>
      </Box>
    </Stack>
  );
};

export default GSUCard;
