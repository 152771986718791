import React from "react";
// import { logo } from "@/assets/auth";
import style from "./index.module.scss";
import { Box, SxProps, Theme } from "@mui/material";

const Logo = (props: SxProps<Theme> | undefined) => {
  return (
    <Box className={style.wrapper} sx={props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="252"
        height="189"
        viewBox="0 0 252 189"
        fill="none"
        aria-label="Company Logo"
      >
        <g clipPath="url(#clip0_85_106550)">
          <path
            d="M46.0149 113.822C45.8687 104.212 45.79 94.5954 45.537 84.9849H0.0449219L5.07087 99.8498L0.0449219 113.822H46.0149Z"
            fill="url(#paint0_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.12"
            strokeMiterlimit="10"
          />
          <path
            d="M36.4353 89.6329L56.9945 83.1099V93.394L36.4353 89.6329Z"
            fill="#3B2422"
          />
          <path
            d="M207.244 113.822C207.391 104.206 207.464 94.5842 207.711 84.9736H251.955L247.064 99.8498L251.955 113.828H207.244V113.822Z"
            fill="url(#paint1_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.12"
            strokeMiterlimit="10"
          />
          <path
            d="M215.879 89.6329L195.32 83.1099V93.3997L215.879 89.6329Z"
            fill="#3B2422"
          />
          <path
            d="M45.5315 140.975V48.0246L126.149 1.54932L206.761 48.0246V140.975L126.149 187.451L45.5315 140.975Z"
            fill="white"
            stroke="#3C6C3D"
            strokeWidth="4.78"
            strokeMiterlimit="10"
          />
          <path
            d="M49.8376 50.5058V138.494L126.149 182.488L202.46 138.494V50.5058L126.149 6.51172L49.8376 50.5058Z"
            fill="white"
          />
          <path
            d="M54.4253 135.844V53.1499L126.149 11.8054L197.867 53.1499V135.844L126.149 177.194L54.4253 135.844Z"
            stroke="#F7A70A"
            strokeWidth="4.78"
            strokeMiterlimit="10"
          />
          <path
            d="M215.835 89.9136H36.4634V120.233H215.835V89.9136Z"
            fill="url(#paint2_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M52.2438 98.7831V100.506H47.7744V110.274H45.6775V98.7887H52.2494L52.2438 98.7831Z"
            fill="#3C6C3D"
          />
          <path
            d="M63.3863 104.408C63.3863 108.259 61.0476 110.46 57.8768 110.46C54.7061 110.46 52.5811 107.99 52.5811 104.616C52.5811 101.242 54.8017 98.6035 58.0455 98.6035C61.2893 98.6035 63.3863 101.146 63.3863 104.414V104.408ZM54.8017 104.576C54.8017 106.912 55.9823 108.77 57.9949 108.77C60.0075 108.77 61.1713 106.895 61.1713 104.492C61.1713 102.331 60.0975 100.282 57.9949 100.282C55.8923 100.282 54.8017 102.207 54.8017 104.576Z"
            fill="#3C6C3D"
          />
          <path
            d="M73.6069 109.926C73.1121 110.184 72.0159 110.437 70.6385 110.437C66.9843 110.437 64.7637 108.152 64.7637 104.661C64.7637 100.877 67.3947 98.5923 70.9084 98.5923C72.2913 98.5923 73.2864 98.8786 73.7081 99.1031L73.2471 100.776C72.7017 100.535 71.9484 100.333 70.9927 100.333C68.654 100.333 66.9674 101.798 66.9674 104.56C66.9674 107.08 68.4516 108.702 70.9815 108.702C71.836 108.702 72.7186 108.534 73.2639 108.276L73.6069 109.932V109.926Z"
            fill="#3C6C3D"
          />
          <path
            d="M83.4115 109.926C82.9168 110.184 81.8262 110.437 80.4432 110.437C76.789 110.437 74.5684 108.152 74.5684 104.661C74.5684 100.877 77.1938 98.5923 80.7131 98.5923C82.096 98.5923 83.0855 98.8786 83.5127 99.1031L83.0517 100.776C82.5064 100.535 81.7587 100.333 80.803 100.333C78.4643 100.333 76.7778 101.798 76.7778 104.56C76.7778 107.08 78.2619 108.702 80.7861 108.702C81.6407 108.702 82.5289 108.534 83.0742 108.276L83.4172 109.932L83.4115 109.926Z"
            fill="#3C6C3D"
          />
          <path
            d="M95.1893 104.408C95.1893 108.259 92.8506 110.46 89.6742 110.46C86.4978 110.46 84.384 107.99 84.384 104.616C84.384 101.242 86.6047 98.6035 89.8429 98.6035C93.081 98.6035 95.1893 101.146 95.1893 104.414V104.408ZM86.6047 104.576C86.6047 106.912 87.7853 108.77 89.7979 108.77C91.8105 108.77 92.9742 106.895 92.9742 104.492C92.9742 102.331 91.9005 100.282 89.7979 100.282C87.6953 100.282 86.6047 102.207 86.6047 104.576Z"
            fill="#3C6C3D"
          />
          <path
            d="M97.157 98.9347C97.9103 98.8 98.9504 98.699 100.367 98.699C101.902 98.699 103.032 99.0246 103.763 99.6365C104.449 100.198 104.893 101.102 104.893 102.179C104.893 103.257 104.55 104.172 103.903 104.784C103.066 105.621 101.75 106.008 100.266 106.008C99.8723 106.008 99.5125 105.991 99.2427 105.941V110.269H97.157V98.9347ZM99.2427 104.285C99.5013 104.352 99.8386 104.369 100.266 104.369C101.834 104.369 102.796 103.605 102.796 102.252C102.796 100.978 101.907 100.293 100.457 100.293C99.878 100.293 99.4676 100.344 99.2483 100.394V104.279L99.2427 104.285Z"
            fill="#3C6C3D"
          />
          <path
            d="M109.171 100.535H105.893V98.7831H114.585V100.535H111.273V110.269H109.176V100.535H109.171Z"
            fill="#3C6C3D"
          />
          <path
            d="M118.16 98.7831V103.706H118.503L122.022 98.7831H124.563L120.398 103.964C122.073 104.273 122.776 105.362 123.282 106.811C123.709 108.018 124.069 109.18 124.58 110.269H122.326C121.932 109.483 121.657 108.528 121.37 107.658C120.909 106.26 120.296 105.256 118.655 105.256H118.16V110.269H116.097V98.7831H118.16Z"
            fill="#3C6C3D"
          />
          <path
            d="M136.01 104.408C136.01 108.259 133.671 110.46 130.5 110.46C127.329 110.46 125.204 107.99 125.204 104.616C125.204 101.242 127.425 98.6035 130.669 98.6035C133.913 98.6035 136.01 101.146 136.01 104.414V104.408ZM127.425 104.576C127.425 106.912 128.606 108.77 130.618 108.77C132.631 108.77 133.795 106.895 133.795 104.492C133.795 102.331 132.721 100.282 130.618 100.282C128.516 100.282 127.425 102.207 127.425 104.576Z"
            fill="#3C6C3D"
          />
          <path
            d="M147.63 105.581C147.562 104.099 147.473 102.309 147.489 100.759H147.439C147.068 102.157 146.584 103.706 146.073 105.155L144.397 110.128H142.773L141.238 105.216C140.794 103.751 140.383 102.185 140.08 100.754H140.046C139.995 102.252 139.928 104.077 139.844 105.643L139.591 110.263H137.629L138.399 98.7776H141.165L142.666 103.398C143.076 104.745 143.436 106.109 143.723 107.389H143.773C144.083 106.143 144.47 104.728 144.92 103.381L146.506 98.7776H149.238L149.907 110.263H147.855L147.636 105.576L147.63 105.581Z"
            fill="#3C6C3D"
          />
          <path
            d="M154.14 98.7832V102.836C154.14 104.661 154.089 106.35 154.005 107.883L154.039 107.9C154.635 106.609 155.388 105.222 156.136 103.931L159.172 98.7832H161.302V110.269H159.357V106.193C159.357 104.386 159.374 102.92 159.514 101.433H159.481C158.935 102.746 158.165 104.178 157.429 105.407L154.528 110.263H152.184V98.7776H154.129L154.14 98.7832Z"
            fill="#3C6C3D"
          />
          <path
            d="M172.124 109.926C171.63 110.184 170.539 110.437 169.156 110.437C165.502 110.437 163.287 108.152 163.287 104.661C163.287 100.877 165.912 98.5923 169.432 98.5923C170.815 98.5923 171.804 98.8786 172.231 99.1031L171.77 100.776C171.225 100.535 170.472 100.333 169.522 100.333C167.183 100.333 165.491 101.798 165.491 104.56C165.491 107.08 166.975 108.702 169.499 108.702C170.354 108.702 171.242 108.534 171.787 108.276L172.124 109.932V109.926Z"
            fill="#3C6C3D"
          />
          <path
            d="M181.934 109.926C181.44 110.184 180.349 110.437 178.966 110.437C175.318 110.437 173.097 108.152 173.097 104.661C173.097 100.877 175.728 98.5923 179.242 98.5923C180.625 98.5923 181.614 98.8786 182.041 99.1031L181.58 100.776C181.035 100.535 180.282 100.333 179.326 100.333C176.987 100.333 175.295 101.798 175.295 104.56C175.295 107.08 176.779 108.702 179.309 108.702C180.164 108.702 181.052 108.534 181.597 108.276L181.94 109.932L181.934 109.926Z"
            fill="#3C6C3D"
          />
          <path
            d="M185.842 98.7832V102.836C185.842 104.661 185.791 106.35 185.707 107.883L185.74 107.9C186.336 106.609 187.09 105.222 187.837 103.931L190.873 98.7832H193.01V110.269H191.064V106.193C191.064 104.386 191.081 102.92 191.216 101.433H191.182C190.637 102.746 189.867 104.178 189.13 105.407L186.23 110.263H183.891V98.7776H185.836L185.842 98.7832Z"
            fill="#3C6C3D"
          />
          <path
            d="M194.556 110.269C194.758 109.926 194.927 109.516 195.084 109.09C195.562 107.776 195.854 106.317 196.759 105.424C197.018 105.171 197.355 104.964 197.715 104.846V104.795C196.366 104.554 195.242 103.6 195.242 101.983C195.242 100.944 195.686 100.125 196.366 99.5803C197.17 98.9516 198.446 98.699 199.919 98.699C201.043 98.699 202.173 98.8 202.972 98.9347V110.269H200.891V105.548H200.037C199.441 105.548 198.98 105.705 198.62 106.042C197.867 106.788 197.563 108.343 197.187 109.365C197.08 109.668 196.984 109.926 196.793 110.269H194.556ZM200.891 100.4C200.667 100.349 200.256 100.282 199.666 100.282C198.401 100.282 197.344 100.776 197.344 102.14C197.344 103.353 198.418 104.015 199.801 104.015C200.211 104.015 200.622 104.015 200.897 103.981V100.4H200.891Z"
            fill="#3C6C3D"
          />
          <path
            d="M113.415 43.8931C113.899 49.2765 123.197 49.5348 125.362 54.1267V63.5015C123.715 58.9881 114.928 58.292 113.348 54.0032L113.415 43.8987V43.8931Z"
            fill="url(#paint3_linear_85_106550)"
          />
          <path
            d="M113.415 43.8931C113.899 49.2765 123.197 49.5348 125.362 54.1267V63.5015C123.715 58.9881 114.928 58.292 113.348 54.0032L113.415 43.8987V43.8931Z"
            fill="url(#paint4_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.23"
            strokeMiterlimit="10"
          />
          <path
            d="M138.247 43.8931C137.764 49.2765 128.465 49.5348 126.301 54.1267V63.5015C127.948 58.9881 136.735 58.292 138.315 54.0032L138.247 43.8987V43.8931Z"
            fill="white"
            stroke="#1E1E1C"
            strokeWidth="0.23"
            strokeMiterlimit="10"
          />
          <path
            d="M113.415 54.5983C113.899 59.9817 123.197 60.24 125.362 64.8319V74.2067C123.715 69.6933 114.928 68.9972 113.348 64.7084L113.415 54.6039V54.5983Z"
            fill="url(#paint5_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.23"
            strokeMiterlimit="10"
          />
          <path
            d="M138.247 54.5983C137.764 59.9817 128.465 60.24 126.301 64.8319V74.2067C127.948 69.6933 136.735 68.9972 138.315 64.7084L138.247 54.6039V54.5983Z"
            fill="#1961AC"
          />
          <path
            d="M125.525 30.4933C124.755 35.9778 119.661 38.1222 119.667 42.9556C119.667 48.8892 122.77 48.1762 125.677 53.2061L125.525 30.4933Z"
            fill="url(#paint6_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.23"
            strokeMiterlimit="10"
          />
          <path
            d="M126.143 53.2061C128.842 48.064 132.136 48.7601 131.9 42.8321C131.703 37.9987 126.835 35.9385 125.84 30.4933L126.143 53.2061Z"
            fill="url(#paint7_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.23"
            strokeMiterlimit="10"
          />
          <path
            d="M113.415 65.2417C113.899 70.6252 123.197 70.8834 125.362 75.4754V84.8501C123.715 80.3368 114.928 79.6407 113.348 75.3519L113.415 65.2473V65.2417Z"
            fill="url(#paint8_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.23"
            strokeMiterlimit="10"
          />
          <path
            d="M138.247 65.2417C137.764 70.6252 128.465 70.8834 126.301 75.4754V84.8501C127.948 80.3368 136.735 79.6407 138.315 75.3519L138.247 65.2473V65.2417Z"
            fill="#E30613"
          />
          <path
            d="M94.5147 58.5334C94.8295 62.0083 100.834 62.1767 102.228 65.1407V71.1922C101.165 68.2787 95.4929 67.8296 94.4697 65.0621L94.5147 58.5391V58.5334Z"
            fill="url(#paint9_linear_85_106550)"
          />
          <path
            d="M94.5147 58.5334C94.8295 62.0083 100.834 62.1767 102.228 65.1407V71.1922C101.165 68.2787 95.4929 67.8296 94.4697 65.0621L94.5147 58.5391V58.5334Z"
            fill="url(#paint10_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M110.548 58.5334C110.233 62.0083 104.229 62.1767 102.835 65.1407V71.1922C103.897 68.2787 109.57 67.8296 110.593 65.0621L110.548 58.5391V58.5334Z"
            fill="url(#paint11_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M94.5147 65.4438C94.8295 68.9187 100.834 69.0871 102.228 72.0511V78.1026C101.165 75.1891 95.4929 74.74 94.4697 71.9725L94.5147 65.4495V65.4438Z"
            fill="url(#paint12_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M110.548 65.4438C110.233 68.9187 104.229 69.0871 102.835 72.0511V78.1026C103.897 75.1891 109.57 74.74 110.593 71.9725L110.548 65.4495V65.4438Z"
            fill="url(#paint13_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M94.5147 72.3149C94.8295 75.7898 100.834 75.9582 102.228 78.9222V84.9737C101.165 82.0602 95.4929 81.6111 94.4697 78.8436L94.5147 72.3206V72.3149Z"
            fill="url(#paint14_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M110.548 72.3149C110.233 75.7898 104.229 75.9582 102.835 78.9222V84.9737C103.897 82.0602 109.57 81.6111 110.593 78.8436L110.548 72.3206V72.3149Z"
            fill="url(#paint15_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M102.335 49.8828C101.834 53.425 98.5513 54.806 98.5513 57.9328C98.5513 61.7613 100.553 61.3066 102.43 64.5512L102.335 49.8828Z"
            fill="url(#paint16_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M102.728 64.5456C104.471 61.2224 106.596 61.6771 106.444 57.8486C106.321 54.7274 103.178 53.397 102.531 49.8828L102.728 64.5512V64.5456Z"
            fill="url(#paint17_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M140.71 58.5334C141.024 62.0083 147.029 62.1767 148.423 65.1407V71.1922C147.36 68.2787 141.688 67.8296 140.665 65.0621L140.71 58.5391V58.5334Z"
            fill="url(#paint18_linear_85_106550)"
          />
          <path
            d="M140.71 58.5334C141.024 62.0083 147.029 62.1767 148.423 65.1407V71.1922C147.36 68.2787 141.688 67.8296 140.665 65.0621L140.71 58.5391V58.5334Z"
            fill="url(#paint19_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M156.743 58.5334C156.428 62.0083 150.424 62.1767 149.03 65.1407V71.1922C150.092 68.2787 155.765 67.8296 156.788 65.0621L156.743 58.5391V58.5334Z"
            fill="url(#paint20_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M140.71 65.4438C141.024 68.9187 147.029 69.0871 148.423 72.0511V78.1026C147.36 75.1891 141.688 74.74 140.665 71.9725L140.71 65.4495V65.4438Z"
            fill="url(#paint21_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M156.743 65.4438C156.428 68.9187 150.424 69.0871 149.03 72.0511V78.1026C150.092 75.1891 155.765 74.74 156.788 71.9725L156.743 65.4495V65.4438Z"
            fill="url(#paint22_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M140.71 72.3149C141.024 75.7898 147.029 75.9582 148.423 78.9222V84.9737C147.36 82.0602 141.688 81.6111 140.665 78.8436L140.71 72.3206V72.3149Z"
            fill="url(#paint23_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M156.743 72.3149C156.428 75.7898 150.424 75.9582 149.03 78.9222V84.9737C150.092 82.0602 155.765 81.6111 156.788 78.8436L156.743 72.3206V72.3149Z"
            fill="url(#paint24_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M148.53 49.8828C148.029 53.425 144.746 54.806 144.746 57.9328C144.746 61.7613 146.747 61.3066 148.625 64.5512L148.53 49.8828Z"
            fill="url(#paint25_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M148.929 64.5456C150.671 61.2224 152.797 61.6771 152.645 57.8486C152.521 54.7274 149.378 53.397 148.732 49.8828L148.929 64.5512V64.5456Z"
            fill="url(#paint26_linear_85_106550)"
            stroke="#1E1E1C"
            strokeWidth="0.15"
            strokeMiterlimit="10"
          />
          <path
            d="M17.9731 96.1729H17.9394L15.8705 97.217L15.5051 95.6003L18.2542 94.2474H20.0476V105.542H17.9787V96.1785L17.9731 96.1729Z"
            fill="white"
          />
          <path
            d="M24.9836 104.01C25.3153 104.043 25.6414 104.026 26.1642 103.976C27.0019 103.908 27.8339 103.611 28.4635 103.106C29.1944 102.516 29.7004 101.663 29.9084 100.608L29.8746 100.591C29.3012 101.231 28.5029 101.601 27.4066 101.601C25.439 101.601 23.9773 100.175 23.9773 98.0927C23.9773 96.0101 25.647 94.0621 28.0644 94.0621C30.6898 94.0621 32.1009 96.128 32.1009 98.873C32.1009 101.287 31.3026 103.01 30.1332 104.122C29.1438 105.042 27.7833 105.564 26.271 105.666C25.7819 105.716 25.2984 105.716 24.9836 105.699V104.015V104.01ZM26.0967 97.9636C26.1136 99.1256 26.777 100.029 27.9576 100.029C28.829 100.029 29.453 99.6309 29.7847 99.092C29.8521 98.9516 29.9084 98.7776 29.9084 98.4857C29.9252 96.9588 29.3518 95.634 27.9576 95.634C26.8782 95.634 26.0967 96.5883 26.0967 97.9636Z"
            fill="white"
          />
          <path
            d="M219.629 106.115V104.812L220.967 103.577C223.626 101.113 224.88 99.7543 224.897 98.261C224.897 97.2506 224.357 96.3693 222.879 96.3693C221.884 96.3693 221.035 96.8745 220.461 97.3067L219.837 95.7967C220.658 95.1343 221.889 94.6346 223.317 94.6346C225.824 94.6346 227.039 96.2177 227.039 98.059C227.039 100.041 225.611 101.64 223.643 103.459L222.654 104.313V104.346H227.297V106.12H219.624L219.629 106.115Z"
            fill="white"
          />
          <path
            d="M233.841 106.115V103.246H228.725V101.854L233.335 94.8199H235.859V101.629H237.321V103.246H235.859V106.115H233.841ZM233.841 101.629V98.553C233.841 97.9074 233.858 97.2506 233.909 96.5882H233.841C233.493 97.3012 233.2 97.8738 232.852 98.4969L230.783 101.59L230.766 101.624H233.847L233.841 101.629Z"
            fill="white"
          />
          <path
            d="M116.451 148.397V141.301C117.519 141.121 118.323 140.302 118.323 139.314C118.323 138.197 117.289 137.287 116.013 137.287C114.737 137.287 113.702 138.197 113.702 139.314C113.702 140.032 114.129 140.661 114.776 141.02V150.03C113.691 151.001 112.544 151.855 111.319 152.545V138.623C112.179 138.331 112.791 137.596 112.791 136.737C112.791 135.62 111.757 134.71 110.481 134.71C109.205 134.71 108.17 135.62 108.17 136.737C108.17 137.596 108.783 138.331 109.643 138.623V153.359C108.553 153.808 107.406 154.122 106.186 154.28V145.562C107.046 145.27 107.659 144.534 107.659 143.675C107.659 142.558 106.624 141.649 105.348 141.649C104.072 141.649 103.038 142.558 103.038 143.675C103.038 144.534 103.65 145.27 104.51 145.562V154.392C102.054 154.409 99.3215 153.797 96.2463 152.36C122.995 172.591 130.236 131.011 143.301 132.953C130.483 122.13 125.57 138.797 116.463 148.391L116.451 148.397Z"
            fill="#3C6C3D"
          />
          <path
            d="M107.31 132.959C133.294 121.013 135.678 167.073 154.376 152.444C153.477 152.309 152.611 152.09 151.779 151.793V143.013C152.47 142.8 152.971 142.227 152.971 141.542C152.971 140.683 152.178 139.987 151.194 139.987C150.211 139.987 149.418 140.683 149.418 141.542C149.418 142.222 149.918 142.8 150.61 143.013V151.321C149.744 150.928 148.912 150.451 148.114 149.912V139.353C148.805 139.14 149.305 138.567 149.305 137.882C149.305 137.023 148.513 136.327 147.529 136.327C146.545 136.327 145.752 137.023 145.752 137.882C145.752 138.561 146.253 139.14 146.944 139.353V149.065C146.084 148.391 145.252 147.65 144.448 146.853V140.7C145.14 140.487 145.64 139.914 145.64 139.229C145.64 138.371 144.847 137.674 143.863 137.674C142.88 137.674 142.087 138.371 142.087 139.229C142.087 139.909 142.587 140.487 143.279 140.7V145.651C133.575 135.21 126.857 118.184 107.299 132.965L107.31 132.959Z"
            fill="#3C6C3D"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_85_106550"
            x1="0.0449219"
            y1="99.4063"
            x2="46.0149"
            y2="99.4063"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="0.63" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_85_106550"
            x1="251.955"
            y1="99.4007"
            x2="207.244"
            y2="99.4007"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="0.74" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_85_106550"
            x1="126.149"
            y1="89.9136"
            x2="126.149"
            y2="120.233"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_85_106550"
            x1="113.354"
            y1="53.6945"
            x2="125.367"
            y2="53.6945"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_85_106550"
            x1="113.354"
            y1="53.6945"
            x2="125.367"
            y2="53.6945"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_85_106550"
            x1="113.354"
            y1="64.3997"
            x2="125.367"
            y2="64.3997"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_85_106550"
            x1="119.673"
            y1="41.8482"
            x2="125.677"
            y2="41.8451"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_85_106550"
            x1="131.852"
            y1="41.6116"
            x2="125.684"
            y2="41.8618"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_85_106550"
            x1="113.354"
            y1="75.0431"
            x2="125.367"
            y2="75.0431"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_85_106550"
            x1="94.4697"
            y1="64.86"
            x2="102.228"
            y2="64.86"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_85_106550"
            x1="94.4697"
            y1="64.86"
            x2="102.228"
            y2="64.86"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_85_106550"
            x1="110.593"
            y1="64.86"
            x2="102.835"
            y2="64.86"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_85_106550"
            x1="94.4697"
            y1="71.776"
            x2="102.228"
            y2="71.776"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_85_106550"
            x1="110.593"
            y1="71.776"
            x2="102.835"
            y2="71.776"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_85_106550"
            x1="94.4697"
            y1="78.6471"
            x2="102.228"
            y2="78.6471"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_85_106550"
            x1="110.593"
            y1="78.6471"
            x2="102.835"
            y2="78.6471"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_85_106550"
            x1="98.5536"
            y1="57.2126"
            x2="102.433"
            y2="57.2106"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_85_106550"
            x1="106.417"
            y1="57.0626"
            x2="102.44"
            y2="57.224"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_85_106550"
            x1="140.67"
            y1="64.86"
            x2="148.428"
            y2="64.86"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_85_106550"
            x1="140.67"
            y1="64.86"
            x2="148.428"
            y2="64.86"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_85_106550"
            x1="156.788"
            y1="64.86"
            x2="149.03"
            y2="64.86"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_85_106550"
            x1="140.67"
            y1="71.776"
            x2="148.428"
            y2="71.776"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_85_106550"
            x1="156.788"
            y1="71.776"
            x2="149.03"
            y2="71.776"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_85_106550"
            x1="140.67"
            y1="78.6471"
            x2="148.428"
            y2="78.6471"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_85_106550"
            x1="156.788"
            y1="78.6471"
            x2="149.03"
            y2="78.6471"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_85_106550"
            x1="144.748"
            y1="57.2109"
            x2="148.628"
            y2="57.2089"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_85_106550"
            x1="152.616"
            y1="57.0589"
            x2="148.633"
            y2="57.2205"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDD698" />
            <stop offset="1" stopColor="#F7A60F" />
          </linearGradient>
          <clipPath id="clip0_85_106550">
            <rect width="252" height="189" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};

export default Logo;
