import React, { useEffect } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Box,
  Divider,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import { EditIcon, DeleteIcon } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { SWR_KEYS } from "@/const";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { resInterface } from "@/api/interfaces";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};


export const FullNameCell = (_: any, cell: any): JSX.Element => {
  const {full_name} = cell
  return (
    <Box>
    <Typography fontSize={14}>{full_name ?? "-"}</Typography>
  </Box>
  );
};

/**
 * @component EmailCell
 * * компонент ячейки таблицы для почты
 * @param
 */
export const EmailCell = (emails: any): JSX.Element => {
  const displayEmails = emails.slice(0, 2);
  const hasMore = emails.length > 2;
  return (
    <Box>
      {displayEmails.map((i) => (
        <Typography key={i.email} fontSize={14}>
          {i.email ?? "-"}
        </Typography>
      ))}
      {hasMore && (
        <Typography fontSize={14}>...</Typography>
      )}
    </Box>
  );
};

/**
 * @component PhoneCell
 * * компонент ячейки таблицы для телефона
 * @param
 */
export const PhoneCell = (phone: any): JSX.Element => {
  const displayPhones = phone.slice(0, 2);
  const hasMore = phone.length > 2;

  return (
    <Box>
      {displayPhones.map((i) => (
        <Typography key={i.phone} fontSize={14}>
          {i.phone ?? "-"}
        </Typography>
      ))}
      {hasMore && (
        <Typography fontSize={14}>...</Typography>
      )}
    </Box>
  );
};
/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCellsEmployee = (
  _: string,
  cell: resInterface.IEmployee,
  itemsInPage: any[],
): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: "true" },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };


  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    modalStore.open(VARIANT_MODAL.EMPLOYEE, {
      theme: THEME_MODAL.W_555,
      initialValues: cell,
      employeeId: cell.id,
    });
    setAnchorEl(null);
  };

  const mutateList =
    swrStore.mutators[SWR_KEYS.getEmployeeList()];

  const mutateWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateList && mutateList();
    }
  };

  const handleDelete = (resolve, reject) => {
    api.regulatoryInfo.deleteEmployee(cell.id).then(() => {
      mutateWithCheckingLast();
      resolve()
    }).catch(reject);
    setAnchorEl(null);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const canEditEmployee = userStore.isPermission(
    PERMISSIONS.regulatory_infoEmployee,
    PERMISSION_CRUD.change
  )
  const canDeleteEmployee = userStore.isPermission(
    PERMISSIONS.regulatory_infoEmployee,
    PERMISSION_CRUD.delete
  )

  if (!canEditEmployee && !canDeleteEmployee) {
    return <></>
  }

  return (
    <Box>
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {canEditEmployee && 
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon />
          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
       {canEditEmployee && canDeleteEmployee && <Divider />}
       {canDeleteEmployee && 
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color={"error"} />
          <Typography color="error">
            {EDITING_CELL.delete}
          </Typography>
        </StyledMenuItem>
       }
      </Menu>
    </Box>
  );
};
