import React, { useState } from "react";
import useSWR from "swr";
import Table from "@/apps/Table";
import {
  IVariety,
  IVarietyPlanPlot
} from "@/api/interfaces/responses";
import { useStores } from "@/hooks";
import {
  TVarietyKeys
} from "@/apps/RightSidebar/templates/Planning/SecondLevel/components/VarietyTable/interfaces";
import {
  columns
} from "@/apps/RightSidebar/templates/Planning/SecondLevel/components/VarietyTable/models";
import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";
import Loader from "@/components/Loader";
import { Button } from "@mui/material";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";
import { Box } from "@mui/system";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import {
  SWR_KEYS,
  TRIAL_TYPE,
  VARIETY_TYPE
} from "@/const";

interface IProps {
  groupId: number;
  planPlotId: number;
  cultivarId: number;
  trialType: string;
}

const modalVariants = {
  [TRIAL_TYPE.HP]: VARIANT_MODAL.SELECT_STANDARD,
  [TRIAL_TYPE.OOS]: VARIANT_MODAL.SELECT_SIMILAR_VARIETY
};

const buttonLabels = {
  [TRIAL_TYPE.HP]: "Добавить стандарт",
  [TRIAL_TYPE.OOS]: "Добавить похожий сорт"
};

const varietyTypes = {
  [TRIAL_TYPE.HP]: VARIETY_TYPE.STANDART,
  [TRIAL_TYPE.OOS]: VARIETY_TYPE.SIMILAR
};

const DistributionGroup: React.FC<IProps> = ({
  groupId,
  planPlotId,
  cultivarId,
  trialType,
}) => {
  const {
    api,
    modalStore,
    swrStore,
  } = useStores();
  const [page, setPage] = useState(1);

  const swrKey = SWR_KEYS.getVarietyPlanPlotList(groupId);
  const buttonLabel = buttonLabels[trialType] || "Добавить";
  const variantModal = modalVariants[trialType] ?? undefined;
  const varietyType = varietyTypes[trialType] ?? undefined;

  const {
    data,
    isLoading,
    error,
    mutate
  } = useSWR(
    {
      page: page,
      pageSize: DEFAULT_SIZE_PAGE,
      plan_plot: planPlotId,
      variety_plan_plot_group: groupId
    },
    api.planning.getVarietyPlanPlot,
    {
      revalidateOnMount: true
    }
  );

  swrStore.addMutator(swrKey, mutate);

  const handleAccept = (variety: IVariety | IVariety[]) => {
    const payload = Array.isArray(variety) ? variety.map((el) => ({
      variety: el.id,
      type: varietyType,
      plan_plot: planPlotId,
      variety_plan_plot_group: groupId
    })) : [{
      variety: variety.id,
      type: varietyType,
      plan_plot: planPlotId,
      variety_plan_plot_group: groupId
    }];
    if (varietyType) {
      api.planning.postVarietyPlanPlot(payload).then(() => {
        void mutate();
        modalStore.close();
      });
    }
  };

  const handleAdd = () => {
    if (variantModal) {
      modalStore.open(variantModal, {
        currentCultivar: {
          id: cultivarId,
          name: cultivarId,
        }, onSubmit: handleAccept
      });
    }
  };

  return (
    <Box>
      <Box>
        <Button
          color={"blue"}
          startIcon={<AddOutlinedIcon/>}
          variant={"outlined"}
          onClick={handleAdd}
        >
          {buttonLabel}
        </Button>
      </Box>
      <Box>
        <Table<IVarietyPlanPlot, TVarietyKeys>
          columns={columns}
          isLoading={isLoading}
          data={data}
          error={error}
          page={page}
          setPage={setPage}
          isFilters={false}
          renderLoader={() => <Loader size={40}/>}
        />
      </Box>
    </Box>
  );
};

export default DistributionGroup;
