import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { IProps,validationSchema } from "./const";
import { AxiosError } from "axios";
import { errorToString, getRandomColor } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import AddRegionForm from "./components/AddRegionForm";
import { SWR_KEYS } from "@/const";
import { IRegionReq } from "@/api/interfaces/requests";

/**
 * Модалка с формой создания/редактирования регионов
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const AddRegion: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const initialValues = Object.assign(
    {
      name: "",
      number: '',
      colour: getRandomColor(),
    },
    modalProps?.initialValues,
  );

  const { api, toastStore, swrStore } = useStores();

  const mutateDetail = modalProps?.regionId
    ? swrStore.mutators[
        SWR_KEYS.getRegionById(
          Number(modalProps?.regionId) as number,
        )
      ]
    : null;

  const mutateList =
    swrStore.mutators[SWR_KEYS.getRegions()];

  const handleSubmit = (
    values: IRegionReq,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);
    const promise = modalProps?.regionId
      ? api.regulatoryInfo.patchRegionByID(
          modalProps?.regionId,
          values,
        )
      : api.regulatoryInfo.postRegion(values);

    promise
      .then(() => {
        modalProps?.regionId
          ? mutateDetail && mutateDetail()
          : null;
          handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          if (errors.colour) {
            errors.colour = "Введите допустимый цвет в формате HEX, например, #000000.";
          }
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        modalProps?.regionId
          ? mutateDetail && mutateDetail()
          : null;
        mutateList && mutateList();
        
      });
  };

  
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <AddRegionForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      )}
    />
  );
};

export default AddRegion;
