import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import {
  STEP_TITLES,
} from "@/pages/AddPlan/const";
import { IOffBudget } from "@/api/interfaces/requests";
import { CulturesContainer } from "./CulturesContainer";

interface IProps {
  data?: IOffBudget | null;
}

/**
 * Компонент для шага производства
 * */

const ProductionFormStep: React.FC<IProps> = ({ data }) => {

  return (
    <Stack spacing={3}>
      <Typography variant="h3">
        {STEP_TITLES.PRODUCTION}
      </Typography>
      {data ? (
        <Box
          sx={{
            width: "100%",
            height: "inherit",
          }}
        >
          <CulturesContainer renderData={data} />
        </Box>
      ) : null}
    </Stack>
  );
};
export default ProductionFormStep;
