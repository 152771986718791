import React, { PropsWithChildren, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  accordionSummaryClasses,
  buttonBaseClasses,
  Stack,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import theme from "@/theme";

const StyledAccordion = styled(Accordion)(() => ({
  [`& .${buttonBaseClasses.root}`] : {
    minHeight: 'initial'
  },
  [`& .${accordionSummaryClasses.content}`] : {
    margin: 0
  },
  border: "none",
  "& > div": {
    padding: 0,
  },
  "&:before": { height: "0px" },
}));

interface IProps extends AccordionProps {
  title: string;
  subtitle?: string;
  defaultExpanded?: boolean;
}

const TableAccordion = ({
  title,
  subtitle = "",
  children,
  defaultExpanded = false,
  ...props
}: PropsWithChildren<IProps>) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledAccordion
      elevation={0}
      disableGutters
      expanded={expanded}
      onChange={handleExpand}
      {...props}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon
          sx={{
            color: expanded ?
              theme.palette.blue.main :
              theme.palette.blackAndWhite.black,
          }}
        />}
      >
        <Stack>
          <Typography variant="h4">{title}</Typography>
          {
            subtitle && (
              <Typography
                variant="body2"
                color={theme.palette.blackAndWhite.gray}
              >
                {subtitle}
              </Typography>
            )
          }
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          pl: 0,
          pr: 0,
        }}
      >
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default TableAccordion;
