import React, { useState } from "react";
import TextField, {
  TextFieldProps
} from "@mui/material/TextField";

export const InputField: React.FC<TextFieldProps> = (props) => {
  /**
   * флаг для правильного отображения автокомплита
   * */
  const [isAutoFill, setIsAutoFill] = useState(false);
  return (
    <TextField
      {...props}
      InputLabelProps={{ shrink: isAutoFill || undefined }}
      InputProps={{
        onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
          e.animationName === "mui-auto-fill" && setIsAutoFill(true);
        },
        onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) =>
          e.animationName === "mui-auto-fill-cancel" && setIsAutoFill(false),
        onFocus: () => setIsAutoFill(false),
      }}
    />
  );
};
