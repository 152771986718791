import React from "react";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
import SelectCultivarFieldVarietyForm
  from "./components/SelectCultivarFieldVarietyForm";
import {
  IFormik,
  initialValues,
  validationSchema,
} from "./const";
import theme from "@/theme";

/**
 * Компонент модалки выбора сорта плана использования земли
 * @param props
 * @param props.cultivarId - id плана внебюджета
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    cultivarId : number;
    cultivarLabel: string;
    handleSubmit: (payload: IFormik) => void
  }
}

const SelectCultivarFieldVariety: React.FC<IProps> = ({handleClose, modalProps}) => {
  const handleSubmit = (
    values: IFormik,
  ) => {
    modalProps.handleSubmit && modalProps.handleSubmit(values)
    handleClose()
  };

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="body2" fontSize={12} color={theme.palette.blackAndWhite.gray}>
          {modalProps.cultivarLabel}
        </Typography>
        <Typography variant="h2" fontSize={24}>
          Добавить сорт
        </Typography>
      </Box>
      <Divider />
      <Formik<IFormik>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => <SelectCultivarFieldVarietyForm handleClose={handleClose} cultivarId={modalProps.cultivarId} />}
      />
    </Box>
  );
};

export default SelectCultivarFieldVariety;
