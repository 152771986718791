import { SxProps, Theme } from "@mui/material/styles";

export const tableWrapperStyle = (theme: Theme): SxProps => ({
  width: "100%",
  padding: theme.spacing(3),
});

export const headerCellStyle = (theme: Theme): SxProps => ({
  whiteSpace: "nowrap",
  color: theme.palette.blackAndWhite.black,
});

export const cell = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  color: theme.palette.blackAndWhite.black,
});
