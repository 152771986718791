import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface IProps extends SvgIconProps {
  active?: boolean;
}
//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=86-113421&mode=design&t=NsBAwfXhny5rGRRi-0
const MapLayers = ({
  active = false,
  ...props
}: IProps) => {
  const isActive = active ? 'green' : '#25292C';
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.000000"
        height="20.000000"
        viewBox="0 0 22 20"
        fill="none"
      >
        <path
          id="stacks"
          d="M11 12L0 6L11 0L22 6L11 12ZM11 16L0.575195 10.3252L2.6748 9.1748L11 13.7251L19.3252 9.1748L21.4248 10.3252L11 16ZM11 20L0.575195 14.3252L2.6748 13.1748L11 17.7251L19.3252 13.1748L21.4248 14.3252L11 20ZM11 9.7251L17.8252 6L11 2.2749L4.1748 6L11 9.7251Z"
          fill={isActive}
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="stacks"
          d="M11 12L0 6L11 0L22 6L11 12ZM11 16L0.575195 10.3252L2.6748 9.1748L11 13.7251L19.3252 9.1748L21.4248 10.3252L11 16ZM11 20L0.575195 14.3252L2.6748 13.1748L11 17.7251L19.3252 13.1748L21.4248 14.3252L11 20ZM11 9.7251L17.8252 6L11 2.2749L4.1748 6L11 9.7251Z"
          fill={isActive}
          fillOpacity="0.200000"
          fillRule="nonzero"
        />
        <path
          id="stacks"
          d="M11 12L0 6L11 0L22 6L11 12ZM11 16L0.575195 10.3252L2.6748 9.1748L11 13.7251L19.3252 9.1748L21.4248 10.3252L11 16ZM11 20L0.575195 14.3252L2.6748 13.1748L11 17.7251L19.3252 13.1748L21.4248 14.3252L11 20ZM11 9.7251L17.8252 6L11 2.2749L4.1748 6L11 9.7251Z"
          fill={isActive}
          fillOpacity="0.200000"
          fillRule="nonzero"
        />
        <path
          id="stacks"
          d="M11 12L0 6L11 0L22 6L11 12ZM11 16L0.575195 10.3252L2.6748 9.1748L11 13.7251L19.3252 9.1748L21.4248 10.3252L11 16ZM11 20L0.575195 14.3252L2.6748 13.1748L11 17.7251L19.3252 13.1748L21.4248 14.3252L11 20ZM11 9.7251L17.8252 6L11 2.2749L4.1748 6L11 9.7251Z"
          fill={isActive}
          fillOpacity="0.200000"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
};

export default MapLayers;
