import React from "react";

import {
  Stack,
} from "@mui/material";
import FastRegexTextField from "@/components/FastRegexTextField";
import { IProps, PLOT_FIELD_NAME } from "../const";

const PlotFormThirdStep: React.FC<IProps>  = () => {

  return (
    <>
      <Stack direction={"column"} gap={2}>
        <FastRegexTextField
            name={PLOT_FIELD_NAME.COMMENT}
            label='Комментарий'
            fullWidth
            size="small"
            multiline
            minRows={5}
            maxRows={10}
          />
      </Stack>
    </>
  );
};

export default PlotFormThirdStep;
