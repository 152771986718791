import React, { useState } from "react";
import { FastField, Form, useFormikContext } from "formik";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Autocomplete from "@/components/Autocomplete";
import { useStores, useInfiniteScroll } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { observer } from "mobx-react";
import { FUNDING_SOURCE, FUNDING_SOURCE_NAMES, IAddCostProps, IFormValues } from "./types";
import { FIELDS_NAME } from "./const";
import { TCostFundingSource } from "@/api/types";


const AddCostForm: React.FC<IAddCostProps> = observer(
  ({ handleClose, modalProps }) => {
    const {
      title = "Добавить распределенные расходы",
    } = modalProps || {};

    const formik = useFormikContext<IFormValues>()
    const [search, setSearch] = useState('')

    const { api } = useStores();

    // данные для селекта
    const key = SWR_KEYS.getRegularityInfo();

    const getParameters = (index: number) => ({
      _key: key,
      page: index + 1,
      search: search
    });

    const fetcher = (args) => {
      const { page, search } = args;
      return api.offBudget.getRegulatoryInfo({
        page,
        search,
      });
    };

    const { data, handleScroll } = useInfiniteScroll(
      getParameters,
      fetcher,
      {
        revalidateFirstPage: false,
      },
    );

    const handleChange = (event, value) => {
      formik.setFieldValue(FIELDS_NAME.EXPENSE, value);
    };

    const handleCancel = () => {
      handleClose();
    };

    // айтемы в селекте типа разнарядки
    const renderDistributionTypeItem =
      Object.keys(FUNDING_SOURCE).map((el: TCostFundingSource) => {
        return (
          <MenuItem key={el} value={el}>
            {FUNDING_SOURCE_NAMES[el]}
          </MenuItem>
        );
      });

    const handleChangeType = (e) => {
      formik.setFieldValue(
        FIELDS_NAME.FUNDING_SOURCE,
        e.target.value,
      );
    };

    return (
      <Form noValidate>
        <Box>
          <Box p={3}>
            <Typography variant="h2">{title}</Typography>
          </Box>
          <Divider />

          <Grid container mt={1} spacing={1} p={3}>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <Autocomplete
                  value={formik.values[FIELDS_NAME.EXPENSE]}
                  onChange={handleChange}
                  label="Наименование статьи"
                  data={data}
                  required
                  search={setSearch}
                  handleScroll={handleScroll}
                  textFieldSize="medium"
                  error={
                    !!formik.touched[FIELDS_NAME.EXPENSE] &&
                    !!formik.errors[FIELDS_NAME.EXPENSE]
                  }
                  helperText={!!formik.touched[FIELDS_NAME.EXPENSE] &&
                    !!formik.errors[FIELDS_NAME.EXPENSE] ? 'Обязательное поле' : undefined}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FastField name={FIELDS_NAME.FUNDING_SOURCE}>
                {({ field, meta }) => (
                  <FormControl
                    fullWidth
                    required
                  >
                    <InputLabel
                      id="select-label"
                    >
                      Источник финансирования
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="select-label"
                      label="Источник финансирования"
                      fullWidth
                      error={meta.touched && !!meta.error}
                      onChange={handleChangeType}
                      value={formik.values.funding_source}
                    >
                      {renderDistributionTypeItem}
                    </Select>
                    <FormHelperText
                      error={meta.touched && !!meta.error}
                    >
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                )}
              </FastField>
            </Grid>

            <Grid item xs={12}>
              <FastField name={FIELDS_NAME.SUM}>
                {({ meta }) => (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    required
                  >
                    <TextField
                      label="Сумма, руб"
                      variant="outlined"
                      type="number"
                      id="sum"
                      fullWidth
                      size='medium'
                      multiline
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.sum}
                      disabled={formik.isSubmitting}
                      error={meta.touched && !!meta.error}
                    />
                    <FormHelperText
                      error={meta.touched && !!meta.error}
                    >
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                )}
              </FastField>
            </Grid>
          </Grid>

          <Divider />
          <Box
            p={3}
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="text"
              color="red"
              onClick={handleCancel}
            >
              Отмена
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </Box>
        </Box>
      </Form>
    );
  },
);

export default AddCostForm;
