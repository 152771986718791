import React, {
  ReactElement,
  useLayoutEffect,
} from "react";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores } from "@/hooks";
import MainContent from "./components/MainContent";
import { Box, SxProps, Theme } from "@mui/material";
import theme from "@/theme";
import useSWR from "swr";
import { SWR_KEYS, SEO_TITLES } from "@/const";
import Loader from "@/components/Loader";
import Seo from "@/components/Seo";

const wrapperStyle = (theme: Theme): SxProps => ({
  width: "100%",
  padding: theme.spacing(3),
});

const MainPage = (): ReactElement => {
  const { headerStore } = useStores();

  const { api } = useStores();

  const key = SWR_KEYS.getRegionMain()

  const { data: renderData, error, isLoading } = useSWR(
    [key],
    () => api.regulatoryInfo.getRegionMain(), {}
  );


  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.MAIN);
  }, []);

  if (error) {
    return <div>Ошибка загрузки данных</div>;
  }

  if (isLoading || !renderData) {
    return <Loader/>;
  }

  return (
    <React.Fragment>
      <Seo title={SEO_TITLES.MAIN}/>
      <Box sx={wrapperStyle(theme)}>
        <MainContent renderData={renderData} />
      </Box>
    </React.Fragment>
  );
};

export default MainPage;
