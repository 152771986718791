import { ValueOf } from "@/utils/types";

export const DROPZONE_VARIANT = {
  FILE: "file",
  IMG: "img",
} as const;

export type TDropzoneVariant = ValueOf<
  typeof DROPZONE_VARIANT
>;

export interface IStatues {
  SUCCESS: "success";
  ERROR: "error";
  LOADING: "loading";
}

export type TStatuses = ValueOf<IStatues>;

export const MAX_SIZE = 1024 * 1024 * 20;

export const SUBTITLE = "DOC, XLS или PDF (не больше 20МБ)";

export const IMAGE_SUBTITLE = "PDF, PNG, JPEG или JPG (не больше 20МБ)";

export const STATUSES: IStatues = {
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
};

export const statusText = {
  [STATUSES.SUCCESS]: "Успешно",
  [STATUSES.ERROR]: "Ошибка",
  [STATUSES.LOADING]: "Загрузка",
};

export const errorsCode = {
  "file-too-large": "Файл слишком большой",
  "too-many-files": "Слишком много файлов",
  "file-invalid-type": "Невалидный тип файла",
  DEFAULT: "Ошибка",
};

export const mockFiles = [
  {
    name: "Первый",
    size: 100,
    status: "loading",
  },
  {
    name: "Второй",
    size: 100,
    status: "error",
    error: "Текст ошибки",
  },
  {
    name: "третий",
    size: 100,
    status: "success",
  },
] as IFile[];

export const FILE_ACCEPT = {
  "application/pdf": [],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    [],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    [],
  "application/msword": [],
  "application/vnd.ms-excel": [],
};

export const IMAGE_ACCEPT = {
  "image/png": [], 
  "image/jpeg": [],
};

export const FILE_AND_IMAGE_ACCEPT = {
  ...FILE_ACCEPT,
  ...IMAGE_ACCEPT,
};

export const FILE_AND_IMAGE_SUBTITLE =
  "DOC, DOCX, XLS, XLSX, PDF, PNG, JPEG или JPG (не больше 20МБ)";

export interface IFile extends File {
  uuid: string;
  id?: number;
  status: string;
  error?: string;
  preview?: string;
  formatSize: number;
  file_name?: string;
  file_size?: number;
  file_url?: string;
}
