import yup from "@/utils/yup";
import {
  IIndicator,
  IPlanningHp,
} from "@/api/interfaces/responses";
import { IPostHp } from "@/api/interfaces/requests";

export const STEP_LABELS = {
  CULTIVAR: "Культура",
  PLOT: "Признаки",
};

export const STEP_TITLES = {
  CULTIVAR: "Культура",
  PLOT: "Культура",
};

export const PLAN_FIELDS_NAME = {
  CULTIVAR: "cultivar", // культура
  ORDER: "order", // приказ
  INDICATOR_METHODOLOGY: "indicator_methodology",
} as const;

export const validationSchema = yup.object().shape({
  [PLAN_FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
  [PLAN_FIELDS_NAME.ORDER]: yup.mixed().required(),
});

export interface IValue {
  id: number;
  label: string;
}

export interface IPlanForm {
  [PLAN_FIELDS_NAME.ORDER]: IValue | null;
  [PLAN_FIELDS_NAME.CULTIVAR]: IValue | null;
  [PLAN_FIELDS_NAME.INDICATOR_METHODOLOGY]: Array<IIndicator>;
}

export const mapValueToForm = (
  data?: IPlanningHp | null,
) => ({
  [PLAN_FIELDS_NAME.ORDER]: data?.order?.id,
  [PLAN_FIELDS_NAME.CULTIVAR]: data?.cultivar
    ? {
        id: data?.cultivar?.id,
        label: data?.cultivar?.name,
      }
    : null,
  [PLAN_FIELDS_NAME.INDICATOR_METHODOLOGY]:
    data?.indicator_methodology ?? [],
});

export const mapFormToValue = (
  data: IPlanForm,
): Partial<IPostHp> => ({
  order: Number(data.order),
  cultivar: data.cultivar?.id,
  indicator_methodology: data.indicator_methodology.map(
    (el) => el.id,
  ),
});
