
import { OFFBUDGET_HEADER_LABELS, OFFBUDGET_KEYS } from "../const";

import { TOffBudgetKeys } from "../interfaces";

import {
  cellBranch,
  cellDate,
  cellName,
  cellStatus,
  headerCellStyle,
} from "../style";
import { BranchCells, DateCells, EditingCells, NameCells, StatusCells } from "../components/OffBudgetCellComponents";
import { IColumn } from "@/apps/Table/interfaces";
import { cellButtons } from "@/apps/Table/style";

export const EDITING_CELL = {
  addFact: "Создать факт",
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
}


/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const columns: IColumn<TOffBudgetKeys>[] = [
  {
    label: OFFBUDGET_HEADER_LABELS.year,
    key: OFFBUDGET_KEYS.year,
    contentHeader: OFFBUDGET_HEADER_LABELS.year,
    bodyCellProps: {
      render: DateCells,
      styles: cellDate,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: OFFBUDGET_HEADER_LABELS.name,
    key: OFFBUDGET_KEYS.name,
    contentHeader: OFFBUDGET_HEADER_LABELS.name,
    bodyCellProps: {
      render: NameCells,
      styles: cellName,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: OFFBUDGET_HEADER_LABELS.branch,
    key: OFFBUDGET_KEYS.branch__name,
    contentHeader: OFFBUDGET_HEADER_LABELS.branch,
    bodyCellProps: {
      render: BranchCells,
      styles: cellBranch,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: OFFBUDGET_HEADER_LABELS.status,
    key: OFFBUDGET_KEYS.status,
    contentHeader: OFFBUDGET_HEADER_LABELS.status,
    bodyCellProps: {
      render: StatusCells,
      styles: cellStatus,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: OFFBUDGET_KEYS.editing,
    label: '',
    contentHeader: '',
    bodyCellProps: {
      render: EditingCells,
      styles: cellButtons,
    },
  },
]
