import React from "react";
import { Field, useFormikContext } from "formik";
import Autocomplete, {
  SearchFilterProps
} from "@/components/Autocomplete";

interface IProps extends Partial<SearchFilterProps<Record<string, string>>> {
  name: string
  handleChange?: (payload: Record<string, string>) => void,
}

/**
 * Автокомплит со списком неблагоприятных условий
 * @param name - имя
 * @param handleChange - калбек при выборе
 */

const weatherConditionsOptions = [
  { id: "very_strong_wind", name: "Очень сильный ветер" },
  {
    id: "hurricane_wind",
    name: "Ураганный ветер (ураган)"
  },
  { id: "squall", name: "Шквал" },
  { id: "tornado", name: "Смерч" },
  {
    id: "very_heavy_rain",
    name: "Очень сильный дождь (очень сильный дождь со снегом, очень сильный мокрый снег, очень сильный снег с дождем)"
  },
  { id: "very_heavy_snow", name: "Очень сильный снег" },
  {
    id: "prolonged_heavy_rain",
    name: "Продолжительный сильный дождь"
  },
  { id: "large_hail", name: "Крупный град" },
  {
    id: "heavy_dust_storm",
    name: "Сильная пыльная (песчаная) буря"
  },
  { id: "heavy_blizzard", name: "Сильная метель" },
  { id: "heavy_fog", name: "Сильный туман (сильная мгла)" },
  {
    id: "heavy_icing",
    name: "Сильное гололедно-изморозевое отложение"
  },
  { id: "severe_frost", name: "Сильный мороз" },
  { id: "severe_heat", name: "Сильная жара" },
  { id: "frost", name: "Заморозки" },
  {
    id: "abnormally_hot_weather",
    name: "Аномально-жаркая погода"
  },
  {
    id: "extreme_fire_danger",
    name: "Чрезвычайная пожарная опасность"
  },
  {
    id: "drought",
    name: "Засуха"
  }
];

const VegetationStageAutocomplete: React.FC<IProps> = ({
  name,
  handleChange,
  ...props
}) => {
  const formik = useFormikContext();
  const onChange = (_, payload: Record<string, string>) => {
    handleChange ? handleChange(payload) : formik.setFieldValue(name, payload);
  };

  const handleClear = () => {
    void formik.setFieldValue(name, null);
  };

  if (!formik) {
    throw new Error(
      "VegetationStageAutocomplete has to be used within <Formik>"
    );
  }

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Autocomplete
          label="Неблагоприятное условие"
          onClear={handleClear}
          filter={true}
          {...field}
          {...props}
          onChange={onChange}
          data={weatherConditionsOptions}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
    </Field>
  );
};

export default VegetationStageAutocomplete;