import React from "react";
import { Box, Typography } from "@mui/material";

import { TITLES_PAGES } from "@/pages/const";

/**
 * хедер для страницы заявки
 * @returns React.FC
 */
const HeaderStatement: React.FC = () => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="h3" noWrap>
        {TITLES_PAGES.statements}
      </Typography>
    </Box>
  );
};

export default HeaderStatement;
