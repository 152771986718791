import React, { useState, MouseEvent } from "react";
import { Divider, Menu, MenuItem, Stack } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import UserInfo from "./UserInfo";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useMatches } from "@/apps/Respons";
import BaseHeader from "@/apps/HeaderContent";

import theme from "@/theme";

import { HEADER_LABELS } from "../const";

import { useStores } from "@/hooks";

import {
  AppBar,
  dividerStyle,
  menuItemStyle,
  menuStyle,
  toolbarStyle,
} from "../style";

interface IProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

/**
 * @component
 * Шапка основного лейаута
 * @param isOpen: положение боковой панели
 * @param toggleSidebar: функция открытия/закрытия сайдбара
 * @param children: контент шапки
 * @returns
 */
const Header: React.FC<IProps> = ({ isOpen }) => {
  const { api } = useStores();
  const { matches } = useMatches();
  const navigate = useNavigate();
  const [isOpenUser, setIsOpenUser] = useState(false);
  const [userMenuComponent, setUserMenuComponent] =
    useState<null | HTMLElement>(null);
  const open = Boolean(isOpenUser);
  const isHideInfo = !matches?.small && !matches?.regular;

  const handleOpenUserInfo = (event: MouseEvent<HTMLButtonElement>) => {
    setUserMenuComponent(event.currentTarget);
    if (event !== null) setIsOpenUser(true);
  };

  const handleCloseUserInfo = () => {
    setIsOpenUser(false);
    setUserMenuComponent(null);
  };

  const handleLogout = () => {
    api.auth.postLogout();
  };

  const handleRedirectProfile = () => {
    navigate(ROUTES_PATHS.profile);
  };

  const getWidthAppBar = () => {
    if (isHideInfo) {
      return {};
    }
    return {
      width: "100%",
    };
  };

  const handlePickElement = (func: () => void): void => {
    func();
    handleCloseUserInfo();
  };

  const menuItems = [
    {
      label: HEADER_LABELS.profile,
      icon: <AccountCircleOutlinedIcon />,
      onClick: handleRedirectProfile,
    },
    {
      label: HEADER_LABELS.exit,
      icon: <LogoutOutlinedIcon />,
      onClick: handleLogout,
    },
  ];

  return (
    <AppBar position="fixed" open={isOpen} sx={getWidthAppBar()}>
      <Toolbar sx={toolbarStyle()}>
        <BaseHeader />

        {isHideInfo && (
          <UserInfo toggleDropdown={handleOpenUserInfo} isOpen={isOpenUser} />
        )}

        <Menu
          anchorEl={userMenuComponent}
          open={open}
          sx={menuStyle(theme)}
          onClose={handleCloseUserInfo}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {menuItems.map((el, index) => [
            index !== 0 ? (
              <Divider component="li" sx={dividerStyle(theme)} />
            ) : null,

            <MenuItem
              onClick={() => handlePickElement(el.onClick)}
              sx={menuItemStyle(theme)}
            >
              <Stack direction={"row"} alignItems={"center"} gap={"12px"}>
                {el.icon}

                <Typography fontSize={14}>{el.label}</Typography>
              </Stack>
            </MenuItem>,
          ])}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default observer(Header);
