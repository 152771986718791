import { IOrderCultivar } from "@/api/interfaces/responses";
import { ORDER_CULTIVAR_FIELDS_NAME } from "@/pages/AddOrderCultivar/const";

export const mapValueToForm = (
  data?: IOrderCultivar | null,
) => ({
  [ORDER_CULTIVAR_FIELDS_NAME.ORDER]: data?.order?.id,
  [ORDER_CULTIVAR_FIELDS_NAME.CULTIVAR]: data?.cultivar
    ? {
        id: data?.cultivar?.id,
        label: data?.cultivar?.name,
      }
    : null,
  [ORDER_CULTIVAR_FIELDS_NAME.SHARED_APPENDIX]:
    data?.shared_appendix ?? false,
});
