import React from "react";
import { Formik } from "formik";
import {
  defaultValues,
  IFormik,
  validationSchema,
} from "./const";
import IndicatorForm
  from "@/apps/Modals/ModalContent/SelectMethodogyIndicator/components/IndicatorForm";
import { IIndicator } from "@/api/interfaces/responses";

interface IProps {
  handleClose: () => void;
  modalProps: {
    cultivarName: string;
    cultivarId: number;
    handleAccept: (payload: IIndicator) => void
  };
}

const SelectMethodologyIndicator: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const handleSubmit = (
    values: IFormik,
  ) => {
    modalProps.handleAccept && modalProps.handleAccept(values.indicator as IIndicator)
    handleClose()
  };

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
      component={() => (
        <IndicatorForm
          cultivarId={modalProps.cultivarId}
          cultivarName={modalProps.cultivarName}
        />
      )}
    />
  );
};

export default SelectMethodologyIndicator;
