import {
  LAYER_TYPES,
  BASE_MAP_STATE,
  VIEW_MOD
} from "@/apps/Map/const";
import { action, makeAutoObservable } from "mobx";
import { IMapObject } from "@/api/interfaces/responses";
import { RootStore } from "./Root";
import { Map } from "leaflet";
import { ValueOf } from "@/utils/types";

export class MapStore {
  rootStore: RootStore;

  currentLayer: string;
  zoomLevel: number;
  anchorEl = null;
  mapInstance = null;

  // Маркеры для карты ( кластеризуются сами )
  markers: IMapObject[];

  // Полигоны ALL
  polygons: {
    coordinates: number[][];
    id: number;
    color: string
  }[] | [];
  polygonsAreas: {
    coordinates: number[][];
    id: number;
    color: string
  }[] | [];
  polygonsFields: {
    coordinates: number[][];
    id: number;
    color: string
  }[] | [];
  polygonsSorts: {
    coordinates: number[][];
    id: number;
    color: string
  }[] | [];
  currentArea: {
    coords: [number, number][] | {
      lat: number;
      lng: number
    }[];
    id: number;
    color: string;
  } | null;
  currentField: {
    coords: [number, number][] | {
      lat: number;
      lng: number
    }[];
    id: number;
    color: string;
  } | null;

  // дефолтные значения для меню с выбором слоя
  showMenu = false;
  markerModeActivated = false;
  rulerModeActivated = false;
  isDrawPoint: boolean;
  isDrawPolygon: boolean;
  isEditPolygon: boolean;
  tempDrawPoligonPoints: { lat: number; lng: number }[];
  tempDrawPoint: { lat: number; lng: number } | null;
  isPermishionDirection: boolean;

  GSU: {
    isOpenFilter: boolean;
    isOpenSetupGSU: boolean;
    isOpenSearch: boolean;
    searchValue: null | string;
    filters: {
      region: string | { label: string; id: number };
      branch: string | { label: string; id: number };
      year: undefined | any;
    };
    coordinates: {
      lat: number | string;
      lng: number | string;
    }
  };

  FIELDS: {
    isOpenFilter: boolean;
    searchValue: null | string;
    filters: {
      destination: string | { label: string; id: number };
      year: number | null;
    };
  };

  VIEW_MOD: ValueOf<typeof VIEW_MOD>;
  CENTER_MAP: [number, number] | undefined;

  initialValuesForms: { [key: string]: any };

  reaction: {
    flyTo: { isFlying: boolean; to: number[] };
    boundsFitPolygon: {
      isBounds: boolean;
      bounds: [number, number][] | undefined;
    };
  };
  undoControlls: { prev: never[]; next: never[] };

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore;
    this.GSU = {
      isOpenFilter: false,
      isOpenSearch: false,
      isOpenSetupGSU: false,
      searchValue: null,
      filters: {
        region: "",
        branch: "",
        year: undefined,
      },
      coordinates: {
        lat: "",
        lng: ""
      }
    };

    this.FIELDS = {
      isOpenFilter: false,
      searchValue: null,
      filters: {
        destination: "",
        year: null
      }
    };

    this.currentLayer = LAYER_TYPES.satellite;
    this.zoomLevel = BASE_MAP_STATE.zoom.base;
    this.isDrawPoint = false;
    this.isDrawPolygon = false;
    this.isEditPolygon = false;
    this.tempDrawPoint = null
    this.tempDrawPoligonPoints = [];
    this.markers = [];
    this.polygons = [];
    this.polygonsSorts = []; //когда надо нарисовать несколько сортов-культур
    this.polygonsAreas = []; //когда надо нарисовать несколько участков
    this.polygonsFields = []; //когда надо нарисовать несколько полей

    this.currentArea = null; // для деталок
    this.currentField = null; // для деталок
    this.isPermishionDirection = true;

    this.CENTER_MAP = undefined;
    this.VIEW_MOD = "GSU_LIST"; // Ключ для управления картой ( в каком режиме будет происзодить отрисовка )
    this.reaction = {
      flyTo: {
        isFlying: false,
        to: []
      },
      boundsFitPolygon: {
        isBounds: false,
        bounds: undefined
      }
    };

    this.undoControlls = {
      prev: [],
      next: []
    };
    // контекст для форм ( при переходах между страницами что б не сбрасывалось значение )
    this.initialValuesForms = {};

    makeAutoObservable(this, {
      stopBoundFitPolyReaction: action,
      stopFlyReaction: action,
      actionBoundsFitPolygon: action,
      actionFlyTo: action
    });
  }

  setUndoPrevStep = () => {};

  setUndoNextStep = () => {};

  /**
   * установка дефолтных значений (ранее выбранных в форме)
   * @param obj любой объект с данными формы
   */
  setInitialValuesForms = (obj) => {
    this.initialValuesForms = obj;
  };

  /**
   * необходимость при переключении режима просмотра
   * все запросы идут в деталках и гидрируют контекстные переменные
   */
  resetMapStore = () => {
    this.currentLayer = LAYER_TYPES.satellite;
    this.zoomLevel = BASE_MAP_STATE.zoom.base;
    this.markers = [];
    this.polygons = [];
    this.polygonsAreas = [];
    this.polygonsFields = [];
    this.polygonsSorts = [];
    this.currentArea = null; // для деталок
    this.currentField = null; // для деталок
  };

  /**
   * установка инстанса карты
   * @param map инстанс карты с методами для управления картой
   */

  setMapInstance = (map) => {
    this.mapInstance = map;
  };

  /**
   * установка положения камеры
   * @param center
   */
  setCenterMap = (center) => {
    this.CENTER_MAP = center;
  };

  /**
   * установка для просмотра списка сортов-культур
   * @param polygons список полей
   */
  setPolygonsSorts = (polygons) => {
    this.polygonsSorts = polygons;
  };
  /**
   * установка для просмотра списка полей
   * @param polygons список полей
   */
  setPolygonsFields = (polygons) => {
    this.polygonsFields = polygons;
  };

  /**
   * установка для просмотра списка полей
   * @param polygons список полей
   */
  setPolygonsAreas = (polygons) => {
    this.polygonsAreas = polygons;
  };

  /**
   * установка наблюдаеммого участка ( для деталок )
   * @param area
   */
  setCurrentArea = (area) => {
    this.currentArea = area;
  };

  /**
   * Установка наблюдаемого поля ( для деталок )
   * @param field
   */
  setCurrentField = (field) => {
    this.currentField = field;
  };

  /**
   * метод для установки значений для временного полигона
   * @param points - двумерный массив точек полигона
   */
  setTempPolygonPoints = (points) => {
    this.tempDrawPoligonPoints = points;
  };

  /**
   * метод для установки значений для временной точки
   * @param points - точка полигона
   */
  setTempPoint = (point) => {
    this.tempDrawPoint = point;
  };

  /**
   * смена состостояния валидности построеного поля
   */
  setPermishionDirection = (payload: boolean) => {
    this.isPermishionDirection = payload
  }

  /**
   * сброс временного полигона
   */
  clearTempPolygon = () => {
    this.tempDrawPoligonPoints = [];
  };
  /**
   * сброс временного гсу
   */
  clearTempPoint = () => {
    this.tempDrawPoint = null;
  };


  /**
   * Метод для добавления полигонов
   * @param points - список точек для построения полигна÷
   */
  handleAddPolygon = (id: number, points: number[][], color: string) => {
    this.polygons = [...this.polygons, {
      id,
      coordinates: points,
      color
    }];
  };

  /**
   * Смена состояния для отрисовки гсу
   * @param b - новое значение для
   */
  setIsDrawPoint = (b: boolean) => {
    this.isDrawPoint = b;
  };

  /**
   * смена состояния на редактирование
   * !обработка должна быть только с выключенным режимом рисования
   */
  setIsEditPolygon = (b: boolean) => {
    this.isEditPolygon = b;
    this.isDrawPolygon = false;
  };
  /**
   * Смена состояния для отрисовки полигонов
   * @param b - новое значение для
   */
  setIsDrawPolygon = (b: boolean) => {
    this.isEditPolygon = false;
    this.isDrawPolygon = b;
  };

  /**
   * переключает котроллер управляющий картой
   * @param mod - строка с ключем от мода
   */
  setViewMod = (mod: ValueOf<typeof VIEW_MOD>) => {
    this.resetMapStore();
    this.VIEW_MOD = mod;
  };

  /**
   * Завершение реакции позиционирования в рамках полигонов
   */
  stopBoundFitPolyReaction = () => {
    this.reaction = {
      ...this.reaction,
      boundsFitPolygon: {
        isBounds: false,
        bounds: undefined
      }
    };
  };

  /**
   * Завершение реакции на полет к точке
   */
  stopFlyReaction = () => {
    this.reaction = {
      ...this.reaction,
      flyTo: {
        isFlying: false,
        to: []
      }
    };
  };

  /**
   * событие позиционирования в рамках полигонов
   * @param cords  -  lat lon
   */
  actionBoundsFitPolygon = (bounds) => {
    this.reaction.boundsFitPolygon = {
      isBounds: true,
      bounds
    };
  };

  /**
   * событие полета на определенную точку
   * @param cords  -  lat lon
   */
  actionFlyTo = (cords: number[]) => {
    this.reaction.flyTo = {
      isFlying: true,
      to: cords
    };
  };

  /**
   * метод для установки контроля над маркерами
   * @param filters - объект со всеми фитрами
   */
  setMarkers = (markers: IMapObject[]) => {
    this.markers = markers;
  };

  /**
   * метод для установик фильтров ГСУ
   * @param filters - объект со всеми фитрами
   */
  setGSUFilters = (filters: {
    region: { label: string; id: number } | string;
    branch: { label: string; id: number } | string;
    year: undefined | any;
  }) => {
    this.GSU.filters = filters;
  };
  /**
   * метод для установик координат ГСУ
   * @param coords - объект с координатами
   */
  setGSUCoords = (coords: {
    lat: number | string;
    lng: number | string;
  }) => {
    this.GSU.coordinates.lat = coords.lat;
    this.GSU.coordinates.lng = coords.lng;
  };

  /**
   * метод для очистки координат ГСУ
   */
  clearGSUCoords = () => {
    this.GSU.coordinates.lat = "";
    this.GSU.coordinates.lng = "";
  };

  /**
   * метод для очистки всех фильров для ГСУ
   */
  clearFiltersGSU = () => {
    this.GSU.filters = {
      region: "",
      branch: "",
      year: undefined
    };
  };

  /**
   * метод для установик значение в поиск по ГСУ
   * @param newValue - строка либ нулл, нулл для сброса
   */
  setGSUSearchValue = (newValue: string | null) => {
    this.GSU.searchValue = newValue;
  };

  /**
   * метод для переключения состояния поиска
   * с открыто в закрыто или наоборот
   * @param open - boolean
   */
  setIsOpenSearch = (open: boolean) => {
    this.GSU.isOpenSearch = open;
    if (!open) {
      this.setGSUSearchValue(null);
    }
  };

  /**
   * метод для переключения состояния фильтра
   * с открыто в закрыто или наоборот
   * @param open - boolean
   */
  setIsOpenFilter = (open: boolean) => {
    this.GSU.isOpenFilter = open;
    this.GSU.isOpenSetupGSU = false;
  };

  /**
   * метод для переключения состояния установки ГСУ
   * с открыто в закрыто или наоборот
   * @param open - boolean
   */
  setIsOpenSetupGSU = (open: boolean) => {
    this.GSU.isOpenSetupGSU = open;
    this.GSU.isOpenFilter = false;
  };

  /**
   * метод для установки значение в поиск по Полям
   * @param newValue - строка либ нулл, нулл для сброса
   */
  setFieldsSearchValue = (newValue: string | null) => {
    this.FIELDS.searchValue = newValue;
  };

  /**
   * метод для переключения состояния фильтра
   * с открыто в закрыто или наоборот
   * @param open - boolean
   */
  setIsOpenFieldsFilter = (open: boolean) => {
    this.FIELDS.isOpenFilter = open;
  };

  /**
   * метод для установик фильтров ГСУ
   * @param filters - объект со всеми фитрами
   */
  setFieldsFilters = (filters: {
    destination: { label: string; id: number } | string;
    year: null | number;
  }) => {
    this.FIELDS.filters = filters;
  };

  /**
   * метод для очистки всех фильров для Полей
   */
  clearFiltersFields = () => {
    this.FIELDS.filters = {
      destination: "",
      year: null
    };
  };

  // выбор слоя
  setLayer = (layer: string) => {
    this.currentLayer = layer;
    this.setShowMenu(false);
    this.setAnchorEl(null);
  };

  // настройки для меню с выбором слоя
  setShowMenu = (value: boolean) => {
    this.showMenu = value;
  };

  setAnchorEl = (el: null) => {
    this.anchorEl = el;
  };
  closeLayerMenu = () => {
    this.setAnchorEl(null);
    this.setShowMenu(false);
  };

  openLayerMenu = (event: { currentTarget: any }) => {
    this.setAnchorEl(event.currentTarget);
    this.setShowMenu(true);
  };

  toggleLayerMenu = (event: { currentTarget: any }) => {
    this.anchorEl = this.anchorEl ? null : event.currentTarget;
    this.showMenu = !this.showMenu;
  };

  // настройки зума
  setZoomLevel = (level: number): void => {
    this.zoomLevel = level;
  };

  handleZoomIn = (map: Map): void => {
    if (this.zoomLevel < BASE_MAP_STATE.zoom.max) {
      this.setZoomLevel(this.zoomLevel + 1);
      map.zoomIn();
    }
  };

  handleZoomOut = (map: Map): void => {
    if (this.zoomLevel > BASE_MAP_STATE.zoom.min) {
      this.setZoomLevel(this.zoomLevel - 1);
      map.zoomOut();
    }
  };

  // активация меню маркера
  toggleMarkerMode = () => {
    this.markerModeActivated = !this.markerModeActivated;
  };

  // линейка
  toggleRulerMode = (b: boolean) => {
    this.rulerModeActivated = b;
  };
}
