import React, { useLayoutEffect } from "react";
import { AxiosError } from "axios";
import { Formik } from "formik";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { formToValue, initialValues, validationSchema } from "./const";
import { useStores } from "@/hooks";
import PhytoTrialForm from "./PhytoTrialForm";
import {
  LOCATION_KEY,
  SWR_KEYS,
  TRIAL_TYPE
} from "@/const";
import {
  errorToString,
  isNull,
  isUndefined
} from "@/utils/helpers";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { TOAST_TYPES } from "@/apps/Toast";
import useSWR, { useSWRConfig } from "swr";
import { Box, CircularProgress } from "@mui/material";
import { serializeContext, valueToFormSerializer } from "./utils";

/**
 * Компонент с формой испытания на фито
 * @component
 */
const PhytoTrial = () => {
  const { statementId, trialId } = useParams();
  const [searchParams] = useSearchParams();
  const cultivarId = searchParams.get("cultivar");
  const cultivarName = searchParams.get("cultivarName");
  const { api, toastStore, headerStore, swrStore } = useStores();
  const navigate = useNavigate();
  const location = useLocation();
  const swr = useSWRConfig()
  const mutateTrials =
    swrStore.mutators[
    SWR_KEYS.getTrialByStatementIdKey(Number(statementId))
    ];

  const fetcherContext = ([, id]: [
    string,
    string | undefined,
  ]) => {
    if (isUndefined(id)) return null;
    return api.statement.getTrialContext(id, "phyto");
  };
  
  const fetcher = ([, id]: [string, string | undefined]) => {
    if (isUndefined(id)) return null;
    return api.trial.getTrial(id);
  };

  const {
    data,
    isLoading
  } = useSWR(["getTrial", trialId], fetcher, {
    onError: (error: AxiosError) => {
      toastStore.createToast({
        type: TOAST_TYPES.ALERT,
        toastProps: {
          message: errorToString(error),
          severity: "error"
        }
      });
    }
  });

  const { data: contextData, isLoading: isContextLoading } =
  useSWR(
    ["phytoTrialContext", statementId],
    fetcherContext,
    {
      onError: (error: AxiosError) => {
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorToString(error),
            severity: "error",
          },
        });
      },
    },
  );

  const onSubmit = (values, helpers) => {
    const serializedValues = formToValue(values);
    const data = {
      ...serializedValues,
      statement: Number(statementId),
      trial_type: TRIAL_TYPE.PHYTO
    };
    const promise = trialId 
    ? api.trial.pathTrial(trialId as string, data) 
    : api.trial.postPhytoTrial(data);
    promise.then(() => {
      if (location.key !== LOCATION_KEY.DEFAULT) {
        navigate(-1);
      } else
        navigate({
          pathname: ROUTES_PATHS.statements
        });
      mutateTrials && mutateTrials();
      trialId && swr.mutate(SWR_KEYS.getTrialById(trialId))
    }).catch((error: AxiosError) => {
      toastStore.createToast({
        type: TOAST_TYPES.ALERT,
        toastProps: {
          message: errorToString(error),
          severity: "error"
        }
      });
    }).finally(() => {
      helpers.setSubmitting(false);
    });
  };


  useLayoutEffect(() => {
    headerStore.setProps({
      title: trialId ? "Редактировать испытание" : "Добавить испытание",
      type: TRIAL_TYPE.PHYTO
    });
    headerStore.setHeader(VARIANT_HEADER.EDIT_TRIAL);
  }, []);

  if (isLoading || isContextLoading ) {
    return (
      <Box display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  if (
    !statementId ||
    isUndefined(contextData) ||
    isNull(contextData)
  ) {
    return null;
  }

  if (trialId && (isUndefined(data) || isNull(data))) {
    return null;
  }

  const values = trialId
  ? valueToFormSerializer(data)
  : initialValues;

  const cultivar = {
    id: Number(cultivarId),
    name: String(cultivarName),
  }

  return (
    <Formik initialValues={{
      ...initialValues,
        ...values,
        context: serializeContext(contextData),
    }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
      component={() => <PhytoTrialForm
        cultivar={cultivar}/>} />
  );
};

export default PhytoTrial;