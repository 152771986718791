import { resInterface } from "@/api/interfaces";

import { IAgriculturalMachinery, IStatementFile } from "@/api/interfaces/responses";
import { STATUSES, IFile } from "@/components/FileDropzone/const";
import yup from "@/utils/yup";
import { v4 as uuidv4 } from 'uuid';

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    agrMachineryId?: number;
    initialValues?: resInterface.IAgriculturalMachinery;
  };
  onSubmit?: () => void;
}

export const  AGRICULTURAL_MACHINERY_NAME = {
  FILE_LIST : 'file_list',
  NAME :"name",
  TYPE: "type",
  IDENTIFICATION_NUMBER: 'identification_number',
  CONDITION: 'condition',
  PRODUCTION_YEAR :'production_year',
  LOCATION: 'location',

  REGION: 'region',
  PLOT: 'plot',
  BRANCH: 'branch'
} as const

export const AGR_MACHINERY_TYPES = [
  { value: 'tractor', label: 'Трактор' },
  { value: 'combine', label: 'Комбайн' },
  { value: 'attachment', label: 'Навесное оборудование' },
];


export interface IValue {
  id: number;
}

export interface TForm {
  [AGRICULTURAL_MACHINERY_NAME.NAME]: string,
  [AGRICULTURAL_MACHINERY_NAME.IDENTIFICATION_NUMBER]: string,
  [AGRICULTURAL_MACHINERY_NAME.TYPE]: string,
  [AGRICULTURAL_MACHINERY_NAME.CONDITION]: string,
  [AGRICULTURAL_MACHINERY_NAME.PRODUCTION_YEAR]: number,
  [AGRICULTURAL_MACHINERY_NAME.LOCATION]: IValue ,
  [AGRICULTURAL_MACHINERY_NAME.FILE_LIST]: IFile[],
}


export const mapValueToForm = (value: IAgriculturalMachinery) => {
  return {
    [AGRICULTURAL_MACHINERY_NAME.NAME]: value?.name || '',
    [AGRICULTURAL_MACHINERY_NAME.IDENTIFICATION_NUMBER]: value?.identification_number || '',
    [AGRICULTURAL_MACHINERY_NAME.TYPE]: value?.type || null,
    [AGRICULTURAL_MACHINERY_NAME.CONDITION]: value?.condition || null,
    [AGRICULTURAL_MACHINERY_NAME.PRODUCTION_YEAR]: value?.production_year ,
    [AGRICULTURAL_MACHINERY_NAME.LOCATION]: value?.location ? {
      ...value?.location,
      label: value.location?.name,
    }
      : "",
    [AGRICULTURAL_MACHINERY_NAME.PLOT]: value?.location ? {
      ...value?.location?.plot,
      label: value?.location?.plot?.name
    }
      : "",
    [AGRICULTURAL_MACHINERY_NAME.BRANCH]: value?.location ? {
      ...value?.location?.plot?.branch,
      label: value?.location?.plot?.branch?.name
    }
      : "",
    [AGRICULTURAL_MACHINERY_NAME.REGION]: value?.location ? {
      ...value?.location?.plot?.branch?.region,
      label: value?.location?.plot?.branch?.region?.name
    }
      : "",
    [AGRICULTURAL_MACHINERY_NAME.FILE_LIST]: value.files.map(serializeFile),
  }
};

export const mapFormToValue = (values: TForm) => {
  return {
    [AGRICULTURAL_MACHINERY_NAME.NAME]: values.name,
    [AGRICULTURAL_MACHINERY_NAME.IDENTIFICATION_NUMBER]: values.identification_number,
    [AGRICULTURAL_MACHINERY_NAME.TYPE]:values.type ,
    [AGRICULTURAL_MACHINERY_NAME.CONDITION]: values.condition,
    [AGRICULTURAL_MACHINERY_NAME.LOCATION]: values.location?.id || '',
    [AGRICULTURAL_MACHINERY_NAME.PRODUCTION_YEAR]: values.production_year,
    [AGRICULTURAL_MACHINERY_NAME.FILE_LIST]: values.file_list ? values.file_list.filter(el => !el.id) : []
  }
};

export const serializeFile = (file: IStatementFile) => {
  return {
    uuid: uuidv4(),
    id: file.id,
    name: file.file_name,
    formatSize: file.file_size,
    preview: file.file_url,
    status: STATUSES.SUCCESS
  };
};

/**
 * Validation schema using Yup for form validation.
 */
export const validationSchema = yup.object().shape({
  [AGRICULTURAL_MACHINERY_NAME.NAME]: yup.string().trim().required(),
  [AGRICULTURAL_MACHINERY_NAME.TYPE]: yup.mixed().required(),
  [AGRICULTURAL_MACHINERY_NAME.IDENTIFICATION_NUMBER]: yup.string().trim().required(),
});

