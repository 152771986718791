import {
  InfoField
} from "@/components/InfoField/InfoField";
import { Box, Typography } from "@mui/material";
import React from "react";
import { IBranchMain } from "@/api/interfaces/responses";

interface IProps {
  renderData: Pick<IBranchMain, "staff_director" | "actual_address" | "phones" | "emails" | "staffing" | "vacancies" | "total_area" | "agricultural_land" | "arable_land" | "trials_area" | "extrabudgetary_area" | "unused_area" | "unfit_land">;
}

const GeneralInfo: React.FC<IProps> = ({ renderData }) => {
  const RequestInfoPropsGeneral = {
    title: "Общие сведения",
    infoFieldElements: [
      {
        label: "Руководитель:",
        content: renderData?.staff_director?.full_name || "-",
      },
      {
        label: "Адрес: ",
        content: renderData?.actual_address?.one_line || "-",
      },
      {
        label: "Телефон: ",
        content: (Array.isArray(renderData.phones) && renderData.phones.length) ? (
          <Box display="flex" flexDirection="column" gap={1}>
            {
              renderData.phones.map((phone, index) =>
                <Typography key={index} variant="body2"
                            sx={{ fontWeight: 500 }}>{phone.phone}</Typography>)
            }
          </Box>
        ) : "-",
      },
      {
        label: "Почта: ",
        content: (Array.isArray(renderData.emails) && renderData.emails.length) ? (
          <Box display="flex" flexDirection="column" gap={1}>
            {
              renderData.emails.map((email, index) =>
                <Typography key={index} variant="body2"
                            sx={{ fontWeight: 500 }}>{email.email}</Typography>)
            }
          </Box>
        ) : "-",
      },
      {
        label: "Штатная численность:",
        content: `${renderData.staffing || 0} человек, ваканты ${renderData.vacancies || 0}`,
      },
    ],
  };

  const RequestInfoPropsSquare = {
    title: `Общая земельная площадь ${renderData.total_area} га, в том числе:`,
    infoFieldElements: [
      {
        label: "C/Х земли:",
        content: `${renderData.agricultural_land} га`,
      },
      {
        label: "Пашни: ",
        content: `${renderData.arable_land} га`,
      },
      {
        label: "Под сортоопытами: ",
        content: `${renderData.trials_area} га`,
      },
      {
        label: "Под производственную деятельность: ",
        content: `${renderData.extrabudgetary_area} га`,
      },
      {
        label: "Неиспользуемая:",
        content:`${renderData.unused_area} га`,
      },
      {
        label: "Неугодье, неудобье:",
        content: `${renderData.unfit_land} га`,
      },
    ],
  };

  return (
    <Box>
      <Typography mb={2} mt={2} variant="h3">
        {RequestInfoPropsGeneral.title}
      </Typography>
      {RequestInfoPropsGeneral.infoFieldElements.map(
        (infoFieldElement, index) => (
          <InfoField key={index} {...infoFieldElement} />
        ),
      )}
      <Box mt={"45px"}>
        <Typography mb={2} mt={3} variant="h3">
          {RequestInfoPropsSquare.title}
        </Typography>
        {RequestInfoPropsSquare.infoFieldElements.map(
          (infoFieldElement, index) => (
            <InfoField key={index} {...infoFieldElement} />
          ),
        )}
      </Box>
    </Box>
  );
};

export default GeneralInfo;
