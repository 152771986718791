import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import theme from "@/theme";
import { AddOutlined, TextSnippet } from "@mui/icons-material";

interface IProps {
  title: string;
  buttonText: string;
  onClick: () => void;
}

const EmptyItem: React.FC<IProps> = (props) => {
  const { title, buttonText, onClick } = props;

  return (
    <Stack
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: "100%" }}
    >
      <TextSnippet
        sx={{
          fontSize: 60,
          color: theme.palette.blackAndWhite.stroke,
          margin: "40px 0 30px",
        }}
      />
      <Typography variant="h3">{title}</Typography>
      <Button
        sx={{
          marginLeft: "24px",
          marginTop: "24px",
        }}
        color={"blue"}
        size={"large"}
        startIcon={<AddOutlined />}
        variant={"outlined"}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};

export default EmptyItem;
