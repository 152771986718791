import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { validationSchema } from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import QuantityForm from "./components/QuantityForm";
import { IProps, TForm } from "./types";

/**
 * Модалка с формой создания/редактирования справочника "Количество в разнарядках"
 * @param initialValues - начальные значения формы
 * @param onSubmit - коллбэк после успешного запроса
 * */

const Quantity: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const { quantityId, isDetail } = modalProps;
  const { api, toastStore, swrStore } = useStores();

  // филиал
  const initialBranchValue = {
    ...(modalProps?.initialValues?.plot?.branch as any),
    label: modalProps?.initialValues?.plot?.branch
      ?.name as any,
  };

  // регион
  const initialRegionValue = {
    ...modalProps?.initialValues?.plot?.branch?.region,
    label: modalProps?.initialValues?.plot?.branch?.region
      ?.name as any,
  };

  const defaultValues: TForm = Object.assign(
    {
      unit: null,
      material_type: null,
      plot: null,
      cultivar: null,
      quantity: null,
      type: null,
      branch: isDetail ? initialBranchValue : null, // филиал
      region: isDetail ? initialRegionValue : null, // регион
      non_field_errors: undefined
    },
    modalProps.initialValues,
  );

  const mutateDetail = quantityId
    ? swrStore.mutators[
        SWR_KEYS.getQuantityDistributionItem(quantityId)
      ]
    : null;

  const mutateList =
    swrStore.mutators[
      SWR_KEYS.getQuantityDistributionList()
    ];

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    const payload = {
      ...values,
      material_type: values.material_type
        ?.id as unknown as number,
      plot: values.plot?.id as unknown as number,
      cultivar: values.cultivar?.id as unknown as number,
      unit: values.unit?.id as unknown as number,
    };

    helpers.setSubmitting(true);
    const promise = quantityId
      ? api.regulatoryInfo.patchQuantityDistributionItem(
          quantityId,
          payload,
        )
      : api.regulatoryInfo.postQuantityDistributionItem(
          payload,
        );

    promise
      .then(() => {
        mutateDetail && mutateDetail();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors.non_field_errors) {
          const errorMessage = errorToString(error);
          helpers.setFieldError('non_field_errors', errorMessage)
        }
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail();
        mutateList && mutateList();
      });
  };

  const initialValues = modalProps?.initialValues
    ? {
        ...defaultValues,
        ...modalProps.initialValues,
        plot: {
          id: modalProps.initialValues.plot?.id,
          label: modalProps.initialValues.plot?.name,
        },
        material_type: {
          id: modalProps.initialValues.material_type?.id,
          label:
            modalProps.initialValues.material_type?.name,
        },
        unit: {
          id: modalProps.initialValues.unit?.id,
          label: modalProps.initialValues.unit?.name,
        },
        cultivar: {
          id: modalProps.initialValues.cultivar?.id,
          label: modalProps.initialValues.cultivar?.name,
        },
      }
    : defaultValues;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <QuantityForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      )}
    />
  );
};

export default Quantity;
