import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import useSWR, { SWRResponse } from "swr";
import qs from "qs";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Table from "@/apps/Table";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";

import {
  IOrder,
  IPaginatedResult,
  IPlanningHpList,
} from "@/api/interfaces/responses";
import { IErrorResponse, IValue } from "@/interfaces";

import {
  useInfiniteScroll,
  useQuery,
  useStores,
} from "@/hooks";
import useRightSidebar from "@/hooks/useRightSidebar";

import { columns } from "../models";
import { isUndefined } from "@/utils/helpers";
import { TPlanningHpKeys } from "../interfaces";
import { IPlanningHpQueryParams } from "@/api/interfaces/requests";
import {
  ADD_PLANING_HP_TEXT,
  EMPTY_LIST_PLANING_HP_TEXT,
  EMPTY_LIST_PLANING_HP_TEXT_WITH_FILTERS,
} from "../const";
import {
  KEYS_SIDEBAR_VARIANT_MODULES,
  ORDER_TYPE,
  SWR_KEYS,
} from "@/const";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Filters from "@/components/Filters";
import { getFilters } from "../utils";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { FileDownloadOutlined } from "@mui/icons-material";
import { CopyIcon } from "@/components/icons";
import useDebounce from "@/hooks/useDebounce";
import dayjs from "dayjs";

/**
 * Компонент, представляющий таблицу планирование ГСУ.
 * @returns {ReactElement}
 */
const PlanningHpTable = (): ReactElement => {
  const {
    api,
    queryStringSidebarCollector,
    swrStore,
    modalStore,
  } = useStores();
  const { handleOpen } = useRightSidebar();
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const query = useQuery<IPlanningHpQueryParams>();
  const [queryParams, setQueryParams] =
    useState<IPlanningHpQueryParams>(query);

  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orgeringArray);

  const [searchOrder, setSearchOrder] = useState("");
  const dbouncedSearchOrderValue = useDebounce(
    searchOrder,
    500,
  );

  const location = useLocation();

  const getKey = (index: number) => {
    return {
      key: SWR_KEYS.getOrderList(),
      page: index + 1,
      search: dbouncedSearchOrderValue,
      order_type: ORDER_TYPE.HP_GROUPING,
    };
  };

  const fetcher = (args) => {
    const { page, search, order_type } = args;
    return api.regulatoryInfo.getOrderList({
      page,
      search,
      order_type,
    });
  };

  const {
    data: orderArr,
    handleScroll: handleOrderScroll,
  } = useInfiniteScroll<IOrder>(getKey, fetcher, {
    revalidateFirstPage: false,
  });

  const orders: IValue[] = orderArr.map(
    ({ number, id, order_date }) => {
      const label = `${number} - ${dayjs(order_date).format(
        "DD.MM.YYYY",
      )}`;
      return { label, key: id };
    },
  );

  const {
    data,
    error,
    isLoading,
    mutate,
  }: SWRResponse<
    IPaginatedResult<IPlanningHpList>,
    IErrorResponse
  > = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      key: SWR_KEYS.getPlanningHpTable(),
      ordering: orderBy.join(","),
      search: queryParams?.search,
      order__year: queryParams?.year,
      status: queryParams.status?.key,
      order: queryParams.order_number?.key,
    },
    api.planning.getPlanningHp,
  );

  swrStore.addMutator(
    SWR_KEYS.getPlanningHpTable(),
    mutate,
  );

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (
    value: TPlanningHpKeys[],
  ) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */
  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {
    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent: KEYS_SIDEBAR_VARIANT_MODULES.PLANNING_HP,
      module_id: id,
    });

    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.PLANNING_HP,
      modalProps: {
        id,
        keySidebar:
          KEYS_SIDEBAR_VARIANT_MODULES.PLANNING_HP,
      },
    });
    setSearchParams(str);
  };

  const handleDownloadOrder = (payload: IOrder) =>
    api.planning.downloadOrder(payload.id, payload.name);

  const handleCopyOrder = (from: number, to: number) => {
    return api.planning
      .copyPlanHpOrder(from, to)
      .then(() => {
        mutate();
      });
  };

  const handleOpenSelectOrderModal = () => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_ORDER, {
      theme: THEME_MODAL.W_555,
      fetcher: handleDownloadOrder,
      q: {
        order_type: ORDER_TYPE.HP_GROUPING,
      },
    });
  };

  const handleOpenCopyModal = () => {
    modalStore.open(VARIANT_MODAL.COPY_ORDER, {
      theme: THEME_MODAL.W_555,
      handleAccept: handleCopyOrder,
    });
  };

  useEffect(() => {
    setQueryParams(query);
  }, [location.search]);

  const handleCreateCultivar = () => {
    navigate(ROUTES_PATHS.planningHpAdd);
  };
  const filters = getFilters(
    query.status,
    query?.order_number,
    orders,
    setSearchOrder,
    handleOrderScroll,
  );

  return (
    <Fragment>
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            flexWrap: "wrap",
          }}
        >
          <Button
            color="blue"
            variant="outlined"
            startIcon={<FileDownloadOutlined />}
            onClick={handleOpenSelectOrderModal}
          >
            Документы
          </Button>
          <Button
            sx={{
              whiteSpace: "nowrap",
              flexShrink: "0",
            }}
            color="blue"
            variant="outlined"
            startIcon={<CopyIcon />}
            onClick={handleOpenCopyModal}
          >
            Копировать приказ
          </Button>

          <Button
            sx={{
              whiteSpace: "nowrap",
              flexShrink: "0",
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateCultivar}
          >
            Добавить культуру
          </Button>
        </Box>
      </Box>

      <Table<IPlanningHpList, TPlanningHpKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={false}
        textAdd={ADD_PLANING_HP_TEXT}
        textEmptyTableWithFilter={
          EMPTY_LIST_PLANING_HP_TEXT_WITH_FILTERS
        }
        textEmptyTable={EMPTY_LIST_PLANING_HP_TEXT}
        onRowClick={handleOpenRightSidebar}
      />
    </Fragment>
  );
};

export default PlanningHpTable;
