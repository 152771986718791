import React from "react";
import { Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Box, Button, Divider, } from "@mui/material";
import { FIELDS_NAME, IFormik } from "../const";
import { useStores } from "@/hooks";
import {
  PlotAutoComplete
} from "@/components/Autocompletes";
import { RangeDatePickerField } from "@/components/Fields";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";

const DownloadSeedArrivalJournalForm = () => {
  const { modalStore } = useStores();
  const formik = useFormikContext<IFormik>();

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <PlotAutoComplete
          name={FIELDS_NAME.PLOT} required
        />
        <RangeDatePickerField name={FIELDS_NAME.DATE}
                              label="Дата (период от и до)"
                              fullWidth={true}/>
      </Stack>
      <Divider/>
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red"
                onClick={() => modalStore.close()}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<FileDownloadOutlinedIcon/>}
          disabled={formik.isSubmitting}
        >
          Скачать
        </Button>
      </Box>
    </Form>
  );
};

export default DownloadSeedArrivalJournalForm;
