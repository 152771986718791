export const enum FiltersType {
  date = "date",
  datesRange = "datesRange",
  select = "select",
  autoComplete = "autocomplete",
  yearsRange = "yearsRange",
  yearPicker = "yearPicker",
  checkbox = 'checkbox',
}

export interface IYearsRangeQueryParams {
  year_max?: number;
  year_min?: number;
}
