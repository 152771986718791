import { IFilter, IValue } from "@/interfaces";

import { PLANNING_STATUS_INPUT } from "../const";
import { FiltersType } from "@/components/Filters/interfaces";

export const getFilters = (
  statementTypeValue?: IValue,
  orderValue?: IValue,
  orders?: IValue[],
  setValue?: any,
  handleOrderScroll?: any,
): IFilter[] => {
  return [
    {
      name: "order_number",
      label: "Приказ",
      type: FiltersType.autoComplete,
      selectedValue: orderValue,
      options: orders,
      value: "",
      searchKey: "search_order_number",
      handleScroll: handleOrderScroll,
      style: { minWidth: "180px", flexGrow: 1 },
      setValue: setValue,
    },
    {
      name: "status",
      label: "Статус",
      type: FiltersType.autoComplete,
      selectedValue: statementTypeValue,
      options: PLANNING_STATUS_INPUT,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
  ];
};
