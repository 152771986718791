import { Chip } from "@mui/material";
import theme from "@/theme";
import React from "react";
import { TUploadExcelStatus } from "@/api/types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { STATUSES_UPLOAD_STATUS_KEYS } from "@/const";
import { ProgressActivityIcon, } from "@/components/icons";

const ICON = {
  [STATUSES_UPLOAD_STATUS_KEYS.COMPLETED]:
    <CheckIcon
      color="inherit"/>,
  [STATUSES_UPLOAD_STATUS_KEYS.FAILED]: <CloseIcon
    color="inherit"/>,
  [STATUSES_UPLOAD_STATUS_KEYS.PROCESSING]:
    <ProgressActivityIcon color="inherit"/>,
};

const LABEL = {
  [STATUSES_UPLOAD_STATUS_KEYS.COMPLETED]: "Выполнено",
  [STATUSES_UPLOAD_STATUS_KEYS.FAILED]: "Ошибка",
  [STATUSES_UPLOAD_STATUS_KEYS.PROCESSING]: "Обработка",
};

const COLOR = {
  [STATUSES_UPLOAD_STATUS_KEYS.COMPLETED]: theme.palette.primary.main,
  [STATUSES_UPLOAD_STATUS_KEYS.FAILED]: theme.palette.error.main,
  [STATUSES_UPLOAD_STATUS_KEYS.PROCESSING]: theme.palette.blue.main,
};

interface IProps {
  status: TUploadExcelStatus;
}

const StatementUploadExcelStatusChip: React.FC<IProps> = ({ status }) => {
  const icon = ICON[status] || undefined;
  const label = LABEL[status] || "Неизвестно";
  const color = COLOR[status] || theme.palette.blackAndWhite.gray;
  return (
    <Chip size="small"
          variant="outlined"
          icon={icon}
          label={label}
          sx={{
            color: color,
            borderColor: color
          }}
    />
  );
};

export default StatementUploadExcelStatusChip;