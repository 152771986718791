import { IColumn } from "@/apps/Table/interfaces";
import { TCultivarUsageType } from "../interfaces";
import { CULTIVAR_USAGE_TYPE_KEYS, CULTIVAR_USAGE_TYPE_LABELS } from "../const";
import { CultivarTextCell, PointerTextCell } from "../cells";
import {cellButtonsMaterialType,cellPointText, headerCellStyle } from "../style";
import { EditingCellsCultivarUsageType, ProductFormCell } from "../templates/CultivarUsageType/cells";

export const COLUMN_EDITING = {
    value: "editing",
    label: "",
};

export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const CultivarUsageTypeColumns: IColumn<TCultivarUsageType>[] = [
    {
        label: CULTIVAR_USAGE_TYPE_LABELS.cultivar,
        key: CULTIVAR_USAGE_TYPE_KEYS.cultivar,
        contentHeader: CULTIVAR_USAGE_TYPE_LABELS.cultivar,
        bodyCellProps: {
            render: CultivarTextCell,
            styles: cellPointText,
        },
        headerCellProps: {
            styles: headerCellStyle,
        },
    },
    {
        label: CULTIVAR_USAGE_TYPE_LABELS.product_form,
        key: CULTIVAR_USAGE_TYPE_KEYS.product_form,
        contentHeader: CULTIVAR_USAGE_TYPE_LABELS.product_form,
        bodyCellProps: {
          render: ProductFormCell,
          styles: cellPointText,
        },
        headerCellProps: {
          styles: headerCellStyle,
        },
      },
      {
        label: CULTIVAR_USAGE_TYPE_LABELS.name,
        key: CULTIVAR_USAGE_TYPE_KEYS.name,
        contentHeader: CULTIVAR_USAGE_TYPE_LABELS.name,
        bodyCellProps: {
          render: PointerTextCell,
          styles: cellPointText,
        },
        headerCellProps: {
          styles: headerCellStyle,
        },
      },
      {
        key: "editing",
        label: CULTIVAR_USAGE_TYPE_LABELS.editing,
        contentHeader: COLUMN_EDITING.label,
        bodyCellProps: {
          render: EditingCellsCultivarUsageType,
          styles: cellButtonsMaterialType,
        },
      },
];