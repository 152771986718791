import React, { Fragment, useMemo, useState } from "react";
import {
  useNavigate,
  useSearchParams
} from "react-router-dom";
import qs from "qs";
import useSWR from "swr";
import {
  useInfiniteScroll,
  useQuery,
  useRightSidebar,
  useStores
} from "@/hooks";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  menuItemClasses
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import Table from "@/apps/Table";
import Filters from "@/components/Filters";
import {
  IQueryParams,
  TSeedArrivalSortableKeys
} from "../interfaces";
import { isUndefined } from "@/utils/helpers";
import {
  KEYS_SIDEBAR_VARIANT_MODULES,
  SWR_KEYS,
  DOWNLOAD_SEED_ARRIVAL
} from "@/const";
import { columns } from "../models";
import {
  ADD_TEXT,
  EMPTY_LIST_TEXT,
  EMPTY_LIST_TEXT_WITH_FILTERS,
} from "../const";
import {
  ISeedArrivalListItem
} from "@/api/interfaces/responses";
import { ROUTES } from "@/apps/AppRouter/const";
import { getFilters } from "../utils";
import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";
import useDebounce from "@/hooks/useDebounce";
import { IValue } from "@/interfaces";
import {
  VARIANT_MODAL,
  THEME_MODAL
} from "@/apps/Modals/const";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

const AWTable: React.FC = () => {
  const {
    api,
    swrStore,
    queryStringSidebarCollector,
    modalStore,
    userStore
  } = useStores();
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { handleOpen } = useRightSidebar();
  const query = useQuery<IQueryParams>();

  // переменная принимающая параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orderingArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering) ? query.ordering : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] = useState<string[]>([
    ...new Set(["-created_at", ...orderingArray])
  ]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [page, setPage] = useState<number>(query.page ?? 1);
  const [searchGsu, setSearchGsu] = useState("");
  const dbouncedSearchGsu = useDebounce(
    searchGsu,
    500,
  );

  const KEY_SWR = SWR_KEYS.getSeedArrivalList();
  const { data, error, isLoading, mutate } = useSWR(
    {
      page: page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: query.search ?? undefined,
      status: query.status?.key as string ?? undefined,
      receipt_at_after: query.date_range_after ?? undefined,
      receipt_at_before: query.date_range_before ?? undefined,
      plot: query.plot?.key ?? undefined,
      _key: KEY_SWR
    },
    api.agriculturalLabour.getSeedArrivalList
  );

  const handleChangePage = (value: number) => {
    setPage(value);

    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" }
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value: TSeedArrivalSortableKeys[]) => {
    const params = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" }
    );
    setSearchParams(params);
    setOrderBy(value);
  };

  const handleCreate = () => {
    navigate(`${ROUTES.addSeedArrival}`);
  };

  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {
    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent: KEYS_SIDEBAR_VARIANT_MODULES.SEED_ARRIVAL,
      module_id: id,
    });

    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.SEED_ARRIVAL,
      modalProps: {
        id,
      },
    });

    setSearchParams(str);
  };

  const getGsuKey = (index: number) => {
    return {
      key: SWR_KEYS.getPlotList(),
      page: index + 1,
      search: dbouncedSearchGsu,
    };
  };

  const fetcherGsu = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getPlotList(
      {
        page,
        search,
      },
    );
  };

  const {
    data: gsuData,
    handleScroll: handleGsuScroll,
  } = useInfiniteScroll(getGsuKey, fetcherGsu, {
    revalidateFirstPage: false,
  });

  const gsuList: IValue[] = gsuData.map(
    ({ name, id }) => {
      return { label: name, key: id };
    },
  );

  const filters = getFilters(
    [
      query.date_range_after,
      query.date_range_before,
    ],
    gsuList,
    query.plot,
    handleGsuScroll,
    setSearchGsu,
    query.status,
  );

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadSeedArrivalJournal = () => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_SEED_ARRIVAL_JOURNAL, {
      theme: THEME_MODAL.W_555,
      type: DOWNLOAD_SEED_ARRIVAL.JOURNAL,
      title: "Журнал учета поступления семян"
    });
    handleClose();
  };

  const handleDownloadSeedArrival = () => {
    modalStore.open(VARIANT_MODAL.DOWNLOAD_SEED_ARRIVAL_JOURNAL, {
      theme: THEME_MODAL.W_555,
      type: DOWNLOAD_SEED_ARRIVAL.DEFAULT,
      title: "Сведения ГСУ"
    });
    handleClose();
  };

  swrStore.addMutator(KEY_SWR, mutate);

  const isAdd = userStore.isPermission(
    PERMISSIONS.agricultural_labourSeedArrival,
    PERMISSION_CRUD.add
  )

  return (
    <Fragment>
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px"
        }}
      >
        <Filters filters={filters}
                 defaultSearch={query.search}/>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px"
          }}
        >
          <Button
            color="blue"
            onClick={handleClick}
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon/>}
          >
            Документы
          </Button>
          {isAdd && (
          <Button
            sx={{
              whiteSpace: "nowrap",
              flexShrink: "0"
            }}
            variant="contained"
            startIcon={<AddIcon/>}
            onClick={handleCreate}
          >
            Поступление
          </Button>
          )}
        </Box>
      </Box>

      <Table<ISeedArrivalListItem, TSeedArrivalSortableKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={page}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={false}
        textAdd={ADD_TEXT}
        textEmptyTableWithFilter={EMPTY_LIST_TEXT_WITH_FILTERS}
        textEmptyTable={EMPTY_LIST_TEXT}
        onRowClick={handleOpenRightSidebar}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ [`.${menuItemClasses.root}`]: { textWrap: "wrap" } }}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <MenuItem
          onClick={handleDownloadSeedArrivalJournal}>
          Журнал учета поступления семян
        </MenuItem>
        <MenuItem
          onClick={handleDownloadSeedArrival}>
          Сведения о потребности, обеспеченности, сортовых и
          посевных качествах семенных и страховых фондов на
          ГСУ
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default AWTable;
