import React from "react";
import { Box, Typography } from "@mui/material";
import GSUCard from "./GSUCard";
import {
  IBranchMainDistrict
} from "@/api/interfaces/responses";
import theme from "@/theme";
import { TextSnippet } from "@mui/icons-material";

interface IProps {
  districts: IBranchMainDistrict[];
}

const GSU: React.FC<IProps> = ({ districts }) => {
  return (
    <Box>
      <Typography mb={2} mt={2} variant="h3">
        ГСУ
      </Typography>
      {districts.length ? districts.map((district) => (
        <GSUCard key={district.id} district={district}/>
      )) : (
        <Box p={3} display="flex" alignItems="center"
             justifyContent="center" flexDirection="column">
          <TextSnippet
            sx={{
              fontSize: 60,
              color: theme.palette.blackAndWhite.stroke,
            }}
          /><Typography variant="h2">Список ГСУ
          пуст</Typography></Box>
      )}
    </Box>
  );
};

export default GSU;
