import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const EmptyFIeld: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
      >
        <mask
          id="mask0_3647_140222"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="60"
          height="60"
        >
          <rect width="60" height="60" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3647_140222)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.78125 51.2188C9.46875 51.9063 10.2083 52.3333 11 52.5L52.5625 11C52.3125 10.1667 51.875 9.42708 51.25 8.78125C50.625 8.13542 49.875 7.70833 49 7.5L7.5 49C7.66667 49.7917 8.09375 50.5313 8.78125 51.2188ZM7.5 29.75V36.75L36.75 7.5H29.75L7.5 29.75ZM7.5 12.5V17.5L17.5 7.5H12.5C11.125 7.5 9.94792 7.98958 8.96875 8.96875C7.98958 9.94792 7.5 11.125 7.5 12.5ZM52.5 30.25V23.25L45.75 30C46.75 30.0833 47.7188 30.2396 48.6562 30.4688C49.5938 30.6979 50.4792 31.0208 51.3125 31.4375L52.5 30.25ZM30 45.75L23.25 52.5H30.25L31.4375 51.3125C31.0208 50.4792 30.6979 49.5938 30.4688 48.6562C30.2396 47.7188 30.0833 46.75 30 45.75Z"
            fill="#D7D7E1"
          />
          <path
            d="M51.3125 31.4375C50.4792 31.0208 49.5938 30.6979 48.6562 30.4688C47.7188 30.2396 46.75 30.0833 45.75 30L30 45.75C30.0833 46.75 30.2396 47.7188 30.4688 48.6562C30.6979 49.5938 31.0208 50.4792 31.4375 51.3125L35.25 47.5H35V42.5H40.25L42.5 40.25V35H47.5V35.25L51.3125 31.4375Z"
            fill="#D7D7E1"
          />
          <path d="M35 42.5V47.5H35.25L40.25 42.5H35Z" fill="#D7D7E1" />
          <path d="M47.5 35H42.5V40.25L47.5 35.25V35Z" fill="#D7D7E1" />
          <path
            d="M52.5 47.5V42.5L42.5 52.5H47.5C48.875 52.5 50.0521 52.0104 51.0312 51.0312C52.0104 50.0521 52.5 48.875 52.5 47.5Z"
            fill="#D7D7E1"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default EmptyFIeld;
