import React, {
  createContext,
  ReactNode,
  useContext
} from "react";
import { Theme } from "@mui/material";
import Media, { QueryResults } from "react-media";

/**
 * Тип для MatchesContext
 * @typedef {Object} MatchesContextType
 * @property {QueryResults} [matches] - Результаты медиа-запросов.
 * @property {ReactNode} [children] - Дочерние элементы.
 */
interface MatchesContextType {
  matches?: QueryResults;
  children?: ReactNode;
}
export interface WrapperProps {
  theme?: Theme; // Замени Theme на тип своей темы
  matches?: QueryResults | undefined;
}

// Создание контекста
const MatchesContext = createContext<MatchesContextType>({
  matches: undefined,
  children: <></>
});

// Hook для использования контекста
/**
 * Hook для получения данных из MatchesContext.
 * @function
 * @returns {MatchesContextType} Объект с результатами медиа-запросов и дочерними элементами.
 */
export const useMatches = () => {
  return useContext(MatchesContext);
};

// Компонент-поставщик для контекста
/**
 * MatchesProvider - поставщик контекста с результатами медиа-запросов.
 * @param {MatchesContextType} props - Пропсы компонента.
 * @returns {ReactNode} Дочерние элементы.
 */
export const MatchesProvider: React.FC<MatchesContextType> = ({
  children,
  matches
}) => {
  return (
    <MatchesContext.Provider value={{ matches, children }}>
      {children}
    </MatchesContext.Provider>
  );
};

/**
 * Тип пропсов для компонента Responsive.
 * @typedef {Object} TPropResponsive
 * @property {ReactNode} children - Дочерние элементы, которые будут отрендерены в зависимости от размера экрана.
 */
type TPropResponsive = {
  children: ReactNode;
};

/**
 * Тип пропсов для оберток (Small, Regular, Medium, Large).
 * @typedef {Object} TPropWrapp
 * @property {React.ReactNode} children - Дочерние элементы, которые будут отрендерены, если условие соответствует.
 * @property {QueryResults} matches - Объект с булевыми значениями, указывающими, соответствует ли текущий размер экрана каждому типу.
 */
type TPropWrapp = {
  children: React.ReactNode;
  matches: QueryResults;
};

/**
 * Ключи медиа-запросов.
 * @constant
 * @type {Object}
 * @property {string} small - Ключ для маленького экрана.
 * @property {string} regular - Ключ для планшетного экрана.
 * @property {string} medium - Ключ для среднего экрана.
 * @property {string} large - Ключ для большого экрана.
 */
export const KEYS_BREAK_POINTS = {
  small: "small",
  regular: "regular",
  medium: "medium",
  large: "large"
};

/**
 * Объект с медиа-запросами.
 * @constant
 * @type {Object}
 * @property {string} small - Медиа-запрос для маленького экрана.
 * @property {string} regular - Медиа-запрос для планшетного экрана.
 * @property {string} medium - Медиа-запрос для среднего экрана.
 * @property {string} large - Медиа-запрос для большого экрана.
 */
const BREAK_POINTS = {
  [KEYS_BREAK_POINTS.small]: "(max-width: 599.98px)",
  [KEYS_BREAK_POINTS.regular]: "(min-width: 600px) and (max-width: 1023.98px)",
  [KEYS_BREAK_POINTS.medium]: "(min-width: 1024px) and (max-width: 1199.98px)",
  [KEYS_BREAK_POINTS.large]: "(min-width: 1200px)"
};

/**
 * Компонент Responsive для реагирования на размер экрана.
 * @param {TPropResponsive} props - Пропсы компонента Responsive.
 * @returns {ReactNode} Дочерние элементы.
 */
const Responsive: React.FC<TPropResponsive> = ({ children }) => {
  return (
    <Media queries={BREAK_POINTS}>
      {(matches) => {
        return <MatchesProvider matches={matches}>{children}</MatchesProvider>;
      }}
    </Media>
  );
};

/**
 * Компонент Small для рендеринга дочерних элементов на маленьком экране.
 * @param {TPropWrapp} props - Пропсы компонента Small.
 * @returns {ReactNode|null} Дочерние элементы, если условие соответствует, в противном случае null.
 */
const Small: React.FC<TPropWrapp> = ({ children, matches }) => {
  if (matches.small) return <>{children}</>;
  return null;
};

/**
 * Компонент Regular для рендеринга дочерних элементов на среднем экране.
 * @param {TPropWrapp} props - Пропсы компонента Regular.
 * @returns {ReactNode|null} Дочерние элементы, если условие соответствует, в противном случае null.
 */
const Regular: React.FC<TPropWrapp> = ({ children, matches }) => {
  if (matches.regular) return <>{children}</>;
  return null;
};

/**
 * Компонент Medium для рендеринга дочерних элементов на среднем экране.
 * @param {TPropWrapp} props - Пропсы компонента Medium.
 * @returns {ReactNode|null} Дочерние элементы, если условие соответствует, в противном случае null.
 */
const Medium: React.FC<TPropWrapp> = ({ children, matches }) => {
  if (matches.medium) return <>{children}</>;
  return null;
};

/**
 * Компонент Large для рендеринга дочерних элементов на большом экране.
 * @param {TPropWrapp} props - Пропсы компонента Large.
 * @returns {ReactNode|null} Дочерние элементы, если условие соответствует, в противном случае null.
 */
const Large: React.FC<TPropWrapp> = ({ children, matches }) => {
  if (matches.large) return <>{children}</>;
  return null;
};

export default { Responsive, Small, Regular, Medium, Large };
