import React, { useEffect, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
    Box,
    Divider,
    IconButton,
    Menu,
    Typography,
} from "@mui/material";
import { EditIcon, DeleteIcon } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { SWR_KEYS } from "@/const";
import { TYPES_ARRAY } from "../Methodology/cells";
import { resInterface } from "@/api/interfaces";

export const COLUMN_EDITING = {
    value: "editing",
    label: "",
};

export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
};

export const IndicatorTypeCell = (
    _: string,
    cell: any,
): JSX.Element => {
    const { type_of_trial: type } = cell;
    const decoratedType = TYPES_ARRAY.find(
        (item) => item.name === type,
    );
    return (
        <Box>
            <Typography
                sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "5px",
                }}
                fontSize={14}
                color={decoratedType?.textColor}
            >
                {decoratedType?.icon}
                {decoratedType?.label}
            </Typography>
        </Box>
    );
};


export const APPLICANTS = {
    individual: "ФЛ",
    legal_entity: "ЮЛ",
} as const;

export const ApplicantCell = (_: string,
    cell: resInterface.IBudgetaryTrailCalc) => {
    const {type_of_trial} = cell
    const {type_of_applicant} = cell
    return (
        <Box >
            <Typography fontSize={14}>{type_of_applicant ? (type_of_trial === 'hp' ? APPLICANTS[type_of_applicant] : "-") : "-"}</Typography>
        </Box>
    )
}

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCellBudgetaryTrailCalc = (_: string, cell: any, itemsInPage: any[]): JSX.Element => {
    const { api, modalStore, swrStore } = useStores();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const query = useQuery<any>();
    const [, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (itemsInPage.length === 1) {
            const params = qs.stringify(
                { ...query, last: 'true' },
                { arrayFormat: "comma" },
            );
            setSearchParams(params);
        } else {
            const params = qs.stringify(
                { ...query, last: undefined },
                { arrayFormat: "comma" },
            );
            setSearchParams(params);
        }
    }, [itemsInPage.length]);



    // функция открытия/закрытия компонента дропдауна
    const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    // функция закрытия компонента дропдауна (для клика вне попапа)
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        modalStore.open(VARIANT_MODAL.BUDGETARY_TRIAL_CALC, {
            theme: THEME_MODAL.W_555,
            initialValues: cell,
            budgetaryTrialCalcId: cell.id,
        });
        setAnchorEl(null);
    };

    const mutateList =
        swrStore.mutators[SWR_KEYS.getBudgetaryTrailCalc()];

    const deleteWithCheckingLast = () => {
        // если один эл-т на странице перенаправляем на предыдущую страницу
        if (itemsInPage.length === 1 && query.page > 1) {
            const params = qs.stringify(
                { ...query, page: query.page - 1 },
                { arrayFormat: "comma" },
            );
            setSearchParams(params);
        } else {
            mutateList && mutateList();
        }
    };

    const handleDelete = (resolve, reject) => {
        api.trial.deleteBudgetaryTrailCalc(cell.id).then(
            () => {
                deleteWithCheckingLast();
                resolve()
            }
        ).catch(reject);
        setAnchorEl(null);
    };

    const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        const modalProp = {
            theme: THEME_MODAL.W_555,
            type: CELL_TYPES.handbook,
            id: cell.id,
            handleDelete: handleDelete,
        };

        modalStore.setTheme(THEME_MODAL.W_555);
        modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
    };

    return (
        <Box>
            <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <StyledMenuItem onClick={handleClickEdit}>
                    <EditIcon />
                    {EDITING_CELL.editing}
                </StyledMenuItem>
                <Divider />
                <StyledMenuItem onClick={handleClickDelete}>
                    <DeleteIcon color={"error"} />
                    <Typography color='error'>{EDITING_CELL.delete}</Typography>
                </StyledMenuItem>
            </Menu>
        </Box>
    );
};
