import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {
  IOffBudget,
  IOffBudgetFile,
  TBranch,
  TRegion,
} from "@/api/interfaces/requests";
import {
  IEffectivityList
} from "@/api/interfaces/responses";
import {
  PLAN_FIELDS_NAME,
  IPlanForm,
  IEfficiencyItem,
} from "@/pages/AddPlan/const";
import {
  EFFECTIVITY_FIELD_NAMES
} from "@/apps/RightSidebar/templates/OffBudget/firstLevel/components/Effectivity/const";

export const mapValueToForm = (value?: IOffBudget | null) => ({
  [PLAN_FIELDS_NAME.YEAR]: value?.year ? dayjs().year(value.year) : undefined,
  [PLAN_FIELDS_NAME.REGION]: transformAutoCompleteValue(value?.branch.region),
  [PLAN_FIELDS_NAME.BRANCH]: transformAutoCompleteValue(value?.branch),
  [PLAN_FIELDS_NAME.NAME]: value?.name ?? "",
  [PLAN_FIELDS_NAME.FILE_LIST]: filesSerializer(value?.files),
});

const transformAutoCompleteValue = (value?: TBranch | TRegion) => {
  if (!value) return null;

  return {
    id: value?.id,
    label: value?.name,
  };
};

const filesSerializer = (files?: IOffBudgetFile[]) =>
  files?.map((file) =>
    Object.assign(file, {
      uuid: uuidv4(),
      name: file.file_name,
      status: "success",
      formatSize: file.file_size,
    })
  );

export const formToValueSerializer = (values: IPlanForm): FormData => {
  const formData = new FormData();

  formData.append(
    PLAN_FIELDS_NAME.YEAR,
    String(new Date(dayjs(values.year).toString()).getFullYear())
  );
  formData.append(PLAN_FIELDS_NAME.BRANCH, String(values?.branch?.id));
  formData.append(PLAN_FIELDS_NAME.NAME, values?.name);
  if (values?.file_list?.length) {
    values?.file_list.forEach((file) => {
      if(file.status !== "error"){
        formData.append(PLAN_FIELDS_NAME.FILE_LIST, file);
      }
    });
  }

  return formData;
};

export const editFormToValueSerializer = (values: IPlanForm) => ({
  [PLAN_FIELDS_NAME.YEAR]: new Date(
    dayjs(values.year).toString()
  ).getFullYear(),
  [PLAN_FIELDS_NAME.BRANCH]: Number(values?.branch?.id),
  [PLAN_FIELDS_NAME.NAME]: values?.name,
});

export const effectivityListToItemsSerializer = (
  data: IEffectivityList[]
): IEfficiencyItem[] => {
  const items: IEfficiencyItem[] = [];

  data.forEach((element) => {
    const effectivityArray = Object.entries(element);

    effectivityArray.forEach((effectivity, index) => {
      const item = {
        id: `${element.year}${index}`,
        year: element.year,
        label: EFFECTIVITY_FIELD_NAMES[effectivity[0]],
        value: effectivity[1],
      };

      if (item.label) {
        items.push(item);
      }
    });
  });

  return items;
};
