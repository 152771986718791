import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import Autocomplete, {
  SearchFilterProps
} from "@/components/Autocomplete";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import {
  IAgriculturalLabourLocation
} from "@/api/interfaces/responses";
import {
  IAgriculturalLabourLocationList
} from "@/api/interfaces/requests";

interface IProps
  extends Partial<SearchFilterProps<IAgriculturalLabourLocation>> {
  name: string;
  q?: IAgriculturalLabourLocationList;
  handleChange: (payload: IAgriculturalLabourLocation) => void;
  initialSearch?: string;
}

/**
 * Автокомплит со списком участков
 * @param name - имя
 * @param q - квери параметры для запроса
 * @param handleChange - калбек при выборе
 * @param initialSearch - начальное значение поиска
 */

const LocationAutocomplete: React.FC<IProps> = ({
  name,
  initialSearch = "",
  handleChange,
  q = {},
  ...props
}) => {
  const swrKeyLocation = SWR_KEYS.getAgriculturalLabourLocationList();
  const [search, setSearch] = useState(initialSearch);
  const formik = useFormikContext();
  const { api } = useStores();
  const getKeyRegion = (index: number) => {
    return {
      _key: swrKeyLocation,
      page: index + 1,
      search: search,
      ...q
    };
  };
  const { data, handleScroll } = useInfiniteScroll(
    getKeyRegion,
    api.agriculturalLabour.getLocationList,
    {
      revalidateFirstPage: false
    }
  );
  const onChange = (_, payload: IAgriculturalLabourLocation) => {
    handleChange ? handleChange(payload) : formik.setFieldValue(name, payload);
  };

  if (!formik) {
    throw new Error("LocationAutocomplete has to be used within <Formik>");
  }

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Autocomplete<IAgriculturalLabourLocation>
          label="Участок"
          {...field}
          {...props}
          onChange={onChange}
          search={setSearch}
          data={data}
          handleScroll={handleScroll}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
    </Field>
  );
};

export default LocationAutocomplete;
