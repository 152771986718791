import yup from "@/utils/yup";

import {
  reqInterface,
  resInterface
} from "@/api/interfaces";

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    key: string;
    diseaseId?: number;
    initialValues?: resInterface.IDisease;
    handleAccept?: (payload: resInterface.IDisease) => void
  };
  onSubmit?: () => void;
}

export interface ICultivarDisease {
  id: number;
  name: string;
}

export type TForm = Omit<reqInterface.IDisease, "cultivar" | "editor" | "creator"> & {
  cultivar: ICultivarDisease[]
};

export enum DISEASE_FIELD_NAME {
  NAME = "name",
  DESCRIPTION = "description",
  CULTIVAR = "cultivar",
}

export const validationSchema = yup.object().shape({
  [DISEASE_FIELD_NAME.NAME]: yup.string()
      .trim()
      .matches(/^(?! *$).+$/, 'Поле не может состоять только из пробелов')
      .required(),
});

export const mapValueToForm = (
  baseInititialValues,
  diseaseData: resInterface.IDisease,
): TForm => {
  return {
    ...baseInititialValues,
    ...{
      [DISEASE_FIELD_NAME.NAME]: diseaseData.name,
      [DISEASE_FIELD_NAME.DESCRIPTION]: diseaseData.description,
      [DISEASE_FIELD_NAME.CULTIVAR]: diseaseData.cultivar,
    },
  };
};

export const mapFormToValue = (values: TForm) => {
  return {
    [DISEASE_FIELD_NAME.NAME]: values.name,
    [DISEASE_FIELD_NAME.DESCRIPTION]: values.description,
    [DISEASE_FIELD_NAME.CULTIVAR]: values.cultivar.map(i => i.id),
    is_active: values.is_active,
  };
};
