import React from "react";
import { useStores } from "@/hooks";
import { Stack, styled } from "@mui/system";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel
} from "@mui/material";
import { FIELDS_NAME } from "../const";
import {
  Field,
  Form as FormikForm,
  useFormikContext
} from "formik";
import {
  IFormik
} from "@/apps/Modals/ModalContent/CreateStatement/const";
import {
  ICultivar,
  IVariety
} from "@/api/interfaces/responses";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import Typography from "@mui/material/Typography";
import {
  StyledChip
} from "@/apps/Modals/ModalContent/CreateVariety/styles";
import AddChip from "@/components/Chips/AddChip";

interface IProps {
  modalKey: string;
}

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.blackAndWhite.black,
  fontWeight: 500
}));

const Form: React.FC<IProps> = ({ modalKey }) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<IFormik>();
  const handleSelectCultivar = (payload: ICultivar) => {
    modalStore.setModalPropsByKey(modalKey, {
      initialValues: {
        ...formik.values,
        [FIELDS_NAME.CULTIVAR]: payload
      }
    });
  };
  const handleSelectVariety = (payload: IVariety) => {
    modalStore.setModalPropsByKey(modalKey, {
      initialValues: {
        ...formik.values,
        [FIELDS_NAME.VARIETY]: payload
      }
    });
  };
  const handleAddCultivar = () => {
    modalStore.open(VARIANT_MODAL.SELECT_CULTIVAR, { onSubmit: handleSelectCultivar });
  };
  const handleAddVariety = () => {
    modalStore.open(VARIANT_MODAL.SELECT_VARIETY, {
      onSubmit: handleSelectVariety,
      currentCultivar: formik.values.cultivar
    });
  };
  const handleDeleteCultivar = () => {
    formik.setFieldValue(FIELDS_NAME.CULTIVAR, "");
    formik.setFieldValue(FIELDS_NAME.VARIETY, "");
  };
  const handleCancel = () => {
    modalStore.close();
  };

  return (
    <FormikForm noValidate>
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
          >
            Культура и Сорт
          </Typography>
        </Box>
        <Divider/>
        <Stack p={3} direction="column" spacing={3}>
          <Field name={FIELDS_NAME.CULTIVAR}>
            {({ field, meta }) => (
              <FormControl required>
                <StyledFormLabel>Культура</StyledFormLabel>
                <Box>
                  {field.value ? (
                    <StyledChip
                      size="small"
                      variant="outlined"
                      label={field.value.name}
                      onDelete={handleDeleteCultivar}
                    />
                  ) : (
                    <AddChip
                      onClick={handleAddCultivar}/>
                  )}
                </Box>
                <FormHelperText
                  error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={FIELDS_NAME.VARIETY}>
            {({ field, meta }) => (
              <FormControl required>
                <StyledFormLabel>Название
                  сорта</StyledFormLabel>
                <Box>
                  {field.value ? (
                    <StyledChip
                      size="small"
                      variant="outlined"
                      label={field.value.name}
                      onDelete={() => formik.setFieldValue(field.name, "")}
                    />
                  ) : (
                    <AddChip
                      disabled={!formik.values.cultivar}
                      onClick={handleAddVariety}/>
                  )}
                </Box>
                <FormHelperText
                  error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Divider/>
        <Box p={3} display="flex"
             justifyContent="space-between">
          <Button variant="text" color="red"
                  onClick={handleCancel}>
            Отмена
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </FormikForm>
  );
};

export default Form;