import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";
import Logo from "@/pages/auth/components/Logo";

import Layout from "./components/Layout";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";

const Error500 = observer(() => {
  const navigate = useNavigate();

  const handleClickReloadPage = () => {
    navigate(ROUTES_PATHS.statements);
  };
  return (
    <Layout>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
      >
        <Logo
          position={"relative"}
          top={"auto"}
          left={"auto"}
          transform={"none"}
          pb={2}
        />
        <Typography textAlign={"center"} variant="h1">
          Ошибка обращения к серверу
        </Typography>
        <Typography
          variant="h4"
          fontWeight={400}
          textAlign={"center"}
          lineHeight={"24px"}
        >
          Мы уже устраняем неисправность, попробуйте обновить страницу через
          некоторое время. Приносим извинения за временные неудобства!
        </Typography>
        <Button
          onClick={handleClickReloadPage}
          variant="outlined"
          color="secondary"
          size="medium"
        >
          Обновить страницу
        </Button>
      </Stack>
    </Layout>
  );
});

export default Error500;
