import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TRIAL_TYPE } from "@/const";

interface StyledButtonProps extends ButtonProps {
  active?: boolean;
  trialType?: string;
}

export const StyledButton = styled(Button, { shouldForwardProp: (prop) => prop !== "active" && prop !== "trialType" })<StyledButtonProps>(({
  theme,
  active,
  trialType
}) => ({
  height: "100%",
  minHeight: "60px",
  justifyContent: "flex-start",
  textAlign: "left",
  ...(trialType === TRIAL_TYPE.HP && {
    color: theme.palette.primary.main,
    borderColor: "#00A0494D",
    "&:hover": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: "inherit",
    },
    ...(active && {
      borderColor: theme.palette.primary.main
    })
  }),
  ...(trialType === TRIAL_TYPE.PHYTO && {
    color: theme.palette.red.main,
    borderColor: "#F92C384D",
    "&:hover": {
      color: theme.palette.red.main,
      borderColor: theme.palette.red.main,
      backgroundColor: "inherit"
    },
    ...(active && {
      borderColor: theme.palette.red.main
    })
  }),
  ...(trialType === TRIAL_TYPE.OOS && {
    color: theme.palette.another.orange,
    borderColor: "#FA78004D",
    "&:hover": {
      color: theme.palette.another.orange,
      borderColor: theme.palette.another.orange,
      backgroundColor: "inherit"
    },
    ...(active && {
      borderColor: theme.palette.another.orange
    })
  }),
  ...(trialType === TRIAL_TYPE.LAB && {
    color: theme.palette.another.violet,
    borderColor: "#942EE44D",
    "&:hover": {
      color: theme.palette.another.violet,
      borderColor: theme.palette.another.violet,
      backgroundColor: "inherit"
    },
    ...(active && {
      borderColor: theme.palette.another.violet
    })
  }),
}));