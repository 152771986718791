import React, { FC, useState } from "react";
import { useInfiniteScroll, useStores } from "@/hooks";
import { Field, Form, useFormikContext } from "formik";
import { FIELDS_NAME, IFormik } from "../const";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import theme from "@/theme";
import Autocomplete from "@/components/Autocomplete";
import { IIndicator } from "@/api/interfaces/responses";

interface IProp {
  cultivarId: number;
  cultivarName: string;
}

const IndicatorForm: FC<IProp> = ({
  cultivarId,
  cultivarName,
}) => {
  const { api, modalStore } = useStores();
  const formik = useFormikContext<IFormik>();
  const [search, setSearch] = useState("");

  const getParameters = (index: number) => ({
    page: index + 1,
    type_trial_indicator: "hp",
    type_trial_methodology: "hp",
    cultivar: cultivarId,
    search,
  });

  const fetcher = (args) => {
    const {
      page,
      type_trial_indicator,
      type_trial_methodology,
      cultivar,
      search,
    } = args;
    return api.regulatoryInfo.getIndicatorMethodologyList({
      page,
      type_trial_indicator,
      type_trial_methodology,
      cultivar,
      search,
    });
  };

  const { data: data, handleScroll: handleScroll } =
    useInfiniteScroll(getParameters, fetcher, {
      revalidateFirstPage: true,
    });

  const handleChangeSign = (_, payload: IIndicator) => {
    void formik.setFieldValue(
      FIELDS_NAME.INDICATOR,
      payload,
    );
  };

  const handleCancel = () => {
    modalStore.close();
  };

  return (
    <Form noValidate>
      <Box>
        <Box p={3}>
          <Typography
            sx={{
              color: theme.palette.blackAndWhite.gray,
            }}
          >
            {cultivarName}
          </Typography>

          <Typography variant="h2">
            Добавить признак
          </Typography>
        </Box>

        <Divider />

        <Stack p={3} direction="column" spacing={3}>
          <Field name={FIELDS_NAME.INDICATOR}>
            {({ field, meta }) => (
              <FormControl required>
                <Autocomplete
                  {...field}
                  onChange={handleChangeSign}
                  label="Признак"
                  data={data}
                  required
                  search={setSearch}
                  handleScroll={handleScroll}
                  error={meta.touched && !!meta.error}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color="red"
            onClick={handleCancel}
          >
            Отмена
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default IndicatorForm;
