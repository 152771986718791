import React from "react";
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";
import { SxProps } from "@mui/material/styles";
import { StyledStack } from "./styles";
import { TOOLTIP_TEXT } from "../WorkList/const";

interface IProps {
  handleAddField: () => void,
  handleSearch: (event: any) => void
  sx?: SxProps;
  isDisabledBtnAdd: boolean;
}

const WorkHeadList: React.FC<IProps> = ({
  handleAddField,
  sx = {},
  isDisabledBtnAdd
}) => {
  return (
    <StyledStack sx={{
      ...sx
    }}
    >
      <Typography variant="h3"
        sx={{ flex: 1 }}>Работы</Typography>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        gap={"10px"}
      >
        <Tooltip
          title={!isDisabledBtnAdd ? TOOLTIP_TEXT : ""}
          followCursor
        >
          <Box>
            <Button
              sx={{
                width: "40px",
                minWidth: "auto",
                padding: 0,
                height: "40px",
                flexShrink: 0,
              }}
              color={"blue"}
              size={"small"}
              variant={"outlined"}
              onClick={handleAddField}
              disabled={!isDisabledBtnAdd}
            >
              <AddOutlinedIcon />
            </Button>
          </Box>
        </Tooltip>
      </Stack>
    </StyledStack>
  );
};

export default WorkHeadList;