import React, { Fragment, ReactElement, useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Table from "@/apps/Table";
import { observer } from "mobx-react";
import { useQuery, useStores } from "@/hooks";
import { IPaginatedResult } from "@/api/interfaces/responses";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { SWR_KEYS } from "@/const";
import { IErrorResponse, IValue } from "@/interfaces";
import useSWR, { SWRResponse } from "swr";
import { isUndefined } from "swr/_internal";
import { IPropsSidebar, TRemarkKeys } from "../../interfaces";
import AddIcon from "@mui/icons-material/Add";
import qs from "qs";
import Filters from "@/components/Filters";
import { Box, Button } from "@mui/material";

import { IFilter } from "@/interfaces";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { resInterface } from "@/api/interfaces";
import { RemarkColumns } from "../../models/remark";
import { FiltersType } from "@/components/Filters/interfaces";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

export const REMARK_TYPES_FILTER: IValue[] = [
  {
    key: "revision",
    label: "Доработка",
  },
  {
    key: "refusal",
    label: "Отказ",
  },
];

export const getFilters = (typeValue): IFilter[] => {
  return [
    {
      name: "remark_type",
      label: "Тип",
      type: FiltersType.autoComplete,
      selectedValue: typeValue,
      options: REMARK_TYPES_FILTER,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
  ];
};

/**
 * Компонент, представляющий таблицу
 * @returns {ReactElement}
 */
const RemarkTable: React.FC<IPropsSidebar> = ({
  handleOpenRightSidebar
}): ReactElement => {
  const { api, swrStore, modalStore, userStore } = useStores();
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState<any>(query);

  // переменная которая показывает применены ли фильтры
  const isFilters: boolean = Object.keys(queryParams).length > 0;
  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering) ? query.ordering : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] = useState<string[]>(orgeringArray);
  const {
    data,
    error,
    isLoading,
    mutate
  }: SWRResponse<IPaginatedResult<resInterface.IRemarkHandbook>, IErrorResponse> = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      date_range_after: queryParams.date_range_after,
      date_range_before: queryParams.date_range_before,
      search: queryParams.search,
      executor: queryParams.executor?.key,
      remark_type: queryParams.remark_type?.key ?? undefined,
      key: SWR_KEYS.getRemarkList()
    },
    api.regulatoryInfo.getRemarkList
  );

  const filters = getFilters(query.remark_type);

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" }
    );
    setSearchParams(queryParams);
  };

  const handleCreateModal = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.REMARK, { theme: THEME_MODAL.W_555 });
  };

  const handleChangeOrderBy = (value) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" }
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  useEffect(() => {
    setQueryParams(query);
  }, [location.search]);

  swrStore.addMutator(SWR_KEYS.getRemarkList(), mutate);

  const isAddRemark = userStore.isPermission(
    PERMISSIONS.registrationRemark,
    PERMISSION_CRUD.add
  )

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px"
        }}
      >
        <Filters filters={filters} defaultSearch={query.search} />
        {isAddRemark && (
          <Button
            sx={{
              whiteSpace: "nowrap",
              flexShrink: "0"
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateModal}
          >
            Добавить запись
          </Button>
        )}
      </Box>

      <Table<resInterface.IRemarkHandbook, TRemarkKeys>
        columns={RemarkColumns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={"Добавить запись"}
        textEmptyTableWithFilter={"Не найдено ни одной записи"}
        textEmptyTable={"Еще не создано ни одной записи"}
        onRowClick={handleOpenRightSidebar}
        emptyTableAddEntity={handleCreateModal}
      />
    </Fragment>
  );
};

export default observer(RemarkTable);
