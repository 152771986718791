import React, { useEffect } from "react";
import {
  Stack,
  Typography,
  Button,
  List,
  ListItem,
  Box,
  CircularProgress,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";

import { IOffBudget } from "@/api/interfaces/requests";
import { STEP_TITLES } from "@/pages/AddPlan/const";
import { useStores, useInfiniteScroll } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { SWR_KEYS } from "@/const";
import theme from "@/theme";
import { resInterface } from "@/api/interfaces";
import EmptyItem from "@/components/EmptyItem";
import InvestmentItem from "@/apps/RightSidebar/templates/OffBudget/firstLevel/components/Investment/InvestmentItem";

interface IProps {
  data?: IOffBudget | null;
}

/**
 * Компонет для шага инвестиций
 * */

const InvestmentsFormStep: React.FC<IProps> = ({ data: offBudgetData }) => {
  const { api } = useStores();
  const { modalStore, swrStore } = useStores();
  const id = offBudgetData?.id ?? 0;
  const swrKey = SWR_KEYS.getInvestmentList(id);

  const getKey = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    id,
  });

  const fetcher = () => {
    return api.offBudget.getInvestmentList({
      com_plan_fact: id,
    });
  };

  const { data, handleScroll, isLoadingMore, isLoading, mutate } =
    useInfiniteScroll<resInterface.IInvestmentList>(getKey, fetcher, {
      revalidateFirstPage: false,
    });

    const onSubmitCreation = () => {
    mutate();
  };

  useEffect(() => {
    swrStore.addMutator(swrKey, mutate);
  }, []);

  const handleAddInvestment = () => {
    modalStore.open(VARIANT_MODAL.ADD_INVESTMENT, {
      com_plan_fact: id,
      onSubmit: onSubmitCreation,
    });
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Stack spacing={3}>
      <Typography variant="h3">{STEP_TITLES.INVESTMENTS}</Typography>
      <Box onScroll={handleScroll}>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4">Инвестиции</Typography>
          {data.length > 0 && (
            <Button
              sx={{
                width: "40px",
                height: "40px",
                minWidth: "auto",
                padding: 0,
              }}
              color={"blue"}
              size={"medium"}
              variant={"outlined"}
              onClick={handleAddInvestment}
            >
              <AddOutlined />
            </Button>
          )}
        </Stack>
        {data?.length > 0 ? (
          <>
            <Box pt={2}>
              <List>
                {data.map((item) => (
                  <ListItem
                    key={item.id}
                    sx={{
                      gap: 1,
                      justifyContent: "space-between",
                      borderBottom: "1px solid black",
                      borderColor: theme.palette.blackAndWhite.stroke,
                    }}
                  >
                    <InvestmentItem comPlanFactId={id} item={item} />
                  </ListItem>
                ))}
              </List>
            </Box>
            {isLoadingMore ? (
              <Stack pt={2} alignItems={"center"} justifyContent={"center"}>
                <CircularProgress />
              </Stack>
            ) : (
              <Button
                sx={{
                  marginTop: "24px",
                  width: "100%",
                }}
                color={"blue"}
                size={"large"}
                startIcon={<AddOutlined />}
                variant={"outlined"}
                onClick={handleAddInvestment}
              >
                Добавить инвестиции
              </Button>
            )}
          </>
        ) : (
          <EmptyItem
            title="Заполните данные по инвестициям"
            buttonText="Добавить инвестиции"
            onClick={handleAddInvestment}
          />
        )}
      </Box>
    </Stack>
  );
};
export default InvestmentsFormStep;
