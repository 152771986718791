import { IFilter } from '@/interfaces'
import { Clear } from '@mui/icons-material'
import { Box, IconButton, TextField } from '@mui/material'
import React, { FC } from 'react'

interface IProps {
    filter: IFilter
}

/**
 * Отображает select.
 *
 * @param {IProps} filter - Объект фильтра, содержащий необходимые данные.
 * @return {JSX.Element} Компонент select фильтра.
 */

const EditableSelectFilter: FC<IProps> = ({ filter }) => {


    return (
        <Box sx={{ position: 'relative' }}>
            <TextField
                key={filter.name}
                onChange={filter.handleSelectChange}
                label={filter.label}
                select
                value={filter.selectedValue}
                size="small"
                sx={{ cursor: 'pointer' }}
            >
                {filter.selectItems}
            </TextField>
            {filter.selectedValue && (
                <IconButton sx={{ mr: '28px', position: 'absolute', top: '50%', right: '0', transform: 'translateY(-50%)' }} onClick={filter.handleClearSelect} >
                    {filter.selectedValue ? <Clear fontSize="small" /> : null}
                </IconButton>
            )}
        </Box>
    )
}

export default EditableSelectFilter