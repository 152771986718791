import React, { useEffect, useState } from "react";
import qs from "qs";
import { SWR_KEYS } from "@/const";
import { resInterface } from "@/api/interfaces";
import { useSearchParams } from "react-router-dom";
import { useQuery, useStores } from "@/hooks";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
    Box,
    Divider,
    IconButton,
    Menu,
    Typography,
} from "@mui/material";
import { EditIcon, DeleteIcon } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";

export const COLUMN_EDITING = {
    value: "editing",
    label: "",
};

export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
};


export const GsuCell = (
    _: string,
    cell: resInterface.IAgriculturalMachinery,
): JSX.Element => {
    const { location} = cell;
    return (
        <Box>
            <Typography fontSize={14}>
                {location?.plot?.name ?? "-"}
            </Typography>
        </Box>
    );
};

export const MACHINERY_TYPE = {
    tractor: 'Трактор',
    combine: 'Комбайн',
    attachment: 'Навесное оборудование',
}

export const TypeCell = (
    type: any
): JSX.Element => {
    return (
        <Box>
            <Typography fontSize={14}>
                {MACHINERY_TYPE[type] ?? "-"}
            </Typography>
        </Box>
    );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCellAgrCulMachinery = (_: string, cell: any, itemsInPage: any[]): JSX.Element => {
    const { api, modalStore, swrStore } = useStores();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const query = useQuery<any>();
    const [, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (itemsInPage.length === 1) {
            const params = qs.stringify(
                { ...query, last: 'true' },
                { arrayFormat: "comma" },
            );
            setSearchParams(params);
        } else {
            const params = qs.stringify(
                { ...query, last: undefined },
                { arrayFormat: "comma" },
            );
            setSearchParams(params);
        }
    }, [itemsInPage.length]);



    // функция открытия/закрытия компонента дропдауна
    const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    // функция закрытия компонента дропдауна (для клика вне попапа)
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        modalStore.open(VARIANT_MODAL.AGRICULTURAL_MACHINERY, {
            theme: THEME_MODAL.W_555,
            initialValues: cell,
            agrMachineryId: cell.id,
        });
        setAnchorEl(null);
    };

    const mutateList =
        swrStore.mutators[SWR_KEYS.getAgriculturalMachinery()];

    const deleteWithCheckingLast = () => {
        // если один эл-т на странице перенаправляем на предыдущую страницу
        if (itemsInPage.length === 1 && query.page > 1) {
            const params = qs.stringify(
                { ...query, page: query.page - 1 },
                { arrayFormat: "comma" },
            );
            setSearchParams(params);
        } else {
            mutateList && mutateList();
        }
    };

    const handleDelete = (resolve, reject) => {
        api.regulatoryInfo.deleteAgrCultMachinery(cell.id).then(
            () => {
                deleteWithCheckingLast();
                resolve()
            }
        ).catch(reject);
        setAnchorEl(null);
    };

    const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        const modalProp = {
            theme: THEME_MODAL.W_555,
            type: CELL_TYPES.handbook,
            id: cell.id,
            handleDelete: handleDelete,
        };

        modalStore.setTheme(THEME_MODAL.W_555);
        modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
    };

    return (
        <Box>
            <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <StyledMenuItem onClick={handleClickEdit}>
                    <EditIcon />
                    {EDITING_CELL.editing}
                </StyledMenuItem>
                <Divider />
                <StyledMenuItem onClick={handleClickDelete}>
                    <DeleteIcon color={"error"} />
                    <Typography color='error'>{EDITING_CELL.delete}</Typography>
                </StyledMenuItem>
            </Menu>
        </Box>
    );
};
