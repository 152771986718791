import React from "react";
import {
  Box,
  IconButton,
  Menu,
  Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  StyledMenuItem
} from "@/components/StyledMenuItem";
import { DeleteIcon, EditIcon } from "@/components/icons";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";
import { useStores } from "@/hooks";

interface IProps {
  handleDelete: () => void;
  handleEdit: () => void;
  hide?: boolean;
}

const DocumentEditMenu: React.FC<IProps> = ({
  handleDelete,
  handleEdit,
  hide = false
}) => {
  const  { userStore } = useStores()
  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleDelete();
    setIsOpen(null);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleEdit();
    setIsOpen(null);
  };

  if (hide) {
    return <></>;
  }

  const isEdit = userStore.isPermission(
    PERMISSIONS.regulatory_infoFile,
    PERMISSION_CRUD.change
  )
  const isDelete = userStore.isPermission(
    PERMISSIONS.regulatory_infoFile,
    PERMISSION_CRUD.delete
  )

  if (!isEdit && !isDelete) {
    return <></>
  }

  return (
    <Box>
      <IconButton onClick={handleToggle}>
        <MoreVertIcon sx={{ fontSize: 20 }}/>
      </IconButton>

      <Menu anchorEl={isOpen} open={open}
            onClose={handleClose}>
        {isEdit && (
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon/>
          Редактировать
        </StyledMenuItem>
        )}
        { isDelete && (
        <StyledMenuItem
          onClick={handleClickDelete}
        >
          <DeleteIcon color={"error"}/>
          <Typography color="error">Удалить</Typography>
        </StyledMenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default DocumentEditMenu;