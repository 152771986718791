import {
  FiltersType
} from "@/components/Filters/interfaces";
import {
  STATUSES_CULTIVAR_FIELD_VARIETY_LIST
} from "@/const";

interface IParams {
  selectedValue?: {
    key: string;
    label: string;
  } | null
  name?: string,
  label?: string
}

export const useCultivarFieldVarietyStatusFilter = ({
  selectedValue,
  name = "status",
  label = "Статус"
}: IParams) => {

  return {
    name: name,
    label: label,
    type: FiltersType.autoComplete,
    selectedValue: selectedValue,
    options: STATUSES_CULTIVAR_FIELD_VARIETY_LIST,
    value: "",
    style: { minWidth: "260px", flexGrow: 1 },
  };
};