import React, { useMemo } from "react";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";

const YearDatePicker = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const handleChange = (payload) => {
    const value = payload ? payload.$y : ""
    setSearchParams(params => {
      value ? params.set("year", value) : params.delete("year")
      return params
    })
  };

  const currYear = useMemo(() => {
    const year = searchParams.get("year")
    return year ? dayjs().year(Number(year)) : null
  },[searchParams])

  return (
    <DatePicker
      name={"Текущий год"}
      label="Текущий год"
      openTo="year"
      views={["year"]}
      value={currYear}
      slotProps={{
        textField: {
          size: "small",
        },
      }}
      onChange={handleChange}
    />
  );
};

export default YearDatePicker;