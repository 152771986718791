import yup from "@/utils/yup";
import {
  IAgriculturalLabourField,
  IAgriculturalLabourLocation
} from "@/api/interfaces/responses";

// Коордианты для тестирования
// const testCoords = [
//   [-0.03570556640625, 0.039138790901432],
//   [0.047035217285156, 0.037078854833759],
//   [0.0054931640625, -0.00995635981319],
// ];

export const FIELDS_NAME = {
  NAME: "name",
  COLOUR: "colour",
  DESTINATION: "destination",
  LOCATION: "location",
  PLANTING_YEAR: "planting_year",
  BRANCH: "branch",
  REGION: "region",
  PLOT: "plot",
  COORDINATES: "coordinates"
} as const;

const EMPTY_COORDS = [];

export const initialValues = {
  [FIELDS_NAME.COORDINATES]: EMPTY_COORDS,
  [FIELDS_NAME.BRANCH]: null,
  [FIELDS_NAME.REGION]: null,
  [FIELDS_NAME.COLOUR]: "",
  [FIELDS_NAME.DESTINATION]: null,
  [FIELDS_NAME.PLANTING_YEAR]: null,
  [FIELDS_NAME.LOCATION]: null,
  [FIELDS_NAME.PLOT]: null,
  [FIELDS_NAME.NAME]: ""
};

export interface IValue {
  id: number;
  label: string;
}

export interface IFormik {
  [FIELDS_NAME.PLOT]: IValue | null;
  [FIELDS_NAME.COORDINATES]: number[][] | string[][];
  [FIELDS_NAME.BRANCH]: IValue | null;
  [FIELDS_NAME.REGION]: IValue | null;
  [FIELDS_NAME.NAME]: string;
  [FIELDS_NAME.COLOUR]: string;
  [FIELDS_NAME.DESTINATION]: IValue | null;
  [FIELDS_NAME.PLANTING_YEAR]: number | null;
  [FIELDS_NAME.LOCATION]: IValue | null;
}

export const serializeLocationValues = (values: IAgriculturalLabourLocation) => ({
  region: {
    id: values.plot.branch.region.id,
    label: values.plot.branch.region.name,
  },
  branch: {
    id: values.plot.branch.id,
    label: values.plot.branch.name,
  },
  location: {
    id: values.id,
    label: values.name
  },
  plot: {
    id: values.plot.id,
    label: values.plot.name,
  }
});

export const serializeValues = (
  values: IAgriculturalLabourField | undefined
): IFormik => ({
  name: values?.name || "",
  region: values?.plot
    ? {
      id: values.plot.branch.region.id,
      label: values.plot.branch.region.name
    }
    : null,
  branch: values?.plot
    ? {
      id: values.plot.branch.id,
      label: values.plot.branch.name
    }
    : null,
  destination: values?.destination
    ? {
      id: values.destination.id,
      label: values.destination.name
    }
    : null,
  location: values?.location
    ? {
      id: values.location.id,
      label: values.location.name
    }
    : null,
  plot: values?.plot
    ? {
      id: values.plot.id,
      label: values.plot.name
    }
    : null,
  planting_year: values?.planting_year || null,
  colour: values?.colour || "",
  coordinates: values?.map_object?.coordinates?.[0] ? values.map_object.coordinates[0].slice(0, -1).map((el) => [el[1], el[0]]) : EMPTY_COORDS
});

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.NAME]: yup.string().required(),
  [FIELDS_NAME.BRANCH]: yup.mixed().required(),
  [FIELDS_NAME.REGION]: yup.mixed().required(),
  [FIELDS_NAME.COLOUR]: yup.string().required(),
  [FIELDS_NAME.DESTINATION]: yup.mixed().required(),
  [FIELDS_NAME.PLANTING_YEAR]: yup.mixed().required(),
  [FIELDS_NAME.LOCATION]: yup.mixed().required(),
  [FIELDS_NAME.COORDINATES]: yup
    .array()
    .of(yup.tuple([yup.number().required(), yup.number().required()]))
    .test('minLength', "Добавьте минимум 3 координаты", value => {
      return !(value && value.length > 0 && value.length < 3);
    })
});
