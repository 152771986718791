import React from "react";
import { Dayjs } from "dayjs";
import { FastField, useFormikContext } from "formik";
import {
  DatePicker,
  DatePickerProps
} from "@mui/x-date-pickers";
import { TextFieldProps } from "@mui/material/TextField";

interface IProps extends DatePickerProps<Dayjs> {
  name: string;
  handleChange?: (payload: Date) => void;
  required?: boolean;
  fullWidth?: boolean;
  textFieldProps?: TextFieldProps;
}

const DatePickerField: React.FC<IProps> = ({
  handleChange,
  name,
  required = false,
  fullWidth = false,
  textFieldProps,
  ...props
}) => {
  const formik = useFormikContext();

  const onChange = (payload: Date) => {
    handleChange ? handleChange(payload) : formik.setFieldValue(name, payload);
  };

  if (!formik) {
    throw new Error(
      "DatePickerField has to be used within <Formik>"
    );
  }

  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <DatePicker
          name={field.name}
          value={field.value}
          onChange={onChange}
          label="Дата"
          slotProps={{
            textField: {
              size: "small",
              error: meta.touched && !!meta.error,
              helperText: meta.touched && meta.error,
              onBlur: field.onBlur,
              required: required,
              fullWidth: fullWidth,
              ...textFieldProps
            },
          }}
          {...props}
        />
      )}
    </FastField>
  );
};

export default DatePickerField;