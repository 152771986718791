export const ROUTES = {
  login: "login",
  Error500: "500",
  Error404: "404",
  passwordResetRequest: "password-reset-request",
  passwordResetConfirm: "password-reset-confirm",
  emailConfirm: "email-confirm",
  emailIsSend: "email-is-send",
  waitingForRole: "waiting-for-role",
  passwordSuccess: "password-success",
  planning: "planning",
  addPlan: "add-plan",
  expertAssessment: "expert-assessment",
  addExpertAssessment: "add-expert-assessment",
  hp: "hp",
  hpAdd: "add-hp",
  crop: "crop",
  agriculturalWork: "agricultural-work",
  offBudget: "off-budget",
  handbook: "handbook",
  reports: "reports",
  addRequest: "add-request",
  profile: "profile",
  statements: "statements",
  addStatement: "add-statement",
  editStatement: "edit-statement",
  map: "map",
  areaMap: "area",
  fieldMap: "field",
  sortMap: "cultivar",
  plotMap: "plot",
  draw: "draw",
  hpTrial: "hp-trial",
  oosTrial: "oos-trial",
  phytoTrial: "phyto-trial",
  LabTrial: "lab-trial",
  fact: "fact",
  addFact: "add-fact",
  editFact: "edit-fact",
  analysis: "analysis",
  editPlan: "edit-plan",
  Error404Modules: "error-404-module",
  fields: "fields",
  addArea: "add-area",
  addField: "add-field",
  addCultivar: "add-cultivar",
  editCultivar: "edit-cultivar",
  agriculturalWorkDoc: "agricultural-work-doc",
  seedAccounting: "seed-accounting",
  addSeedArrival: "add-seed-arrival",
  addPlanPageHP: "add-plan-page-hp",
  addPlanPageOOS: "add-plan-page-oos",
  addPlanPagePhyto: "add-plan-page-phyto",
  addPlanPageLAB: "add-plan-page-lab",
  editPlanningGroup: "edit-planning-group",
  addCultivarFieldWork: "cultivar-field-work",
  addCultivarField: "cultivar-field",
  cultivarFieldVariety: "cultivar-field-variety",
  cultivarFieldVarietyLab: "cultivar-field-variety-lab",
  testingExpertReview: "testing-expert-review",
  addLab: "add-lab",
  addObservation: "add-observation",
  addTestingExpertReview: "add-testing-expert-review",
  branch: "branch",
  gsu: "gsu",
  landAreas: "land-areas",
  addTechMap: "add-tech-map",
  addTechMapForm: "form"
};

export const ROUTES_PATHS = {
  login: `/${ROUTES.login}`,
  Error500: `/${ROUTES.Error500}`,
  Error404: `/${ROUTES.Error404}`,
  Error404Modules: `/${ROUTES.Error404Modules}`,
  passwordResetRequest: `/${ROUTES.passwordResetRequest}`,
  passwordResetConfirm: `/${ROUTES.passwordResetConfirm}`,
  emailConfirm: `/${ROUTES.emailConfirm}`,
  emailIsSend: `/${ROUTES.emailIsSend}`,
  waitingForRole: `/${ROUTES.waitingForRole}`,
  passwordSuccess: `/${ROUTES.passwordSuccess}`,
  statements: `/${ROUTES.statements}`,
  main: "/",
  planning: `/${ROUTES.planning}`,
  planning_crop: `/${ROUTES.planning}/${ROUTES.crop}`,
  planning_expert_assessment: `/${ROUTES.planning}/${ROUTES.expertAssessment}`,
  planingAddCultivar: `/${ROUTES.planning}/:id?/${ROUTES.addCultivar}`,
  planingEditCultivar: `/${ROUTES.planning}/:id/${ROUTES.editCultivar}`,
  planningHp: `/${ROUTES.planning}/${ROUTES.hp}`,
  planningHpAdd: `/${ROUTES.planning}/${ROUTES.hp}/${ROUTES.hpAdd}`,
  agriculturalWork: `/${ROUTES.agriculturalWork}`,
  agriculturalWorkFields: `/${ROUTES.agriculturalWork}/${ROUTES.fields}`,
  agriculturalWorkDoc: `/${ROUTES.agriculturalWorkDoc}`,
  agriculturalWorkDocSeedAccounting: `/${ROUTES.agriculturalWorkDoc}/${ROUTES.seedAccounting}`,
  cultivarFieldVarietyList: `/${ROUTES.agriculturalWorkDoc}/${ROUTES.cultivarFieldVariety}`,
  cultivarFieldVarietyLabList: `/${ROUTES.agriculturalWorkDoc}/${ROUTES.cultivarFieldVarietyLab}`,
  testingExpertReview: `/${ROUTES.agriculturalWorkDoc}/${ROUTES.testingExpertReview}`,
  offBudget: `/${ROUTES.offBudget}`,
  offBudgetFact: `/${ROUTES.offBudget}/${ROUTES.fact}`,
  offBudgetEditFact: `/${ROUTES.offBudget}/:id/${ROUTES.editFact}`,
  offBudgetAnalysis: `/${ROUTES.offBudget}/${ROUTES.analysis}`,
  offBudgetAddPlan: `/${ROUTES.offBudget}/:id?/${ROUTES.addPlan}`,
  offBudgetEditPlan: `/${ROUTES.offBudget}/:id/${ROUTES.editPlan}`,
  handbook: `/${ROUTES.handbook}`,
  reports: `/${ROUTES.reports}`,
  addRequest: `/${ROUTES.addRequest}`,
  profile: `/${ROUTES.profile}`,
  addStatement: `/${ROUTES.addStatement}`,
  editStatement: `/${ROUTES.statements}/:id/${ROUTES.editStatement}`,
  map: `/${ROUTES.map}`,
  mapAreaDetail: `/${ROUTES.map}/${ROUTES.areaMap}/`,
  mapFieldDetail: `/${ROUTES.map}/${ROUTES.fieldMap}/`,
  mapSortDetail: `/${ROUTES.map}/${ROUTES.sortMap}/`,
  mapPlotDetail: `/${ROUTES.map}/${ROUTES.plotMap}/`,
  mapAreaDraw: `/${ROUTES.map}/${ROUTES.areaMap}/${ROUTES.draw}`,
  mapFieldDraw: `/${ROUTES.map}/${ROUTES.fieldMap}/${ROUTES.draw}`,
  mapSortDraw: `/${ROUTES.map}/${ROUTES.sortMap}/${ROUTES.draw}`,
  hpTrial: `/${ROUTES.hpTrial}`,
  oosTrial: `/${ROUTES.oosTrial}`,
  phytoTrial: `/${ROUTES.phytoTrial}`,
  LabTrial: `/${ROUTES.LabTrial}`,
  addArea: `${ROUTES.addArea}`,
  addField: `${ROUTES.addField}`,
  addPlanPageHP: `/${ROUTES.addPlanPageHP}`,
  addPlanPageOOS: `/${ROUTES.addPlanPageOOS}`,
  addPlanPagePhyto: `/${ROUTES.addPlanPagePhyto}`,
  branch: `/${ROUTES.branch}`,
  gsu: `/${ROUTES.gsu}`,
  landAreas: `/${ROUTES.landAreas}`,
  addTechMap: `/${ROUTES.addTechMap}`,
  addTechMapForm: `/${ROUTES.addTechMap}/${ROUTES.addTechMapForm}`,
};
