import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { METH_SIGN_FIELD_NAME } from "../const";
import { TForm, TModalProps, TSign } from "../types";
import {
  Field,
  FieldArray,
  useFormikContext,
} from "formik";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import theme from "@/theme";
import Autocomplete from "@/components/Autocomplete";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";

type TPush<T> = (obj: T) => void;

interface IProps {
  modalProps: TModalProps;
}

export const AddSignFieldArray: FC<IProps> = ({
  modalProps,
}) => {
  const form = useFormikContext<TForm>();
  const { api, swrStore } = useStores();

  const [searchSign, setSearchSign] = useState("");
  const swrKey = SWR_KEYS.getIndicatorMethodology();

  const getParameters = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    search: searchSign,
    type_trial: modalProps.trialType,
  });

  const fetcher = (args) => {
    const { page, search, type_trial } = args;
    return api.regulatoryInfo.getIndicatorsList({
      page,
      search,
      type_trial,
    });
  };

  const {
    data: data,
    handleScroll: handleScroll,
    mutate: mutate,
  } = useInfiniteScroll(getParameters, fetcher, {
    revalidateFirstPage: false,
  });

  useEffect(() => {
    swrStore.addMutator(swrKey, mutate);
  }, []);

  const handleAddSign = (push: TPush<TSign>) => {
    push({
      [METH_SIGN_FIELD_NAME.INDICATOR]: null,
      [METH_SIGN_FIELD_NAME.IS_GROUPED]: false,
      [METH_SIGN_FIELD_NAME.IS_MANDATORY]: false,
      [METH_SIGN_FIELD_NAME.METHODOLOGY]:
        modalProps.methodologyId,
    });
  };

  const handleChangeIndicator = (value, index) => {
    form.setFieldValue(
      `sign.${index}.${METH_SIGN_FIELD_NAME.INDICATOR}`,
      value,
    );
  };

  const shouldRenderButton =
    !modalProps.isEdit ||
    (modalProps.isEdit && modalProps.trialType === "hp");

  return (
    <Box
      sx={{
        m: "30px",
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "16px",
          mb: "5px",
        }}
      >
        Признаки методики
      </Typography>
      <FieldArray name="sign">
        {({ remove, push }) => (
          <Box>
            <Stack direction={"column"} gap={2} pt={1}>
              {form.values.sign.map((_, index) => (
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
                    borderRadius: "4px",
                    p: 1,
                  }}
                  key={index}
                >
                  <Stack
                    p={2}
                    direction="column"
                    spacing={3}
                  >
                    <Field
                      name={`sign.${index}.${METH_SIGN_FIELD_NAME.INDICATOR}`}
                    >
                      {({ field, meta }) => (
                        <FormControl required>
                          <Autocomplete
                            {...field}
                            onChange={(_, value) =>
                              handleChangeIndicator(
                                value,
                                index,
                              )
                            }
                            label="Признак"
                            data={data}
                            required
                            handleScroll={handleScroll}
                            error={
                              meta.touched && !!meta.error
                            }
                            search={setSearchSign}
                          />

                          <FormHelperText
                            error={
                              meta.touched && !!meta.error
                            }
                          >
                            {meta.touched && meta.error}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>

                  {modalProps.trialType === "oos" && (
                    <Stack direction={"row"} gap={2} p={1}>
                      <Field
                        name={`sign.${index}.${METH_SIGN_FIELD_NAME.IS_MANDATORY}`}
                      >
                        {({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                size="small"
                                checked={field.value}
                              />
                            }
                            label={
                              <Typography variant="body2">
                                Обязательный
                              </Typography>
                            }
                          />
                        )}
                      </Field>
                      <Field
                        name={`sign.${index}.${METH_SIGN_FIELD_NAME.IS_GROUPED}`}
                      >
                        {({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                size="small"
                                checked={field.value}
                              />
                            }
                            label={
                              <Typography variant="body2">
                                Используется в группировке
                              </Typography>
                            }
                          />
                        )}
                      </Field>
                    </Stack>
                  )}

                  {index > 0 && (
                    <Button
                      onClick={() => remove(index)}
                      color="red"
                    >
                      Удалить
                    </Button>
                  )}
                </Box>
              ))}
            </Stack>
            {shouldRenderButton && (
              <Button
                sx={{
                  width: "100%",
                  margin: "5px 1px 10px 1px",
                  marginTop: "5px",
                }}
                color={"blue"}
                size={"large"}
                startIcon={<AddOutlinedIcon />}
                variant={"outlined"}
                onClick={() => handleAddSign(push)}
              >
                Добавить признак методики
              </Button>
            )}
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};
