import theme from "@/theme";
import {
  TPlanningTrialType,
  TPlanningStatus,
  TPlanningHp,
} from "@/api/types";
import {
  flower,
  hub,
  labs,
  microbiology,
} from "@/assets/common";
import { IValue } from "@/interfaces";

export const PLANNING_KEYS = {
  number: "number",
  trialType: "trial_type",
  cultivarName: "cultivar__name",
  branchName: "branch__name",
  createdAt: "created_at",
  status: "status",
  editing: "editing",
} as const;

export const PLANNING_HEADER_LABELS = {
  number: "Номер",
  trialType: "Вид испытания",
  cultivar: "Культура",
  branch: "Филиал",
  createdAt: "Дата создания",
  status: "Статус",
};

export const PLANNING_TRIALS_TYPES_ICON: {
  [key in TPlanningTrialType]: string;
} = {
  hp: flower,
  oos: hub,
  phyto: microbiology,
  lab: labs,
};

export const PLANNING_TRIALS_TYPES: {
  [key in TPlanningTrialType]: string;
} = {
  hp: "Хоз. полезность",
  oos: "ООС",
  phyto: "Фитопатология",
  lab: "В лаборатории",
};

export const PLANNING_TRIALS_TYPES_COLOR: {
  [key in TPlanningTrialType]: string;
} = {
  hp: theme.palette.green.main,
  oos: theme.palette.another.orange,
  phyto: theme.palette.red.main,
  lab: theme.palette.another.violet,
};

export const PLANNING_STATUS: {
  [key in TPlanningStatus]: string;
} = {
  draft: "Черновик",
  approved: "Утверждено",
};

export const PLANNING_STATUS_NAME: {
  [key in TPlanningStatus]: TPlanningStatus;
} = {
  draft: "draft",
  approved: "approved",
} as const;

export const PLANNING_ENTITY_NAME = "план";

export const PLANNING_STATUS_COLOR: {
  [key in TPlanningStatus]: string;
} = {
  draft: theme.palette.blackAndWhite.gray,
  approved: theme.palette.green.main,
};

export const PLANNING_STATUS_ICON: {
  [key in TPlanningStatus]: string;
} = {
  draft: "draft",
  approved: "approved",
};

export const EMPTY_LIST_PLANING_TEXT =
  "Еще не создано ни одного плана";
export const EMPTY_LIST_PLANING_TEXT_WITH_FILTERS =
  "По указанным параметрам планы не найдены";
export const ADD_PLANING_TEXT = "Добавить план";

export const PLANNING_STATUS_INPUT: IValue[] = [
  {
    key: "draft",
    label: "Черновик",
  },
  {
    key: "approved",
    label: "Утверждено",
  },
];

export const PLANNING_TRIAL_TYPE_INPUT: IValue[] = [
  {
    key: "hp",
    label: "Хоз. полезность",
  },
  {
    key: "oos",
    label: "ООС",
  },
  {
    key: "phyto",
    label: "Фитопатология",
  },
  {
    key: "lab",
    label: "В лаборатории",
  },
];

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

export const PLANNING_HP_STATUS: {
  [key in TPlanningHp]: string;
} = {
  draft: "Черновик",
  approved: "Утверждено",
};
