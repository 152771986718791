import { Box, Typography } from "@mui/material";
import React from "react";
import EquipmentTable from "./EquipmentTable";
import {
  ISummaryMachineries
} from "@/api/interfaces/responses";

interface IProps {
  data: ISummaryMachineries[]
}
const AgricultiralMachinery:React.FC<IProps> = ({data}) => {
  return (
    <Box>
      <Typography mb={2} mt={2} variant="h3">
        Сельскохозяйственная техника
      </Typography>
      <EquipmentTable data={data} />
    </Box>
  );
};

export default AgricultiralMachinery;
