import React, {
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { Box, CircularProgress } from "@mui/material";

import {
  STEP_LABELS,
  validationSchema
} from "@/pages/AddPlan/const";
import {
  FormikStepper,
  PlanFormStep,
  InvestmentsFormStep,
  EfficiencyFormStep,
  ProductionFormStep,
} from "@/pages/AddPlan/components";
import {
  FormikStep
} from "@/pages/AddStatement/components/FormikStep";
import { mapValueToForm } from "@/pages/AddPlan/utils";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";
import { isUndefined } from "@/utils/helpers";
import { SWR_KEYS } from "@/const";

/**
 * Страница добавления плана
 * @param headerTitle - заголовок шапки
 * */

interface IProps {
  headerTitle?: string;
}

const AddPlanPage: React.FC<IProps> = ({ headerTitle }) => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const { api, headerStore } = useStores();

  const KEY_SWR = SWR_KEYS.getOffbudgetPlanById(Number(id));

  const fetcher = () => {
    if (isUndefined(id)) return null;
    return api.offBudget.getOffBudgetPlan(Number(id));
  };

  const { data, isLoading, mutate } = useSWR(
    KEY_SWR,
    fetcher,
  );

  useEffect(() => {
    if (step === 1) mutate();
  }, [step]);

  useLayoutEffect(() => {
    headerStore.setProps({
      title: headerTitle,
      id: data?.id,
    });
    headerStore.setHeader(VARIANT_HEADER.ADD_PLAN);
  }, [data]);

  const handleSubmit = () => {
    navigate({
      pathname: ROUTES_PATHS.offBudget,
    });
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <FormikStepper
      initialValues={mapValueToForm(data)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
      step={step}
      setStep={setStep}
    >
      <FormikStep label={STEP_LABELS.PLAN}>
        <PlanFormStep />
      </FormikStep>
      <FormikStep label={STEP_LABELS.PRODUCTION}>
        <ProductionFormStep data={data} />
      </FormikStep>
      <FormikStep label={STEP_LABELS.INVESTMENTS}>
        <InvestmentsFormStep data={data} />
      </FormikStep>
      <FormikStep label={STEP_LABELS.EFFICIENCY}>
        <EfficiencyFormStep data={data} />
      </FormikStep>
    </FormikStepper>
  );
};

export default observer(AddPlanPage);
