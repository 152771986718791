import {
  FiltersType
} from "@/components/Filters/interfaces";
import {
  CULTIVAR_FIELD_TYPE_INPUT
} from "@/pages/CultivarField/const";
import { useQuery } from "@/hooks";
import {
  ICultivarFieldQueryParams
} from "@/pages/CultivarField/interfaces";

const useFilters = () => {
  const query = useQuery<ICultivarFieldQueryParams>();

  return [
    {
      name: "trial_type",
      label: "Вид испытаний",
      type: FiltersType.autoComplete,
      selectedValue: query?.trial_type,
      options: CULTIVAR_FIELD_TYPE_INPUT,
      value: "",
      style: { minWidth: "260px",flexGrow: 1 },
    },
    {
      name: "year",
      label: "Год посева",
      type: FiltersType.yearPicker,
      selectedValue: query.year,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
  ];
};

export default useFilters;