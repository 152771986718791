import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

declare module "dayjs" {
  interface Dayjs {
    utc(b: boolean): Dayjs;
  }

  function utc(): Dayjs;

  const dayjs: {
    utc: typeof utc;
  };
}

export default dayjs;
