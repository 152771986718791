import { IFile } from "@/components/FileDropzone/const";
import yup from "@/utils/yup";

type TTrial = "hp" | "oos";

export type TCultivar = {
  name: string;
  id: number;
};

export type TForm = {
  name: string;
  number: string;
  confirmation_date: string;
  additional_info?: string;
  files: IFile[];
  type_trial: TTrial;
  cultivar: TCultivar;
};

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    methodologyId?: number;
    initialValues?: Partial<TForm>;
    isEdit?: boolean;
  };
  onSubmit?: () => void;
}

export enum METH_FIELD_NAME {
  NAME = "name",
  NUMBER = "number",
  CONFIRMATION_DATE = "confirmation_date",
  ADDITIONAL_INFO = "additional_info",
  FILES = "files",
  TYPE_TRIAL = "type_trial",
  CULTIVAR = "cultivar",
}

export const validationSchema = yup.object().shape({
  [METH_FIELD_NAME.NAME]: yup.string().trim().required(),
  [METH_FIELD_NAME.NUMBER]: yup
    .number()
    .max(2147483647)
    .required(),
  [METH_FIELD_NAME.CONFIRMATION_DATE]: yup
    .mixed()
    .required(),
  [METH_FIELD_NAME.ADDITIONAL_INFO]: yup
    .string()
    .max(100)
    .trim()
    .nullable(),
  [METH_FIELD_NAME.FILES]: yup.mixed().nullable(),

  [METH_FIELD_NAME.TYPE_TRIAL]: yup.string().required(),
  [METH_FIELD_NAME.CULTIVAR]: yup.mixed().required(),
});
