import React from 'react'
import { IIcon } from "./interfaces"

const CloseIcon = ({className}: IIcon) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6242_9278"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6242_9278)">
        <path
          d="M5.33329 15.8334L4.16663 14.6667L8.83329 10L4.16663 5.33335L5.33329 4.16669L9.99996 8.83335L14.6666 4.16669L15.8333 5.33335L11.1666 10L15.8333 14.6667L14.6666 15.8334L9.99996 11.1667L5.33329 15.8334Z"
          fill="#F92C38"
        />
      </g>
    </svg>
  )
}

export default CloseIcon