import React from "react";
import {
  Box,
  CircularProgress,
  CircularProgressProps
} from "@mui/material";

interface IProps {
  size?: CircularProgressProps['size']
}
const Loader:React.FC<IProps> = ({size = 80}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <CircularProgress size={size} />
    </Box>
  );
};

export default Loader;