import React, { useLayoutEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import { Box, CircularProgress } from "@mui/material";

import {
  deliveryDataValidationSchema,
  FIELDS_NAME,
  IFormik,
  informationValidationSchema,
  initialValues,
  serializeFormToValue,
  serializeValueToForm,
  STEP_LABELS
} from "./const";
import FormikStepper from "@/components/FormikStepper";

import { useStores } from "@/hooks";

import {
  errorToString,
  isUndefined
} from "@/utils/helpers";

import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { LOCATION_KEY, SWR_KEYS } from "@/const";
import DeliveryData
  from "@/pages/AddSeedArrival/components/DeliveryData";
import Information
  from "@/pages/AddSeedArrival/components/Information";
import Documents
  from "@/pages/AddSeedArrival/components/Documents";
import { FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { TOAST_TYPES } from "@/apps/Toast";
import dayjs from "dayjs";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";


/**
 * Страница добавления Поступление семян
 * */

const AddStatementPage: React.FC = () => {
  const { id } = useParams();
  const {
    api,
    headerStore,
    toastStore,
    swrStore,
    userStore
  } = useStores();
  const location = useLocation();
  const navigate = useNavigate();
  const mutateSeesArrivalList = swrStore.mutators[SWR_KEYS.getSeedArrivalList()];

  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };
  const { data, isLoading, mutate } = useSWR(
    id ? SWR_KEYS.getAgriculturalSeedArrival(id) : null,
    () => api.agriculturalLabour.getSeedArrivalById(id as string),
    {
      onError: showToast
    }
  );

  const handleSubmit = (values: IFormik, helpers: FormikHelpers<FormikValues>) => {
    helpers.setSubmitting(true)
    const payload = serializeFormToValue(values, id);
    const formData = new FormData();
    for (const key in payload) {
      if (!isUndefined(payload[key])) {
        formData.append(key, payload[key]);
      }
    }
    if (!id && values?.file_list?.length) {
      values?.file_list.forEach((file, index) => {
        formData.append(`${FIELDS_NAME.FILE_LIST}[${index}]${FIELDS_NAME.FILE}`, file.file as File);
        formData.append(`${FIELDS_NAME.FILE_LIST}[${index}]${FIELDS_NAME.NAME}`, file.name);
        formData.append(`${FIELDS_NAME.FILE_LIST}[${index}]${FIELDS_NAME.NUMBER}`, file.number);
        formData.append(`${FIELDS_NAME.FILE_LIST}[${index}]${FIELDS_NAME.TYPE}`, file.type);
        if(file.receipt_date){
          formData.append(`${FIELDS_NAME.FILE_LIST}[${index}]${FIELDS_NAME.RECEIPT_DATE}`, dayjs(file.receipt_date).toISOString());
        }
      });
    }    
    const promise = id ? api.agriculturalLabour.updateSeedArrivalById(id, formData) : api.agriculturalLabour
      .createSeedArrival(formData);
    promise
      .then(() => {
        id && mutate();
        mutateSeesArrivalList && mutateSeesArrivalList();
        handleBack();
      })
      .catch((error) => {
        showToast(error);
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const handleBack = () => {
    if (location.key !== LOCATION_KEY.DEFAULT) {
      navigate(-1);
    } else
      navigate({
        pathname: ROUTES_PATHS.agriculturalWorkDocSeedAccounting
      });
  };

  useLayoutEffect(() => {
    headerStore.setProps({
      title: id ? "Редактировать поступление семян" : "Добавить поступление семян",
      subtitle: id ? data?.variety?.name : undefined,
      handleBack
    });
    headerStore.setHeader(VARIANT_HEADER.ADD_SEED_ARRIVAL);
  }, [data]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80}/>
      </Box>
    );
  }

  const values = data ? serializeValueToForm(data) : initialValues;

  const isView = userStore.isPermission(
    PERMISSIONS.regulatory_infoFile,
    PERMISSION_CRUD.view
  )

  return (
    <FormikStepper
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <FormikStepper.FormikStep
        validationSchema={deliveryDataValidationSchema}
        label={STEP_LABELS.DELIVERY_DATA}>
        <DeliveryData/>
      </FormikStepper.FormikStep>
      <FormikStepper.FormikStep
        validationSchema={informationValidationSchema}
        label={STEP_LABELS.INFORMATION}>
        <Information/>
      </FormikStepper.FormikStep>
      { isView ? (
      <FormikStepper.FormikStep
        label={STEP_LABELS.DOCUMENTS}>
        <Documents status={data?.status}/>
      </FormikStepper.FormikStep>
      ) : null
      }
    </FormikStepper>
  );
};

export default observer(AddStatementPage);
