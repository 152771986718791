import React, { Dispatch, SetStateAction } from "react";
import {
  FormikConfig,
  FormikValues,
  FormikHelpers
} from "formik";

export interface IFormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
  handleSubmit?: (values: FormikValues, helpers:FormikHelpers<FormikValues>, handleStep:Dispatch<SetStateAction<number>> ) => void
}

/**
 * Компонент обертка шага формы
 * @param label - заголовок шага
 * @param validationSchema - схема для валидации шага
 * */
const FormikStep = ({ children }: IFormikStepProps)  => {
  return <>{children}</>;
}

export default FormikStep