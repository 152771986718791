import React from "react";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useStores } from "@/hooks";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { ROUTES } from "@/apps/AppRouter/const";
import {
  CopyIcon,
  DeleteIcon,
  EditIcon,
} from "@/components/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { EDITING_CELL } from "../models";
import { buttonDotsStyle } from "@/apps/Table/style";
import { IOffBudget } from "@/api/interfaces/requests";
import { truncateString } from "@/utils/helpers";
import { OffbudgetStatusBadge } from "@/components/OffbudgetStatusBadge";
import { OFFBUDGET_STATUS_NAMES } from "../const";
import { SWR_KEYS } from "@/const";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * @component DateCells
 * * компонент ячейки таблицы дата создания заявки
 * @param
 */

export const DateCells = (
  _: string,
  { year }: IOffBudget,
): JSX.Element => {
  return (
    <Box>
      <Typography color="GrayText" variant="body2">
        {year}
      </Typography>
    </Box>
  );
};

/**
 * @component NameCells
 * * компонент ячейки таблицы наименование культуры
 * @param
 */

export const NameCells = (
  _: string,
  { name }: IOffBudget,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2" title={name}>
        {truncateString(name, 70)}
      </Typography>
    </Box>
  );
};

/**
 * @component BranchCells
 * * компонент ячейки таблицы филиал
 * @param
 */

export const BranchCells = (
  _: string,
  { branch }: IOffBudget,
): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2" title={branch?.name}>
        {branch?.name}
      </Typography>
    </Box>
  );
};

/**
 * @component StatusCells
 * * компонент ячейки таблицы статуса плана
 * @param
 */

export const StatusCells = (
  _: string,
  { status }: IOffBudget,
): JSX.Element => {
  return <OffbudgetStatusBadge status={status} />;
};

export const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => ({
    gap: theme.spacing(1),
  }),
);

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCells = (
  _: string,
  cell: IOffBudget,
): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const mutateList =
    swrStore.mutators[SWR_KEYS.getOffBudget()];

  const handleDeletePlan = (resolve, reject) => {
    api.offBudget
      .deleteOffBudgetPlan(cell.id)
      .then(() => {
        mutateList && mutateList();
        resolve();
      })
      .catch(reject);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.offbudget,
      id: cell.id,
      planFact: "plan",
      handleDelete: handleDeletePlan,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const handleClickDuplicate = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    modalStore.open(
      VARIANT_MODAL.COPY_PLAN,
      {
        id: cell.id,
        name: `${cell.name} (копия)`,
        type: cell.type,
        title: "Копировать заявку",
      },
      true,
    );
  };

  const handleClickEdit = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    navigate(
      `/${ROUTES.offBudget}/${cell.id}/${ROUTES.editPlan}`,
    );
  };

  /**
   * Функция, для добавления факта по плану (только утвержденный план)
   * @param {React.MouseEvent<HTMLElement>} event - the click event
   * @return {void}
   */
  const handleClickAddFact = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    modalStore.open(VARIANT_MODAL.ADD_FACT, {
      initialValue: {
        branch: cell.branch,
        year: cell.year,
        plan: { id: cell.id, name: cell.name },
        region: cell.branch.region,
      },
    });
  };

  const isApprovedOrRejected =
    cell.status === OFFBUDGET_STATUS_NAMES.approved ||
    cell.status === OFFBUDGET_STATUS_NAMES.rejected;

  const hideEdit =
    cell.status === OFFBUDGET_STATUS_NAMES.agreed;

  const canEditPlan = userStore.isPermission(
    PERMISSIONS.extrabudgetaryComPlanFact,
    PERMISSION_CRUD.change
  )
  const canDeletePlan = userStore.isPermission(
    PERMISSIONS.extrabudgetaryComPlanFact,
    PERMISSION_CRUD.delete
  )
  const canAddPlanFact = userStore.isPermission(
    PERMISSIONS.extrabudgetaryComPlanFact,
    PERMISSION_CRUD.add
  )

  const showAddFact = cell.status === OFFBUDGET_STATUS_NAMES.approved && canAddPlanFact;
  const showEdit = !isApprovedOrRejected && !hideEdit && canEditPlan;
  const showDuplicate = canAddPlanFact;
  const showDelete = !isApprovedOrRejected && canDeletePlan;
  
  const showMenu = showAddFact || showEdit || showDuplicate || showDelete;
  
  return (
    <Box>
      {showMenu && (
        <>
          <IconButton
            sx={buttonDotsStyle()}
            onClick={handleToggle}
          >
            <MoreVertIcon />
          </IconButton>
  
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {showAddFact && (
              <StyledMenuItem onClick={handleClickAddFact}>
                <AddIcon />
                {EDITING_CELL.addFact}
              </StyledMenuItem>
            )}
  
            {showEdit && (
              <StyledMenuItem onClick={handleClickEdit}>
                <EditIcon />
                {EDITING_CELL.editing}
              </StyledMenuItem>
            )}
  
            {showDuplicate && (
              <StyledMenuItem onClick={handleClickDuplicate}>
                <CopyIcon />
                {EDITING_CELL.duplicate}
              </StyledMenuItem>
            )}
  
            {(showDelete && showDuplicate || showDelete && showEdit) && <Divider />}
  
            {showDelete && (
              <StyledMenuItem onClick={handleClickDelete}>
                <DeleteIcon color="error" />
                <Typography color="error">
                  {EDITING_CELL.delete}
                </Typography>
              </StyledMenuItem>
            )}
          </Menu>
        </>
      )}
    </Box>
  );
};
