import { FiltersType } from "@/components/Filters/interfaces";
import { IFilter, IValue } from "@/interfaces";
interface IFilterWithParams extends IFilter {
    handleScroll?: (event: React.UIEvent<HTMLElement, UIEvent>) => void;
}

export const getFilters = (
    lightZone?: IValue[],
    LightZoneValue?: IValue,
    handleScroll?: (
        event: React.UIEvent<HTMLElement, UIEvent>,
    ) => void,
): IFilterWithParams[] => {
    return [
        {
            name: "light_zone",
            label: "Световая зона",
            type: FiltersType.autoComplete,
            selectedValue: LightZoneValue,
            handleScroll: handleScroll,
            options: lightZone,
            value: "",
            style: { minWidth: "230px", flexGrow: 1 },
        },
    ];
};