import { IYearWeatherHistory } from "@/api/interfaces/responses";
import yup from "@/utils/yup";
import dayjs, { Dayjs } from "dayjs";


export interface IProps {
  handleClose: () => void;
  modalProps: {
    onSubmit?: () => void;
    initialStep: number;
    initialValues: IYearWeatherHistory,
    id: number,
    year: string | number,
    fieldId: number
  };
}

export const YEAR_WEATHER_FIELD_NAME = {
  LOCATION: 'location',
  YEAR: 'analysis_year',
  SNOW_COVER_ESTABLISHMENT: 'snow_cover_establishment',
  SNOW_COVER_DESCENT: 'snow_cover_descent',
  AUTUMN_FIRST_FROST: 'autumn_first_frost',
  SPRING_LAST_FROST: 'spring_last_frost',
  SPRING_TRASITION_0: 'spring_transition_0',
  AUTUMN_TRANSITION_0: 'autumn_transition_0',
  SPRING_TRANSITION_5: 'spring_transition_5',
  AUTUMN_TRANSITION_5: 'autumn_transition_5',
  SPRING_TRANSITION_10: 'spring_transition_10',
  AUTUMN_TRANSITION_10: 'autumn_transition_10',
  SPRING_TRANSITION_15: 'spring_transition_15',
  AUTUMN_TRANSITION_15: 'autumn_transition_15',
  WATER_STAGNATION: 'water_stagnation',
} as const


export interface TForm {
  [YEAR_WEATHER_FIELD_NAME.LOCATION]: number | null;
  [YEAR_WEATHER_FIELD_NAME.YEAR]: number | null;
  [YEAR_WEATHER_FIELD_NAME.SNOW_COVER_ESTABLISHMENT]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.SNOW_COVER_DESCENT]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.AUTUMN_FIRST_FROST]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.SPRING_LAST_FROST]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.SPRING_TRASITION_0]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_0]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_5]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_5]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_10]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_10]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_15]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_15]: Dayjs | null;
  [YEAR_WEATHER_FIELD_NAME.WATER_STAGNATION]: string;
}


export const mapValueToForm = (value) => {
  return {
    [YEAR_WEATHER_FIELD_NAME.SNOW_COVER_ESTABLISHMENT]: dayjs(value.snow_cover_establishment),
    [YEAR_WEATHER_FIELD_NAME.SNOW_COVER_DESCENT]: dayjs(value.snow_cover_descent),
    [YEAR_WEATHER_FIELD_NAME.AUTUMN_FIRST_FROST]: dayjs(value.autumn_first_frost),
    [YEAR_WEATHER_FIELD_NAME.SPRING_LAST_FROST]: dayjs(value.spring_last_frost),
    [YEAR_WEATHER_FIELD_NAME.SPRING_TRASITION_0]: dayjs(value.spring_transition_0),
    [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_0]: dayjs(value.autumn_transition_0),
    [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_5]: dayjs(value.spring_transition_5),
    [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_5]: dayjs(value.autumn_transition_5),
    [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_10]: dayjs(value.spring_transition_10),
    [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_10]: dayjs(value.autumn_transition_10),
    [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_15]: dayjs(value.spring_transition_15),
    [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_15]: dayjs(value.autumn_transition_15),
    [YEAR_WEATHER_FIELD_NAME.WATER_STAGNATION]: value.water_stagnation || '',

  }
};


export const mapFormToValue = (values: TForm) => {
  return {
    [YEAR_WEATHER_FIELD_NAME.LOCATION]: values.location || null,
    [YEAR_WEATHER_FIELD_NAME.YEAR]: values.analysis_year || null,
    [YEAR_WEATHER_FIELD_NAME.SNOW_COVER_ESTABLISHMENT]: dayjs(values.snow_cover_establishment).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.SNOW_COVER_DESCENT]: dayjs(values.snow_cover_descent).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.AUTUMN_FIRST_FROST]: dayjs(values.autumn_first_frost).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.SPRING_LAST_FROST]: dayjs(values.spring_last_frost).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.SPRING_TRASITION_0]: dayjs(values.spring_transition_0).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_0]: dayjs(values.autumn_transition_0).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_5]: dayjs(values.spring_transition_5).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_5]: dayjs(values.autumn_transition_5).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_10]: dayjs(values.spring_transition_10).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_10]: dayjs(values.autumn_transition_10).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_15]: dayjs(values.spring_transition_15).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_15]: dayjs(values.autumn_transition_15).format('YYYY-MM-DD'),
    [YEAR_WEATHER_FIELD_NAME.WATER_STAGNATION]: values.water_stagnation || '',

  };
};



export const validationSchemaFirst = yup.object().shape({
  [YEAR_WEATHER_FIELD_NAME.SNOW_COVER_ESTABLISHMENT]: yup.mixed().required(),
  [YEAR_WEATHER_FIELD_NAME.SNOW_COVER_DESCENT]: yup.mixed().required(),
  [YEAR_WEATHER_FIELD_NAME.AUTUMN_FIRST_FROST]: yup.mixed().required(),
  [YEAR_WEATHER_FIELD_NAME.SPRING_LAST_FROST]:yup.mixed().required(),
  [YEAR_WEATHER_FIELD_NAME.WATER_STAGNATION]: yup.mixed().required(),
});

export const validationSchemaSecond = yup.object().shape({
  [YEAR_WEATHER_FIELD_NAME.SPRING_TRASITION_0]: yup.mixed().required(),
  [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_0]: yup.mixed().required(),
  [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_5]: yup.mixed().required(),
  [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_5]: yup.mixed().required(),
  [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_10]: yup.mixed().required(),
  [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_10]: yup.mixed().required(),
  [YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_15]: yup.mixed().required(),
  [YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_15]: yup.mixed().required(),
});

