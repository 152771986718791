import { VARIANT_HEADER } from "@/apps/HeaderContent/const"
import { useRightSidebar, useStores } from "@/hooks"
import React, { ReactElement, useEffect, useLayoutEffect } from "react"
import { Box } from "@mui/material"
import { tableWrapperStyle } from "../OffBudget/style"
import theme from "@/theme"
import TableFacts from "./components/TableFacts"
import { useLocation } from "react-router-dom"

/**
 * @component
 * раздел "Внебюджет"
 * @returns ReactElement
 */
const OffBudgetFactPage = (): ReactElement => {
  const { headerStore } = useStores()
  const { handleCloseInstantly } = useRightSidebar();
  const location = useLocation();
  
  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps()
    headerStore.setHeader(VARIANT_HEADER.OFF_BUDGET)
  }, [])


  // закрытие правого сайдбара при переходе на другую страницу
  useEffect(() => {
    return () => {
      handleCloseInstantly();
    };
  }, [location.search]);

  return (
    <Box sx={tableWrapperStyle(theme)}>
      <TableFacts />
    </Box>
  )
}

export default OffBudgetFactPage
