import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { Box, Button, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  ROUTES
} from "@/apps/AppRouter/const";
import { TPlanningTrialType } from "@/api/types";

import {
  HpIcon,
  LabIcon,
  OSIcon,
  PhytoIcon
} from "@/components/icons";
import {
  StyledButton
} from "@/apps/Modals/ModalContent/SelectTrial/styles";
import { TRIAL_TYPE } from "@/const";
import {
  TRIAL_TYPES_SUBMIT,
  validationSchema
} from "@/apps/Modals/ModalContent/SelectTrial/const";

interface IFormik {
  trial_type: TPlanningTrialType;
}

interface ISelectTrial {
  handleClose: () => void;
  modalProps: {
    onSubmitType: keyof typeof TRIAL_TYPES_SUBMIT;
    title: string;
  };
}

const getPath = {
  [TRIAL_TYPE.HP]: `/${ROUTES.planning}/${ROUTES.addPlanPageHP}?trial_type=${TRIAL_TYPE.HP}`,
  [TRIAL_TYPE.OOS]: `/${ROUTES.planning}/${ROUTES.addPlanPageOOS}?trial_type=${TRIAL_TYPE.OOS}`,
  [TRIAL_TYPE.PHYTO]: `/${ROUTES.planning}/${ROUTES.addPlanPagePhyto}?trial_type=${TRIAL_TYPE.PHYTO}`,
  [TRIAL_TYPE.LAB]: `/${ROUTES.planning}/${ROUTES.addPlanPageLAB}?trial_type=${TRIAL_TYPE.LAB}`,
};

export const items = [
  {
    type: TRIAL_TYPE.HP,
    label: "Испытания на хозяйственную полезность",
    icon: <HpIcon/>,
    disabled: false
  },
  {
    type: TRIAL_TYPE.PHYTO,
    label: "Фитопатология",
    icon: <PhytoIcon/>,
    disabled: false
  },
  {
    type: TRIAL_TYPE.OOS,
    label: "Испытания на отличимость, однородность и стабильность",
    icon: <OSIcon/>,
    disabled: false
  },
  {
    type: TRIAL_TYPE.LAB,
    label: "Лабораторные испытания",
    icon: <LabIcon />,
    disabled: false
  }
];

/** Модалка выбора типа испытания */
const SelectTrialPlan: React.FC<ISelectTrial> = ({
  handleClose,
  modalProps
}) => {
  const {
    onSubmitType = "create",
    title = "Добавить план"
  } = modalProps || {};

  const navigate = useNavigate();

  const create = (values) => {
    navigate(getPath[values.trial_type]);
    handleClose();
  };

  const copy = () => {
  };

  const ON_SUBMIT_VARIANTS = {
    create,
    copy
  };

  const formik = useFormik<IFormik>({
    initialValues: { trial_type: "" as TPlanningTrialType },
    validationSchema,
    validateOnMount: true,
    onSubmit: ON_SUBMIT_VARIANTS[onSubmitType]
  });

  const handleChange = (type: TPlanningTrialType) => {
    formik.setFieldValue("trial_type", type);
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Box p={3}>
        <Typography
          variant="h2"
        >
          {title}
        </Typography>
      </Box>
      <Divider/>
      <Box p={3}>
        <Typography marginBottom="20px" fontSize={14}>
          Выберите вид испытания:
        </Typography>
        <Grid container spacing={1}>
          {
            items.map((el) => (
              <Grid key={el.type} item xs={6}>
                <StyledButton
                  startIcon={el.icon}
                  fullWidth
                  variant="outlined"
                  trialType={el.type}
                  active={el.type === formik.values.trial_type}
                  onClick={() => handleChange(el.type)}
                  disabled={el.disabled}
                >
                  {el.label}
                </StyledButton>
              </Grid>))
          }
        </Grid>
      </Box>
      <Divider/>
      <Box p={3} display="flex"
           justifyContent="space-between">
        <Button variant="text" color="red"
                onClick={handleCancel}>
          Отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!formik.values.trial_type || formik.isSubmitting}
        >
          Продолжить
        </Button>
      </Box>
    </form>
  );
};

export default SelectTrialPlan;
