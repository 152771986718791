import { makeAutoObservable } from "mobx";
import { RootStore } from "./Root";
import { Roles } from "@/const";
// import { IGetUserRes } from "@/api/interfaces/responses";
import { resInterface } from "@/api/interfaces";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";
import { ValueOf } from "@/utils/types";

export class UserStore {
  rootStore: RootStore;
  role: string | undefined;
  isEmailConfirmed: boolean;
  id: number | null;
  username: string | null;
  first_name: string | null;
  last_name: string | null;
  middle_name: string | null;
  emails:
    | {
        id: number;
        email: string;
      }[]
    | null;
  phones:
    | {
        name: string;
        id: number;
        phone: string;
      }[]
    | null;
  employee: number | null;
  is_staff: boolean | null;
  is_active: boolean | null;
  date_joined: string | null;
  permissions: { [key: string]: { [key: string]: boolean } };
  avatar: string | null;
  small_avatar: string | null;
  post: string | null;
  trialPassword: null | string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.isEmailConfirmed = false;
    this.emails = null;
    this.phones = null;
    this.username = null;
    this.id = null;
    this.first_name = null;
    this.middle_name = null;
    this.last_name = null;
    this.employee = null;
    this.is_staff = null;
    this.is_active = null;
    this.date_joined = null;
    this.trialPassword = null;
    this.small_avatar = null;
    this.avatar = null;
    this.post = null;
    this.permissions = {};
    this.role = Roles.Unauthorized;
    makeAutoObservable(this);
  }

  getFullname = (): string => {
    if (!this.first_name && !this.last_name && !this.middle_name) {
      console.error("[ERROR] - getFullname отсутвуют данные пользователя");
      return "Не найдено.";
    }
    return `${this.first_name} ${this.last_name} ${this.middle_name}`;
  };

  setRole = (role: string): void => {
    this.role = role;
  };
  setUsername = (username: string | null): void => {
    this.username = username;
  };

  setTrialPassword = (password: string | null): void => {
    this.trialPassword = password;
  };

  updateCurrentUser = () => {
    this.rootStore.api.user.getCurrentUser().then((res) => {
      this.setupUserDataFromReq(res.data);
    });
  };

  isPermission = (
    key: ValueOf<typeof PERMISSIONS>,
    keyCRUD: ValueOf<typeof PERMISSION_CRUD>
  ) => {
    if (this.permissions && Object.values(this.permissions).length)
      if (key in this.permissions) {
        return this.permissions[key][keyCRUD];
      }
    return false;
  };

  setupUserDataFromReq = (data: resInterface.IGetCurrentUserRes) => {
    console.log(data);
    this.permissions = data.permissions;
    this.middle_name = data.middle_name;
    this.id = data.id;
    this.username = data.username;
    this.emails = data.emails;
    this.phones = data.phones;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.employee = data.employee;
    this.is_staff = data.is_staff;
    this.is_active = data.is_active;
    this.date_joined = data.date_joined;
    this.small_avatar = data.small_avatar;
    this.avatar = data.avatar;
    this.post = data.post;
  };
}
