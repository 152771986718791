import React from "react";
import { IIcon } from "./interfaces";

const TrashRemoval = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="13.333496"
      height="15.000000"
      viewBox="0 0 13.3335 15"
      fill="none"
    >
      <path
        id="delete"
        d="M2.5 15C2.0415 15 1.64941 14.8369 1.32275 14.5103C0.996582 14.1841 0.833496 13.7915 0.833496 13.3335L0.833496 2.5L0 2.5L0 0.833496L4.1665 0.833496L4.1665 0L9.1665 0L9.1665 0.833496L13.3335 0.833496L13.3335 2.5L12.5 2.5L12.5 13.3335C12.5 13.7915 12.3369 14.1841 12.0103 14.5103C11.6841 14.8369 11.2915 15 10.8335 15L2.5 15ZM10.8335 2.5L2.5 2.5L2.5 13.3335L10.8335 13.3335L10.8335 2.5ZM4.1665 11.6665L5.8335 11.6665L5.8335 4.1665L4.1665 4.1665L4.1665 11.6665ZM7.5 11.6665L9.1665 11.6665L9.1665 4.1665L7.5 4.1665L7.5 11.6665Z"
        fill="currentColor"
        fillOpacity="1.000000"
        fillRule="evenodd" />
    </svg>
  );
};

export default TrashRemoval;
