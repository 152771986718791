import { FiltersType } from "@/components/Filters/interfaces";
import { IFilter } from "@/interfaces";

/**
 * Поля для фильтрации
 * @param cultivarTypeValue
 * @param handleScroll
 */

interface IFilterWithParams extends IFilter {
  handleSelectChange?: (event: any) => void;
  selectedType?: any;
  selectItems?: any[];
  handleClearSelect?: () => void;
}

export const getFilters = (
  handleSelectChange?: (event: any) => void,
  handleClearSelect?: () => void,
  selectItems?: any[],
  selectedType?: any,
): IFilterWithParams[] => {
  return [
    {
      name: "type",
      label: "Вид испытаний",
      type: FiltersType.select,
      selectedValue: selectedType,
      value: "",
      selectItems: selectItems,
      handleSelectChange: handleSelectChange,
      handleClearSelect: handleClearSelect,
    },
  ];
};
