import { SxProps } from "@mui/material";

/**
 * Функция для переопределения стилей FormControl.
 * @param {width} size - Размер FormControl ("100%", "250px", "auto", etc).
 * @returns {SxProps} - Объект стилей для применения к FormControl.
 */
export const searchWrapper = (width: string): SxProps => ({
  width,
});
