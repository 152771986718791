import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Divider,
  IconButton,
  Menu,
  Typography,
} from '@mui/material';
import { MutatorCallback } from 'swr';
import { DeleteIcon, EditIcon } from '@/components/icons';
import { StyledMenuItem } from '@/components/StyledMenuItem';
import { useStores } from '@/hooks';
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from '@/apps/Modals/const';
import { CELL_TYPES } from '@/apps/Modals/ModalContent/OkOrNotDelete/const';
import { resInterface } from '@/api/interfaces';
import { EXPENSE_TYPE } from '../../../const';

type TProps = {
  id: number;
  item: resInterface.IExpenseList;
  comPlanFactId: number;
  mutate: MutatorCallback;
};

const CostMenuItem: React.FC<TProps> = ({
  id,
  item,
  comPlanFactId,
  mutate,
}): JSX.Element => {
  const [isOpen, setIsOpen] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);
  const { api, modalStore } = useStores();

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const handleDeleteCost = (resolve, reject) => {
    api.offBudget
      .deleteExpenseItem(id)
      .then(resolve)
      .catch(reject)
      .finally(() => {
        mutate();
      });
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.remark,
      handleDelete: handleDeleteCost,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const handleEdit = () => {
    modalStore.open(VARIANT_MODAL.ADD_COST, {
      costId: Number(id),
      isEdit: true,
      title: 'Редактирование расхода',
      type: EXPENSE_TYPE.FINANCIAL_EXPENSES, // тип расхода
      expenseName: item.expense.name, // имя в поле
      initialValue: {
        expense: item.expense,
        com_plan_fact: comPlanFactId,
        years: [{ year: item.year, sum: item.sum }],
      },
    });
  };

  return (
    <Box>
      <IconButton onClick={handleToggle}>
        <MoreVertIcon sx={{ fontSize: 20 }} />
      </IconButton>

      <Menu
        anchorEl={isOpen}
        open={open}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            handleEdit();
          }}
        >
          <EditIcon />
          Редактировать
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem
          onClick={(e) => {
            handleClose(e);
            handleClickDelete(e);
          }}
        >
          <DeleteIcon color='error'/>
          
          <Typography color="error"> Удалить</Typography>
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};

export default CostMenuItem;
