import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  BreadcrumbsComponent,
  IBreadcrumbItem,
} from "@/components/Breadcrumbs/Breadcrums";
import { styled } from "@mui/system";
import { styles } from "./styles";

const StyledBox = styled(Box)(styles);

interface IProps {
  breadcrumbItems: IBreadcrumbItem[];
  title: string;
}

/**
 * Хедер раздела Земельные участки
 * @param breadcrumbItems - массив элементов для хлебных крошек
 * @param title текст заголовка
 */

export const LAHeader = ({
  breadcrumbItems,
  title,
}: IProps) => {
  return (
    <StyledBox>
      <BreadcrumbsComponent items={breadcrumbItems} />
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Typography variant="h2">{title}</Typography>
      </Stack>
    </StyledBox>
  );
};
