import AbstractApi from "@/api/abstract";
import { apiInterface, reqInterface, resInterface } from "@/api/interfaces";
import { IWeatherDataReq } from "@/api/interfaces/requests";
import { IYearWeatherHistory } from "@/api/interfaces/responses";
import { AxiosResponse } from "axios";

class WeatherApi implements apiInterface.IWeatherApi {
    ctx: AbstractApi;
  
    constructor(ctx: AbstractApi) {
      this.ctx = ctx;
    }
    getWeatherById = async (id: number) => {
      const res = await this.ctx.get<
        unknown,
       resInterface.IWeatherHistoryOneDay>
       (`/weather/weather_history/${id}/`);
    return res.data;
    }

    postWeather = async (params: reqInterface.IWeatherDataReq) => {
        const res = await this.ctx.post<
          reqInterface.IWeatherDataReq,
          IWeatherDataReq
        >("/weather/weather_history/", params);
    
        return res.data;
    };
    patchWeather = async (id: number, params: reqInterface.IWeatherDataReq) => {
        const res = await this.ctx.patch<
          reqInterface.IWeatherDataReq,
          IWeatherDataReq
        >(`/weather/weather_history/${id}/`, params);
    
        return res.data;
    };

    getYearWeatherHistoryById = async (id: number) => {
      const res = await this.ctx.get<
        unknown,
       resInterface.IYearWeatherHistory>
       (`/weather/year_weather_history/${id}/`);
      return res.data;
    }

    postYearWeatherHistory = async (
      params: Partial<reqInterface.IYearWeatherHistoryReq>,
    ) => {
      const res = await this.ctx.post<
        Partial<reqInterface.IYearWeatherHistoryReq>,
        AxiosResponse
      >(`/weather/year_weather_history/`, params);
      return res.data;
    };

    patchYearWeatherHistory = async (id: number,params: reqInterface.IYearWeatherHistoryReq) => {
        const res = await this.ctx.patch<
          reqInterface.IYearWeatherHistoryReq,
          IYearWeatherHistory
        >(`/weather/year_weather_history/${id}/`, params);
    
        return res.data;
    };
}

export default WeatherApi;