import React from "react";
import {
  Box,
  Button, Divider,
  IconButton,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import TextSnippetOutlinedIcon
  from "@mui/icons-material/TextSnippetOutlined";
import theme from "@/theme";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useStores } from "@/hooks";
import { ROUTES } from "@/apps/AppRouter/const";
import { MODULES } from "@/pages/Handbook/fixtures";


interface IProps {
  handleClose: () => void,
  modalProps: {
    title: string;
    description: string;
  }
}

const GoToHandbook: React.FC<IProps> = ({  
  modalProps
}) => {

  const { palette } = useTheme();
  const navigate = useNavigate();
  const { modalStore } = useStores();

  const handleClose = () => {
    modalStore.close()
    navigate(-1)
  }

  const handleGoToHandbook = () => {
    modalStore.close();
    navigate(`/${ROUTES.handbook}?module=${MODULES.cultivar_group.id}`);
  };

  return (
    <Box>
      <Stack 
        p={2} 
        direction={"row"} 
        justifyContent={"space-between"} 
        alignItems={"center"}
      >
        <Typography variant='h2'>
          {modalProps.title}
        </Typography>
        <IconButton
          aria-label="close"
          sx={{
            color: palette.blackAndWhite.gray,
            fontSize: "32px",
          }}
          onClick={handleClose}
        >
          <CloseIcon

            fontSize="inherit"
          />
        </IconButton>
      </Stack>
      <Divider />
      <Stack sx={{ padding: "40px 24px" }} alignItems="center" spacing={3}>
        <Box>
          <TextSnippetOutlinedIcon sx={{
            fontSize: 45,
            color: theme.palette.blackAndWhite.stroke
          }} />
        </Box>
        <Typography variant='h3' align="center">
          {modalProps.description}
        </Typography>
        <Button
          onClick={handleGoToHandbook}
          variant="contained"
        >
          Перейти в справочник
        </Button>
      </Stack>
      <Divider />
      <Box p={3} display="flex"
        justifyContent="space-between" 
        flexDirection={"row-reverse"}
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>       
      </Box>
    </Box>
  );
};

export default GoToHandbook;