import { IColumn } from "@/apps/Table/interfaces";
import {
  DateCells,
  EditingCells,
  NameAuthorCells,
  NumberCells,
  StatusCells,
  TypeCells
} from "../components/StatementCellComponents";

import { STATEMENT_HEADER_LABELS, STATEMENT_KEYS } from "../const";

import { TStatemenKeys } from "../interfaces";

import {
  cellAuthor,
  cellButtons,
  cellDate,
  cellNumber,
  cellStatus,
  cellTypes,
  headerCellStyle
} from "../style";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
  };


/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const columns: IColumn<TStatemenKeys>[] = [
  {
    label:  STATEMENT_HEADER_LABELS.status,
    key: STATEMENT_KEYS.status,
    contentHeader: STATEMENT_HEADER_LABELS.status,
    bodyCellProps: {
      render: StatusCells,
      styles: cellStatus
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  STATEMENT_HEADER_LABELS.number,
    key: STATEMENT_KEYS.id,
    contentHeader: STATEMENT_HEADER_LABELS.number,
    bodyCellProps: {
      render: NumberCells,
      styles: cellNumber
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  STATEMENT_HEADER_LABELS.type,
    key: STATEMENT_KEYS.type,
    contentHeader: STATEMENT_HEADER_LABELS.type,
    bodyCellProps: {
      render: TypeCells,
      styles: cellTypes
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  STATEMENT_HEADER_LABELS.executor,
    key: STATEMENT_KEYS.executor,
    contentHeader: STATEMENT_HEADER_LABELS.executor,
    bodyCellProps: {
      render: NameAuthorCells,
      styles: cellAuthor
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  STATEMENT_HEADER_LABELS.date,
    key: STATEMENT_KEYS.registration_date,
    contentHeader: STATEMENT_HEADER_LABELS.date,
    bodyCellProps: {
      render: DateCells,
      styles: cellDate
    },
    headerCellProps: {
      styles: headerCellStyle
    },
  },
  {
    key: 'editing',
    label:  STATEMENT_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCells,
      styles: cellButtons,
    },
  }
];