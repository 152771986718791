import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import theme from "@/theme";
import EditingPopup
  from "@/apps/RightSidebar/templates/Statement/FirstLevel/сomponents/EditingPopup";
import { HP_TYPE, TRIAL_TYPE } from "@/const";
import {
  HpIcon,
  LabIcon,
  OSIcon,
  PhytoIcon
} from "@/components/icons";
import { ITrial } from "@/api/interfaces/responses";
import TrialStatusChip
  from "@/components/Chips/TrialStatusChip";

const HP_TYPE_VALUES = {
  [HP_TYPE.ACCORDING_REGIONS_RF]:
    "По данным государственных испытаний в регионах РФ",
  [HP_TYPE.ACCORDING_EXPERT_ASSESSMENT]:
    "По экспертной оценке. Осмотр селекц. достижения уполномоченным лицом у заявителя",
  [HP_TYPE.ACCORDING_APPLICANT]: "По данным заявителя"
};

const items = {
  [TRIAL_TYPE.HP]: {
    type: TRIAL_TYPE.HP,
    label: "Хоз. полезность",
    color: theme.palette.primary.main,
    icon: <HpIcon sx={{ width: "20px", height: "20px" }}/>
  },
  [TRIAL_TYPE.PHYTO]: {
    type: TRIAL_TYPE.PHYTO,
    label: "Фитопатология",
    color: theme.palette.error.main,
    icon: <PhytoIcon
      sx={{ width: "20px", height: "20px" }}/>
  },
  [TRIAL_TYPE.OOS]: {
    type: TRIAL_TYPE.OOS,
    color: theme.palette.another.orange,
    label: "Испытания на отличимость, однородность и стабильность",
    icon: <OSIcon sx={{ width: "20px", height: "20px" }}/>
  },
  [TRIAL_TYPE.LAB]: {
    type: TRIAL_TYPE.LAB,
    color: theme.palette.another.violet,
    label: "Лабораторные испытания",
    icon: <LabIcon sx={{ width: "20px", height: "20px", color: theme.palette.another.violet,}}/>
  }
};

interface IProps {
  trialItem: ITrial;
  handleClickTrialItem?: () => void;
  handleDelete?: () => void;
  handleEdit?: () => void;
  isActive?: boolean;
  isEdit?: boolean;
  isDelete?: boolean;
}

const TrilItemView: React.FC<IProps> = ({
  trialItem,
  isEdit,
  isActive = true,
  isDelete = true,
  handleClickTrialItem,
  handleDelete,
  handleEdit
}) => {
  return (
    <Stack direction={"column"}
           onClick={handleClickTrialItem}>
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          borderRadius: "4px",
          padding: "10px 15px",
          border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
          transition: ".6s",
          "&:hover": {
            borderColor: theme.palette.primary.main
          }
        }}
      >
        <Stack direction={"row"}
               justifyContent={"space-between"}>
          <Stack direction={"row"} alignItems={"center"}
                 gap={1}>
            {items[trialItem?.trial_type].icon}
            <Typography
              color={items[trialItem?.trial_type].color}
              variant="h6">
              {items[trialItem?.trial_type].label}
            </Typography>
          </Stack>
          {isActive && (
            <EditingPopup
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              isEdit={isEdit}
              isDelete={isDelete}
              colorDelete={theme.palette.red.main}
            />
          )}
        </Stack>
        <Stack mt={1}>
          <Typography
            variant="h4">Испытание {trialItem.id}</Typography>
          <Typography
            mb={1.5}
            variant="h5"
            color={theme.palette.blackAndWhite.gray}
          >
            {HP_TYPE_VALUES[trialItem.type_hp]}
          </Typography>
          <Stack direction={"row"} gap={1}
                 alignItems="center">
            <TrialStatusChip status={trialItem.status}/>
            <Typography
              variant="h5"
              lineHeight={1.5}
              color={theme.palette.blackAndWhite.gray}
            >
              Год испытания:
            </Typography>
            <Typography variant="h4" fontWeight={400}>
              {trialItem.year_of_testing} год
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default TrilItemView;
