import { ValueOf } from "@/utils/types";

export interface IVariantHeader {
  PLANING: "PLANING";
  PROFILE: "PROFILE";
  STATEMENTS: "STATEMENTS";
  ADD_STATEMENTS: "ADD_STATEMENTS";
  AGRICULTURE_WORK: "AGRICULTURE_WORK";
  AGRICULTURE_WORK_DOC: "AGRICULTURE_WORK_DOC";
  HANDBOOK: "HANDBOOK";
  HANDBOOK_AUTO: "HANDBOOK_AUTO";
  OFF_BUDGET: "OFF_BUDGET";
  REPORTS: "REPORTS";
  EDIT_TRIAL: "EDIT_TRIAL";
  ADD_PLAN: "ADD_PLAN";
  ADD_PLAN_HP: "ADD_PLAN_HP";
  ADD_FACT: "ADD_FACT";
  ADD_AREA: "ADD_AREA";
  ADD_FIELD: "ADD_FIELD";
  ADD_ORDER_CULTIVAR: "ADD_ORDER_CULTIVAR";
  ADD_SEED_ARRIVAL: "ADD_SEED_ARRIVAL";
  ADD_CULTIVAR_FIELD_WORK: "ADD_CULTIVAR_FIELD_WORK";
  DEFAULT: "DEFAULT";
  EMPTY: "EMPTY";
  MAIN: "MAIN";
  BRANCH: "BRANCH";
  GSU: "GSU";
  LAND_AREAS: "LAND_AREAS";
}

export type TVariantHeader = ValueOf<IVariantHeader>;

export const VARIANT_HEADER: IVariantHeader = {
  MAIN: "MAIN",
  PLANING: "PLANING",
  PROFILE: "PROFILE",
  STATEMENTS: "STATEMENTS",
  ADD_STATEMENTS: "ADD_STATEMENTS",
  AGRICULTURE_WORK: "AGRICULTURE_WORK",
  AGRICULTURE_WORK_DOC: "AGRICULTURE_WORK_DOC",
  HANDBOOK: "HANDBOOK",
  HANDBOOK_AUTO: "HANDBOOK_AUTO",
  OFF_BUDGET: "OFF_BUDGET",
  REPORTS: "REPORTS",
  EDIT_TRIAL: "EDIT_TRIAL",
  ADD_FACT: "ADD_FACT",
  ADD_PLAN: "ADD_PLAN",
  ADD_PLAN_HP: "ADD_PLAN_HP",
  ADD_AREA: "ADD_AREA",
  ADD_FIELD: "ADD_FIELD",
  ADD_ORDER_CULTIVAR: "ADD_ORDER_CULTIVAR",
  ADD_SEED_ARRIVAL: "ADD_SEED_ARRIVAL",
  ADD_CULTIVAR_FIELD_WORK: "ADD_CULTIVAR_FIELD_WORK",
  DEFAULT: "DEFAULT",
  EMPTY: "EMPTY",
  BRANCH: "BRANCH",
  GSU: "GSU",
  LAND_AREAS: "LAND_AREAS",
};

export const MAIN_NAV = {
  main: `Добро пожаловать в систему ФГБУ "Госсорткомиссия"`,
  branch: `Филиал`,
  landAreas: `Земельный участок`,
};

export const PLANNING_NAVS = {
  planning: "План на ГСУ",
  expertAssessment: "План эксперт. оценок",
  crop: "Места испытаний",
  plan_hp: "Правила группировки",
};

export const OFFBUDGET_NAVS = {
  plan: "План",
  fact: "Факт",
  analysis: "План-факторный анализ",
};

export const AW_NAVS = {
  location: "Участки",
  field: "Поля",
};

export const AW_DOC_NAVS = {
  landUsePlan: "План использования земли",
  seedAccounting: "Учет поступления семян",
  varietyTesting: "Сортоопыты",
  cultivarFieldVarietyLab: "Лабораторные опыты",
  testingExpertReview: "Опыты (Экспертная оценка)",
};
