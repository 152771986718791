import React, { useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react";
import { useParams, useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { Box, CircularProgress } from "@mui/material";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores } from "@/hooks";
import { isUndefined } from "@/utils/helpers";
import FormAddPlanWrap from "./components/FormAddPlanWrap";

/**
 * Страница добавления плана
 * @param headerTitle - заголовок шапки
 * */

interface IProps {
  headerTitle?: string;
}

const PlanningAddPlanPage: React.FC<IProps> = () => {
  const [step, ] = useState(0);
  const { id } = useParams();
  const { api, headerStore } = useStores();
  const [searchParams] = useSearchParams();
  const trialType = searchParams.get("trial_type");
  
  const fetcher = ([, id]: [string, string | undefined]) => {
    if (isUndefined(id)) return null;
    return api.offBudget.getOffBudgetPlan(Number(id));
  };

  const { data, isLoading, mutate } = useSWR(
    ["fetchOffBudgetPlan", id],
    fetcher
  );

  useEffect(() => {
    if (step === 1) mutate();
  }, [step]);

  useLayoutEffect(() => {
    headerStore.setProps({
      title: "Добавить план",
      type: trialType
    });
    headerStore.setHeader(VARIANT_HEADER.EDIT_TRIAL);
  }, [data]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <FormAddPlanWrap/>
  );
};

export default observer(PlanningAddPlanPage);
