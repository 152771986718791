import React from "react";
import { observer } from "mobx-react";

import {
  HeaderAddArea,
  HeaderAddFact,
  HeaderAddField,
  HeaderAddOrderCultivar,
  HeaderAddPlan,
  HeaderAddPlanHp,
  HeaderAddSeedArrival,
  HeaderAddStatement,
  HeaderAgriculturalWorkDoc,
  HeaderAgriculturalWorks,
  HeaderEditTrial,
  HeaderEmpty,
  HeaderHandbook,
  HeaderHandbookAuto,
  HeaderOffBudget,
  HeaderPlaning,
  HeaderProfile,
  HeaderReports,
  HeaderStatement,
  HeaderAddCultivarFieldWork,
} from "./PagesHeaders";

import { useStores } from "@/hooks";

import { isNull, isUndefined } from "@/utils/helpers";

import { TVariantHeader } from "./const";
import HeaderDefault from "@/apps/HeaderContent/PagesHeaders/HeaderDefault";
import HeaderMain from "./PagesHeaders/HeaderMain";
import HeaderBranch from "./PagesHeaders/HeaderBranch";
import HeaderGSU from "./PagesHeaders/HeaderGSU";
import HeaderLandAreas from "./PagesHeaders/HeaderLandAreas";

/**
 * перечисление вариантов хедеров со страниц
 * @returns Record<TVariantHeader, React.FC<any>>
 */
const headerContents: Record<
  TVariantHeader,
  React.FC<any>
> = {
  PLANING: HeaderPlaning,
  PROFILE: HeaderProfile,
  STATEMENTS: HeaderStatement,
  ADD_STATEMENTS: HeaderAddStatement,
  AGRICULTURE_WORK: HeaderAgriculturalWorks,
  AGRICULTURE_WORK_DOC: HeaderAgriculturalWorkDoc,
  HANDBOOK: HeaderHandbook,
  OFF_BUDGET: HeaderOffBudget,
  MAIN: HeaderMain,
  REPORTS: HeaderReports,
  EDIT_TRIAL: HeaderEditTrial,
  ADD_PLAN: HeaderAddPlan,
  ADD_PLAN_HP: HeaderAddPlanHp,
  ADD_FACT: HeaderAddFact,
  HANDBOOK_AUTO: HeaderHandbookAuto,
  ADD_AREA: HeaderAddArea,
  ADD_FIELD: HeaderAddField,
  ADD_ORDER_CULTIVAR: HeaderAddOrderCultivar,
  ADD_SEED_ARRIVAL: HeaderAddSeedArrival,
  ADD_CULTIVAR_FIELD_WORK: HeaderAddCultivarFieldWork,
  DEFAULT: HeaderDefault,
  EMPTY: HeaderEmpty,
  BRANCH: HeaderBranch,
  GSU: HeaderGSU,
  LAND_AREAS: HeaderLandAreas,
};

/**
 * компонент обертка для хедера
 * @returns React.FC
 */
const BaseHeader: React.FC = () => {
  const { headerStore } = useStores();
  const { variant, headerProps } = headerStore;
  const CurrentHeader = headerContents[variant ?? "empty"];

  if (isNull(CurrentHeader) || isUndefined(CurrentHeader)) {
    return null;
  }

  return <CurrentHeader headerProps={headerProps} />;
};

export default observer(BaseHeader);
