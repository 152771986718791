import React, { useState } from "react";
import { ITrial } from "@/api/interfaces/responses";
import TrialItemView
  from "@/apps/RightSidebar/components/TrialItem/components/TrialItemView";
import {
  Box,
  Button,
  Divider,
  styled,
  Typography
} from "@mui/material";
import { DeleteIcon } from "@/components/icons";

const DeleteButton = styled(Button)(({ theme }) => ({
  padding: "5px 15px",
  borderRadius: 4,
  border: "1px solid",
  color: theme.palette.blackAndWhite.white,
  borderColor: theme.palette.red.main,
}));

interface IProps {
  handleClose: () => void,
  modalProps: {
    trialItem: ITrial
    handleAccept: () => Promise<void>
  }
}

const DeleteTrial: React.FC<IProps> = ({
  modalProps, handleClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAccept = () => {
    setIsLoading(true);
    modalProps.handleAccept().then(() => {
      setIsLoading(false);
      handleClose();
    });
  };

  return (
    <Box>
      <Box p={3} pb={0}>
        <Typography
          variant="h2"
          sx={{ paddingRight: "30px" }}
        >
          Вы действительно хотите удалить испытание?
        </Typography>
      </Box>

      <Box p={3}>
        <TrialItemView
          trialItem={modalProps.trialItem}
          isActive={false}
        />
      </Box>

      <Divider />

      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button
          variant="text"
          color={"blue"}
          onClick={handleClose}
        >
          Отмена
        </Button>

        <DeleteButton
          color={"red"}
          variant={"contained"}
          onClick={handleAccept}
          startIcon={<DeleteIcon />}
          disabled={isLoading}
        >
          Удалить
        </DeleteButton>
      </Box>
    </Box>
  );
};

export default DeleteTrial;