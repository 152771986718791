import { IColumn } from "@/apps/Table/interfaces";
import {
  BranchCells,
  CultivarCells,
  VarietyCells,
  StatusCells,
  YearCells,
  EditingCells
} from "../components/Cells";

import {
  CULTIVAR_FIELD_VARIETY_LAB_LABELS,
  CULTIVAR_FIELD_VARIETY_LAB_KEYS
} from "../const";

import {
  TCultivarFieldVarietyLabKeys
} from "../interfaces";

import { cell, headerCellStyle, } from "../style";
import { cellButtons } from "@/apps/Table/style";

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const columns: IColumn<TCultivarFieldVarietyLabKeys>[] = [

  {
    label: CULTIVAR_FIELD_VARIETY_LAB_LABELS.branch,
    key: CULTIVAR_FIELD_VARIETY_LAB_KEYS.branchName,
    contentHeader: CULTIVAR_FIELD_VARIETY_LAB_LABELS.branch,
    bodyCellProps: {
      render: BranchCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: CULTIVAR_FIELD_VARIETY_LAB_LABELS.year,
    key: CULTIVAR_FIELD_VARIETY_LAB_KEYS.year,
    contentHeader: CULTIVAR_FIELD_VARIETY_LAB_LABELS.year,
    bodyCellProps: {
      render: YearCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: CULTIVAR_FIELD_VARIETY_LAB_LABELS.cultivar,
    key: CULTIVAR_FIELD_VARIETY_LAB_KEYS.cultivarName,
    contentHeader: CULTIVAR_FIELD_VARIETY_LAB_LABELS.cultivar,
    bodyCellProps: {
      render: CultivarCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: CULTIVAR_FIELD_VARIETY_LAB_LABELS.variety,
    key: CULTIVAR_FIELD_VARIETY_LAB_KEYS.varietyName,
    contentHeader: CULTIVAR_FIELD_VARIETY_LAB_LABELS.variety,
    bodyCellProps: {
      render: VarietyCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: CULTIVAR_FIELD_VARIETY_LAB_LABELS.status,
    key: CULTIVAR_FIELD_VARIETY_LAB_KEYS.status,
    contentHeader: CULTIVAR_FIELD_VARIETY_LAB_LABELS.status,
    bodyCellProps: {
      render: StatusCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    key: CULTIVAR_FIELD_VARIETY_LAB_KEYS.editing,
    label: "",
    contentHeader: "",
    bodyCellProps: {
      render: EditingCells,
      styles: cellButtons,
    },
  }
];
