import React from "react";
import {
  Typography,
  Grid,
  Box,
  Tooltip,
} from "@mui/material";
import { MutatorCallback } from "swr/_internal";
import theme from "@/theme";
import FEMenuItem from "./FEItemMenu";
import { resInterface } from "@/api/interfaces";
import { stringWithSuffix } from "@/utils/helpers";

interface IProp {
  id: number;
  item: resInterface.IExpenseList;
  mutate: MutatorCallback;
  isHidden?: boolean
}

const FEItem: React.FC<IProp> = ({ id, item, mutate, isHidden=false }) => {
  return (
    <Grid
      container
      alignItems="center"
      wrap="nowrap"
      gap="10px"
      padding="12px 15px"
      justifyContent="space-between"
      overflow="auto"
    >
      <Tooltip placement="bottom-start" title="Год">
        <Box width="150px">
          <Typography
            variant="body2"
            color={theme.palette.blackAndWhite.gray}
          >
            {item.year}
          </Typography>
        </Box>
      </Tooltip>

      <Tooltip
        placement="bottom-start"
        title="Статья затрат"
      >
        <Box width="150px">
          <Typography variant="body2">
            {item.expense.name}
          </Typography>
        </Box>
      </Tooltip>

      <Box width="150px">
        <Tooltip
          placement="bottom-start"
          title="Сумма затрат"
        >
          <Typography
            variant="body2"
            color={theme.palette.blue.main}
            whiteSpace="nowrap"
          >
            {stringWithSuffix(item.sum, "руб", "-")}
          </Typography>
        </Tooltip>
      </Box>

      {isHidden ? null : (
        <FEMenuItem
          item={item}
          comPlanFactId={id}
          id={item.id}
          mutate={mutate}
        />
      )}
    </Grid>
  );
};

export default FEItem;
