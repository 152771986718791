import { IFilter, IValue } from "@/interfaces";
import { FiltersType } from "@/components/Filters/interfaces";
import { OFFBUDGET_STATUS_INPUT } from "../const";

export const getFilters = (
  handleScroll: (
    event: React.UIEvent<HTMLElement, UIEvent>,
  ) => void,
  branch: IValue[],
  branchValue?: IValue,
  statusValue?: IValue,
  setValue?: any,
): IFilter[] => {
  return [
    {
      name: "branch",
      label: "Филиал",
      type: FiltersType.autoComplete,
      selectedValue: branchValue,
      options: branch,
      value: "",
      handleScroll: handleScroll,
      style: { minWidth: "230px", flexGrow: 1 },
      setValue: setValue,
    },
    {
      name: "status",
      label: "Статус",
      type: FiltersType.autoComplete,
      selectedValue: statusValue,
      options: OFFBUDGET_STATUS_INPUT,
      handleScroll: handleScroll,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
  ];
};
