import yup from "@/utils/yup";


type TInitialValues = {
  name: string
  address: Record<string, {id: number}>
}

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    organizationId?: number;
    initialValues?: TInitialValues;
  };
  onSubmit?: () => void;
}

export type TForm = TInitialValues;

export enum FIELDS_NAME {
  ADDRESS = "address",
  NAME = "name",
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.NAME]: yup.string().trim().required(),
  [FIELDS_NAME.ADDRESS]: yup.mixed().required(),
});
