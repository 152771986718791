import yup from "@/utils/yup";

import { reqInterface } from "@/api/interfaces";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    materialTypeId?: number;
    initialValues?: {name?: string};
  };
  onSubmit?: () => void;
}

export type TForm = Pick<reqInterface.IPost, 'name'>;

export enum FIELD_NAME {
  NAME = "name",
}

export const validationSchema = yup.object().shape({
  [FIELD_NAME.NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Это поле не может быть пустым'),
});
