import React from "react"
import { IFilter } from "@/interfaces"
import { Box, Checkbox, FormControlLabel } from "@mui/material"

interface IProps {
    filter: IFilter;
}

/**
 * Отображает checkbox.
 *
 * @param {IProps} filter - Объект фильтра, содержащий необходимые данные.
 * @return {JSX.Element} Компонент select фильтра.
 */

const CheckBoxFilter: React.FC<IProps> = ({ filter }) => {
    return (
        <Box sx={{ position: 'relative' }}>
            <FormControlLabel
                key={filter.name}
                label={filter.label}
                control={<Checkbox onChange={filter.handleCheckboxChange} />}
            />
        </Box>
    )
}

export default CheckBoxFilter