import React, {
  ReactElement,
  useLayoutEffect,
  useEffect,
} from "react";
import { Box } from "@mui/material";

import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores, useRightSidebar } from "@/hooks";
import theme from "@/theme";
import { tableWrapperStyle } from "@/pages/PlaningCrop/style";
import { OrderCultivarTable } from "@/pages/PlaningCrop/components";

/**
 * @component
 * раздел "Места испытаний"
 * @returns ReactElement
 */
const PlaningCropPage = (): ReactElement => {
  const { headerStore } = useStores();
  const { handleCloseInstantly } = useRightSidebar();

  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.PLANING);
  }, []);

  useEffect(() => {
    return () => {
      handleCloseInstantly();
    };
  }, []);

  return (
    <Box sx={tableWrapperStyle(theme)}>
      <OrderCultivarTable />
    </Box>
  );
};

export default PlaningCropPage;
