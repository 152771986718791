import React, { useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import {
  TextField,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import Autocomplete from "@/components/Autocomplete";

import { useStores, useInfiniteScroll } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { FIELDS_NAME } from "../const";

interface IProps {
  handleClose: () => void;
  modalProps: {
    id: number;
  };
}

const AddOBWageFundForm: React.FC<IProps> = ({
  handleClose,
}) => {
  const { api } = useStores();
  const formik = useFormikContext();
  const swrKey = SWR_KEYS.getPostList();

  const [search, setSearch] = useState("");

  const getParameters = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    search: search,
  });

  const fetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getPostList({
      page,
      search,
    });
  };

  const { data, handleScroll } = useInfiniteScroll(
    getParameters,
    fetcher,
    {
      revalidateFirstPage: false,
    },
  );

  const handleChange = (event, value) => {
    formik.setFieldValue(FIELDS_NAME.POST, value);
  };

  return (
    <Form noValidate>
      <Grid
        container
        direction={"column"}
        mt={1}
        spacing={1}
        p={3}
      >
        <Grid p={1}>
          <Field name={FIELDS_NAME.POST}>
            {({ field, meta }) => (
              <FormControl fullWidth required>
                <Autocomplete
                  {...field}
                  onChange={handleChange}
                  label="Должность"
                  data={data}
                  required
                  handleScroll={handleScroll}
                  search={setSearch}
                  error={meta.touched && !!meta.error}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>
        <Grid container p={1} gap={3}>
          <Grid item xs={5.8}>
            <Field name={FIELDS_NAME.STAFF_NUMBER}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  type="number"
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                  label="Количество"
                  fullWidth
                  required
                  size="small"
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={5.8}>
            <Field name={FIELDS_NAME.WAGE_FUND_MONTH}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  type="number"
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                  label="ФОТ в месяц, руб"
                  fullWidth
                  required
                  size="small"
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default AddOBWageFundForm;
