import yup from "@/utils/yup";
import { IAgriculturalLabourWork } from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  WORK: "work",
  FUEL_PRICE: "fuel_price"
} as const;

export interface IFormik {
  [FIELDS_NAME.WORK]: IAgriculturalLabourWork,
  [FIELDS_NAME.FUEL_PRICE]: string
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.WORK]: yup.mixed().required(),
  [FIELDS_NAME.FUEL_PRICE]: yup
    .string()
    .matches(
      /^\d+(\.\d{1,2})?$/,
      "Обратите внимание, рубли и копейки разделяются точкой",
    )
    .required(),
});

