import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import {
  IStatement,
  IStatementFile,
  ISurvey,
  IHpContextRes,
  IHpIndicatorsVariant
} from "@/api/interfaces/responses";
import { STATEMENT_TYPE } from "@/const";
import {
  APPROVAL_SUBJECT_FIELDS,
  PATENT_SUBJECT_FIELDS,
  FIELDS_NAME
} from "./const";
import { ValueOf } from "@/utils/types";
import { STATUSES } from "@/components/FileDropzone/const";

export const getSubjectFieldsByType = (type: ValueOf<typeof STATEMENT_TYPE>) => {
  return type === STATEMENT_TYPE.APPROVAL_STATEMENT ? APPROVAL_SUBJECT_FIELDS : PATENT_SUBJECT_FIELDS;
};
export const mapDataToForm = (data: IStatement) => {
  return {
    ...data,
    registration_date: dayjs(data.registration_date) ?? "",
    year_of_testing: data.year_of_testing || "",
    [FIELDS_NAME.SURVEYS]: [],
    [FIELDS_NAME.SURVEYS_OOS]: []
  };
};

export const serializeStatementFile = (statementFile: IStatementFile) => {
  return {
    uuid: uuidv4(),
    id: statementFile.id,
    name: statementFile.file_name,
    formatSize: statementFile.file_size,
    preview: statementFile.thumbnail_url,
    status: STATUSES.SUCCESS
  };
};

export const serializeSurvey = (surveys: ISurvey[]) => {
  return surveys.map((el) => {
    return {
      id: el.id,
      indicator_methodology: el.indicator_methodology,
      indicator_name: el.indicator_name,
      [FIELDS_NAME.INDICATOR]: {
        index: el.index,
        severity: el.severity,
        label: el.severity ? `${el.index} - ${el.severity}` : "",
      }
    };
  });
};

export const serializeContext = (context?: IHpContextRes | null) => {
  return {
    indicators: context?.indicators || [],
    indicators_variants: context?.indicators ? context.indicators.reduce((acc, el) => {
      acc[el.id] = el.variants.map(variant => ({
        id: variant.severity,
        severity: variant.severity,
        index: variant.index
      }));
      return acc;
    }, {}) : {} as Record<string, IHpIndicatorsVariant[]>
  };
};

export const serializeSurveys = (surveys: ISurvey[] = []) => {
  return surveys.map(survey => {
    return {
      id: survey.id,
      severity: survey.severity ? {
        id: survey.severity,
        name: survey.severity,
        label: survey.severity
      }  : "",
      [FIELDS_NAME.INDICATOR_METHODOLOGY]: survey.indicator_methodology ? {
        id: survey.indicator_methodology,
        name: survey.indicator_name,
        label: survey.indicator_name
      } : null
    };
  });
};