import React from "react";
import {
  Box,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import theme from "@/theme";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import DocumentEditMenu
  from "@/pages/AddSeedArrival/components/DocumentEditMenu";
import dayjs from "dayjs";
import { IFile } from "../../const";

interface IProps {
  data: IFile,
  handleDelete: () => void
  handleEdit: () => void
  hide?: boolean
}

const DocumentListItem: React.FC<IProps> = ({
  data,
  handleDelete,
  handleEdit,
  hide
}) => {
  return (
    <Box p={2} borderRadius={1}
         sx={{
           border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
           display: "flex"
         }}>
      <Stack flexGrow={1} gap={1.2}>
        <Typography variant="h4">{data.name || data.file.name}</Typography>
        <Box display="flex" gap={1.2}>
          <Typography
            width="100px"
            variant="body2"
            color={theme.palette.blackAndWhite.gray}
          >
            {data.receipt_date ? dayjs(data.receipt_date).format("DD.MM.YYYY") : "-"}
          </Typography>
          <Typography variant="body2"
                      color={theme.palette.blackAndWhite.gray}
          >
            {data.number ? `№${data.number}` : null}
          </Typography>
        </Box>
      </Stack>
      <Box display="flex" gap="14px">
        <Box>
          {
            data.file.file_url && (
              <IconButton href={data.file.file_url} download target="blank">
                <FileDownloadOutlinedIcon
                  sx={{
                    color: theme.palette.blackAndWhite.gray,
                    fontSize: 20
                  }}/>
              </IconButton>
            )
          }
        </Box>
        <DocumentEditMenu handleEdit={handleEdit}
                          handleDelete={handleDelete} hide={hide}/>
      </Box>
    </Box>
  );
};

export default DocumentListItem;