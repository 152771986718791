import { reqInterface, resInterface } from "@/api/interfaces";
import yup from "@/utils/yup";

export interface IProps {
    handleClose: () => void;
    modalProps: {
      key: string;
      cultivarUsageTypeId?: number;
      initialValues?: resInterface.ICultivarUsageTypes;
    };
    onSubmit?: () => void;
  }

  export interface ICultivar {
    id: number,
    name: string,
  }

  export type TForm = Omit<reqInterface.ICultivarUsageTypeReq, "cultivar" > & {
    cultivar: {
      id: number;
    };
  };
  

  export enum CULTIVAR_USAGE_TYPE_FIELD_NAME {
    CULTIVAR = "cultivar", 
    NAME = "name", 
    PRODUCT_FORM = "product_form", 
  }

  export const PRODUCT_FORM_TYPES= [
    {value:'main', label: 'основная'},
    {value:'early',label: 'ранняя'},

  ];

  export const mapValueToForm = (
    baseInititialValues,
    cultivarUsageTypeData: resInterface.ICultivarUsageTypes,
  ) => {
    return {
      ...baseInititialValues,
      ...{
        ...cultivarUsageTypeData,
        [CULTIVAR_USAGE_TYPE_FIELD_NAME.CULTIVAR]: {
          ...cultivarUsageTypeData.cultivar,
          label: cultivarUsageTypeData.cultivar?.name
        },
      },
    };
  };

  export const mapFormToValue = (values: TForm) => {
    return {
      ...values,
      [CULTIVAR_USAGE_TYPE_FIELD_NAME.CULTIVAR]: values?.cultivar?.id,
    };
  };


  /*Validation of the form*/

export const validationSchema = yup.object().shape({
    [CULTIVAR_USAGE_TYPE_FIELD_NAME.CULTIVAR]: yup.mixed().required(), 
    [CULTIVAR_USAGE_TYPE_FIELD_NAME.PRODUCT_FORM]: yup.string()
    .trim().required()
    .notOneOf([''], 'Это поле не может быть пустым'),
    [CULTIVAR_USAGE_TYPE_FIELD_NAME.NAME]: yup.string()
      .required()
      .trim()
      .notOneOf([''], 'Это поле не может быть пустым'),
  });
  