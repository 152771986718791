import React from "react";
import {
  Box,
  Button, Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Link as RouterLink } from "react-router-dom";
import { useStores } from "@/hooks";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { FAILED_MESSAGE, ORDER_MODULE } from "./const";

interface IProps {
  modalProps: {
    title: string;
    succesDescription: string;
    failedDescription: string;
    succesMesage: string[];
    failedMesage: string[];
    success_count: number;
    failed_count: number;
  }
}

const SuccessAddPlan: React.FC<IProps> = ({
  modalProps,
}) => {
  const { modalStore } = useStores();
  const handleClose = () => {
    modalStore.close();
  }

  const modalMessage =
    <Typography variant="body1">
      Проверьте наличие &nbsp;
      <Link
        component={RouterLink}
        to={ROUTES_PATHS.statements}
        onClick={handleClose}
      >
        испытаний
      </Link>
      &nbsp; и &nbsp;
      <Link
        component={RouterLink}
        to={ORDER_MODULE}
        onClick={handleClose}
      >
        приказа
      </Link>
      &nbsp; по указанным культурам и ГСУ.
    </Typography>


  return (
    <Box>
      <Box p={3}>
        <Typography variant='h2'>
          {modalProps.title}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ padding: "16px 50px 40px" }}>
        {modalProps.succesMesage.length ?
          <Box
            mt={3}
            sx={{
              "& ul.MuiList-root": {
                m: "8px 0 24px 0"
              }
            }}
          >
            <Typography variant='h3' align="left">
              {modalProps.succesDescription}
            </Typography>
            <List sx={{ m: "24px 0" }}>
              {modalProps.succesMesage?.map((item, index) =>
                <ListItem key={index} sx={{ p: 0 }}>
                  <ListItemText>{`${item}`}</ListItemText>
                </ListItem>
              )}
            </List>
          </Box> :
          null
        }
        {modalProps.failedMesage.length ?
          <Box
            mt={3}
            sx={{
              "& ul.MuiList-root": {
                m: "8px 0 24px 0"
              }
            }}
          >
            <Typography variant='h3' align="left">
              {modalProps.failedDescription}
            </Typography>
            <List sx={{ m: "24px 0" }}>
              {modalProps.failedMesage?.map((item, index) =>
                <ListItem key={index} sx={{ p: 0 }}>
                  <ListItemText>{`${item}`}</ListItemText>
                </ListItem>
              )}
            </List>
            {modalMessage}
          </Box> :
          null
        }
         { modalProps.failed_count === 0 && modalProps.success_count === 0?
          <Box
            mt={3}
            sx={{
              "& ul.MuiList-root": {
                m: "8px 0 24px 0"
              }
            }}
          >
            <Typography variant='h3' align="left">
              {FAILED_MESSAGE}
            </Typography>
            {modalMessage}
          </Box> :
          null
        }
      </Box>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
        flexDirection="row-reverse"
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          onClick={handleClose}
        >
          Продолжить
        </Button>
      </Box>
    </Box>
  );
};

export default SuccessAddPlan;
