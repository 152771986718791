import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";
import Logo from "@/pages/auth/components/Logo";

import Layout from "./components/Layout";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";

const Error404Modules = observer(() => {
  const navigate = useNavigate();

  const handleClickRedirectToAuthPage = () => {
    navigate(ROUTES_PATHS.login);
  };

  return (
    <Layout>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
      >
        <Logo
          position={"relative"}
          top={"auto"}
          left={"auto"}
          transform={"none"}
          pb={2}
        />
        <Typography textAlign={"center"} variant="h1">
          Страница не найдена!
        </Typography>
        <Typography
          variant="h4"
          fontWeight={400}
          textAlign={"center"}
          lineHeight={"24px"}
        >
          Страница, которую вы ищите, не существует, возможно она устарела или
          была удалена.
        </Typography>
        <Stack direction={"row"}>
          <Button
            onClick={handleClickRedirectToAuthPage}
            variant={"contained"}
            color={"primary"}
            size="medium"
          >
            К авторизации
          </Button>
        </Stack>
      </Stack>
    </Layout>
  );
});

export default Error404Modules;
