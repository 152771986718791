import { makeAutoObservable } from "mobx";
import { RootStore } from "./Root";
import { MutatorCallback } from "swr";

export interface IMutatator {
  [swrKey: string]: MutatorCallback;
}

export class SwrStore {
  rootStore: RootStore;
  mutators: IMutatator;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.mutators = {};
    makeAutoObservable(this);
  }

  addMutator = (swrKey: string, mutate: MutatorCallback): void => {
    this.mutators[swrKey] = mutate;
  };

  deleteMutator = (swrKey: string): void => {
    delete this.mutators[swrKey];
  };
}