import React from "react";
import { Formik, FormikHelpers } from "formik";
import { AxiosError } from "axios";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import {
  IForm,
  validationSchema,
  serializeValueToForm,
  initialValues,
  serializeFormToValue
} from "./const";
import { errorToString, pickFrom } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import AddProductivityForm
  from "./components/AddProductivityForm";
import { IProductivity } from "@/api/interfaces/responses";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    handleSuccess: () => void,
    cultivarFieldVarietyId: number,
    initialValues?: IProductivity
    subtitle?: string
  };
}

/**
 * Модалка с формой создания/редактирования урожайности
 * @param initialValues - начальные значения
 * */
const AddProductivity: React.FC<IProps> = ({
  handleClose,
  modalProps
}) => {

  const { api, toastStore } = useStores();

  const showToast = (error:AxiosError) => toastStore.createToast({
    type: TOAST_TYPES.ALERT,
    toastProps: {
      message: errorToString(error),
      severity: "error",
    },
  });
  const handleSubmit = (
    values: IForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const serializedValues = serializeFormToValue(values);
    const payload = {
      ...serializedValues,
      cultivar_field_variety: modalProps.cultivarFieldVarietyId
    };

    const promise = modalProps.initialValues?.id
      ? api.agriculturalLabour.patchProductivityById(
        modalProps.initialValues.id,
        payload
      )
      : api.agriculturalLabour.postProductivity(payload);

    promise
      .then(() => {
        modalProps.handleSuccess && modalProps.handleSuccess();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const formikErrors = pickFrom(error?.response?.data, Object.keys(initialValues));
        if (formikErrors) {
          helpers.setErrors(formikErrors);
        } else {
          showToast(error);
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const values = modalProps?.initialValues ? serializeValueToForm(modalProps.initialValues) : initialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <AddProductivityForm
          id={modalProps.initialValues?.id}
          handleClose={handleClose}
          subtitle={modalProps.subtitle}
        />
      )}
    />
  );
};

export default AddProductivity;
