import React from "react";
import { useMediaQuery } from "@mui/material";
import StepControls
  from "@/components/Stepper/components/StepControls";
import MobileControls
  from "@/components/Stepper/components/MobileControls";
import useStepperContext
  from "@/components/Stepper/hooks/useStepperContext";

export interface IStepProps {
  children?: React.ReactNode
  nextButtonLabel?: string;
  handleSubmit: () => Promise<void> | void
  disabled?: boolean;
}

/**
 * Компонент обертка шага
 * @param children
 * @param nextButtonLabel - заголовок кнопки некст
 * @param handleSubmit - обработчик кнопки некст
 * @param disbled - дизейбл сабмит кнопки
 * */
const Step = ({ children, nextButtonLabel, handleSubmit, disabled }: IStepProps)  => {
  const matches = useMediaQuery("(min-width:1024px)");
  const {handleBack, maxSteps, step, isLastStep} = useStepperContext()
  return (
    <>
      {children}
      {matches ? (
        <StepControls
          handleAccept={handleSubmit}
          nextButtonLabel={nextButtonLabel}
          step={step}
          disabled={disabled}
          handleBack={handleBack}
          isLastStep={isLastStep}
        />
      ) : <MobileControls
        disabled={disabled}
        handleAccept={handleSubmit}
        nextButtonLabel={nextButtonLabel}
        steps={maxSteps}
        activeStep={step}
        handleBack={handleBack}
        isLastStep={isLastStep}
      />}
    </>
  );
}

export default Step