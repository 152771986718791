import React, { useEffect, useState } from "react";
import {
  FastField,
  Field,
  Form,
  useFormikContext,
} from "formik";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CheckIcon from "@mui/icons-material/Check";

import FieldsForOrganization from "./components/FieldsForOrganization";
import FieldsForPlot from "./components/FieldsForPlot";
import AddChip from "@/components/Chips/AddChip";
import { IAddress } from "@/api/interfaces/responses";

import { useInfiniteScroll, useStores } from "@/hooks";

import {
  DISTRIBUTION_FIELD_NAME,
  DISTRIBUTION_ORGANIZATION_ARRAY,
  DISTRIBUTION_TYPES_ARRAY,
  IProps,
  TForm,
} from "../const";
import {
  DISTRIBUTION_ORGANOZATIONS,
  SWR_KEYS,
} from "@/const";
import { VARIANT_MODAL } from "@/apps/Modals/const";

import { useTheme } from "@mui/material/styles";
import Autocomplete from "@/components/Autocomplete";
import {
  PERMISSIONS,
  PERMISSION_CRUD,
} from "@/premissions";

// айтемы в селекте типа разнарядки
const renderDistributionTypeItem =
  DISTRIBUTION_TYPES_ARRAY.map((el) => {
    return (
      <MenuItem key={el.name} value={el.name}>
        <Box
          display={"flex"}
          gap={"10px"}
          alignItems={"center"}
        >
          <ListItemIcon>{el.icon}</ListItemIcon>

          <Typography color={el.textColor}>
            {el.label}
          </Typography>
        </Box>
      </MenuItem>
    );
  });

/**
 * Форма создания/редактирования сорта
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * @param modalProps.key - ключ модалки
 * @param modalProps.submitOnMount - сабмит после монтирования
 * */
const CreateDistributionForm: React.FC<IProps> = ({
  modalProps,
}) => {
  const { modalStore, api, swrStore, userStore } =
    useStores();
  const { idStatement, initialValue, submitOnMount } =
    modalProps;
  const { setModalPropsByKey } = modalStore;
  const [searchMaterialType, setSearchMaterialType] =
    useState("");
  const [searchUnit, setSearchUnit] = useState("");

  const isViewAddress = userStore.isPermission(
    PERMISSIONS.regulatory_infoAddress,
    PERMISSION_CRUD.view,
  );

  const theme = useTheme();
  const formik = useFormikContext<TForm>();

  const handleClickRadio = (event) => {
    if (
      event.target.value === DISTRIBUTION_ORGANOZATIONS.plot
    ) {
      formik.setFieldValue(
        DISTRIBUTION_FIELD_NAME.ORGANIZATION,
        null,
      );
      formik.setFieldValue(
        DISTRIBUTION_FIELD_NAME.ADDRESS,
        null,
      );
    }
    if (
      event.target.value ===
      DISTRIBUTION_ORGANOZATIONS.organization
    ) {
      formik.setFieldValue(
        DISTRIBUTION_FIELD_NAME.PLOT,
        null,
      );
      formik.setFieldValue(
        DISTRIBUTION_FIELD_NAME.BRANCH,
        null,
      );
      formik.setFieldValue(
        DISTRIBUTION_FIELD_NAME.ADDRESS,
        null,
      );
    }

    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.ORGANIZATION_OR_PLOT,
      event.target.value,
    );
  };

  // ед. измерения
  const swrKeyUnit = SWR_KEYS.getUnitList();
  const getKeyUnit = (index: number) => ({
    _key: swrKeyUnit,
    page: index + 1,
    search: searchUnit,
  });

  const fetcherUnits = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getUnitList({ page, search });
  };

  const {
    data: dataUnit,
    handleScroll: handleScrollUnit,
    mutate: mutateUnit,
  } = useInfiniteScroll(getKeyUnit, fetcherUnits, {
    revalidateFirstPage: false,
  });

  // тип материала

  const swrKeyMaterialType = SWR_KEYS.getMaterialTypeList();
  const getKeyMaterialType = (index: number) => ({
    _key: swrKeyMaterialType,
    page: index + 1,
    search: searchMaterialType,
  });

  const fetcherMaterialType = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getMaterialTypeList({
      page,
      search,
    });
  };

  const {
    data: dataMaterialType,
    handleScroll: handleScrollMaterialType,
    mutate: mutateMaterialType,
  } = useInfiniteScroll(
    getKeyMaterialType,
    fetcherMaterialType,
    {
      revalidateFirstPage: false,
    },
  );

  const handleChangeType = (e) => {
    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.TYPE,
      e.target.value,
    );
  };

  const handleCloseWithSave = (
    initialValue = {},
    props = {},
  ) => {
    setModalPropsByKey(modalProps.key, {
      ...modalProps,
      ...props,
      initialValue: {
        ...formik.values,
        ...initialValue,
      },
    });
  };

  const handleOpenModalAddress = () => {
    modalStore.open(VARIANT_MODAL.ADD_ADDRESS, {
      onSubmit: handleSaveAddress,
      initialValue: formik.values.address,
      onClose: handleCloseWithSave,
    });
  };

  const handleSaveAddress = (value: IAddress) => {
    setModalPropsByKey(modalProps.key, {
      initialValue: { ...formik.values, address: value },
    });
  };

  const removeSelectedAddress = () => {
    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.ADDRESS,
      null,
    );
  };

  const handleChangeQuantity = (event) => {
    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.QUANTITY,
      event.target.value,
    );
  };

  const handleChangeUnit = (e, child) => {
    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.UNIT,
      child,
    );
  };

  const handleChangeMaterialType = (e, child) => {
    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.MATERIAL_TYPE,
      child,
    );
  };

  /**
   * Метод выбора даты
   * @param value
   */
  const handleChangeDate = (value: Date) => {
    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.DATE,
      value,
    );
  };

  useEffect(() => {
    submitOnMount && formik.handleSubmit();
  }, [submitOnMount]);

  useEffect(() => {
    swrStore.addMutator(swrKeyUnit, mutateUnit);
    swrStore.addMutator(
      swrKeyMaterialType,
      mutateMaterialType,
    );
  }, []);

  const title = initialValue?.id
    ? "Редактировать разнарядку"
    : "Добавить разнарядку";

  const CustomPaper = (props) => {
    return (
      <Paper
        sx={{
          marginBottom: "7px",
        }}
        elevation={1}
        {...props}
      />
    );
  };

  return (
    <Form noValidate>
      <Box>
        <Box p={3}>
          <Typography
            fontSize={12}
            color={theme.palette.blackAndWhite.gray}
          >
            {initialValue?.material_type?.name}
          </Typography>

          <Typography variant="h2">{title}</Typography>
        </Box>

        <Divider />

        <Box p={3}>
          <Stack direction="column" spacing="20px">
            <Field name={DISTRIBUTION_FIELD_NAME.TYPE}>
              {({ field, meta }) => (
                <FormControl
                  size="small"
                  variant="outlined"
                  required
                >
                  <InputLabel id="select-label">
                    Тип разнарядки
                  </InputLabel>

                  <Select
                    {...field}
                    onChange={handleChangeType}
                    label="Тип разнарядки"
                    fullWidth
                    error={meta.touched && !!meta.error}
                  >
                    {renderDistributionTypeItem}
                  </Select>

                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>

            <Field
              name={
                DISTRIBUTION_FIELD_NAME.ORGANIZATION_OR_PLOT
              }
            >
              {({ field }) => (
                <RadioGroup>
                  <Stack direction="row" spacing="5px">
                    {DISTRIBUTION_ORGANIZATION_ARRAY.map(
                      (el) => {
                        return (
                          <FormControlLabel
                            key={el.type}
                            checked={
                              el.type === field.value
                            }
                            value={el.type}
                            onClick={handleClickRadio}
                            control={
                              <Radio color="secondary" />
                            }
                            label={el.label}
                          />
                        );
                      },
                    )}
                  </Stack>
                </RadioGroup>
              )}
            </Field>

            {formik.values.organization_or_plot ===
            DISTRIBUTION_ORGANOZATIONS.plot ? (
              <FieldsForPlot
                formik={formik}
                idStatement={idStatement}
              />
            ) : (
              <FieldsForOrganization
                formik={formik}
                idStatement={idStatement}
              />
            )}

            <Field name={DISTRIBUTION_FIELD_NAME.ADDRESS}>
              {({ field, meta }) => {
                return (
                  <FormControl required>
                    <FormLabel
                      sx={{
                        fontWeight: 500,
                        color:
                          theme.palette.blackAndWhite.black,
                        marginBottom: "4px",
                      }}
                    >
                      {formik.values
                        .organization_or_plot ===
                      DISTRIBUTION_ORGANOZATIONS.plot ? (
                        <>Адрес ГСУ</>
                      ) : null}
                      {formik.values
                        .organization_or_plot ===
                      DISTRIBUTION_ORGANOZATIONS.organization ? (
                        <>Адрес организации</>
                      ) : null}
                    </FormLabel>

                    <Box>
                      {field.value ? (
                        <Chip
                          size="small"
                          variant="outlined"
                          label={
                            formik.values
                              .organization_or_plot ===
                            DISTRIBUTION_ORGANOZATIONS.organization
                              ? field.value?.address
                                  ?.one_line ||
                                field.value?.one_line
                              : field.value?.one_line
                          }
                          onDelete={
                            !isViewAddress
                              ? undefined
                              : () =>
                                  removeSelectedAddress()
                          }
                        />
                      ) : (
                        <Tooltip
                          title={
                            !isViewAddress
                              ? "У вас недостаточно прав"
                              : ""
                          }
                          followCursor
                        >
                          <Box>
                            <AddChip
                              disabled={!isViewAddress}
                              onClick={
                                handleOpenModalAddress
                              }
                            />
                          </Box>
                        </Tooltip>
                      )}
                    </Box>

                    <FormHelperText
                      error={meta.touched && !!meta.error}
                    >
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            </Field>

            <Field
              name={DISTRIBUTION_FIELD_NAME.MATERIAL_TYPE}
            >
              {({ field, meta }) => (
                <FormControl
                  size="small"
                  variant="outlined"
                  required
                >
                  <Autocomplete
                    {...field}
                    onChange={handleChangeMaterialType}
                    label={"Тип материала"}
                    required={true}
                    data={dataMaterialType}
                    handleScroll={handleScrollMaterialType}
                    search={setSearchMaterialType}
                    error={meta.touched && !!meta.error}
                  />

                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>

            <Box display={"flex"} gap={"20px"}>
              <Field
                name={DISTRIBUTION_FIELD_NAME.QUANTITY}
              >
                {({ field, meta }) => (
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                    size="small"
                    variant="outlined"
                    required
                  >
                    <TextField
                      {...field}
                      value={formik.values.quantity}
                      type="number"
                      size={"small"}
                      onChange={handleChangeQuantity}
                      label="Количество"
                      required
                      fullWidth
                      error={meta.touched && !!meta.error}
                    />
                    <FormHelperText
                      error={meta.touched && !!meta.error}
                    >
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>

              <Field name={DISTRIBUTION_FIELD_NAME.UNIT}>
                {({ field, meta }) => (
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                    size="small"
                    variant="outlined"
                    required
                  >
                    <Autocomplete
                      {...field}
                      PaperComponent={CustomPaper}
                      onChange={handleChangeUnit}
                      label={"Единицы измерения"}
                      required={true}
                      data={dataUnit}
                      handleScroll={handleScrollUnit}
                      search={setSearchUnit}
                      error={meta.touched && !!meta.error}
                    />

                    <FormHelperText
                      error={meta.touched && !!meta.error}
                    >
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
            </Box>

            <FastField name={DISTRIBUTION_FIELD_NAME.DATE}>
              {({ field, meta }) => (
                <DatePicker
                  name={field.name}
                  value={field.value}
                  onChange={handleChangeDate}
                  label="Срок поставки до"
                  slotProps={{
                    textField: {
                      size: "small",
                      required: true,
                      error: meta.touched && !!meta.error,
                      helperText:
                        meta.touched && meta.error,
                      onBlur: field.onBlur,
                    },
                  }}
                />
              )}
            </FastField>
          </Stack>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={formik.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default CreateDistributionForm;
