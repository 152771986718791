import React from "react";
import { Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Box, Button, Divider, Grid, } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME, IFormik } from "../const";
import {
  DiseaseAutocomplete,
  UnitAutocomplete
} from "@/components/Autocompletes";
import FastRegexTextField
  from "@/components/FastRegexTextField";

interface IProps {
  handleClose: () => void;
}

const SelectDiseaseForm: React.FC<IProps> = ({
  handleClose,
}) => {
  const formik = useFormikContext<IFormik>();

  return (
    <Form noValidate>
      <Stack p={3} gap="20px">
        <DiseaseAutocomplete
          name={FIELDS_NAME.DISEASE}
          label="Болезнь/Вредитель"
          required/>
        <Grid container spacing={"20px"}>
          <Grid item xs={6}>
            <FastRegexTextField
              name={FIELDS_NAME.DAMAGE}
              label="Пораженность/ поврежденность"
              regexType="number"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <UnitAutocomplete
              name={FIELDS_NAME.UNIT}
              label="Единица измерения"
              required
            />
          </Grid>
        </Grid>
      </Stack>
      <Divider/>
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon/>}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default SelectDiseaseForm;
