import { styled } from "@mui/material/styles";
import {
  stepLabelClasses,
  Stepper as MuiStepper,
  typographyClasses,
} from "@mui/material";

const stepperWidth = 200;

export const Root = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  width: "100%",
  height: "100%",
  overflowY: "auto",
  [theme.breakpoints.down(1024)]: {
    paddingBottom: theme.spacing(1),
    flexDirection: "column-reverse"
  }
}));

export const Stepper = styled(MuiStepper)(({ theme }) => ({
  height: 500,
  "& .MuiStepConnector-line": {
    height: "100%",
  },
  [`& .${stepLabelClasses.label}`]: {
    fontWeight: 500
  },
  [`& .${stepLabelClasses.label}.Mui-completed`]: {
    color: theme.palette.primary.main,
  },
  [`& .${stepLabelClasses.label}.Mui-active`]: {
    color: theme.palette.blackAndWhite.black
  },
  [`& .${stepLabelClasses.label}.Mui-disabled`]: {
    color: theme.palette.blackAndWhite.gray
  },
}));

export const StepperWrap = styled("div")(({ theme }) => ({
  position: "fixed",
  height: `calc(100% - 70px)`,
  display: "flex",
  flexDirection: "column",
  width: stepperWidth,
  borderRight: `1px solid ${theme.palette.blackAndWhite.stroke}`,
}));

export const ContentWrap = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  maxWidth: `calc(750px + ${stepperWidth}px)`,
  paddingLeft: stepperWidth,
  overflowY: "scroll",
  [theme.breakpoints.down(1024)]: {
    paddingLeft: 0,
    maxWidth: "100%"
  }
}));

export const CircleStepIconRoot = styled("div")<{ ownerState: { active?: boolean, completed?: boolean } }>(
  ({ theme, ownerState }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
    backgroundColor: theme.palette.blackAndWhite.gray,
    borderRadius: "50%",
    ...(ownerState.active && {
      backgroundColor: theme.palette.primary.main,
    }),
    ...(ownerState.completed && {
      backgroundColor: theme.palette.primary.main,
    }),
    [`& .${typographyClasses.root}`]: {
      fontSize: 12,
      color: theme.palette.blackAndWhite.white,
    }
  }),
);