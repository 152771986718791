import React from "react";
import { Formik, FormikHelpers } from "formik";

import { useStores } from "@/hooks";
import useToastContent, {
  TOAST_CONTENT_TYPE,
} from "@/hooks/useToastContent";
import { FormikValues } from "formik/dist/types";
import {
  defaultValues,
  IFormik,
  validationSchema,
} from "./const";
import { AddSignForm } from "./components/AddSignForm";
import { IPlanningHp } from "@/api/interfaces/responses";
import { SWR_KEYS } from "@/const";

interface IProps {
  handleClose: () => void;
  modalProps: {
    id: number;
    key: string;
    cultivarName: string;
    initialValues: IFormik;
    renderData: IPlanningHp;
  };
}

/** Модалка создания заявки */
const AddSign: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const {
    initialValues = {
      ...defaultValues,
    },
  } = modalProps;
  const { api, toastStore, swrStore } = useStores();
  const toastContent = useToastContent(
    TOAST_CONTENT_TYPE.SERVER_ERROR,
  );

  const indicators: any =
    modalProps.renderData?.indicator_methodology;

  const prevIndicatorsIds = indicators.map(
    (el: any) => el.id,
  );

  const mutateDetail =
    swrStore.mutators[
      SWR_KEYS.getPlanningHpByIdKey(modalProps.id)
    ];

  const handleSubmit = (
    values: any,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    const payload = {
      order: modalProps.renderData.order?.id,
      cultivar: modalProps.renderData.cultivar?.id,
      status: modalProps.renderData.status,
      indicator_methodology: [
        ...prevIndicatorsIds,
        values?.sign?.id,
      ],
      editor: modalProps.renderData.editor,
    };

    api.planning
      .patchPlanningHp(modalProps.id, payload as any)
      .then(() => {
        mutateDetail && mutateDetail();
        handleClose();
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
      component={() => (
        <AddSignForm
          cultivarId={modalProps.renderData.cultivar.id}
          cultivarName={modalProps.cultivarName}
        />
      )}
    />
  );
};

export default AddSign;
