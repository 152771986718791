import yup from "@/utils/yup";
import { MAX_NUMBERS, ERROR_MESSAGES } from "@/const";

export const FIELDS_NAME = {
  NAME: "name",
  NUMBER: "number",
  RESIDENT: "resident",
  NON_RESIDENT: "non_resident",
  CULTIVAR: "cultivar"
}


export const validationSchema = yup.object().shape({
  [FIELDS_NAME.NAME] : yup.string().trim().required(),
  [FIELDS_NAME.NUMBER]: yup.string().trim().required(),
  [FIELDS_NAME.CULTIVAR]: yup.array().min(1, ERROR_MESSAGES.REQUIRED).required(),
  [FIELDS_NAME.RESIDENT]: yup.number().required().max(MAX_NUMBERS.MAX),
  [FIELDS_NAME.NON_RESIDENT]: yup.number().required().max(MAX_NUMBERS.MAX)
})

export const defaultValues = {
  [FIELDS_NAME.NAME]: "",
  [FIELDS_NAME.NUMBER]: "",
  [FIELDS_NAME.CULTIVAR]: [],
  [FIELDS_NAME.RESIDENT]: "",
  [FIELDS_NAME.NON_RESIDENT]: ""
}


export const serializedValues = (values) => ({
  ...defaultValues,
  ...values,
})




