import {
  reqInterface,
  resInterface
} from "@/api/interfaces";
import { IVarieties } from "@/api/interfaces/responses";
import yup from "@/utils/yup";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    varietyId?: number;
    initialValues?: resInterface.IVarieties;
    cultivarEditable?: boolean;
    onSubmit?: (variety: IVarieties) => void;
    submitOnMount?: boolean;
  };
}

export interface IRegions {
  id: number;
}


export type TForm = Omit<reqInterface.IVarietiesReq, "cultivar" | "regions"> & {
  cultivar: {
    id: number;
  };
  regions: IRegions[];
};


export enum VARIETY_FIELD_NAME {
  NAME = "name", 
  LATIN_VARIETY_NAME = "latin_variety_name", 
  CULTIVAR = "cultivar", 
  FULLNAME = "fullname", 
  SELECTION_NUMBER = "selection_number", 
  IS_STANDARD = "is_standard", 
  IS_BENCHMARK = "is_benchmark", 
  UPOV_CHECK = "UPOV_check", 
  TYPE = "type", 
  IN_REGISTER = 'in_register',
  IS_INDICATOR = 'is_indicator',
  CIPHER = 'cipher',
  REGIONS ='regions',
  IS_UNIQUE_VARIETY_NAME = "is_unique_variety_name",
}

export const VARIETY_TYPES = [
  {value:'G_common_hybrid', label: 'Простой гибрид'},
  {value:'M_trilinear_hybrid',label: 'Трёхлинейный гибрид'},
  {value:'I_complex_hybrid', label: 'Сложный гибрид'},
  {value:'C_first_generation_hybrid', label: 'Гибрид первого поколения'},
  {value:'B_genetically_modified', label: 'Генетически модифицированный'},
  {value:'A_cultivated_on_republic_territory',label: 'Возделывается на территории республики'},
  {value:'D_cross', label: 'Кросс'},
  {value:'E_line', label: 'Линия'},
  {value:'F_species', label: 'Порода'},
  {value:'H_parent_component', label: 'Родительский компонент'},
  {value:'J_variety_line_hybrid', label: 'Сортолинейный гибрид'},
  {value:'K_variety_population', label: 'Сорт-популяция'},
  {value:'L_type', label: 'Тип'}
];



export const mapFormToValue = (values: TForm) => {
  const regions = values.regions.map((i) => i.id
  );
  return {
    ...values,
    [VARIETY_FIELD_NAME.CULTIVAR]: values?.cultivar?.id,
    [VARIETY_FIELD_NAME.REGIONS]: regions,
  };
};


/**
 * Validation schema using Yup for form validation.
 */
export const validationSchema = yup.object().shape({
  [VARIETY_FIELD_NAME.TYPE]: yup.string().trim().required(),
  [VARIETY_FIELD_NAME.CULTIVAR]: yup.mixed().required(),
  [VARIETY_FIELD_NAME.NAME]: yup.string().trim().required(),
  [VARIETY_FIELD_NAME.FULLNAME]: yup.string().trim(),
  [VARIETY_FIELD_NAME.CIPHER]: yup.string().trim(),
  [VARIETY_FIELD_NAME.LATIN_VARIETY_NAME]: yup
    .string()
    .trim()
    .matches(/[a-zA-Z]+/gu, {
      message: "Возможно использовать только латинские символы",
    }),
  [VARIETY_FIELD_NAME.SELECTION_NUMBER]: yup.string().trim(),
  [VARIETY_FIELD_NAME.IS_STANDARD]: yup.boolean(),
  [VARIETY_FIELD_NAME.IS_BENCHMARK]: yup.boolean(),
  [VARIETY_FIELD_NAME.UPOV_CHECK]: yup.boolean(),
  [VARIETY_FIELD_NAME.IN_REGISTER]: yup.boolean(),
  [VARIETY_FIELD_NAME.IS_INDICATOR]: yup.boolean(),
  [VARIETY_FIELD_NAME.REGIONS]: yup.array(),
});
