import { Chip } from "@mui/material";
import theme from "@/theme";
import React from "react";
import { TPlanningStatus } from "@/api/types";
import CheckIcon from "@mui/icons-material/Check";
import TaskOutlinedIcon
  from "@mui/icons-material/TaskOutlined";

const labels = {
  draft: "Черновик",
  approved: "Утверждено",
};

const icons = {
  draft: <TaskOutlinedIcon color="inherit"/>,
  approved: <CheckIcon color="inherit"/>
};

const colors = {
  draft: theme.palette.blackAndWhite.gray,
  approved: theme.palette.green.main,
};

interface IProps {
  status?: TPlanningStatus;
}

const PlanningStatusChip: React.FC<IProps> = ({ status = "default" }) => {
  const icon = icons[status] || undefined;
  const label = labels[status] || "неизвестно";
  const color = colors[status] || theme.palette.blackAndWhite.gray;
  return (
    <Chip size="small"
          variant="outlined"
          icon={icon}
          label={label}
          sx={{
            color: color,
            borderColor: color
          }}
    />
  );
};

export default PlanningStatusChip;