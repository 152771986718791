import React, { useEffect } from "react";
import { Tab } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

import { ROUTES_PATHS } from "@/apps/AppRouter/const";

import { StyledTabs, tabStyles } from "./style";

import { OFFBUDGET_NAVS } from "../const";
import { useStores } from "@/hooks";

/**
 * хедер для таблиц внебюджета
 * @returns React.FC
 */

const routes = {
  plan: `${ROUTES_PATHS.offBudget}?type=plan`,
  fact: `${ROUTES_PATHS.offBudgetFact}?type=fact`,
  analysis: ROUTES_PATHS.offBudgetAnalysis,
};

const HeaderOffBudget: React.FC = () => {
  const { rightSidebarStore } = useStores();
  const location = useLocation();
  const [value, setValue] = React.useState(
    location.pathname,
  );

  // закрытие правой панели при переходе на родительскую страницу (план) не происходит, поэтому жоское закрытие
  const handleCloseRightSidebar = () => {
    rightSidebarStore.close();
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value !== location.pathname) {
      setValue(location.pathname);
    }
  }, [location.pathname]);

  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      variant="fullWidth"
      sx={{ width: 900 }}
    >
      <Tab
        component={NavLink}
        value={ROUTES_PATHS.offBudget}
        label={OFFBUDGET_NAVS.plan}
        to={routes.plan}
        sx={tabStyles}
        onClick={handleCloseRightSidebar}
      />
      <Tab
        component={NavLink}
        value={ROUTES_PATHS.offBudgetFact}
        label={OFFBUDGET_NAVS.fact}
        to={routes.fact}
        sx={tabStyles}
        onClick={handleCloseRightSidebar}
      />
    </StyledTabs>
  );
};

export default HeaderOffBudget;
