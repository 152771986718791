import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { EmailField } from "./email";
import { PhoneField } from "./phone";
import { IProps } from "../../types";

const SubjectFormThirdStep: React.FC<IProps> = () => {
  return (
    <Stack direction={"column"} gap={2}>
      <Box>
        <Typography>Телефоны</Typography>
        <PhoneField />
      </Box>
      <Box>
        <Typography>Электронные почты</Typography>
        <EmailField />
      </Box>
    </Stack>
  );
};

export default SubjectFormThirdStep;
