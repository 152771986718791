import { resInterface } from "@/api/interfaces";
import yup from "@/utils/yup";

export interface IProps {
    handleClose: () => void;
    modalProps: {
      key: string;
      chemistryId?: number;
      initialValues?: resInterface.IChemistryList;
    };
    onSubmit?: () => void;
  }

  type TType =  'fertilizer' | 'pesticide' 

  export type TForm = {
    name: string,
    type: TType,
  }
  
  export enum CHEMISTRY_FIELD_NAME {
    NAME = "name", 
    TYPE = "type", 
  }

  export const mapFormToValue = (values: TForm) => {
    return {
        [CHEMISTRY_FIELD_NAME.NAME]: values?.name,
        [CHEMISTRY_FIELD_NAME.TYPE]: values?.type
    }
  };

  /*Validation of the form*/

export const validationSchema = yup.object().shape({
    [CHEMISTRY_FIELD_NAME.TYPE]: yup.string().required(), 
    [CHEMISTRY_FIELD_NAME.NAME]: yup.string()
      .required()
      .trim()
      .notOneOf([''], 'Это поле не может быть пустым'),
  });
  