import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import { Box, CircularProgress } from "@mui/material";

import { STEP_LABELS } from "@/pages/AddStatement/const";
import {
  FormikStepper
} from "@/pages/AddStatement/components/FormikStepper/FormikStepper";
import {
  FormikStep
} from "@/pages/AddStatement/components/FormikStep";
import SubjectsFormStep
  from "@/pages/AddStatement/components/SubjectsFormStep/SubjectsFormStep";
import DataFormStep
  from "@/pages/AddStatement/components/DataFormStep/DataFormStep";
import DocumentsStep
  from "@/pages/AddStatement/components/DocumentsStep";
import ImageFormStep
  from "@/pages/AddStatement/components/ImageFormStep/ImageFormStep";

import { useRightSidebar, useStores } from "@/hooks";

import { isNull, isUndefined } from "@/utils/helpers";

import { mapDataToForm } from "@/pages/AddStatement/utils";

import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { KEYS_SIDEBAR_VARIANT_MODULES } from "@/const";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";
import Methodology
  from "@/pages/AddStatement/components/Methodology";

/**
 * Страница добавления заявки
 * @param headerTitle - заголовок шапки
 * */

interface IProps {
  headerTitle?: string;
}

const AddStatementPage: React.FC<IProps> = ({ headerTitle }) => {
  const { id } = useParams();
  const { api, headerStore, queryStringSidebarCollector, userStore } =
    useStores();
  const navigate = useNavigate();
  const { handleOpen } = useRightSidebar();

  const fetcher = ([, id]: [string, string | undefined]) => {
    if (isUndefined(id)) return null;
    return api.statement.getStatementById(Number(id));
  };

  const { data, isLoading, mutate } = useSWR(
    ["fetchStatementById", id],
    fetcher,
    {
      revalidateOnMount: true
    }
  );

  const handleSubmit = () => {
    if (id) {
      const { str } = queryStringSidebarCollector.setup({
        tab: undefined,
        keyContent: KEYS_SIDEBAR_VARIANT_MODULES.STATEMENT,
        module_id: id
      });

      navigate({
        pathname: ROUTES_PATHS.statements,
        search: str
      });
      handleOpen({
        type: KEYS_SIDEBAR_VARIANT_MODULES.STATEMENT,
        modalProps: {
          id
        }
      });
    } else
      navigate({
        pathname: ROUTES_PATHS.statements
      });
  };

  useLayoutEffect(() => {
    headerStore.setProps({
      title: headerTitle,
      status: data?.status,
      type: data?.type,
      id: data?.id
    });
    headerStore.setHeader(VARIANT_HEADER.ADD_STATEMENTS);
  }, [data]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  if (!id || isUndefined(data) || isNull(data)) {
    return null;
  }

  const isFileStep = userStore.isPermission(
    PERMISSIONS.regulatory_infoFile,
    PERMISSION_CRUD.view
  );

  return (
    <FormikStepper
      initialValues={mapDataToForm(data)}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <FormikStep label={STEP_LABELS.SUBJECTS}>
        <SubjectsFormStep mutate={mutate} />
      </FormikStep>
      <FormikStep label={STEP_LABELS.DATA}>
        <DataFormStep mutate={mutate} />
      </FormikStep>
      <FormikStep label={STEP_LABELS.INDICATORS}>
        <Methodology/>
      </FormikStep>
      {isFileStep ? (
        <FormikStep label={STEP_LABELS.DOCUMENTS}>
          <DocumentsStep />
        </FormikStep>
      ) : null}
      {isFileStep ? (
        <FormikStep label={STEP_LABELS.PHOTOGRAPHY}>
          <ImageFormStep />
        </FormikStep>
      ) : null}
    </FormikStepper>
  );
};

export default observer(AddStatementPage);
