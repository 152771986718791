import { ICountry } from "@/api/interfaces/requests";
import {
  Stack,
  FormControl,
  FormHelperText,
  TextField,
  Divider,
  Box,
  Button,
} from "@mui/material";
import { Formik, FormikProps, Field, Form } from "formik";
import React, { FC, useEffect, useState } from "react";
import {
  validationSchema,
  FIELDS_SET,
  ISO_RUSSIAN,
} from "../utils";
import CheckIcon from "@mui/icons-material/Check";
import { SWR_KEYS } from "@/const";
import { useInfiniteScroll, useStores } from "@/hooks";
import { RussianAddressForm } from "./RussianAddressForm";
import { NotRussianAddressForm } from "./NotRussianAddressForm";
import Autocomplete from "@/components/Autocomplete";
import theme from "@/theme";

interface IProps {
  handleSubmit: (values: any, actions: any) => void;
  handleClose: () => void;
  initialValues: any;
  modalProps: any;
}

export const AddressForm: FC<IProps> = ({
  handleSubmit,
  handleClose,
  initialValues,
  modalProps,
}) => {
  const { api, swrStore } = useStores();
  const { initialValues: defaultValues } = modalProps;

  const [countrySearch, setCountrySearch] = useState("");
  const [regionSearch, setRegionSearch] = useState("");

  const swrKeyCountry = SWR_KEYS.getCountries();
  const swrKeyRegions = SWR_KEYS.getRegions();

  const getKeyCountry = (index: number) => ({
    _key: swrKeyCountry,
    page: index + 1,
    search: countrySearch,
  });

  const getKeyRegions = (index: number) => ({
    _key: swrKeyRegions,
    page: index + 1,
    search: regionSearch,
  });

  const fetcherCountry = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getCountriesList({
      page,
      search,
    });
  };

  const fetcherRegions = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getDistrictList({
      page,
      search,
    });
  };

  const {
    data: dataCountry,
    handleScroll: handleScrollCountry,
    mutate: mutateCountry,
  } = useInfiniteScroll(getKeyCountry, fetcherCountry, {
    revalidateFirstPage: false,
  });

  const {
    data: dataRegions,
    handleScroll: handleScrollRegions,
    mutate: mutateRegions,
  } = useInfiniteScroll(getKeyRegions, fetcherRegions, {
    revalidateFirstPage: false,
  });

  useEffect(() => {
    swrStore.addMutator(swrKeyCountry, mutateCountry);
    swrStore.addMutator(swrKeyRegions, mutateRegions);
  }, []);

  return (
    <Stack direction={"column"}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(form: FormikProps<any>) => {
          const handleChangeContry = (event, child) => {
            form.setFieldValue(FIELDS_SET.country, child);
          };

          const fielsSetFromCountry = {
            russian: (
              <RussianAddressForm
                dataRegions={dataRegions}
                handleScrollRegions={handleScrollRegions}
                setRegionSearch={setRegionSearch}
              />
            ),
            not_russian: <NotRussianAddressForm />,
          };

          const renderFieldSet = (
            country: ICountry | undefined,
          ) => {
            if (!country?.iso) return null;
            if (country.iso === ISO_RUSSIAN) {
              return fielsSetFromCountry.russian;
            } else {
              return fielsSetFromCountry.not_russian;
            }
          };
          return (
            <Form>
              <Stack direction={"column"} gap={2} p={2}>
                <Field name={FIELDS_SET.country}>
                  {({ field, meta }) => (
                    <FormControl fullWidth required>
                      <Autocomplete
                        {...field}
                        onChange={handleChangeContry}
                        label="Страна"
                        defaultValue={
                          defaultValues?.country
                        }
                        required
                        data={dataCountry}
                        handleScroll={handleScrollCountry}
                        search={setCountrySearch}
                        error={meta.touched && !!meta.error}
                      />

                      <FormHelperText
                        error={meta.touched && !!meta.error}
                      >
                        {meta.touched && meta.error}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Field>
                {renderFieldSet(
                  form.values[FIELDS_SET.country],
                )}
                <Field name={FIELDS_SET.comment}>
                  {({ field, meta }) => (
                    <FormControl fullWidth>
                      <TextField
                        {...field}
                        label={"Примечания"}
                        inputProps={{
                          maxLength: 100,
                        }}
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={10}
                        defaultValue={
                          defaultValues?.district
                        }
                        sx={{ minHeight: "135px" }}
                        onChange={form.handleChange}
                        onBlur={field.handleBlur}
                        value={
                          form.values[FIELDS_SET.comment]
                        }
                        disabled={meta.isSubmitting}
                      />
                      <Box
                        sx={{
                          color:
                            theme.palette.text.secondary,
                        }}
                      >
                        {form.values[FIELDS_SET.comment]
                          ?.length ?? 0}
                        /100
                      </Box>

                      <FormHelperText
                        error={meta.touched && !!meta.error}
                      >
                        {meta.touched && meta.error}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Field>
              </Stack>
              <Divider />

              <Box
                p={3}
                display="flex"
                justifyContent="space-between"
              >
                <Button color="red" onClick={handleClose}>
                  отмена
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  startIcon={<CheckIcon />}
                >
                  Сохранить
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Stack>
  );
};
