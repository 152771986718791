import React from "react";
import { Box } from "@mui/material";

interface ViewerSinglePhoto {
  handleClose: () => void;
  modalProps: {
    url: string;
  };
}

/**
 * @param {ViewerSinglePhoto} props - Component props.
 * @returns {React.FC<ViewerSinglePhoto>} - Rendered component.
 */
const ViewerSinglePhoto: React.FC<ViewerSinglePhoto> = ({ modalProps }) => {
  const { url } = modalProps;

  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
      <img
        src={url}
        style={{
          height: "100vh",
          display: "block",
          objectFit: "contain"
        }}
      />
    </Box>
  );
};

export default ViewerSinglePhoto;
