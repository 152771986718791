import yup from "@/utils/yup";
import {
  IAgriculturalLabourCultivarField
} from "@/api/interfaces/responses";

// Коордианты для тестирования
// const testCoords = [
//   [-0.03570556640625, 0.039138790901432],
//   [0.047035217285156, 0.037078854833759],
//   [0.0054931640625, -0.00995635981319],
// ];

export const FIELDS_NAME = {
  CULTIVAR: "cultivar",
  COLOUR: "colour",
  AREA: "area",
  COORDINATES: "coordinates"
} as const;

const EMPTY_COORDS = [];

export const initialValues = {
  [FIELDS_NAME.COORDINATES]: EMPTY_COORDS,
  [FIELDS_NAME.COLOUR]: "",
  [FIELDS_NAME.AREA]: "",
  [FIELDS_NAME.CULTIVAR]: null,
};

export interface IValue {
  id: number;
  label: string;
}

export interface IFormik {
  [FIELDS_NAME.COORDINATES]: number[][] | string[][];
  [FIELDS_NAME.COLOUR]: string;
  [FIELDS_NAME.CULTIVAR]: IValue | null;
  [FIELDS_NAME.AREA]: null | number | string;
}

export const serializeValues = (
  values: IAgriculturalLabourCultivarField | undefined
): IFormik => ({
  cultivar: values?.cultivar
    ? {
      id: values.cultivar.id,
      label: values.cultivar.name
    }
    : null,
  area: values?.area || "",
  colour: values?.colour || "",
  coordinates:   values?.map_object?.coordinates?.[0] ? values.map_object.coordinates[0].slice(0, -1).map((el) => [el[1], el[0]]) : EMPTY_COORDS
});

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.COLOUR]: yup.string().required(),
  [FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
  [FIELDS_NAME.AREA]: yup.number().required(),
  [FIELDS_NAME.COORDINATES]: yup
    .array()
    .of(yup.tuple([yup.number().required(), yup.number().required()]))
    .test('minLength', "Добавьте минимум 3 координаты", value => {
      return !(value && value.length > 0 && value.length < 3);
    })
});
