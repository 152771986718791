import React, {
  Fragment,
  ReactElement,
  useMemo,
  useState,
} from "react";
import useSWR from "swr";
import qs from "qs";
import { useNavigate } from "react-router";
import { useSearchParams, } from "react-router-dom";
import BaseTable from "@/apps/Table";
import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";
import {
  ICultivarFieldVariety,
} from "@/api/interfaces/responses";
import {
  useQuery,
  useStores,
  useRightSidebar
} from "@/hooks";
import { columns } from "../models";
import { isUndefined } from "@/utils/helpers";
import {
  TCultivarFieldVarietyLabKeys,
  ICultivarFieldVarietyLabQueryParams
} from "../interfaces";
import {
  ADD_TEXT,
  EMPTY_LIST_TEXT,
  EMPTY_LIST_TEXT_WITH_FILTERS,
  TYPE_TESTING,
} from "../const";
import {
  SWR_KEYS,
  KEYS_SIDEBAR_VARIANT_MODULES,
} from "@/const";
import { Box, Button, Stack } from "@mui/material";
import Filters from "@/components/Filters";
import useFilters
  from "@/pages/CultivarFieldVarietyLab/hooks/useFilters";
import AddIcon from "@mui/icons-material/Add";
import { ROUTES } from "@/apps/AppRouter/const";

/**
 * Компонент, представляющий таблицу Лабораторные опыты
 * @returns {ReactElement}
 */
const Table = (): ReactElement => {
  const { api, swrStore, queryStringSidebarCollector } =
    useStores();
  const [, setSearchParams] = useSearchParams();
  const query = useQuery<ICultivarFieldVarietyLabQueryParams>();
  const filters = useFilters();
  const { handleOpen } = useRightSidebar()
  const navigate = useNavigate()

  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orgeringArray);
  const {
    data,
    error,
    isLoading,
    mutate,
  } = useSWR(
    {
      _key: SWR_KEYS.getCultivarFieldVarietyLabListTestingExpertReview(),
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: query?.search,
      plan__cultivar: query?.cultivar?.key,
      plan__branch: query?.branch?.key,
      status: query?.status?.key,
      trial__year_of_testing: query?.year,
      is_expert_assessment: true
    },
    api.agriculturalLabour.getCultivarFieldVarietyList,
  );

  swrStore.addMutator(SWR_KEYS.getCultivarFieldVarietyLabListTestingExpertReview(), mutate);

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value: TCultivarFieldVarietyLabKeys[]) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  const isFilters: boolean = Object.keys(query).length > 0;

  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   *
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */
  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {
    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY_LAB_TESTING_EXPERT_REVIEW,
      module_id: id,
      typeTesting: TYPE_TESTING.EXPERT_REVIEW,
    });

    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY_LAB_TESTING_EXPERT_REVIEW,
      modalProps: {
        id,
        keySidebar: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY_LAB_TESTING_EXPERT_REVIEW,
      },
    });
    setSearchParams(str);
  };
  const handleAdd = () => {
    navigate(`${ROUTES.addTestingExpertReview}`);
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
        />
        <Stack
          direction={"row"}
          gap={2}
        >
          <Button
            sx={{
              whiteSpace: "nowrap",
              flexShrink: "0",
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAdd}
          >
            Добавить
          </Button>
        </Stack>
      </Box>

      <BaseTable<ICultivarFieldVariety, TCultivarFieldVarietyLabKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={ADD_TEXT}
        textEmptyTableWithFilter={
          EMPTY_LIST_TEXT_WITH_FILTERS
        }
        textEmptyTable={EMPTY_LIST_TEXT}
        onRowClick={handleOpenRightSidebar}
      />
    </Fragment>
  );
};

export default Table;
