import React, { useLayoutEffect } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores } from "@/hooks";
import { Box } from "@mui/material";
import { tableWrapperStyle } from "./style";
import theme from "@/theme";
import BranchView from "./components/BranchView";
import { SWR_KEYS } from "@/const";
import Loader from "@/components/Loader";

const BranchPage: React.FC = () => {
  const { headerStore } = useStores();
  const { id } = useParams();
  const { api } = useStores();

  const { data: renderData, error, isLoading } = useSWR(
    id ? SWR_KEYS.getBranchMainById(id) : null,
    () => api.regulatoryInfo.getBranchMainById(id as string), {}
  );

  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.BRANCH);
  }, []);

  if (error) {
    return <div>Ошибка загрузки данных</div>;
  }

  if (isLoading || !renderData) {
    return <Loader/>;
  }

  return (
    <Box sx={tableWrapperStyle(theme)}>
      <BranchView renderData={renderData} />
    </Box>
  );
};

export default BranchPage;
