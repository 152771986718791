import React, { useMemo } from "react";
import { KeyedMutator } from "swr";
import { Field, FieldArray, useFormikContext } from "formik";
import { Box, Chip, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { IEntity, IStatement } from "@/api/interfaces/responses";
import { FIELDS_NAME, STEP_LABELS } from "@/pages/AddStatement/const";
import { useStores } from "@/hooks";
import { TVariantModal, VARIANT_MODAL } from "@/apps/Modals/const";
import AddChip from "@/components/Chips/AddChip";
import FormAccordion from "@/components/Accordions/FormAccordion";
import { ChipWrap } from "./styles";
import { useAddStatement } from "@/pages/AddStatement/hooks/useAddStatement";
import { getSubjectFieldsByType } from "@/pages/AddStatement/utils";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

interface IProps {
  mutate: KeyedMutator<IStatement>;
}

/**
 * Компонет для шага Субьекты
 * */

const SubjectsFormStep: React.FC<IProps> = ({ mutate }) => {
  const { modalStore, userStore } = useStores();
  const { values } = useFormikContext<IStatement>();

  const { removeStatementValue, checkDisabledByName } = useAddStatement();
  const handleOpenModal = (
    modalVariant: TVariantModal,
    initialValues: IEntity | IEntity[] | null
  ) => {
    modalStore.open(modalVariant, {
      onClose: mutate,
      initialValue: initialValues
    });
  };

  const handleDelete = (name: string, value: IEntity) => {
    if (name === FIELDS_NAME.CENSUS_TAKER) {
      handleOpenModal(VARIANT_MODAL.CENSUS_TAKER, value);
    } else {
      removeStatementValue(name, value.id);
    }
  };

  const fields = useMemo(
    () => getSubjectFieldsByType(values.type),
    [values.type]
  );

  const isNoExpandable = (name: string): boolean => {
    const value = values[name];
    return !value || (Array.isArray(value) && value.length === 0);
  };

  const isSubject = userStore.isPermission(
    PERMISSIONS.registrationSubject,
    PERMISSION_CRUD.view
  );

  return (
    <Stack spacing={3}>
      <Typography variant="h3">{STEP_LABELS.SUBJECTS}</Typography>
      {fields.map(({ name, label, modalVariant, required, isMulti }) => (
        <FormAccordion
          key={name}
          label={label}
          required={required}
          defaultExpanded
          noExpandable={isNoExpandable(name)}
        >
          {isMulti ? (
            <FieldArray
              name={name}
              render={(helpers) => {
                const values = helpers.form.values[name] as IEntity[] | null;
                return (
                  <ChipWrap>
                    {Array.isArray(values) &&
                      values.map((value: IEntity) => (
                        <Chip
                          key={value.id}
                          size="small"
                          variant="outlined"
                          label={value.name}
                          onDelete={
                            !isSubject
                              ? undefined
                              : () => handleDelete(name, value)
                          }
                          disabled={checkDisabledByName(name)}
                        />
                      ))}
                    <Tooltip
                      followCursor
                      title={
                        !isSubject
                          ? "У вас недостаточно прав для редактирования"
                          : ""
                      }
                    >
                      <Box>
                        <AddChip
                          disabled={!isSubject}
                          onClick={() => handleOpenModal(modalVariant, values)}
                        />
                      </Box>
                    </Tooltip>
                  </ChipWrap>
                );
              }}
            />
          ) : (
            <Field name={name}>
              {({ field }) => (
                <ChipWrap>
                  {field.value && (
                    <Chip
                      key={field.name}
                      size="small"
                      variant="outlined"
                      label={field.value.name}
                      onDelete={
                        !isSubject
                          ? undefined
                          : () => handleDelete(field.name, field.value)
                      }
                      disabled={checkDisabledByName(name)}
                    />
                  )}
                  {!field.value && (
                    <Tooltip
                      followCursor
                      title={
                        !isSubject
                          ? "У вас недостаточно прав для редактирования"
                          : ""
                      }
                    >
                      <Box>
                        <AddChip
                          disabled={!isSubject}
                          onClick={() =>
                            handleOpenModal(modalVariant, field.value)
                          }
                        />
                      </Box>
                    </Tooltip>
                  )}
                </ChipWrap>
              )}
            </Field>
          )}
        </FormAccordion>
      ))}
    </Stack>
  );
};
export default SubjectsFormStep;
