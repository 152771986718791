import React from "react";
import { Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";
import { IProps, TForm, WEATHER_WORK_FIELD_NAME } from "../const";

import {
  Box,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import FastRegexTextField from "@/components/FastRegexTextField";

/**
 * Форма создания/редактирования культуры
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const WeatherWorkForm: React.FC<IProps> = ({modalProps}) => {
  const { modalStore } = useStores();
  const form = useFormikContext<TForm>();

  const title = modalProps.id 
    ? "Редактировать условия погоды"
    : "Добавить условия погоды";

  return (
    <>
      <Box p={3}>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} p={2}>
          <FastRegexTextField
            name={WEATHER_WORK_FIELD_NAME.WEATHER_CONDITION}
            label='Условия погоды'
            required
            fullWidth
            size="small"
            multiline
            minRows={4}
            maxRows={10}
          />
        </Stack>
        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red' onClick={() => modalStore.close()}>
            отмена
          </Button>
          <Button
            type='submit'
            disabled={form.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </>
  );
};

export default WeatherWorkForm;
