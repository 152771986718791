import { IColumn } from "@/apps/Table/interfaces";
import { VEGETATION_CAUSES_KEYS, VEGETATION_CAUSES_LABELS } from "../const";
import { PointerTextCell } from "../cells";
import {cellButtonsMaterialType,cellPointText, headerCellStyle } from "../style";
import { TVegetationCausesKey } from "../interfaces";
import { EditingCellsVegetationCauses, NameCell, ReasonmCell } from "../templates/VegetationCauses/cells";

export const COLUMN_EDITING = {
    value: "editing",
    label: "",
};

export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const VegetationCausesColumns: IColumn<TVegetationCausesKey>[] = [
    {
        label: VEGETATION_CAUSES_LABELS.code,
        key: VEGETATION_CAUSES_KEYS.code,
        contentHeader: VEGETATION_CAUSES_LABELS.code,
        bodyCellProps: {
            render: PointerTextCell,
            styles: cellPointText,
        },
        headerCellProps: {
            styles: headerCellStyle,
        },
    },
    {
        label: VEGETATION_CAUSES_LABELS.name,
        key: VEGETATION_CAUSES_KEYS.name,
        contentHeader: VEGETATION_CAUSES_LABELS.name,
        bodyCellProps: {
            render: NameCell,
            styles: cellPointText,
        },
        headerCellProps: {
            styles: headerCellStyle,
        },
    },
    {
        label: VEGETATION_CAUSES_LABELS.type_of_reason,
        key: VEGETATION_CAUSES_KEYS.type_of_reason,
        contentHeader: VEGETATION_CAUSES_LABELS.type_of_reason,
        bodyCellProps: {
          render: ReasonmCell,
          styles: cellPointText,
        },
        headerCellProps: {
          styles: headerCellStyle,
        },
      },
      {
        key: "editing",
        label: VEGETATION_CAUSES_LABELS.editing,
        contentHeader: COLUMN_EDITING.label,
        bodyCellProps: {
          render: EditingCellsVegetationCauses,
          styles: cellButtonsMaterialType,
        },
      },
];