import React from "react";
import { Form, useFormikContext } from "formik";
import {
  Button,
  Typography,
  Box,
  Grid
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME, IFormik } from "../../const";
import {
  YearDatePicker,
  DatePickerField
} from "@/components/Fields";
import {
  CultivarAutocomplete,
  BranchAutocomplete
} from "@/components/Autocompletes";
import { Stack } from "@mui/system";

const FormExpertAssessment = () => {
  const formik = useFormikContext<IFormik>();

  return (
    <Form noValidate>
      <Box p={3} sx={{ maxWidth: 750 }}>
        <Typography variant="h3" marginBottom="20px">
          Данные плана
        </Typography>
        <Stack direction="column" gap="20px">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <DatePickerField name={FIELDS_NAME.PLAN_DATE} label="Дата утверждения плана" required fullWidth/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <YearDatePicker name={FIELDS_NAME.YEAR} label="Год испытаний" required fullWidth/>
            </Grid>
          </Grid>
          <CultivarAutocomplete name={FIELDS_NAME.CULTIVAR} required/>
          <BranchAutocomplete name={FIELDS_NAME.BRANCH} required/>
        </Stack>

        <Box display="flex" justifyContent="space-between"
             mt={5}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<CheckIcon/>}
            disabled={formik.isSubmitting}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default FormExpertAssessment;
