import React from "react";
import { Formik, FormikHelpers } from "formik";
import { Box, Divider, Typography } from "@mui/material";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString, isUndefined } from "@/utils/helpers";
import { SWR_KEYS } from "@/const";

import AddOBWageFundForm from "./components/AddOBWageFundForm";
import { FIELDS_NAME, IFormik, validationSchema } from "./const";
import { observer } from "mobx-react";

/**
 * Компонент модалки добавления расходов для внебюджета
 * @param props
 * @param props.id - id плана внебюджета
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    id: number;
    isEdit?: boolean;
    initialValues?: {
      post: {
        id: number;
        name: string;
      };
      staff_number: number;
      wage_fund_month: number;
    };
    onSubmit?: () => void;
  };
}

const AddOBWageFund: React.FC<IProps> = (props) => {
  const { api, toastStore, swrStore } = useStores();
  const { modalProps } = props;
  const { id } = modalProps;
  const mutate = swrStore.mutators[SWR_KEYS.getOffBudgetWageFund(id)];
  const mutatePlan = swrStore.mutators[SWR_KEYS.getOffBudgetById(id)];
  const isEdit = !!modalProps.isEdit;

  const handleMutate = (): void => {
    mutate?.();
    mutatePlan?.();
    modalProps?.onSubmit?.();
  }

  const convertInitialValues = (item) => {
    if (!isUndefined(item))
      return {
        ...item,
        [FIELDS_NAME.POST]: {
          ...item?.post,
          label: item?.post?.name,
        },
      };
  };

  const initialValues = Object.assign(
    {
      [FIELDS_NAME.POST]: null,
      [FIELDS_NAME.STAFF_NUMBER]: null,
      [FIELDS_NAME.WAGE_FUND_MONTH]: null,
    },
    convertInitialValues(modalProps.initialValues)
  );

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const payload = {
      com_plan_fact: id,
      post: values.post.id,
      staff_number: values.staff_number,
      wage_fund_month: values.wage_fund_month,
    };

    if (isEdit) {
      api.offBudget
        .editOffBudgetWageFund(id, {
          post: values.post.id,
          staff_number: values.staff_number,
          wage_fund_month: values.wage_fund_month,
        })
        .then(() => {
          handleMutate();
          props.handleClose();
        })
        .catch((error) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    } else {
      api.offBudget
        .addOffBudgetWageFund(payload)
        .then(() => {
          handleMutate();
          props.handleClose();
        })
        .catch((error) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    }
  };

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          Добавить расходы на оплату труда
        </Typography>
      </Box>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => <AddOBWageFundForm {...props} />}
      />
    </Box>
  );
};

export default observer(AddOBWageFund);
