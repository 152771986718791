import React from "react";
import {
  Button,
  MobileStepper,
  MobileStepperProps,
} from "@mui/material";
import NavigateNextIcon
  from "@mui/icons-material/NavigateNext";
import CheckIcon from "@mui/icons-material/Check";
import NavigateBeforeIcon
  from "@mui/icons-material/NavigateBefore";

interface IMobileControls extends Omit<MobileStepperProps, 'nextButton' | 'backButton'> {
  isSubmitting?: boolean;
  handleBack: () => void;
  handleClose: () => void;
  isLastStep: boolean;
}
const MobileControls: React.FC<IMobileControls> = ({
  steps,
  activeStep,
  handleBack,
  handleClose,
  isLastStep,
  isSubmitting = false,
  ...props
}) => {
  return (
    <MobileStepper
      steps={steps}
      activeStep={activeStep}
      position="static"
      sx={{padding: 0}}
      {...props}
      nextButton={
        <Button
          endIcon={isLastStep ? null : <NavigateNextIcon/>}
          startIcon={isLastStep ? <CheckIcon/> : null}
          disabled={isSubmitting}
          variant="contained"
          color="primary"
          type="submit"
        >
          {isLastStep ? "Готово" : "Далее"}
        </Button>
      }
      backButton={
        activeStep === 0 ? (
            <Button
              disabled={isSubmitting}
              color="red"
              onClick={handleClose}
            >
              Отмена
            </Button>
          ) :
          (
            <Button
              startIcon={<NavigateBeforeIcon/>}
              disabled={activeStep === 0 || isSubmitting}
              variant="contained"
              color="secondary"
              onClick={handleBack}
            >
              Назад
            </Button>
          )
      }
    />
  );
};

export default MobileControls;