import { Chip } from "@mui/material";
import theme from "@/theme";
import React from "react";
import { TCultivarFieldVarietyStatus } from "@/api/types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RefreshOutlinedIcon
  from "@mui/icons-material/RefreshOutlined";
import {
  STATUSES_CULTIVAR_FIELD_VARIETY_KEYS
} from "@/const";
import {
  EditDocumentIcon,
  ProgressActivityIcon,
} from "@/components/icons";

const WORK_ICON = {
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.COMPLETED]:
    <CheckIcon
      color="inherit"/>,
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.ABORTED]: <CloseIcon
    color="inherit"/>,
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.NOT_SOWED]:
    <EditDocumentIcon
      color="inherit"/>,
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.NOT_COMPLETED]:
    <EditDocumentIcon
      color="inherit"/>,
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.OBSERVATION]:
    <RefreshOutlinedIcon
      color="inherit"/>,
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.PLANNED]:
    <ProgressActivityIcon color="inherit"/>,
  DEFAULT: undefined
};

const WORK_LABEL = {
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.COMPLETED]: "Выполнено",
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.ABORTED]: "Прервано",
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.NOT_COMPLETED]: "Не выполнено",
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.NOT_SOWED]: "Не заложено",
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.OBSERVATION]: "Наблюдение",
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.PLANNED]: "Запланировано",
  DEFAULT: "Неизвестно"
};

const WORK_COLOR = {
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.COMPLETED]: theme.palette.primary.main,
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.ABORTED]: theme.palette.error.main,
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.NOT_SOWED]: theme.palette.blackAndWhite.gray,
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.NOT_COMPLETED]: theme.palette.blackAndWhite.gray,
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.OBSERVATION]: theme.palette.yellow.main,
  [STATUSES_CULTIVAR_FIELD_VARIETY_KEYS.PLANNED]: theme.palette.blue.main,
  DEFAULT: theme.palette.blackAndWhite.gray
};

interface IProps {
  status?: TCultivarFieldVarietyStatus;
}

const CultivarFieldVarietyStatusChip: React.FC<IProps> = ({ status = "DEFAULT" }) => {
  const icon = WORK_ICON[status] || WORK_ICON.DEFAULT;
  const label = WORK_LABEL[status] || WORK_LABEL.DEFAULT;
  const color = WORK_COLOR[status] || WORK_COLOR.DEFAULT;
  return (
    <Chip size="small"
          variant="outlined"
          icon={icon}
          label={label}
          sx={{
            color: color,
            borderColor: color
          }}
    />
  );
};

export default CultivarFieldVarietyStatusChip;