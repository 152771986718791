import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { IProps, TForm, validationSchema } from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import OrganizationForm from "./components/AddOrganizationForm";
import { SWR_KEYS } from "@/const";


/**
 * Модалка с формой создания/редактирования организаций
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const Orgaization: React.FC<IProps> = ({ handleClose, modalProps, onSubmit }) => {

  const baseInititialValues = {
    name: null,
    address: null,
  };

  const { organizationId } = modalProps;
  const { api, toastStore, swrStore } = useStores();

  const mutateOrganization = organizationId ? 
    swrStore.mutators[
      SWR_KEYS.getOrganizationById(Number(organizationId))
    ]: null;

  const mutateOrganizationList =
    swrStore.mutators[SWR_KEYS.getOrganizationList()];

  const handleSuccessPromise = (): void => {
    mutateOrganization && mutateOrganization();
    onSubmit && onSubmit();
  };

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const payload = {
      name: values.name,
      address: values.address?.id as unknown as number,
    }

    const promise = organizationId
      ? api.statement.patchOrganization(
          organizationId,
          payload,
        )
      : api.statement.postOrganization(payload);

    promise
      .then(() => {
        handleSuccessPromise();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateOrganization && mutateOrganization();
        mutateOrganizationList();
        handleClose();
      });
  };

  const values = modalProps?.initialValues
    ? { ...baseInititialValues, ...modalProps.initialValues }
    : baseInititialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <OrganizationForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      )}
    />
  );
};

export default Orgaization;
