
import React, { Fragment, useEffect } from 'react'
import { useStores } from '@/hooks';
import { Field, Form, useFormikContext } from 'formik';
import { Alert, Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { BUDGETARY_TRIAL_CALC_FIELD_NAME, IProps, TForm } from '../const';
import CheckIcon from "@mui/icons-material/Check";
import theme from '@/theme';
import FastRegexTextField from '@/components/FastRegexTextField';
import { YearDatePicker } from '@/components/Fields';

/**
 * Форма создания/редактирования фазы вегетации
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * */

export const BudgetaryTrialCalcForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<TForm>();

  const title = modalProps.budgetaryTrialCalcId
    ? "Редактировать запись"
    : "Добавить запись";

  useEffect(() => {
    formik.errors.non_field_errors && formik.setFieldError('non_field_errors', undefined)
  }, [formik.values])

  const TRIAL_TYPE = {
    hp: "hp",
    oos: "oos",
  };

  useEffect(() => {
    if (formik.values.type_of_trial === 'oos') {
      formik.setFieldValue(BUDGETARY_TRIAL_CALC_FIELD_NAME.TYPE_OF_APPLICANT, 'individual');
    }
  }, [formik.values.type_of_trial]);

  return (
    <Fragment>
      <Box p={3}>
        <Typography fontSize={12} color={theme.palette.blackAndWhite.gray}>
          Счетчик бюджетных испытаний
        </Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>

        <Stack direction={"column"} gap={2} p={2}>
          <YearDatePicker
            name={BUDGETARY_TRIAL_CALC_FIELD_NAME.YEAR_OF_TRIAL}
            label='Год испытания'
            required
          />
          <Field name={BUDGETARY_TRIAL_CALC_FIELD_NAME.TYPE_OF_TRIAL}>
            {({ field }) => (
              <FormControl>
                <RadioGroup {...field}>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                  >
                    <FormLabel>Вид испытаний: </FormLabel>
                    <FormControlLabel
                      value={TRIAL_TYPE.hp}
                      control={<Radio color="secondary" />}
                      label="Хоз. полезность"
                    />
                    <FormControlLabel
                      value={TRIAL_TYPE.oos}
                      control={<Radio color="secondary" />}
                      label="ООС"
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          </Field>

          {formik.values.type_of_trial === 'hp' &&

            <Field name={BUDGETARY_TRIAL_CALC_FIELD_NAME.TYPE_OF_APPLICANT}>
              {({ field }) => (
                <FormControl>
                  <RadioGroup {...field}>
                    <Stack
                      direction="row"
                      alignItems={"center"}
                    >
                      <FormLabel>Тип заявителя: </FormLabel>
                      <FormControlLabel
                        value={"legal_entity"}
                        control={<Radio color="secondary" />}
                        label="Юридическое лицо"
                      />
                      <FormControlLabel
                        value={"individual"}
                        control={<Radio color="secondary" />}
                        label="Физическое лицо"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            </Field>
          }

          <FastRegexTextField
            name={BUDGETARY_TRIAL_CALC_FIELD_NAME.AMOUNT_OF_EXPERIENCES}
            label='Количество сортоопытов'
            fullWidth
            size="small"
            required
          />
        </Stack>

        <Divider />

        {formik.errors?.non_field_errors &&
          <Alert
            severity="error">
            {formik.errors?.non_field_errors}
          </Alert>
        }

        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red'
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type='submit'
            disabled={formik.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  )
}
