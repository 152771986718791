import { reqInterface, resInterface } from "@/api/interfaces";
import yup from "@/utils/yup";


export interface IProps {
    handleClose: () => void;
    modalProps: {
      key: string;
      districtId?: number;
      initialValues?: resInterface.IDistrictRF;
    };
    onSubmit?: () => void;
  }

export interface IDistrictBranch {
    id: number,
    name: string
}

export interface IDistrictLightZone {
    id: number,
    rome_number: string
}

export type TForm = Omit<
  reqInterface.IDistrictRFReq,
  | "branch"
  | "light_zone"
> & {
  light_zone: IDistrictLightZone,
  branch: IDistrictBranch,
};

export enum DISTRICT_FIELD_NAME {
    NAME = 'name',
    FULL_NAME = 'full_name',
    POSTAL_NAME = 'postal_name',
    REGIONAL_CAPITAL = 'regional_capital',
    ISO ='iso',
    TIME_ZONE = 'time_zone',
    BRANCH = 'branch',
    LIGHT_ZONE = 'light_zone'
}

export const mapFormToValue = (values: TForm) => {
    return {
      ...values,
      [DISTRICT_FIELD_NAME.LIGHT_ZONE]: values.light_zone?.id,
      [DISTRICT_FIELD_NAME.BRANCH]: values.branch?.id,
    };
};

/*Validation of the form*/

export const validationSchema = yup.object().shape({
  [DISTRICT_FIELD_NAME.NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Это поле не может быть пустым'),

  [DISTRICT_FIELD_NAME.FULL_NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Это поле не может быть пустым'),

  [DISTRICT_FIELD_NAME.BRANCH]: yup.object<IDistrictBranch>().required(),
  [DISTRICT_FIELD_NAME.POSTAL_NAME]: yup.string().trim(),
  [DISTRICT_FIELD_NAME.REGIONAL_CAPITAL]: yup.string().trim(),
  [DISTRICT_FIELD_NAME.ISO]: yup.string().trim(),
});