import React, {
    Fragment,
    ReactElement,
    useMemo,
    useState,
  } from "react";
  import {
    useSearchParams,
  } from "react-router-dom";
  import Table from "@/apps/Table";
  import { observer } from "mobx-react";
  import { useQuery, useStores } from "@/hooks";
  import {
      ICultivarGroups,
    IPaginatedResult,
  } from "@/api/interfaces/responses";
  import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
  import { SWR_KEYS } from "@/const";
  import { IErrorResponse } from "@/interfaces";
  import useSWR, { SWRResponse } from "swr";
  import {
    IPropsSidebar,
    TCultivarGroup,
  } from "../../interfaces";
  import AddIcon from "@mui/icons-material/Add";
  import qs from "qs";
  import Filters from "@/components/Filters";
  import { Box, Button } from "@mui/material";
  
  import { IFilter } from "@/interfaces";
  import {
    THEME_MODAL,
    VARIANT_MODAL,
  } from "@/apps/Modals/const";
  import { isUndefined } from "@/utils/helpers";
import { CultivarGroupColumns } from "../../models/cultivarGroup";
  
  export const getFilters = (): IFilter[] => {
    return [];
  };
  
  /**
   * Компонент, представляющий таблицу Направления использования.
   * @returns {ReactElement}
   */
  const CultivarGroupTable: React.FC<IPropsSidebar> = ({
    handleOpenRightSidebar,
  }): ReactElement => {
    const { api, swrStore, modalStore } = useStores();
    const query = useQuery<any>();
    const [, setSearchParams] = useSearchParams();
  
    // переменная которая показывает применены ли фильтры
    const isFilters: boolean =
      Object.keys(query).length > 0;
    // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
    const orgeringArray: string[] = useMemo(() => {
      if (isUndefined(query.ordering)) return [];
      return Array.isArray(query.ordering)
        ? query.ordering
        : [query.ordering];
    }, [query.ordering]);
  
    const [orderBy, setOrderBy] =
      useState<string[]>(orgeringArray);
    const {
      data,
      error,
      isLoading,
      mutate,
    }: SWRResponse<
      IPaginatedResult<ICultivarGroups>,
      IErrorResponse
    > = useSWR(
      {
        page: query.page,
        page_size: DEFAULT_SIZE_PAGE,
        ordering: orderBy.join(","),
        search: query.search,
        key: SWR_KEYS.getCultivarGroupList(),
      },
      api.regulatoryInfo.getCultivarGroups,
    );
  
    const filters = getFilters();
  
    const handleOpenCreateModal = () => {
      modalStore.setTheme(THEME_MODAL.W_555);
      modalStore.open(VARIANT_MODAL.CULTIVAR_GROUP, {
        theme: THEME_MODAL.W_555,
      });
    };
  
    const handleChangePage = (value: number) => {
      const queryParams = qs.stringify(
        { ...query, page: value },
        { arrayFormat: "comma" },
      );
      setSearchParams(queryParams);
    };
  
    const handleChangeOrderBy = (value) => {
      const queryParams = qs.stringify(
        { ...query, ordering: value },
        { arrayFormat: "comma" },
      );
      setSearchParams(queryParams);
      setOrderBy(value);
    };

    swrStore.addMutator(SWR_KEYS.getCultivarGroupList(), mutate);
  
    return (
      <Fragment>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          <Filters
            filters={filters}
            defaultSearch={query.search}
          />
  
          <Button
            sx={{
              whiteSpace: "nowrap",
              flexShrink: "0",
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpenCreateModal}
          >
            Добавить запись
          </Button>
        </Box>
  
        <Table<ICultivarGroups, TCultivarGroup>
          columns={CultivarGroupColumns}
          isLoading={isLoading}
          data={data}
          error={error}
          page={query.page ?? 1}
          setPage={handleChangePage}
          orderBy={orderBy}
          setOrderBy={handleChangeOrderBy}
          isFilters={isFilters}
          textAdd={"Добавить запись"}
          textEmptyTableWithFilter={
            "Не найдено ни одной записи"
          }
          textEmptyTable={"Еще не создано ни одной записи"}
          onRowClick={handleOpenRightSidebar}
          emptyTableAddEntity={handleOpenCreateModal}
        />
      </Fragment>
    );
  };
  
  export default observer(CultivarGroupTable);
  
  