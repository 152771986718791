import * as React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";

import AvatarUser from "@/components/avatar/AvatarUser";

import { userButtonStyle } from "../style";
import { useStores } from "@/hooks";

interface IProps {
  toggleDropdown: (value: React.MouseEvent<HTMLButtonElement>) => void;
  isOpen: boolean;
}

/**
 * @component
 * Компонент в шапке mainLayout, по клику на которые открывается дропдаун
 * @param toggleDropdown функция открытия/закрытия дропдауна
 * @param isOpen состояние дропдауна
 * @returns
 */
const UserInfo: React.FC<IProps> = ({ toggleDropdown, isOpen }) => {
  const { userStore } = useStores();
  const theme = useTheme();

  return (
    <Button
      id="basic-button"
      aria-controls={isOpen ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={isOpen ? "true" : undefined}
      onClick={toggleDropdown}
      sx={userButtonStyle(theme, isOpen)}
    >
      <Box className="userBlock" component="span">
        <Typography 
          className="userName"
          component="span"
          data-qa='name'
        > 
          {userStore.first_name} {userStore.last_name}
        </Typography>

        <Typography className="userProfession" component="span">
          {userStore.post ?? "Не указано"}
        </Typography>
      </Box>
      <AvatarUser
        initials={
          userStore.first_name && userStore.last_name
            ? userStore.first_name[0] + userStore.last_name[0]
            : "ИИ"
        }
        imageUrl={userStore.small_avatar}
      />
    </Button>
  );
};

export default UserInfo;
