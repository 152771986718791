import React, {
  ReactElement,
  useEffect,
  useLayoutEffect
} from "react";
import { Box, Typography } from "@mui/material";

import { tableWrapperStyle } from "./style";

import theme from "@/theme";
import Table from "./components/Table";
import { useStores } from "@/hooks";
import useRightSidebar from "@/hooks/useRightSidebar";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";

/**
 * @component
 * раздел "Планирование"
 * @returns ReactElement
 */
const PlaningPage = (): ReactElement => {
  const { handleCloseInstantly } = useRightSidebar();
  const { headerStore } = useStores();

  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.AGRICULTURE_WORK_DOC);
  }, []);

  // закрытие правого сайдбара при переходе на другую страницу
  useEffect(() => {
    return () => {
      handleCloseInstantly();
    };
  }, []);

  return (
    <Box sx={tableWrapperStyle(theme)}>
      <Box mb={2}>
        <Typography
          variant="h2"
          pb={1}
        >
          План использования земли
        </Typography>
      </Box>
      <Table />
    </Box>
  );
};

export default PlaningPage;
