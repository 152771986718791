import React from "react";
import {
  Autocomplete,
  ListItem,
  SxProps,
  TextField,
} from "@mui/material";
import qs from "qs";
import { useSearchParams } from "react-router-dom";

import { IFilter, IValue } from "@/interfaces";
import { IStatementQueryParams } from "@/api/interfaces/requests";

import { useDebounceCallback, useQuery } from "@/hooks";

import { isUndefined } from "@/utils/helpers";

interface AutocompleteFilterProps {
  filter: IFilter;
  onChange: (value: IValue | null, name: string) => void;
}

type TReason = "input" | "reset" | "clear";

/**
 * Автокомплит, принимающий асинхронные данные
 * необходимо в filter прописать коллбек который запросит следующие данные при прокрутке в конец
 * @param filter - информация о фильтре (имя, тип и тд.), в том числе коллбек на запрос следующей страницы
 * @param onChange - коллбек работающий в момент изменения данных
 * @returns
 */
const AutocompleteFilter: React.FC<
  AutocompleteFilterProps
> = ({ filter, onChange }) => {
  const query = useQuery<IStatementQueryParams>();
  const [, setSearchParams] = useSearchParams();

  // функция работающая при выборе варианта в селект
  const handleChange = (
    event: React.ChangeEvent,
    value: IValue | null,
  ) => {
    onChange(value, filter.name);
    // handleChangeSearchKey(null);
  };

  // коллбек прокрутки вариантов селекта
  const handleScroll = (event) => {
    filter.handleScroll?.(event);
  };

  // отправка квери параметров при фильтрации вариантов в селекте
  const handleInputChange = useDebounceCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: TReason,
    ) => {
      filter.setValue && filter?.setValue(value);

      if (isUndefined(filter.searchKey)) return;
      if (reason === "input") {
        handleChangeSearchKey(value);
      }
      if (reason === "reset") {
        handleChangeSearchKey(null);
      }
    },
    300,
  );

  const handleChangeSearchKey = (
    value: string | null,
  ): void => {
    if (isUndefined(filter.searchKey)) return;
    const hasSearch = value !== null ? value : undefined;

    const queryParams = qs.stringify(
      {
        ...query,
        [filter.searchKey]: hasSearch,
      },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const filterValue = filter.options ?? [];

  const sxProps: SxProps = [
    filter.style ?? {},
    {
      "& .MuiAutocomplete-clearIndicator": {
        visibility: "visible",
      },
    },
  ];

  return (
    <Autocomplete
      id={filter.name}
      freeSolo
      forcePopupIcon
      autoHighlight
      size={"small"}
      defaultValue={filter.selectedValue}
      sx={sxProps}
      onChange={handleChange}
      options={filterValue}
      noOptionsText={"Результаты отсутствуют"}
      onInputChange={handleInputChange}
      ListboxProps={{
        sx() {
          return { maxHeight: "200px" };
        },
        onScroll: handleScroll, // Добавляем обработчик скролла к элементу списка результатов
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={filter.label}
          variant="outlined"
        />
      )}
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.key}>
            {option.label}
          </ListItem>
        );
      }}
    />
  );
};

export default AutocompleteFilter;
