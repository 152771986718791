import { SxProps, Theme } from "@mui/material/styles";

// стили для ячеек таблицы Планирования ХП

export const tableWrapperStyle = (theme: Theme): SxProps => ({
  width: "100%",
  padding: theme.spacing(3),
});

export const cultivarCellsStyle = (theme: Theme): SxProps => ({
  whiteSpace: "nowrap",
  minWidth: '200px',
  padding: theme.spacing(3),
});

export const headerCellStyle = (theme: Theme): SxProps => ({
  whiteSpace: "nowrap",
  color: theme.palette.blackAndWhite.black,
  fontWeight: "600",
});

export const cellStatus = (theme: Theme): SxProps => ({
  padding: theme.spacing(1),
});

export const cellNumber = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  color: theme.palette.blackAndWhite.gray,
  whiteSpace: "nowrap",
  minWidth: '200px'
});

export const cellYear = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  whiteSpace: "nowrap",
  minWidth: '200px'
});

export const cellApproval = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  whiteSpace: "nowrap",
  minWidth: '200px'
});

export const nameAuthorCellsStyle = (): SxProps => ({
  display: "flex",
  alignItems: "center",
  gap: "6px",
});

export const cellDate = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
});
