import React, { Fragment } from "react";
import { Field, Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";

import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import {
  INDICATOR_FIELD_NAME,
  IProps,
  TForm,
} from "../const";

/**
 * Форма создания/редактирования
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const IndicatorVariantForm: React.FC<IProps> = ({
  modalProps,
}) => {
  const { modalStore } = useStores();
  const form = useFormikContext<TForm>();

  const title = modalProps.isEdit
    ? "Редактировать вариант признака"
    : "Добавить вариант признака";

  return (
    <Fragment>
      <Box p={3}>
        <Typography>Признак</Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"row"} gap={2} p={2}>
          {modalProps.trialType === "oos" ? (
            <Field name={INDICATOR_FIELD_NAME.INDEX}>
              {({ field, meta }) => (
                <FormControl required fullWidth>
                  <TextField
                    {...field}
                    label="Индекс"
                    fullWidth
                    size="small"
                    required
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={
                      form.values[
                        INDICATOR_FIELD_NAME.INDEX
                      ]
                    }
                    disabled={meta.isSubmitting}
                  />
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
          ) : null}

          <Field name={INDICATOR_FIELD_NAME.SEVERITY}>
            {({ field, meta }) => (
              <FormControl required fullWidth>
                <TextField
                  {...field}
                  label={
                    modalProps.trialType === "hp"
                      ? "Значение признака"
                      : "Степень выраженности"
                  }
                  fullWidth
                  size="small"
                  required
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={
                    form.values[
                      INDICATOR_FIELD_NAME.SEVERITY
                    ]
                  }
                  disabled={meta.isSubmitting}
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>
        
        {form.errors?.non_field_errors &&
          <Alert
            severity="error">
            {form.errors?.non_field_errors}
          </Alert>
        }

        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={form.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default IndicatorVariantForm;
