import React from "react";
import { useParams } from "react-router-dom";
import FileDropzone from "@/components/FileDropzone";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { STATEMENT_FILES_TYPE, STEP_LABELS } from "@/pages/AddStatement/const";
import { useStores } from "@/hooks";
import { DROPZONE_VARIANT, IFile, IMAGE_ACCEPT, IMAGE_SUBTITLE } from "@/components/FileDropzone/const";
import { useFormikContext } from "formik";
import { IStatement } from "@/api/interfaces/responses";
import { serializeStatementFile } from "@/pages/AddStatement/utils";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

const ImageFormStep = () => {
  const { id } = useParams();
  const { api, userStore } = useStores();
  const { values } = useFormikContext<IStatement>();
  const fetcher = (fd: FormData) =>
    api.statement.addStatementFileById(id as string, fd);
  const deleteMutation = (fileId: number) =>
    api.statement.deleteStatementFile(id as string, fileId);

  const photos = values.files
    .filter((el) => el.type === STATEMENT_FILES_TYPE.PHOTO)
    .map(serializeStatementFile);

  const isFileAdd = userStore.isPermission(
    PERMISSIONS.regulatory_infoFile,
    PERMISSION_CRUD.add
  );

  const isFileDelete = userStore.isPermission(
    PERMISSIONS.regulatory_infoFile,
    PERMISSION_CRUD.delete
  );
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h3">{STEP_LABELS.PHOTOGRAPHY}</Typography>
      <FileDropzone
        name={STATEMENT_FILES_TYPE.PHOTO}
        fetcher={fetcher}
        variant={DROPZONE_VARIANT.IMG}
        value={photos as IFile[]}
        isFileAdd={isFileAdd}
        isFileDelete={isFileDelete}
        deleteMutation={deleteMutation}
        accept={IMAGE_ACCEPT}
        subtitle={IMAGE_SUBTITLE}
      />
    </Stack>
  );
};

export default ImageFormStep;
