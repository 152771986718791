import React from "react";
import {
  Stack,
  Typography,
} from "@mui/material";
import { DatePickerField } from "@/components/Fields";
import { YEAR_WEATHER_FIELD_NAME } from "../const";


const YearWeatherHistorySecondStep: React.FC<any> = () => {

  return (
    <Stack direction={"column"} gap={2}>
        <Typography variant="h4">
        Весна
        </Typography>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.SPRING_TRASITION_0}  label="Дата устойчивого перехода температуры воздуха через предел 0°" fullWidth/>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_5}  label="Дата устойчивого перехода температуры воздуха через предел 5°" fullWidth/>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_10}  label="Дата устойчивого перехода температуры воздуха через предел 10°" fullWidth/>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.SPRING_TRANSITION_15}  label="Дата устойчивого перехода температуры воздуха через предел 15°" fullWidth/>
        <Typography variant="h4">
        Осень
        </Typography>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_0}  label="Дата устойчивого перехода температуры воздуха через предел 0°" fullWidth/>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_5}  label="Дата устойчивого перехода температуры воздуха через предел 5°" fullWidth/>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_10}  label="Дата устойчивого перехода температуры воздуха через предел 10°" fullWidth/>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.AUTUMN_TRANSITION_15}  label="Дата устойчивого перехода температуры воздуха через предел 15°" fullWidth/>
    </Stack>
  );
};

export default YearWeatherHistorySecondStep;