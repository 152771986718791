import { makeAutoObservable } from "mobx";
import { RootStore } from "./Root";
import { IModalProps } from "@/interfaces";

export type TRSidebarShowBlock = "sidebar";

export class RightSidebarStore {
  currentShowingBlock: TRSidebarShowBlock;
  isStartClosingAnimation: boolean;
  isOpen: boolean;
  isCollapse: boolean;
  sidebarProps: IModalProps;
  type: string | null;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.isOpen = false;
    this.isCollapse = true;
    this.isStartClosingAnimation = false;
    this.sidebarProps = { id: 0 };
    this.type = null;
    makeAutoObservable(this);
  }

  setStartAnimationClosing = (): void => {
    this.isStartClosingAnimation = true;
  };

  open = (
    type: string,
    sidebarProps: IModalProps,
  ): void => {
    this.type = type;
    this.sidebarProps = sidebarProps;
    this.isOpen = true;
  };

  close = (): void => {
    this.sidebarProps = { id: 0 };
    this.isOpen = false;
    this.isStartClosingAnimation = false;
  };

  collapse = (): void => {
    this.isCollapse = true;
  };

  expand = (): void => {
    this.isCollapse = false;
  };
}
