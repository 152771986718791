import {
  apiInterface,
  resInterface
} from "@/api/interfaces";
import AbstractApi from "../../abstract";

class ExcelUploadTaskApi implements apiInterface.IExcelUploadTask {
  ctx: AbstractApi;

  constructor (ctx: AbstractApi) {
    this.ctx = ctx;
  }

  getExcelUploadTaskById = async (id: number | string) => {
    const res = await this.ctx.get<unknown, resInterface.IStatementUploadExcel>(`/excel_upload_task/${id}/`);
    return res.data;
  };

  postExcelUploadTask = async (data: FormData) => {
    const res = await this.ctx.post<FormData, resInterface.IStatementUploadExcel>(`/excel_upload_task/upload_statements/`, data);
    return res.data;
  };
}

export default ExcelUploadTaskApi;
