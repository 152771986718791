import React from "react";
import { Formik } from "formik";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import AddWorkForm from "./components/AddWorkForm";
import {
  defaultValues,
  IFormik,
  validationSchema,
} from "./const";

/**
 * Компонент модалки добавления технологической операции для внебюджета деталка культура
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    title?: string;
    initialValues?: IFormik;
    handleSubmit: () => void;
  };
}

const AddALWork: React.FC<IProps> = (props) => {
  const { modalProps } = props;
  const { title, handleSubmit } = modalProps;

  const initialValues = Object.assign(
    defaultValues,
    modalProps.initialValues,
  );

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {title ?? 'Добавить работу'}
        </Typography>
      </Box>
      <Divider />
      <Formik<IFormik>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => <AddWorkForm handleClose={props.handleClose} />}
      />
    </Box>
  );
};

export default AddALWork;
