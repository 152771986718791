import yup from "@/utils/yup";

export const SIGN_FIELD_NAME = {
  INDICATOR_METHODOLOGY: "indicator_methodology",
  VARIANT: "variant",
  VARIETY: "variety",
  NON_FIELD_ERRORS: "non_field_errors",
} as const;

const signSchema = yup.array().of(
  yup.object().shape({
    [SIGN_FIELD_NAME.INDICATOR_METHODOLOGY]: yup
      .number()
      .required(),
    [SIGN_FIELD_NAME.VARIANT]: yup.mixed().required(),
    [SIGN_FIELD_NAME.VARIETY]: yup
      .array()
      .of(yup.mixed())
      .required(),
  }),
);

export const validationSchema = yup.object().shape({
  sign: signSchema,
});
