import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores } from "@/hooks";
import React, { useEffect, useLayoutEffect } from "react";
import OffBudgetTable from "./components/OffBudgetTable";
import { Box } from "@mui/material";
import { tableWrapperStyle } from "./style";
import theme from "@/theme";
import useRightSidebar from "@/hooks/useRightSidebar";

const OffBudgetPage: React.FC = () => {
  const { headerStore } = useStores();
  const { handleCloseInstantly } = useRightSidebar();

  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.OFF_BUDGET);
  }, []);

  // закрытие правого сайдбара при переходе на другую страницу
  useEffect(() => {
    return () => {
      handleCloseInstantly();
    };
  }, []);

  return (
    <Box sx={tableWrapperStyle(theme)}>
      <OffBudgetTable />
    </Box>
  );
};

export default OffBudgetPage;
