import { IColumn } from "@/apps/Table/interfaces";
import {
  EditingCell,
  StatusCell,
  YearCell,
  CultivarCell,
  NumberCell,
} from "../components/PlanningCellComponents";

import {
  PLANNING_HP_HEADER_LABELS,
  PLANNING_HP_KEYS,
} from "../const";

import { TPlanningHpKeys } from "../interfaces";

import {
  cellNumber,
  cellStatus,
  cellYear,
  cultivarCellsStyle,
  headerCellStyle,
} from "../style";
import { cellButtons } from "@/apps/Table/style";
/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const columns: IColumn<TPlanningHpKeys>[] = [
  {
    label: PLANNING_HP_HEADER_LABELS.order__number,
    key: PLANNING_HP_KEYS.order__number,
    contentHeader: PLANNING_HP_HEADER_LABELS.order__number,
    bodyCellProps: {
      render: NumberCell,
      styles: cellNumber,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: PLANNING_HP_HEADER_LABELS.order__order_date,
    key: PLANNING_HP_KEYS.order__order_date,
    contentHeader:
      PLANNING_HP_HEADER_LABELS.order__order_date,
    bodyCellProps: {
      render: YearCell,
      styles: cellYear,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: PLANNING_HP_HEADER_LABELS.cultivar,
    key: PLANNING_HP_KEYS.cultivar,
    contentHeader: PLANNING_HP_HEADER_LABELS.cultivar,
    bodyCellProps: {
      render: CultivarCell,
      styles: cultivarCellsStyle,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },

  {
    label: PLANNING_HP_HEADER_LABELS.status,
    key: PLANNING_HP_KEYS.status,
    contentHeader: PLANNING_HP_HEADER_LABELS.status,
    bodyCellProps: {
      render: StatusCell,
      styles: cellStatus,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: PLANNING_HP_KEYS.editing,
    label: "",
    contentHeader: "",
    bodyCellProps: {
      render: EditingCell,
      styles: cellButtons,
    },
  },
];
