import { IColumn } from "@/apps/Table/interfaces";
import { cellButtons } from "@/apps/Table/style";

import {
  NumberCells,
  CultivarCells,
  StatusCells,
  EditingCells,
  DateCells,
} from "../components/CellComponents";
import {
  cellNumber,
  cellText,
  headerCellStyle,
  cellStatus,
} from "../style";

import {
  CULTIVAR_HEADER_LABELS,
  CULTIVAR_KEYS,
} from "../const";

import { TCultivarKeys } from "../interfaces";

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const columns: IColumn<TCultivarKeys>[] = [
  {
    label: CULTIVAR_HEADER_LABELS.order,
    key: CULTIVAR_KEYS.order,
    contentHeader: CULTIVAR_HEADER_LABELS.order,
    bodyCellProps: {
      render: NumberCells,
      styles: cellNumber,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: CULTIVAR_HEADER_LABELS.order__order_date,
    key: CULTIVAR_KEYS.order__order_date,
    contentHeader: CULTIVAR_HEADER_LABELS.order__order_date,
    bodyCellProps: {
      render: DateCells,
      styles: cellNumber,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: CULTIVAR_HEADER_LABELS.cultivar,
    key: CULTIVAR_KEYS.cultivarName,
    contentHeader: CULTIVAR_HEADER_LABELS.cultivar,
    bodyCellProps: {
      render: CultivarCells,
      styles: cellText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: CULTIVAR_HEADER_LABELS.status,
    key: CULTIVAR_KEYS.status,
    contentHeader: CULTIVAR_HEADER_LABELS.status,
    bodyCellProps: {
      render: StatusCells,
      styles: cellStatus,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: CULTIVAR_KEYS.editing,
    label: "",
    contentHeader: "",
    bodyCellProps: {
      render: EditingCells,
      styles: cellButtons,
    },
  },
];
