import React from "react";
import { useNavigate, useLocation } from "react-router";
import { observer } from "mobx-react";
import { Box, IconButton, Stack } from "@mui/material";
import NavigateBeforeIcon
  from "@mui/icons-material/NavigateBefore";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import StatementStatus from "@/components/StatementStatus";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { STATEMENT_TYPES } from "@/pages/Statement/const";
import { useStores } from "@/hooks";
import {
  TStatementStatus,
  TStatementType
} from "@/api/types";
import { LOCATION_KEY } from "@/const";

/** Шапка для страницы добавления заявки */
interface IProps {
  type?: TStatementType;
  title?: string;
  status?: TStatementStatus;
  id?: number;
}

/**
 * хедер для страницы добавления заявки
 * @returns React.FC
 */
const HeaderAddStatement: React.FC = () => {
  const theme = useTheme();
  const location = useLocation()
  const navigate = useNavigate()
  const { headerStore } = useStores();
  const {
    id,
    title = "Добавить заявку",
    status,
    type
  }: IProps = headerStore.getProps();

  const handleBack = () => {
    if (location.key !== LOCATION_KEY.DEFAULT) {
      navigate(-1);
    } else
      navigate({
        pathname: ROUTES_PATHS.statements
      });
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <IconButton onClick={handleBack}>
        <NavigateBeforeIcon/>
      </IconButton>
      <Box>
        <Typography fontSize={24} fontWeight={500}
                    variant="h3" noWrap>
          {title}
        </Typography>
        <Stack direction="row" spacing={1}
               alignItems="center">
          {type && (
            <Typography
              variant="subtitle1"
              fontSize={14}
              color={theme.palette.blackAndWhite.gray}
              noWrap
            >
              {`${STATEMENT_TYPES[type]} - ${id}`}
            </Typography>
          )}
          {status &&
            <StatementStatus size="small" status={status}/>}
        </Stack>
      </Box>
    </Box>
  );
};

export default observer(HeaderAddStatement);
