import yup from "@/utils/yup";
import {
  convertStringToDate
} from "../AddDistribution/utils";
import dayjs from "dayjs";
import { IPlanning } from "@/api/interfaces/responses";

export interface IProps {
  modalProps: {
    planning: IPlanning;
    handleSubmit?: () => void
  };
}

export type TForm = Partial<IPlanning>;

export enum FIELD_NAME {
  YEAR = "year",
  PLAN_DATE = "plan_date",
}

export const validationSchema = yup.object().shape({
  [FIELD_NAME.PLAN_DATE]: yup.date().required(),
  [FIELD_NAME.YEAR]: yup.date().required(),
});

export const mapValueToForm = (value: IPlanning) => {
  return {
    [FIELD_NAME.PLAN_DATE]: value.plan_date
      ? convertStringToDate(value.plan_date)
      : undefined,
    [FIELD_NAME.YEAR]: value.year
      ? dayjs().year(Number(value.year))
      : undefined,
  };
};

export const mapFormToValues = (
  value: Partial<IPlanning>,
) => ({
  [FIELD_NAME.PLAN_DATE]: dayjs(value.plan_date)
    .local()
    .format("YYYY-MM-DD"),
  [FIELD_NAME.YEAR]: String(
    dayjs(value.year).local().year(),
  ),
});
