import dayjs, { Dayjs } from "dayjs";

/**
 * Утилитка которая преобразует строку с годом в объект для формы
 * @param year год плана 
 * @returns
 */
export const convertStringToDate = (
  year?: string
): Dayjs | undefined => {
  const date = dayjs().year(Number(year));
  if (!date.isValid()) return undefined;

  return date;
};
