import {
  TTrialType,
  TBenefitType,
  TVarietyType,
} from "@/api/types";
import {
  BENEFIT_VARIANTS,
  VARIETY_VARIANTS,
} from "@/const";

export const getPaymentType = (
  type_trial: TTrialType | undefined,
): string => {
  if (type_trial) {
    if (type_trial === "paid") {
      return "Платные";
    } else if (type_trial === "budget") {
      return "Бюджет";
    }
  }
  return "-";
};

export const getBenefitType = (
  benefit: TBenefitType | undefined,
): string => {
  if (benefit) {
    return BENEFIT_VARIANTS[benefit];
  }
  return "-";
};

export const getVarietyType = (
  variety: TVarietyType | undefined,
): string => {
  if (variety) {
    return VARIETY_VARIANTS[variety];
  }
  return "-";
};

export const coordinateFormatter = (
  coord: number[],
  showStroke = false,
): string => {
  if(!Array.isArray(coord) || coord.length === 0) return "-"
  if (showStroke) {
    return `${coord[0].toFixed(6)}', ${coord[1].toFixed(
      6,
    )}'`;
  }
  return `${coord[0].toFixed(6)}°, ${coord[1].toFixed(6)}°`;
};
