import yup from "@/utils/yup";
import dayjs from "dayjs";
import { IEntity } from "@/api/interfaces/responses";
import { ERROR_MESSAGES } from "@/const";

export const FIELDS_NAME = {
  PLAN_DATE: "plan_date", // Дата плана
  YEAR: "year", // год испытания
  CULTIVARS: "cultivars", // культуры
  REGION: "region", //  регион
  BRANCHES: "branches" // филиалы
} as const;

export const defaultValues = {
  [FIELDS_NAME.PLAN_DATE]: dayjs(),
  [FIELDS_NAME.YEAR]: "",
  [FIELDS_NAME.CULTIVARS]: "",
  [FIELDS_NAME.BRANCHES]: null,
};

export interface IFormik {
  [FIELDS_NAME.PLAN_DATE]: string | null,
  [FIELDS_NAME.YEAR]: number | null,
  [FIELDS_NAME.CULTIVARS]: IEntity[] | null,
  [FIELDS_NAME.BRANCHES]: IEntity[] | null,
}

export interface IFormikLAB {
  [FIELDS_NAME.PLAN_DATE]: string | null,
  [FIELDS_NAME.YEAR]: number | null,
  [FIELDS_NAME.CULTIVARS]: IEntity | null,
  [FIELDS_NAME.BRANCHES]: IEntity | null,
}

export const formToValue = (values: IFormik) => ({
  [FIELDS_NAME.PLAN_DATE]: dayjs(values.plan_date).local().format('YYYY-MM-DD'),
  [FIELDS_NAME.YEAR]: dayjs(values.year).local().year(),
  [FIELDS_NAME.CULTIVARS]: values.cultivars?.map((cultivar) => cultivar.id),
  [FIELDS_NAME.BRANCHES]: values.branches?.map((branch) => branch.id),
});

export const formToValueLAB = (values: IFormikLAB) => ({
  [FIELDS_NAME.PLAN_DATE]: dayjs(values.plan_date).local().format('YYYY-MM-DD'),
  [FIELDS_NAME.YEAR]: dayjs(values.year).local().year(),
  cultivar: values.cultivars?.id,
  branch: values.branches?.id,
});

export const validationSchemaAddPlan = yup.object().shape({
  [FIELDS_NAME.PLAN_DATE]: yup.date().required(ERROR_MESSAGES.REQUIRED),
  [FIELDS_NAME.YEAR]: yup.mixed().required(ERROR_MESSAGES.REQUIRED),
  [FIELDS_NAME.CULTIVARS]: yup.mixed().required(ERROR_MESSAGES.REQUIRED),
  [FIELDS_NAME.BRANCHES]: yup.mixed().required(ERROR_MESSAGES.REQUIRED),
});

export const validationSchemaAddPlanWithoutBranch = yup.object().shape({
  [FIELDS_NAME.PLAN_DATE]: yup.date().required(ERROR_MESSAGES.REQUIRED),
  [FIELDS_NAME.YEAR]: yup.mixed().required(ERROR_MESSAGES.REQUIRED),
  [FIELDS_NAME.CULTIVARS]: yup.mixed().required(ERROR_MESSAGES.REQUIRED),
});
