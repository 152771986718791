import { VIEW_MOD } from "@/apps/Map/const";
import Loader from "@/components/Loader";
import { useStores } from "@/hooks";
import { Box, CircularProgress } from "@mui/material";
import React, { Suspense, useEffect } from "react";
import { asyncComponent } from "react-async-component";
import MapLayout from "@/components/MapLayout";
import { SEO_TITLES } from "@/const";

const AsyncMap = asyncComponent({
  resolve: () => import("@/apps/Map"),
  LoadingComponent: () => <CircularProgress />
});

const Map = () => {
  const { mapStore } = useStores();

  useEffect(() => {
    mapStore.setViewMod(VIEW_MOD.GSU_LIST);
  }, []);

  return (
    <MapLayout title={SEO_TITLES.MAP}>
      <div>
        <Suspense
          fallback={
            <Box height={"100vh"}>
              <Loader/>
            </Box>
          }
        >
          <AsyncMap/>
        </Suspense>
      </div>
    </MapLayout>
  );
};

export default Map;
