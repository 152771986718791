import { SxProps, Theme } from "@mui/material";

// стили для компонентов пагинации

export const buttonStyles = (theme: Theme): SxProps => ({
  minWidth: "32px",
  minHeight: "32px",
  color: theme.palette.text.primary,
  backgroundColor: "transparent",

  "& .MuiInputAdornment-root": {
    margin:'0',

  },
  "& :hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    borderRadius: "50%",
  },
  
  "& svg": {
  padding:'2px',
  },
});
