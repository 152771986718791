import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Button
} from "@mui/material";
import FileDownloadOutlinedIcon
  from "@mui/icons-material/FileDownloadOutlined";
import InfoOutlinedIcon
  from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/system";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { useStores } from "@/hooks";
import {
  VARIANT_MODAL,
  THEME_MODAL
} from "@/apps/Modals/const";

interface IProps {
  handleClose: () => void;
}

const VisuallyHiddenInput = styled("input")({
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UPLOAD_STATEMENT_KEY = "gsortStatement";

const DownloadStatement: React.FC<IProps> = ({ handleClose }) => {
  const { toastStore, modalStore, api } = useStores();
  const [isLoading, setIsLoading] = useState(false);

  const setItem = (id: number) => {
    localStorage.setItem(UPLOAD_STATEMENT_KEY, JSON.stringify(id));
  };
  const getItem = () => {
    const item = localStorage.getItem(UPLOAD_STATEMENT_KEY);
    return item && JSON.parse(item);
  };

  const showToast = (severity: string, message: string) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: message,
        severity: severity,
      },
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setIsLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      api.excelUploadTask.postExcelUploadTask(formData).then((data) => {
        setItem(data.id);
        handleStatus();
      }).catch(error => {
        showToast("error", errorToString(error));
        handleClose();
      }).finally(() => {
        setIsLoading(false);
      });

    }
  };

  const handleStatus = () => {
    const id = getItem();
    if (id) {
      setIsLoading(true);
      api.excelUploadTask.getExcelUploadTaskById(id).then((data) => {
        handleClose();
        modalStore.open(VARIANT_MODAL.DOWNLOAD_STATEMENT_STATUS, {
          data,
          theme: THEME_MODAL.W_555
        });
      }).catch((error) => {
        showToast("error", errorToString(error));
      }).finally(() => {
        setIsLoading(false)
      });
    } else {
      showToast("warning", "Не найден id загружаемого файла");
      handleClose();
    }
  };

  return (
    <Box>
      <Box p={3}>
        <Typography variant="h2">
          Загрузка заявок
        </Typography>
      </Box>

      <Divider/>
      <Box
        p={3}
        display="flex"
        gap={2}
      >
        <Button
          component="label"
          color="blue"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={<FileDownloadOutlinedIcon/>}
          disabled={isLoading}
        >
          Загрузить
          <VisuallyHiddenInput type="file"
                               onChange={handleChange}
                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
        </Button>
        <Button
          color="blue"
          variant="outlined"
          startIcon={<InfoOutlinedIcon/>}
          onClick={handleStatus}
          disabled={isLoading}
        >
          Статус загрузки
        </Button>
      </Box>
    </Box>
  );
};

export default DownloadStatement;