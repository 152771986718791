import React from "react";
import { FormikConfig, FormikValues } from "formik";

export interface IFormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

/**
 * Компонент обертка шага формы
 * @param label - заголовок шага
 * @param validationSchema - схема для валидации шага
 * */
export const FormikStep = ({ children }: IFormikStepProps)  => {
  return <>{children}</>;
}