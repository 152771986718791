import { CSSObject } from "@emotion/react";
import { Tabs, tabsClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTabs = styled(Tabs)(() => ({
  height: "100%",
  [`& .${tabsClasses.flexContainer}`]: {
    height: "100%"
  }
}));

export const tabStyles = (): CSSObject => ({
  height: "100%",
  fontSize: "20px",
  fontWeight: 500,
  textTransform: 'none'
});
  
