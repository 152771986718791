import React, { useState } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import { DeleteIcon, EditIcon } from "@/components/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { StyledMenuItem } from "@/components/StyledMenuItem";
import { useStores } from "@/hooks";
import {
  VARIANT_MODAL,
  THEME_MODAL,
} from "@/apps/Modals/const";
import { SWR_KEYS } from "@/const";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { IOrderPlot } from "@/api/interfaces/responses";

interface TProps {
  item: IOrderPlot;
}

const MoreMenu: React.FC<TProps> = ({
  item,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] =
    useState<null | HTMLElement>(null);
  const { api, modalStore, swrStore } = useStores();
  const id = item?.order_cultivar?.id ?? "";
  const mutatePlot =
    swrStore.mutators[SWR_KEYS.getOrderPlotList(id)];
  const mutateType =
    swrStore.mutators[
      SWR_KEYS.getOrderCultivarUsageType(id)
    ];
  const mutateGroups =
    swrStore.mutators[SWR_KEYS.getOrderCultivarGroups(id)];

  const triggerMutates = () => {
    mutatePlot && mutatePlot();
    mutateType && mutateType();
    mutateGroups && mutateGroups();
  };

  const onSubmit = () => {
    triggerMutates();
  };

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (event !== null) setIsOpen((prev) => !prev);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  const handleDelete = (resolve, reject) => {
    api.regulatoryInfo
      .deleteOrderPlot(item.id)
      .then(() => {
        triggerMutates();
        resolve();
      })
      .catch(reject)
      .finally(() => onSubmit());
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, {
      id: item.id,
      type: CELL_TYPES.plot,
      handleDelete: handleDelete,
    });
  };

  const handleDeleteClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    handleClose(e);
    handleClickDelete(e);
  };

  const handleEdit = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    handleClose(e);

    modalStore.open(VARIANT_MODAL.ADD_ORDER_PLOT, {
      title: "Редактировать ГСУ",
      orderCultivar: item.order_cultivar,
      theme: THEME_MODAL.W_555,
      initialValues: item,
      onSubmit,
    });
  };

  return (
    <Box>
      <IconButton onClick={handleToggle}>
        <MoreVertIcon sx={{ fontSize: 20 }} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleEdit}>
          <EditIcon />
          <Typography>Редактировать</Typography>
        </StyledMenuItem>

        <Divider />

        <StyledMenuItem onClick={handleDeleteClick}>
          <DeleteIcon color="error" />

          <Typography color="error"> Удалить</Typography>
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};

export default observer(MoreMenu);
