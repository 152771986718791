import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { FIELDS_SET, ISO_RUSSIAN } from "./utils";
import { IAddress } from "./utils/types";
import { AddressForm } from "./components/AddressForm";
import { transformNullToUndefined } from "@/utils/objects";

const Address: React.FC<IAddress> = ({
  handleClose,
  handlePop,
  modalProps,
}) => {
  const { api, swrStore } = useStores();

  const { initialValues: defaultValues, isChange } =
    modalProps;

  const initialValues = Object.assign(
    {
      [FIELDS_SET.country]: null,
      [FIELDS_SET.district]: null,
      [FIELDS_SET.comment]: null,
      [FIELDS_SET.one_line_address]: null,
      [FIELDS_SET.locality_name]: null,
      [FIELDS_SET.area_name]: null,
      [FIELDS_SET.street_name]: null,
      [FIELDS_SET.index]: null,
      [FIELDS_SET.house]: null,
      [FIELDS_SET.building]: null,
      [FIELDS_SET.housing]: null,
      [FIELDS_SET.flat]: null,
      [FIELDS_SET.entrance]: null,
      [FIELDS_SET.post_office_box]: null,
    },
    defaultValues,
  );

  const mutateDetail = isChange
    ? swrStore.mutators[
        SWR_KEYS.getAddressById(defaultValues.id)
      ]
    : null;

  const mutateAddressList =
    swrStore.mutators[SWR_KEYS.getAddress()];

  const handleCreate = (data, actions) => {
    const dataWithoutNulls =
      transformNullToUndefined<IAddress>(data);

    if (isChange) {
      api.regulatoryInfo
        .updateAddress(
          defaultValues.id,
          dataWithoutNulls as any,
        )
        .then((value) => {
          modalProps.handleAccept &&
            modalProps.handleAccept(value);
          handlePop();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          actions.setSubmitting(false);
          mutateDetail && mutateDetail();
          mutateAddressList && mutateAddressList();
        });
    } else {
      api.regulatoryInfo
        .postAddress(dataWithoutNulls as any)
        .then((value) => {
          modalProps.handleAccept &&
            modalProps.handleAccept(value);
          handlePop();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          mutateAddressList && mutateAddressList();
          actions.setSubmitting(false);
        });
    }
  };

  const handleSubmit = (values, actions) => {
    if (values.country.iso === ISO_RUSSIAN) {
      const data = {
        ...values,
        country: values[FIELDS_SET.country].id,
        district: values[FIELDS_SET.district].id,
        eng_name: values[FIELDS_SET.country].eng_name,
        full_name: values[FIELDS_SET.country].full_name,
        iso: values[FIELDS_SET.country].iso,
        name: values[FIELDS_SET.country].name,
        short_name: values[FIELDS_SET.country].short_name,
      };

      handleCreate(data, actions);
    } else {
      const data = {
        country: values[FIELDS_SET.country].id,
        comment: values[FIELDS_SET.comment],
        foreign_one_line_address:
          values[FIELDS_SET.one_line_address],
      };

      handleCreate(data, actions);
    }
  };

  return (
    <Box>
      <Box p={3}>
        <Typography>Адреса</Typography>
        <Typography
          variant="h2"
          sx={{ paddingRight: "30px" }}
        >
          {isChange
            ? "Редактировать запись"
            : "Добавить запись"}
        </Typography>
      </Box>
      <Divider />
      <AddressForm
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        initialValues={initialValues}
        modalProps={modalProps}
      />
    </Box>
  );
};

export default Address;
