import React from "react";
import { Field, Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";
import { IProps, TForm, COUNTRY_FIELD_NAME } from "../../const";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";

/**
 * Форма создания/редактирования культуры
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const CountryForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore } = useStores();
  const form = useFormikContext<TForm>();

  const title = modalProps.postId
    ? "Редактировать запись"
    : "Добавить запись";

  return (
    <>
      <Box p={3}>
        <Typography>Должности</Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <Field name={COUNTRY_FIELD_NAME.NAME}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Название'
                  fullWidth
                  size='small'
                  required
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[COUNTRY_FIELD_NAME.NAME]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={COUNTRY_FIELD_NAME.FULL_NAME}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Полное название'
                  fullWidth
                  size='small'
                  required
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[COUNTRY_FIELD_NAME.FULL_NAME]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={COUNTRY_FIELD_NAME.DATIVE_NAME}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Название в дательном падеже'
                  fullWidth
                  size='small'
                  required
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[COUNTRY_FIELD_NAME.DATIVE_NAME]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>

        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red' onClick={() => modalStore.close()}>
            отмена
          </Button>
          <Button
            type='submit'
            disabled={form.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </>
  );
};

export default CountryForm;
