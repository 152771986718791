import React from "react"
import { Button, FormControl, FormHelperText, FormLabel, Stack, Tooltip, styled } from "@mui/material"
import { Field, FormikProps } from "formik"
import { DatePicker } from "@mui/x-date-pickers-pro"
import CheckIcon from "@mui/icons-material/Check";
import { StyledChip } from "@/apps/Modals/ModalContent/CreateVariety/styles"
import AddChip from "@/components/Chips/AddChip"
import { useStores } from "@/hooks"
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions"
import { FIELDS_NAME, IFormik } from "../../const"
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { ICultivar, IRegionItem } from "@/api/interfaces/responses";
import theme from "@/theme";

interface IProps {
  formikProps: FormikProps<IFormik>
  showFieldBranhes?: boolean
}

const FormAddPlanLAB = ({ formikProps, showFieldBranhes }: IProps) => {

  const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    color: theme.palette.blackAndWhite.black,
    fontWeight: 500
  }));

  const { userStore, modalStore } = useStores();

  const isCulture = userStore.isPermission(
    PERMISSIONS.regulatory_infoСultivar,
    PERMISSION_CRUD.view
  );

  const handleSelectCultivars = (value: ICultivar) => {
    modalStore.close()
    formikProps.setFieldValue(FIELDS_NAME.CULTIVARS, value);
  };

  const handleAddCultivars = () => {
    modalStore.open(VARIANT_MODAL.SELECT_CULTIVAR,
      {
        initialValue: formikProps.values.cultivars ?? [],
        onSubmit: handleSelectCultivars,
        saveButtonLabel: "Добавить",
      }
    )
  };

  const handleSelectBranches = (value: IRegionItem) => {
    modalStore.close()
    formikProps.setFieldValue(FIELDS_NAME.BRANCHES, value);
  };

  const handleAddBranches = () => {
    modalStore.open(VARIANT_MODAL.SELECT_BRANCH,
      {
        initialValue: formikProps.values.branches ?? [],
        onSubmit: handleSelectBranches,
        saveButtonLabel: "Добавить",
      }
    )
  }

  const handleDeleteChipBranch = () => {
    formikProps.setFieldValue(FIELDS_NAME.BRANCHES, null);
  };

  const handleChangeDate = (value: Date) => {
    formikProps.setFieldValue(FIELDS_NAME.PLAN_DATE, value);
  };

  const handleChangeYear = (value: Record<string, any>) => {
    formikProps.setFieldValue(FIELDS_NAME.YEAR, value);
  };

  const handleDeleteChip = () => {
    formikProps.setFieldValue(FIELDS_NAME.CULTIVARS, null);
  };

  return (
    <>
      <Stack
        direction={"row"}
        gap={3}
        justifyContent={"flex-start"}
      >
        <Field name={FIELDS_NAME.PLAN_DATE}>
          {({ field, form, meta }) => (
            <DatePicker
              {...field}
              label="Дата утверждения плана"
              slotProps={{
                textField: {
                  size: "small",
                  required: true,
                  error: meta.touched && !!meta.error,
                  helperText: meta.error,
                  onBlur: field.onBlur,
                }
              }}
              onChange={handleChangeDate}
              onBlur={() => form.setFieldTouched(FIELDS_NAME.YEAR)}
            />
          )}
        </Field>

        <Field
          name={FIELDS_NAME.YEAR}>
          {({ field, meta }) => (
            <DatePicker
              name={field.name}
              value={field.value}
              onChange={handleChangeYear}
              label="Год испытаний"
              openTo="year"
              views={["year"]}
              slotProps={{
                textField: {
                  size: "small",
                  required: true,
                  error: meta.touched && !!meta.error,
                  helperText: meta.error,
                  onBlur: field.onBlur,
                }
              }}
            />
          )}
        </Field>
      </Stack>

      <Stack
        gap={2}
        mt={"20px"}
        mb={"20px"}
      >
        <Field name={FIELDS_NAME.CULTIVARS}>
          {({ field, meta }) => {
            return (
              <FormControl required>
                <StyledFormLabel
                  sx={{ color: theme.palette.blackAndWhite.gray }}
                >
                  Культура
                </StyledFormLabel>
                <Tooltip
                  followCursor
                  title={
                    !isCulture
                      ? "У вас недостаточно прав для просмотра списка культур"
                      : ""
                  }
                >
                  <Stack
                    direction={"row"}
                    flexWrap={"wrap"}
                    alignItems={"center"}
                    gap={1}
                  >
                    {
                      field.value ?
                        <StyledChip
                          key={field.value.id}
                          size="small"
                          variant="outlined"
                          label={field.value.name}
                          onDelete={() => handleDeleteChip()}
                        /> :
                        <AddChip
                          disabled={!isCulture}
                          onClick={handleAddCultivars}
                        />
                    }
                  </Stack>
                </Tooltip>
                <FormHelperText error={!!meta.error}>
                  {meta.error}
                </FormHelperText>
              </FormControl>
            )
          }}
        </Field>

        {showFieldBranhes ?
          <Field name={FIELDS_NAME.BRANCHES}>
            {({ field, meta }) => {
              return (
                <FormControl required>
                  <StyledFormLabel
                    sx={{ color: theme.palette.blackAndWhite.gray }}
                  >
                    Филиал
                  </StyledFormLabel>
                  <Tooltip
                    followCursor
                    title={
                      !isCulture
                        ? "У вас недостаточно прав для просмотра списка культур"
                        : ""
                    }
                  >
                    <Stack
                      direction={"row"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                      gap={1}
                    >
                      {field.value ?
                        <StyledChip
                          key={field.value.id}
                          size="small"
                          variant="outlined"
                          label={field.value.name}
                          onDelete={() => handleDeleteChipBranch()}
                        /> :
                        <AddChip
                          disabled={!isCulture}
                          onClick={handleAddBranches}
                        />
                      }
                    </Stack>
                  </Tooltip>
                  <FormHelperText error={!!meta.error}>
                    {meta.error}
                  </FormHelperText>
                </FormControl>
              )
            }}
          </Field> : null}
      </Stack>
      <Button
        startIcon={<CheckIcon />}
        disabled={formikProps.isSubmitting}
        variant="contained"
        type="submit"
      >
        Готово
      </Button>
    </>
  )
}

export default FormAddPlanLAB
