import {
  Box,
  Typography,
  Divider,
  Button,
  Chip,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { useStores } from "@/hooks";
import {
  IUniqueRemark,
  IUser,
  IUserShort,
} from "@/api/interfaces/responses";
import theme from "@/theme";
import { REMARK_STATUS, REMARK_TYPE } from "./const";
import useToastContent, {
  TOAST_CONTENT_TYPE,
} from "@/hooks/useToastContent";
import { SWR_KEYS } from "@/const";
import {
  STATEMENT_STATUS_NAMES
} from "@/pages/Statement/const";
import { observer } from "mobx-react";
import {
  PERMISSIONS,
  PERMISSION_CRUD,
} from "@/premissions";

interface IProps {
  handleClose: () => void;
  modalProps: {
    title: string;
    titleUser: string;
    titleRemark: string;
    statementId: number;
    statementCreator?: IUserShort | null;
    initialValue: IUniqueRemark[];
    prevExecutorId?: number;
  };
}

const ReworkModal = ({
  modalProps,
  handleClose,
}: IProps) => {
  const {
    title,
    titleRemark,
    initialValue = [],
    statementCreator = null,
    statementId,
    prevExecutorId,
  } = modalProps;

  const {
    modalStore,
    api,
    toastStore,
    queryStringSidebarCollector,
    userStore,
    swrStore,
  } = useStores();
  const toastContent = useToastContent(
    TOAST_CONTENT_TYPE.SERVER_ERROR,
  );
  const [remarks, setRemarks] = useState(initialValue);
  const statementIdQuery =
    queryStringSidebarCollector.getCurrentModuleId();
  const [isDisabledAddRemark, setisDisabledAddRemark] =
    useState(false);
  const [
    isCompletedUniqueRemarks,
    setIsCompletedUniqueRemarks,
  ] = useState(false);
  const [hasNotSolovedRemark, sethasNotSolvedRemark] =
    useState(false);

  const isViewRemark = userStore.isPermission(
    PERMISSIONS.registrationRemark,
    PERMISSION_CRUD.view,
  );

  const isAddRemark = userStore.isPermission(
    PERMISSIONS.registrationRemark,
    PERMISSION_CRUD.add,
  );

  const isViewEmployee = userStore.isPermission(
    PERMISSIONS.regulatory_infoEmployee,
    PERMISSION_CRUD.view,
  );

  const hotCheckLengthRemarkList = async () => {
    const data = await api.statement.getUniqueRemarks({
      remark_type: REMARK_TYPE.REVISION,
      statement_id: statementId,
    });
    return data.count;
  };

  const checkHasNotSolvedRemark = async () => {
    const data =
      await api.statement.getRemarksByStatementId({
        statement: statementIdQuery,
        status: REMARK_STATUS.not_solved,
      });
    return data.results.length > 0;
  };

  const handleAddRemark = () => {
    modalStore.close();
    const handleAccept = (values: IUniqueRemark[]) => {
      modalStore.close();
      modalStore.open(VARIANT_MODAL.REWORK, {
        initialValue: values,
        statementCreator: statementCreator,
        title: title,
        key: "GET_REMARKS_REVISION",
      });
    };

    modalStore.open(VARIANT_MODAL.ADD_REMARKS, {
      id: statementIdQuery,
      remarkType: REMARK_TYPE.REVISION,
      handleAccept,
      initialValue: remarks,
      statementCreator,
      title: titleRemark,
      key: SWR_KEYS.getRevisionRemarksByStatementIdKey(
        Number(statementId),
      ),
      fromAdd: true,
    });
  };

  const handleChangeUser = () => {
    modalStore.close();
    const handleAccept = (value: IUser): any => {
      modalStore.close();
      modalStore.open(VARIANT_MODAL.REWORK, {
        statementCreator: value,
        initialValue: remarks,
        title: title,
        prevExecutorId: statementCreator?.id,
      });
    };
    modalStore.open(VARIANT_MODAL.SELECT_USER_SIGN, {
      statementCreator: statementCreator,
      title: "Выберите регистратора",
      handleAccept,
    });
  };

  const handleClearRemark = (id: number) => {
    setRemarks((prev) =>
      prev.filter((item) => item.id !== id),
    );
  };

  const changeStatusToRefinement = () => {
    const statement = statementIdQuery;
    if (statement)
      api.statement
        .patchStatementById(statement, {
          status: STATEMENT_STATUS_NAMES.refinement,
        })
        .then(() => {
          modalStore.close();
          modalStore.setTheme(THEME_MODAL.W_555);
          modalStore.open(
            VARIANT_MODAL.SUCCESS_REFINEMENT_STATEMENT,
            {},
          );
        })
        .catch(() => {
          toastStore.createToast(toastContent);
        })
        .finally(() => {
          swrStore.mutators[SWR_KEYS.getStatements()]();
          swrStore.mutators[
            SWR_KEYS.getStatementByIdKey(statement)
          ]();
          try {
            swrStore.mutators[
              SWR_KEYS.getRemarksByStatementIdKey(statement)
            ]();
          } catch {
            console.log("не обнаружен мутатор для ремарок");
          }
        });
  };

  const handleSubmit = () => {
    const remarksIds = remarks.map((obj) => obj.id);
    const statement = statementIdQuery
      ? Number(statementIdQuery)
      : null;

    if (!statement) {
      return;
    }

    modalStore.close();

    if (remarksIds.length) {
      api.statement
        .postRemarksByStatementId(
          remarksIds,
          statement,
          REMARK_STATUS.not_solved,
        )
        .then(() => {
          changeStatusToRefinement();
        })
        .catch(() => {
          toastStore.createToast(toastContent);
        });
      return;
    }

    if (statementCreator?.id !== prevExecutorId) {
      api.statement
        .patchStatementById(statement, {
          executor: statementCreator?.id,
        })
        .then(() => {
          changeStatusToRefinement();
        })
        .catch(() => {
          toastStore.createToast(toastContent);
        });
      return;
    }

    changeStatusToRefinement();
  };

  useEffect(() => {
    hotCheckLengthRemarkList().then((count) => {
      setisDisabledAddRemark(count === 0);
      setIsCompletedUniqueRemarks(true);
    });
    checkHasNotSolvedRemark().then((result) => {
      sethasNotSolvedRemark(result);
    });
  });

  const checkButtonDisabled = () => {
    if (!isCompletedUniqueRemarks) {
      return true;
    }
    if (hasNotSolovedRemark) {
      return false;
    }
    if (remarks.length && !isDisabledAddRemark) {
      return false;
    }
    return true;
  };

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {title}
        </Typography>
      </Box>
      <Divider />
      <Box p={3}>
        <Typography fontWeight={500}>
          Выберите регистратора, которому отправите заявку
          на доработку
        </Typography>
        <Chip
          label={
            statementCreator
              ? statementCreator.full_name
              : ""
          }
          size="small"
          variant="outlined"
          onDelete={
            isViewEmployee ? handleChangeUser : undefined
          }
        />
        <Typography mt={"30px"} fontWeight={500}>
          Добавьте замечания по заявке:
        </Typography>
        {Boolean(remarks.length) && (
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            gap={"5px"}
            mb={"5px"}
          >
            {remarks.map((item) => (
              <Chip
                key={item.id}
                label={item.name}
                size="small"
                variant="outlined"
                onDelete={() => handleClearRemark(item.id)}
              />
            ))}
          </Stack>
        )}
        <Tooltip
          title={
            !isViewRemark || !isAddRemark
              ? "У вас недостаточно прав"
              : ""
          }
          followCursor
        >
          <Box>
            <Button
              sx={{
                color: theme.palette.blackAndWhite.white,
                borderRadius: "50px",
                minWidth: 0,
                padding: "5px",
                "& .MuiButton-startIcon": {
                  m: 0,
                },
              }}
              disabled={
                isDisabledAddRemark ||
                !isViewRemark ||
                !isAddRemark
              }
              startIcon={<AddOutlinedIcon />}
              variant={"contained"}
              onClick={handleAddRemark}
            />
          </Box>
        </Tooltip>
      </Box>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          disabled={checkButtonDisabled()}
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          onClick={handleSubmit}
        >
          Отправить
        </Button>
      </Box>
    </Box>
  );
};

export default observer(ReworkModal);
