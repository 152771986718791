import { SWR_KEYS } from "@/const";
import { useInfiniteScroll, useStores } from "@/hooks";
import {
  FormControl
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import React, { useState } from "react";
import { EMPLOYEE_FIELD_NAME, TForm } from "../../../const";
import Autocomplete from "@/components/Autocomplete";
import { CustomPaper } from "../first";

export const SubdivisionDepartment: React.FC = () => {
  const form = useFormikContext<TForm>();
  const { api } = useStores();
  const [deptSearch, setDeptSearch] = useState("");
  const swrKeyDep = SWR_KEYS.getDepartmentList();


  const getKeyDept = (index: number) => ({
    _key: swrKeyDep,
    page: index + 1,
    search: deptSearch,
  });

  const fetcherDept = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getDepartmentList({
      page,
      search,
    });
  };

  const { data, handleScroll } = useInfiniteScroll(
    getKeyDept,
    fetcherDept,
    {
      revalidateFirstPage: false,
    },
  );

  /**
   * Метод для изменения должности
   * @param event
   */
  const handleChange = (event, value) => {
      form.setFieldValue(EMPLOYEE_FIELD_NAME.DEPARTMENT, value)
  };


  return (
    <Field name={EMPLOYEE_FIELD_NAME.DEPARTMENT}>
      {({ field, meta }) => (
        <FormControl size='small' variant='outlined' fullWidth>
          <Autocomplete
            {...field}
            PaperComponent={CustomPaper}
            label='Отдел'
            onChange={handleChange}
            data={data}
            handleScroll={handleScroll}
            search={setDeptSearch}
            error={meta.touched && !!meta.error}
          />
        </FormControl>
      )}
    </Field>
  );
};

