import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtonsMaterialType, cellNumberRegions, cellPointText, headerCellStyle } from "../style";

import { MATERIAL_TYPE_LABELS, REGIONS_TYPE_KEYS, REGIONS_TYPE_LABELS } from "../const";

import { TRegionsTypeKeys } from "../interfaces";
import { EditingCellsRegions, NameCells } from "../templates/Regions/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const RegionsTypesColumns: IColumn<TRegionsTypeKeys>[] = [
  {
    label: REGIONS_TYPE_LABELS.number,
    key: REGIONS_TYPE_KEYS.number,
    contentHeader: REGIONS_TYPE_LABELS.number,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: cellNumberRegions,
    },
  },
  {
    label: REGIONS_TYPE_LABELS.name,
    key: REGIONS_TYPE_KEYS.name,
    contentHeader: REGIONS_TYPE_LABELS.name,
    bodyCellProps: {
      render: NameCells,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: MATERIAL_TYPE_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsRegions,
      styles: cellButtonsMaterialType,
    },
  },
];
