import React, { useState } from "react";
import { useSWRConfig } from "swr";

import { AddEntity } from "@/apps/Modals/ModalContent";

import { useStores } from "@/hooks";
import useToastContent, { TOAST_CONTENT_TYPE } from "@/hooks/useToastContent";

import { PAGE_SIZE } from "@/const";

import { resInterface } from "@/api/interfaces";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    idStatement: number;
  };
}

const AddDuties: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const { idStatement } = modalProps;
  const { mutate } = useSWRConfig();
  const toastContent = useToastContent(TOAST_CONTENT_TYPE.SERVER_ERROR);
  const { api, toastStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const handleAccept = (value: resInterface.IDuty) => {
    setIsLoading(true);
    api.statement
      .addStatementDuties({ statement: idStatement, duty: value.id })
      .then(() => {
        handleClose();
        mutate(() => true);
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const fetcher = (args) => {
    const { page, search } = args;
    return api.statement.getDuties({
      page,
      page_size: PAGE_SIZE,
      name: search,
    });
  };

  const getDutyLabel = (el: resInterface.IDuty) => {
    return `${el.number}.${el.name}`;
  };

  const props = {
    ...modalProps,
    key: "GET_DUTIES",
    title: "Выберите пошлину",
    handleAccept,
    disabled: isLoading,
    fetcher,
    getLabel: getDutyLabel,
  };

  return (
    <AddEntity<resInterface.IDuty>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default AddDuties;
