import { IColumn } from "@/apps/Table/interfaces";
import {
  NumberCells,
  CultivarCells,
  BranchCells,
  CreatedAtCells,
  StatusCells,
  EditingCells
} from "../components/Cells";

import {
  PLANNING_HEADER_LABELS,
  PLANNING_KEYS
} from "../const";

import { TExpertAssessmentKeys } from "../interfaces";

import { cell, bigCell, headerCellStyle, } from "../style";
import { cellButtons } from "@/apps/Table/style";

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const columns: IColumn<TExpertAssessmentKeys>[] = [

  {
    label: PLANNING_HEADER_LABELS.number,
    key: PLANNING_KEYS.number,
    contentHeader: PLANNING_HEADER_LABELS.number,
    bodyCellProps: {
      render: NumberCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: PLANNING_HEADER_LABELS.cultivar,
    key: PLANNING_KEYS.cultivarName,
    contentHeader: PLANNING_HEADER_LABELS.cultivar,
    bodyCellProps: {
      render: CultivarCells,
      styles: bigCell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: PLANNING_HEADER_LABELS.branch,
    key: PLANNING_KEYS.branchName,
    contentHeader: PLANNING_HEADER_LABELS.branch,
    bodyCellProps: {
      render: BranchCells,
      styles: bigCell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: PLANNING_HEADER_LABELS.createdAt,
    key: PLANNING_KEYS.createdAt,
    contentHeader: PLANNING_HEADER_LABELS.createdAt,
    bodyCellProps: {
      render: CreatedAtCells,
      styles: bigCell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: PLANNING_HEADER_LABELS.status,
    key: PLANNING_KEYS.status,
    contentHeader: PLANNING_HEADER_LABELS.status,
    bodyCellProps: {
      render: StatusCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    key: PLANNING_KEYS.editing,
    label: "",
    contentHeader: "",
    bodyCellProps: {
      render: EditingCells,
      styles: cellButtons,
    },
  }
];
