import { MaskedInputProps } from "react-maskedinput";

type TCommonMask = "phoneSimple" | "phoneWithBracket";

export const inputMaskHash: Record<TCommonMask, MaskedInputProps["mask"]> = {
  phoneSimple: "111 111 11 11",
  phoneWithBracket: "(111) 111-11-11"
};

export const inputUnmaskNumber = (value: string) => {
  return value.length !== 12 ? `+7${value.replace(/[^0-9]/g, "")}` : value;
};
