import Yup from "@/utils/yup";
import {
  ISeedArrivalCreate
} from "@/api/interfaces/requests";
import dayjs, { Dayjs } from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {
  ISeedArrival,
  ISeedArrivalFile,
} from "@/api/interfaces/responses";
import { STATUSES } from "@/components/FileDropzone/const";
import { MAX_NUMBERS, MIN_NUMBERS } from "@/const";

export const STEP_LABELS = {
  DELIVERY_DATA: "Данные поставки",
  INFORMATION: "Сведения",
  DOCUMENTS: "Документы"
};

export const FIELDS_NAME = {
  REGION: "region", // регион
  BRANCH: "branch", // филиал
  SENDER: "sender", // отправитель
  MATERIAL_TYPE: "material_type", // тип материала
  QUANTITY_OF_SEED: "quantity_of_seeds", // фактическое количество
  PLANNED_QUANTITY_OF_SEEDS: "planned_quantity_of_seeds", // плановое количество
  RECEIPT_AT: "receipt_at", // Фактическая дата поступления
  PLANNED_RECEIPT_AT: "planned_receipt_at", // Планируемая дата поступления
  WEIGHT: "weight", // Масса 1000 семян, г
  VARIETAL_PURITY: "varietal_purity", // Сортовая чистота, %
  ENERGY_GERMINATION: "energy_germination", // Энергия прорастания, %
  GERMINATION: "germination", // Всхожесть, %
  SEED_PURITY: "seed_purity", // Чистота семян, %
  CROP_YEAR: "crop_year", // Год урожая
  UNIT: "unit", // Единица измерения
  REPRODUCTION: "reproduction", // Репродукция
  CULTIVAR: "cultivar", // Культура
  VARIETY: "variety", // Сорт
  PLOT: "plot", // ГСУ
  FILE_LIST: "file_list", // Документы
  FILE: "file", // Документы - файл
  NAME: "name", // Документы - Название документа
  TYPE: "type", // Документы - Номер документа
  NUMBER: "number", //
  RECEIPT_DATE: "receipt_date", // Документы - Дата поступления
  HUMIDITY: "humidity", // Влажность
  SEED_CLASS: "seed_class" // Класс семян
} as const;

export const initialValues = {
  [FIELDS_NAME.PLANNED_RECEIPT_AT]: null,
  [FIELDS_NAME.REGION]: null,
  [FIELDS_NAME.BRANCH]: null,
  [FIELDS_NAME.RECEIPT_AT]: null,
  [FIELDS_NAME.SENDER]: "",
  [FIELDS_NAME.MATERIAL_TYPE]: null,
  [FIELDS_NAME.PLANNED_QUANTITY_OF_SEEDS]: "",
  [FIELDS_NAME.QUANTITY_OF_SEED]: "",
  [FIELDS_NAME.UNIT]: null,
  [FIELDS_NAME.CULTIVAR]: null,
  [FIELDS_NAME.VARIETY]: null,
  [FIELDS_NAME.CROP_YEAR]: null,
  [FIELDS_NAME.REPRODUCTION]: null,
  [FIELDS_NAME.PLOT]: null,
  [FIELDS_NAME.WEIGHT]: "",
  [FIELDS_NAME.VARIETAL_PURITY]: 0,
  [FIELDS_NAME.ENERGY_GERMINATION]: 0,
  [FIELDS_NAME.GERMINATION]: 0,
  [FIELDS_NAME.SEED_PURITY]: 0,
  [FIELDS_NAME.FILE_LIST]: [],
  [FIELDS_NAME.HUMIDITY]: 0,
  [FIELDS_NAME.SEED_CLASS]: 0
} as const;

export interface IValue {
  id: number;
  label: string;
}

export interface IFile {
  file: {
    uuid?: string,
    id: number,
    name: string,
    file_url: string,
    formatSize: string,
    preview: string,
    status: string,
  } & Partial<File>,
  type: string,
  name: string,
  number: string,
  receipt_date: string | Dayjs,
}


export interface IFormik {
  [FIELDS_NAME.PLOT]: IValue | null;
  [FIELDS_NAME.REGION]: IValue | null;
  [FIELDS_NAME.BRANCH]: IValue | null;
  [FIELDS_NAME.PLANNED_RECEIPT_AT]: Dayjs | null,
  [FIELDS_NAME.RECEIPT_AT]: Dayjs | null,
  [FIELDS_NAME.SENDER]: string,
  [FIELDS_NAME.MATERIAL_TYPE]: IValue | null,
  [FIELDS_NAME.PLANNED_QUANTITY_OF_SEEDS]: number | string,
  [FIELDS_NAME.QUANTITY_OF_SEED]: number | string,
  [FIELDS_NAME.UNIT]: IValue | null,
  [FIELDS_NAME.CULTIVAR]: IValue | null,
  [FIELDS_NAME.VARIETY]: IValue | null,
  [FIELDS_NAME.CROP_YEAR]: number | null,
  [FIELDS_NAME.REPRODUCTION]: IValue | null,
  [FIELDS_NAME.WEIGHT]: number | string,
  [FIELDS_NAME.VARIETAL_PURITY]: number | string,
  [FIELDS_NAME.ENERGY_GERMINATION]: number | string,
  [FIELDS_NAME.GERMINATION]: number | string,
  [FIELDS_NAME.SEED_PURITY]: number | string,
  [FIELDS_NAME.FILE_LIST]: IFile[]
  [FIELDS_NAME.HUMIDITY]: number | string,
  [FIELDS_NAME.SEED_CLASS]: number
}

export const deliveryDataValidationSchema = Yup.object().shape({
  [FIELDS_NAME.PLANNED_RECEIPT_AT]: Yup.date().typeError('Введите корректную дату').required(),
  [FIELDS_NAME.PLOT]: Yup.mixed().required(),
  [FIELDS_NAME.PLANNED_QUANTITY_OF_SEEDS]: Yup.number().max(MAX_NUMBERS.MAX).min(MIN_NUMBERS.MIN).required(),
  [FIELDS_NAME.QUANTITY_OF_SEED]: Yup.number().max(MAX_NUMBERS.MAX).min(MIN_NUMBERS.MIN),
  [FIELDS_NAME.SENDER]: Yup.mixed(),
  [FIELDS_NAME.MATERIAL_TYPE]: Yup.mixed().required(),
  [FIELDS_NAME.RECEIPT_AT]:Yup.date().nullable().typeError('Введите корректную дату')
});
export const informationValidationSchema = Yup.object().shape({
  [FIELDS_NAME.CROP_YEAR]: Yup.number().nullable().min(MIN_NUMBERS.MIN_DATE),
  [FIELDS_NAME.VARIETY]: Yup.mixed().required(),
  [FIELDS_NAME.WEIGHT]: Yup.number().nullable().max(MAX_NUMBERS.MAX).min(MIN_NUMBERS.MIN),
  [FIELDS_NAME.VARIETAL_PURITY]: Yup.number().max(MAX_NUMBERS.MAX_PERCENT),
  [FIELDS_NAME.ENERGY_GERMINATION]: Yup.number().max(MAX_NUMBERS.MAX_PERCENT),
  [FIELDS_NAME.GERMINATION]: Yup.number().max(MAX_NUMBERS.MAX_PERCENT),
  [FIELDS_NAME.SEED_PURITY]: Yup.number().max(MAX_NUMBERS.MAX_PERCENT),
  [FIELDS_NAME.HUMIDITY]: Yup.number().max(MAX_NUMBERS.MAX_PERCENT),
});

export const serializeFile = (file: ISeedArrivalFile) => {
  return {
    file: {
      uuid: uuidv4(),
      id: file.id,
      name: file.file_name,
      formatSize: file.file_size,
      file_url: file.file_url,
      preview: file.thumbnail_url,
      status: STATUSES.SUCCESS,
    },
    type: file.type,
    name: file.name,
    number: file.number,
    receipt_date: file.receipt_date,
  };
};

export const serializeFormToValue = (value: IFormik, id?: string ): ISeedArrivalCreate => ({
  crop_year: value.crop_year || undefined,
  cultivar: value.cultivar?.id as number,
  plot: value.plot?.id as number,
  planned_quantity_of_seeds: value.planned_quantity_of_seeds as number,
  planned_receipt_at: dayjs(value.planned_receipt_at).toISOString(),
  quantity_of_seeds: value.quantity_of_seeds as number,
  receipt_at: value.receipt_at ? dayjs(value.receipt_at).format('YYYY-MM-DD') : null,
  reproduction: value.reproduction?.id,
  region: value.region?.id as number,
  branch: value.branch?.id,
  sender: value.sender,
  unit: value.unit?.id,
  varietal_purity: id 
    ? value.varietal_purity as number || 0 
    : value.varietal_purity as number || undefined,
  energy_germination: id 
    ? value.energy_germination as number || 0 
    : value.energy_germination as number || undefined,
  germination: id 
    ? value.germination as number || 0 
    : value.germination as number || undefined,
  seed_purity: id 
    ? value.seed_purity as number || 0 
    : value.seed_purity as number || undefined,
  humidity: id 
    ? value.humidity as number || 0 
    : value.humidity as number || undefined,
  variety: value.variety?.id as number,
  weight: value.weight as number,
  seed_class: value.seed_class,
  material_type: value.material_type?.id,
});

export const serializeValueToForm = (value: ISeedArrival): IFormik => ({
  material_type: value.material_type ? {
    id: value.material_type.id,
    label: value.material_type.name
  } : null,
  crop_year: value.crop_year,
  cultivar: value.cultivar ? {
    id: value.cultivar.id,
    label: value.cultivar.name
  } : null,
  energy_germination: value.energy_germination || "",
  plot: value.plot ? {
    id: value.plot.id,
    label: value.plot.name
  } : null,
  region: value.plot?.branch?.region ? {
    id: value.plot.branch.region.id,
    label: value.plot.branch.region.name
  } : null,
  branch: value.plot?.branch ? {
    id: value.plot.branch.id,
    label: value.plot.branch.name
  } : null,
  germination: value.germination || "",
  planned_quantity_of_seeds: value.planned_quantity_of_seeds,
  planned_receipt_at: value.planned_receipt_at ? dayjs(value.planned_receipt_at): null,
  quantity_of_seeds: value.quantity_of_seeds,
  receipt_at: value.receipt_at ? dayjs(value.receipt_at): null,
  reproduction: value.reproduction ? {
    id: value.reproduction.id,
    label: value.reproduction.name
  } : null,
  sender: value.sender,
  unit: value.unit ? {
    id: value.unit.id,
    label: value.unit.name
  } : null,
  seed_purity: value.seed_purity || "",
  varietal_purity: value.varietal_purity || "",
  variety: value.variety ? {
    id: value.variety.id,
    label: value.variety.name
  } : null,
  weight: value.weight,
  file_list: value.files ? value.files.map(serializeFile) : [],
  humidity: value.humidity || "",
  seed_class: value.seed_class
})

