import React, { useEffect } from "react";
import {
  FastField,
  Form,
  useFormikContext,
} from "formik";
import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  IFormik,
  LAB_FIELD_NAME,
} from "./const";
import {
  StyledList,
} from "@/apps/EditTrial/styles";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation, useParams } from "react-router-dom";

/**
 * Компонент формы испытания на ООС
 * @сomponent
 */
const LabTrialForm = () => {
  const formik = useFormikContext<IFormik>();
  const { values } = formik;
  const { trialId } = useParams();
  const location = useLocation();

  /**
   * Метод выбора даты
   * @param value
   */
  const handleChangeDate = (value: Date) => {
    formik.setFieldValue(
      LAB_FIELD_NAME.YEAR_OF_TESTING,
      value,
    );
  };

  /**
   * Метод для изменений региона
   * @param event
   */
  const handleRegionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const id = Number(event.target.name);
    const checkedRegions =
      formik.values[LAB_FIELD_NAME.REGIONS];

    if (event.target.checked) {
      formik.setFieldValue(LAB_FIELD_NAME.REGIONS, [
        ...checkedRegions,
        id,
      ]);
    } else {
      formik.setFieldValue(
        LAB_FIELD_NAME.REGIONS,
        checkedRegions.filter((val) => val !== id),
      );
    }
  };

  /**
   * Список регионов
   */
  const renderRegionItems = values.context.regions?.map(
    (el: any) => {
      return (
        <Box key={el.id}>
          {el?.is_deleted ? null : (
            <ListItem>
              <FormControlLabel
                name={String(el.id)}
                checked={values[
                  LAB_FIELD_NAME.REGIONS
                ].includes(el.id)}
                control={<Checkbox color="secondary" />}
                label={
                  <Typography
                    fontSize={14}
                  >{`${el.number} - ${el.name}`}</Typography>
                }
              />
            </ListItem>
          )}
        </Box>
      );
    },
  );

  /**
   * Если создание испытания (не редактирование), то по умолчанию сетим все регионы в формик
   */

  useEffect(() => {
    if (!trialId) {
      const regionIds =
        values.context.regions?.map(
          (region) => region.id,
        ) || [];
      formik.setFieldValue(
        LAB_FIELD_NAME.REGIONS,
        regionIds,
      );
    }
  }, [location]);

  return (
    <Box p={3}>
      <Box sx={{ maxWidth: 762 }}>
        <Form noValidate>
          <Stack direction="column" spacing="20px">
            <Typography variant="h3">
              Данные испытания
            </Typography>
            <FastField
              name={LAB_FIELD_NAME.YEAR_OF_TESTING}
            >
              {({ field, meta }) => (
                <DatePicker
                  name={field.name}
                  value={field.value}
                  onChange={handleChangeDate}
                  label="Год испытания"
                  openTo="year"
                  views={["year"]}
                  slotProps={{
                    textField: {
                      size: "small",
                      required: true,
                      error: meta.touched && !!meta.error,
                      helperText:
                        meta.touched && meta.error,
                      onBlur: field.onBlur,
                    },
                  }}
                />
              )}
            </FastField>
            <FastField name={LAB_FIELD_NAME.PAYMENT_TYPE}>
              {({ field }) => (
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Тип оплаты
                  </FormLabel>
                  <RadioGroup {...field}>
                    <Stack direction="row">
                      <FormControlLabel
                        value="budget"
                        control={<Radio color="secondary" />}
                        label="Бюджет"
                      />
                      <FormControlLabel
                        value="paid"
                        control={<Radio color="secondary" />}
                        label="Платные"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            </FastField>
            <FastField name={LAB_FIELD_NAME.REGIONS}>
              {({ meta }) => (
                <FormControl required>
                  <FormLabel>Регионы допуска</FormLabel>
                  <FormGroup onChange={handleRegionChange}>
                    <StyledList>
                      {renderRegionItems}
                    </StyledList>
                  </FormGroup>
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </FastField>
          </Stack>
          <Box mt={5}>
            <Button
              startIcon={<CheckIcon />}
              disabled={formik.isSubmitting}
              variant="contained"
              type="submit"
            >
              Готово
            </Button>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default LabTrialForm;
