import React, { useState } from "react";

import { SWR_KEYS } from "@/const";
import { useInfiniteScroll, useStores } from "@/hooks";
import {
  FormControl,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import { EMPLOYEE_FIELD_NAME, TForm } from "../../../const";
import Autocomplete from "@/components/Autocomplete";
import { CustomPaper } from "../first";

export const SubdivisionGSU: React.FC = () => {
  const form = useFormikContext<TForm>();
  const { api } = useStores();
  const [gsuSearch, setGsuSearch] = useState("");
  const swrKeyGsu = SWR_KEYS.getPlotList();


  const getKeyGsu = (index: number) => ({
    _key: swrKeyGsu,
    page: index + 1,
    search: gsuSearch,
  });

  const fetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getPlotList({
      page,
      search,
    });
  };

  const { data, handleScroll } = useInfiniteScroll(
    getKeyGsu,
    fetcher,
    {
      revalidateFirstPage: false,
    },
  );

  /**
   * Метод для изменения гсу
   * @param event
   */
  const handleChange = (event, value) => {
    form.setFieldValue(EMPLOYEE_FIELD_NAME.PLOT, value);
  }


  return (
    <Field name={EMPLOYEE_FIELD_NAME.PLOT}>
      {({ field, meta }) => (
        <FormControl size='small' variant='outlined' fullWidth>
          <Autocomplete
            {...field}
            label='ГСУ'
            PaperComponent={CustomPaper}
            onChange={handleChange}
            data={data}
            handleScroll={handleScroll}
            search={setGsuSearch}
            error={meta.touched && !!meta.error}
          />
        </FormControl>
      )}
    </Field>
  );
};

