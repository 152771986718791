import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import {
  IProps,
  mapValueToForm,
  TForm,
  validationSchema,
  mapFormToValues,
} from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import OrderForm from "./OrderForm";
import { SWR_KEYS } from "@/const";

/**
 * Модалка с формой
 * @param initialValues - начальные значения
 * @param onSubmit - коллбек после успешного запроса
 * */
const Order: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const baseInititialValues: TForm = Object.assign(
    {
      name: undefined,
      number: undefined,
      order_date: undefined,
      order_type: "cultivar",
      files: [],
    },
    modalProps.initialValues,
  );

  const { orderId, initialValues } = modalProps;
  const { api, toastStore, swrStore } = useStores();

  const addFiles = (id, files) => {
    files.forEach((file) => {
      const formData = new FormData();
      formData.append("file", file);
      if (!file.id) {
        api.regulatoryInfo
          .orderAddFile(id, formData)
          .then(() => {
            mutateDetail && mutateDetail();
          });
      }
    });
  };

  const mutateDetail = orderId
    ? swrStore.mutators[SWR_KEYS.getOrderById(orderId)]
    : null;

  const mutateList =
    swrStore.mutators[SWR_KEYS.getOrderList()];

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);
    const promise = orderId
      ? api.regulatoryInfo.updateOrder(
          orderId,
          mapFormToValues(values),
        )
      : api.regulatoryInfo.postOrder(
          mapFormToValues(values),
        );

    promise
      .then((res) => {
        if (values.files.length) {
          addFiles(res.id, values.files);
        }
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail();
        mutateList && mutateList();
        handleClose();
      });
  };

  const values = initialValues
    ? {
        ...baseInititialValues,
        ...mapValueToForm(initialValues),
      }
    : baseInititialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <OrderForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      )}
    />
  );
};

export default Order;
