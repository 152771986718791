import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell} from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { BUDGETARY_TRAIL_CALC_KEYS, BUDGETARY_TRAIL_CALC_LABEL } from "../const";

import { TBudgetaryTrailCalcKey } from "../interfaces";
import { ApplicantCell, EditingCellBudgetaryTrailCalc, IndicatorTypeCell } from "../templates/BudgetaryTrialsCalc/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const BudgetaryTrailCalcColumns: IColumn<TBudgetaryTrailCalcKey>[] = [
  {
    label: BUDGETARY_TRAIL_CALC_LABEL.year_of_trial,
    key: BUDGETARY_TRAIL_CALC_KEYS.year_of_trial,
    contentHeader: BUDGETARY_TRAIL_CALC_LABEL.year_of_trial,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: BUDGETARY_TRAIL_CALC_LABEL.type_of_trial,
    key: BUDGETARY_TRAIL_CALC_KEYS.type_of_trial,
    contentHeader: BUDGETARY_TRAIL_CALC_LABEL.type_of_trial,
    bodyCellProps: {
      render: IndicatorTypeCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: BUDGETARY_TRAIL_CALC_LABEL.type_of_applicant,
    key: BUDGETARY_TRAIL_CALC_KEYS.type_of_applicant,
    contentHeader: BUDGETARY_TRAIL_CALC_LABEL.type_of_applicant,
    bodyCellProps: {
      render: ApplicantCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: BUDGETARY_TRAIL_CALC_LABEL.amount_of_experiences,
    key: BUDGETARY_TRAIL_CALC_KEYS.amount_of_experiences,
    contentHeader: BUDGETARY_TRAIL_CALC_LABEL.amount_of_experiences,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: BUDGETARY_TRAIL_CALC_LABEL.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellBudgetaryTrailCalc,
      styles: cellButtons,
    },
  },
];
