import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=86-113421&mode=design&t=wjp27yPmbYjfxv6D-0
const CustomIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.66665 12.9998L9.99998 11.2498L12.2916 12.9998L11.4166 10.1665L13.75 8.33317H10.9166L9.99998 5.49984L9.08331 8.33317H6.24998L8.54165 10.1665L7.66665 12.9998ZM9.99998 18.3332C8.06942 17.8471 6.47567 16.7394 5.21873 15.0103C3.96179 13.2811 3.33331 11.3609 3.33331 9.24984V4.1665L9.99998 1.6665L16.6666 4.1665V9.24984C16.6666 11.3609 16.0382 13.2811 14.7812 15.0103C13.5243 16.7394 11.9305 17.8471 9.99998 18.3332ZM9.99998 16.5832C11.4444 16.1248 12.6389 15.2082 13.5833 13.8332C14.5278 12.4582 15 10.9304 15 9.24984V5.31234L9.99998 3.43734L4.99998 5.31234V9.24984C4.99998 10.9304 5.4722 12.4582 6.41665 13.8332C7.36109 15.2082 8.55553 16.1248 9.99998 16.5832Z"
          fill="#1310C4"
        />
      </svg>
    </SvgIcon>
  );
};

export default CustomIcon;
