import {
  SxProps,
  Theme,
  styled
} from "@mui/material/styles";
import { Typography } from "@mui/material";

export const tableWrapperStyle = (theme: Theme): SxProps => ({
  width: "100%",
  padding: theme.spacing(3),
});

export const headerCellStyle = (theme: Theme): SxProps => ({
  whiteSpace: "nowrap",
  color: theme.palette.blackAndWhite.black,
});

export const cell = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  color: theme.palette.blackAndWhite.black,
  width: "max-content",
});

export const bigCell = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  color: theme.palette.blackAndWhite.black,
  width: "30%",
});

export const StyledTypography = styled(Typography)(() => ({
  display: "-webkit-box",
  "WebkitLineClamp": "1",
  "WebkitBoxOrient": "vertical",
  overflow: "hidden",
}))
