import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  IBranchMainProductionValue
} from "@/api/interfaces/responses";

interface IProps {
  data: IBranchMainProductionValue[];
}

const titleNames = {
  total: "Итого"
};

const ProductionTable: React.FC<IProps> = ({ data }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Культура</TableCell>
            <TableCell align="right">Площадь, га</TableCell>
            <TableCell align="right">Урожай, ц</TableCell>
            <TableCell align="right">
              Урожайность, ц/га
            </TableCell>
            <TableCell align="right">
              Прибыль, руб.
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const sx = { fontWeight: row.name === "total" ? 700 : 400 };
            return (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" sx={sx}>
                  {titleNames[row.name] || row.name}
                </TableCell>
                <TableCell align="right" sx={sx}>
                  {row.acreage}
                </TableCell>
                <TableCell align="right" sx={sx}>
                  {row.croppage}
                </TableCell>
                <TableCell align="right" sx={sx}>
                  {row.productivity}
                </TableCell>
                <TableCell align="right" sx={sx}>
                  {row.profit}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductionTable;
