import React, { Fragment } from "react";
import {
  Field,
  Form,
  useFormikContext,
  FieldArray
} from "formik";
import { useStores } from "@/hooks";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  FormLabel,
  Chip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { IProps, IForm } from "../types";
import theme from "@/theme";
import { FIELDS_NAME } from "../const";
import FastRegexTextField
  from "@/components/FastRegexTextField";
import AddChip from "@/components/Chips/AddChip";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import {
  ICultivar,
  IEntity
} from "@/api/interfaces/responses";
import { ChipWrap } from "@/components/Chips/ChipWrap";

/**
 * Форма создания/редактирования элемента для справочника "Пошлины"
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const DutyForm: React.FC<IProps> = ({
  modalProps,
  handleClose
}) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<IForm>();
  const title = modalProps.id ? "Редактировать запись" : "Добавить запись";

  const handleSelectCultivar = (payload: ICultivar[]) => {
    modalStore.setModalPropsByKey(modalProps._key, {
      initialValues: {
        ...formik.values,
        [FIELDS_NAME.CULTIVAR]: payload
      }
    });
  };
  const handleAddCultivar = () => {
    modalStore.open(VARIANT_MODAL.SELECT_CULTIVARS, {
      onSubmit: handleSelectCultivar,
      onClose: () => {
        modalStore.setModalPropsByKey(modalProps._key, {
          initialValues: {
            ...formik.values,
          }
        })
      },
      initialValue: formik.values.cultivar
    });
  };

  return (
    <Fragment>
      <Box p={3}>
        <Typography
          color={theme.palette.blackAndWhite.gray}
          fontSize={12}
        >
          Пошлина
        </Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider/>
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={3}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FastRegexTextField name={FIELDS_NAME.NUMBER} label="Номер" required/>
            </Grid>
          </Grid>
          <Field name={FIELDS_NAME.NAME}>
            {({ field, meta }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  label={"Название"}
                  inputProps={{
                    maxLength: 100,
                  }}
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={10}
                  disabled={meta.isSubmitting}
                  required
                />
                <Box
                  p="3px"
                  pl={2}
                  pr={2}
                  sx={{
                    color:
                    theme.palette.text.secondary,
                    fontSize:12
                  }}
                >
                  {formik.values.name
                    ?.length ?? 0}
                  /100
                </Box>
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <FieldArray
            name={FIELDS_NAME.CULTIVAR}
            render={(helpers) => {
              const values = helpers.form.values[helpers.name];
              const meta = helpers.form.getFieldMeta(helpers.name);
              return (
                <FormControl required>
                  <FormLabel>Культура</FormLabel>
                  <ChipWrap>
                    {Array.isArray(values) &&
                      values.map((value: IEntity, index) => (
                        <Chip
                          key={value.id}
                          size="small"
                          variant="outlined"
                          label={value.name}
                          onDelete={() => helpers.remove(index)}
                        />
                      ))}
                    <Box>
                      <AddChip
                        onClick={handleAddCultivar}
                      />
                    </Box>
                  </ChipWrap>
                  <FormHelperText error={meta.touched && !!meta.error}>
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              );
            }}
          />
          <Typography variant="p16" sx={{fontWeight: 700}}>Размер пошлины</Typography>
          <Grid container spacing="20px">
            <Grid item xs={6}>
              <FastRegexTextField
                name={FIELDS_NAME.RESIDENT} label="Для резидента, руб" required regexType="decimal"/>
            </Grid>
            <Grid item xs={6}>
              <FastRegexTextField
                name={FIELDS_NAME.NON_RESIDENT} label="Для нерезидента, руб" required regexType="decimal"/>
            </Grid>
          </Grid>
        </Stack>
        <Divider/>
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={handleClose}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={formik.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon/>}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default DutyForm;
