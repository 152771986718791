import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Form, useFormikContext } from "formik";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  BranchAutocomplete,
  LocationAutoComplete,
  PlotAutoComplete,
  RegionAutocomplete,
} from "@/components/Autocompletes";
import CheckIcon from "@mui/icons-material/Check";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import DestinationAutoComplete
  from "@/components/Autocompletes/DestinationAutoComplete";
import {
  ColorPicker,
  CoordinatesFieldArray,
  YearDatePicker,
} from "@/components/Fields";
import { FIELDS_NAME, IFormik } from "../../const";
import { useStores } from "@/hooks";

/**
 * Блок с полями Даные поля
 */

interface IProps {
  handleBack: () => void;
  locationId?: number | string;
  hasLocationCoordinates: boolean;
}

const AreaData: React.FC<IProps> = ({
  handleBack,
  locationId,
  hasLocationCoordinates
}) => {
  const { mapStore } = useStores();
  const { id } = useParams();

  const formik = useFormikContext<IFormik>();
  const navigate = useNavigate();

  const handleChangeBranch = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, payload);
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
  };
  const handleChangePlot = (child) => {
    void formik.setFieldValue(FIELDS_NAME.PLOT, child);

    formik.setFieldValue(FIELDS_NAME.LOCATION, "");
  };
  const handleChangeLocation = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.LOCATION, payload);
  };
  const handleChangeRegion = (payload) => {
    // меняем регион -> сбрасываем зависимые поля
    void formik.setFieldValue(FIELDS_NAME.REGION, payload);

    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
  };

  const handleChangeDestination = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.DESTINATION, payload);
  };

  const handleChangeYear = (payload) => {
    void formik.setFieldValue(
      FIELDS_NAME.PLANTING_YEAR,
      payload,
    );
  };

  const handleChange = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.COLOUR, payload);
  };

  const handleRegionClear = () => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
  };

  const handleBranchClear = () => {
    void formik.setFieldValue(FIELDS_NAME.LOCATION, "");
  };

  const handleClickDrawField = () => {
    mapStore.setInitialValuesForms(formik.values);
    // mapStore.setTempPolygonPoints([]);
    const locationId =
      formik.values[FIELDS_NAME.LOCATION]?.id;
    const qs = id
      ? `?id=${id}&locationId=${locationId}`
      : `?locationId=${locationId}`;
    navigate(`${ROUTES_PATHS.mapFieldDraw}/${qs}`);
  };

  useEffect(() => {
    if (mapStore.initialValuesForms) {
      for (const key in mapStore.initialValuesForms) {
        const value = mapStore.initialValuesForms[key];
        formik.setFieldValue(key, value);
      }
      if (mapStore.tempDrawPoligonPoints.length) {
        formik.setFieldValue(
          FIELDS_NAME.COORDINATES,
          mapStore.tempDrawPoligonPoints.map((el) => [
            el.lat,
            el.lng,
          ]),
        );
        mapStore.setIsDrawPolygon(false);
      }

      mapStore.setInitialValuesForms({});
    }

    return () => {
      mapStore.setTempPolygonPoints([]);
    };
  }, []);

  return (
    <Form noValidate>
      <Box p={3} sx={{ maxWidth: 750 }}>
        <Typography variant="h3" marginBottom="20px">
          Данные поля
        </Typography>
        <Stack direction="column" gap="20px">
          <ColorPicker
            name={FIELDS_NAME.COLOUR}
            onChange={handleChange}
          />
          <Field name={FIELDS_NAME.NAME}>
            {({ field, meta }) => (
              <TextField
                {...field}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                label="Название"
                fullWidth
                required
                size="small"
              />
            )}
          </Field>
          <RegionAutocomplete
            name={FIELDS_NAME.REGION}
            handleChange={handleChangeRegion}
            onClear={handleRegionClear}
            disabled={!!locationId || !!id}
            required={true}
          />
          <BranchAutocomplete
            name={FIELDS_NAME.BRANCH}
            handleChange={handleChangeBranch}
            q={{
              region: formik.values.region?.id ?? undefined,
            }}
            disabled={!!locationId || !!id || !formik.values.region}
            onClear={handleBranchClear}
            required={true}
          />
          <PlotAutoComplete
            name={FIELDS_NAME.PLOT}
            handleChange={handleChangePlot}
            q={{
              branch: formik.values.branch?.id ?? undefined,
            }}
            disabled={!!locationId || !!id || !formik.values.branch}
            required={true}
          />
          <LocationAutoComplete
            name={FIELDS_NAME.LOCATION}
            handleChange={handleChangeLocation}
            q={{
              plot: formik.values.plot?.id ?? undefined,
              branch: formik.values.branch?.id ?? undefined,
            }}
            disabled={!!locationId || !!id || !formik.values.branch}
            required={true}
          />

          <Grid container spacing="20px">
            <Grid item md={6} xs={12}>
              <DestinationAutoComplete
                name={FIELDS_NAME.DESTINATION}
                handleChange={handleChangeDestination}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <YearDatePicker
                name={FIELDS_NAME.PLANTING_YEAR}
                handleChange={handleChangeYear}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <CoordinatesFieldArray
            name={FIELDS_NAME.COORDINATES}
            handleClickDraw={handleClickDrawField}
            buttonLabel={
              id ? "Редактировать поле" : "Нарисовать поле"
            }
            disabled={
              !hasLocationCoordinates ||
              !formik.values[FIELDS_NAME.NAME] ||
              !formik.values[FIELDS_NAME.DESTINATION]
                ?.label ||
              !formik.values[FIELDS_NAME.PLANTING_YEAR] ||
              !formik.values[FIELDS_NAME.LOCATION]
            }
            helperText="сначала укажите участок"
          />
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          mt={5}
        >
          <Button color="red" onClick={handleBack}>
            отмена
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<CheckIcon/>}
            disabled={formik.isSubmitting}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default AreaData;
