import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import Autocomplete, {
  SearchFilterProps
} from "@/components/Autocomplete";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { IVariantMeth } from "@/api/interfaces/responses";
import { reqInterface } from "@/api/interfaces";

interface IProps extends Partial<SearchFilterProps<IVariantMeth>> {
  name: string
  handleChange?: (payload: IVariantMeth, e: any) => void,
  q?: reqInterface.IList & {
    id?: number;
  }
  initialSearch?: string
}

/**
 * Автокомплит со списком причин незакладки
 * @param name - имя
 * @param q - квери параметры для запроса
 * @param handleChange - калбек при выборе
 * @param initialSearch - начальное значение поиска
 */

const VegetationStageAutocomplete: React.FC<IProps> = ({
  name,
  initialSearch = "",
  handleChange,
  q = {},
  ...props
}) => {
  const swrKey = SWR_KEYS.getIndicatorVariantList();
  const [search, setSearch] = useState(initialSearch);
  const formik = useFormikContext();
  const { api } = useStores();
  const getKey = (index: number) => {
    return {
      _key: swrKey,
      page: index + 1,
      search: search,
      ...q
    };
  };
  const {
    data,
    handleScroll,
  } = useInfiniteScroll(
    getKey,
    api.regulatoryInfo.getVariantIndicatorMethodology,
    {
      revalidateFirstPage: false,
    },
  );
  const onChange = (e, payload: IVariantMeth) => {
    handleChange ? handleChange(payload, e) : formik.setFieldValue(name, payload);
  };

  const handleClear = () => {
    void formik.setFieldValue(name, null);
  };

  if (!formik) {
    throw new Error(
      "IndicatorVariantMethodologyAutocomplete has to be used within <Formik>"
    );
  }

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Autocomplete
          label="Вариант признака"
          onClear={handleClear}
          getLabel={el => el.variant.severity}
          {...field}
          {...props}
          onChange={onChange}
          search={setSearch}
          data={data}
          handleScroll={handleScroll}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
    </Field>
  );
};

export default VegetationStageAutocomplete;