import React from "react";
import { Box, Grid } from "@mui/material";
import GroupTabsBranch from "./GroupTabsBranch";
import {
  QS_KEYS,
  QUERY_STRING_MICRO_ROUTES_MAIN,
} from "@/apps/RightSidebar/const";
import GeneralInfo from "./tabs/GeneralInfo";
import GSU from "./tabs/Gsu";
import AgricultiralMachinery
  from "./tabs/AgriculturalMachinery";
import VarietyTesting from "./tabs/VarietyTesting";
import ProductionActivity from "./tabs/ProductionActivity";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { BranchHeader } from "./BranchHeader/BranchHeader";
import { IBranchMain } from "@/api/interfaces/responses";

interface IProps {
  renderData: IBranchMain
}

const BranchView: React.FC<IProps> = ({renderData}) => {
  const HeaderProps = {
    breadcrumbItems: [
      { id: 1, label: "Главная", href: ROUTES_PATHS.main },
      {
        id: 2,
        label: renderData.region.name,
        href: ROUTES_PATHS.main
      },
      {
        id: 3,
        label:
        renderData.name,
      },
    ],
    title: renderData.name,
  };

  const tabsContent = [
    {
      name: "ОБЩИЕ СВЕДЕНИЯ",
      content: <GeneralInfo renderData={renderData} />,
      keyqs:
      QUERY_STRING_MICRO_ROUTES_MAIN.GENERAL_INFO.path,
    },
    {
      name: "ГСУ",
      content: <GSU districts={renderData.plots?.districts || []} />,
      keyqs: QUERY_STRING_MICRO_ROUTES_MAIN.GSU.path,
    },
    {
      name: "СЕЛЬХОЗ.ТЕХНИКА",
      content: <AgricultiralMachinery data={renderData.machineries?.summary || []} />,
      keyqs:
      QUERY_STRING_MICRO_ROUTES_MAIN
          .AGRICULTIRAL_MACHINERY.path,
    },
    {
      name: "СОРТООПЫТЫ",
      content: <VarietyTesting />,
      keyqs:
      QUERY_STRING_MICRO_ROUTES_MAIN.VARIETY_TESTING
          .path,
    },
    {
      name: "ПРОИЗВОДСТВЕННАЯ ДЕЯТЕЛЬНОСТЬ",
      content: <ProductionActivity />,
      keyqs:
      QUERY_STRING_MICRO_ROUTES_MAIN.PRODUCTION_ACTIVITY
          .path,
    },
  ];

  const CollapseContent = (
    <GroupTabsBranch
      tabsData={tabsContent}
      tabType={QS_KEYS.TAB}
      baseTabValue={
        QUERY_STRING_MICRO_ROUTES_MAIN.GENERAL_INFO.path
      }
      centered={false}
      variant="scrollable"
    />
  );

  return (
    <Box>
      <BranchHeader {...HeaderProps} />
      <Grid
        container
        direction="column"
        wrap={"nowrap"}
        sx={{ width: "100%", height: "inherit" }}
      >
        <Grid
          container
          direction={"column"}
          wrap={"nowrap"}
          height={"inherit"}
        >
          {CollapseContent}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BranchView;
