import { Chip } from "@mui/material";
import theme from "@/theme";
import React from "react";
import { TTrialStatus } from "@/api/types";
import {
  STATUSES_TRIAL_KEYS,
  STATUSES_TRIAL
} from "@/const";

const COLORS = {
  [STATUSES_TRIAL_KEYS.completed]: theme.palette.primary.main,
  [STATUSES_TRIAL_KEYS.planned]: theme.palette.blue.main,
  [STATUSES_TRIAL_KEYS.created]: theme.palette.blackAndWhite.gray,
  DEFAULT: theme.palette.blackAndWhite.gray
};

interface IProps {
  status?: TTrialStatus;
}

const TTrialStatusChip: React.FC<IProps> = ({ status = "DEFAULT" }) => {
  const label = STATUSES_TRIAL[status] || "Неизвестно"
  const color = COLORS[status] || theme.palette.blackAndWhite.gray;
  return (
    <Chip size="small"
          variant="outlined"
          label={label}
          sx={{
            color: color,
            borderColor: color,
            backgroundColor: theme.palette.blackAndWhite.white
          }}
    />
  );
};

export default TTrialStatusChip;