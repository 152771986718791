import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Button,
  List,
  ListItem,
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { SearchIcon } from "@/components/icons";

import EmptyItem from "@/components/EmptyItem";
import theme from "@/theme";
import { useStores, useInfiniteScroll } from "@/hooks";
import { useDebounceEffect } from "@/hooks/useDebounce";
import {
  THEME_MODAL,
  VARIANT_MODAL,
} from "@/apps/Modals/const";
import { SWR_KEYS } from "@/const";
import {
  IOrderCultivar,
  IOrderPlot,
} from "@/api/interfaces/responses";
import { STEP_LABELS } from "@/pages/AddOrderCultivar/const";
import { OrderPlotItem } from "@/pages/AddOrderCultivar/components";

interface IProps {
  data?: IOrderCultivar | null;
}

/**
 * Компонет для шага ГСУ
 * */

const PlotFormStep: React.FC<IProps> = ({
  data: orderCultivarData,
}) => {
  const { modalStore, api, swrStore } = useStores();
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const swrKey = SWR_KEYS.getOrderPlotList(
    orderCultivarData?.id ?? "",
  );

  const getKey = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    order_cultivar: orderCultivarData?.id,
    search,
  });

  const {
    data,
    handleScroll,
    isLoadingMore,
    isLoading,
    mutate,
  } = useInfiniteScroll<IOrderPlot>(
    getKey,
    api.regulatoryInfo.getOrderPlotList,
    {
      revalidateFirstPage: false,
    },
  );

  useEffect(() => {
    swrStore.addMutator(swrKey, mutate);
  }, []);

  const onSubmit = () => {
    mutate();
  };

  const handleAddPlot = () => {
    modalStore.open(VARIANT_MODAL.ADD_ORDER_PLOT, {
      orderCultivar: orderCultivarData,
      mutate,
      theme: THEME_MODAL.W_555,
      onSubmit,
    });
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  useDebounceEffect(() => {
    setSearch(searchValue);
  }, 500);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Stack spacing={3}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h3">
          {STEP_LABELS.PLOT}
        </Typography>
        {((data.length > 0 && search.length === 0) ||
          search?.length > 0) && (
          <Stack direction="row" spacing={1}>
            <TextField
              value={searchValue}
              onChange={handleSearch}
              placeholder="Поиск..."
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              size="small"
              sx={{
                ".MuiInputBase-root": {
                  width: "300px",
                },
              }}
            />
            <Button
              sx={{
                width: "40px",
                height: "40px",
                minWidth: "auto",
                padding: 0,
              }}
              color={"blue"}
              size={"medium"}
              variant={"outlined"}
              onClick={handleAddPlot}
            >
              <AddOutlined />
            </Button>
          </Stack>
        )}
      </Stack>
      <Box onScroll={handleScroll}>
        {data?.length > 0 ? (
          <>
            <Box pt={2}>
              <List>
                {data.map((item) => (
                  <ListItem
                    key={item?.id}
                    sx={{
                      gap: 1,
                      justifyContent: "space-between",
                      border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
                      borderRadius: "4px",
                      padding: "10px",
                      marginBottom: "16px",
                    }}
                  >
                    <OrderPlotItem
                      item={item}
                      isDetailOpen={false}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            {isLoadingMore ? (
              <Stack
                pt={2}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress />
              </Stack>
            ) : (
              <Button
                sx={{
                  marginTop: "24px",
                  width: "100%",
                }}
                color={"blue"}
                size={"large"}
                startIcon={<AddOutlined />}
                variant={"outlined"}
                onClick={handleAddPlot}
              >
                Добавить ГСУ
              </Button>
            )}
          </>
        ) : (
          <EmptyItem
            title="Заполните данные по ГСУ"
            buttonText="Добавить ГСУ"
            onClick={handleAddPlot}
          />
        )}
      </Box>
    </Stack>
  );
};
export default PlotFormStep;
