import MuiAppBar, {
  AppBarProps as MuiAppBarProps
} from "@mui/material/AppBar";

import {
  styled,
  SxProps,
  Theme
} from "@mui/material/styles";

/**
 * переопределение стилей mui компонентов в mainLayout
 */

// значения размера сайдбара
const appBarHeight = 70;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const Flex = styled("div", {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme }) => ({
  position: "relative",
  height: `100%`,
  display: "flex",
  overflow: "hidden",
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: 0
}));

// стили для блока контента
export const Content = styled("main")({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  overflow: "auto", ///todo: scroll small size
  flexGrow: 1
});

// описание стилей хедера
export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
  height: appBarHeight,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  position: "relative",
  backgroundColor: theme.palette.blackAndWhite.white,
  color: theme.palette.blackAndWhite.black,
  "& .MuiToolbar-root": {
    paddingRight: "0"
  },
  ...(open && {
    // marginLeft: drawerWidth,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  ...(!open && {
    // marginLeft: drawerWidthClosed,
    width: `100%`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.blackAndWhite.stroke}`
}));

export const userButtonStyle = (theme: Theme, isOpen: boolean): SxProps => ({
  transition: theme.transitions.create(["background-color"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  padding: theme.spacing(0, 3),
  color: theme.palette.blackAndWhite.stroke,
  display: "flex",
  gap: "10px",
  marginLeft: "auto",
  alignSelf: "stretch",
  textTransform: "capitalize",
  "& .userBlock": {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    gap: "2px"
  },
  "& .userProfession": {
    fontSize: "12px"
  },
  "& .userName": {
    color: theme.palette.blackAndWhite.black,
    fontSize: "14px",
    transition: theme.transitions.create(["color"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    ...(isOpen && {
      color: theme.palette.primary.main
    })
  },
  "&:hover": {
    "& .userProfession": {
      color: theme.palette.blackAndWhite.gray
    },
    backgroundColor: theme.palette.backgroundColors.superLightGray
  }
});

export const menuItemStyle = (theme: Theme): SxProps => ({
  padding: theme.spacing(0.5, 2),
  minWidth: "185px",
});

export const dividerStyle = (theme: Theme): SxProps => ({
  color: theme.palette.blackAndWhite.stroke
});

export const menuStyle = (theme: Theme): SxProps => ({
  padding: theme.spacing(1, 0)
});

export const toolbarStyle = (): SxProps => ({
  height: "100%"
});
