import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=86-113421&mode=design&t=NsBAwfXhny5rGRRi-0
const FilterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.041016"
        height="16.000000"
        viewBox="0 0 16.041 16"
        fill="none"
      >
        <path
          id="filter_alt"
          d="M7.02051 16C6.7373 16 6.49951 15.9043 6.30811 15.7124C6.11621 15.521 6.02051 15.2832 6.02051 15L6.02051 9L0.220703 1.6001C-0.0292969 1.2666 -0.0668945 0.916504 0.10791 0.549805C0.283203 0.183105 0.587402 0 1.02051 0L15.0205 0C15.4541 0 15.7583 0.183105 15.9331 0.549805C16.1079 0.916504 16.0708 1.2666 15.8208 1.6001L10.0205 9L10.0205 15C10.0205 15.2832 9.9248 15.521 9.73291 15.7124C9.5415 15.9043 9.30371 16 9.02051 16L7.02051 16ZM8.02051 8.2998L12.9707 2L3.0708 2L8.02051 8.2998Z"
          fill="currentColor"
          fillOpacity="1.000000"
          fillRule="nonzero" />
        <path
          id="filter_alt"
          d="M7.02051 16C6.7373 16 6.49951 15.9043 6.30811 15.7124C6.11621 15.521 6.02051 15.2832 6.02051 15L6.02051 9L0.220703 1.6001C-0.0292969 1.2666 -0.0668945 0.916504 0.10791 0.549805C0.283203 0.183105 0.587402 0 1.02051 0L15.0205 0C15.4541 0 15.7583 0.183105 15.9331 0.549805C16.1079 0.916504 16.0708 1.2666 15.8208 1.6001L10.0205 9L10.0205 15C10.0205 15.2832 9.9248 15.521 9.73291 15.7124C9.5415 15.9043 9.30371 16 9.02051 16L7.02051 16ZM8.02051 8.2998L12.9707 2L3.0708 2L8.02051 8.2998Z"
          fill="currentColor"
          fillOpacity="0.200000"
          fillRule="nonzero" />
        <path
          id="filter_alt"
          d="M7.02051 16C6.7373 16 6.49951 15.9043 6.30811 15.7124C6.11621 15.521 6.02051 15.2832 6.02051 15L6.02051 9L0.220703 1.6001C-0.0292969 1.2666 -0.0668945 0.916504 0.10791 0.549805C0.283203 0.183105 0.587402 0 1.02051 0L15.0205 0C15.4541 0 15.7583 0.183105 15.9331 0.549805C16.1079 0.916504 16.0708 1.2666 15.8208 1.6001L10.0205 9L10.0205 15C10.0205 15.2832 9.9248 15.521 9.73291 15.7124C9.5415 15.9043 9.30371 16 9.02051 16L7.02051 16ZM8.02051 8.2998L12.9707 2L3.0708 2L8.02051 8.2998Z"
          fill="currentColor"
          fillOpacity="0.200000"
          fillRule="nonzero"
        />
        <path
          id="filter_alt"
          d="M7.02051 16C6.7373 16 6.49951 15.9043 6.30811 15.7124C6.11621 15.521 6.02051 15.2832 6.02051 15L6.02051 9L0.220703 1.6001C-0.0292969 1.2666 -0.0668945 0.916504 0.10791 0.549805C0.283203 0.183105 0.587402 0 1.02051 0L15.0205 0C15.4541 0 15.7583 0.183105 15.9331 0.549805C16.1079 0.916504 16.0708 1.2666 15.8208 1.6001L10.0205 9L10.0205 15C10.0205 15.2832 9.9248 15.521 9.73291 15.7124C9.5415 15.9043 9.30371 16 9.02051 16L7.02051 16ZM8.02051 8.2998L12.9707 2L3.0708 2L8.02051 8.2998Z"
          fill="currentColor"
          fillOpacity="0.200000"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
};

export default FilterIcon;
