const runtimeConfig = (() => {
  if (typeof window === "undefined") {
    // Для сервера
    return {
      REACT_APP_API_DOMAIN: process?.env?.REACT_APP_API_DOMAIN || ""
    };
  } else {
    // Для клиента
    return {
      REACT_APP_API_DOMAIN: globalThis?.env?.REACT_APP_API_DOMAIN || ""
    };
  }
})();

export { runtimeConfig };
