import { IColumn } from "@/apps/Table/interfaces";
import {
  FieldCells,
  PlotCells,
  CultivarCells,
  TrialTypeCells,
  EditingCells,
  PlantingYearCells,
} from "../components/Cells";

import {
  CULTIVAR_FIELD_LABELS,
  CULTIVAR_FIELD_KEYS
} from "../const";

import { TCultivarFieldKeys } from "../interfaces";

import {
  cell,
  headerCellStyle,
  trialTypeCellsStyle
} from "../style";
import { cellButtons } from "@/apps/Table/style";

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const columns: IColumn<TCultivarFieldKeys>[] = [
  {
    label:  CULTIVAR_FIELD_LABELS.year,
    key: CULTIVAR_FIELD_KEYS.year,
    contentHeader: CULTIVAR_FIELD_LABELS.year,
    bodyCellProps: {
      render: PlantingYearCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  CULTIVAR_FIELD_LABELS.plot,
    key: CULTIVAR_FIELD_KEYS.plotName,
    contentHeader: CULTIVAR_FIELD_LABELS.plot,
    bodyCellProps: {
      render: PlotCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  CULTIVAR_FIELD_LABELS.cultivar,
    key: CULTIVAR_FIELD_KEYS.cultivarName,
    contentHeader: CULTIVAR_FIELD_LABELS.cultivar,
    bodyCellProps: {
      render: CultivarCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  CULTIVAR_FIELD_LABELS.fieldName,
    key: CULTIVAR_FIELD_KEYS.fieldName,
    contentHeader: CULTIVAR_FIELD_LABELS.fieldName,
    bodyCellProps: {
      render: FieldCells,
      styles: cell
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  CULTIVAR_FIELD_LABELS.typeTrial,
    key: CULTIVAR_FIELD_KEYS.typeTrial,
    contentHeader: CULTIVAR_FIELD_LABELS.typeTrial,
    bodyCellProps: {
      render: TrialTypeCells,
      styles: trialTypeCellsStyle
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    key: CULTIVAR_FIELD_KEYS.editing,
    label: '',
    contentHeader: '',
    bodyCellProps: {
      render: EditingCells,
      styles: cellButtons,
    },
  }
];
