import React from "react";
import { Field, Form, useFormikContext } from "formik";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import theme from "@/theme";
import { FIELD_NAME } from "../const";
import { FILE_AND_IMAGE_ACCEPT, FILE_AND_IMAGE_SUBTITLE, IFile } from "@/components/FileDropzone/const";
import CheckIcon from "@mui/icons-material/Check";
import { useStores } from "@/hooks";
import {
  DatePickerField,
  FastTextField,
} from "@/components/Fields";
import FileDropzone from "@/components/FileDropzone";

interface IProps {
  subtitle: string;
  title: string;
}

const AddDocumentForm: React.FC<IProps> = ({
  subtitle,
  title,
}) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<any>();

  const handleChangeFiles = (files: IFile[]) => {
    void formik.setFieldValue(FIELD_NAME.FILES, files);
  };

  return (
    <Form noValidate>
      <Box p={3}>
        <Typography
          variant="body2"
          color={theme.palette.blackAndWhite.gray}
        >
          {subtitle}
        </Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Stack p={3} gap="20px">
        <FastTextField
          name={FIELD_NAME.NAME}
          label="Название документа"
        />
        <Grid container spacing="20px">
          <Grid item xs={6}>
            <FastTextField
              name={FIELD_NAME.NUMBER}
              label="Номер документа"
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerField
              name={FIELD_NAME.RECEIPT_DATE}
              label="Дата поступления"
              fullWidth={true}
            />
          </Grid>
        </Grid>
        <Field name={FIELD_NAME.FILES}>
          {({ field, meta }) => (
            <FormControl>
              <FormLabel>
                <Typography variant="p14Medium">
                  Файл
                </Typography>
              </FormLabel>
              <FileDropzone
                isFileAdd={true}
                name={field.name}
                value={field.value}
                setValue={handleChangeFiles}
                maxFiles={1}
                multiple={false}
                accept={FILE_AND_IMAGE_ACCEPT}
                subtitle={FILE_AND_IMAGE_SUBTITLE}
              />
              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
      </Stack>
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button
          color="red"
          onClick={() => modalStore.close()}
        >
          отмена
        </Button>
        <Button
          type="submit"
          disabled={formik.isSubmitting}
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default AddDocumentForm;
