import React, { useEffect, useMemo, useState } from "react";
import {
  FastField,
  Field,
  Form,
  useFormikContext,
} from "formik";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CheckIcon from "@mui/icons-material/Check";

import Autocomplete from "@/components/Autocomplete";

import { useInfiniteScroll, useStores } from "@/hooks";

import { FACT_FIELD_NAME, IProps, TForm } from "../const";
import { SWR_KEYS } from "@/const";
import { isNull, isUndefined } from "@/utils/helpers";
import { PLANNING_STATUS_NAME } from "@/pages/Planning/const";
import { BranchAutocomplete, RegionAutocomplete } from "@/components/Autocompletes";

/**
 * Форма создания/редактирования сорта
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * @param modalProps.key - ключ модалки
 * @param modalProps.submitOnMount - сабмит после монтирования
 * */
const CreateFactForm: React.FC<IProps> = ({
  modalProps,
}) => {
  const { api, modalStore, swrStore } = useStores();
  const { submitOnMount } = modalProps;
  const formik = useFormikContext<TForm>();
  const [isPlanSelected, setIsPlanSelected] =
    useState(false);
  const [isPlanEmpty, setIsPlanEmpty] = useState(true);

  // дизейбл плана при изменении года/филиала
  const isDisabledPlan = useMemo(
    () =>
      isUndefined(formik.values[FACT_FIELD_NAME.BRANCH]) ||
      isNull(formik.values[FACT_FIELD_NAME.YEAR]),
    [
      formik.values[FACT_FIELD_NAME.BRANCH],
      formik.values[FACT_FIELD_NAME.YEAR],
    ],
  );

  const swrKeyPlan = SWR_KEYS.getPlanList();

  /**
   * Генератор параметров запроса плана
   * @param index - номер страницы (нумерация от 0)
   * @param branch - id филиала
   * @param year - год плана
   * @returns ключ для запроса
   */
  const getParametersPlan = (index: number) => ({
    _key: swrKeyPlan,
    page: index + 1,
    branch: formik.values[FACT_FIELD_NAME.BRANCH]?.id,
    year: formik.values[FACT_FIELD_NAME.YEAR].year(),
    status: PLANNING_STATUS_NAME.approved,
    type: "plan",
  });

  const {
    data: dataPlan,
    handleScroll: handleScrollPlan,
    mutate: mutatePlan,
    isEmpty: isEmptyPlanData,
  } = useInfiniteScroll(
    getParametersPlan,
    api.offBudget.getOffBudgetList,
    {
      revalidateFirstPage: false,
    },
  );

 
  const handleChangeDate = (value) => {
    // зануление плана при изменении года (undefined для autocomplete)
    formik.setFieldValue(FACT_FIELD_NAME.PLAN, undefined);

    formik.setFieldValue(FACT_FIELD_NAME.YEAR, value);
  };

  const disableSubmitRule =
    !formik.values?.name?.length &&
    !formik.values?.source_plan;

  const handleChangeRegion = (child) => {
    // зануление плана и филиала при изменении региона
    formik.setFieldValue(FACT_FIELD_NAME.BRANCH, undefined);
    formik.setFieldValue(FACT_FIELD_NAME.PLAN, undefined);

    formik.setFieldValue(FACT_FIELD_NAME.REGION, child);
  };

  const handleClearRegion = () => {
    // зануление плана и филиала при изменении региона
    formik.setFieldValue(FACT_FIELD_NAME.BRANCH, "");
    formik.setFieldValue(FACT_FIELD_NAME.PLAN, "");

    formik.setFieldValue(FACT_FIELD_NAME.REGION, "");
  };


  const handleChangeBranch = (child) => {
    // зануление плана при изменении филиала
    formik.setFieldValue(FACT_FIELD_NAME.PLAN, undefined);
    formik.setFieldValue(FACT_FIELD_NAME.BRANCH, child);
  };

  const handleClearBranch = () => {
    formik.setFieldValue(FACT_FIELD_NAME.PLAN, "");
    formik.setFieldValue(FACT_FIELD_NAME.BRANCH, "");
  };

  const handleChangePlan = (event, child) => {
    formik.setFieldValue(FACT_FIELD_NAME.PLAN, child);
    setIsPlanSelected(true);
    setIsPlanEmpty(!child);
    // если выбран план, мы не можем писать что-то свое в поле плана
  };

  useEffect(() => {
    submitOnMount && formik.handleSubmit();
  }, [submitOnMount]);

  useEffect(() => {
    swrStore.addMutator(swrKeyPlan, mutatePlan);
  }, []);

  const title = "Добавить факт";

  const handleBlockWriting = (e) => {
    if (isPlanSelected && !isPlanEmpty) {
      e.preventDefault(); // не даем вводить символы
    }
  };

  const handleActivateWriting = () => {
    setIsPlanSelected(false);
  };

  return (
    <Form noValidate>
      <Box>
        <Box p={3}>
          <Typography variant="h2">{title}</Typography>
        </Box>

        <Divider />

        <Box p={3}>
          <Stack direction="column" spacing="20px">
            <FastField name={FACT_FIELD_NAME.YEAR}>
              {({ field, meta }) => (
                <DatePicker
                  name={field.name}
                  onChange={handleChangeDate}
                  value={field.value}
                  label="Год плана"
                  openTo="year"
                  views={["year"]}
                  slotProps={{
                    textField: {
                      size: "small",
                      required: true,
                      error: meta.touched && !!meta.error,
                      helperText:
                        meta.touched && meta.error,
                      onBlur: field.onBlur,
                    },
                  }}
                />
              )}
            </FastField>

            <RegionAutocomplete name={FACT_FIELD_NAME.REGION}
              handleChange={handleChangeRegion}
              onClear={handleClearRegion} />
            <BranchAutocomplete name={FACT_FIELD_NAME.BRANCH}
              q={{ region: formik.values.region?.id }}
              disabled={!formik.values.region}
              handleChange={handleChangeBranch}
              onClear={handleClearBranch} />

            <Field name={FACT_FIELD_NAME.PLAN} required>
              {({ field, meta }) => (
                <FormControl required>
                  <Autocomplete
                    {...field}
                    onKeyPress={handleBlockWriting}
                    onClear={handleActivateWriting}
                    filterOptions={(x) => x} // отключаем подсказки на клиенте
                    onChange={handleChangePlan}
                    label="План"
                    data={dataPlan}
                    required
                    handleScroll={handleScrollPlan}
                    disabled={
                      isDisabledPlan || isEmptyPlanData
                    }
                    error={meta.touched && !!meta.error}
                  />

                  <FormHelperText
                    error={
                      (meta.touched || isEmptyPlanData) &&
                      !!meta.error
                    }
                  >
                    {(meta.touched || isEmptyPlanData) &&
                      meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
            <Field name={FACT_FIELD_NAME.NAME}>
              {({ field, meta }) => (
                <FormControl
                  sx={{
                    width: "100%",
                  }}
                  size="small"
                  variant="outlined"
                >
                  <TextField
                    {...field}
                    size={"small"}
                    label="Название факта"
                    required
                    fullWidth
                  />

                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
          </Stack>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>

          <Button
            type="submit"
            disabled={
              formik.isSubmitting || disableSubmitRule
            }
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default CreateFactForm;
