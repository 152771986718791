import React from "react";
import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import ModalStepper from "@/components/ModalStepper";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import {
  IProps,
  mapFormToValue,
  mapValueToForm,
  TForm,
  validationSchemaFirst,
  validationSchemaSecond,
} from "./const";
import YearWeatherHistoryFirstStep from "./components/first";
import YearWeatherHistorySecondStep from "./components/second";
import { SWR_KEYS } from "@/const";

/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const AddYearWeatherHistory: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const { api, toastStore, swrStore } = useStores();
  const initialValues = modalProps.initialValues;
  const {id, year, fieldId} = modalProps
  const baseInititialValues = {
    location: fieldId,
    analysis_year: Number(year),
    snow_cover_establishment: null,
    snow_cover_descent: null,
    autumn_first_frost: null,
    spring_last_frost: null,
    spring_transition_0: null,
    autumn_transition_0: null,
    spring_transition_5: null,
    autumn_transition_5: null,
    spring_transition_10: null,
    autumn_transition_10: null,
    spring_transition_15: null,
    autumn_transition_15: null,
    water_stagnation: '',
  };

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const promise =
      id ? api.weather.patchYearWeatherHistory(id, mapFormToValue(values))
      : api.weather.postYearWeatherHistory(mapFormToValue(values));

    const mutateDetail = id ? swrStore.mutators[SWR_KEYS.getYearWeatherHistoryById(id)] : null

    return promise
      .then(() => {
        mutateDetail && mutateDetail();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errorMessage = errorToString(error);
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorMessage,
            severity: "error",
          },
        });
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail()
      });
  };

  const subtitle = modalProps.id
    ? `Редактировать метеоданные за ${year}`
    : `Добавить метеоданные за ${year}`;

  const values = initialValues
    ? {
      ...baseInititialValues,
      ...mapValueToForm(initialValues),
    }
    : baseInititialValues;

  return (
    <ModalStepper
      title=""
      subtitle={subtitle}
      initialValues={values}
      onSubmit={handleSubmit}
      handleClose={handleClose}
      enableReinitialize={true}
    >
      <ModalStepper.Step
        validationSchema={validationSchemaFirst}
      >
        <YearWeatherHistoryFirstStep
          modalProps={modalProps}
          handleClose={handleClose}
        />
      </ModalStepper.Step>

      <ModalStepper.Step
        validationSchema={validationSchemaSecond}
      >
        <YearWeatherHistorySecondStep
          modalProps={modalProps}
          handleClose={handleClose}
        />
      </ModalStepper.Step>
    </ModalStepper>
  );
};

export default AddYearWeatherHistory;
