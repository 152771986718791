import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import NavigateBeforeIcon
  from "@mui/icons-material/NavigateBefore";

interface IProps {
  headerProps: {
    title: string
    handleBack: () => void
    subtitle?: string
  };
}

/**
 * общий хедер с заголовком подзаголовком и кнопкой назад
 */
const HeaderDefault: React.FC<IProps> = ({ headerProps }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <IconButton onClick={headerProps.handleBack}>
        <NavigateBeforeIcon/>
      </IconButton>
      <Box>
        <Typography fontSize={24} fontWeight={500}
                    variant="h3" noWrap>
          {headerProps.title}
        </Typography>
        {
          headerProps.subtitle && (
            <Typography
              variant="body2" noWrap>
              {headerProps.subtitle}
            </Typography>
          )
        }
      </Box>
    </Box>
  );
};

export default HeaderDefault;
