import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import {
  IProps,
  mapValueToForm,
  TForm,
  validationSchema,
  mapFormToValues,
} from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import EditPlanningForm from "./EditPlanningForm";
import { SWR_KEYS } from "@/const";

/**
 * Модалка с формой  редактирования деталки плана
 * @param modalProps пропсы
 * @param modalProps.planning - начальные значения
 * */

const EditPlanning: React.FC<IProps> = ({
  modalProps,
}) => {

  const initialValues = modalProps.planning;
  const planningId = modalProps.planning?.id;
  const { api, toastStore, swrStore, modalStore } = useStores();

  const baseInititialValues: TForm = Object.assign(
    {
      year: undefined,
      plan_date: undefined,
    },
    modalProps.planning,
  );

  const mutateDetail = swrStore.mutators[SWR_KEYS.getPlanningByIdKey(planningId)];

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);
    api.planning
      .patchPlanningById(
        planningId,
        mapFormToValues(values)
      )
      .then(() => {
        mutateDetail && mutateDetail();
        modalProps.handleSubmit && modalProps.handleSubmit()
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        modalStore.close()
      });
  };

  const values = initialValues
    ? {
      ...baseInititialValues,
      ...mapValueToForm(initialValues),
    }
    : baseInititialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <EditPlanningForm
          modalProps={modalProps}
        />
      )}
    />
  );
};

export default EditPlanning;
