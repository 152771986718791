import React from "react";
import { Box, Button, Tooltip, } from "@mui/material";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";
import {
  IAgriculturalLabourWork
} from "@/api/interfaces/responses";
import WorkListItem from "../WorkListItem";
import { TOOLTIP_TEXT } from "../WorkList/const";

interface IProps {
  data: IAgriculturalLabourWork[];
  handleAddField: () => void;
  isDisabledBtnAdd: boolean;
}

const WorkContentList: React.FC<IProps> = ({
  data,
  handleAddField,
  isDisabledBtnAdd,
}) => {
 
  return (
    <>
      {data.map((el, index) => (
        <WorkListItem key={el.id} data={el} itemIndex={index}/>
      ))}
      <Tooltip
        title={!isDisabledBtnAdd ? TOOLTIP_TEXT : ""}
        followCursor
      >
        <Box width={"100%"}>
          <Button
            color={"blue"}
            size={"large"}
            startIcon={<AddOutlinedIcon />}
            variant={"outlined"}
            onClick={handleAddField}
            disabled={!isDisabledBtnAdd}
            sx={{
              width: "100%",
            }}
          >
            Добавить работу
          </Button>
        </Box>
      </Tooltip>
    </>
  );
};

export default WorkContentList;
