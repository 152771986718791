import { useState } from "react";
import {
  FiltersType
} from "@/components/Filters/interfaces";
import { IFilter, IValue } from "@/interfaces";
import {
  useInfiniteScroll,
  useQuery,
  useStores
} from "@/hooks";
import useDebounce from "@/hooks/useDebounce";
import { SWR_KEYS } from "@/const";



const useFilters = (): IFilter[] => {
  const { api } = useStores();

  const query = useQuery<any>();

  const [searchBranch, setSearchBranch] = useState("");
  const dbouncedSearchBranchValue = useDebounce(
    searchBranch,
    500,
  );

  const getKey = (index: number) => {
    return {
      key: SWR_KEYS.getBranchList(),
      page: index + 1,
      search: dbouncedSearchBranchValue,
    };
  };


  const { data: branches, handleScroll: handleScrollBranch } =
    useInfiniteScroll(getKey, api.regulatoryInfo.getBranchList , {
      revalidateFirstPage: false,
    });

  const branch: IValue[] = branches.map(({ name, id }) => {
    return { label: name, key: id };
  });

const [searchRegion, setSearchRegion] = useState("");

  const dbouncedSearchRegion = useDebounce(
    searchRegion,
    500,
  );

  const getRegionKey = (index: number) => {
    return {
      key: SWR_KEYS.getRegionsList(),
      page: index + 1,
      search: dbouncedSearchRegion,
    };
  };


  const {
    data: regionData,
    handleScroll: handleScrollRegion,
  } = useInfiniteScroll(getRegionKey, api.regulatoryInfo.getRegionList, {
    revalidateFirstPage: false,
  });

  const region: IValue[] = regionData.map(
    ({ name, id }) => {
      return { label: name, key: id };
    },
  );

  const filters = [
    {
      name: "region",
      label: "Регион",
      type: FiltersType.autoComplete,
      selectedValue: query.region,
      handleScroll: handleScrollRegion,
      options: region,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
      setValue: setSearchRegion
    },
    {
      name: "branch",
      label: "Филиал",
      type: FiltersType.autoComplete,
      selectedValue: query.branch,
      options: branch,
      value: "",
      handleScroll: handleScrollBranch,
      style: { minWidth: "230px", flexGrow: 1 },
      setValue: setSearchBranch,
    },
  ];

  return filters;
};

export default useFilters;
