import React from "react";
// import { useAppDispatch } from 'store';
// import { emailConfirm } from 'store/slices/account/actions';

export const EmailConfirm = () => {
  // React.useEffect(() => {
  //   api.user
  //     .emailConfirm({
  //       email: query.email as string,
  //       emailConfirmToken: query.emailConfirmToken as string,
  //       userType: query.userType as string
  //     })
  //     .unwrap()
  //     .then(() => {
  //       // notification.success({
  //       //   message: "Email успешно подтвержден!"
  //       // });
  //       navigate("/");
  //     })
  //     .catch(() => {
  //       navigate("/email-is-send?skipTimer=true");
  //     });
  // }, []);
  return <div>...загрузка</div>;
};
