import { makeAutoObservable } from "mobx";
import { RootStore } from "./Root";
import { IPlanGroupPlot } from "@/api/interfaces/responses";

export class PlanPlotStore {
  planPlotGroups : IPlanGroupPlot[] | undefined
  rootStore: RootStore

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.planPlotGroups = []
    makeAutoObservable(this)
  }

  setPlanPlotGroups = (payload: IPlanGroupPlot[] | undefined) => {
    this.planPlotGroups = payload
  }

}