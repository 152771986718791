import React from "react";
import { observer } from "mobx-react";
import { Form, useFormikContext } from "formik";
import { Box, Button, Stack } from "@mui/material";
import {
  CultivarAutocomplete,
  RegionAutocomplete,
  BranchAutocomplete,
  PlanAutocomplete
} from "@/components/Autocompletes";
import { FIELDS_NAME, IFormik } from "../../const";
import CheckIcon from "@mui/icons-material/Check";
import { IRegionItem, } from "@/api/interfaces/responses";
import { YearDatePicker } from "@/components/Fields";

const CultivarFieldVarietyLabForm = () => {
  const formik = useFormikContext<IFormik>();

  const handleChangeYear = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.YEAR, payload);
    void formik.setFieldValue(FIELDS_NAME.PLAN, "");
  };

  const handleChangeCultivar = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.CULTIVAR, payload);
    void formik.setFieldValue(FIELDS_NAME.PLAN, "");
  };

  const handleClearCultivar = () => {
    void formik.setFieldValue(FIELDS_NAME.CULTIVAR, "");
    void formik.setFieldValue(FIELDS_NAME.PLAN, "");
  };

  const handleChangeBranch = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, payload);
    void formik.setFieldValue(FIELDS_NAME.PLAN, "");
  };

  const handleClearBranch = () => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLAN, "");
  };

  const handleRegionClear = () => {
    void formik.setFieldValue(FIELDS_NAME.REGION, "");
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
  };

  const handleChangeRegion = (payload: IRegionItem) => {
    void formik.setFieldValue(FIELDS_NAME.REGION, payload);
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
  };

  return (
    <Form noValidate>
      <Box p={3} sx={{ maxWidth: 350 }}>
        <Stack direction="column" gap="20px">
          <YearDatePicker name={FIELDS_NAME.YEAR}
                          label="Год испытаний"
                          handleChange={handleChangeYear}
                          fullWidth/>
          <CultivarAutocomplete
            name={FIELDS_NAME.CULTIVAR}
            handleChange={handleChangeCultivar}
            onClear={handleClearCultivar}
          />
          <RegionAutocomplete name={FIELDS_NAME.REGION}
                              handleChange={handleChangeRegion}
                              onClear={handleRegionClear}
          />
          <BranchAutocomplete name={FIELDS_NAME.BRANCH}
                              q={{ region: formik.values.region?.id }}
                              handleChange={handleChangeBranch}
                              onClear={handleClearBranch}
          />
          <PlanAutocomplete name={FIELDS_NAME.PLAN} required
                            q={{
                              status: "approved",
                              expert_assessment: true,
                              cultivar: formik.values.cultivar?.id,
                              branch: formik.values.branch?.id,
                              year_max: formik.values.year || undefined,
                              year_min: formik.values.year || undefined
                            }}
          />
        </Stack>
        <Box display="flex" justifyContent="space-between"
             mt={5}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<CheckIcon/>}
            disabled={formik.isSubmitting}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default observer(CultivarFieldVarietyLabForm);
