import { TOrderCultivarStatus } from "@/api/types";
import theme from "@/theme";

export const ADD_CULTIVAR_TEXT = "Добавить культуру";

export const EMPTY_LIST_CULTIVAR_TEXT =
  "Еще не создано ни одной культуры";

export const EMPTY_LIST_CULTIVAR_TEXT_WITH_FILTERS =
  "По указанным параметрам культуры не найдены";

export const CULTIVAR_HEADER_LABELS = {
  order: "Приказ",
  order__order_date: "Дата приказа",
  cultivar: "Культура",
  status: "Статус",
} as const;

export const CULTIVAR_KEYS = {
  order: "order__number",
  cultivarName: "cultivar__name",
  order__order_date: "order__order_date",
  status: "status",
  editing: "editing",
} as const;

export const ORDER_CULTIVAR_STATUS: {
  [key in TOrderCultivarStatus]: string;
} = {
  draft: "Черновик",
  approved: "Утверждено",
};

export const ORDER_CULTIVAR_STATUS_NAME: {
  [key in TOrderCultivarStatus]: TOrderCultivarStatus;
} = {
  draft: "draft",
  approved: "approved",
} as const;

export const ORDER_CULTIVAR_STATUS_COLOR: {
  [key in TOrderCultivarStatus]: string;
} = {
  draft: theme.palette.blackAndWhite.gray,
  approved: theme.palette.green.main,
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};
