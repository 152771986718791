import React from "react";
import { Button, MobileStepper } from "@mui/material";

interface IMobileControls {
  handleAccept?: () => void,
  disabled?: boolean,
  nextButtonLabel?: string;
  steps: number;
  activeStep: number;
  handleBack: () => void;
  isLastStep: boolean;
}

/** Степпер с кнопками для мобилки */
const MobileControls: React.FC<IMobileControls> = ({
  steps,
  activeStep,
  handleBack,
  isLastStep,
  nextButtonLabel = "Далее",
  disabled = false,
  handleAccept
}) => {
  const handleClick = () => {
    handleAccept && handleAccept();
  };
  return (
    <MobileStepper
      variant="text"
      steps={steps}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Button
          disabled={disabled}
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          {isLastStep ? "Готово" : nextButtonLabel}
        </Button>
      }
      backButton={
        <Button
          disabled={activeStep === 0 || disabled}
          variant="contained"
          color="secondary"
          onClick={handleBack}
        >
          Назад
        </Button>
      }
    />
  );
};

export default MobileControls;