import React from "react";
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import TextureTwoToneIcon from "@mui/icons-material/TextureTwoTone";
import theme from "@/theme";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { TOOLTIP_TEXT } from "../WorkList/const";

interface IProps {
  handleAddField: () => void;
  isDisabledBtnAdd: boolean;
}

export const WorkEmptyList: React.FC<IProps> = ({
  handleAddField,
  isDisabledBtnAdd,
}) => {
  return (
    <Stack
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        height: "65%",
        pl: 3,
        pr: 3,
      }}
    >
      <TextureTwoToneIcon
        sx={{
          fontSize: 60,
          color: theme.palette.blackAndWhite.stroke,
        }}
      />

      <Typography variant="h3">
        Заполните данные по работам
      </Typography>

      <Tooltip
        title={!isDisabledBtnAdd ? TOOLTIP_TEXT : ""}
        followCursor
      >
        <Box width={"100%"}>
          <Button
            sx={{
              marginTop: "24px",
              width: "100%",
            }}
            color={"blue"}
            size={"large"}
            startIcon={<AddOutlinedIcon />}
            variant={"outlined"}
            onClick={handleAddField}
            disabled={!isDisabledBtnAdd}
          >
            Добавить работы
          </Button>
        </Box>
      </Tooltip>
    </Stack>
  );
};

export default WorkEmptyList;
