// @ts-nocheck
import { styled } from "@mui/system";
import {
  CSSObject,
  SxProps,
  Theme
} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 220;

export const navLinkStyle = (theme: Theme): SxProps => ({
  borderRadius: "4px",
  color: theme.palette.blackAndWhite.black,
  "&.active": {
    backgroundColor: theme.palette.backgroundColors.avatarBg,
    color: theme.palette.green.main,

    span: {
      color: theme.palette.primary.main
    },

    ".MuiListItemIcon-root": {
      color: theme.palette.primary.main
    }
  }
});
export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end"
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: `calc(${drawerWidth}px + 1px)`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.backgroundColors.superLightGray,
  borderRight: `1px solid ${theme.palette.blackAndWhite.stroke}`
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: theme.spacing(11),
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.backgroundColors.superLightGray,
  borderRight: `1px solid ${theme.palette.blackAndWhite.stroke}`
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

export const sidebarTitleStyle = (isOpen: boolean, theme: Theme): SxProps => ({
  transition: theme.transitions.create("padding", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: theme.spacing(1),
  ...(!isOpen && {
    "@media (min-width: 600px)": {
      padding: theme.spacing(0, 1, 0, 2)
    }
  })
});

export const sidebarHeaderStyle = {
  justifyContent: "flex-start",
  marginBottom: "8px"
};

export const hideSidebarStyle = (isOpen: boolean, theme: Theme): SxProps => ({
  transition: theme.transitions.create("right", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  position: "absolute",
  bottom: theme.spacing(3),
  ...(isOpen ? { right: "0" } : {
    right: "50%",
    transform: "translateX(50%)"
  })
});

