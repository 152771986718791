import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Field, Form, useFormikContext } from "formik";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  BranchAutocomplete,
  PlotAutoComplete,
  RegionAutocomplete,
} from "@/components/Autocompletes";
import { FIELDS_NAME, IFormik } from "../../const";
import CheckIcon from "@mui/icons-material/Check";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";
import {
  ColorPicker,
  CoordinatesFieldArray,
} from "@/components/Fields";

/**
 * Блок с полями Даные участка
 */
const AreaData = () => {
  const { mapStore } = useStores();
  const formik = useFormikContext<IFormik>();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleChangeBranch = (child) => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, child);
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };

  const handleChangePlot = (child) => {
    void formik.setFieldValue(FIELDS_NAME.PLOT, child);
  };

  const handleChangeRegion = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.REGION, payload);
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };

  const handleClose = () => {
    navigate(`${ROUTES_PATHS.agriculturalWork}`);
  };
  const handleClickDrawArea = () => {
    mapStore.setInitialValuesForms(formik.values);
    const idPlot = formik.values[FIELDS_NAME.PLOT]?.id;
    const qs = id
      ? `?idPlot=${idPlot}&id=${id}`
      : `?idPlot=${idPlot}`;
    navigate(`${ROUTES_PATHS.mapAreaDraw}/${qs}`);
  };
  const handleChange = (payload) => {
    void formik.setFieldValue(FIELDS_NAME.COLOUR, payload);
  };

  const handleRegionClear = () => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };

  const handleBranchClear = () => {
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };

  useEffect(() => {
    if (mapStore.initialValuesForms) {
      for (const key in mapStore.initialValuesForms) {
        const value = mapStore.initialValuesForms[key];
        formik.setFieldValue(key, value);
      }

      if (mapStore.tempDrawPoligonPoints.length) {
        formik.setFieldValue(
          FIELDS_NAME.COORDINATES,
          mapStore.tempDrawPoligonPoints.map((el) => [
            el.lat,
            el.lng,
          ]),
        );
        mapStore.setIsDrawPolygon(false);
      }
      mapStore.setInitialValuesForms({});
    }
  }, []);

  return (
    <Form noValidate>
      <Box p={3} sx={{ maxWidth: 750 }}>
        <Typography variant="h3" marginBottom="20px">
          Данные участка
        </Typography>
        <Stack direction="column" gap="20px">
          <ColorPicker
            name={FIELDS_NAME.COLOUR}
            onChange={handleChange}
          />
          <Field name={FIELDS_NAME.NAME}>
            {({ field, meta }) => (
              <TextField
                {...field}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                label="Название"
                fullWidth
                required
                size="small"
                inputProps={{ 'data-qa': 'mapName' }}
              />
            )}
          </Field>
          <RegionAutocomplete
            name={FIELDS_NAME.REGION}
            handleChange={handleChangeRegion}
            onClear={handleRegionClear}
            required={true}
            disabled={!!id}
          />
          <BranchAutocomplete
            name={FIELDS_NAME.BRANCH}
            handleChange={handleChangeBranch}
            q={{
              region: formik.values.region?.id ?? undefined,
            }}
            disabled={!formik.values.region || !!id}
            onClear={handleBranchClear}
            required={true}
          />
          <PlotAutoComplete
            name={FIELDS_NAME.PLOT}
            handleChange={handleChangePlot}
            q={{
              branch: formik.values.branch?.id ?? undefined,
            }}
            disabled={!formik.values.branch || !!id}
            required={true}
          />
          <CoordinatesFieldArray
            name={FIELDS_NAME.COORDINATES}
            buttonLabel={
              id
                ? "Редактировать участок"
                : "Нарисовать участок"
            }
            handleClickDraw={handleClickDrawArea}
            error={!formik.values[FIELDS_NAME.PLOT]}
            disabled={!formik.values[FIELDS_NAME.PLOT]}
            helperText="сначала укажите ГСУ"
          />
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          mt={5}
        >
          <Button color="red" onClick={handleClose}>
            отмена
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
            disabled={formik.isSubmitting}
            data-qa="buttonSaveLocation"
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default observer(AreaData);
