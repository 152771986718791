import React from "react"
import { IIcon } from "./interfaces"

const SellIcon = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4242_265297"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4242_265297)">
        <path
          d="M9.49992 14.2667C9.24436 14.5223 8.9277 14.65 8.54992 14.65C8.17214 14.65 7.85547 14.5223 7.59992 14.2667L1.73325 8.40004C1.61103 8.27782 1.51381 8.13337 1.44159 7.96671C1.36936 7.80004 1.33325 7.62226 1.33325 7.43337V2.66671C1.33325 2.30004 1.46381 1.98615 1.72492 1.72504C1.98603 1.46393 2.29992 1.33337 2.66659 1.33337H7.43325C7.62214 1.33337 7.79992 1.36949 7.96659 1.44171C8.13325 1.51393 8.2777 1.61115 8.39992 1.73337L14.2666 7.61671C14.5221 7.87226 14.6499 8.18615 14.6499 8.55837C14.6499 8.9306 14.5221 9.24449 14.2666 9.50004L9.49992 14.2667ZM8.54992 13.3334L13.3166 8.56671L7.43325 2.66671H2.66659V7.43337L8.54992 13.3334ZM4.33325 5.33337C4.61103 5.33337 4.84714 5.23615 5.04159 5.04171C5.23603 4.84726 5.33325 4.61115 5.33325 4.33337C5.33325 4.0556 5.23603 3.81949 5.04159 3.62504C4.84714 3.4306 4.61103 3.33337 4.33325 3.33337C4.05547 3.33337 3.81936 3.4306 3.62492 3.62504C3.43047 3.81949 3.33325 4.0556 3.33325 4.33337C3.33325 4.61115 3.43047 4.84726 3.62492 5.04171C3.81936 5.23615 4.05547 5.33337 4.33325 5.33337Z"
          fill="#FA7800"
        />
      </g>
    </svg>
  )
}

export default SellIcon
