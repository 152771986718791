import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import { CHEMISTRY_FIELD_NAME, IProps, mapFormToValue, TForm, validationSchema } from "./const";
import { ChemistryForm } from "./components/ChemistryForm";

/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const Chemistry: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const baseInititialValues = {
    [CHEMISTRY_FIELD_NAME.NAME]: null,
    [CHEMISTRY_FIELD_NAME.TYPE]: 'fertilizer',
  }

  const { api, toastStore, swrStore } = useStores();
  const { chemistryId, initialValues } = modalProps;

  const mutateDetail = chemistryId ?
    swrStore.mutators[SWR_KEYS.getChemistryById(chemistryId)] : null

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const promise = chemistryId
      ? api.regulatoryInfo.patchChemistryById(
        chemistryId,
        mapFormToValue(values),
      )
      : api.regulatoryInfo.postChemistry(mapFormToValue(values));

    promise
      .then(() => {
        mutateDetail && mutateDetail()
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail();
        swrStore.mutators[SWR_KEYS.getChemistriesList()]();
      });
  };

  const values = initialValues
    ? {
      ...baseInititialValues,
      ...initialValues,
    }
    : baseInititialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <ChemistryForm modalProps={modalProps} handleClose={handleClose} />
      )}
    />
  );
};

export default Chemistry;


