import React, { useState } from "react";
import { FastField, Field, Form, useFormikContext } from "formik";
import { useInfiniteScroll, useStores } from "@/hooks";
import { IProps, TForm, CULTIVAR_FIELD_NAME, LIST_TYPES } from "../../const";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { DatePicker } from "@mui/x-date-pickers";
import { SWR_KEYS } from "@/const";
import Autocomplete from "@/components/Autocomplete";

/**
 * Форма создания/редактирования культуры
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const CultivarForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore, api } = useStores();
  const form = useFormikContext<TForm>();

  const title = modalProps.cultivarId
    ? "Редактировать запись"
    : "Добавить запись";

  /**
   * Метод выбора даты
   * @param value
   */
  const handleChangeDate = (value: Date) => {
    form.setFieldValue(CULTIVAR_FIELD_NAME.RECEIPT_AT, value);
  };

  /**
   * Метод для изменения перечня
   * @param event
   */

  const handleChange = (event) => {
    form.setFieldValue(event.target.name, event.target.value);
  };

  /**
   * Список типов перечня
   */

  const renderItems = LIST_TYPES?.map((el) => (
    <MenuItem key={el.value} value={el.value}>
      {el.label}
    </MenuItem>
  ));

  const [searchDepartment, setSearchDepartment] = useState("");
  const swrKeyDepartment = SWR_KEYS.getDepartmentList();

  // gsu
  const getKeyDepartment = (index: number) => ({
    _key: swrKeyDepartment,
    page: index + 1,
    search: searchDepartment,
  });

  const departmentFetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getDepartmentList({
      page,
      search,
    });
  };

  const { data: dataDepartment, handleScroll: handleScrollDepartment } =
    useInfiniteScroll(getKeyDepartment, departmentFetcher, {
      revalidateFirstPage: false,
    });

  /**
   * Метод для изменения перечня
   * @param event
   */

  const handleChangeDepartment = (event, child) => {
    form.setFieldValue(CULTIVAR_FIELD_NAME.DEPARTMENT, child);
  };

  const CustomPaper = (props) => {
    return (
      <Paper
        sx={{
          marginBottom: "7px",
        }}
        elevation={1}
        {...props}
      />
    );
  };

  return (
    <>
      <Box p={3}>
        <Typography>Культуры</Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <Field name={CULTIVAR_FIELD_NAME.NAME}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Название'
                  fullWidth
                  size='small'
                  required
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[CULTIVAR_FIELD_NAME.NAME]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={CULTIVAR_FIELD_NAME.LATIN_NAME}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Латинское название'
                  fullWidth
                  size='small'
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[CULTIVAR_FIELD_NAME.LATIN_NAME]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={CULTIVAR_FIELD_NAME.UPOV_CODE}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Код УПОВ'
                  fullWidth
                  size='small'
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[CULTIVAR_FIELD_NAME.UPOV_CODE]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field type='checkbox' name={CULTIVAR_FIELD_NAME.IS_PROTECTED_GROUND}>
            {({ field }) => (
              <Box>
                <FormControlLabel
                  {...field}
                  checked={form.values.is_protected_ground}
                  control={<Checkbox />}
                  label='Защищенный грунт'
                />
              </Box>
            )}
          </Field>
          <Field name={CULTIVAR_FIELD_NAME.LIST_TYPE}>
            {({ field, meta }) => (
              <FormControl size='small' variant='outlined' fullWidth>
                <InputLabel id='select-label'>Тип перечня</InputLabel>
                <Select
                  {...field}
                  onChange={handleChange}
                  label='Тип перечня'
                  fullWidth
                  required
                  disabled={meta.isSubmitting}
                >
                  {renderItems}
                </Select>
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <FastField name={CULTIVAR_FIELD_NAME.RECEIPT_AT}>
            {({ field, meta }) => (
              <DatePicker
                name={field.name}
                value={field.value}
                onChange={handleChangeDate}
                views={["month", "day"]}
                label='Срок поставки до'
                slotProps={{
                  textField: {
                    size: "small",
                    error: meta.touched && !!meta.error,
                    helperText: meta.touched && meta.error,
                    onBlur: field.onBlur,
                    fullWidth: true,
                  },
                }}
              />
            )}
          </FastField>

          <Field name={CULTIVAR_FIELD_NAME.DEPARTMENT}>
            {({ field, meta }) => (
              <FormControl>
                <Autocomplete
                  {...field}
                  PaperComponent={CustomPaper}
                  onChange={handleChangeDepartment}
                  label={"Отдел"}
                  data={dataDepartment}
                  handleScroll={handleScrollDepartment}
                  search={setSearchDepartment}
                />

                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>

        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red' onClick={() => modalStore.close()}>
            отмена
          </Button>
          <Button
            type='submit'
            disabled={form.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </>
  );
};

export default CultivarForm;
