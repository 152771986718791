import { truncateString } from '@/utils/helpers'
import { Box, SxProps, Tooltip, Typography } from '@mui/material'
import React, { FC } from 'react'

interface IProps {
    title: string,
    sx: SxProps,
    text: string,
    textLength: number,
}

/**
 * Пеереиспользуемый компонент с регулируемой длинной строрки
 */

export const ShortText: FC<IProps> = ({
    title, 
    sx,
    text,
    textLength
}) => {
  return (
    <Tooltip title={title}>
    <Box sx={sx}>
        <Typography fontSize={14}>{truncateString(text, textLength)}</Typography>
    </Box>
  </Tooltip>
  )
}
