import React from "react";
import { Box, Grid } from "@mui/material";
import StatsGrid from "./StatsGrid";

import RegionCard from "./RegionCard";
import { IRegionMain } from "@/api/interfaces/responses";

/**
 * Рендерит основное содержимое страницы.
 */

interface IProps {
  renderData: IRegionMain
}
const MainContent:React.FC<IProps> = ({renderData}) => {

  return (
    <Box>
      <StatsGrid totals={renderData.totals} />
      <Grid container spacing={2} mt={1}>
        {renderData.regions.map((region) => (
          <Grid item xs={12} sm={6} md={4} key={region.id}>
            <RegionCard {...region}/>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MainContent;
