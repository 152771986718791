import React from "react";
import { Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Box, Button, Divider, } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME, IFormik } from "../const";
import { useStores } from "@/hooks";
import {
  VegetationCausesAutocomplete
} from "@/components/Autocompletes";
import {
  IVegetationCauseReq
} from "@/api/interfaces/requests";

interface IProps {
  q?: IVegetationCauseReq
  label?: string
}

const SelectVegetationCauseForm:React.FC<IProps> = ({q, label}) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<IFormik>();
  
  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <VegetationCausesAutocomplete
          name={FIELDS_NAME.VEGETATION_CAUSE} 
          q={q}
          label={label}
          required 
          />
      </Stack>
      <Divider/>
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red"
                onClick={() => modalStore.close()}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon/>}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default SelectVegetationCauseForm;
