import styled from "@emotion/styled";
import theme from "@/theme";
import { Theme } from "@emotion/react";
import { QueryResults } from "react-media";

export interface WrapperProps {
  theme?: Theme; // Замени Theme на тип своей темы
  matches?: QueryResults | undefined;
}

export const Wrapper = styled("div")<WrapperProps>(({ matches }) => ({
  display: "flex",
  alignItems: "stretch",
  height: "100%",
  justifyContent: "flex-start",
  flexDirection: matches?.medium || matches?.small ? "column" : "row"
  // backgroundColor: matches?.small ? theme.colors.small : theme.colors.large,
}));

export const LeftSideWrapper = styled("div")<WrapperProps>(({ matches }) => ({
  display: "flex",
  width: matches?.medium || matches?.small ? "100%" : "371px",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignContent: "center",
  //todo: ebat castil...
  borderRight:
    matches?.medium || matches?.small
      ? "none"
      : `1px solid ${theme.palette.backgroundColors.darkGray}`,
  borderBottom:
    matches?.medium || matches?.small
      ? `1px solid ${theme.palette.backgroundColors.darkGray}`
      : "none",
  alignItems: matches?.medium || matches?.small ? "center" : "flex-start"
}));

export const RightSideWrapper = styled("div")(() => ({
  display: "flex",
  padding: "var(--3, 24px) var(--none, 0px)",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "var(--5, 40px)",
  flex: "1 0 0",
  alignSelf: "stretch"
}));

export const VerticalWrapper = styled("div")<WrapperProps>(({ matches }) => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignContent: matches?.medium || matches?.small ? "center" : "flex-start",
  justifyContent: "space-between",
  alignItems: matches?.medium || matches?.small ? "center" : "flex-start",
  padding: "24px",
  height: "100%",
  width: "100%"
}));

export const NameWrapper = styled("div")<WrapperProps>(({ matches }) => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  alignContent: "flex-start",
  alignItems: matches?.medium || matches?.small ? "center" : "flex-start",
  height: "100%",
  marginBottom: "10px"
}));

export const WrapperLogout = styled("div")<WrapperProps>(({ matches }) => ({
  display: "flex",
  padding: "24px 0px",
  alignItems: matches?.medium || matches?.small ? "center" : "flex-start",
  gap: "26px"
  // alignSelf: "stretch"
}));

export const FormLayout = styled("div")<WrapperProps>(({ matches }) => ({
  display: "flex",
  padding: "var(--none, 24px)",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "var(--5, 20px)",
  flex: "1 0 0",
  alignSelf: "stretch",
  overflow: (() => {
    console.log("matches", matches);
    if (matches?.large && !matches?.medium && !matches?.small) {
      return "scroll-y";
    }
    return matches?.medium || matches?.small ? "visible" : "visible";
  })(),
  height: matches?.medium || matches?.small ? "fit-content" : "100%",
  minHeight: matches?.medium || matches?.small ? "620px" : "100%"
}));

export const ActionLayout = styled("div")(() => ({
  position: "absolute",
  bottom: "-24px",
  left: "-24px",
  width: "calc(100% + 48px)",
  display: "flex",
  padding: "var(--3, 24px) var(--3, 24px) 80px var(--3, 24px)",
  flexDirection: "column",
  alignItems: "flex-end",
  gap: "26px",
  alignSelf: "stretch",
  borderLeft: `1px solid var(--Stroke, ${theme.palette.backgroundColors.lightGray})`,
  borderTop: `1px solid var(--Stroke, ${theme.palette.backgroundColors.lightGray})`
}));

export const Form = styled("form")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "stretch",
  flexWrap: "nowrap"
}));
