import React, {
  useCallback,
  useMemo,
  useState
} from "react";
import {
  Box,
  Button,
  Divider,
  Step,
  StepIconProps,
  StepLabel,
  Typography,
  useMediaQuery
} from "@mui/material";
import CustomStep from "./components/Step";
import { Container } from "./styles";
import {
  StepperContext
} from "@/components/Stepper/context";
import StepContainer, {
  IStepContainerProps
} from "@/components/Stepper/components/StepContainer";
import {
  CircleStepIconRoot,
  ContentWrap,
  Root,
  Stepper,
  StepperWrap
} from "@/components/FormikStepper/styles";

const  CircleStepIcon = (props: StepIconProps) => {
  const { active, completed, className, icon } = props;

  return (
    <CircleStepIconRoot ownerState={{ active, completed }} className={className}>
      <Typography variant="caption">
        {icon}
      </Typography>
    </CircleStepIconRoot>
  );
}

export interface IStepper {
  children?: React.ReactNode;
  handleClose: () => void;
}

const CustomStepper = ({
  children,
  handleClose,
}: IStepper) => {
  const [step, setStep] = useState(0);
  const [completed, setCompleted] = useState(false);

  /** Компоненты шагов*/
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<IStepContainerProps>[];
  /** Текущий компонент шага*/
  const currentChild = childrenArray[step];

  const maxSteps = useMemo(() => childrenArray.length, [childrenArray.length]);
  const matches = useMediaQuery("(min-width:1024px)");
  const isLastStep = useMemo(
    () => step === childrenArray.length - 1,
    [childrenArray.length, step]
  );

  const handleBack = useCallback((): void => {
    setStep((s) => s - 1);
  }, []);

  const handleNext = () => {
    if(!isLastStep){
      setStep((s) => s + 1);
    }
  }

  const renderStepper = () => {
    return matches ? (
      <StepperWrap>
        <Box flexGrow={1} p={3}>
          <Stepper activeStep={step} orientation="vertical">
            {childrenArray.map((child, index) => (
              <Step
                key={child.props.label}
                completed={step > index || completed}
              >
                <StepLabel StepIconComponent={CircleStepIcon}>{child.props.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Divider/>
        <Box p={3} pb={4} sx={{
          display: "flex",
          justifyContent: "center"
        }}>
          <Button
            variant="contained"
            onClick={handleClose}
          >
            Закрыть
          </Button>
        </Box>
      </StepperWrap>
    ) : null;
  };

  const contextValue = {
    maxSteps,
    step,
    isLastStep,
    handleBack,
    handleNext,
    setCompleted,
  };

  return (
    <StepperContext.Provider value={contextValue}>
      <Root>
        {renderStepper()}
        <ContentWrap>
          <Container>
            {currentChild}
          </Container>
        </ContentWrap>
      </Root>
    </StepperContext.Provider>
  );
};

CustomStepper.Step = CustomStep;
CustomStepper.StepContainer = StepContainer;
export default CustomStepper;
