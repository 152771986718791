import React from "react";
import { Field, useFormikContext } from "formik";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import theme from "@/theme";
import { CloseIcon } from "@/components/icons";
import { INDICATOR_FIELD_NAME, TForm } from "../const";

export const HPVariantFieldArray: React.FC = () => {
  const form = useFormikContext<TForm>();

  const handleAdd = () => {
    form.setFieldValue(INDICATOR_FIELD_NAME.VARIANTS, [
      ...form.values.variants,
      {
        id: form.values.variants.length + 1,
        index: null,
        severity: "",
      },
    ]);
  };

  const deleteVariant = (i) => {
    if (form.values.variants.length > 1) {
      form.setFieldValue(
        INDICATOR_FIELD_NAME.VARIANTS,
        form.values.variants.filter((a) => a.id !== i.id),
      );
    }
  };

  return (
    <>
      {form.values.variants.map((i, index) => {
        return (
          <Box
            key={i.id}
            display="flex"
            flexWrap="nowrap"
            justifyContent="space-between"
            flexDirection={"row"}
            gap={"5px"}
            sx={{
              border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
              borderRadius: "4px",
              padding: "10px",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Field
                  name={`${INDICATOR_FIELD_NAME.VARIANTS}.${index}.severity`}
                >
                  {({ field, meta }) => (
                    <FormControl required>
                      <TextField
                        {...field}
                        size="small"
                        required
                        sx={{ width: "auto" }}
                        value={
                          form.values.variants[index]
                            .severity
                        }
                        onBlur={form.handleBlur}
                        label={`Значение признака`}
                        onChange={form.handleChange}
                        error={meta.touched && !!meta.error}
                        helperText={
                          meta.touched && meta.error
                        }
                        InputProps={{
                          sx: {
                            width: "450px",
                          },
                        }}
                      />
                    </FormControl>
                  )}
                </Field>
              </Grid>
            </Grid>
            <Button
              disableRipple
              disableTouchRipple
              disabled={form.values.variants.length === 1}
              sx={{
                minWidth: "25px",
                ml: "10px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => deleteVariant(i)}
              startIcon={<CloseIcon />}
            />
          </Box>
        );
      })}

      <Button
        color="blue"
        size="large"
        startIcon={<AddOutlinedIcon />}
        variant="outlined"
        onClick={handleAdd}
        fullWidth
      >
        Добавить вариант признака
      </Button>
    </>
  );
};
