import { IRegionItem } from "@/api/interfaces/responses";
import yup from "@/utils/yup";

export const FIELDS_NAME = {
  REGION: "region",
} as const;

export const defaultValues = {
  [FIELDS_NAME.REGION]: null,
};

export interface IFormik {
  [FIELDS_NAME.REGION]: IRegionItem | null;
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.REGION]: yup.mixed().required(),
});