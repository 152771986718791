import React from "react";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";
import { Box, IconButton, Typography } from "@mui/material";
import NavigateBeforeIcon
  from "@mui/icons-material/NavigateBefore";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useParams } from "react-router-dom";

/**
 * хедер для страницы добавления факта
 * @returns React.FC
 */
const HeaderAddFact: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`${ROUTES_PATHS.offBudgetFact}?type=fact`);
  };

  const title = id ? "Редактировать факт" : "Добавить факт"

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <IconButton onClick={handleBack}>
        <NavigateBeforeIcon />
      </IconButton>
      <Box>
        <Typography fontSize={24} fontWeight={500} variant="h3" noWrap>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default observer(HeaderAddFact);
