import { useEffect, useState } from "react";

import { DependencyList } from "react";

export const useDebounceEffect = (
  fn: () => void,
  waitTime: number,
  deps?: DependencyList
) => {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
};

/**
 * @hook useDebounce для отложенной записи значения
 * @param value   значение: string
 * @param delay   время задержки в мс: number
 *
 * @returns       значение: string
 */

const useDebounce = (value, delay = 300): string => {
  const [delayedValue, setDelayedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDelayedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return delayedValue;
};

export default useDebounce;
