import yup from "@/utils/yup";
import { IWeatherCultivarFieldWork } from "@/api/interfaces/responses";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    workId?: number;
    initialValues?: IWeatherCultivarFieldWork;
    id?: number;
  };
  onSubmit?: () => void;
}


export enum WEATHER_WORK_FIELD_NAME {
  WEATHER_CONDITION = "weather_condition",
  CULTIVAR_FIELD_WORK = "cultivar_field_work",
}

export interface TForm {
    [WEATHER_WORK_FIELD_NAME.WEATHER_CONDITION]: string;
    [WEATHER_WORK_FIELD_NAME.CULTIVAR_FIELD_WORK]: number;
}

export const mapValueToForm = (value) => {
    return {
      [WEATHER_WORK_FIELD_NAME.WEATHER_CONDITION]: value?.weather_condition || '',
    
    }
  };

  export const mapFormToValue = (values: TForm) => { 
    return {
      [WEATHER_WORK_FIELD_NAME.WEATHER_CONDITION]:values?.weather_condition,
      [WEATHER_WORK_FIELD_NAME.CULTIVAR_FIELD_WORK]: values?.cultivar_field_work,
    };
  };


export const validationSchema = yup.object().shape({
[WEATHER_WORK_FIELD_NAME.WEATHER_CONDITION]: yup.string().trim().required(),
});
