import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  CircularProgress,
  Button,
  ListItem,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { VARIANT_MODAL } from "@/apps/Modals/const";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { STEP_TITLES } from "@/pages/AddPlan/const";
import { IOffBudget } from "@/api/interfaces/requests";
import {
  useInfiniteScroll,
  useStores,
  useDebounceCallback,
} from "@/hooks";
import { SWR_KEYS } from "@/const";
import { resInterface } from "@/api/interfaces";
import EmptyItem from "@/components/EmptyItem";
import theme from "@/theme";
import { isNumber } from "@/utils/helpers";
import EffectivityItem from "@/apps/RightSidebar/templates/OffBudget/firstLevel/components/Effectivity/EffectivityItem";
import EffectivityMenuItem from "@/apps/RightSidebar/templates/OffBudget/firstLevel/components/Effectivity/EffectivityItemMenu";

interface IProps {
  data?: IOffBudget | null;
}

/**
 * Компонет для шага эффективности
 * */

const EfficiencyFormStep: React.FC<IProps> = ({
  data: offBudgetData,
}) => {
  const id = offBudgetData?.id ?? "";
  const { api, swrStore, modalStore } = useStores();
  const [yearValue, setYearValue] = useState<
    number | undefined
  >(undefined);

  const key = SWR_KEYS.getEffectivityList(id);

  const getKey = (index: number) => ({
    _key: key,
    page: index + 1,
    com_plan_fact: id,
    year: isNumber(yearValue) ? yearValue : undefined,
  });

  const {
    data,
    handleScroll,
    isLoadingMore,
    isLoading,
    mutate,
  } = useInfiniteScroll<resInterface.IEffectivityList>(
    getKey,
    api.offBudget.getEffectivityList,
    {
      revalidateFirstPage: false,
    },
  );

  useEffect(() => {
    swrStore.addMutator(key, mutate);
  }, []);

  const onSubmitCreation = () => {
    mutate();
  };

  const handleAddEffectivity = () => {
    modalStore.open(VARIANT_MODAL.ADD_EFFECTIVITY, {
      com_plan_fact: id,
      onSubmit: onSubmitCreation,
    });
  };

  const handleSearchYear = useDebounceCallback(
    (e: Record<string, any>) => {
      setYearValue(e?.$y);
      mutate();
    },
    1100,
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Stack spacing={3}>
      <Typography variant="h3">
        {STEP_TITLES.EFFICIENCY}
      </Typography>
      <Box
        sx={{
          overflowY: "scroll",
          width: "100%",
          height: "400px",
        }}
        p={1}
        onScroll={handleScroll}
      >
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <DatePicker
            value={
              yearValue
                ? dayjs().year(yearValue)
                : undefined
            }
            name={"year"}
            onChange={handleSearchYear}
            label="Год"
            openTo="year"
            views={["year"]}
            slotProps={{
              textField: {
                size: "small",
                sx: {
                  "& .MuiOutlinedInput-root": {
                    width: "100%",
                    maxWidth: "300px",
                  },
                },
              },
            }}
          />
          {data.length > 0 && (
            <Button
              sx={{
                width: "40px",
                height: "40px",
                minWidth: "auto",
                padding: 0,
              }}
              color={"blue"}
              size={"medium"}
              variant={"outlined"}
              onClick={handleAddEffectivity}
            >
              <AddOutlinedIcon />
            </Button>
          )}
        </Stack>
        {data?.length > 0 ? (
          <>
            <Box pt={2}>
              {data.map((item) => (
                <Stack
                  key={item.id}
                  pl={1}
                  pr={1}
                  sx={{
                    zIndex: 2,
                    backgroundColor: "white",
                    paddingRight: "24px",
                    border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
                    borderRadius: "4px",
                    marginBottom: "10px",
                  }}
                >
                  <Stack
                    direction={"row"}
                    pt={"10px"}
                    pb={"24px"}
                    pl={"10px"}
                    flexWrap={"nowrap"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{
                      zIndex: 2,
                      backgroundColor: "white",
                    }}
                  >
                    <Typography variant="h4">
                      {`${item.year.toString()} год`}
                    </Typography>
                    <Box pl={1}>
                      <EffectivityMenuItem
                        comPlanFactId={+id}
                        fullData={item}
                      />
                    </Box>
                  </Stack>
                  <ListItem
                    sx={{
                      flexDirection: "column",
                      justifyContent: "space-between",
                      border: "none",
                      gap: "3px",
                    }}
                  >
                    <EffectivityItem item={item} />
                  </ListItem>
                </Stack>
              ))}
            </Box>
            {isLoadingMore ? (
              <Stack
                pt={2}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress />
              </Stack>
            ) : (
              <Button
                sx={{
                  marginTop: "24px",
                  width: "100%",
                }}
                color={"blue"}
                size={"large"}
                startIcon={<AddOutlinedIcon />}
                variant={"outlined"}
                onClick={handleAddEffectivity}
              >
                Добавить показатели эффективности
              </Button>
            )}
          </>
        ) : (
          <EmptyItem
            title="Заполните данные по эффективности"
            buttonText="Добавить показатели эффективности"
            onClick={handleAddEffectivity}
          />
        )}
      </Box>
    </Stack>
  );
};
export default EfficiencyFormStep;
