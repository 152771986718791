import {
  resInterface,
} from "@/api/interfaces";

export interface ISubjectEmail {
  id?: number;
  email: string;
}
export interface ISubjectPhone {
  id?: number;
  phone: string;
  name?: string;
}

export interface ISubjectAddress {
  id: number;
}

export interface TForm {
  [FIELD_NAME.EMAILS]:  ISubjectEmail[],
  [FIELD_NAME.PHONES]: ISubjectPhone[];
  [FIELD_NAME.FIRST_NAME]: string;
  [FIELD_NAME.LAST_NAME]: string;
  [FIELD_NAME.COMPANY_NAME]: string;
  [FIELD_NAME.SECOND_NAME]: string;
  [FIELD_NAME.INN]: number | null;
  [FIELD_NAME.BANK_INN]: number | null;
  [FIELD_NAME.BANK_KPP]: string;
  [FIELD_NAME.SNILS]: number | null;
  [FIELD_NAME.SUBJECT_TYPE]: "individual" | "legal_entity";
  [FIELD_NAME.OKPO]: string;
  [FIELD_NAME.KPP]: string;
  [FIELD_NAME.OGRN]: string;
  [FIELD_NAME.BANK]: string;
  [FIELD_NAME.CHECKING_ACCOUNT]: string;
  [FIELD_NAME.BIK]: string;
  [FIELD_NAME.CORRESPONDENT_ACCOUNT]: string;
  [FIELD_NAME.ADDRESS]: ISubjectAddress | null;
  [FIELD_NAME.LEGAL_ADDRESS]: ISubjectAddress | null;
  [FIELD_NAME.ACTUAL_ADDRESS]: ISubjectAddress | null;
}

export const HANDBOOK_SUBJECT_TYPE = {
  individual: "individual",
  legal_entity: "legal_entity",
};

export const SUBJECT_FORM_STEPS = {
  FIRST: "first",
  SECOND: "second",
  THIRD: "third",
} as const;

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    key: string;
    subjectId?: number;
    initialValues?: resInterface.ISubjectFull;
    handleAccept?: (
      payload: resInterface.ISubjectFull,
    ) => resInterface.ISubjectFull;
  };
  onSubmit?: () => void;
}

export interface ISubjectNavProps {
  step: "first" | "second" | "third";
  handleClickFirstStep: () => void;
  handleClickSecondStep: () => void;
  handleClickThirdStep: () => void;
}

export enum FIELD_NAME {
  SUBJECT_TYPE = "subject_type",

  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  SECOND_NAME = "second_name",
  SNILS = "snils",
  COMPANY_NAME = "company_name",
  OGRN = "ogrn",
  INN = "inn",
  KPP = "kpp",
  OKPO = "okpo",
  ADDRESS = "address",
  LEGAL_ADDRESS = "legal_address",
  ACTUAL_ADDRESS = "actual_address",

  BANK = "bank",
  BIK = "bik",
  BANK_INN = "bank_inn",
  BANK_KPP = "bank_kpp",
  CHECKING_ACCOUNT = "checking_account",
  CORRESPONDENT_ACCOUNT = "correspondent_account",

  PHONES = "phones",
  EMAILS = "emails",
}


export const initial =  {
  [FIELD_NAME.EMAILS]:  [{ id: 0, email: "" }],
  [FIELD_NAME.PHONES]: [{ id: 0, phone: "" }],
  [FIELD_NAME.FIRST_NAME]: '',
  [FIELD_NAME.LAST_NAME]: '',
  [FIELD_NAME.COMPANY_NAME]: '',
  [FIELD_NAME.SECOND_NAME]: '',
  [FIELD_NAME.INN]: null,
  [FIELD_NAME.BANK_INN]: null,
  [FIELD_NAME.BANK_KPP]: '',
  [FIELD_NAME.SNILS]: null,
  [FIELD_NAME.SUBJECT_TYPE]: '',
  [FIELD_NAME.OKPO]: '',
  [FIELD_NAME.KPP]: '',
  [FIELD_NAME.OGRN]: '',
  [FIELD_NAME.BANK]: '',
  [FIELD_NAME.CHECKING_ACCOUNT]: '',
  [FIELD_NAME.BIK]: '',
  [FIELD_NAME.CORRESPONDENT_ACCOUNT]: '',
  [FIELD_NAME.ADDRESS]: null,
  [FIELD_NAME.LEGAL_ADDRESS]: null,
  [FIELD_NAME.ACTUAL_ADDRESS]: null,
}
