import { PixelCrop } from "react-image-crop";

const TO_RADIANS = Math.PI / 180;

/**
 * Generates a preview of an image on a canvas with specified crop, scale, and rotation.
 *
 * @param {HTMLImageElement} image - The source image.
 * @param {HTMLCanvasElement} canvas - The target canvas for rendering the preview.
 * @param {PixelCrop} crop - The crop parameters for the image.
 * @param {number} [scale=1] - The scale factor for the image. Default is 1.
 * @param {number} [rotate=0] - The rotation angle for the image in degrees. Default is 0.
 *
 * @throws {Error} Throws an error if the 2d context for the canvas is not available.
 */
export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export const getCroppedImageBlob = (
  image: HTMLImageElement,
  crop: { width: number; height: number; x: number; y: number }
): Promise<Blob | string> => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    // Вычисляем масштабные коэффициенты для пропорционального обрезания
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Установите размеры холста в соответствии с crop
    canvas.width = crop.width;
    canvas.height = crop.height;

    // Обрежьте изображение с сохранением пропорций
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob((blob: Blob) => {
      return resolve(blob);
    }, "image/jpeg");
    // resolve(base64String);
  });
};