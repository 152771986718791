import React, { useEffect } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  ListItem,
} from "@mui/material";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useInfiniteScroll, useStores } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { resInterface } from "@/api/interfaces";
import { SWR_KEYS } from "@/const";
import theme from "@/theme";
import { TMenuProp } from "@/apps/RightSidebar/templates/OffBudget/firstLevel/interfaces";
import FEItem from "@/apps/RightSidebar/templates/OffBudget/firstLevel/components/InnerFinance/FinanceExpense/FEItem";
import EmptyItem from "@/components/EmptyItem";
import { EXPENSE_TYPE } from "@/apps/RightSidebar/templates/OffBudget/firstLevel/const";

const ExpensesContainer: React.FC<TMenuProp> = observer(({ renderData }) => {
  const { api } = useStores();
  const { modalStore, swrStore } = useStores();
  const swrKey = SWR_KEYS.getExpenseList();
  const planId = renderData?.id;

  const getKey = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    com_plan_fact: planId,
    type: EXPENSE_TYPE.FINANCIAL_EXPENSES,
  });

  const { data, handleScroll, isLoadingMore, isLoading, mutate } =
    useInfiniteScroll<resInterface.IExpenseList>(
      getKey,
      api.offBudget.getExpenseList
    );

  const onSubmitCreation = () => {
    mutate();
  };

  useEffect(() => {
    swrStore.addMutator(swrKey, mutate);
  }, []);

  const handleAddCost = () => {
    modalStore.open(VARIANT_MODAL.ADD_COST, {
      id: planId,
      type: EXPENSE_TYPE.FINANCIAL_EXPENSES,
      onSubmit: onSubmitCreation,
    });
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Box pt={2} onScroll={handleScroll}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h4">Распределение расходов</Typography>
        {data.length > 0 && (
          <Button
            sx={{
              width: "40px",
              height: "40px",
              minWidth: "auto",
              padding: 0,
            }}
            color={"blue"}
            size={"medium"}
            variant={"outlined"}
            onClick={handleAddCost}
          >
            <AddOutlinedIcon />
          </Button>
        )}
      </Stack>
      {data?.length > 0 ? (
        <>
          <Box pt={2}>
            {data.map((item) => (
              <ListItem
                key={item.id}
                sx={{
                  gap: 1,
                  justifyContent: "space-between",
                  borderBottom: "1px solid black",
                  borderColor: theme.palette.blackAndWhite.stroke,
                }}
              >
                <FEItem id={planId} item={item} mutate={mutate} />
              </ListItem>
            ))}
          </Box>
          {isLoadingMore ? (
            <Stack pt={2} alignItems={"center"} justifyContent={"center"}>
              <CircularProgress />
            </Stack>
          ) : (
            <Button
              sx={{
                marginTop: "24px",
                width: "100%",
              }}
              color={"blue"}
              size={"large"}
              startIcon={<AddOutlinedIcon />}
              variant={"outlined"}
              onClick={handleAddCost}
            >
              Добавить cтатью
            </Button>
          )}
        </>
      ) : (
        <EmptyItem
          title="Заполните данные по расходам"
          buttonText="Добавить cтатью"
          onClick={handleAddCost}
        />
      )}
    </Box>
  );
});

export default ExpensesContainer;
