import React from "react";
import { SvgIcon } from "@mui/material";

const CollapseContentIcon = () => {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24"
           fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1950_15461)">
          <mask id="mask0_1950_15461"
                maskUnits="userSpaceOnUse" x="0" y="0"
                width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_1950_15461)">
            <path
              d="M11 13V19H9V15H5V13H11ZM15 5V9H19V11H13V5H15Z"
              fill="currentColor"/>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1950_15461">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default CollapseContentIcon;