import { SxProps, Theme } from "@mui/material";

// стили для компонентов пагинации

export const buttonStyles = (theme: Theme): SxProps => ({
  minWidth: "32px",
  minHeight: "32px",
  color: theme.palette.blackAndWhite.black,
});

export const boxStyles = (): SxProps => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0",
  flexWrap: "wrap",
});

export const blockStyles = (): SxProps => ({
  display: "flex",
  alignItems: "center",
});

export const currentPageStyles = (theme: Theme): SxProps => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  minWidth: "32px",
  minHeight: "32px",
});

export const pageStyles = (theme: Theme): SxProps => ({
  minWidth: "32px",
  minHeight: "32px",
  color: theme.palette.blackAndWhite.black,
});

export const numberPageStyles = (theme: Theme): SxProps => ({
  display: "flex",
  alignItems: "center",
  gap: "6px",

  "& .Mui-focusVisible": {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },

  "& .MuiTouchRipple-root": {
    color: "transparent",
  },
});
