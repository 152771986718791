import React from "react";
import { Dayjs } from "dayjs";
import { useParams } from "react-router-dom";
import {
  Form,
  useFormikContext,
  FastField,
  Field
} from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  FormHelperText,
  TextField
} from "@mui/material";
import {
  CultivarAutocomplete,
  RegionAutocomplete,
  BranchAutocomplete,
  PlotAutoComplete,
} from "@/components/Autocompletes";
import { FIELDS_NAME, IFormik, } from "../../const";
import {
  IRegionItem,
  IBranchItem,
  IPlot,
} from "@/api/interfaces/responses";
import WorkList from "../WorkList";

const TechMapPlan = () => {
  const formik = useFormikContext<IFormik>();
  const { id } = useParams()

  const handleRegionClear = () => {
    void formik.setFieldValue(FIELDS_NAME.REGION, "");
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };

  const handleChangeRegion = (payload: IRegionItem) => {
    void formik.setFieldValue(FIELDS_NAME.REGION, payload);
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };

  const handleChangeBranch = (payload: IBranchItem) => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, payload);
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };

  const handleBranchClear = () => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };

  const handlePlotClear = () => {
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };

  const handleChangePlot = (payload: IPlot) => {
    void formik.setFieldValue(FIELDS_NAME.PLOT, payload);
  };

  const handleChangeYear = (value: Dayjs) => {
    void formik.setFieldValue(FIELDS_NAME.YEAR, value);
  };

  return (
    <Form noValidate>
      <Box p={3} sx={{ maxWidth: "1020px" }}>
        <Typography variant="h3" marginBottom="20px">
          Данные тех карты
        </Typography>
        <Grid container spacing="20px">

          <Grid item sm={6} xs={12}>

          <Field name={FIELDS_NAME.NAME}>
            {({ field, meta }) => (
              <FormControl
                fullWidth
              >
                <TextField
                  {...field}
                  type="text"
                  label="Название"
                  fullWidth
                  size="small"
                  required
                  error={meta.touched && !!meta.error}
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>

          </Grid>

          <Grid item sm={6} xs={12}>
            <FastField name={FIELDS_NAME.YEAR}>
              {({ field, meta }) => (
                <FormControl
                  fullWidth
                >
                  <DatePicker
                    name={field.name}
                    value={field.value}
                    onChange={handleChangeYear}
                    label="Год"
                    openTo="year"
                    views={["year"]}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        required: true,
                        error: meta.touched && !!meta.error,
                        onBlur: field.onBlur, 
                      }
                    }}
                  />
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>

              )}
            </FastField>
          </Grid>

          <Grid item sm={6} xs={12}>
            <RegionAutocomplete name={FIELDS_NAME.REGION}
              handleChange={handleChangeRegion}
              onClear={handleRegionClear}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <BranchAutocomplete name={FIELDS_NAME.BRANCH}
              q={{ region: formik.values.region?.id }}
              handleChange={handleChangeBranch}
              onClear={handleBranchClear}
              disabled={!formik.values.region}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <PlotAutoComplete name={FIELDS_NAME.PLOT}
              handleChange={handleChangePlot}
              q={{ branch: formik.values.branch?.id }}
              onClear={handlePlotClear}
              disabled={!formik.values.branch}
              required
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <CultivarAutocomplete
              name={FIELDS_NAME.CULTIVAR} required />
          </Grid>

        </Grid>

        {
          !id && (
            <Box mt={4}>
              <FastField name={FIELDS_NAME.WORKS}>
                {({ field, meta }) => (
                  <FormControl
                    fullWidth
                    {...field}
                  >
                    <WorkList/>

                    <FormHelperText
                      error={meta.touched && !!meta.error}
                    >
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                )}
              </FastField>
            </Box>
          )
        }

        <Box display="flex" justifyContent="space-between"
          mt={5}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
          >
            Сохранить
          </Button>
        </Box>

      </Box>
    </Form>
  )

}

export default TechMapPlan