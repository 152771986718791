import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import { WeatherDataForm } from "./components/WeatherDataForm";
import { IProps, mapFormToValue, mapValueToForm, TForm, validationSchema } from "./const";
import useSWR from "swr";


/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const AddWeatherData: React.FC<IProps> = ({ handleClose, modalProps }) => {
    const { api, toastStore, swrStore } = useStores();
    const initialValues = modalProps.initialValues;
    const {id, day, fieldId } = modalProps

    const baseInitialValues = {
        location: fieldId,
        date: day,
        maxtemp_c: '',
        mintemp_c: '',
        avgtemp_c: '',
        totalprecip_mm: '',
        totalsnow_cm: '',
        avghumidity: '',
        condition: '',
        maxwind_kph: '',
        soil_surface_min_temp: ''
    }

    const KEY_SWR = !id && SWR_KEYS.getWeatherHistoryForForm(fieldId,day)
    
    const {
        data,
    } = useSWR(fieldId && day ? KEY_SWR : null , 
        () => api.agriculturalLabour.getWeatherHistoryForForm(fieldId, day),
    );

    const forecast = data?.detail?.forecast?.forecastday[0]?.day

    const mutateDetail = id ? swrStore.mutators[SWR_KEYS.getWeatherHistoryById(id)] :  null

    const handleSubmit = (
        values: TForm,
        helpers: FormikHelpers<FormikValues>,
    ) => {
        helpers.setSubmitting(true);

        const promise =  
        id ? api.weather.patchWeather(id, mapFormToValue(values)) : api.weather.postWeather(mapFormToValue(values));

        promise
            .then(() => {
                mutateDetail && mutateDetail()
                handleClose();
            })
            .catch((error: AxiosError) => {
                const errors = error.response?.data;
                if (errors) {
                    helpers.setTouched(errors).then(() => {
                        helpers.setErrors(errors);
                    });
                } else {
                    const errorMessage = errorToString(error);
                    toastStore.createToast({
                        type: TOAST_TYPES.ALERT,
                        toastProps: {
                            message: errorMessage,
                            severity: "error",
                        },
                    });
                }
            })
            .finally(() => {
                helpers.setSubmitting(false);
                mutateDetail && mutateDetail()
            });
    };

    const values = id
        ? {
            ...baseInitialValues,
            ...mapValueToForm(initialValues),
          }
        : { ...baseInitialValues,
                location: fieldId,
                date: day,
                maxtemp_c: forecast?.maxtemp_c || '',
                mintemp_c: forecast?.mintemp_c || '',
                avgtemp_c: forecast?.avgtemp_c || '',
                totalprecip_mm: forecast?.totalprecip_mm ?? '',
                totalsnow_cm: forecast?.totalsnow_cm ?? '',
                avghumidity: forecast?.avghumidity || '',
                condition: forecast?.condition?.text || '',
                maxwind_kph: forecast?.maxwind_kph || '',
                soil_surface_min_temp: ''
            }
        ;

    return (
        <Formik
            initialValues={values}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
            component={() => (
                <WeatherDataForm modalProps={modalProps} handleClose={handleClose} />
            )}
        />
    );
};

export default AddWeatherData;


