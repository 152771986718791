import React from "react";
import { Box, Button, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Form, Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { IOrder } from "@/api/interfaces/responses";
import { OrderAutocomplete } from "@/components/Autocompletes";
import { IOrderList } from "@/api/interfaces/requests";

const FIELDS_NAME = {
  ORDER: "order",
} as const;

interface IFormik {
  [FIELDS_NAME.ORDER]: null | IOrder;
}

interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    fetcher: (payload: IOrder) => Promise<boolean>;
    q?: IOrderList;
  };
}

const DownloadOrder: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps,
}) => {
  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    if (values.order !== null) {
      helpers.setSubmitting(true);
      modalProps
        .fetcher(values.order)
        .then(() => {
          handlePop();
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    }
  };
  return (
    <Formik<IFormik>
      initialValues={{
        order: null,
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values, isSubmitting }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <Box p={3}>
            <Typography variant="h2">
              Выбрать приказ
            </Typography>
          </Box>
          <Divider />
          <Box p={3}>
            <OrderAutocomplete
              name={FIELDS_NAME.ORDER}
              q={modalProps.q}
              isModified
            />
          </Box>
          <Divider />
          <Box
            p={3}
            display="flex"
            justifyContent="space-between"
          >
            <Button
              variant="text"
              color="red"
              onClick={handleClose}
            >
              Отмена
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!values.order || isSubmitting}
            >
              Сохранить
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default DownloadOrder;
