import { SIDEBAR_STORE_KEY } from "@/apps/MainLayout/const";
import { Sidebar } from "./Sidebar";
import { SpinnerStore } from "./Spinner";
import { Tooltip } from "./Tooltip";
import { Api } from "@/api";
import { UserStore } from "./User";
import { RightSidebarStore } from "./RightSidebarStore";
import { ModalStore } from "./ModalStore";
import {
  QueryStringSidebarCollector
} from "./QueryStringSidebarCollector";
import { HeaderStore } from "./HeaderStore";
import { ToastStore } from "@/services/store/ToastStore";
import { SwrStore } from "./SwrStore";
import { MapStore } from "./MapStore";
import { PlanPlotStore } from "./PlanPlotStore";

export class RootStore {
  spinerStore: SpinnerStore
  sidebarStore: Sidebar
  userStore: UserStore
  tooltip: Tooltip
  api: Api
  rightSidebarStore: RightSidebarStore
  modalStore: ModalStore
  headerStore: HeaderStore
  toastStore: ToastStore
  swrStore: SwrStore
  queryStringSidebarCollector: QueryStringSidebarCollector
  mapStore: MapStore
  planPlotStore: PlanPlotStore

  constructor(url: string) {
    this.api = new Api(url)
    this.spinerStore = new SpinnerStore(this)
    this.sidebarStore = new Sidebar(this, SIDEBAR_STORE_KEY)
    this.tooltip = new Tooltip(this)
    this.userStore = new UserStore(this)
    this.rightSidebarStore = new RightSidebarStore(this)
    this.modalStore = new ModalStore(this)
    this.headerStore = new HeaderStore(this)
    this.toastStore = new ToastStore(this)
    this.swrStore = new SwrStore(this)
    this.queryStringSidebarCollector = new QueryStringSidebarCollector(this)
    this.mapStore = new MapStore(this)
    this.planPlotStore = new PlanPlotStore(this)
    // [INIT]
  }
}
