/**
 * описание полей в списке пермишеннов
 * ? во избежание опечаток
 * ? ключи для крудов
 */
export const PERMISSIONS = {
  //! Заявка
  registrationStatement: "registration.statement", // модуль
  registrationStatementstatusaccepted: "registration.statementstatusaccepted", // смена статуса
  registrationStatementstatusrefinement:
    "registration.statementstatusrefinement", // смена статуса
  registrationStatementstatusrejected: "registration.statementstatusrejected", // смена статуса
  registrationStatementstatusreview: "registration.statementstatusreview", // смена статуса
  trialTrial: "trial.trial",
  registrationDistributionlist: "registration.distributionlist",
  registrationSurvey: "registration.survey",
  registrationSubject: "registration.subject",
  registrationStatementduties: "registration.statementduties",
  registrationRemark: "registration.remark",
  registrationRegionstatement: "registration.regionstatement",
  //! Планирование
  planningPlanPlot: "planning.planplot",
  //! План использования земли
  agriculturalLabourCultivarField: "agricultural_labour.cultivarfield",
  agriculturalLabourCultivarFieldWork: "agricultural_labour.cultivarfieldwork",
  agriculturalLabourCultivarFieldWorkChemistry: "agricultural_labour.cultivarfieldworkchemistry",
  agriculturalLabourWeatherCultivarFieldWork: "agricultural_labour.weathercultivarfieldwork",
  agriculturalLabourCultivarFieldVariety: "agricultural_labour.cultivarfieldvariety",
  agriculturalLabourCultivarFieldVarietyRate: "agricultural_labour.cultivarfieldvarietyrate",
  agriculturalLabourSeedArrival: "agricultural_labour.seedarrival",
  //! Карта ГСУ
  agriculturalLabourLocation: 'agricultural_labour.location',
  agriculturalLabourField: 'agricultural_labour.field',
  weatherHistory: 'weather.weatherhistory',
  weatherYearHistory: 'weather.yearweatherhistory',

  //! С/Х работы
  agricultural_labourSeedArrival: 'agricultural_labour.seedarrival',
  agricultural_labourCultivarFieldVarietyStatusObservation: "agricultural_labour.cultivarfieldvarietystatusobservation",
  agricultural_labourCultivarFieldVarietyStatusNotSowed: "agricultural_labour.cultivarfieldvarietystatusnotsowed",
  agricultural_labourCultivarFieldVarietyStatusCompleted: "agricultural_labour.cultivarfieldvarietystatuscompleted",
  agricultural_labourCultivarFieldVarietyStatusAborted: "agricultural_labour.cultivarfieldvarietystatusaborted",
  agricultural_labourObservation: "agricultural_labour.observation",
  agricultural_labourObservationAdd: "agricultural_labour.observation",
  agricultural_labourObservationChange: "agricultural_labour.observation",
  agricultural_labourObservationDelete: "agricultural_labour.observation",
  agricultural_labourObservationIndicator: "agricultural_labour.observationindicator",
  agricultural_labourObservationIndicatorChange: "agricultural_labour.observationindicator",
  agricultural_labourObservationIndicatorDelete: "agricultural_labour.observationindicator",
  agricultural_labourObservationindicatorAdd: "agricultural_labour.observationindicator",
  agricultural_labourObservationDisease: "agricultural_labour.observationdisease",
  agricultural_labourObservationDiseaseAdd: "agricultural_labour.observationdisease",
  agricultural_labourObservationDiseaseChange: "agricultural_labour.observationdisease",
  agricultural_labourObservationDiseaseDelete: "agricultural_labour.observationdisease",
  agricultural_labourObservationWeatherResistance: "agricultural_labour.observationweatherresistance",
  agricultural_labourProductivity: "agricultural_labour.productivity",
  //! Внебюджет
  extrabudgetaryComPlanFact:'extrabudgetary.complanfact',
  extrabudgetaryComCultivar:'extrabudgetary.comcultivar',
  extrabudgetaryComInvestment: 'extrabudgetary.cominvestment',
  extrabudgetaryComEffectiveness: 'extrabudgetary.comeffectiveness',
  extrabudgetaryComcultivarChemistry: 'extrabudgetary.comcultivarchemistry',
  extrabudgetaryComExpense: 'extrabudgetary.comexpense',
  extrabudgetaryComcultivarWork: 'extrabudgetary.comcultivarwork',
  extrabudgetaryComPlanFactStatusAgreed: 'extrabudgetary.complanfactstatusagreed',
  extrabudgetaryComPlanFactStatusApproved: 'extrabudgetary.complanfactstatusapproved',
  extrabudgetaryComPlanFactStatusRejected: 'extrabudgetary.complanfactstatusrejected',
  extrabudgetaryComPlanFactStatusRevision: 'extrabudgetary.complanfactstatusrevision',
  //! Справочники
  regulatory_infoMethodology: "regulatory_info.methodology",
  regulatory_infoDisease: "regulatory_info.disease",
  regulatory_infoAgriculturalmachinery: "regulatory_info.agriculturalmachinery",
  regulatory_infoCountry: "regulatory_info.country",
  regulatory_infoPost: "regulatory_info.post",
  regulatory_infoLightZone: "regulatory_info.lightzone",
  regulatory_infoRegion: "regulatory_info.region",
  regulatory_infoPlot: 'regulatory_info.plot',
  //! Отчеты
  //! Промежуточные модели
  regulatory_infoFile: "regulatory_info.file", //файлы в заявке
  regulatory_infoСultivar: "regulatory_info.cultivar", // культуры
  regulatory_infoVariety: "regulatory_info.variety", // сорта
  regulatory_infoEmployee: "regulatory_info.employee", // эксперты
  regulatory_infoAddress: "regulatory_info.address", // адреса
  regulatory_infoIndicatorMethodology: "regulatory_info.indicatormethodology",
  regulatory_infoVariantIndicatorMethodology: "regulatory_info.variantindicatormethodology",
} as const;

/**
 * вариант того какой доступ интересует
 * view - чтение
 * add - добавление
 * change - редактирование
 * delete - удаление
 */
export const PERMISSION_CRUD = {
  view: "view",
  add: "add",
  change: "change",
  delete: "delete"
} as const;
