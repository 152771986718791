import React, { useState } from "react";
import { AddEntity } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { useParams } from "react-router-dom";
import { FIELDS_NAME } from "@/pages/AddStatement/const";
import { PAGE_SIZE } from "@/const";
import { resInterface } from "@/api/interfaces";
import { IVariety } from "@/api/interfaces/responses";
import useToastContent, { TOAST_CONTENT_TYPE } from "@/hooks/useToastContent";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    currentCultivar: { id: number; name: string };
    initialValue?: resInterface.IVariety;
    key: string;
  };
}

/** Модалка добавления сорта для заявки */

const AddVariety: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const { id } = useParams();
  const { modalStore, api, toastStore, userStore } = useStores();
  const toastContent = useToastContent(TOAST_CONTENT_TYPE.SERVER_ERROR);
  const [isLoading, setIsLoading] = useState(false);
  const handleAccept = (value: resInterface.IVariety) => {
    setIsLoading(true);
    api.statement
      .patchStatementById(Number(id), { [FIELDS_NAME.VARIETY]: value.id })
      .then(() => {
        handleClose();
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getVarietyList({
      page,
      page_size: PAGE_SIZE,
      search,
      cultivar: modalProps.currentCultivar.id,
      first_ids: modalProps.initialValue?.id
    });
  };

  const handleSubmit = (payload: IVariety) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      ...modalProps,
      initialValue: payload
    });
  };
  const handleAdd = () => {
    modalStore.open(VARIANT_MODAL.CREATE_VARIETY, {
      onSubmit: handleSubmit,
      initialValue: { cultivar: modalProps.currentCultivar },
      isStatement: true,
      cultivarEditable: false
    });
  };
  const getLabel = (value: IVariety): string => value.name;
  const getMeta = (value: IVariety) => String(value.code);

  const isSort = userStore.isPermission(
    PERMISSIONS.regulatory_infoVariety,
    PERMISSION_CRUD.add
  );

  const props = {
    initialValue: modalProps.initialValue,
    key: [
      "GET_VARIETY",
      modalProps.currentCultivar.id,
      modalProps.initialValue?.id
    ],
    addButtonLabel: "Добавить сорт",
    isDisabledBtnAdd: isSort,
    tooltipText: "У вас недостаточно прав для добавления сорта",
    title: "Сорт",
    handleAccept,
    handleAdd,
    disabled: isLoading,
    getLabel: getLabel,
    getMeta: getMeta,
    fetcher
  };

  return (
    <AddEntity<resInterface.IVariety>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default AddVariety;
