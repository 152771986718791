/**
 * React component representing the StatementPage.
 * Displays a main layout with header content and a statement table.
 * Allows adding a new statement through a modal.
 *
 * @component
 * @returns {ReactElement} The rendered StatementPage component.
 */
import React, { ReactElement, useEffect, useLayoutEffect } from "react";
import { Box, useTheme } from "@mui/material";
import StatementTable from "./components/StatementTable";
import { tableWrapperStyle } from "./style";
import { useStores } from "@/hooks";
import useRightSidebar from "@/hooks/useRightSidebar";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";

/**
 * StatementPage functional component.
 *
 * @returns {ReactElement} The rendered component.
 */
const StatementPage = (): ReactElement => {
  const { handleCloseInstantly } = useRightSidebar();
  const theme = useTheme();
  const { headerStore } = useStores();

  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.STATEMENTS);
  }, []);

  // закрытие правого сайдбара при переходе на другую страницу
  useEffect(() => {
    return () => {
       handleCloseInstantly();
    };
  }, []);

  return (
    <Box sx={tableWrapperStyle(theme)}>
      <StatementTable />
    </Box>
  );
};

export default StatementPage;
