import React, { useMemo } from "react";
import dayjs from "dayjs";
import {
  DateRangePicker,
  DateRangePickerSlotsComponentsProps,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import {
  SingleInputDateRangeField,
  ruRU,
} from "@mui/x-date-pickers-pro";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

import { IFilter } from "@/interfaces";

import { isUndefined } from "@/utils/helpers";
import theme from "@/theme";
import { SxProps } from "@mui/material";

interface DateRangeFilterProps {
  filter: IFilter;
  onChange: (date: [Date | null, Date | null]) => void;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  filter,
  onChange,
}) => {
  const handleDateChange = (
    date: [Date | null, Date | null],
  ) => {
    onChange(date);
  };

  const formattedValue: [Date | null, Date | null] =
    useMemo(() => {
      const timeAfter: any = !isUndefined(
        filter.selectedValue[0],
      )
        ? dayjs(filter.selectedValue[0])
        : null;
      const timeBefore: any = !isUndefined(
        filter.selectedValue[1],
      )
        ? dayjs(filter.selectedValue[1])
        : null;
      return [timeAfter, timeBefore];
    }, [filter]);

  const sxProps: SxProps = [
    {
      flexGrow: 1,
      "& .MuiInputBase-root": {
        flexGrow: 1,
        width: "100%",
        minWidth: "280px",
      },
      "& .clearButton": {
        visibility: "visible",
        opacity: 1,
      },
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
      },
    },
  ];

  return (
    <DateRangePicker
      label={filter.label}
      value={formattedValue}
      onChange={handleDateChange}
      sx={sxProps}
      localeText={
        ruRU.components.MuiLocalizationProvider.defaultProps
          .localeText
      }
      format="DD.MM.YYYY"
      slotProps={
        {
          field: { clearable: true, size: "small" },
          textField: {
            InputProps: {
              endAdornment:
                formattedValue[0] ||
                formattedValue[1] ? null : (
                  <CalendarTodayOutlinedIcon
                    sx={{
                      color:
                        theme.palette.blackAndWhite.black60,
                    }}
                  />
                ),
            },
          },
        } as DateRangePickerSlotsComponentsProps<Date> // свойство size отсуствует в интерфейсе, но оно позволяет уменьшить размер инпута до необходимого
      }
      slots={{
        field: SingleInputDateRangeField,
      }}
    />
  );
};

export default DateRangeFilter;
