import React, {
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { useNavigate } from "react-router";
import { Box, CircularProgress } from "@mui/material";

import {
  STEP_LABELS,
  validationSchema,
} from "@/pages/AddOrderCultivar/const";
import { mapValueToForm } from "@/pages/AddOrderCultivar/utils";
import {
  FormikStepper,
  CultivarFormStep,
  PlotFormStep,
} from "@/pages/AddOrderCultivar/components";
import { FormikStep } from "@/pages/AddStatement/components/FormikStep";
import { isUndefined } from "@/utils/helpers";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";

/**
 * Страница добавления плана
 * @param headerTitle - заголовок шапки
 * */

interface IProps {
  headerTitle?: string;
}

const AddOrderCultivarPage: React.FC<IProps> = ({
  headerTitle,
}) => {
  const { id } = useParams();
  const [step, setStep] = useState(0);
  const { headerStore, api } = useStores();
  const navigate = useNavigate();

  const fetcher = ([, id]: [
    string,
    string | undefined,
  ]) => {
    if (isUndefined(id)) return null;
    return api.regulatoryInfo.getOrderCultivarById(
      Number(id),
    );
  };

  const { data, isLoading, mutate } = useSWR(
    ["fetchOrderCultivar", id],
    fetcher,
  );

  useEffect(() => {
    if (step === 1) mutate();
  }, [step]);

  useLayoutEffect(() => {
    headerStore.setProps({
      title: headerTitle,
    });
    headerStore.setHeader(
      VARIANT_HEADER.ADD_ORDER_CULTIVAR,
    );
  }, []);

  const onSubmit = () => {
    navigate({
      pathname: ROUTES_PATHS.planning_crop,
    });
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <FormikStepper
      initialValues={mapValueToForm(data)}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
      step={step}
      setStep={setStep}
    >
      <FormikStep
        label={STEP_LABELS.CULTIVAR}
        validationSchema={validationSchema}
      >
        <CultivarFormStep />
      </FormikStep>
      <FormikStep label={STEP_LABELS.PLOT}>
        <PlotFormStep data={data} />
      </FormikStep>
    </FormikStepper>
  );
};

export default observer(AddOrderCultivarPage);
