import React from "react";
import { FieldArray } from "formik";
import { Box } from "@mui/system";
import { Stack, Button, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  FIELDS_NAME,
  IFormik
} from "@/pages/AddObservation/const";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";
import theme from "@/theme";
import { FormikSelect } from "@/components/Selects";
import {
  WeatherConditionsAutocomplete
} from "@/components/Autocompletes";
import CloseIcon from "@mui/icons-material/Close";

const ratingOptions = [
  { key: "", label: "Не выбрано" },
  { key: 1, label: "1" },
  { key: 2, label: "2" },
  { key: 3, label: "3" },
  { key: 4, label: "4" },
  { key: 5, label: "5" },
];


const WeatherConditions = () => {
  return (
    <Box>
      <Stack direction="column" spacing="20px">
        <Typography
          variant="h3">Метеоустойчивость</Typography>
        <FieldArray
          name={FIELDS_NAME.WEATHER_CONDITIONS}
          render={(helpers) => {
            const values = helpers.form.values[helpers.name] as IFormik["weather_resistances"];
            return (
              <Stack direction="column" spacing={1.2}>
                {values.map((_, index) => {
                  return (
                    <Stack direction="column" key={index}
                           sx={{
                             padding: "10px",
                             border: `1px solid ${theme.palette.blackAndWhite.stroke}`
                           }}>
                      <Box mb="20px" display="flex" justifyContent="space-between" alignItems="center">
                        <Typography
                          variant="p14Medium">{`Неблагоприятное условие ${index + 1}`}</Typography>
                        <IconButton onClick={() => helpers.remove(index)}>
                          <CloseIcon sx={{color: theme.palette.red.main}}/>
                        </IconButton>
                      </Box>
                      <Box mb="10px">
                        <WeatherConditionsAutocomplete name={`${helpers.name}.${index}.${FIELDS_NAME.WEATHER_CONDITION}`} required/>
                      </Box>
                      <FormikSelect
                        options={ratingOptions}
                        name={`${helpers.name}.${index}.${FIELDS_NAME.RATING}`}
                        label="Оценка (балл)"
                        fullWidth
                        required
                      />
                    </Stack>
                  );
                })}
                <Button
                  color={"blue"}
                  size={"large"}
                  startIcon={<AddOutlinedIcon/>}
                  variant={"outlined"}
                  onClick={() => helpers.push({
                    [FIELDS_NAME.WEATHER_CONDITION]: null,
                    [FIELDS_NAME.RATING]: ""
                  })}
                >
                  Добавить Неблагоприятное условие
                </Button>
              </Stack>
            );
          }}
        />
      </Stack>
    </Box>
  );
};

export default WeatherConditions;