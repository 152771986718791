import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { AGREEMENT_KEYS, AGREEMENT_LABELS } from "../const";
import { TAgreementKey } from "../interfaces";
import { AgreementTypeCell, CounterpartyCell, DateCell, EditingCellAgreement, ExecutiveCell } from "../templates/Agreement/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const AgreementColumns: IColumn<TAgreementKey>[] = [
  {
    label: AGREEMENT_LABELS.agreement_number,
    key: AGREEMENT_KEYS.agreement_number,
    contentHeader: AGREEMENT_LABELS.agreement_number,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: AGREEMENT_LABELS.counterparty__name,
    key: AGREEMENT_KEYS.counterparty__name,
    contentHeader: AGREEMENT_LABELS.counterparty__name,
    bodyCellProps: {
      render: CounterpartyCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: AGREEMENT_LABELS.agreement_type__name,
    key: AGREEMENT_KEYS.agreement_type__name,
    contentHeader: AGREEMENT_LABELS.agreement_type__name,
    bodyCellProps: {
      render: AgreementTypeCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: AGREEMENT_LABELS.agreement_date,
    key: AGREEMENT_KEYS.agreement_date,
    contentHeader: AGREEMENT_LABELS.agreement_date,
    bodyCellProps: {
      render: DateCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: AGREEMENT_LABELS.expiry_date,
    key: AGREEMENT_KEYS.expiry_date,
    contentHeader: AGREEMENT_LABELS.expiry_date,
    bodyCellProps: {
      render: DateCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: AGREEMENT_LABELS.executive,
    key: AGREEMENT_KEYS.executive,
    contentHeader: AGREEMENT_LABELS.executive,
    bodyCellProps: {
      render: ExecutiveCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: AGREEMENT_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellAgreement,
      styles: cellButtons,
    },
  },
];
