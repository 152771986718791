import React, { FC, useRef, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router";
import {
  SxProps,
  Theme,
  useTheme
} from "@mui/material/styles";
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography
} from "@mui/material";
import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from "@mui/icons-material";
import { createPortal } from "react-dom";

import AliceCarousel, {
  Responsive
} from "react-alice-carousel";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import {
  AgricultureIcon,
  BookIcon,
  LabProfileIcon,
  ShoppingCartIcon,
  TodayIcon,
} from "@/components/icons";
import { NAVBAR_LABELS } from "@/const";
import MapOutlinedIcon
  from "@mui/icons-material/MapOutlined";
import HomeOutlinedIcon
  from "@mui/icons-material/HomeOutlined";

/**
 * Styles for NavLink.
 * @param theme - Material-UI theme.
 * @returns SxProps
 */
export const navLinkStyle = (theme: Theme): SxProps => ({
  borderRadius: "4px",
  color: theme.palette.blackAndWhite.black,
  maxWidth: "fit-content",
  "&.active": {
    backgroundColor: theme.palette.backgroundColors.avatarBg,
    color: theme.palette.green.main,

    h5: {
      color: theme.palette.primary.main,
      fontWeight: "bold"
    },

    ".MuiListItemIcon-root": {
      color: theme.palette.primary.main
    }
  }
});

export const boxStyle = (theme: Theme): SxProps => ({
  padding: theme.spacing(1, 0),
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "100%",
  bottom: "0px",
  position: "absolute",
  backgroundColor: theme.palette.blackAndWhite.white,
  zIndex: 1202,
  [theme.breakpoints.down(1024)]: {
    padding: theme.spacing(0, 0, 1, 0)
  }
})

/**
 * Previous button component for the carousel.
 * @param onClick - Click handler function.
 * @returns IconButton
 */
const CustomPrevButton: FC<{
  onClick?: () => void
}> = ({ onClick = () => { } }) => (
  <IconButton onClick={onClick}>
    <ChevronLeftIcon />
  </IconButton>
);

/**
 * Next button component for the carousel.
 * @param onClick - Click handler function.
 * @returns IconButton
 */
const CustomNextButton: FC<{
  onClick?: () => void
}> = ({ onClick = () => { } }) => (
  <IconButton onClick={onClick}>
    <ChevronRightIcon />
  </IconButton>
);

/**
 * Mock data for the sidebar items.
 */
const mockSidebarData = [
  {
    label: NAVBAR_LABELS.main,
    href: ROUTES_PATHS.main,
    icon: <HomeOutlinedIcon />
  },
  {
    label: NAVBAR_LABELS.statements,
    href: ROUTES_PATHS.statements,
    icon: <LabProfileIcon />
  },
  {
    label: NAVBAR_LABELS.planning,
    href: ROUTES_PATHS.planning,
    icon: <TodayIcon />
  },
  {
    label: NAVBAR_LABELS.agriculturalWork,
    href: ROUTES_PATHS.agriculturalWork,
    icon: <MapOutlinedIcon />
  },
  {
    label: NAVBAR_LABELS.agriculturalWorkDoc,
    href: ROUTES_PATHS.agriculturalWorkDoc,
    icon: <AgricultureIcon />
  },
  {
    label: NAVBAR_LABELS.offBudget,
    href: ROUTES_PATHS.offBudget,
    icon: <ShoppingCartIcon />
  },
  {
    label: NAVBAR_LABELS.handbook,
    href: `${ROUTES_PATHS.handbook}?module=1`,
    icon: <BookIcon />
  },
  // {
  //   label: NAVBAR_LABELS.reports,
  //   href: ROUTES_PATHS.reports,
  //   icon: <AnalyticsIcon />
  // },
  {
    label: NAVBAR_LABELS.profile,
    href: ROUTES_PATHS.profile,
    icon: <AccountCircleOutlinedIcon />
  },
];

/**
 * Sidebar component for the main layout.
 */
const MobileNavbar: FC = () => {
  const theme = useTheme();
  const location = useLocation()
  const activeIndex = useMemo(() => {
    const currentRoute = location.pathname
    const activeItem = mockSidebarData.findIndex(
      (item) => item.href.split("?")[0] === currentRoute
    );
    return activeItem || 0
  },[location.pathname]);
  const carouselRef = useRef<any>(null);

  const rootElement = document.getElementById("root"); // Assuming "root" is the ID of your root element

  /**
   * Handles the start of dragging.
   * @param e - Drag event.
   */
  const handleDragStart = (e: {
    preventDefault: () => any
  }) =>
    e.preventDefault();

  /**
   * Responsive configuration for the carousel.
   */
  const responsiveConfig: Responsive = {
    0: {
      items: 2,
      itemsFit: "fill"
    },
    375: {
      items: 3,
      itemsFit: "fill"
    },
    600: {
      items: 5
    }
  };

  /**
   * Generates the list items for the sidebar.
   */
  const items = mockSidebarData.map(({
    label,
    href,
    icon
  }) => (
    <ListItem
      onDragStart={handleDragStart}
      title={label}
      disablePadding
      sx={{
        maxWidth: "200px",
        justifyContent: "center",
      }}
    >
      <ListItemButton component={NavLink} to={href}
        sx={navLinkStyle(theme)}>
        <Stack alignItems={"center"}
          justifyContent={"center"}>
          <ListItemIcon
            sx={{ fontSize: "16px" }}>{icon}</ListItemIcon>
          <Typography variant="h5" sx={{
            opacity: 0.5,
            fontSize: "10px"
          }}>
            {label}
          </Typography>
        </Stack>
      </ListItemButton>
    </ListItem>
  ));

  return rootElement
    ? createPortal(
      <Box
        sx={boxStyle(theme)}
      >
        <Divider />
        <AliceCarousel
          ref={carouselRef}
          responsive={responsiveConfig}
          disableDotsControls
          mouseTracking
          activeIndex={activeIndex}
          items={items}
          renderPrevButton={() => <CustomPrevButton />}
          renderNextButton={() => <CustomNextButton />}
          infinite={true}
        />
      </Box>,
      rootElement
    )
    : null;
};

export default MobileNavbar;
