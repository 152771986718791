import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import {
  cellButtons,
  cellPointText,
  headerCellStyle,
} from "../style";

import { ORG_HEADER_LABELS, ORG_KEYS } from "../const";

import { TOrgKeys } from "../interfaces";

import { AddressCell, EditingCellsOrg } from "../templates/Organizations/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const OrgColumns: IColumn<TOrgKeys>[] = [
  {
    label: ORG_HEADER_LABELS.name,
    key: ORG_KEYS.name,
    contentHeader: ORG_HEADER_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: ORG_HEADER_LABELS.address,
    key: ORG_KEYS.address,
    contentHeader: ORG_HEADER_LABELS.address,
    bodyCellProps: {
      render: AddressCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: ORG_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsOrg,
      styles: cellButtons,
    },
  },
];
