import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import {
  accordionSummaryClasses,
  Button,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import FormAccordion
  from "@/components/Accordions/FormAccordion";
import {
  accordionStyles
} from "@/pages/AddStatement/components/DocumentsStep/styles";
import DocumentListItem
  from "@/pages/AddSeedArrival/components/DocumentListItem";
import { Add as AddIcon } from "@mui/icons-material";
import { Theme } from "@mui/material/styles";
import { useStores } from "@/hooks";
import {
  THEME_MODAL,
  VARIANT_MODAL
} from "@/apps/Modals/const";
import {
  FIELDS_NAME,
  IFile,
  IFormik,
  serializeFile
} from "@/pages/AddSeedArrival/const";
import { FILES_TYPE } from "@/const";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import {
  SEED_ARRIVAL_STATUS
} from "@/pages/SeedAccounting/const";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

const stylesAccordion = (theme: Theme) => ({
  ...accordionStyles(theme),
  [`& .${accordionSummaryClasses.root}`]: {
    width: "100%",
    borderBottom: `1px solid ${theme.palette.blackAndWhite.stroke}`,
  }
});

const documents = [
  {
    id: 1,
    title: "Документы о сортовых и посевных качествах семян",
    type: FILES_TYPE.SEED_QUALITY
  },
  {
    id: 2,
    title: "Документы по ветеринарному и фитосанитарному надзору",
    type: FILES_TYPE.VETERINARY_AND_PHYTOSANITARY_SUPERVISION
  }
];

interface IProps {
  id?: number | string
  showTitle?: boolean
  status?: string
}

const Documents: React.FC<IProps> = ({ showTitle = true, status, ...props }) => {
  const { modalStore, userStore } = useStores();
  const params = useParams();
  const { api, toastStore } = useStores();
  const id = props.id || params.id

  const formik = useFormikContext<IFormik>();

  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };

  const onAccept = (file: IFile, type: string) => {
    const newFile = Object.assign(file, {
      uuid: uuidv4(),
      type
    });
    return formik.setFieldValue(FIELDS_NAME.FILE_LIST, [...formik.values.file_list, newFile]);
  };


  const onUpdate = (uuid: string, file: IFile, type: string) => {
    const newFile = Object.assign(file, {
      type
    });
    const values = formik.values.file_list.map(el => el.file.uuid === uuid ? newFile : el)
    return formik.setFieldValue(FIELDS_NAME.FILE_LIST, values);
  }

  const serializeFileToFormData = (file: IFile, type: string): FormData => {
    const formData = new FormData();
    if (file.file instanceof File) {
      formData.append(FIELDS_NAME.FILE, file.file);
    }
    formData.append(FIELDS_NAME.NAME, file.name);
    formData.append(FIELDS_NAME.NUMBER, file.number);
    formData.append(FIELDS_NAME.TYPE, type);
    if (file.receipt_date) {
      formData.append(FIELDS_NAME.RECEIPT_DATE, dayjs(file.receipt_date).toISOString());
    }
    return formData;
  };

  const createFile = async (id: number | string, file: IFile, type: string) => {
    const formData = serializeFileToFormData(file, type);
    return api.agriculturalLabour.addSeedArrivalFile(id, formData).then((data) => {
      return formik.setFieldValue(FIELDS_NAME.FILE_LIST, [...formik.values.file_list, serializeFile(data)]);
    }).catch((error) => {
      showToast(error);
    });
  };

  const updateFile = async ({ id, fileId, file, type }: {
    id: number | string,
    fileId: number,
    file: IFile,
    type: string
  }) => {
    const formData = serializeFileToFormData(file, type);
    return api.agriculturalLabour.patchSeedArrivalFileById(id, fileId, formData).then((data) => {
      const values = formik.values.file_list.map(file => file.file.id === data.id ? serializeFile(data) : file)
      return formik.setFieldValue(FIELDS_NAME.FILE_LIST, values);
    }).catch((error) => {
      showToast(error);
    });
  };

  const handleAddDocument = (type: string) => {
    modalStore.open(VARIANT_MODAL.ADD_DOCUMENT, {
      handleAccept: (file: IFile) => {
        return id ? createFile(id, file, type) : onAccept(file, type);
      },
      subtitle: "Добавить поступление семян",
      theme: THEME_MODAL.W_555
    });

  };

  const files = useMemo(() => formik.values.file_list.reduce((acc, el) => {
    if (acc[el.type]) {
      acc[el.type].push(el);
    } else {
      acc[el.type] = [el];
    }
    return acc;
  }, {}), [formik.values.file_list]);

  const deleteFileByUuid = (fileUuid: string) => {
    const values = formik.values.file_list.filter(el => el.file.uuid !== fileUuid);
    void formik.setFieldValue(FIELDS_NAME.FILE_LIST, values);
  };

  const deleteFileById = (fileId: number) => {
    const values = formik.values.file_list.filter(el => el.file.id !== fileId);
    void formik.setFieldValue(FIELDS_NAME.FILE_LIST, values);
  };

  const handleDelete = (file: IFile) => {
    if (id) {
      api.agriculturalLabour.deleteSeedArrivalFileById(id, file.file.id)
        .then(() => {
          deleteFileById(file.file.id);
        }).catch(error => {
          showToast(error);
        });
    } else {
      deleteFileByUuid(file.file.uuid as string);
    }
  };

  const handleEdit = (file: IFile, type: string) => {
    modalStore.open(VARIANT_MODAL.ADD_DOCUMENT, {
      handleAccept: (payload: IFile) => {
        return id ? updateFile({
          file: payload,
          type,
          id,
          fileId: file.file.id
        }) : onUpdate(file.file.uuid as string, payload, type);
      },
      initialValue: file,
      subtitle: "Добавить поступление семян",
      theme: THEME_MODAL.W_555
    });
  };

  const isAdd = userStore.isPermission(
    PERMISSIONS.regulatory_infoFile,
    PERMISSION_CRUD.add
  )

  const isDisabled = status === SEED_ARRIVAL_STATUS.PLANNED || status === SEED_ARRIVAL_STATUS.NOT_RECEIVED


  return (
    <Stack spacing={3} height="inherit">
      {
        showTitle && <Typography
          variant="h3">Документы</Typography>
      }
      <Stack direction="column" spacing="20px">
        {documents.map(doc => (
          <Stack key={doc.id} spacing={1.2}>
            <FormAccordion
              defaultExpanded={true}
              label={doc.title}
              sx={stylesAccordion}>
              <Stack pt={1.2} spacing={1.2}>
                {
                  Array.isArray(files[doc.type]) && files[doc.type].map(el => (
                    <DocumentListItem
                      key={el.file.id || el.uuid}
                      data={el}
                      hide={isDisabled}
                      handleDelete={() => handleDelete(el)}
                      handleEdit={() => handleEdit(el, doc.type)}
                    />))
                }
              </Stack>
            </FormAccordion>
            <Tooltip
              followCursor
              title={
                !isAdd ? "У вас недостаточно прав для добавления документа" : ""
              }
            >
              <>
                <Button
                  color="blue"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  fullWidth
                  onClick={() => handleAddDocument(doc.type)}
                  disabled={!isAdd || isDisabled}
                >
                  Добавить документ
                </Button>
              </>
            </Tooltip>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
export default Documents;