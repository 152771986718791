import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=86-113421&mode=design&t=wjp27yPmbYjfxv6D-0
const CollectionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66669 13.3332H16.6667V3.33317H15V9.1665L12.9167 7.9165L10.8334 9.1665V3.33317H6.66669V13.3332ZM6.66669 14.9998C6.20835 14.9998 5.81599 14.8366 5.4896 14.5103C5.16321 14.1839 5.00002 13.7915 5.00002 13.3332V3.33317C5.00002 2.87484 5.16321 2.48248 5.4896 2.15609C5.81599 1.8297 6.20835 1.6665 6.66669 1.6665H16.6667C17.125 1.6665 17.5174 1.8297 17.8438 2.15609C18.1702 2.48248 18.3334 2.87484 18.3334 3.33317V13.3332C18.3334 13.7915 18.1702 14.1839 17.8438 14.5103C17.5174 14.8366 17.125 14.9998 16.6667 14.9998H6.66669ZM3.33335 18.3332C2.87502 18.3332 2.48266 18.17 2.15627 17.8436C1.82988 17.5172 1.66669 17.1248 1.66669 16.6665V4.99984H3.33335V16.6665H15V18.3332H3.33335Z"
          fill="#F7A70A"
        />
      </svg>
    </SvgIcon>
  );
};

export default CollectionIcon;
