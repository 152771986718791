import React, { Fragment } from "react";
import { Field, Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";
import { IProps, TForm, FIELDS_NAME } from "../const";

import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { IAddress } from "@/api/interfaces/responses";
import AddChip from "@/components/Chips/AddChip";
import { VARIANT_MODAL } from "@/apps/Modals/const";

/**
 * Форма создания/редактирования культуры
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const OrganizationForm: React.FC<IProps> = ({
  modalProps,
}) => {
  const { modalStore } = useStores();
  const form = useFormikContext<TForm>();

  const { setModalPropsByKey } = modalStore;

  const title = modalProps.organizationId
    ? "Редактировать запись"
    : "Добавить запись";

  const handleOpenModalAddress = () => {
    modalStore.open(VARIANT_MODAL.ADD_ADDRESS, {
      onSubmit: handleAcceptAddress,
      onClose: handleAcceptAddress
    });
  };

  const handleAcceptAddress = (value: IAddress) => {
    setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...form.values,
        address: value,
        name: form.values.name
      },
    });
  };

  const removeSelectedAddress = () => {
    form.setFieldValue(FIELDS_NAME.ADDRESS, null);
  };

  return (
    <Fragment>
      <Box p={3}>
        <Typography>Организация</Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <Field name={FIELDS_NAME.NAME}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label="Название"
                  fullWidth
                  size="small"
                  required
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[FIELDS_NAME.NAME]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={FIELDS_NAME.ADDRESS}>
            {({ field, meta }) => (
              <FormControl required>
                <FormLabel>Адрес</FormLabel>
                <Box>
                  {field.value ? (
                    <Chip
                      size="small"
                      variant="outlined"
                      label={field.value.one_line}
                      onDelete={removeSelectedAddress}
                    />
                  ) : (
                    <AddChip
                      onClick={handleOpenModalAddress}
                    />
                  )}
                </Box>

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>

        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={form.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default OrganizationForm;
