
import { AWPLOTS_HEADER_LABELS, AW_PLOTS_KEYS } from "../const";

import { TAWPlotsKeys } from "../interfaces";

import {
  cell,
  cellGSU,
  headerCellStyle,
} from "../style";
import { BranchCells, EditingCells, GSUCells, RegionCells, NameCells } from "../components/AWCellComponents";
import { IColumn } from "@/apps/Table/interfaces";
import { cellButtons } from "@/apps/Table/style";


/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const columns: IColumn<TAWPlotsKeys>[] = [
  {
    label: AWPLOTS_HEADER_LABELS.gsu,
    key: AW_PLOTS_KEYS.gsu,
    contentHeader: AWPLOTS_HEADER_LABELS.gsu,
    bodyCellProps: {
      render: GSUCells,
      styles: cellGSU,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: AWPLOTS_HEADER_LABELS.name,
    key: AW_PLOTS_KEYS.name,
    contentHeader: AWPLOTS_HEADER_LABELS.name,
    bodyCellProps: {
      render: NameCells,
      styles: cell,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: AWPLOTS_HEADER_LABELS.region,
    key: AW_PLOTS_KEYS.region,
    contentHeader: AWPLOTS_HEADER_LABELS.region,
    bodyCellProps: {
      render: RegionCells,
      styles: cell,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: AWPLOTS_HEADER_LABELS.branch,
    key: AW_PLOTS_KEYS.branch,
    contentHeader: AWPLOTS_HEADER_LABELS.branch,
    bodyCellProps: {
      render: BranchCells,
      styles: cell,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: AW_PLOTS_KEYS.editing,
    label: '',
    contentHeader: '',
    bodyCellProps: {
      render: EditingCells,
      styles: cellButtons,
    },
  },
]
