import {
  FiltersType
} from "@/components/Filters/interfaces";
import { IFilter, IValue } from "@/interfaces";
import { Dispatch, SetStateAction } from "react";

interface IFilterWithParams extends IFilter {
  handleScroll?: (event: React.UIEvent<HTMLElement, UIEvent>) => void;
}

export const IN_REGISTER_FILTER: IValue[] = [
  {
    key: "true",
    label: "В реестре",
  },
  {
    key: "false",
    label: "Не в реестре",
  },
];

export const getFilters = (
  cultivarTypeValue?: IValue[],
  selectedCultivarTypeValue?: IValue,
  handleScroll?: (
    event: React.UIEvent<HTMLElement, UIEvent>,
  ) => void,
  selectedType?: any,
  setValue?: Dispatch<SetStateAction<string>>,
): IFilterWithParams[] => {
  return [
    {
      name: "culture",
      label: "Культура",
      type: FiltersType.autoComplete,
      selectedValue: selectedCultivarTypeValue,
      handleScroll: handleScroll,
      options: cultivarTypeValue,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
      setValue: setValue
    },
    {
      name: "in_register",
      label: "В реестре",
      type: FiltersType.autoComplete,
      selectedValue: selectedType,
      value: "",
      options: IN_REGISTER_FILTER,
      style: { width: "230px", flexGrow: 1 },
    }
  ];
};