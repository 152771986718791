import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Table from "@/apps/Table";
import { observer } from "mobx-react";
import { useQuery, useStores } from "@/hooks";
import { IPaginatedResult } from "@/api/interfaces/responses";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { SWR_KEYS } from "@/const";
import { IErrorResponse } from "@/interfaces";
import useSWR, { SWRResponse } from "swr";
import { isUndefined } from "swr/_internal";
import { IPropsSidebar, TEmployeeKeys } from "../../interfaces";
import AddIcon from "@mui/icons-material/Add";
import qs from "qs";
import Filters from "@/components/Filters";
import { Box, Button } from "@mui/material";

import { IFilter } from "@/interfaces";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { resInterface } from "@/api/interfaces";
import { EmployeeColumns } from "../../models/employee";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const getFilters = (): IFilter[] => {
  return [];
};

/**
 * Компонент, представляющий таблицу световых зон.
 * @returns {ReactElement}
 */
const EmployeeTable: React.FC<IPropsSidebar> = ({
  handleOpenRightSidebar,
}): ReactElement => {
  const { api, swrStore, modalStore, userStore } = useStores();
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [queryParams, setQueryParams] = useState<any>(query);

  // переменная которая показывает применены ли фильтры
  const isFilters: boolean = Object.keys(queryParams).length > 0;
  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering) ? query.ordering : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] = useState<string[]>(orgeringArray);

  const {
    data,
    error,
    isLoading,
    mutate,
  }: SWRResponse<
    IPaginatedResult<resInterface.IEmployeeList>,
    IErrorResponse
  > = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      date_range_after: queryParams.date_range_after,
      date_range_before: queryParams.date_range_before,
      search: queryParams.search,
      executor: queryParams.executor?.key,
      key: SWR_KEYS.getEmployeeList(),
    },
    api.regulatoryInfo.getEmployeeList,
  );

  const filters = getFilters();

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleOpenCreateModal = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.EMPLOYEE, { theme: THEME_MODAL.W_555 });
  };

  const handleChangeOrderBy = (value) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  useEffect(() => {
    setQueryParams(query);
  }, [location.search]);

  swrStore.addMutator(SWR_KEYS.getEmployeeList(), mutate);

  const canAddEmployee = userStore.isPermission(
    PERMISSIONS.regulatory_infoEmployee,
    PERMISSION_CRUD.add
  )

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters filters={filters} defaultSearch={query.search} />
        {canAddEmployee && 
        <Button
          sx={{
            whiteSpace: "nowrap",
            flexShrink: "0",
          }}
          variant='contained'
          startIcon={<AddIcon />}
          onClick={handleOpenCreateModal}
        >
          Добавить запись
        </Button>
        }
      </Box>

      <Table<resInterface.IEmployeeList, TEmployeeKeys>
        columns={EmployeeColumns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={"Добавить запись"}
        textEmptyTableWithFilter={"Не найдено ни одной записи"}
        textEmptyTable={"Еще не создано ни одной записи"}
        onRowClick={handleOpenRightSidebar}
        emptyTableAddEntity={handleOpenCreateModal}
      />
    </>
  );
};

export default observer(EmployeeTable);
