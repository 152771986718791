import React from "react";
import {
  Formik,
  FormikHelpers,
  FormikValues,
} from "formik";
import { AxiosError } from "axios";

import CreateDistributionForm from "./CreateDistributionForm";
import { IDistributionStatement } from "@/api/interfaces/responses";
import { TOAST_TYPES } from "@/apps/Toast";

import {
  TForm,
  mapFormToValue,
  mapValueToForm,
  validationSchema,
} from "./const";

import { useStores } from "@/hooks";

import { errorToString } from "@/utils/helpers";

interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    idStatement: number;
    dateCulture: string;
    initialValue?: IDistributionStatement;
    onSubmit?: () => void;
  };
}

/**
 * Компонент формы создания/редактирования разнарядки
 * @param modalProps - пропсы формы
 * @returns
 */
const AddDistribution: React.FC<IProps> = ({
  modalProps,
  handleClose,
}) => {
  const {
    initialValue,
    idStatement,
    onSubmit,
    dateCulture,
  } = modalProps || {};
  const { api, toastStore } = useStores();

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);
    const payload = mapFormToValue(values, idStatement);
    const promise = initialValue?.id
      ? api.statement.patchDistribution(
          initialValue.id,
          payload,
        )
      : api.statement.postDistribution(payload);

    promise
      .then(() => {
        onSubmit && onSubmit();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        handleClose();
        helpers.setSubmitting(false);
      });
  };

  const formInitialValues = mapValueToForm(
    initialValue,
    dateCulture ?? undefined,
  );

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      component={() => (
        <CreateDistributionForm modalProps={modalProps} />
      )}
    />
  );
};

export default AddDistribution;
