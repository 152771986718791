import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  IAgriculturalLabourWork
} from "@/api/interfaces/responses";
import theme from "@/theme";
import EditMenu from "../EditMenu";

type IProps = {
  data: IAgriculturalLabourWork;
  itemIndex:number
};

const WorkListItem: React.FC<IProps> = ({ data,itemIndex }) => {

  return (
    <Stack
      sx={{
        cursor: "pointer"
      }}
      direction={"column"}
    >
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          borderRadius: "4px",
          padding: "10px",
          border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
          transition: ".6s",
          "&:hover": {
            borderColor: theme.palette.primary.main
          }
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} alignItems={"center"} spacing={1.2} mb={1.2}>
            <Typography variant="h4">{data?.type?.work_type}</Typography>
          </Stack>
          <EditMenu itemIndex={itemIndex} />
        </Stack>

        <Stack direction="row" gap={2} spacing={1.2} alignItems="center">
          <Box width={"100%"}>
            <Typography
              variant="body2"
              color={theme.palette.blackAndWhite.gray}
            >
              Эталонная норма выработки:
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.blackAndWhite.black}
            >
              {data.reference_production_rate}
            </Typography>
          </Box>

          <Box width={"100%"}>
            <Typography
              variant="body2"
              color={theme.palette.blackAndWhite.gray}
            >
              Сменная норма выработки:
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.blackAndWhite.black}
            >
              {data.shift_production_rate}
            </Typography>
          </Box>

        </Stack>
      </Box>
    </Stack>
  );
};

export default WorkListItem;
