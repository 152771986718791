import {
    FiltersType
  } from "@/components/Filters/interfaces";
  import { IFilter, IValue, TRangeDateFormatted } from "@/interfaces";
import { Dispatch, SetStateAction } from "react";
  
  interface IFilterWithParams extends IFilter {
    handleScroll?: (event: React.UIEvent<HTMLElement, UIEvent>) => void;
  }
  
  export const getFilters = (
    typeValue?:any,
    selectedTypeValue?: IValue,
    handleScroll?: (
      event: React.UIEvent<HTMLElement, UIEvent>,
    ) => void,
    setValue?: Dispatch<SetStateAction<string>>,
    dateRange?: TRangeDateFormatted,
  ): IFilterWithParams[] => {
    return [
      {
        name: "agreement_date",
        label: "Дата договора",
        type: FiltersType.datesRange,
        selectedValue: dateRange,
        value: "",
        style: { minWidth: "230px", flexGrow: 1 },
      },
      {
        name: "type",
        label: "Тип",
        type: FiltersType.autoComplete,
        selectedValue: selectedTypeValue,
        handleScroll: handleScroll,
        options: typeValue,
        value: "",
        style: { minWidth: "230px", flexGrow: 1 },
        setValue: setValue
      },
    ];
  };