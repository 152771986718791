import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=86-113421&mode=design&t=FG1Hx7YXDBnXGCGT-0
const LabIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="11.666656"
        height="16.666626"
        viewBox="0 0 11.6667 16.6666"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          id="labs"
          d="M5.83334 16.6666C4.68054 16.6666 3.69791 16.2604 2.88541 15.4479C2.07291 14.6354 1.66666 13.6528 1.66666 12.5L1.66666 5C1.20834 5 0.815979 4.83679 0.489594 4.51038C0.163208 4.18408 0 3.79163 0 3.33337L0 1.66663C0 1.20837 0.163208 0.815918 0.489594 0.489624C0.815979 0.163208 1.20834 0 1.66666 0L10 0C10.4583 0 10.8507 0.163208 11.1771 0.489624C11.5035 0.815918 11.6667 1.20837 11.6667 1.66663L11.6667 3.33337C11.6667 3.79163 11.5035 4.18408 11.1771 4.51038C10.8507 4.83679 10.4583 5 10 5L10 12.5C10 13.6528 9.59375 14.6354 8.78125 15.4479C7.96875 16.2604 6.98611 16.6666 5.83334 16.6666ZM1.66666 3.33337L10 3.33337L10 1.66663L1.66666 1.66663L1.66666 3.33337ZM5.83334 15C6.375 15 6.86111 14.8438 7.29166 14.5312C7.72223 14.2188 8.02084 13.8195 8.1875 13.3334L5.83334 13.3334L5.83334 11.6666L8.33334 11.6666L8.33334 10.8334L5.83334 10.8334L5.83334 9.16663L8.33334 9.16663L8.33334 8.33337L5.83334 8.33337L5.83334 6.66663L8.33334 6.66663L8.33334 5L3.33334 5L3.33334 12.5C3.33334 13.1945 3.57639 13.7847 4.0625 14.2709C4.54861 14.757 5.13889 15 5.83334 15Z"
          fill="currentColor"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
};

export default LabIcon;
