import React from "react";
import { Formik } from "formik";

import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { AxiosError } from "axios";
import { IAddEffectivityProps } from "./utils/types";
import { validationSchema } from "./utils/scheme";
import { SWR_KEYS } from "@/const";
import { FIELD_NAME } from "./utils/const";

import { AddEffectivityForm } from "./components/AddEffectivityForm";

const AddEffectivity: React.FC<IAddEffectivityProps> = ({ modalProps }) => {
  const { api, toastStore, modalStore, swrStore } = useStores();

  const mutateDetail =
    swrStore.mutators[SWR_KEYS.getEffectivityList(modalProps.com_plan_fact)];

  const initialValues = Object.assign(
    {
      [FIELD_NAME.COM_PLAN_FACT]: modalProps.com_plan_fact,
      [FIELD_NAME.YEAR]: (modalProps.year && new Date(modalProps.year)) || null,
      [FIELD_NAME.REVENUE]: null,
      [FIELD_NAME.DIRECT_EXPENSES]: null,
      [FIELD_NAME.PRODUCTION_EXPENSES]: null,
      [FIELD_NAME.ADMINISTRATIVE_EXPENSES]: null,
      [FIELD_NAME.TAXES]: null,
      [FIELD_NAME.CAPITAL_INVESTMENTS]: null,
    },
    modalProps.initialValues
  );


  const handleSubmit = (values: any) => {

    const promise = modalProps.id
      ? api.offBudget.editEffectivity(modalProps.id.toString(), {
        [FIELD_NAME.YEAR]: values.year,
        [FIELD_NAME.REVENUE]: values.revenue,
        [FIELD_NAME.DIRECT_EXPENSES]: values.direct_expenses,
        [FIELD_NAME.PRODUCTION_EXPENSES]: values.production_expenses,
        [FIELD_NAME.ADMINISTRATIVE_EXPENSES]:
          values.administrative_expenses,
        [FIELD_NAME.TAXES]: values.taxes,
        [FIELD_NAME.CAPITAL_INVESTMENTS]: values.capital_investments,
      })
      : api.offBudget.addEffectivity(values);
    promise.then(() => {
      mutateDetail && mutateDetail();
      modalStore.pop();
    });
    promise.catch((error: AxiosError) => {
      toastStore.createToast({
        type: TOAST_TYPES.ALERT,
        toastProps: {
          message: errorToString(error),
          severity: "error",
        },
      });
    });
  };


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnMount
      validationSchema={validationSchema}
      component={() => <AddEffectivityForm modalProps={modalProps} />}
    />
  );
};

export default AddEffectivity;
