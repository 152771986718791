import React, { useState } from "react";
import { AddEntity } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { FILTERS_TYPE, PAGE_SIZE } from "@/const";
import {
  IBranchItem,
  IRegionItem,
} from "@/api/interfaces/responses";

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    onSubmit: (payload: IBranchItem) => void
    initialValue?: IBranchItem
    key: string
  };
}

const Q = {
  STANDARD_BRANCH: "standard_branch",
  PAGE: "page",
  SEARCH: "search",
  REGION: "region"

} as const;

interface IArgs {
  [Q.PAGE]: number,
  [Q.SEARCH]: string,
  [Q.REGION]: number,
  [Q.STANDARD_BRANCH]: number | null | undefined,
}

/** Модалка с множественным выбором Филиалов с фильтром по региону */

const SelectBranch: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps
}) => {
  const { api } = useStores();
  const [q, setQ] = useState<Record<string, any>>({});
  const handleAccept = (value: IBranchItem) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handlePop();
  };
  const fetcher = (q: IArgs) => {
    return api.regulatoryInfo.getPlanBranchesList({
      page: q.page,
      page_size: PAGE_SIZE,
      search: q.search,
      region: q.region,
    });
  };

  const getLabel = (value: IBranchItem): string => value.name;

  const filters = [
    {
      type: FILTERS_TYPE.AUTOCOMPLETE,
      name: Q.REGION,
      label: "Регион",
      fetcher: api.regulatoryInfo.getRegionList,
      handleChange: (region: IRegionItem | null) => {
        setQ(prev => ({
          ...prev,
          [Q.REGION]: region ? region.id : undefined
        }));
      }
    },
  ];

  const props = {
    initialValue: modalProps.initialValue,
    key: ["GET_VARIETY", ],
    listTitle: "Филиалы",
    filters,
    q,
    title: "Выберите филиал",
    handleAccept,
    getLabel: getLabel,
    fetcher,
  };

  return (
    <AddEntity<IBranchItem>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectBranch;