import React, { useState } from "react";
import {
  Button,
  InputAdornment,
  InputProps,
  SxProps,
  TextField as TextFieldMui,
  useTheme,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { buttonStyles } from "./styles";

type TSize = "medium" | "small";
type TVariant = "filled" | "outlined" | "standard";
type TAutocomplete = "off" | "on";

interface SearchFilterProps {
  sx?: SxProps;
  size?: TSize;
  label?: string;
  defaultValue?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: TVariant;
  fullWidth?: boolean;
  value?: string;
  InputProps?: Partial<InputProps>;
  autoComplete?: TAutocomplete;
  id?: string;
  placeholder?: string;
}

/**
 * инпут для поля search
 *
 * @param filter - информация о фильтре (имя, тип и тд.), в том числе коллбек на запрос следующей страницы
 * @param onChange - коллбек работающий в момент изменения данных
 * @returns
 */
const TextField: React.FC<SearchFilterProps> = ({ ...props }) => {
  const theme = useTheme();
  const [value, setValue] = useState(props.defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    props.onChange(event); // вызов onChange события
  };

  const handleClear = () => {
    setValue("");
    props.onChange({
      target: { value: "" },
    } as React.ChangeEvent<HTMLInputElement>); // вызов onChange события с пустым значением
  };
  const inputPropsValue = props?.InputProps

  return (
    <TextFieldMui
      {...props}
      variant="outlined"
      value={value}
      onChange={handleChange}
      InputProps={{
          ...inputPropsValue,
          endAdornment: value && (
          <Button sx={buttonStyles(theme)} onClick={handleClear}>
            <InputAdornment position="end">
              <ClearIcon />
            </InputAdornment>
          </Button>
        ),
      }}
    />
  );
};

export default TextField;
