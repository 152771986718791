import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  IPlotMainLocation
} from "@/api/interfaces/responses";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";

interface IProps {
  data: IPlotMainLocation[];
}

const LATable: React.FC<IProps> = ({ data }) => {
  const navigate = useNavigate();
  const handleClick = (id: string | number): void => {
    navigate(
      `${ROUTES_PATHS.landAreas}/${id}/?tab=generalInfo`,
    );
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Название участка</TableCell>
            <TableCell align="right">
              Кадастровый номер
            </TableCell>
            <TableCell align="right">Адрес</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              sx={{ ":hover": { cursor: "pointer" } }}
              key={row.id}
              onClick={() => handleClick(row.id)}
            >
              <TableCell component="th" scope="row">
                <Box display="flex" alignItems="center"
                     flexWrap="nowrap">
                  <Box sx={{
                    width: 18,
                    height: 18,
                    borderRadius: 1,
                    background: row.colour
                  }} mr={1}/>
                  <Typography variant="body2">
                    {row.name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="right">
                {row.cadastral_number || "-"}
              </TableCell>
              <TableCell align="right">
                {row.actual_address?.one_line || "-"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LATable;
