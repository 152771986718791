import React from "react";
import { SvgIconProps, SvgIcon } from "@mui/material";

const EditDocumentIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_6242_2981"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <rect width="20" height="20" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_6242_2981)">
          <path
            d="M11.6667 18.3334V15.7709L16.2709 11.1875C16.3959 11.0625 16.5348 10.9722 16.6875 10.9167C16.8403 10.8611 16.9931 10.8334 17.1459 10.8334C17.3125 10.8334 17.4723 10.8646 17.625 10.9271C17.7778 10.9896 17.9167 11.0834 18.0417 11.2084L18.8125 11.9792C18.9237 12.1042 19.0105 12.2431 19.073 12.3959C19.1355 12.5486 19.1667 12.7014 19.1667 12.8542C19.1667 13.007 19.1389 13.1632 19.0834 13.3229C19.0278 13.4827 18.9375 13.625 18.8125 13.75L14.2292 18.3334H11.6667ZM12.9167 17.0834H13.7084L16.2292 14.5417L15.8542 14.1459L15.4584 13.7709L12.9167 16.2917V17.0834ZM5.00004 18.3334C4.54171 18.3334 4.14935 18.1702 3.82296 17.8438C3.49657 17.5174 3.33337 17.125 3.33337 16.6667V3.33335C3.33337 2.87502 3.49657 2.48266 3.82296 2.15627C4.14935 1.82988 4.54171 1.66669 5.00004 1.66669H11.6667L16.6667 6.66669V9.16669H15V7.50002H10.8334V3.33335H5.00004V16.6667H10V18.3334H5.00004ZM15.8542 14.1459L15.4584 13.7709L16.2292 14.5417L15.8542 14.1459Z"
            fill="#8392A1"
          />
          <path
            d="M11.6667 18.3334V15.7709L16.2709 11.1875C16.3959 11.0625 16.5348 10.9722 16.6875 10.9167C16.8403 10.8611 16.9931 10.8334 17.1459 10.8334C17.3125 10.8334 17.4723 10.8646 17.625 10.9271C17.7778 10.9896 17.9167 11.0834 18.0417 11.2084L18.8125 11.9792C18.9237 12.1042 19.0105 12.2431 19.073 12.3959C19.1355 12.5486 19.1667 12.7014 19.1667 12.8542C19.1667 13.007 19.1389 13.1632 19.0834 13.3229C19.0278 13.4827 18.9375 13.625 18.8125 13.75L14.2292 18.3334H11.6667ZM12.9167 17.0834H13.7084L16.2292 14.5417L15.8542 14.1459L15.4584 13.7709L12.9167 16.2917V17.0834ZM5.00004 18.3334C4.54171 18.3334 4.14935 18.1702 3.82296 17.8438C3.49657 17.5174 3.33337 17.125 3.33337 16.6667V3.33335C3.33337 2.87502 3.49657 2.48266 3.82296 2.15627C4.14935 1.82988 4.54171 1.66669 5.00004 1.66669H11.6667L16.6667 6.66669V9.16669H15V7.50002H10.8334V3.33335H5.00004V16.6667H10V18.3334H5.00004ZM15.8542 14.1459L15.4584 13.7709L16.2292 14.5417L15.8542 14.1459Z"
            fill="black"
            fillOpacity="0.2"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default EditDocumentIcon;