import { Box } from "@mui/material";
import React from "react";

export interface IAddEntity {
  modalProps: {
    id: string;
  };
}
/**
 * Модалка детальной информации заявки
 * */
const StatementDetail: React.FC<IAddEntity> = ({ modalProps }) => {
  const { id } = modalProps;
  return (
    <Box>
      <Box p={3}>id:{id}</Box>
    </Box>
  );
};

export default StatementDetail;
