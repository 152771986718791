import yup from "@/utils/yup";
import {
  IIndicator,
  IVariantMeth
} from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  INDICATOR: "indicator",
  INDICATOR_VARIANT: "indicator_variant",
  OFF_TYPE_PLANTS_NUMBER: "off_type_plants_number",
  UNFORMITY: "uniformity",
} as const;

export interface IFormik {
  [FIELDS_NAME.INDICATOR]: IIndicator | null,
  [FIELDS_NAME.INDICATOR_VARIANT]: IVariantMeth | null
  [FIELDS_NAME.OFF_TYPE_PLANTS_NUMBER]: number | string,
  [FIELDS_NAME.UNFORMITY]: boolean,
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.INDICATOR]: yup.mixed().required(),
  [FIELDS_NAME.INDICATOR_VARIANT]: yup.mixed().required(),
  [FIELDS_NAME.OFF_TYPE_PLANTS_NUMBER]: yup.number(),
  [FIELDS_NAME.UNFORMITY]: yup.mixed(),
});

export const initialValues = {
  [FIELDS_NAME.INDICATOR]: null,
  [FIELDS_NAME.INDICATOR_VARIANT]: null,
  [FIELDS_NAME.OFF_TYPE_PLANTS_NUMBER]: 0,
  [FIELDS_NAME.UNFORMITY]: true,
};

export const serializeValuesToForm = (values: {
  indicator: IIndicator | null,
  indicator_variant: IVariantMeth | null,
  off_type_plants_number: number | string,
  uniformity: boolean,
}) => {
  return {
    indicator: values.indicator ? {
      ...values.indicator,
      label: values.indicator.name
    } as IIndicator : null,
    indicator_variant: values.indicator_variant ? {
      ...values.indicator_variant,
      label: values.indicator_variant.variant.severity,
    } as IVariantMeth : null,
    off_type_plants_number: values.off_type_plants_number ? 
      values.off_type_plants_number : 0,
    uniformity: values.uniformity ?
      values.uniformity : false,
  };
};

export const UNFORMITY_VALUES = [
  {
    id: 1,
    value: true,
    label: "Да"
  },
  {
    id: 2,
    value: false,
    label: "Нет"
  },
]
