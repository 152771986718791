import React from "react";

import { Field, FieldArray } from "formik";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, FormControl, Grid, Stack, TextField } from "@mui/material";
import theme from "@/theme";
import { CloseIcon } from "@/components/icons";
import { PLOT_FIELD_NAME } from "../../const";
import { v4 as uuidv4 } from "uuid";

export const EmailField: React.FC = () => {
  return (
    <>
      <FieldArray name={PLOT_FIELD_NAME.EMAILS}>
        {({ name, form, push, remove }) => {
          return (
            <Stack>
              {form.values[name].map((item, index) => {
                return (
                  <Box
                    key={item.id}
                    display='flex'
                    flexWrap='wrap'
                    justifyContent='space-between'
                    gap='10px'
                    sx={{
                      border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
                      borderRadius: "4px",
                      padding: "10px",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <Grid container display='flex' xs={10.7}>
                      <Grid item width='100%'>
                        <Field name={`${name}.${index}.email`} >
                          {({ field, meta }) => (
                            <FormControl fullWidth>
                              <TextField
                                {...field}
                                error={meta.touched && !!meta.error}
                                helperText={meta.touched && meta.error}
                                label={`Электронная почта ${index + 1}`}
                                fullWidth
                                size='small'
                                type="text"
                              />
                            </FormControl>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Button
                      sx={{
                        minWidth: "25px",
                        ml: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => remove(index)}
                      startIcon={<CloseIcon />}
                    />

                  </Box>
                );
              })}
              <Button
                color='blue'
                size='large'
                startIcon={<AddOutlinedIcon />}
                variant='outlined'
                onClick={() => push({ email: '', id: uuidv4() })}
                fullWidth
              >
                Добавить почту
              </Button>
            </Stack>
          )
        }}
      </FieldArray>
    </>
  );
};
