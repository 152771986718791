import {
  apiInterface,
  reqInterface,
  resInterface,
} from "@/api/interfaces";
import AbstractApi from "../../abstract";
import {
  IPaginatedResult,
  IPlanning,
} from "@/api/interfaces/responses";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  IGeneratePlanningPlanReq,
  IPlanningCreateGroups,
  IPlanPlotReq,
  IPlanReq,
} from "@/api/interfaces/requests";
import { getCookie } from "@/utils/cookie";
import { TOKEN_KEYS } from "@/const";

class PlanningApi implements apiInterface.IPlanningApi {
  ctx: AbstractApi;

  constructor(ctx: AbstractApi) {
    this.ctx = ctx;
  }

  getPlanning = async (
    params: reqInterface.IPlanningListReq,
  ): Promise<
    IPaginatedResult<resInterface.IPlanningList>
  > => {
    const res = await this.ctx.get<
      reqInterface.IPlanningListReq,
      IPaginatedResult<resInterface.IPlanningList>
    >("/planning/plan/", params);
    return res.data;
  };

  postPlanning = async (params: IPlanReq) => {
    const res = await this.ctx.post<
      IPlanReq,
      AxiosResponse
    >("/planning/plan/", params);
    return res.data;
  };

  getPlanningById = async (id: number) => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IPlanning
    >(`/planning/plan/${id}/`);
    return res.data;
  };

  getPlanningPlot = async (params: Partial<IPlanning>) => {
    const res = await this.ctx.get<
      Partial<IPlanning>,
      IPaginatedResult<resInterface.IPlanPlot>
    >(`/planning/plan_plot/`, { ...params });
    return res.data;
  };

  postPlanningPlot = async (params: IPlanPlotReq[]) => {
    const res = await this.ctx.post<
      IPlanPlotReq[],
      AxiosResponse
    >("/planning/plan_plot/", params);
    return res.data;
  };

  getPlanningPlotById = async (id: number | string) => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IPlanPlot
    >(`/planning/plan_plot/${id}/`);
    return res.data;
  };

  deletePlanningPlot = async (id: number | string) => {
    const res = await this.ctx.delete<
      unknown,
      AxiosResponse
    >(`/planning/plan_plot/${id}/`);
    return res.data;
  };

  deletePlanningById = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      AxiosResponse
    >(`/planning/plan/${id}/`, {});
    return res.data;
  };

  getVarietyPlanPlot = async (
    params,
  ): Promise<
    IPaginatedResult<resInterface.IVarietyPlanPlot>
  > => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IVarietyPlanPlot>
    >("/planning/variety_plan_plot/", {
      ...params,
    });
    return res.data;
  };

  deleteVarietyPlanPlotById = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      AxiosResponse
    >(`/planning/variety_plan_plot/${id}/`, {});
    return res.data;
  };

  getPlanGroupPlotList = async (
    params,
  ): Promise<
    IPaginatedResult<resInterface.IPlanGroupPlot>
  > => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IPlanGroupPlot>
    >("/planning/plan_group_plot/", {
      ...params,
    });
    return res.data;
  };

  patchPlanningById = async (
    id: number | string,
    params: Partial<reqInterface.IPlanReq>,
    axiosParams: AxiosRequestConfig = {},
  ): Promise<any> => {
    const res = await this.ctx.patch<
      Partial<reqInterface.IPlanReq>,
      resInterface.ITrial
    >(`/planning/plan/${id}/`, params, axiosParams);
    return res.data;
  };

  postGeneratePlanningPlan = async (
    params: Partial<IGeneratePlanningPlanReq>,
  ) => {
    const res = await this.ctx.post<
      Partial<IGeneratePlanningPlanReq>,
      resInterface.IGeneratePlanMessage
    >(`/planning/plan/generate_plan/`, params);
    return res.data;
  };

  postPlanningPlan = async (
    params: Partial<IGeneratePlanningPlanReq>,
  ) => {
    const res = await this.ctx.post<
      Partial<IGeneratePlanningPlanReq>,
      resInterface.IGeneratePlanMessage
    >(`/planning/plan/`, params);
    return res.data;
  };

  getMethodologyGroupingList = async (
    id: number | string,
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      IPaginatedResult<resInterface.IMethodologyGroupingListItem>
    >(
      `/planning/plan_plot/${id}/indicator_methodology_for_grouping/`,
      params,
    );
    return res.data;
  };
  createGroups = async (
    id: number | string,
    params: IPlanningCreateGroups,
  ) => {
    const res = await this.ctx.post<
      IPlanningCreateGroups,
      AxiosResponse
    >(`/planning/plan_plot/${id}/create_groups/`, params);
    return res.data;
  };

  postVarietyPlanPlot = async (
    params: reqInterface.ICreateVarietyPlanPlot[],
  ) => {
    const res = await this.ctx.post<
      reqInterface.ICreateVarietyPlanPlot[],
      AxiosResponse
    >("/planning/variety_plan_plot/", params);
    return res.data;
  };

  downloadTrialsBranchPlanHpSummary = async (params: reqInterface.DownloadTrialsPlanHpSummaryReq) => {
    const res = await this.ctx.post<reqInterface.DownloadTrialsPlanHpSummaryReq, Blob>("/planning/plan/download_trials_plan_hp_summary/", params, {
      responseType: "blob"
    })
    return res.data
  }

  downloadTrialsRegionPlanHpSummary = async (params: reqInterface.DownloadTrialsPlanHpSummaryReq) => {
    const res = await this.ctx.post<reqInterface.DownloadTrialsPlanHpSummaryReq, Blob>("/planning/plan/download_trials_by_regions_plan_hp_summary/", params, {
      responseType: "blob"
    })
    return res.data
  }

  downloadCultivarPlans = async (params: reqInterface.DownloadTrialsPlanHpSummaryReq) => {
    const res = await this.ctx.post<reqInterface.DownloadTrialsPlanHpSummaryReq, Blob>("/planning/plan/download_cultivar_plans/", params, {
      responseType: "blob"
    })
    return res.data
  }

  patchVarietyPlanPlot = async (
    id: number,
    params: Partial<reqInterface.ICreateVarietyPlanPlot>,
  ) => {
    const res = await this.ctx.patch<
      Partial<reqInterface.ICreateVarietyPlanPlot>,
      AxiosResponse
    >(`/planning/variety_plan_plot/${id}/`, params);
    return res.data;
  };

  deleteVarietyPlanPlot = async (id: number | string) => {
    const res = await this.ctx.delete<
      unknown,
      AxiosResponse
    >(`/planning/variety_plan_plot/${id}/`);
    return res.data;
  };

  downloadOrder = async (
    id: number | string,
    name: string,
  ) => {
    const accessToken = getCookie(
      TOKEN_KEYS.AUTH_TOKEN_KEY,
    );
    const url = `${this.ctx.MAIN_URL}/planning/plan_hp/download_plan_hp/${id}/`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();

        // Создаем URL для скачивания файла
        const fileUrl = URL.createObjectURL(blob);

        // Создаем ссылку на элемент <a> для скачивания файла
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = `${name}.docx`; // Устанавливаем имя файла для скачивания
        document.body.appendChild(link);

        // Кликаем на ссылку для скачивания файла
        link.click();

        // Удаляем ссылку на элемент <a>
        document.body.removeChild(link);

        // Освобождаем URL
        URL.revokeObjectURL(fileUrl);
        return true;
      } else {
        console.error(
          `Ошибка при скачивании файла. Статус: ${response.status}`,
        );
        return false;
      }
    } catch (error) {
      console.error("Произошла ошибка:", error);
      return false;
    }
  };

  copyPlanHpOrder = async (from: number, to: number) => {
    const res = await this.ctx.post<
      {
        source_order: number;
        target_order: number;
      },
      AxiosResponse
    >("/planning/plan_hp/copy_order/", {
      source_order: from,
      target_order: to,
    });
    return res.data;
  };

  // PlanningHP

  getPlanningHp = async (
    params,
  ): Promise<
    IPaginatedResult<resInterface.IPlanningHpList>
  > => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IPlanningHpList>
    >("/planning/plan_hp/", {
      ...params,
    });
    return res.data;
  };

  postPlanningHp = async (
    params: Partial<reqInterface.IPostHp>,
  ) => {
    const res = await this.ctx.post<
      Partial<reqInterface.IPostHp>,
      resInterface.IPlanningHp
    >("/planning/plan_hp/", {
      ...params,
    });
    return res.data;
  };

  getPlanningHpById = async (id: number) => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IPlanningHp
    >(`/planning/plan_hp/${id}/`);
    return res.data;
  };

  patchPlanningHp = async (
    id: number | string,
    params: Partial<reqInterface.IPostHp>,
  ) => {
    const res = await this.ctx.patch<
      Partial<reqInterface.IPostHp>,
      resInterface.IPlanningHp
    >(`/planning/plan_hp/${id}/`, {
      ...params,
    });
    return res.data;
  };

  deletePlanningHp = async (id: number | string) => {
    const res = await this.ctx.delete<
      undefined,
      resInterface.IPlanningHp
    >(`/planning/plan_hp/${id}/`);
    return res.data;
  };

  downloadFileFromBlob = async (
    id: number,
    nameDoc: string,
  ) => {
    const accessToken = getCookie(
      TOKEN_KEYS.AUTH_TOKEN_KEY,
    );
    const url = `${this.ctx.MAIN_URL}/planning/plan/${id}/download_trials_plan_hp/`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();

        // Создаем URL для скачивания файла
        const fileUrl = URL.createObjectURL(blob);

        // Создаем ссылку на элемент <a> для скачивания файла
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = `${nameDoc}.docx`; // Устанавливаем имя файла для скачивания
        document.body.appendChild(link);

        // Кликаем на ссылку для скачивания файла
        link.click();

        // Удаляем ссылку на элемент <a>
        document.body.removeChild(link);

        // Освобождаем URL
        URL.revokeObjectURL(fileUrl);
        return true;
      } else {
        console.error(
          `Ошибка при скачивании файла. Статус: ${response.status}`,
        );
        return false;
      }
    } catch (error) {
      console.error("Произошла ошибка:", error);
      return false;
    }
  };
}

export default PlanningApi;
