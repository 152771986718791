import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { SUBJECT_HEADER_LABELS, SUBJECT_KEYS } from "../const";

import { TSubjectKeys } from "../interfaces";
import {
  EditingCellsSubject,
  SubjectTypeCell,
} from "../templates/Subject/cells";
import { EmailCell, PhoneCell } from "../templates/Employee/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const SubjectColumns: IColumn<TSubjectKeys>[] = [
  {
    label: SUBJECT_HEADER_LABELS.subject_type,
    key: SUBJECT_KEYS.subject_type,
    contentHeader: SUBJECT_HEADER_LABELS.subject_type,
    bodyCellProps: {
      render: SubjectTypeCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: SUBJECT_HEADER_LABELS.name,
    key: SUBJECT_KEYS.name,
    contentHeader: SUBJECT_HEADER_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: SUBJECT_HEADER_LABELS.phones,
    key: SUBJECT_KEYS.phones,
    contentHeader: SUBJECT_HEADER_LABELS.phones,
    bodyCellProps: {
      render: PhoneCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: SUBJECT_HEADER_LABELS.emails,
    key: SUBJECT_KEYS.emails,
    contentHeader: SUBJECT_HEADER_LABELS.emails,
    bodyCellProps: {
      render: EmailCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: SUBJECT_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsSubject,
      styles: cellButtons,
    },
  },
];
