import React from "react";
import { useParams } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import NavigateBeforeIcon
  from "@mui/icons-material/NavigateBefore";

interface IProps {
  headerProps: {
    handleBack: () => void;
  }
}
const HeaderAddCultivarFieldWork:React.FC<IProps> = ({headerProps}) => {
  const { id } = useParams();

  const title = id ? "Редактировать работу" : "Добавить работу";

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <IconButton onClick={headerProps.handleBack}>
        <NavigateBeforeIcon />
      </IconButton>
      <Box>
        <Typography fontSize={24} fontWeight={500} variant="h3" noWrap>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default HeaderAddCultivarFieldWork;
