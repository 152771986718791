import { resInterface } from "@/api/interfaces";
import { SelectOBUsers } from "./SelectOBUser";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    onClose: any;
    id: number;
    onAcceptModal: (user: resInterface.IUserShort) => void;
  };
}

export default SelectOBUsers;
