import React from "react";
// import { Navigate, useLocation } from "react-router";

// import { useCurrentUser } from "@/hooks";
// import { Roles } from "@/const";

type TProps = {
  children: React.ReactNode;
};

export const GuestRoute: React.FC<TProps> = () => {
  // const { children } = props;
  // const location = useLocation();

  // const { role, isEmailConfirmed } = useCurrentUser();

  // if (isEmailConfirmed) {
  //   if (role === Roles.Guest) {
  //     if (location.pathname === '/waiting-for-role') {
  //       return <React.Fragment>{children}</React.Fragment>;
  //     } else {
  //       return <Navigate to='/waiting-for-role' />;
  //     }
  //   } else {
  //     return <Navigate to='/' />;
  //   }
  // } else {
  //   if (location.pathname === '/email-is-send') {
  //     return <React.Fragment>{children}</React.Fragment>;
  //   } else {
  //     return <Navigate to='/email-is-send?skipTimer=true' />;
  //   }
  // }

  return null;
};
