import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

interface IProps extends SvgIconProps {
  active?: boolean;
}

//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=928-180868&mode=design&t=3VnYT7lJxbThy7hV-0
const Marker = ({ ...props }: IProps) => {
  return (
    <SvgIcon {...props}>
      {/* <svg
        width="75"
        height="86"
        viewBox="0 0 75 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.5 40.3025C41.9479 36.2892 45.2474 32.6431 47.3984 29.3644C49.5495 26.0856 50.625 23.1742 50.625 20.63C50.625 16.7242 49.3581 13.526 46.8242 11.0356C44.2904 8.54521 41.1823 7.3 37.5 7.3C33.8177 7.3 30.7096 8.54521 28.1758 11.0356C25.6419 13.526 24.375 16.7242 24.375 20.63C24.375 23.1742 25.4505 26.0856 27.6016 29.3644C29.7526 32.6431 33.0521 36.2892 37.5 40.3025ZM40.5898 23.2369C39.7331 24.079 38.7031 24.5 37.5 24.5C36.2969 24.5 35.2669 24.079 34.4102 23.2369C33.5534 22.3948 33.125 21.3825 33.125 20.2C33.125 19.0175 33.5534 18.0052 34.4102 17.1631C35.2669 16.321 36.2969 15.9 37.5 15.9C38.7031 15.9 39.7331 16.321 40.5898 17.1631C41.4466 18.0052 41.875 19.0175 41.875 20.2C41.875 21.3825 41.4466 22.3948 40.5898 23.2369Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.3984 29.3644C45.2474 32.6431 41.9479 36.2892 37.5 40.3025C33.0521 36.2892 29.7526 32.6431 27.6016 29.3644C25.4505 26.0856 24.375 23.1742 24.375 20.63C24.375 16.7242 25.6419 13.526 28.1758 11.0356C30.7096 8.54521 33.8177 7.3 37.5 7.3C41.1823 7.3 44.2904 8.54521 46.8242 11.0356C49.3581 13.526 50.625 16.7242 50.625 20.63C50.625 23.1742 49.5495 26.0856 47.3984 29.3644ZM24.3477 32.3206C27.2461 36.531 31.6302 41.0908 37.5 46C43.3698 41.0908 47.7539 36.531 50.6523 32.3206C53.5508 28.1102 55 24.2133 55 20.63C55 15.255 53.2409 10.9729 49.7227 7.78375C46.2044 4.59458 42.1302 3 37.5 3C32.8698 3 28.7956 4.59458 25.2773 7.78375C21.7591 10.9729 20 15.255 20 20.63C20 24.2133 21.4492 28.1102 24.3477 32.3206Z"
            fill="currentColor"
          />
          <path
            d="M37.5 24.5C38.7031 24.5 39.7331 24.079 40.5898 23.2369C41.4466 22.3948 41.875 21.3825 41.875 20.2C41.875 19.0175 41.4466 18.0052 40.5898 17.1631C39.7331 16.321 38.7031 15.9 37.5 15.9C36.2969 15.9 35.2669 16.321 34.4102 17.1631C33.5534 18.0052 33.125 19.0175 33.125 20.2C33.125 21.3825 33.5534 22.3948 34.4102 23.2369C35.2669 24.079 36.2969 24.5 37.5 24.5Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_918_221444"
            x="-8"
            y="0"
            width="90"
            height="90"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="20" />
            <feGaussianBlur stdDeviation="10" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.109804 0 0 0 0 0.105882 0 0 0 0 0.121569 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_918_221444"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_918_221444"
              result="shape"
            />
          </filter>
        </defs>
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
      >
        <path
          d="M8 10C8.55 10 9.02083 9.80417 9.4125 9.4125C9.80417 9.02083 10 8.55 10 8C10 7.45 9.80417 6.97917 9.4125 6.5875C9.02083 6.19583 8.55 6 8 6C7.45 6 6.97917 6.19583 6.5875 6.5875C6.19583 6.97917 6 7.45 6 8C6 8.55 6.19583 9.02083 6.5875 9.4125C6.97917 9.80417 7.45 10 8 10ZM8 17.35C10.0333 15.4833 11.5417 13.7875 12.525 12.2625C13.5083 10.7375 14 9.38333 14 8.2C14 6.38333 13.4208 4.89583 12.2625 3.7375C11.1042 2.57917 9.68333 2 8 2C6.31667 2 4.89583 2.57917 3.7375 3.7375C2.57917 4.89583 2 6.38333 2 8.2C2 9.38333 2.49167 10.7375 3.475 12.2625C4.45833 13.7875 5.96667 15.4833 8 17.35ZM8 20C5.31667 17.7167 3.3125 15.5958 1.9875 13.6375C0.6625 11.6792 0 9.86667 0 8.2C0 5.7 0.804167 3.70833 2.4125 2.225C4.02083 0.741667 5.88333 0 8 0C10.1167 0 11.9792 0.741667 13.5875 2.225C15.1958 3.70833 16 5.7 16 8.2C16 9.86667 15.3375 11.6792 14.0125 13.6375C12.6875 15.5958 10.6833 17.7167 8 20Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default Marker;
