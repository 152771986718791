import React, { useEffect, useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import { useDebounceCallback, useStores } from "@/hooks";
import {
  IProps,
  // renderVarietyTypeItem,
  // TForm,
  VARIETY_FIELD_NAME,
} from "@/apps/Modals/ModalContent/CreateVariety/const";
import {
  THEME_MODAL,
  VARIANT_MODAL
} from "@/apps/Modals/const";
import { ICultivar, IRegion } from "@/api/interfaces/responses";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import { Done, Error } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import AddChip from "@/components/Chips/AddChip";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import {
  StyledChip
} from "@/apps/Modals/ModalContent/CreateVariety/styles";
import { VARIETY_TYPES } from "../../../Varieties/const";
import FastRegexTextField from "@/components/FastRegexTextField";

/**
 * Форма создания/редактирования сорта
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * @param modalProps.key - ключ модалки
 * @param modalProps.submitOnMount - сабмит после монтирования
 * */

const CreateVarietyForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore } = useStores();
  const { cultivarEditable = true } = modalProps || {};
  const [isLoading, setIsLoading] = useState(false);
  const { api } = useStores();
  const theme = useTheme();
  const formik = useFormikContext<any>();

  const title = modalProps.varietyId
    ? "Редактировать запись"
    : "Добавить запись";

  const renderItems = VARIETY_TYPES?.map((el) => (
    <MenuItem key={el.value} value={el.value}>
      {el.label}
    </MenuItem>
  ));

  const handleCheck = useDebounceCallback((id: number, name: string) => {
    setIsLoading(true);
    api.regulatoryInfo
      .checkUniqueVarietyName({
        cultivar: id,
        name,
      })
      .then((res) => {
        formik.setFieldValue(
          VARIETY_FIELD_NAME.IS_UNIQUE_VARIETY_NAME,
          res.unique
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, 300);

  const handleChangeName = (e) => {
    formik.setFieldValue(VARIETY_FIELD_NAME.NAME, e.target.value);
    handleCheck(formik.values.cultivar.id, e.target.value);
  };


  const handleAddCultivar = () => {
    modalStore.open(VARIANT_MODAL.SELECT_CULTIVAR, {
      onSubmit: handleSelectCultivars,
      initialValue: formik.values.cultivar ?? [],
    });
  };

  const handleSelectCultivars = (payload: ICultivar[]) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...formik.values,
        [VARIETY_FIELD_NAME.CULTIVAR]: payload,
      },
    });
  };

  const handleAddRegions = () => {
    modalStore.open(VARIANT_MODAL.SELECT_REGIONS, {
      onSubmit: handleSelectRegions,
      initialValue: formik.values.regions
    });
  };

  const handleSelectRegions = (payload: IRegion[]) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValue: {
        ...formik.values,
        [VARIETY_FIELD_NAME.REGIONS]: payload,
      },
    });
  };

  const handleDeleteRegion= (id: number) => {
    const newChip =
      formik.values.regions &&
      formik.values.regions.filter(item => item.id !== id);
    formik.setFieldValue(VARIETY_FIELD_NAME.REGIONS, newChip);
  };

  const handleSubmit = () => {
    if (formik.values?.is_unique_variety_name === false) {
      modalStore.open(VARIANT_MODAL.UNIQUE_VARIETY_NAME, {
        name: formik.values.name,
        theme: THEME_MODAL.W_555,
        handleAccept: () => {
          modalStore.pop();
          formik.submitForm();
        },
        handleDecline: () => {
          modalStore.pop();
          formik.setFieldValue(VARIETY_FIELD_NAME.NAME, "");
          formik.setFieldValue(VARIETY_FIELD_NAME.IS_UNIQUE_VARIETY_NAME, undefined);
        },
        onClose: () => modalStore.pop(),
      });
    } else {
      formik.handleSubmit();
    }
  };

  const renderIcon = () => {
    if (isLoading) {
      return <CircularProgress size={20} />;
    } else if (formik.values.is_unique_variety_name === true) {
      return (
        <Tooltip title="Поле уникально">
          <Done color="primary" />
        </Tooltip>
      );
    } else if (formik.values.is_unique_variety_name === false) {
      return (
        <Tooltip title="Такое название уже существует">
          <Error sx={{ color: theme.palette.yellow.main }} />
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    modalProps.submitOnMount && formik.handleSubmit();
  }, [modalProps.submitOnMount]);

  return (
    <Form noValidate>
      <Box>
        <Box p={3}>
          <Typography fontSize={12} color={theme.palette.blackAndWhite.gray}>
            Сорта
          </Typography>
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Divider />
        <Box p={3}>
          <Stack direction="column" spacing="20px">
            <Field name={VARIETY_FIELD_NAME.CULTIVAR}>
              {({ field, form, meta }) => (
                <FormControl required>
                  <FormLabel>Культура</FormLabel>
                  <Box>
                    {field.value ? (
                      <StyledChip
                        size="small"
                        variant="outlined"
                        label={field.value.name}
                        onDelete={
                          cultivarEditable
                            ? () => form.setFieldValue(field.name, "")
                            : undefined
                        }
                      />
                    ) : (
                      <AddChip onClick={handleAddCultivar} />
                    )}
                  </Box>
                  <FormHelperText error={meta.touched && !!meta.error}>
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>

            <Field name={VARIETY_FIELD_NAME.NAME}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  onChange={handleChangeName}
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                  disabled={!formik.values.cultivar}
                  label="Название"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {renderIcon()}
                      </InputAdornment>
                    ),
                  }}
                  required
                  fullWidth
                  size="small"
                />
              )}
            </Field>
            <FastRegexTextField
              name={VARIETY_FIELD_NAME.FULLNAME}
              label={"Полное название"}
              fullWidth
              size="small"
            />
            <FastRegexTextField
              name={VARIETY_FIELD_NAME.LATIN_VARIETY_NAME}
              label={"Латинское название"}
              fullWidth
              size="small"
            />
            <Field type="checkbox" name={VARIETY_FIELD_NAME.UPOV_CHECK}>
              {({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox />}
                  label="Проверка на UPOV"
                />
              )}
            </Field>
            <Field name={VARIETY_FIELD_NAME.TYPE}>
              {({ field, meta }) => (
                <FormControl size='small' variant='outlined' fullWidth required>
                  <InputLabel id='select-label'>Тип</InputLabel>
                  <Select
                    {...field}
                    label='Тип'
                    error={meta.touched && !!meta.error}
                    required
                    fullWidth
                    disabled={meta.isSubmitting}
                  >
                    {renderItems}
                  </Select>
                  <FormHelperText error={meta.touched && !!meta.error}>
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
            <FastRegexTextField
              name={VARIETY_FIELD_NAME.SELECTION_NUMBER}
              label={"Селекционный номер"}
              fullWidth
              size="small"
            />
            <FastRegexTextField
              name={VARIETY_FIELD_NAME.CIPHER}
              label={"Шифр"}
              fullWidth
              size="small"
            />
            <Stack direction='column' spacing='10px' >
              <Field type='checkbox' name={VARIETY_FIELD_NAME.IN_REGISTER}>
                {({ field }) => (
                  <FormControlLabel
                    {...field}
                    sx={{ width: 'fit-content' }}
                    control={<Checkbox />}
                    label='В реестре'
                  />
                )}
              </Field>
              <Field type='checkbox' name={VARIETY_FIELD_NAME.IS_BENCHMARK}>
                {({ field }) => (
                  <FormControlLabel
                    {...field}
                    sx={{ width: 'fit-content' }}
                    control={<Checkbox />}
                    label='Сорт-эталон'
                  />
                )}
              </Field>
              <Field type='checkbox' name={VARIETY_FIELD_NAME.IS_STANDARD}>
                {({ field }) => (
                  <FormControlLabel
                    {...field}
                    sx={{ width: 'fit-content' }}
                    control={<Checkbox />}
                    label='Сорт-стандарт '
                  />
                )}
              </Field>
              <Field type='checkbox' name={VARIETY_FIELD_NAME.CANDIDATE_FOR_STANDARD}>
                {({ field }) => (
                  <FormControlLabel
                    {...field}
                    sx={{ width: 'fit-content' }}
                    control={<Checkbox />}
                    label='Кандидат в стандарты'
                  />
                )}
              </Field>
            </Stack>
            <Stack
              direction="column"
              alignItems={"flex-start"}
              spacing={3}
            >
             <Field
              name={VARIETY_FIELD_NAME.REGIONS}
            >
              {({ field, meta }) => {
                return (
                  <FormControl>
                    <FormLabel>
                      Регион
                    </FormLabel>
                    <Stack
                      direction={"row"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                      gap={1}
                    >
                      {field.value
                        ? field?.value?.map(
                          (item) => (
                            <StyledChip
                              key={item?.id}
                              size="small"
                              variant="outlined"
                              label={item?.name}
                              onDelete={() =>handleDeleteRegion(item?.id)}
                            />
                          ),
                        )
                        : null}
                      <AddChip
                        onClick={handleAddRegions} 
                      />
                    </Stack>
                    <FormHelperText
                      error={!!meta.error}
                    >
                      {meta.error}
                    </FormHelperText>
                  </FormControl>
                );
              }}
            </Field>

            </Stack>
          </Stack>
        </Box>
        <Divider />
        <Box p={3} display="flex" justifyContent="space-between">
          <Button color="red" onClick={() => modalStore.close()}>
            отмена
          </Button>
          <Button
            disabled={formik.isSubmitting}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default CreateVarietyForm;
