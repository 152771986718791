export const FIELDS_NAME = {
  EXPENSE: 'expense',
  COM_CULTIVAR: "com_cultivar",
  YEAR: 'year',
  SUM: 'sum',
  TYPE: 'type',
  FUNDING_SOURCE: 'funding_source',
} as const;

export const MAX_NUMBERS = {
  MAX: 9999999999
}

export const MAX_NUMBERS_MESSAGE = "Допускается не более 10 символов"