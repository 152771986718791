import { IPostIndicatorMethodologyParams } from "@/api/interfaces/requests";
import { TForm } from "../types";

export const mapFormToValuesSerializer = (
  formValues: TForm,
  trialType: "oos" | "hp",
): IPostIndicatorMethodologyParams[] => {
  if (trialType === "oos") {
    return formValues.sign.map((el) => ({
      is_grouped: Boolean(el.is_grouped),
      is_mandatory: Boolean(el.is_mandatory),
      indicator: Number(el.indicator?.id),
      methodology: Number(el.methodology),
    }));
  } else {
    return formValues.sign.map((el) => ({
      is_grouped: undefined,
      is_mandatory: undefined,
      indicator: Number(el.indicator?.id),
      methodology: Number(el.methodology),
    }));
  }
};
