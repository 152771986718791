import yup from "@/utils/yup";
import { ERROR_MESSAGES, OOS_TYPE } from "@/const";
import { TContextType } from "./utils";

export enum OOS_FIELD_NAME {
  YEAR_OF_TESTING = "year_of_testing", //Год испытаний
  TYPE_OOS = "type_oos", //Подвид испытаний
  SURVEYS = "surveys", // методолгия
  VARIANT = "variant", // Степень выраженности
  VARIETY = "variety", // Эталон
  VARIETY_MAP = "variety_map",
  REGIONS = "regions",
  PAYMENT_TYPE = 'payment_type',
}

export interface IFormik {
  [OOS_FIELD_NAME.YEAR_OF_TESTING]: string | number;
  [OOS_FIELD_NAME.TYPE_OOS]: string;
  [OOS_FIELD_NAME.SURVEYS]: Record<string, any>[];
  [OOS_FIELD_NAME.REGIONS]: number[];
  [OOS_FIELD_NAME.PAYMENT_TYPE]: string;
  errors: string
  context: TContextType;
}

export const initialValues = {
  [OOS_FIELD_NAME.YEAR_OF_TESTING]: null,
  [OOS_FIELD_NAME.TYPE_OOS]: "",
  [OOS_FIELD_NAME.REGIONS]: [],
  [OOS_FIELD_NAME.PAYMENT_TYPE]: 'budget',
};

export const validationSchema = yup.object().shape({
  [OOS_FIELD_NAME.YEAR_OF_TESTING]: yup.string().required(),
  [OOS_FIELD_NAME.TYPE_OOS]: yup.string().required(),
  [OOS_FIELD_NAME.REGIONS]: yup
    .array()
    .min(1, ERROR_MESSAGES.REQUIRED)
    .required(),
});

export const OOS_TYPE_VALUES = [
  {
    value: OOS_TYPE.ACCORDING_APPLICANT,
    label: "По данным заявителя"
  },
  {
    value: OOS_TYPE.BASED_ON_TEST_RESULTS,
    label: "По результатам испытаний на ГСУ",
    label_v2: "По результатам испытаний на госсортучастках"
  },
  {
    value: OOS_TYPE.ACCORDING_APPLICANT_SORTING_PLANTS,
    label: "По данным заявителя с одновременной закладкой в ГСУ",
    label_v2:
      "По данным заявителя с одновременной закладкой опытов на госсортучасках"
  },
  {
    value: OOS_TYPE.NOT_CARRIED_OUT,
    label: "OOC не проводится"
  },
  {
    value: OOS_TYPE.ACCORDING_AUTHORIZED_ORGANIZATION,
    label: "По данным уполномоченной организации"
  }
];
