import React from "react";
import { FastField, Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";
import { IProps, TForm, FIELD_NAME } from "../const";
import {
  Box,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { DatePicker } from "@mui/x-date-pickers";

/**
 * Форма создания/редактирования приказа
 * @param modalProps пропсы
 * @param modalProps.planning - начальные значения
 * */

const EditPlanningForm: React.FC<IProps> = () => {

  const { modalStore } = useStores();
  const form = useFormikContext<TForm>();

  /**
   * Метод выбора даты
   * @param value
   */

  const handleChangeDate = (value: string) => {
    form.setFieldValue(FIELD_NAME.PLAN_DATE, value);
  };

  const handleChangeYear = (value: number) => {
    form.setFieldValue(FIELD_NAME.YEAR, value);
  };

  return (
    <>
      <Box p={3}>
        <Typography>Планирование</Typography>
        <Typography variant='h2'>{"Редактирование данных плана"}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>

          <FastField name={FIELD_NAME.PLAN_DATE}>
            {({ field, meta }) =>
              <DatePicker
                name={field.name}
                value={field.value}
                onChange={handleChangeDate}
                label='Дата утверждения плана'
                slotProps={{
                  textField: {
                    size: "small",
                    error: meta.touched && !!meta.error,
                    helperText: meta.touched && meta.error,
                    onBlur: field.onBlur,
                    fullWidth: true,
                  },
                }}
              />
            }
          </FastField>

          <FastField name={FIELD_NAME.YEAR}>
            {({ field, meta }) =>
              <DatePicker
                name={field.name}
                value={field.value}
                onChange={handleChangeYear}
                label='Год испытаний'
                openTo="year"
                views={["year"]}
                slotProps={{
                  textField: {
                    size: "small",
                    error: meta.touched && !!meta.error,
                    helperText: meta.touched && meta.error,
                    onBlur: field.onBlur,
                    fullWidth: true,
                  },
                }}
              />
            }
          </FastField>

        </Stack>

        <Divider />

        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red' onClick={() => modalStore.close()}>
            отмена
          </Button>
          <Button
            type='submit'
            disabled={form.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>

      </Form>
    </>
  );
};

export default EditPlanningForm;
