import { resInterface } from "@/api/interfaces";
import yup from "@/utils/yup";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    rateId?: number;
    initialValues?: resInterface.IRate;
  };
  onSubmit?: () => void;
}

export interface TForm {
  [RATE_FIELD_NAME.NAME]: string,
}

export enum RATE_FIELD_NAME {
  NAME = 'name',
}


export const mapFormToValue = (values: TForm) => {
  return {
    [RATE_FIELD_NAME.NAME]: values?.name || '',
  };
};


/*Validation of the form*/

export const validationSchema = yup.object().shape({
  [RATE_FIELD_NAME.NAME]: yup.string().trim().required(),
});
