import React from "react";
import {
  STATEMENT_STATUS,
  STATEMENT_STATUS_COLORS
} from "@/pages/Statement/const";
import { TStatementStatus } from "@/api/types";
import { cellStatusStyle } from "@/pages/Statement/style";
import { useTheme } from "@mui/material/styles";
import { Chip, ChipProps } from "@mui/material";

interface IProps extends ChipProps {
  status: TStatementStatus;
}

/**
 * @component StatusCells
 * * Компонент для статуса заявки
 * @param status - статус заявки
 */

const StatementStatus: React.FC<IProps> = ({
  status,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Chip
      {...props}
      sx={cellStatusStyle(
        theme,
        STATEMENT_STATUS_COLORS[status].background,
        STATEMENT_STATUS_COLORS[status].text
      )}
      label={STATEMENT_STATUS[status]}
    />
  );
};

export default StatementStatus;