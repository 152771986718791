import { resInterface } from "@/api/interfaces";
import { SelectUserSign } from "./SelectUserSign";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    onClose: any;
    statement_id: number;
    onAcceptModal: (user: resInterface.IUserShort) => void;
  };
}

export default SelectUserSign;
