import React, { useEffect, useState } from "react";

import { Field, useFormikContext } from "formik";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { BRANCH_FIELD_NAME, IBranchEmail, TForm } from "../../../const";
import { Box, Button, FormControl, Grid, TextField } from "@mui/material";
import theme from "@/theme";
import { CloseIcon } from "@/components/icons";

export const EmailField: React.FC = () => {
  const form = useFormikContext<TForm>();
  const [emails, setEmails] = useState<IBranchEmail[]>(form.values.emails)

  useEffect(() => {
    setEmails(form.values.emails)
  }, [form.values.emails]);

  const handleAdd = () => {
    form.setFieldValue(BRANCH_FIELD_NAME.EMAILS, [
      ...form.values.emails,
      { id: form.values.emails.length + 1, email: '' },
    ]);
  };

  const deleteEmail = (i: IBranchEmail) => {
    form.setFieldValue(
      BRANCH_FIELD_NAME.EMAILS,
      form.values.emails.filter((a) => a.id !== i.id),
    );
  };

  const handleBlur = (event, index) => {
    event.preventDefault()
    const nextEmails = form.values.emails.map((c, i) => {
      if (i === index) {
        return { ...c, email: event.target.value };
      } else {
        return c;
      }
    });
    form.setFieldValue(BRANCH_FIELD_NAME.EMAILS, nextEmails);
  };

  const handleChange = (event, index) => {
    event.preventDefault()
    const nextEmails = form.values.emails.map((c, i) => {
      if (i === index) {
        return { ...c, email: event.target.value };
      } else {
        return c;
      }
    });
    setEmails(nextEmails);
  };

  return (
    <>
      {form.values.emails.map((i, index) => {
        return (
          <Box
            key={i.id}

            display='flex'
            flexWrap='wrap'
            justifyContent='space-between'
            gap='10px'
            sx={{
              border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
              borderRadius: "4px",
              padding: "10px",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <Grid container display='flex'
              sx={{
                maxWidth: "430px",
              }}
              >
              <Grid item width='100%'>
                <Field name={`${BRANCH_FIELD_NAME.EMAILS}.${index}.email`} >
                  {({ field, meta }) => (
                    <FormControl fullWidth>
                      <TextField
                        {...field}
                        autoComplete="off"
                        value={emails[index]?.email}
                        onBlur={(event) => handleBlur(event, index)}
                        onChange={(e) => handleChange(e, index)}
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error}
                        label={`Электронная почта ${index + 1}`}
                        fullWidth
                        size='small'
                        type="text"
                        key={i.id}

                      />
                    </FormControl>
                  )}
                </Field>
              </Grid>
            </Grid>
            <Button
              sx={{
                minWidth: "25px",
                ml: "10px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => deleteEmail(i)}
              startIcon={<CloseIcon />}
            />

          </Box>
        );
      })}
      <Button
        color='blue'
        size='large'
        startIcon={<AddOutlinedIcon />}
        variant='outlined'
        onClick={handleAdd}
        fullWidth
      >
        Добавить почту
      </Button>
    </>
  );
};
