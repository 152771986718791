import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import { useInfiniteScroll, useStores } from "@/hooks";
import { TForm, BRANCH_FIELD_NAME, } from "../../const";

import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  TextField,
} from "@mui/material";
import {
  IAddress,
  IEmployeeList,
  IRegionItem
} from "@/api/interfaces/responses";
import Autocomplete from "@/components/Autocomplete";
import AddChip from "@/components/Chips/AddChip";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { resInterface } from "@/api/interfaces";

interface IProps {
  key: string,
  _key?: string,
  initialValues?: resInterface.IBranchItem;
}

const BranchFormFirstStep: React.FC<IProps> = (modalProps) => {
  const { api, modalStore } = useStores();
  const form = useFormikContext<TForm>();
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [regionSearch, setRegionSearch] = useState("");

  const getKeyRegion = (index: number) => ({
    _key: "GSU_region",
    page: index + 1,
    search: regionSearch
  });

  const { data: regionData, handleScroll: handleScrollRegion } =
    useInfiniteScroll<IRegionItem>(
      getKeyRegion,
      api.regulatoryInfo.getRegionList,
      {
        pageSize: 30,
        revalidateFirstPage: true
      }
    );

  const getEmployee = (index: number) => ({
    _key: "getEmployee",
    page: index + 1,
    search: employeeSearch
  });


  const { data: employeeData, handleScroll: handleScrollEmployee } =
    useInfiniteScroll<IEmployeeList>(
      getEmployee,
      api.regulatoryInfo.getEmployeeList,
      {
        pageSize: 30,
        revalidateFirstPage: true
      }
    );

  const seriaLizeEmployeeData = employeeData.map(
    item => ({ name: item.full_name, id: item.id })
  )

  const { setModalPropsByKey } = modalStore;

  const handleOpenModalActualAddress = () => {
    modalStore.open(VARIANT_MODAL.ADD_ADDRESS, {
      onSubmit: handleAcceptActualAddress,
      onClose: handleAcceptActualAddress,

      initialValues: {
        ...form.values,
      },
    });

  };
  const handleAcceptActualAddress = (value: IAddress) => {
    setModalPropsByKey(modalProps._key, {
      initialValues: {
        ...form.values,
        actual_address: value,
      },
    });
  };
  const removeSelectedActualAddress = () => {
    form.setFieldValue(BRANCH_FIELD_NAME.ACTUAL_ADDRES, null);
  };


  const handleOpenModalLegalAddress = () => {
    modalStore.open(VARIANT_MODAL.ADD_ADDRESS, {
      onSubmit: handleAcceptLegalAddress,
      onClose: handleAcceptLegalAddress,
      initialValues: {
        ...form.values,
      },
    });
  };

  const handleAcceptLegalAddress = (value: IAddress) => {
    setModalPropsByKey(modalProps._key, {
      initialValues: {
        ...form.values,
        legal_address: value,
      },
    });
  };

  const removeSelectedLegalAddress = () => {
    form.setFieldValue(BRANCH_FIELD_NAME.LEGAL_ADDRESS, null);
  };

  const handleOpenModalPostalAddress = () => {
    modalStore.open(VARIANT_MODAL.ADD_ADDRESS, {
      onSubmit: handleAcceptPostalAddress,
      onClose: handleAcceptPostalAddress,
      initialValues: {
        ...form.values,
      },
    });
  };
  const handleAcceptPostalAddress = (value: IAddress) => {
    setModalPropsByKey(modalProps._key, {
      initialValues: {
        ...form.values,
        postal_address: value,
      },
    });
  };

  const removeSelectedPostalAddress = () => {
    form.setFieldValue(BRANCH_FIELD_NAME.POSTAL_ADDRESS, null);
  };

  const handleChangeRegion = (_event, child) => {
    form.setFieldValue(BRANCH_FIELD_NAME.REGION, child)
  }

  const handleChangeStaffDirector = (_event, child) => {
    form.setFieldValue(BRANCH_FIELD_NAME.STAFF_DIRECTOR, child)
  }

  return (
    <Stack direction={"column"} gap={2} p={0}>
      <Field name={BRANCH_FIELD_NAME.REGION}>
        {({ field, meta }) => (
          <FormControl>
            <Autocomplete
              label={"Регион"}
              data={regionData}
              onChange={handleChangeRegion}
              onBlur={field.handleBlur}
              handleScroll={handleScrollRegion}
              value={form.values[BRANCH_FIELD_NAME.REGION]}
              search={setRegionSearch}
              required
            />
            <FormHelperText error={meta.touched && !!meta.error}>
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>

      <Field name={BRANCH_FIELD_NAME.NAME}>
        {({ field, meta }) =>{ 
          return (
          <FormControl>
            <TextField
              {...field}
              label='Название'
              fullWidth
              size='small'
              required
              disabled={meta.isSubmitting}
            />
            <FormHelperText error={meta.touched && !!meta.error}>
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}}
      </Field>

      <Field name={BRANCH_FIELD_NAME.FULL_NAME}>
        {({ field, meta }) => {
          return(
          <FormControl>
            <TextField
              {...field}
              label='Полное название'
              fullWidth
              size='small'
              required
              disabled={meta.isSubmitting}
            />
            <FormHelperText error={meta.touched && !!meta.error}>
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}}
      </Field>

      <Field name={BRANCH_FIELD_NAME.SHORT_NAME}>
        {({ field, meta }) => (
          <FormControl>
            <TextField
              {...field}
              label='Короткое название'
              fullWidth
              size='small'
              required
              disabled={meta.isSubmitting}
            />
            <FormHelperText error={meta.touched && !!meta.error}>
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>

      <Field name={BRANCH_FIELD_NAME.STAFF_DIRECTOR}>
        {({ field, meta }) => (
          <FormControl>
            <Autocomplete
              label={"Начальник филиала"}
              data={seriaLizeEmployeeData}
              value={form.values[BRANCH_FIELD_NAME.STAFF_DIRECTOR]}
              onChange={handleChangeStaffDirector}
              onBlur={field.handleBlur}
              handleScroll={handleScrollEmployee}
              search={setEmployeeSearch}
            />
            <FormHelperText error={meta.touched && !!meta.error}>
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>

      <Stack direction={"row"} justifyContent={"space-between"} >
        <Field name={BRANCH_FIELD_NAME.STAFFING}>
          {({ field, meta }) => (
            <FormControl sx={{maxWidth:"247px"}}>
              <TextField
                {...field}
                label='Штатная численность, чел'
                fullWidth
                size='small'
                disabled={meta.isSubmitting}
              />
              <FormHelperText error={meta.touched && !!meta.error}>
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>

        <Field name={BRANCH_FIELD_NAME.VACANCIES}>
          {({ field, meta }) => ( 
            <FormControl sx={{maxWidth:"247px"}}>
              <TextField
                {...field}
                label='Ваканты'
                fullWidth
                size='small'
                disabled={meta.isSubmitting}
              />
              <FormHelperText error={meta.touched && !!meta.error}>
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
      </Stack>

      <Field name={BRANCH_FIELD_NAME.LEGAL_ADDRESS}>
        {({ field, meta }) => (
          <FormControl>
            <FormLabel>Юридический адрес</FormLabel>
            <Box>
              {field.value ? (
                <Chip
                  size="small"
                  variant="outlined"
                  label={field.value.one_line}
                  onDelete={removeSelectedLegalAddress}
                />
              ) : (
                <AddChip
                  onClick={handleOpenModalLegalAddress}
                />
              )}
            </Box>

            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>

      <Field name={BRANCH_FIELD_NAME.ACTUAL_ADDRES}>
        {({ field, meta }) => {
          return (
            <FormControl>
              <FormLabel>Фактический адрес</FormLabel>
              <Box>
                {field.value ? (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={field.value.one_line}

                    onDelete={removeSelectedActualAddress}
                  />
                ) : (
                  <AddChip
                    onClick={handleOpenModalActualAddress}
                  />
                )}
              </Box>

              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )
        }}
      </Field>

      <Field name={BRANCH_FIELD_NAME.POSTAL_ADDRESS}>
        {({ field, meta }) => {
          return (
            <FormControl>
              <FormLabel>Почтовый адрес</FormLabel>
              <Box>
                {field.value ? (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={field.value.one_line}
                    onDelete={removeSelectedPostalAddress}
                  />
                ) : (
                  <AddChip
                    onClick={handleOpenModalPostalAddress}
                  />
                )}
              </Box>

              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )
        }}
      </Field>
    </Stack>
  );
};

export default BranchFormFirstStep;
