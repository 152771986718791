import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  Menu,
  Typography
} from "@mui/material";
import { DeleteIcon } from "@/components/icons";
import {
  StyledMenuItem
} from "@/components/StyledMenuItem";

interface IProps {
  handleDelete: () => void;
}

const EditMenu: React.FC<IProps> = ({ handleDelete }): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleDelete();
    setIsOpen(null);
  };

  return (
    <Box>
      <IconButton onClick={handleToggle}>
        <MoreVertIcon sx={{ fontSize: 20 }}/>
      </IconButton>

      <Menu anchorEl={isOpen} open={open}
            onClose={handleClose}>
        <StyledMenuItem
          onClick={handleClickDelete}
        >
          <DeleteIcon color={"error"}/>
          <Typography color="error">Удалить</Typography>
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};

export default EditMenu;
