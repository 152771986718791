import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import useSWR, { useSWRConfig } from "swr";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router";
import OosTrialForm
  from "@/apps/EditTrial/components/OosTrial/OosTrialForm";
import { initialValues, validationSchema } from "./const";
import { useStores } from "@/hooks";
import {
  errorToString,
  isNull,
  isUndefined,
} from "@/utils/helpers";
import { Box, CircularProgress } from "@mui/material";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { TOAST_TYPES } from "@/apps/Toast";
import {
  formToValueSerializer,
  serializeContext,
  valueToFormSerializer,
} from "@/apps/EditTrial/components/OosTrial/utils";
import {
  LOCATION_KEY,
  SWR_KEYS,
  TRIAL_TYPE,
} from "@/const";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { FormikValues } from "formik/dist/types";

/**
 * Компонент с формой испытания на оос
 * @component
 */
const OosTrial = () => {
  const { statementId, trialId } = useParams();
  const { api, headerStore, toastStore, swrStore } =
    useStores();
  const swr = useSWRConfig();
  const mutateTrials =
    swrStore.mutators[
      SWR_KEYS.getTrialByStatementIdKey(Number(statementId))
    ];
  const navigate = useNavigate();
  const location = useLocation();
  const fetcherContext = ([, id]: [
    string,
    string | undefined,
  ]) => {
    if (isUndefined(id)) return null;
    return api.statement.getTrialContext(id, "oos");
  };

  const fetcher = ([, id]: [
    string,
    string | undefined,
  ]) => {
    if (isUndefined(id)) return null;
    return api.trial.getTrial(id);
  };

  const { data, isLoading } = useSWR(
    ["getTrial", trialId],
    fetcher,
    {
      onError: (error: AxiosError) => {
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorToString(error),
            severity: "error",
          },
        });
      },
    },
  );

  const { data: contextData, isLoading: isContextLoading } =
    useSWR(
      ["oosTrialContext", statementId],
      fetcherContext,
      {
        onError: (error: AxiosError) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        },
      },
    );

  const onSubmit = (
    values,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    const serializedValues = formToValueSerializer(values);
    const data = {
      ...serializedValues,
      statement: Number(statementId),
      trial_type: TRIAL_TYPE.OOS,
    };

    const promise = trialId
      ? api.trial.pathTrial(trialId as string, data)
      : api.trial.postOosTrial(data);
    promise
      .then(() => {
        if (location.key !== LOCATION_KEY.DEFAULT) {
          navigate(-1);
        } else
          navigate({
            pathname: ROUTES_PATHS.statements,
          });
        mutateTrials();
        trialId &&
          swr.mutate(SWR_KEYS.getTrialById(trialId));
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          const errorMessage = errorToString(error);
          helpers.setFieldError('errors', errorMessage)
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  useLayoutEffect(() => {
    headerStore.setProps({
      title: trialId
        ? "Редактировать испытание"
        : "Добавить испытание",
      type: TRIAL_TYPE.OOS,
    });
    headerStore.setHeader(VARIANT_HEADER.EDIT_TRIAL);
  }, []);

  if (isLoading || isContextLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  if (
    !statementId ||
    isUndefined(contextData) ||
    isNull(contextData)
  ) {
    return null;
  }

  if (trialId && (isUndefined(data) || isNull(data))) {
    return null;
  }

  const values = trialId
    ? valueToFormSerializer(data)
    : initialValues;

  return (
    <Formik
      initialValues={{
        ...values,
        context: serializeContext(contextData),
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      component={() => <OosTrialForm statementId={statementId} />}
    />
  );
};

export default OosTrial;
