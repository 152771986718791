import { TOAST_TYPES } from "@/apps/Toast";
import { ERROR_MESSAGES } from "@/const";

export enum TOAST_CONTENT_TYPE {
  SERVER_ERROR = "SERVER_ERROR"
}

type TToastContentType = `${TOAST_CONTENT_TYPE}`

const content = {
  [TOAST_CONTENT_TYPE.SERVER_ERROR]: {
    type: TOAST_TYPES.ALERT,
    autoHide: true,
    toastProps: {
      severity: "error",
      message: ERROR_MESSAGES.SERVER,
    }
  }
};
const useToastContent = (type: TToastContentType) => {
  const props = content[type] ?? null;
  return props;
};

export default useToastContent;