import React, { useMemo } from "react";
import {
  Box,
  Typography,
  Divider,
  Button
} from "@mui/material";
import {
  IStatementUploadExcel
} from "@/api/interfaces/responses";
import {
  InfoField
} from "@/components/InfoField/InfoField";
import StatementUploadExcelStatusChip
  from "@/components/Chips/StatementUploadExcelStatusChip";
import RefreshOutlinedIcon
  from "@mui/icons-material/RefreshOutlined";
import { useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";

interface IProps {
  handleClose: () => void;
  modalProps: {
    data: IStatementUploadExcel
  };
}

const DownloadStatementStatus: React.FC<IProps> = ({
  handleClose,
  modalProps
}) => {
  const { swrStore } = useStores()
  const { data } = modalProps;


  const errorsSerializer = (errors: string[]) => {
    return errors.map(error => error.split(":")[0]).join((", "));
  };

  const fields = useMemo(() => {
    if (data.status === "completed") {
      return [
        {
          label: "Имя файла",
          content: data.result.file || "-"
        },
        {
          label: "Создано субьектов:",
          content: data.result.created_subjects ?? "-",
        },
        {
          label: "Создано адресов:",
          content: data.result.created_addresses ?? "-",
        },
        {
          label: "Создано культур:",
          content: data.result.created_cultivars ?? "-",
        },
        {
          label: "Создано сортов:",
          content: data.result.created_varieties ?? "-",
        },
        {
          label: "Создано заявок:",
          content: data.result.created_statements ?? "-",
        },
        {
          label: "Ошибки:",
          content: errorsSerializer(data.result.errors) || "-",
        },
      ];
    } else return [];
  }, [data]);

  const isCompleted = data.status === "completed";

  const handleUpdatePage = () => {
    const mutate = swrStore.mutators[SWR_KEYS.getStatements()]
    mutate && mutate()
    handleClose()
  }

  return (
    <Box>
      <Box p={3}>
        <Typography variant="h2">
          Статус загрузки заявки
        </Typography>
      </Box>
      <Divider/>
      <Box p={3}>
        <InfoField
          label="Статус:"
          content={<StatementUploadExcelStatusChip
            status={data.status}/>}
        />
        {
          isCompleted ? (
            fields.map((item, index) => (
              <InfoField
                key={index}
                label={item.label ?? ""}
                content={item.content ?? ""}
                canWrap={false}
              />
            ))
          ) : null
        }
      </Box>
      <Divider/>
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
        gap={2}
      >
        <Button
          color="blue"
          variant="outlined"
          startIcon={<RefreshOutlinedIcon/>}
          onClick={handleUpdatePage}
          disabled={!isCompleted}
        >
          Обновить заявки
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
        >
          Готово
        </Button>
      </Box>
    </Box>
  );
};

export default DownloadStatementStatus;