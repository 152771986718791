import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { PLAN_FIELDS_NAME, STEP_TITLES } from "../../const";

import { CultivarAutocomplete } from "@/components/Autocompletes";
import { SWR_KEYS } from "@/const";
import { useStores } from "@/hooks";
import { OrderSelect } from "@/components/Selects";

const PlanFormStep: React.FC = () => {
  const { swrStore } = useStores();
  const swrKeyOrders = SWR_KEYS.getOrderList();
  const mutateOrders = swrStore.mutators[swrKeyOrders];

  useEffect(() => {
    mutateOrders && mutateOrders();
  }, []);

  return (
    <Stack spacing={3}>
      <Typography variant="h3">
        {STEP_TITLES.CULTIVAR}
      </Typography>
      <Stack direction="column" spacing="20px">
        <OrderSelect
          name={PLAN_FIELDS_NAME.ORDER}
          q={{
            order_type: "hp_grouping",
          }}
        />

        <CultivarAutocomplete
          name={PLAN_FIELDS_NAME.CULTIVAR}
        />
      </Stack>
    </Stack>
  );
};
export default PlanFormStep;
