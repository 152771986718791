import React from "react";
import { AddEntity } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { PAGE_SIZE } from "@/const";
import { IAddress } from "@/api/interfaces/responses";
import {
  PERMISSION_CRUD,
  PERMISSIONS
} from "@/premissions";
import { VARIANT_MODAL } from "@/apps/Modals/const";

export interface IProps {
  handleClose: () => void;
  handlePop: () => void;
  modalProps: {
    key?: string | number,
    initialValue?: IAddress;
    onSubmit?: (value: IAddress) => void;
  };
}

/** Модалка с радио списком адерсов для добавления в разнарядку */
const AddAddress: React.FC<IProps> = ({
  handlePop, 
  handleClose,
  modalProps,
}) => {
  const { api, userStore, modalStore } = useStores();
  const handleAccept = (value: IAddress) => {
    modalProps.onSubmit && modalProps.onSubmit(value)
    handlePop();
  };

  const fetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getAddressList({
      page,
      page_size: PAGE_SIZE,
      search,
      first_ids: modalProps.initialValue?.id,
    });
  };
  const handleAcceptAddress= (payload: IAddress) => {
    modalStore.setModalPropsByKey(modalProps.key, {...modalProps, initialValue: payload})
  }
  const handleAdd = () => {
    modalStore.open(VARIANT_MODAL.ADDRESS, { handleAccept: handleAcceptAddress })
  }
  const getLabel = (value: IAddress): string =>
    value.one_line;

  const isAddAddress = userStore.isPermission(
    PERMISSIONS.regulatory_infoAddress,
    PERMISSION_CRUD.add,
  );

  const props = {
    ...modalProps,
    key: ["GET_ADDRESS", modalProps.initialValue?.id],
    title: "Адреса",
    addButtonLabel: "Добавить адрес",
    isDisabledBtnAdd: isAddAddress,
    tooltipText:
      "У вас недостаточно прав для добавления адреса",
    handleAccept,
    handleAdd,
    getLabel: getLabel,
    fetcher,
  };

  return (
    <AddEntity<IAddress>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default AddAddress;
