import React, { Fragment, useEffect } from "react";
import {
  FastField,
  Field,
  Form,
  useFormikContext,
} from "formik";
import { useStores } from "@/hooks";
import {
  IProps,
  TForm,
  FIELD_NAME,
  HANDBOOK_REMARK_TYPE,
} from "../../const";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";

/**
 * Форма создания/редактирования культуры
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const RemarkHandbookForm: React.FC<IProps> = ({
  modalProps,
}) => {
  const { modalStore } = useStores();
  const form = useFormikContext<TForm>();

  const title = modalProps.remarkId
    ? "Редактировать запись"
    : "Добавить запись";

  const visibleByParams =
    modalProps.fromAdd || modalProps.fromCancel;

  useEffect(() => {
    if (modalProps.fromAdd) {
      form.setFieldValue(
        FIELD_NAME.REMARK_TYPE,
        HANDBOOK_REMARK_TYPE.revision,
      );
    }
    if (modalProps.fromCancel) {
      form.setFieldValue(
        FIELD_NAME.REMARK_TYPE,
        HANDBOOK_REMARK_TYPE.refusal,
      );
    }
  }, [visibleByParams]);

  return (
    <Fragment>
      <Box p={3}>
        <Typography>Замечания</Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <Field name={FIELD_NAME.NAME}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label="Название"
                  fullWidth
                  size="small"
                  required
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[FIELD_NAME.NAME]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          {!visibleByParams && (
            <FastField name={FIELD_NAME.REMARK_TYPE}>
              {({ field }) => (
                <FormControl>
                  <RadioGroup {...field}>
                    <Stack direction="row">
                      <FormControlLabel
                        value={
                          HANDBOOK_REMARK_TYPE.revision
                        }
                        control={
                          <Radio color="secondary" />
                        }
                        label="Доработка"
                      />
                      <FormControlLabel
                        value={HANDBOOK_REMARK_TYPE.refusal}
                        control={
                          <Radio color="secondary" />
                        }
                        label="Отказ"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            </FastField>
          )}

          <Field name={FIELD_NAME.DESCRIPTION}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label="Описание"
                  fullWidth
                  size="small"
                  multiline
                  minRows={4}
                  maxRows={10}
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={
                    form.values[FIELD_NAME.DESCRIPTION]
                  }
                  disabled={meta.isSubmitting}
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>

        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={form.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default RemarkHandbookForm;
