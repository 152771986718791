import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface IProps extends SvgIconProps {
  active?: boolean;
}

//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=86-113421&mode=design&t=NsBAwfXhny5rGRRi-0
const MapRuler = ({ active = false, ...props }: IProps) => {
  const isActive = active ? 'green' : '#1C1B1F';

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.000000"
        height="12.000000"
        viewBox="0 0 20 12"
        fill="none"
      >
        <path
          id="straighten"
          d="M2 12C1.4502 12 0.979004 11.8042 0.587402 11.4126C0.195801 11.021 0 10.5498 0 10L0 2C0 1.4502 0.195801 0.979004 0.587402 0.587402C0.979004 0.195801 1.4502 0 2 0L18 0C18.5498 0 19.021 0.195801 19.4126 0.587402C19.8042 0.979004 20 1.4502 20 2L20 10C20 10.5498 19.8042 11.021 19.4126 11.4126C19.021 11.8042 18.5498 12 18 12L2 12ZM2 10L18 10L18 2L15 2L15 6L13 6L13 2L11 2L11 6L9 6L9 2L7 2L7 6L5 6L5 2L2 2L2 10Z"
          fill={isActive}
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
};

export default MapRuler;
