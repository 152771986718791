import { TOKEN_KEYS } from "@/const";

/** Настроить куки
 * @param name название куки
 * @param value значение куки
 * @param expires !необязательный параметр! срок годности куки
 * @returns
 */
export const setCookie = (
  name: string,
  value: string,
  expires?: string
): void => {
  if (typeof document !== "undefined") {
    document.cookie = `${name}=${value};path=/;${typeof expires === "string" ? `expires=${expires};` : ""}`;
  }
};

/** Получение куки
 * @param name название куки
 * @returns возвращает значение куки если найдет такую
 */
export const getCookie = (name: string): string | undefined => {
  if (typeof document === "undefined") {
    return undefined;
  }
  try {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

/** Удаление куки
 * @param name название куки
 * @returns
 */
export const removeCookie = (name: string): void => {
  if (typeof document !== "undefined") {
    document.cookie = name + "=; Max-Age=-99999999; path=/";
  }
};

/** Удаление куки, участвующих в авторизации
 *  @returns
 */
export const deleteAuthCookies = (): void => {
  if (document !== undefined) {
    const accessTokenValue = getCookie(TOKEN_KEYS.AUTH_TOKEN_KEY);
    const refreshTokenValue = getCookie(TOKEN_KEYS.REFRESH_TOKEN_KEY);
    const accessTokenExpiryValue = getCookie(TOKEN_KEYS.ACCESS_TOKEN_EXPIRY);
    const refreshTokenExpiryValue = getCookie(TOKEN_KEYS.REFRESH_TOKEN_EXPIRY);

    if (accessTokenValue !== undefined) {
      document.cookie = `${TOKEN_KEYS.AUTH_TOKEN_KEY}=${accessTokenValue}; max-age=-1; path=/`;
    }

    if (refreshTokenValue !== undefined) {
      document.cookie = `${TOKEN_KEYS.REFRESH_TOKEN_KEY}=${refreshTokenValue}; max-age=-1; path=/`;
    }

    if (accessTokenExpiryValue !== undefined) {
      document.cookie = `${TOKEN_KEYS.ACCESS_TOKEN_EXPIRY}=${accessTokenExpiryValue}; max-age=-1; path=/`;
    }

    if (refreshTokenExpiryValue !== undefined) {
      document.cookie = `${TOKEN_KEYS.REFRESH_TOKEN_EXPIRY}=${refreshTokenExpiryValue}; max-age=-1; path=/`;
    }
  }
};

/** Удаление всех кук
 * @returns
 */
export const deleteAllCookies = (): void => {
  if (typeof document !== "undefined") {
    const cookies = document.cookie.split("; ");

    console.log("cookies", cookies);

    for (let i = 0; i < cookies.length; i += 1) {
      const domains = window.location.hostname.split(".");

      console.log("domains", domains);

      while (domains.length > 0) {
        const cookieName = encodeURIComponent(
          cookies[i].split(";")[0].split("=")[0]
        );

        const cookieBase = `${cookieName}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${domains.join(
          "."
        )} ;path=`;
        const paths = window.location.pathname.split("/");
        document.cookie = `${cookieBase}/`;

        console.log("document.cookie", document.cookie);

        while (paths.length > 0) {
          document.cookie = `${cookieBase}/${paths.join("/")}`;
          paths.pop();
        }

        console.log("document.cookie 2", document.cookie);

        domains.shift();
      }
    }
  }
};
