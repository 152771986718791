import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

interface IProps extends SvgIconProps {
    active?: boolean;
}

//cсылка на иконку в фигме https://www.figma.com/design/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?node-id=18643-92230&t=Gf4wgtG1P8juQo2t-0
const WarningIcon = ({ ...props }: IProps) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_18643_92265)">
                    <path d="M12 16.4473C11.329 16.4473 10.7666 17.0097 10.7666 17.6807C10.7666 18.3516 11.329 18.9141 12 18.9141C12.6463 18.9141 13.2334 18.3516 13.2038 17.7103C13.2334 17.0048 12.6759 16.4473 12 16.4473Z" fill="#8392A1" />
                    <path d="M12 16.4473C11.329 16.4473 10.7666 17.0097 10.7666 17.6807C10.7666 18.3516 11.329 18.9141 12 18.9141C12.6463 18.9141 13.2334 18.3516 13.2038 17.7103C13.2334 17.0048 12.6759 16.4473 12 16.4473Z" fill="black" fill-opacity="0.2" />
                    <path d="M23.416 20.8188C24.1906 19.4818 24.1955 17.8883 23.4259 16.5562L15.6999 3.17638C14.9352 1.82951 13.5538 1.03027 12.0046 1.03027C10.4555 1.03027 9.07409 1.83445 8.30939 3.17144L0.573551 16.5661C-0.196086 17.913 -0.191152 19.5164 0.588352 20.8534C1.35799 22.1756 2.73446 22.9699 4.27373 22.9699H19.7059C21.2501 22.9699 22.6365 22.1657 23.416 20.8188ZM21.7386 19.8518C21.3094 20.5919 20.5496 21.031 19.701 21.031H4.2688C3.43009 21.031 2.67525 20.6018 2.2559 19.8765C1.83161 19.1414 1.82668 18.2632 2.25097 17.5232L9.98681 4.13349C10.4062 3.39839 11.1561 2.96423 12.0046 2.96423C12.8483 2.96423 13.6031 3.40332 14.0225 4.13842L21.7534 17.5281C22.1678 18.2484 22.1629 19.1167 21.7386 19.8518Z" fill="#8392A1" />
                    <path d="M23.416 20.8188C24.1906 19.4818 24.1955 17.8883 23.4259 16.5562L15.6999 3.17638C14.9352 1.82951 13.5538 1.03027 12.0046 1.03027C10.4555 1.03027 9.07409 1.83445 8.30939 3.17144L0.573551 16.5661C-0.196086 17.913 -0.191152 19.5164 0.588352 20.8534C1.35799 22.1756 2.73446 22.9699 4.27373 22.9699H19.7059C21.2501 22.9699 22.6365 22.1657 23.416 20.8188ZM21.7386 19.8518C21.3094 20.5919 20.5496 21.031 19.701 21.031H4.2688C3.43009 21.031 2.67525 20.6018 2.2559 19.8765C1.83161 19.1414 1.82668 18.2632 2.25097 17.5232L9.98681 4.13349C10.4062 3.39839 11.1561 2.96423 12.0046 2.96423C12.8483 2.96423 13.6031 3.40332 14.0225 4.13842L21.7534 17.5281C22.1678 18.2484 22.1629 19.1167 21.7386 19.8518Z" fill="black" fill-opacity="0.2" />
                    <path d="M11.6934 7.7897C11.1063 7.95744 10.7412 8.49027 10.7412 9.13657C10.7708 9.52632 10.7955 9.92101 10.8251 10.3108C10.909 11.7958 10.9928 13.2512 11.0767 14.7362C11.1063 15.2394 11.496 15.6045 11.9993 15.6045C12.5025 15.6045 12.8972 15.2147 12.9218 14.7066C12.9218 14.4007 12.9218 14.1195 12.9515 13.8087C13.0057 12.8565 13.0649 11.9043 13.1192 10.9521C13.1488 10.3354 13.2031 9.71873 13.2327 9.10203C13.2327 8.88002 13.2031 8.68268 13.1192 8.48534C12.8676 7.93278 12.2805 7.65156 11.6934 7.7897Z" fill="#8392A1" />
                    <path d="M11.6934 7.7897C11.1063 7.95744 10.7412 8.49027 10.7412 9.13657C10.7708 9.52632 10.7955 9.92101 10.8251 10.3108C10.909 11.7958 10.9928 13.2512 11.0767 14.7362C11.1063 15.2394 11.496 15.6045 11.9993 15.6045C12.5025 15.6045 12.8972 15.2147 12.9218 14.7066C12.9218 14.4007 12.9218 14.1195 12.9515 13.8087C13.0057 12.8565 13.0649 11.9043 13.1192 10.9521C13.1488 10.3354 13.2031 9.71873 13.2327 9.10203C13.2327 8.88002 13.2031 8.68268 13.1192 8.48534C12.8676 7.93278 12.2805 7.65156 11.6934 7.7897Z" fill="black" fill-opacity="0.2" />
                </g>
                <defs>
                    <clipPath id="clip0_18643_92265">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </SvgIcon>
    );
};

export default WarningIcon;
