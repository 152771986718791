import React from "react";
import { useFormik } from "formik";
import {
  Button,
  Typography,
  Link as MUILink
} from "@mui/material";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";
import { observer } from "mobx-react";

import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import Yup from "@/utils/yup";
import { errorToString } from "@/utils/helpers";
import { useStores } from "@/hooks";
import {
  checkFieldError,
  checkTextErrorField
} from "@/utils/formikHelpers";
import AuthLayout
  from "../components/AuthLayout/AuthLayout";
import {
  InputField
} from "@/components/form/base/InputField";
import {
  PasswordField
} from "@/components/form/base/PasswordField";

import styles from "./styles/index.module.scss";
import { SEO_TITLES } from "@/const";
// import { initializeRootStore } from "@/client";

const validationSchema = Yup.object().shape({
  username: Yup.string().trim().required("Введите логин"),
  password: Yup.string().trim().required("Введите пароль")
});

const Login = observer(() => {
  // const [error, setError] = React.useState<string>("");
  const navigate = useNavigate();
  const { userStore, api } = useStores();

  const formik = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values, formikHelpers) => {
      formik.setSubmitting(true);
      api.auth
        .postLogin(values)
        .then(() => {
          // initializeRootStore();
          window.location.href = ROUTES_PATHS.main;
          formikHelpers.resetForm();
        })
        .catch(
          (
            e: AxiosError<{
              change_password?: boolean;
            }>
          ) => {
            const error = errorToString(e);
            formikHelpers.setFieldError("password", error);
            if (e.response?.data?.change_password) {
              userStore.setUsername(values.username);
              userStore.setTrialPassword(values.password);
              navigate(ROUTES_PATHS.passwordResetRequest);
            }
          }
        )
        .finally(() => formik.setSubmitting(false));
    }
  });

  return (
    <AuthLayout title={SEO_TITLES.LOGIN}>
      <Typography variant="h4" fontSize={30}>
        Авторизация
      </Typography>
      <form onSubmit={formik.handleSubmit} noValidate className={styles.form}>
        <InputField
          id="outlined-multiline-flexible"
          name="username"
          label="Логин"
          size="medium"
          fullWidth
          error={checkFieldError(formik, "username")}
          helperText={checkTextErrorField(formik, "username")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
          disabled={formik.isSubmitting}
          inputProps={{ 'data-qa': 'login' }} 
        />
        <PasswordField
          id="outlined-adornment-password"
          name="password"
          label="Пароль"
          size="medium"
          fullWidth
          error={checkFieldError(formik, "password")}
          helperText={checkTextErrorField(formik, "password")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          disabled={formik.isSubmitting}
          inputProps={{ 'data-qa': 'password' }} 
        />
        <Button
          variant="contained"
          fullWidth
          type="submit"
          color="primary"
          size="large"
          disabled={formik.isSubmitting}
          data-qa='inner'           
        >
          Войти
        </Button>
        <Link to="">
          <MUILink color="#2196f3">Сайт организации</MUILink>
        </Link>
      </form>
    </AuthLayout>
  );
});

export default Login;
