import React from "react";
import { useParams } from "react-router-dom";
import { FieldArray, useFormikContext } from "formik";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  Stack,
  Typography
} from "@mui/material";
import {
  ChipWrap
} from "@/pages/AddStatement/components/SubjectsFormStep/styles";
import AddChip from "@/components/Chips/AddChip";
import { FIELDS_NAME, IGroupFormik } from "../../const";
import { useStores } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import {
  IMethodologyGroupingListItem
} from "@/api/interfaces/responses";

const GroupForm = () => {
  const { id } = useParams();
  const { modalStore } = useStores();

  const formik = useFormikContext<IGroupFormik>();

  const onSubmit = (payload: IMethodologyGroupingListItem[]) => {
    void formik.setFieldValue(FIELDS_NAME.GROUPS, payload)
  };


  const handleOpenModal = () => {
    modalStore.open(VARIANT_MODAL.SELECT_PLANNING_METHODOLOGY_GROUPS, {
      id,
      onSubmit,
      initialValue: formik.values.groups
    });
  };

  return (
    <Stack direction="column" gap="20px">
      <Typography variant="h3">
        Признаки для группировки
      </Typography>
      <FieldArray
        name={FIELDS_NAME.GROUPS}
        render={(helpers) => {
          const { value } = formik.getFieldProps(helpers.name);
          const meta = formik.getFieldMeta(helpers.name);
          return (
            <FormControl required>
              <ChipWrap>
                {value.map((value, index) => (
                  <Chip
                    key={value.id}
                    size="small"
                    variant="outlined"
                    label={value.name}
                    onDelete={() => helpers.remove(index)}
                  />
                ))}
                <Box>
                  <AddChip
                    onClick={handleOpenModal}
                  />
                </Box>
              </ChipWrap>
              <FormHelperText
                error={meta.touched && !!meta.error}>
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    </Stack>
  );
};

export default GroupForm;