import React from "react";
import { Formik, FormikHelpers } from "formik";
import AddDocumentForm
  from "@/apps/Modals/ModalContent/AddDocument/components/AddDocumentForm";
import {
  IFormik,
  initialValues,
  validationSchema
} from "./const";
import dayjs, { Dayjs } from "dayjs";
import { FormikValues } from "formik/dist/types";

interface IFile {
  file: File,
  name: string;
  number: string;
  receipt_date: string | Dayjs | null;
}

interface IProps {
  handleClose: () => void
  modalProps: {
    subtitle: string
    initialValue: IFile
    handleAccept: (payload: IFile) => Promise<void>
  };
}

const AddDocument: React.FC<IProps> = ({ modalProps, handleClose }) => {
  const handleSubmit = (values: IFormik, helpers: FormikHelpers<FormikValues>) => {
    const payload = {
      file: values.files[0],
      name: values.name,
      number: values.number,
      receipt_date: values.receipt_date
    }
    helpers.setSubmitting(true)
    modalProps.handleAccept && modalProps.handleAccept(payload).then(() => {
      handleClose()
      helpers.setSubmitting(false)
    });
  };

  const title = modalProps.initialValue ? "Редактировать документ" : "Добавить документ";

  const serializeFile = (file: IFile): IFormik => ({
    name: file.name,
    receipt_date: file.receipt_date ? dayjs(file.receipt_date) : null,
    number: file.number,
    files: [file.file]
  });

  const value = modalProps.initialValue ? serializeFile(modalProps.initialValue) : initialValues;
  return (
    <Formik initialValues={value} onSubmit={handleSubmit}
            validationSchema={validationSchema}
            component={() => <AddDocumentForm
              title={title}
              subtitle={modalProps.subtitle}/>}/>
  );
};

export default AddDocument;