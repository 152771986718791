import React from "react";
import FileDropzone from "@/components/FileDropzone";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useStores } from "@/hooks";
import { DROPZONE_VARIANT, IFile, IMAGE_ACCEPT, IMAGE_SUBTITLE } from "@/components/FileDropzone/const";
import { useFormikContext } from "formik";
import { AGRICULTURAL_MACHINERY_NAME, TForm } from "../const";

const ImageForm = ({id}:{id?: number | string }) => {
const formik = useFormikContext<TForm>();
const { api } = useStores();

const fetcher = (fd: FormData) =>
  api.regulatoryInfo.addAgrMachineryFileById(id as string, fd)
const deleteMutation = (fileId: number) => {
  return api.regulatoryInfo.deleteAgrMachineryFile(id as string, fileId)
}

const handleChangeFiles = (files: IFile[]) => {
  void formik.setFieldValue(AGRICULTURAL_MACHINERY_NAME.FILE_LIST, files);
};

return (
  <Stack direction="column">
    <Box>
      <Typography variant="p14Medium">
        Фото
      </Typography>
    </Box>
    {
      id ? (
        <FileDropzone
          name={AGRICULTURAL_MACHINERY_NAME.FILE_LIST}
          value={formik.values[AGRICULTURAL_MACHINERY_NAME.FILE_LIST]}
          deleteMutation={deleteMutation}
          fetcher={fetcher}
          accept={IMAGE_ACCEPT}
          subtitle={IMAGE_SUBTITLE}
          variant={DROPZONE_VARIANT.IMG}
        />
      ) : <FileDropzone
        name={AGRICULTURAL_MACHINERY_NAME.FILE_LIST}
        value={formik.values[AGRICULTURAL_MACHINERY_NAME.FILE_LIST]}
        setValue={handleChangeFiles}
        accept={IMAGE_ACCEPT}
        subtitle={IMAGE_SUBTITLE}
        variant={DROPZONE_VARIANT.IMG}
      />
    }
  </Stack>
);
};

export default ImageForm;
