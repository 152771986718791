import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=86-113421&mode=design&t=wjp27yPmbYjfxv6D-0
const FrostIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.16669 18.3332V14.8748L6.45835 17.5415L5.29169 16.3748L9.16669 12.4998V10.8332H7.50002L3.62502 14.7082L2.45835 13.5415L5.12502 10.8332H1.66669V9.1665H5.12502L2.45835 6.45817L3.62502 5.2915L7.50002 9.1665H9.16669V7.49984L5.29169 3.62484L6.45835 2.45817L9.16669 5.12484V1.6665H10.8334V5.12484L13.5417 2.45817L14.7084 3.62484L10.8334 7.49984V9.1665H12.5L16.375 5.2915L17.5417 6.45817L14.875 9.1665H18.3334V10.8332H14.875L17.5417 13.5415L16.375 14.7082L12.5 10.8332H10.8334V12.4998L14.7084 16.3748L13.5417 17.5415L10.8334 14.8748V18.3332H9.16669Z"
          fill="#1872CF"
        />
      </svg>
    </SvgIcon>
  );
};

export default FrostIcon;
