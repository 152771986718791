import React, { useLayoutEffect } from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores } from "@/hooks";
import { Box, SxProps, Theme } from "@mui/material";
import theme from "@/theme";
import GSUView from "./components/GSUView";
import { SWR_KEYS } from "@/const";
import Loader from "@/components/Loader";

export const wrapperStyle = (theme: Theme): SxProps => ({
  width: "100%",
  padding: theme.spacing(3),
});

const GSUPage: React.FC = () => {
  const { headerStore } = useStores();
  const { id } = useParams();
  const { api } = useStores();

  const { data: renderData, error, isLoading } = useSWR(
    id ? SWR_KEYS.getPlotMainById(id) : null,
    () => api.regulatoryInfo.getPlotMainById(id as string), {}
  );

  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.GSU);
  }, []);

  if (error) {
    return <div>Ошибка загрузки данных</div>;
  }

  if (isLoading || !renderData) {
    return <Loader/>;
  }

  return (
    <Box sx={wrapperStyle(theme)}>
      <GSUView renderData={renderData}/>
    </Box>
  );
};

export default GSUPage;
