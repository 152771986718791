import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import { AxiosResponse } from "axios";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Link,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CheckCircle,
  DeleteOutlineOutlined,
  ImageOutlined,
  UploadFile,
} from "@mui/icons-material";
import {
  errorToString,
  formatSize,
  isUndefined,
} from "@/utils/helpers";
import {
  getAccept,
  getSubtitle,
} from "@/components/FileDropzone/utils";

import {
  DROPZONE_VARIANT,
  errorsCode,
  FILE_AND_IMAGE_ACCEPT,
  FILE_AND_IMAGE_SUBTITLE,
  IFile,
  MAX_SIZE,
  STATUSES,
  statusText,
  TDropzoneVariant,
} from "./const";
import { List, ListItem, UploadContainer } from "./styles";

interface IProps {
  variant?: TDropzoneVariant;
  maxSize?: number;
  maxFiles?: number;
  multiple?: boolean;
  subtitle?: string;
  accept?: Record<string, any>;
  name: string;
  value?: IFile[];
  isFileAdd?: boolean;
  isFileDelete?: boolean;
  setValue?: (value: IFile[]) => void;
  fetcher?: (fd: FormData) => Promise<any>;
  deleteMutation?: (fileId: number) => Promise<void | AxiosResponse>;
}

const Image = ({ file }: { file: IFile }) => {
  return !isUndefined(file?.preview) ? (
    <Avatar
      variant="rounded"
      src={file.preview}
      // Revoke data uri after image is loaded
      onLoad={() => {
        URL.revokeObjectURL(file.preview as string);
      }}
    />
  ) : (
    <Avatar variant="rounded" sx={{ fontSize: 32 }}>
      <ImageOutlined fontSize="inherit" />
    </Avatar>
  );
};

const FileDropzone: React.FC<IProps> = ({
  maxSize = MAX_SIZE,
  multiple = true,
  maxFiles,
  subtitle = FILE_AND_IMAGE_SUBTITLE,
  accept = FILE_AND_IMAGE_ACCEPT,
  variant = DROPZONE_VARIANT.FILE,
  value = [],
  setValue,
  name,
  fetcher,
  deleteMutation,
  isFileAdd = true, // поменять на false как закончим пермишенны
  isFileDelete = true, // поменять на false как закончим пермишенны
}) => {
  const [files, setFiles] = useState<IFile[]>(value);
  const [disabledList, setDisabledList] = useState<
    number[]
  >([]);
  const theme = useTheme();

  useEffect(() => {
    if (setValue) {
      setValue(files);
    }
  }, [files]);

  /** handleAcceptedFiles
   * @function
   *
   * Добавляем выбранным файлам статус и превью если это тип - картинка.
   * */
  const handleAcceptedFiles = (files: IFile[]): void => {
    const newFiles = files.map((file) =>
      Object.assign(file, {
        uuid: uuidv4(),
        status: fetcher ? STATUSES.LOADING : STATUSES.SUCCESS,
        preview:
          variant === DROPZONE_VARIANT.IMG
            ? URL.createObjectURL(file)
            : undefined,
        formatSize: formatSize(file.size),
      }),
    );
    setFiles((prev) => [...prev, ...newFiles]);
  };

  /** handleAcceptedFiles
   * @function
   *
   * Добавляем файлам которые не прошли валидацию статус и текст с ошибкой
   * */
  const handleFileRejections = (fileRejections) => {
    const newFiles = fileRejections.map((file) => {
      const errorMessage = errorsCode[file.errors[0].code]
        ? `${errorsCode[file.errors[0].code]} ${
            file.file.name
          }`
        : errorsCode.DEFAULT;
      return Object.assign(file, {
        uuid: uuidv4(),
        status: STATUSES.ERROR,
        error: errorMessage,
        formatSize: formatSize(file.size),
      });
    });
    setFiles((prev) => [...prev, ...newFiles]);
  };

  /**
   * handleRemove
   * @function
   * Удаляет файлы. Если есть id то по id если нет то по uuid
   * */
  const handleRemove = (file: IFile) => {
    if (deleteMutation && file.id) {
      setDisabledList((prev) => [
        ...prev,
        file.id as number,
      ]);
      deleteMutation(file.id)
        .then(() => {
          setFiles((prev) =>
            prev.filter(
              (prevFile) => file.id !== prevFile.id,
            ),
          );
        })
        .finally(() => {
          setDisabledList((prev) =>
            prev.filter((el) => el !== file.id),
          );
        });
    } else if (file.id) {
      setFiles((prev) =>
        prev.filter((prevFile) => file.id !== prevFile.id),
      );
    } else {
      setFiles((prev) =>
        prev.filter(
          (prevFile) => file.uuid !== prevFile.uuid,
        ),
      );
    }
  };

  /** changeFileStatusToSuccess
   * @function
   *
   * Устанавливаем статус -Успешно для загруженного файла
   *  */
  const changeFileStatusToSuccess = (
    file: IFile,
    id: number,
  ): void => {
    setFiles((prev) =>
      prev.map((prevFile) =>
        prevFile.name === file.name &&
        prevFile.status === STATUSES.LOADING
          ? Object.assign(prevFile, {
              status: STATUSES.SUCCESS,
              id: id,
            })
          : prevFile,
      ),
    );
  };

  /** changeFileStatusToSuccess
   * @function
   *
   * Устанавливаем статус - Ошибка и сообщение с ошибкой для файла с ошибкой
   *  */
  const changeFileStatusToError = (
    file: File,
    errorMessage = errorsCode.DEFAULT,
  ): void => {
    setFiles((prev) =>
      prev.map((prevFile) =>
        file.name === prevFile.name &&
        prevFile.status === STATUSES.LOADING
          ? Object.assign(prevFile, {
              status: STATUSES.ERROR,
              error: errorMessage,
            })
          : prevFile,
      ),
    );
  };

  const isDisabled = (id: number | undefined) =>
    disabledList.some((n) => n === id);

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      handleAcceptedFiles(acceptedFiles);
      handleFileRejections(fileRejections);

      const requests = acceptedFiles.map((file: IFile) => {
        const fd = new FormData();
        fd.append("type", name);
        fd.append("file", file);

        return fetcher ? fetcher(fd) : null;
      });

      Promise.allSettled(requests).then((results) => {
        results.forEach((result, num) => {
          if (
            result.status == "fulfilled" &&
            result.value
          ) {
            changeFileStatusToSuccess(
              acceptedFiles[num],
              result.value.id,
            );
          }
          if (result.status == "rejected") {
            changeFileStatusToError(
              acceptedFiles[num],
              errorToString(result.reason),
            );
          }
        });
      });
    },
    [files],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize,
    maxFiles,
    multiple,
    accept: accept || getAccept(variant),
  });

  const statusIcon = {
    [STATUSES.SUCCESS]: <CheckCircle color="primary" />,
    [STATUSES.LOADING]: (
      <Box display="flex" alignItems="center">
        <CircularProgress
          size={20}
          sx={{ color: theme.palette.blue.main }}
        />
      </Box>
    ),
  };

  const renderStatusText = (file: IFile) => {
    return file.status === STATUSES.ERROR ? (
      <ListItemText
        primary={file.name}
        secondary={`${file.error} • ${
          statusText[file.status]
        }`}
      />
    ) : (
      <ListItemText
        primary={file.name}
        secondary={`${file.formatSize}кб ${
          file.status ? ("•" + " " + statusText[file.status]) : ""
        }`}
      />
    );
  };

  const renderFiles = files.map((file) => {
    const isOutsideFile = value?.find(
      (item) => item.uuid === file.uuid,
    );
    return (
      <ListItem
        key={file.uuid}
        error={file.status === STATUSES.ERROR}
      >
        <ListItemAvatar>
          {variant === DROPZONE_VARIANT.FILE ? (
            <UploadFile />
          ) : (
            <Image file={file} />
          )}
        </ListItemAvatar>
        {renderStatusText(file)}
        <Tooltip
          title={
            !isFileDelete
              ? "У вас недостаточно прав для выполнения этого действия"
              : ""
          }
          followCursor
        >
          <Box>
            <IconButton
              onClick={() => handleRemove(file)}
              disabled={
                isDisabled(file.id) || !isFileDelete
                  ? true
                  : isOutsideFile
                  ? false
                  : file.status === STATUSES.LOADING
              }
            >
              <DeleteOutlineOutlined
                sx={{
                  color:
                    theme.palette.blackAndWhite.black60,
                }}
              />
            </IconButton>
          </Box>
        </Tooltip>
        {statusIcon[file.status] || null}
      </ListItem>
    );
  });

  return (
    <Box>
      <List>{renderFiles}</List>
      {isFileAdd ? (
        files.length !== maxFiles ? (
          <UploadContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <Stack
              direction="column"
              spacing={1}
              alignItems="center"
            >
              <UploadFile
                sx={{ color: theme.palette.blue.lightBlue }}
              />
              <Typography textAlign="center">
                <Link>Нажмите для загрузки</Link> или
                перетащите{" "}
                {`${maxFiles === 1 ? "файл" : "файлы"}`}
              </Typography>
              <Typography
                fontSize={14}
                sx={{
                  color:
                    theme.palette.blackAndWhite.black60,
                }}
              >
                {subtitle || getSubtitle(variant)}
              </Typography>
            </Stack>
          </UploadContainer>
        ) : null
      ) : null}
      {!files.length && !isFileAdd ? (
        <Typography
          sx={{ color: theme.palette.blackAndWhite.stroke }}
        >
          (Отсутствует)
        </Typography>
      ) : null}
    </Box>
  );
};

export default FileDropzone;
