import React from "react";
import { Field, useFormikContext } from "formik";
import { HexColorPicker } from "react-colorful";
import TextField, { BaseTextFieldProps } from "@mui/material/TextField";
import { Box, IconButton, InputAdornment, Popover } from "@mui/material";
import { useDebounceCallback } from "@/hooks";

interface IProps extends BaseTextFieldProps {
  name: string;
  onChange: (payload) => void;
}

/**
 * Колорпикер
 */

const ColorPicker: React.FC<IProps> = ({ name, onChange, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleChange = useDebounceCallback(onChange, 150);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "colour-picker-popover" : undefined;

  const formik = useFormikContext();
  if (!formik) {
    throw new Error("ColorPicker has to be used within <Formik>");
  }

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <React.Fragment>
          <TextField
            variant="outlined"
            fullWidth={true}
            size="small"
            label="Цвет"
            required
            {...field}
            {...props}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton id={id} onClick={handleClick}>
                    <Box
                      sx={{
                        width: 18,
                        height: 18,
                        borderRadius: "4px",
                        background: field.value
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Popover
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          >
            <Box p={0.3}>
              <HexColorPicker color={field.value} onChange={handleChange} />
            </Box>
          </Popover>
        </React.Fragment>
      )}
    </Field>
  );
};

export default ColorPicker;
