import { styled } from "@mui/material/styles";
import {
  Accordion as MuiAccordion,
  accordionClasses,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps as MuiAccordionProps,
  AccordionSummary as MuiAccordionSummary,
  accordionSummaryClasses,
  AccordionSummaryProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

interface AccordionProps extends MuiAccordionProps {
  noExpandable?: boolean;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0}
                square {...props} />
), {
  shouldForwardProp: (prop) => prop !== "noExpandable"
})<AccordionProps>(({ noExpandable }) => ({
  ...(noExpandable && {
    [`&.${accordionClasses.root} > .${accordionSummaryClasses.root}`]: {
      pointerEvents: "none"
    },
    [`&.${accordionClasses.root} > .${accordionSummaryClasses.root} > .${accordionSummaryClasses.expandIconWrapper}`]: {
      display: "none"
    },
  }),
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon/>}
    {...props}
  />
))(({ theme }) => ({
  width: "max-content",
  "&[aria-required=true] .MuiAccordionSummary-content": {
    "&:after": {
      content: "\" *\"",
    }
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.blackAndWhite.black
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    color: theme.palette.blue.main
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    color: theme.palette.blackAndWhite.gray,
  },
  padding: 0
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0
}));

export interface ICustomizedAccordions extends AccordionProps {
  label: string;
  required?: boolean;
  noExpandable?: boolean;
}

/** Аккордион
 * @param label - заголовок
 * @param children - основной контент
 * @param required - отвечает за звездочку над label
 * @param noExpandable - выключает возможность сжиматься и скрывает кнопку
 *  */

const FormAccordion: React.FC<ICustomizedAccordions> = ({
  label,
  children,
  required = false,
  noExpandable,
  ...props
}) => {
  return (
    <Accordion {...props} noExpandable={noExpandable}>
      <AccordionSummary
        aria-required={Boolean(required)}
        expandIcon={<ExpandMoreIcon/>}
      >
        {label}
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default FormAccordion;