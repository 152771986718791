import React from "react";
import { FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";

import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import { IProps, mapFormToValues, mapValueToForm, TForm, validationSchema } from "./const";
import ModalStepper from "@/components/ModalStepper";
import FirstStepForm from "./components/first";
import SecondStepForm from "./components/second";

/**
 * Модалка с формой
 * @param initialValues - начальные значения
 * @param onSubmit - коллбек после успешного запроса
 * */
const Agreement: React.FC<IProps> = ({
    handleClose,
    modalProps,
}) => {
    const baseInititialValues = {
        file_list: [],
        agreement_name: '',
        agreement_number: null,
        agreement_date: null,
        expiry_date: null,
        comment: '',
        agreement_type: null,
        counterparty: null,
        executive: null
    }

    const { agreementId, initialValues } = modalProps;
    const { api, toastStore, swrStore } = useStores();

    const addFiles = (id, files) => {
        files.forEach((file) => {
            const formData = new FormData();
            formData.append("file", file);
            if (!file.id) {
                api.statement
                    .addFileAgreement(id, formData)
                    .then(() => {
                        mutateDetail && mutateDetail();
                    });
            }
        });
    };

    const mutateDetail = agreementId
        ? swrStore.mutators[SWR_KEYS.getAgreementById(agreementId)]
        : null;

    const mutateList =
        swrStore.mutators[SWR_KEYS.getAgreementList()];

    const handleSubmit = (
        values: TForm,
        helpers: FormikHelpers<FormikValues>,
    ) => {
        helpers.setSubmitting(true);
        const promise = agreementId
            ? api.statement.patchAgreement(
                agreementId,
                mapFormToValues(values),
            )
            : api.statement.postAgreement(
                mapFormToValues(values),
            );
            
            promise
            .then((res) => {
                if (values.file_list.length) {
                    addFiles(res.id, values.file_list);
                }
                handleClose();
            })
            .catch((error: AxiosError) => {
                const errors = error.response?.data;
                if (errors) {
                    helpers.setTouched(errors).then(() => {
                        helpers.setErrors(errors);
                    });
                } else { 
                    const errorMessage = errorToString(error);
                    toastStore.createToast({
                        type: TOAST_TYPES.ALERT,
                        toastProps: {
                            message: errorMessage,
                            severity: "error",
                        },
                    });
                }
            })
            .finally(() => {
                helpers.setSubmitting(false);
                mutateDetail && mutateDetail();
                mutateList && mutateList();
            });
    };

    const values = initialValues
        ? {
            ...baseInititialValues,
            ...mapValueToForm(initialValues),
        }
        : baseInititialValues;

    const subtitle = modalProps.agreementId ? "Редактировать запись" : "Добавить запись";

    return (
    <ModalStepper
      title="Договор"
      subtitle={subtitle}
      initialValues={values}
      onSubmit={handleSubmit}
      handleClose={handleClose}
      enableReinitialize={true}
    >
      <ModalStepper.Step
        validationSchema={validationSchema}
      >
        <FirstStepForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      </ModalStepper.Step>

      <ModalStepper.Step>
        <SecondStepForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      </ModalStepper.Step>

    </ModalStepper>
    );
};

export default Agreement;
