import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { COUNTRIES_HEADER_LABELS, COUNTRIES_KEYS } from "../const";

import { TCountriesKeys } from "../interfaces";
import { EditingCellsCountry } from "../templates/Countries/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: ""
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить"
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const CountriesColumns: IColumn<TCountriesKeys>[] = [
  {
    label: COUNTRIES_HEADER_LABELS.iso,
    key: COUNTRIES_KEYS.iso,
    contentHeader: COUNTRIES_HEADER_LABELS.iso,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: COUNTRIES_HEADER_LABELS.name,
    key: COUNTRIES_KEYS.name,
    contentHeader: COUNTRIES_HEADER_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: COUNTRIES_HEADER_LABELS.full_name,
    key: COUNTRIES_KEYS.full_name,
    contentHeader: COUNTRIES_HEADER_LABELS.full_name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    key: "editing",
    label: COUNTRIES_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsCountry,
      styles: cellButtons
    }
  }
];
