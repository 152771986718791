import React from "react";
import { TableRow } from "@mui/material";

import Cell from "./CellWrapper";

import { IColumn, TEntityTable } from "../interfaces";
import { useMatches } from "@/apps/Respons";
import { TStatemenKeys } from "@/pages/Statement/interfaces";

/**
 * Интерфейс свойств компонента ProposalRow.
 * @interface
 * @template T - Тип данных текущей строки таблицы.
 */
interface IProps<T, TColums> {
  /**
   * Данные строки таблицы.
   */
  row: T;
  /**
   * Флаг, указывающий, выбрана ли строка.
   */
  isItemSelected: boolean;
  /**
   * Обработчик клика по строке.
   */
  handleClick: (event: React.MouseEvent<unknown>, id: number) => void;
  /**
   * Массив колонок таблицы.
   */
  columns: IColumn<TColums>[];
  /**
   * Массив строк таблицы.
   */
  visibleRows: T[];
}

/**
 * Компонент, представляющий строку таблицы.
 * @param {IProps<T, TColums>} props - Свойства компонента.
 * @returns {JSX.Element}
 * @template T
 */
function ProposalRow<T extends TEntityTable, TColums>({
  row,
  isItemSelected,
  handleClick,
  columns,
  visibleRows,
}: IProps<T, TColums>): JSX.Element {
  const { matches } = useMatches();

  const getTableStaleMedia = () => {
    const small = {
      display: "flex",
      flexDirection: "column",
      marginBottom: "50px",
      gap: "24px",
    };

    if (matches?.small) {
      return small;
    }
    return {};
  };

  return (
    <TableRow
      hover
      onClick={(event) => handleClick(event, row.id)}
      aria-checked={isItemSelected}
      key={row.id}
      selected={isItemSelected}
      sx={{ cursor: "pointer", ...getTableStaleMedia() }}
    >
      {columns.map((coll) => {
        const columnKey = coll.key as string;
        const columnData = columns.find((el) => el.key === columnKey);

        return (
          <Cell<T>
            key={columnKey}
            content={row[columnKey]}
            currentRowData={row}
            cellStyle={coll.bodyCellProps?.styles}
            {...coll.bodyCellProps}
            columnData={
              columnData as IColumn<TStatemenKeys>
            }
            cellProp={{
              "data-label": columnData?.label,
            }}
            visibleRows={visibleRows}
          />
        );
      })}
    </TableRow>
  );
}

export default ProposalRow;
