import yup from "@/utils/yup";
import {
  IObservationWeatherResistance
} from "@/api/interfaces/responses";
import { translateWeatherCondition } from "@/utils/helpers";

export const FIELDS_NAME = {
  WEATHER_CONDITION: "weather_condition",
  RATING: "rating",
} as const;

export interface IFormik {
  [FIELDS_NAME.WEATHER_CONDITION]: Record<string, string> | null,
  [FIELDS_NAME.RATING]: number | string
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.WEATHER_CONDITION]: yup.mixed().required(),
  [FIELDS_NAME.RATING]: yup.number().required(),
});

export const initialValues = {
  [FIELDS_NAME.WEATHER_CONDITION]: null,
  [FIELDS_NAME.RATING]: "",
};

export const serializeValueToForm = (values: IObservationWeatherResistance): IFormik => {
  return {
    weather_condition: values.weather_condition ? {
      id: values.weather_condition,
      label: translateWeatherCondition(values.weather_condition)
    } : null,
    rating: values.rating
  };
};