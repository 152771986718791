import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { RootStore } from "./Root";

export interface IToast {
  key: string;
  type: string;
  autoHide?: boolean;
  closing?: boolean;
  toastProps?: any
}

type TToast = IToast

export class ToastStore {
  rootStore: RootStore;
  toasts: TToast[];

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore;
    this.toasts = [];
    makeAutoObservable(this);
  }

  createToast = (props: Omit<TToast, 'key'>): void => {
    this.toasts.push({
      autoHide: true,
      ...props,
      key: uuidv4(),
    });
  };

  setClosing = (index: number): void => {
    this.toasts[index] = {
      ...this.toasts[index],
      closing: true
    };
  };

  deleteToast = (index: number): void => {
    this.toasts.splice(index, 1);
  };
}