import { resInterface } from "@/api/interfaces";
import { IBudgetaryTrailCalc } from "@/api/interfaces/responses";
import yup from "@/utils/yup";

export interface IProps {
    handleClose: () => void;
    modalProps: {
        key: string;
        budgetaryTrialCalcId?: number;
        initialValues?: resInterface.IBudgetaryTrailCalc;
    };
    onSubmit?: () => void;
}

export const BUDGETARY_TRIAL_CALC_FIELD_NAME = {
    YEAR_OF_TRIAL: 'year_of_trial',
    TYPE_OF_TRIAL: "type_of_trial",
    TYPE_OF_APPLICANT: "type_of_applicant",
    AMOUNT_OF_EXPERIENCES: "amount_of_experiences",
} as const

export interface TForm {
    [BUDGETARY_TRIAL_CALC_FIELD_NAME.YEAR_OF_TRIAL]: number | null,
    [BUDGETARY_TRIAL_CALC_FIELD_NAME.TYPE_OF_TRIAL]: string,
    [BUDGETARY_TRIAL_CALC_FIELD_NAME.TYPE_OF_APPLICANT]: string,
    [BUDGETARY_TRIAL_CALC_FIELD_NAME.AMOUNT_OF_EXPERIENCES]: number,
    non_field_errors?: string;
}

export const mapValueToForm = (value: IBudgetaryTrailCalc) => {
    return {
        [BUDGETARY_TRIAL_CALC_FIELD_NAME.YEAR_OF_TRIAL]: value?.year_of_trial,
        [BUDGETARY_TRIAL_CALC_FIELD_NAME.TYPE_OF_TRIAL]: value?.type_of_trial,
        [BUDGETARY_TRIAL_CALC_FIELD_NAME.TYPE_OF_APPLICANT]: value?.type_of_applicant,
        [BUDGETARY_TRIAL_CALC_FIELD_NAME.AMOUNT_OF_EXPERIENCES]: value?.amount_of_experiences,
    };
};

export const mapFormToValue = (values: TForm) => {
    return {
        [BUDGETARY_TRIAL_CALC_FIELD_NAME.YEAR_OF_TRIAL]: values?.year_of_trial,
        [BUDGETARY_TRIAL_CALC_FIELD_NAME.TYPE_OF_TRIAL]: values?.type_of_trial,
        [BUDGETARY_TRIAL_CALC_FIELD_NAME.TYPE_OF_APPLICANT]: values?.type_of_applicant,
        [BUDGETARY_TRIAL_CALC_FIELD_NAME.AMOUNT_OF_EXPERIENCES]: values?.amount_of_experiences,
    };
};


/*Validation of the form*/

export const validationSchema = yup.object().shape({
    [BUDGETARY_TRIAL_CALC_FIELD_NAME.YEAR_OF_TRIAL]: yup.mixed().required(),
    [BUDGETARY_TRIAL_CALC_FIELD_NAME.TYPE_OF_TRIAL]: yup.string(),
    [BUDGETARY_TRIAL_CALC_FIELD_NAME.TYPE_OF_APPLICANT]: yup.string(),
    [BUDGETARY_TRIAL_CALC_FIELD_NAME.AMOUNT_OF_EXPERIENCES]: yup.number().required(),
});
