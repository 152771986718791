import { Theme, CSSObject } from "@mui/material";

export const defaultModalTheme = (theme: Theme): CSSObject => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 800,
  display: "flex",
  flexDirection: "column",
  overflow: 'auto',
  maxHeight: "100vh",
  borderRadius: 8,
  background: theme.palette.primary.contrastText,
  boxShadow: "-4px 0px 20px 0px rgba(30, 30, 28, 0.10)"
});

export const w555ModalTheme = (theme: Theme): CSSObject => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 555,
  display: "flex",
  flexDirection: "column",
  borderRadius: 8,
  background: theme.palette.primary.contrastText,
  boxShadow: "-4px 0px 20px 0px rgba(30, 30, 28, 0.10)",
  maxHeight: "100vh",
  overflow: "auto"
});

export const fullscreenModalTheme = (theme: Theme): CSSObject => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: 'auto',
  maxHeight: "100vh",
  background: theme.palette.primary.contrastText,
  boxShadow: "-4px 0px 20px 0px rgba(30, 30, 28, 0.10)"
});
