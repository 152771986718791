import React from "react";
import AuthLayout from "../components/AuthLayout/AuthLayout";

export const EmailIsSend = () => {
  // const { email } = useCurrentUser();
  // const query = useQuery();
  return (
    <AuthLayout>
      <div>OLD PAGE</div>
    </AuthLayout>
  );
};
