import React, { useEffect, useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { Stack } from "@mui/system";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
} from "@mui/material";
import Autocomplete from "@/components/Autocomplete";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME } from "../const";
import { TChemistryType } from "@/api/types";

interface IProps {
  handleClose: () => void;
  modalProps: {
    cultivarId: number;
    type: TChemistryType;
    nameLabel: string;
    countLabel: string;
  };
}

const AddChemistryForm: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const { api, swrStore } = useStores();
  const [search, setSearch] = useState("");
  const formik = useFormikContext();

  const swrKey = SWR_KEYS.getChemistryList(modalProps.type);
  const getKeyOrganization = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    search: search,
    type: modalProps.type,
  });

  const { data, handleScroll, mutate, isEmpty } =
    useInfiniteScroll(
      getKeyOrganization,
      api.regulatoryInfo.getChemistryList,
      {
        revalidateFirstPage: false,
      },
    );

  const handleChange = (event, child) => {
    formik.setFieldValue(FIELDS_NAME.CHEMISTRY, child);
  };

  useEffect(() => {
    swrStore.addMutator(swrKey, mutate);
  }, []);

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <Field name={FIELDS_NAME.CHEMISTRY}>
          {({ field, meta }) => (
            <FormControl required>
              <Autocomplete
                {...field}
                onChange={handleChange}
                label={modalProps.nameLabel}
                required={true}
                data={data}
                handleScroll={handleScroll}
                search={setSearch}
                error={meta.touched && !!meta.error}
              />

              {isEmpty && (
                <Alert sx={{ mt: 2 }} severity="warning">
                  Справочник химии не заполнен по данной
                  культуре
                </Alert>
              )}

              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field name={FIELDS_NAME.QUANTITY_TOTAL}>
          {({ field, meta }) => (
            <TextField
              {...field}
              type="number"
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              label={modalProps.countLabel}
              fullWidth
              size="small"
              required
            />
          )}
        </Field>

        <Field name={FIELDS_NAME.PRICE}>
          {({ field, meta }) => (
            <TextField
              {...field}
              type="number"
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              label={"Цена за ед."}
              fullWidth
              size="small"
              required
            />
          )}
        </Field>
      </Stack>
      <Divider />
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default AddChemistryForm;
