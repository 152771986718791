import React from "react";
import { useParams } from "react-router-dom";
import { useFormikContext } from "formik";
import FileDropzone from "@/components/FileDropzone";
import {
  FIELDS_NAME,
  IFormik
} from "@/pages/AddObservation/const";
import {
  IMAGE_ACCEPT,
  IMAGE_SUBTITLE,
  DROPZONE_VARIANT,
  IFile
} from "@/components/FileDropzone/const";
import { Stack, Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useStores } from "@/hooks";

const Files = () => {
  const formik = useFormikContext<IFormik>();
  const {  observationId } = useParams();
  const { api } = useStores();

  const fetcher = (fd: FormData) =>
    api.agriculturalLabour.addObservationFileById(observationId as string, fd)
  const deleteMutation = (fileId: number) => {
    return api.agriculturalLabour.deleteObservationFileById(observationId as string, fileId)
  }
  const handleChangeFiles = (files: IFile[]) => {
    void formik.setFieldValue(FIELDS_NAME.FILE_LIST, files);
  };

  return (
    <Stack direction="column">
      <Box mb="20px">
        <Typography variant="h3">
          Фотографии
        </Typography>
      </Box>
      <Box mb={1.2}>
        <Typography variant="p14Medium">
          Фото
        </Typography>
      </Box>
      {
        observationId ? (
          <FileDropzone
            name={FIELDS_NAME.FILE_LIST}
            value={formik.values[FIELDS_NAME.FILE_LIST]}
            deleteMutation={deleteMutation}
            fetcher={fetcher}
            accept={IMAGE_ACCEPT}
            subtitle={IMAGE_SUBTITLE}
            variant={DROPZONE_VARIANT.IMG}
          />
        ) : <FileDropzone
          name={FIELDS_NAME.FILE_LIST}
          value={formik.values[FIELDS_NAME.FILE_LIST]}
          setValue={handleChangeFiles}
          accept={IMAGE_ACCEPT}
          subtitle={IMAGE_SUBTITLE}
          variant={DROPZONE_VARIANT.IMG}
        />
      }
    </Stack>
  );
};

export default Files;