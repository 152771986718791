import { IColumn } from "@/apps/Table/interfaces";
import {
  BranchCells,
  CultivarCells,
  NumberCells,
  StatusCells,
  TrialTypeCells,
  DateCells,
  EditingCells,    
} from "../components/PlanningCellComponents";

import { PLANNING_HEADER_LABELS, PLANNING_KEYS } from "../const";

import { TPlanningKeys } from "../interfaces";

import {
  cellAuthor,
  cellDate,
  cellNumber,
  cellStatus,
  headerCellStyle,
  trialTypeCellsStyle
} from "../style";
import { cellButtons } from "@/apps/Table/style";
/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const columns: IColumn<TPlanningKeys>[] = [

  {
    label:  PLANNING_HEADER_LABELS.number,
    key: PLANNING_KEYS.number,
    contentHeader: PLANNING_HEADER_LABELS.number,
    bodyCellProps: {
      render: NumberCells,
      styles: cellNumber
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  PLANNING_HEADER_LABELS.trialType,
    key: PLANNING_KEYS.trialType,
    contentHeader: PLANNING_HEADER_LABELS.trialType,
    bodyCellProps: {
      render: TrialTypeCells,
      styles: trialTypeCellsStyle
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  PLANNING_HEADER_LABELS.cultivar,
    key: PLANNING_KEYS.cultivarName,
    contentHeader: PLANNING_HEADER_LABELS.cultivar,
    bodyCellProps: {
      render: CultivarCells,
      styles: cellAuthor
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  PLANNING_HEADER_LABELS.branch,
    key: PLANNING_KEYS.branchName,
    contentHeader: PLANNING_HEADER_LABELS.branch,
    bodyCellProps: {
      render: BranchCells,
      styles: cellDate
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label:  PLANNING_HEADER_LABELS.createdAt,
    key: PLANNING_KEYS.createdAt,
    contentHeader: PLANNING_HEADER_LABELS.createdAt,
    bodyCellProps: {
      render: DateCells,
      styles: cellDate
    },
    headerCellProps: {
      styles: headerCellStyle 
    }
  },  
  {
    label:  PLANNING_HEADER_LABELS.status,
    key: PLANNING_KEYS.status,
    contentHeader: PLANNING_HEADER_LABELS.status,
    bodyCellProps: {
      render: StatusCells,
      styles: cellStatus
    },
    headerCellProps: {
      styles: headerCellStyle 
    }
  },
  {
    key: PLANNING_KEYS.editing,
    label: '',
    contentHeader: '',
    bodyCellProps: {
      render: EditingCells,
      styles: cellButtons,
    },
  }
];
