import { SxProps, Theme } from "@mui/material/styles";

// стили для ячеек таблицы Заявок

export const tableWrapperStyle = (theme: Theme): SxProps => ({
  width: "100%",
  padding: theme.spacing(3),
});

export const trialTypeCellsStyle = (): SxProps => ({
  whiteSpace: "nowrap",
});

export const cellStatusStyle = (
  theme: Theme,
  backgroundColor: string,
  textColor: string
): SxProps => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  padding: "3px 5px",
  color: textColor,
  backgroundColor: backgroundColor,
  borderRadius: "100px",
  minWidth: "100px",
});

export const headerCellStyle = (theme: Theme): SxProps => ({
  whiteSpace: "nowrap",
  color: theme.palette.blackAndWhite.black,
});

export const cellStatus = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
});

export const cellNumber = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  color: theme.palette.blackAndWhite.gray,
  whiteSpace: "nowrap",
});

export const cellTypes = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  // width: "100%",
});

export const cellAuthor = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  whiteSpace: "nowrap",
});

export const nameAuthorCellsStyle = (): SxProps => ({
  display: "flex",
  alignItems: "center",
  gap: "6px",
});

export const cellDate = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
});
