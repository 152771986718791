import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=10541-356532&mode=design&t=onNKNzCTtrqOl6ZV-0
/**
 * Иконка два листика
 */
const GerminationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24"
           fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_10541_356584"
              maskUnits="userSpaceOnUse" x="0" y="0"
              width="24" height="24">
          <rect width="24" height="24" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_10541_356584)">
          <path
            d="M11 21V13.025C9.93333 13.025 8.90833 12.8208 7.925 12.4125C6.94167 12.0042 6.075 11.425 5.325 10.675C4.575 9.925 4 9.05833 3.6 8.075C3.2 7.09167 3 6.06667 3 5V3H5C6.05 3 7.06667 3.20417 8.05 3.6125C9.03333 4.02083 9.9 4.6 10.65 5.35C11.1667 5.86667 11.5958 6.43333 11.9375 7.05C12.2792 7.66667 12.5417 8.325 12.725 9.025C12.8083 8.90833 12.9 8.79583 13 8.6875C13.1 8.57917 13.2083 8.46667 13.325 8.35C14.075 7.6 14.9417 7.02083 15.925 6.6125C16.9083 6.20417 17.9333 6 19 6H21V8C21 9.06667 20.7958 10.0917 20.3875 11.075C19.9792 12.0583 19.4 12.925 18.65 13.675C17.9 14.425 17.0375 15 16.0625 15.4C15.0875 15.8 14.0667 16 13 16V21H11ZM11 11C11 10.2 10.8458 9.4375 10.5375 8.7125C10.2292 7.9875 9.79167 7.34167 9.225 6.775C8.65833 6.20833 8.0125 5.77083 7.2875 5.4625C6.5625 5.15417 5.8 5 5 5C5 5.8 5.15 6.56667 5.45 7.3C5.75 8.03333 6.18333 8.68333 6.75 9.25C7.31667 9.81667 7.96667 10.25 8.7 10.55C9.43333 10.85 10.2 11 11 11ZM13 14C13.8 14 14.5625 13.85 15.2875 13.55C16.0125 13.25 16.6583 12.8167 17.225 12.25C17.7917 11.6833 18.2292 11.0333 18.5375 10.3C18.8458 9.56667 19 8.8 19 8C18.2 8 17.4333 8.15417 16.7 8.4625C15.9667 8.77083 15.3167 9.20833 14.75 9.775C14.1833 10.3417 13.75 10.9875 13.45 11.7125C13.15 12.4375 13 13.2 13 14Z"
            fill="currentColor"/>
          <path
            d="M11 21V13.025C9.93333 13.025 8.90833 12.8208 7.925 12.4125C6.94167 12.0042 6.075 11.425 5.325 10.675C4.575 9.925 4 9.05833 3.6 8.075C3.2 7.09167 3 6.06667 3 5V3H5C6.05 3 7.06667 3.20417 8.05 3.6125C9.03333 4.02083 9.9 4.6 10.65 5.35C11.1667 5.86667 11.5958 6.43333 11.9375 7.05C12.2792 7.66667 12.5417 8.325 12.725 9.025C12.8083 8.90833 12.9 8.79583 13 8.6875C13.1 8.57917 13.2083 8.46667 13.325 8.35C14.075 7.6 14.9417 7.02083 15.925 6.6125C16.9083 6.20417 17.9333 6 19 6H21V8C21 9.06667 20.7958 10.0917 20.3875 11.075C19.9792 12.0583 19.4 12.925 18.65 13.675C17.9 14.425 17.0375 15 16.0625 15.4C15.0875 15.8 14.0667 16 13 16V21H11ZM11 11C11 10.2 10.8458 9.4375 10.5375 8.7125C10.2292 7.9875 9.79167 7.34167 9.225 6.775C8.65833 6.20833 8.0125 5.77083 7.2875 5.4625C6.5625 5.15417 5.8 5 5 5C5 5.8 5.15 6.56667 5.45 7.3C5.75 8.03333 6.18333 8.68333 6.75 9.25C7.31667 9.81667 7.96667 10.25 8.7 10.55C9.43333 10.85 10.2 11 11 11ZM13 14C13.8 14 14.5625 13.85 15.2875 13.55C16.0125 13.25 16.6583 12.8167 17.225 12.25C17.7917 11.6833 18.2292 11.0333 18.5375 10.3C18.8458 9.56667 19 8.8 19 8C18.2 8 17.4333 8.15417 16.7 8.4625C15.9667 8.77083 15.3167 9.20833 14.75 9.775C14.1833 10.3417 13.75 10.9875 13.45 11.7125C13.15 12.4375 13 13.2 13 14Z"
            fill="black" fillOpacity="0.2"/>
        </g>
      </svg>

    </SvgIcon>
  );
};

export default GerminationIcon;