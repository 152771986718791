import React from "react";
import { Chip, ChipProps } from "@mui/material";
import Add from "@mui/icons-material/Add";

const chip = {
  padding: "2px 4px",
  "& .MuiChip-label": { display: "none" },
  "& .MuiSvgIcon-root": { margin: 0 }
};

const AddChip: React.FC<ChipProps> = (props) => {
  return (
    <Chip
      color="primary"
      icon={<Add/>}
      sx={chip}
      {...props}/>
  );
};

export default AddChip;