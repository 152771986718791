import React, { Fragment, useState } from 'react'
import { useInfiniteScroll, useStores } from '@/hooks';
import { Field, Form, useFormikContext } from 'formik';
import { Box, Button, Divider, FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import theme from '@/theme';
import { CULTIVAR_GROUP_FIELD_NAME, IProps, TForm } from '../const';
import Autocomplete from '@/components/Autocomplete';
import { CustomPaper } from '../../Employee/components/EmployeeFormSteps/first';
import { SWR_KEYS } from '@/const';
import { PRODUCT_FORM_TYPES } from '@/apps/Handboock/templates/CultivarUsageType/cells';

/**
 * Форма создания/редактирования фазы вегетации
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * */

export const CultivarGroupForm: React.FC<IProps> = ({ modalProps }) => {
  const { api, modalStore } = useStores();
  const formik = useFormikContext<TForm>();
  const [usageTypeSearch, setUsageTypeSearch] = useState("");
  const swrKeyUsageType = SWR_KEYS.getCultivarUsageTypeList();
  const [variantSearch, setVariantSearch] = useState("");
  const swrKeyVariant = SWR_KEYS.getVariantList();

  const title = modalProps.cultivarGroupId
    ? "Редактировать запись"
    : "Добавить запись";

  const getKeyUsageType = (index: number) => ({
    _key: swrKeyUsageType,
    page: index + 1,
    search: usageTypeSearch
  });

  const fetcherUsageType = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getCultivarUsageTypesList({
      page,
      search,
    });
  };

  const { data: dataUsageType, handleScroll: handleScrollUsageType } = useInfiniteScroll(
    getKeyUsageType,
    fetcherUsageType,
    {
      revalidateFirstPage: false,
    },
  );

  const seriaLizeData = dataUsageType.map(
    item => ({ name: `${item?.cultivar?.name} - ${PRODUCT_FORM_TYPES[item?.product_form]} - ${item?.name}`, id: item.id })
  )

  const handleChangeUsageType = (event, value) => {
    formik.setFieldValue(CULTIVAR_GROUP_FIELD_NAME.USAGE_TYPE, value);
  };

  const getKeyVariant = (index: number) => ({
    _key: swrKeyVariant,
    page: index + 1,
    search: variantSearch
  });

  const fetcherVariant = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getVariantList({
      page,
      search,
    });
  };

  const { data: dataVariant, handleScroll: handleScrollVariant } = useInfiniteScroll(
    getKeyVariant,
    fetcherVariant,
    {
      revalidateFirstPage: false,
    },
  );

  const seriaLizeVariantData = dataVariant.map(
    item => ({ name: `${item?.indicator?.name} - ${item?.severity}`, id: item.id })
  )

  const handleChangeVariant = (event, value) => {
    formik.setFieldValue(CULTIVAR_GROUP_FIELD_NAME.VARIANT, value);
  };

  return (
    <Fragment>
      <Box p={3}>
        <Typography fontSize={12} color={theme.palette.blackAndWhite.gray}>
          Группы культуры
        </Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>

        <Stack direction={"column"} gap={2} p={2}>
          <Field name={CULTIVAR_GROUP_FIELD_NAME.USAGE_TYPE}>
            {({ field, meta }) => (
              <FormControl>
                <Autocomplete
                  {...field}
                  label='Направление использования'
                  PaperComponent={CustomPaper}
                  onChange={handleChangeUsageType}
                  data={seriaLizeData}
                  handleScroll={handleScrollUsageType}
                  search={setUsageTypeSearch}
                  error={meta.touched && !!meta.error}
                  required
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={CULTIVAR_GROUP_FIELD_NAME.VARIANT}>
            {({ field, meta }) => (
              <FormControl>
                <Autocomplete
                  {...field}
                  label='Значение признака группы'
                  PaperComponent={CustomPaper}
                  onChange={handleChangeVariant}
                  data={seriaLizeVariantData}
                  handleScroll={handleScrollVariant}
                  search={setVariantSearch}
                  error={meta.touched && !!meta.error}
                  required
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>

        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red'
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type='submit'
            disabled={formik.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  )
}
