import { Dispatch, SetStateAction } from "react";
import {
  FiltersType
} from "@/components/Filters/interfaces";
import { IValue } from "@/interfaces";

export const getFilters = (
  cultivarTypeValue?: IValue[],
  selectedCultivarTypeValue?: IValue,
  handleScroll?: (
    event: React.UIEvent<HTMLElement, UIEvent>,
  ) => void,
  setValue?: Dispatch<SetStateAction<string>>
) => {
  return [
    {
      name: "culture",
      label: "Культура",
      type: FiltersType.autoComplete,
      selectedValue: selectedCultivarTypeValue,
      handleScroll: handleScroll,
      options: cultivarTypeValue,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
      setValue: setValue
    }
  ];
};
