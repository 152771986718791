import Yup from "@/utils/yup";
import { FIELD_NAME } from "./types";

// name 'Наименование субъекта' Если в subject_type указано физлицо, то отображается ФИО, если указано юрлицо - отображается Название компании
// first_name 'Имя' Если в subject_type указано физлицо, то ОБЯЗАТЕЛЬНОЕ ПОЛЕ
// second_name 'Отчество'
// last_name 'Фамилия'Если в subject_type указано физлицо, то ОБЯЗАТЕЛЬНОЕ ПОЛЕ
// company_name 'Название организации'Если в subject_type указано юрлицо, то ОБЯЗАТЕЛЬНОЕ ПОЛЕ
// address 'Адрес'Если в subject_type указано физлицо, то ОБЯЗАТЕЛЬНОЕ ПОЛЕ
// inn 'ИНН'Если в subject_type указано юрлицо, то ОБЯЗАТЕЛЬНОЕ ПОЛЕ
// snils 'Снилс'
// subject_type 'Тип субъекта': 'individual' -  'Физ. лицо' | 'legal_entity' -  'Юр. лицо'
// okpo 'ОКПО'
// kpp 'КПП'
// ogrn 'ОГРН'
// bank 'Банк'
// checking_account 'Расчетный счет'
// bik 'БИК'
// correspondent_account 'Корреспондентский счет'
// legal_address 'Юридический адрес' Если в subject_type указано юрлицо, то ОБЯЗАТЕЛЬНОЕ ПОЛЕ
// actual_address 'Фактический адрес'

const isIndividual = (() => {
  return {
    field: [FIELD_NAME.SUBJECT_TYPE],
    scheme: {
      is: "individual",
      then: (schema) => {
        return schema.required("Обязательное поле");
      },
      otherwise: (schema) => schema.nullable(),
    },
  };
})();

const isLegalEntity = (() => {
  return {
    field: [FIELD_NAME.SUBJECT_TYPE],
    scheme: {
      is: "legal_entity",
      then: (schema) => {
        return schema.required("Обязательное поле");
      },
      otherwise: (schema) => schema.nullable(),
    },
  };
})();

export const firstValidationSchema = Yup.object().shape({
  [FIELD_NAME.FIRST_NAME]: Yup.string().when(
    isIndividual.field,
    isIndividual.scheme,
  ),
  [FIELD_NAME.LAST_NAME]: Yup.string().when(
    isIndividual.field,
    isIndividual.scheme,
  ),
  [FIELD_NAME.OGRN]: Yup.string().when(
    isLegalEntity.field,
    {
      is: "legal_entity",
      then: (schema) =>
        schema
          .matches(/^[0-9]{13}$/, "ОГРН должен содержать 13 цифр")
          .max(13, "ОГРН должен содержать 13 цифр")
          .transform((value, originalValue) => {
            return /^[0-9]*$/.test(originalValue)
              ? originalValue
              : value;
          }),
      otherwise: (schema) => schema.nullable(),
    },
  ),
  [FIELD_NAME.INN]: Yup.string()
    .when(isIndividual.field, {
      is: "individual",
      then: (schema) =>
        schema
          .matches(
            /^[0-9]{12}$/,
            "ИНН должен содержать 12 цифр",
          )
          .transform((value, originalValue) => {
            return /^[0-9]*$/.test(originalValue)
              ? originalValue
              : value;
          }),
      otherwise: (schema) => schema.nullable(),
    })
    .when(isLegalEntity.field, {
      is: "legal_entity",
      then: (schema) =>
        schema
          .required("Обязательное поле")
          .matches(
            /^[0-9]{10}$/,
            "ИНН должен содержать 10 цифр",
          )
          .transform((value, originalValue) => {
            return /^[0-9]*$/.test(originalValue)
              ? originalValue
              : value;
          }),
      otherwise: (schema) => schema.nullable(),
    }),

  [FIELD_NAME.SNILS]: Yup.number()
    .max(99999999999)
    .nullable(),
  [FIELD_NAME.ADDRESS]: Yup.mixed().when(
    isIndividual.field,
    isIndividual.scheme,
  ),
  [FIELD_NAME.COMPANY_NAME]: Yup.string().when(
    isLegalEntity.field,
    isLegalEntity.scheme,
  ),
  [FIELD_NAME.KPP]: Yup.string().when(isLegalEntity.field, {
    is: "legal_entity",
    then: (schema) =>
      schema
        .matches(/^[0-9]{9}$/, "Необходимо 9 цифр")
        .transform((value, originalValue) => {
          return /^[0-9]*$/.test(originalValue)
            ? originalValue
            : value;
        }),
    otherwise: (schema) => schema.nullable(),
  }),
  [FIELD_NAME.OKPO]: Yup.string().when(
    isLegalEntity.field,
    {
      is: "legal_entity",
      then: (schema) =>
        schema
          .matches(/^[0-9]{1,10}$/, "Необходимо до 10 цифр")
          .max(10, "Необходимо до 10 цифр")
          .transform((value, originalValue) => {
            return /^[0-9]*$/.test(originalValue)
              ? originalValue
              : value;
          }),
      otherwise: (schema) => schema.nullable(),
    },
  ),
  [FIELD_NAME.LEGAL_ADDRESS]: Yup.mixed().when(
    isLegalEntity.field,
    isLegalEntity.scheme,
  ),
});

export const secondValidationSchema = Yup.object().shape({
  [FIELD_NAME.BIK]: Yup.string()
    .matches(/^[0-9]{9}$/, "Необходимо 9 цифр")
    .transform((value, originalValue) => {
      return /^[0-9]*$/.test(originalValue)
        ? originalValue
        : value;
    }),
  [FIELD_NAME.CHECKING_ACCOUNT]: Yup.string()
    .matches(/^[0-9]{20}$/, "Необходимо 20 цифр")
    .transform((value, originalValue) => {
      return /^[0-9]*$/.test(originalValue)
        ? originalValue
        : value;
    }),

  [FIELD_NAME.BANK_INN]: Yup.string()
    .matches(
      /^[0-9]{10}$/,
      "ИНН должен содержать 10 цифр",
    )
    .nullable(),

  [FIELD_NAME.BANK_KPP]: Yup.string()
    .matches(/^[0-9]{9}$/, "Необходимо 9 цифр")
    .transform((value, originalValue) => {
      return /^[0-9]*$/.test(originalValue)
        ? originalValue
        : value;
    }),

  [FIELD_NAME.CORRESPONDENT_ACCOUNT]: Yup.string()
    .matches(/^[0-9]{20}$/, "Необходимо 20 цифр")
    .transform((value, originalValue) => {
      return /^[0-9]*$/.test(originalValue)
        ? originalValue
        : value;
    }),
});

const phoneSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(
      /^(\+?\d{1,3}[- ]?)?\d{10}$/,
      "Неверный формат номера телефона",
    )
    .nullable(),
});

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /.*@.*/g,
      "Неверный формат электронной почты",
    )
    .nullable(),
});

export const lastValidationSchema = Yup.object().shape({
  [FIELD_NAME.EMAILS]: Yup.array()
    .of(emailSchema)
    .nullable(),
  [FIELD_NAME.PHONES]: Yup.array()
    .of(phoneSchema)
    .nullable(),
});
