import { AxiosRequestConfig, AxiosResponse } from "axios";
import AbstractApi from "@/api/abstract";
import {
  apiInterface,
  reqInterface,
  resInterface
} from "@/api/interfaces";
import {
  IBudgetaryTrailCalc,
  IPaginatedResult
} from "@/api/interfaces/responses";

class TrialApi implements apiInterface.ITrialApi {
  ctx: AbstractApi;

  constructor(ctx: AbstractApi) {
    this.ctx = ctx;
  }

  
  getBudgetaryTrailCalc = async (
    params: reqInterface.IList,
  ) => {
    const res = await this.ctx.get<
    reqInterface.IList,
    IPaginatedResult<resInterface.IBudgetaryTrailCalc>
    >("/trial/budgetary_trials_calc/", params);
    return res.data;
  };
  
  getBudgetaryTrailCalcById = async (id: number) => {
    const res = await this.ctx.get<
      reqInterface.IList,
      resInterface.IBudgetaryTrailCalc
    >(`/trial/budgetary_trials_calc/${id}/`);
    return res.data;
  };

  postBudgetaryTrailCalc = async (
    params: reqInterface.IBudgetaryTrailCalcReq,
  ): Promise<IBudgetaryTrailCalc> => {
    const res = await this.ctx.post<
      reqInterface.IBudgetaryTrailCalcReq,
      IBudgetaryTrailCalc
    >("/trial/budgetary_trials_calc/", params);
    return res.data;
  };

  patchBudgetaryTrailCalc = async (
    id: number,
    params: reqInterface.IBudgetaryTrailCalcReq,
  ): Promise<IBudgetaryTrailCalc> => {
    const res = await this.ctx.patch<
      reqInterface.IBudgetaryTrailCalcReq,
      IBudgetaryTrailCalc
    >(`/trial/budgetary_trials_calc/${id}/`, params);
    return res.data;
  };

  deleteBudgetaryTrailCalc = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      Promise<resInterface.IBudgetaryTrailCalc>
    >(`/trial/budgetary_trials_calc/${id}/`);
    return res.data;
  };

  getTrialList = async (
    params: reqInterface.ITrialListReq,
    axiosParams: AxiosRequestConfig = {}
  ) => {
    const res = await this.ctx.get<reqInterface.ITrialListReq, IPaginatedResult<resInterface.ITrial>>(
      "/trial/trial/",
      params,
      axiosParams
    );

    return res.data;
  };

  getTrial = async (
    id: number | string,
    params = {},
    axiosParams: AxiosRequestConfig = {}
  ) => {
    const res = await this.ctx.get<unknown, resInterface.ITrial>(
      `/trial/trial/${id}/`,
      params,
      axiosParams
    );

    return res.data;
  };

  pathTrial = async (
    id: number | string,
    params = {},
    axiosParams: AxiosRequestConfig = {}
  ): Promise<any> => {
    const res = await this.ctx.patch<unknown, resInterface.ITrial>(
      `/trial/trial/${id}/`,
      params,
      axiosParams
    );

    return res.data;
  };

  deleteTrial = async (id: number) => {
    const res = await this.ctx.delete<unknown, AxiosResponse>(
      `/trial/trial/${id}/`,
      {}
    );
    return res.data;
  };

  postHpTrial = async (params: reqInterface.IHpTrialReq) => {
    const res = await this.ctx.post<reqInterface.IHpTrialReq, AxiosResponse>(
      "/trial/trial/",
      params
    );
    return res.data;
  };

  postOosTrial = async (params: reqInterface.IOosTrialReq) => {
    const res = await this.ctx.post<reqInterface.IOosTrialReq, AxiosResponse>(
      "/trial/trial/",
      params
    );
    return res.data;
  };

  postPhytoTrial = async (params: reqInterface.IPhytoTrialReq) => {
    const res = await this.ctx.post<reqInterface.IPhytoTrialReq, AxiosResponse>(
      "/trial/trial/",
      params
    );
    return res.data;
  };

  postLabTrial = async (params: reqInterface.ILabTrialReq) => {
    const res = await this.ctx.post<reqInterface.ILabTrialReq, AxiosResponse>(
      "/trial/trial/",
      params
    );
    return res.data;
  };

}

export default TrialApi;
