import { makeAutoObservable } from 'mobx'
import { RootStore } from './Root'

export class Tooltip {
  isShow: boolean
  rootStore: RootStore
  content: string | number | null
  domId: null | string

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.isShow = false
    this.content = null
    this.domId = null
    makeAutoObservable(this)
  }

  setDomId = (domId: string): void => {
    this.domId = domId
  }

  setContent = (content: string): void => {
    this.content = content
  }

  end = (): void => {
    this.isShow = false
    this.content = null
    this.domId = null
  }

  show = (): void => {
    if (this.domId === null) {
      console.error(
        'Нельзя показать тултип, дом элемент не определен!'
      )
      return
    }
    this.isShow = true
  }

  hide = (): void => {
    this.isShow = false
  }

  isOpen = (): boolean => Boolean(this.isShow)
}
