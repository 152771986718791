import {
    FiltersType
} from "@/components/Filters/interfaces";
import { IFilter, IValue } from "@/interfaces";


export const REASON_TYPE_FILTER: IValue[] = [
    {
        key: "failure",
        label: "Незакладка семян",
    },
    {
        key: "rejection",
        label: "Браковка сортоопыта",
    },
    {
        key: "death",
        label: "Гибель сортоопыта",
    },
];

export const getFilters = (
    selectedType?: any,
): IFilter[] => {
    return [
        {
            name: "type_of_reason",
            label: "Тип причины",
            type: FiltersType.autoComplete,
            selectedValue: selectedType,
            value: "",
            options: REASON_TYPE_FILTER,
            style: { width: "230px", flexGrow: 1 },
        }
    ];
};