import React from "react"
import { IIcon } from "./interfaces"

const MoneyIcon = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg
      className={className}
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33341 6.66663C8.77786 6.66663 8.30564 6.47218 7.91675 6.08329C7.52786 5.6944 7.33341 5.22218 7.33341 4.66663C7.33341 4.11107 7.52786 3.63885 7.91675 3.24996C8.30564 2.86107 8.77786 2.66663 9.33341 2.66663C9.88897 2.66663 10.3612 2.86107 10.7501 3.24996C11.139 3.63885 11.3334 4.11107 11.3334 4.66663C11.3334 5.22218 11.139 5.6944 10.7501 6.08329C10.3612 6.47218 9.88897 6.66663 9.33341 6.66663ZM4.66675 8.66663C4.30008 8.66663 3.98619 8.53607 3.72508 8.27496C3.46397 8.01385 3.33341 7.69996 3.33341 7.33329V1.99996C3.33341 1.63329 3.46397 1.3194 3.72508 1.05829C3.98619 0.797182 4.30008 0.666626 4.66675 0.666626H14.0001C14.3667 0.666626 14.6806 0.797182 14.9417 1.05829C15.2029 1.3194 15.3334 1.63329 15.3334 1.99996V7.33329C15.3334 7.69996 15.2029 8.01385 14.9417 8.27496C14.6806 8.53607 14.3667 8.66663 14.0001 8.66663H4.66675ZM6.00008 7.33329H12.6667C12.6667 6.96663 12.7973 6.65274 13.0584 6.39163C13.3195 6.13052 13.6334 5.99996 14.0001 5.99996V3.33329C13.6334 3.33329 13.3195 3.20274 13.0584 2.94163C12.7973 2.68051 12.6667 2.36663 12.6667 1.99996H6.00008C6.00008 2.36663 5.86953 2.68051 5.60841 2.94163C5.3473 3.20274 5.03341 3.33329 4.66675 3.33329V5.99996C5.03341 5.99996 5.3473 6.13052 5.60841 6.39163C5.86953 6.65274 6.00008 6.96663 6.00008 7.33329ZM13.3334 11.3333H2.00008C1.63341 11.3333 1.31953 11.2027 1.05841 10.9416C0.797304 10.6805 0.666748 10.3666 0.666748 9.99996V2.66663H2.00008V9.99996H13.3334V11.3333Z"
        fill="#00A049"
      />
    </svg>
  )
}

export default MoneyIcon
