import React from "react";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { AW_NAVS } from "../const";

/**
 * хедер для таблиц сх работ
 * @returns React.FC
 */

const HeaderAgriculturalWorks: React.FC = () => {

  return (
    <Stack direction={"row"} justifyContent={"center"}
      alignItems={"center"}>
      <Typography
        variant="h3"
        data-qa="pageName"
      >
        {AW_NAVS.location}
      </Typography>
    </Stack>
  );
};

export default HeaderAgriculturalWorks;
