import React from "react";
import { ILogo } from "./interfaces";

const LogoText = ({ className }: ILogo): React.ReactElement => {
  return (
    <svg
      width="114"
      height="10"
      viewBox="0 0 114 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.759 0.837891V2.08692H1.51978V9.16616H0V0.841957H4.76307L4.759 0.837891Z"
        fill="#3C6C3D"
      />
      <path
        d="M12.8347 4.91438C12.8347 7.70539 11.1397 9.30025 8.8417 9.30025C6.54369 9.30025 5.00354 7.5101 5.00354 5.06491C5.00354 2.61973 6.61296 0.70752 8.96394 0.70752C11.3149 0.70752 12.8347 2.55056 12.8347 4.91844V4.91438ZM6.61296 5.03643C6.61296 6.72894 7.46861 8.07562 8.92727 8.07562C10.3859 8.07562 11.2294 6.71673 11.2294 4.9754C11.2294 3.40902 10.4511 1.92401 8.92727 1.92401C7.40341 1.92401 6.61296 3.31951 6.61296 5.03643Z"
        fill="#3C6C3D"
      />
      <path
        d="M20.242 8.91381C19.8835 9.10097 19.089 9.28405 18.0907 9.28405C15.4423 9.28405 13.8329 7.62816 13.8329 5.09754C13.8329 2.35535 15.7397 0.699463 18.2863 0.699463C19.2886 0.699463 20.0098 0.906953 20.3154 1.06969L19.9813 2.28212C19.5861 2.10717 19.0401 1.9607 18.3474 1.9607C16.6524 1.9607 15.4301 3.02259 15.4301 5.0243C15.4301 6.85107 16.5057 8.02687 18.3393 8.02687C18.9586 8.02687 19.5983 7.90482 19.9935 7.71767L20.242 8.91788V8.91381Z"
        fill="#3C6C3D"
      />
      <path
        d="M27.3479 8.91381C26.9893 9.10097 26.1989 9.28405 25.1966 9.28405C22.5481 9.28405 20.9387 7.62816 20.9387 5.09754C20.9387 2.35535 22.8415 0.699463 25.3921 0.699463C26.3945 0.699463 27.1116 0.906953 27.4212 1.06969L27.0871 2.28212C26.6919 2.10717 26.15 1.9607 25.4573 1.9607C23.7623 1.9607 22.54 3.02259 22.54 5.0243C22.54 6.85107 23.6157 8.02687 25.4451 8.02687C26.0644 8.02687 26.7082 7.90482 27.1034 7.71767L27.352 8.91788L27.3479 8.91381Z"
        fill="#3C6C3D"
      />
      <path
        d="M35.884 4.91438C35.884 7.70539 34.189 9.30025 31.8869 9.30025C29.5849 9.30025 28.0529 7.5101 28.0529 5.06491C28.0529 2.61973 29.6623 0.70752 32.0092 0.70752C34.3561 0.70752 35.884 2.55056 35.884 4.91844V4.91438ZM29.6623 5.03643C29.6623 6.72894 30.5179 8.07562 31.9766 8.07562C33.4352 8.07562 34.2787 6.71673 34.2787 4.9754C34.2787 3.40902 33.5004 1.92401 31.9766 1.92401C30.4527 1.92401 29.6623 3.31951 29.6623 5.03643Z"
        fill="#3C6C3D"
      />
      <path
        d="M37.3101 0.947489C37.856 0.849844 38.6098 0.776611 39.6366 0.776611C40.7489 0.776611 41.5679 1.01258 42.0976 1.45605C42.5947 1.86291 42.9165 2.51794 42.9165 3.29909C42.9165 4.08025 42.668 4.74342 42.1994 5.18689C41.5923 5.7931 40.6389 6.07382 39.5632 6.07382C39.278 6.07382 39.0173 6.06162 38.8217 6.025V9.16183H37.3101V0.947489ZM38.8217 4.82479C39.0091 4.87361 39.2536 4.88582 39.5632 4.88582C40.7 4.88582 41.3968 4.3325 41.3968 3.35198C41.3968 2.42843 40.753 1.93207 39.7018 1.93207C39.2821 1.93207 38.9847 1.96868 38.8258 2.0053V4.82072L38.8217 4.82479Z"
        fill="#3C6C3D"
      />
      <path
        d="M46.0173 2.10727H43.6418V0.837891H49.941V2.10727H47.5411V9.16209H46.0213V2.10727H46.0173Z"
        fill="#3C6C3D"
      />
      <path
        d="M52.5323 0.837891V4.40599H52.7809L55.3315 0.837891H57.1732L54.154 4.59314C55.3682 4.81691 55.8775 5.6062 56.2442 6.65588C56.5538 7.53061 56.8146 8.37279 57.1854 9.16209H55.5515C55.2663 8.59249 55.0667 7.90085 54.8589 7.27023C54.5247 6.25716 54.0806 5.5289 52.8909 5.5289H52.5323V9.16209H51.037V0.837891H52.5323Z"
        fill="#3C6C3D"
      />
      <path
        d="M65.4688 4.91438C65.4688 7.70539 63.7739 9.30025 61.4759 9.30025C59.1778 9.30025 57.6377 7.5101 57.6377 5.06491C57.6377 2.61973 59.2471 0.70752 61.5981 0.70752C63.9491 0.70752 65.4688 2.55056 65.4688 4.91844V4.91438ZM59.2471 5.03643C59.2471 6.72894 60.1028 8.07562 61.5614 8.07562C63.0201 8.07562 63.8635 6.71673 63.8635 4.9754C63.8635 3.40902 63.0853 1.92401 61.5614 1.92401C60.0376 1.92401 59.2471 3.31951 59.2471 5.03643Z"
        fill="#3C6C3D"
      />
      <path
        d="M73.8907 5.76454C73.8418 4.69045 73.7766 3.3926 73.7889 2.26968H73.7522C73.4833 3.28275 73.1329 4.40566 72.7621 5.45534L71.5479 9.06005H70.3704L69.258 5.50009C68.9361 4.4382 68.6387 3.30309 68.4187 2.26561H68.3942C68.3576 3.35191 68.3087 4.67418 68.2476 5.8093L68.0642 9.15769H66.6422L67.2004 0.833496H69.2051L70.293 4.18189C70.5904 5.15834 70.8512 6.14699 71.059 7.07461H71.0956C71.3197 6.1714 71.6009 5.14613 71.9268 4.16968L73.0758 0.833496H75.056L75.5409 9.15769H74.0537L73.8948 5.76048L73.8907 5.76454Z"
        fill="#3C6C3D"
      />
      <path
        d="M78.6091 0.837562V3.77504C78.6091 5.09731 78.5724 6.32193 78.5113 7.43264L78.5357 7.44485C78.9676 6.50909 79.5136 5.50416 80.0555 4.5684L82.2557 0.837562H83.8V9.16176H82.3902V6.20801C82.3902 4.89795 82.4024 3.83607 82.5043 2.75791H82.4798C82.0846 3.70994 81.5264 4.74742 80.9927 5.63842L78.8902 9.15769H77.1912V0.833496H78.6009L78.6091 0.837562Z"
        fill="#3C6C3D"
      />
      <path
        d="M91.6434 8.91381C91.2848 9.10097 90.4944 9.28405 89.492 9.28405C86.8436 9.28405 85.2383 7.62816 85.2383 5.09754C85.2383 2.35535 87.1411 0.699463 89.6917 0.699463C90.694 0.699463 91.4111 0.906953 91.7208 1.06969L91.3867 2.28212C90.9915 2.10717 90.4455 1.9607 89.7569 1.9607C88.0619 1.9607 86.8355 3.02259 86.8355 5.0243C86.8355 6.85107 87.9111 8.02687 89.7406 8.02687C90.3599 8.02687 91.0037 7.90482 91.3989 7.71767L91.6434 8.91788V8.91381Z"
        fill="#3C6C3D"
      />
      <path
        d="M98.7534 8.91381C98.3948 9.10097 97.6044 9.28405 96.602 9.28405C93.9577 9.28405 92.3483 7.62816 92.3483 5.09754C92.3483 2.35535 94.2551 0.699463 96.8017 0.699463C97.804 0.699463 98.5211 0.906953 98.8308 1.06969L98.4967 2.28212C98.1014 2.10717 97.5555 1.9607 96.8628 1.9607C95.1678 1.9607 93.9414 3.02259 93.9414 5.0243C93.9414 6.85107 95.0171 8.02687 96.8506 8.02687C97.4699 8.02687 98.1137 7.90482 98.5089 7.71767L98.7574 8.91788L98.7534 8.91381Z"
        fill="#3C6C3D"
      />
      <path
        d="M101.585 0.837562V3.77504C101.585 5.09731 101.548 6.32193 101.487 7.43264L101.512 7.44485C101.944 6.50909 102.49 5.50416 103.031 4.5684L105.232 0.837562H106.78V9.16176H105.37V6.20801C105.37 4.89795 105.382 3.83607 105.48 2.75791H105.456C105.061 3.70994 104.502 4.74742 103.969 5.63842L101.866 9.15769H100.171V0.833496H101.581L101.585 0.837562Z"
        fill="#3C6C3D"
      />
      <path
        d="M107.901 9.16183C108.047 8.91365 108.169 8.61665 108.284 8.30745C108.63 7.35541 108.842 6.2976 109.498 5.6507C109.685 5.46762 109.93 5.31708 110.19 5.23164V5.19502C109.212 5.02008 108.398 4.32843 108.398 3.15669C108.398 2.40402 108.719 1.81001 109.212 1.41537C109.795 0.959692 110.72 0.776611 111.788 0.776611C112.602 0.776611 113.421 0.849844 114 0.947489V9.16183H112.492V5.74021H111.873C111.441 5.74021 111.107 5.85412 110.846 6.09823C110.3 6.63935 110.08 7.76634 109.807 8.50681C109.73 8.72651 109.661 8.91365 109.522 9.16183H107.901ZM112.492 2.00937C112.329 1.97276 112.032 1.92393 111.604 1.92393C110.687 1.92393 109.921 2.28196 109.921 3.27061C109.921 4.14941 110.7 4.6295 111.702 4.6295C111.999 4.6295 112.297 4.6295 112.497 4.60509V2.00937H112.492Z"
        fill="#3C6C3D"
      />
    </svg>
  );
};

export default LogoText;
