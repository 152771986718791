import yup from "@/utils/yup";
import { IVariety } from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  VARIETY: "variety",
  STANDARD: "standard"
} as const;

export interface IFormik {
  [FIELDS_NAME.VARIETY]: IVariety | null,
  [FIELDS_NAME.STANDARD]: boolean
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.VARIETY]: yup.mixed().required(),
});

export const initialValues = {
  [FIELDS_NAME.VARIETY]: null,
  [FIELDS_NAME.STANDARD]: false
};