import React, { useState } from "react"
import { DatePicker } from "@mui/x-date-pickers"
import { useQuery } from "@/hooks";
import { Box } from "@mui/material";
import qs from "qs";
import { useSearchParams } from "react-router-dom";
import { IYearsRangeQueryParams } from "../interfaces";
import { isNull } from "@/utils/helpers";

const YearsRange: React.FC = () => {
  
  const [maxYear, setMaxYear] = useState<Date | null>(null)
  const [minYear, setMinYear] = useState<Date | null>(null)
  const [, setSearchParams] = useSearchParams();
  const query = useQuery<IYearsRangeQueryParams>();

  const handleChangeYearMax = (value: Date) => {
    setMaxYear(value)
    const date = new Date(value);
    const formattedValue =!isNull(value) ? date.getFullYear() : undefined
    const queryParams = qs.stringify(
      {
        ...query,
        year_max: formattedValue,
        page: 1,
      },
      { arrayFormat: "comma" }
    );
    setSearchParams(queryParams);
  }

  const handleChangeYearMin = (value: Date) => {
    setMinYear(value)
    const date = new Date(value);
    const formattedValue =!isNull(value) ? date.getFullYear() : undefined

    const queryParams = qs.stringify(
      {
        ...query,
        year_min: formattedValue,
        page: 1,
      },
      { arrayFormat: "comma" }
      );
    setSearchParams(queryParams);
  }
  
  return (
    <Box>
      <DatePicker
        name={"year"}
        onChange={handleChangeYearMin}
        label="Год от"
        openTo="year"
        views={["year"]}
        maxDate={maxYear}
        slotProps={{
          field: { clearable: true },
          textField: {
            size: "small",
          },
        }}
        sx={{
          "& .MuiInputBase-root": {
            maxWidth: "145px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }
        }}
      />
      <DatePicker
        name={"year"}
        onChange={handleChangeYearMax}
        label="Год по"
        openTo="year"
        views={["year"]}
        minDate={minYear}
        slotProps={{
          field: { clearable: true },
          textField: {
            size: "small",
          },
        }}
        sx={{
          "& .MuiInputBase-root": {
            maxWidth: "145px",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }
        }}
      />
    </Box>
  )
}

export default YearsRange