import { useState } from "react";
import { IStatementReq } from "@/api/interfaces/requests";
import { useParams } from "react-router-dom";
import { useStores } from "@/hooks";
import { IStatement } from "@/api/interfaces/responses";
import { useFormikContext } from "formik";
import useToastContent, {
  TOAST_CONTENT_TYPE
} from "@/hooks/useToastContent";

interface IUseAddStatement {
  checkDisabledByName: (name: string) => boolean;
  addDisabledFieldByName: (name: string) => void;
  removeDisabledFieldByName: (name: string) => void;
  saveStatementValue: (name: string, payload: Partial<IStatementReq> | any) => void;
  removeStatementValue: (name: string, id: number) => Promise<void>;
}

/** Хук для мететодов страницы добавления заявки */
export const useAddStatement = (): IUseAddStatement => {
  const { id } = useParams();
  const { api, toastStore } = useStores();
  const toastContent = useToastContent(TOAST_CONTENT_TYPE.SERVER_ERROR);
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const formik = useFormikContext<IStatement>();

  const checkDisabledByName = (name: string): boolean => disabledFields.some(el => el === name);
  const addDisabledFieldByName = (name: string): void => setDisabledFields((prev) => ([...prev, name]));

  const removeDisabledFieldByName = (name: string): void => setDisabledFields(prev => (prev.filter((el) => el !== name)));

  const saveStatementValue = (name: string, payload: Partial<IStatementReq> | any) => {
    addDisabledFieldByName(name);
    api.statement.patchStatementById(Number(id), payload).finally(() => {
      removeDisabledFieldByName(name);
    });
  };

  const removeStatementValue = (name: string, valueId: number): Promise<void> => {
    const values = formik.values[name];
    const newValues = Array.isArray(values) ? values.filter(val => val.id !== valueId) : null;
    const payload = Array.isArray(newValues) ? newValues.map(el => el.id) : null;
    addDisabledFieldByName(name);
    return api.statement.patchStatementById(Number(id), { [name]: payload }).then(() => {
      formik.setFieldValue(name, newValues);
    }).catch(() => {
      toastStore.createToast(toastContent);
      throw new Error('ошибка')
    })
      .finally(() => {
        removeDisabledFieldByName(name);
      });
  };

  return {
    checkDisabledByName,
    addDisabledFieldByName,
    removeDisabledFieldByName,
    saveStatementValue,
    removeStatementValue,
  };

};