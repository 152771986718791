import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Box,
  BoxProps,
  IconButton,
  svgIconClasses,
  typographyClasses
} from "@mui/material";
import NavigateBeforeIcon
  from "@mui/icons-material/NavigateBefore";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";
import {
  HpIcon,
  LabIcon,
  OSIcon,
  PhytoIcon
} from "@/components/icons";
import { TRIAL_TYPE, LOCATION_KEY } from "@/const";
import { TPlanningTrialType, } from "@/api/types";

interface StyledBoxProps extends BoxProps {
  trialType?: string;
}

export const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== "trialType" })<StyledBoxProps>(({
  theme,
  trialType
}) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(.5),
  [`& .${svgIconClasses.root}`]: {
    fontSize: 20,
  },
  [`& .${typographyClasses.root}`]: {
    color: "inherit",
    fontSize: 12
  },
  ...(trialType === TRIAL_TYPE.HP && {
    color: theme.palette.primary.main,
  }),
  ...(trialType === TRIAL_TYPE.PHYTO && {
    color: theme.palette.red.main,
  }),
  ...(trialType === TRIAL_TYPE.OOS && {
    color: theme.palette.another.orange,
  }),
  ...(trialType === TRIAL_TYPE.LAB && {
    color: theme.palette.another.violet,
  }),
}));

const types = {
  [TRIAL_TYPE.HP]:
    <>
      <HpIcon />
      <Typography>Хоз.полезность</Typography>
    </>,
  [TRIAL_TYPE.OOS]:
    <>
      <OSIcon />
      <Typography>
        Отличимость, однородность и стабильность
      </Typography>
    </>,
  [TRIAL_TYPE.PHYTO]:
    <>
      <PhytoIcon />
      <Typography>Фитопатология</Typography>
    </>,
  [TRIAL_TYPE.LAB]:
    <>
      <LabIcon />
      <Typography>Лабораторные испытания</Typography>
    </>
};

interface IProps {
  title?: string;
  type: TPlanningTrialType;
}

/** Шапка для страницы добавления испытания */
export const HeaderEditTrial: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { headerStore } = useStores();

  const {
    type,
    title
  }: IProps = headerStore.getProps();

  const handleBack = () => {
    if (location.key !== LOCATION_KEY.DEFAULT) {
      navigate(-1);
    } else
      navigate({
        pathname: ROUTES_PATHS.statements
      });
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <IconButton onClick={handleBack}>
        <NavigateBeforeIcon />
      </IconButton>
      <Box>
        <Typography fontSize={24} fontWeight={500}
          variant="h3" noWrap>
          {title}
        </Typography>
        <StyledBox trialType={type}>
          {types[type]}
        </StyledBox>
      </Box>
    </Box>
  );
};

export default HeaderEditTrial;
