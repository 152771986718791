import AbstractApi from "@/api/abstract";
import {
  apiInterface,
  reqInterface,
  resInterface,
} from "@/api/interfaces";
import {
  IExpensePaginatedResult,
  IPaginatedResult,
} from "@/api/interfaces/responses";
import { TOKEN_KEYS } from "@/const";
import { getCookie } from "@/utils/cookie";
import { IOffBudgetFile } from "@/api/interfaces/requests";
import { AxiosResponse } from "axios";

class OffBudgetApi implements apiInterface.IOffBudgetApi {
  ctx: AbstractApi;

  constructor(ctx: AbstractApi) {
    this.ctx = ctx;
  }

  pathOffBudgetById = async (
    id: number,
    params: Partial<reqInterface.IOffBudget>,
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IOffBudget,
      reqInterface.IOffBudget
    >(`/extrabudgetary/com_plan_fact/${id}/`, params);
    return res.data;
  };

  getOffBudgetList = async (
    params: reqInterface.IOffBudgetListParam,
  ): Promise<IPaginatedResult<reqInterface.IOffBudget>> => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<reqInterface.IOffBudget>
    >(`/extrabudgetary/com_plan_fact/`, params);

    return res.data;
  };

  deleteOffBudgetPlan = async (id: number) => {
    const res = await this.ctx.delete<
      unknown,
      AxiosResponse
    >(`/extrabudgetary/com_plan_fact/${id}/`, {});
    return res.data;
  };

  editOffBudgetPlan = async (
    id: number,
    params: resInterface.IOffBudgetItem,
  ) => {
    const res = await this.ctx.patch<
      resInterface.IOffBudgetItem,
      AxiosResponse
    >(`/extrabudgetary/com_plan_fact/${id}/`, params);
    return res.data;
  };

  getOffBudgetPlan = async (id: number | string) => {
    const res = await this.ctx.get<
      unknown,
      reqInterface.IOffBudget
    >(`/extrabudgetary/com_plan_fact/${id}/`);
    return res.data;
  };

  addOffBudgetPlan = async (params: FormData) => {
    const res = await this.ctx.post<
      FormData,
      reqInterface.IOffBudget
    >("/extrabudgetary/com_plan_fact/", params);
    return res.data;
  };

  getOffBudgetCultivars = async (
    params: reqInterface.IGetCultivarListParam,
  ) => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IOBCultivar>
    >(`/extrabudgetary/com_cultivar/`, params);
    return res.data;
  };

  getOffBudgetCultivarsGroupPlotLocation = async (
    params: reqInterface.IGetCultivarListParam,
  ) => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IOBCultivarGroupPlotLocation[]
    >(`/extrabudgetary/com_cultivar/by_plot_and_location/`, params);
    return res.data;
  };

  deleteOffBudgetCultivars = async (
    obCultivarId: number,
  ) => {
    await this.ctx.delete(
      `/extrabudgetary/com_cultivar/${obCultivarId}/`,
    );
  };

  editOffBudgetCultivar = async (
    id: number,
    params: Partial< reqInterface.IOBCultivar>,
  ) => {
    const res = await this.ctx.patch<
      Partial<reqInterface.IOBCultivar>,
      AxiosResponse
    >(`/extrabudgetary/com_cultivar/${id}/`, params);
    return res.data;
  };

  createOffBudgetCultivar = async (
    params: Partial<reqInterface.IOBCultivar>,
  ) => {
    const res = await this.ctx.post<
      Partial<reqInterface.IOBCultivar>,
      AxiosResponse
    >(`/extrabudgetary/com_cultivar/`, params);
    return res.data;
  };

  getOffBudgetCultivar = async (id: number | string) => {
    const res = await this.ctx.get<
      unknown,
      resInterface.IOffBudgetCultivar
    >(`/extrabudgetary/com_cultivar/${id}/`);
    return res.data;
  };

  getOffBudgetCultivarChemistry = async (
    param: reqInterface.IChemistryList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IChemistryList,
      IPaginatedResult<resInterface.IOffBudgetCultivarChemistry>
    >(`/extrabudgetary/com_cultivar_chemistry/`, param);
    return res.data;
  };

  getComCultivarWork = async (
    param: reqInterface.IComWorkList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IComWorkList,
      IPaginatedResult<resInterface.IComCultivarWork>
    >(`/extrabudgetary/com_cultivar_work/`, param);
    return res.data;
  };

  getExpenseList = async (
    param: reqInterface.IExpenseParams,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IExpenseParams,
      IExpensePaginatedResult
    >(`/extrabudgetary/com_expense/`, param);
    return res.data;
  };

  deleteExpenseItem = async (id: number) => {
    await this.ctx.delete(
      `/extrabudgetary/com_expense/${id}/`,
    );
  };

  editExpenseItem = async (
    id: number,
    params: reqInterface.IExpensePath,
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IExpensePath,
      AxiosResponse
    >(`/extrabudgetary/com_expense/${id}/`, params);
    return res.data;
  };

  createExpense = async (
    params: reqInterface.IExpenseCreate[],
  ) => {
    const res = await this.ctx.post<
      reqInterface.IExpenseCreate[],
      AxiosResponse
    >(`/extrabudgetary/com_expense/`, params);
    return res.data;
  };

  // Данные для выпадашки по созданию расходов
  getRegulatoryInfo = async (
    params: Pick<
      reqInterface.IList,
      "page" | "page_size" | "search"
    >,
  ) => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IRegulatoryInfo>
    >(`/regulatory_info/expense/`, params);
    return res.data;
  };

  getEffectivityList = async (
    param: reqInterface.IEffectivityList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IEffectivityList,
      IPaginatedResult<resInterface.IEffectivityList>
    >(`/extrabudgetary/com_effectiveness/`, param);
    return res.data;
  };

  addOffBudgetChemistry = async (
    param: reqInterface.IComCultivarChemistry,
  ) => {
    const res = await this.ctx.post<
      reqInterface.IComCultivarChemistry,
      reqInterface.IComCultivarChemistry
    >("/extrabudgetary/com_cultivar_chemistry/", param);
    return res.data;
  };
  deleteOffBudgetChemistryById = async (
    id: number | string,
  ) => {
    const res = await this.ctx.delete<
      unknown,
      AxiosResponse
    >(`/extrabudgetary/com_cultivar_chemistry/${id}/`);
    return res.data;
  };
  deleteOffBudgetWorkById = async (id: number | string) => {
    const res = await this.ctx.delete<
      unknown,
      AxiosResponse
    >(`/extrabudgetary/com_cultivar_work/${id}/`);
    return res.data;
  };

  addOffBudgetWork = async (
    param: reqInterface.IComCultivarWork,
  ) => {
    const res = await this.ctx.post<
      reqInterface.IComCultivarWork,
      reqInterface.IComCultivarWork
    >("/extrabudgetary/com_cultivar_work/", param);
    return res.data;
  };

  addOffBudgetWageFund = async (
    param: reqInterface.IComWageFund,
  ) => {
    const res =
      await this.ctx.post<reqInterface.IComWageFund>(
        "/extrabudgetary/com_wage_fund/",
        param,
      );
    return res.data;
  };

  getOffBudgetWageFund = async (
    params: reqInterface.IGetCultivarListParam,
  ) => {
    const res = await this.ctx.get<
      unknown,
      IPaginatedResult<resInterface.IOffBudgetWageFund>
    >(`/extrabudgetary/com_wage_fund/`, {
      ...params,
    });
    return res.data;
  };

  addOffBudgetExpense = async (
    param: reqInterface.IComExpense,
  ) => {
    const res = await this.ctx.post<
      reqInterface.IComExpense,
      reqInterface.IComExpense
    >("/extrabudgetary/com_expense/", param);
    return res.data;
  };

  addEffectivity = async (
    params: reqInterface.IAddEffectivity,
  ) => {
    const res = await this.ctx.post<
      reqInterface.IAddEffectivity,
      resInterface.IEffectivityResp
    >("/extrabudgetary/com_effectiveness/", params);
    return res.data;
  };

  editEffectivity = async (
    id: number | string,
    params: Partial<reqInterface.IEditEffectivity>,
  ) => {
    const res = await this.ctx.patch<
      Partial<reqInterface.IEditEffectivity>,
      resInterface.IEffectivityResp
    >(`/extrabudgetary/com_effectiveness/${id}/`, params);
    return res.data;
  };

  deleteWageFund = async (id: number) => {
    await this.ctx.delete(
      `/extrabudgetary/com_wage_fund/${id}/`,
    );
  };

  editOffBudgetWageFund = async (
    id: number,
    param: Partial<reqInterface.IComWageFund>,
  ) => {
    const res = await this.ctx.patch<
      Partial<reqInterface.IComWageFund>,
      reqInterface.IComWageFund
    >(`/extrabudgetary/com_wage_fund/${id}/`, param);
    return res.data;
  };

  addInvestment = async (
    params: reqInterface.IAddInvestment,
  ) => {
    const res = await this.ctx.post<
      reqInterface.IAddInvestment,
      resInterface.IInvestmentResp
    >("/extrabudgetary/com_investment/", params);
    return res.data;
  };

  getInvestmentList = async (
    param: reqInterface.IInvestmentList,
  ) => {
    const res = await this.ctx.get<
      reqInterface.IInvestmentList,
      IPaginatedResult<resInterface.IInvestmentList>
    >(`/extrabudgetary/com_investment/`, param);
    return res.data;
  };

  addOffBudgetFact = async (
    params: Partial<reqInterface.IOffBudgetFactParam>,
  ) => {
    const res =
      await this.ctx.post<resInterface.IFactCreate>(
        "/extrabudgetary/com_plan_fact/copy_com_plan_fact/",
        params,
      );
    return res.data;
  };

  editRemark = async (
    id: string | number,
    params: reqInterface.IOffBudgetRemarksParam,
  ) => {
    const res =
      await this.ctx.patch<reqInterface.IOffBudgetRemarksParam>(
        `/extrabudgetary/com_plan_fact/${id}/`,
        params,
      );
    return res.data;
  };

  downloadFileFromBlob = async (
    id: number,
    nameDoc: string,
  ) => {
    const accessToken = getCookie(
      TOKEN_KEYS.AUTH_TOKEN_KEY,
    );
    const url = `${this.ctx.MAIN_URL}/extrabudgetary/com_plan_fact/${id}/download_com_plan_fact_report/`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();

        // Создаем URL для скачивания файла
        const fileUrl = URL.createObjectURL(blob);

        // Создаем ссылку на элемент <a> для скачивания файла
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = `${nameDoc}.docx`; // Устанавливаем имя файла для скачивания
        document.body.appendChild(link);

        // Кликаем на ссылку для скачивания файла
        link.click();

        // Удаляем ссылку на элемент <a>
        document.body.removeChild(link);

        // Освобождаем URL
        URL.revokeObjectURL(fileUrl);
        return true;
      } else {
        console.error(
          `Ошибка при скачивании файла. Статус: ${response.status}`,
        );
        return false;
      }
    } catch (error) {
      console.error("Произошла ошибка:", error);
      return false;
    }
  };

  addOffBudgetFileById = async (
    id: string | number,
    params: FormData,
  ) => {
    const res = await this.ctx.post<
      FormData,
      IOffBudgetFile
    >(
      `/extrabudgetary/com_plan_fact/${id}/add_file/`,
      params,
    );
    return res.data;
  };

  deleteOffBudgetFile = async (
    planId: string | number,
    fileId: string | number,
  ) => {
    await this.ctx.delete(
      `/extrabudgetary/com_plan_fact/${planId}/delete_file/${fileId}/`,
    );
  };

  patchInvestment = async (
    id: number,
    params: reqInterface.IAddInvestment,
  ) => {
    const res = await this.ctx.patch<
      reqInterface.IAddInvestment,
      resInterface.IInvestmentResp
    >(`/extrabudgetary/com_investment/${id}/`, params);
    return res.data;
  };

  deleteInvestment = async (id: number) => {
    await this.ctx.delete(
      `/extrabudgetary/com_investment/${id}/`,
    );
  };

  patchOffBudgetWork = async (
    param: Partial<reqInterface.IComCultivarWork>,
  ) => {
    const res = await this.ctx.patch<
      Partial<reqInterface.IComCultivarWork>,
      reqInterface.IComCultivarWork
    >("/extrabudgetary/com_cultivar_work/", param);
    return res.data;
  };
}

export default OffBudgetApi;
