import React from "react";
import { useFormikContext } from "formik";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  Menu,
  Typography
} from "@mui/material";
import { DeleteIcon } from "@/components/icons";
import {
  StyledMenuItem
} from "@/components/StyledMenuItem";
import {
  FIELDS_NAME,
  IFormik
} from "@/pages/AddTechMap/const";

type TProp = {
  itemIndex: number;
};

const EditMenu: React.FC<TProp> = ({ itemIndex }) => {
  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);

  const formik = useFormikContext<IFormik>();

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    handleClose(event);
    const works = formik.values.works.filter((_, index) => index !== itemIndex);
    void formik.setFieldValue(FIELDS_NAME.WORKS, works);
  };

  return (
    <Box>
      <IconButton onClick={handleToggle}>
        <MoreVertIcon sx={{ fontSize: 20 }}/>
      </IconButton>
      <Menu anchorEl={isOpen} open={open}
            onClose={handleClose}>
        <StyledMenuItem
          onClick={handleClickDelete}
        >
          <DeleteIcon color={"error"}/>
          <Typography color="error">Удалить</Typography>
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};

export default EditMenu;
