import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router";

import { ROUTES } from "@/apps/AppRouter/const";

import { useStores } from "@/hooks";

/**
 * Interface for the EditPlan component's props.
 */
interface IEditPlan {
  handleClose: () => void;
  modalProps: {
    id: string;
    name: string;
    type: string;
  };
}

/**
 * @param {IEditPlan} props - Component props.
 * @returns {React.FC<IEditPlan>} - Rendered component.
 */

const CopyPlan: React.FC<IEditPlan> = ({ handleClose, modalProps }) => {
  const { api } = useStores();
  const { id, name, type } = modalProps;
  const navigate = useNavigate();

  const handleCancel = () => {
    handleClose();
  };

  const handleConfirm = () => {
    handleClose();
    const data = {
      source_plan: id,
      name: name,
      type: type,
    };
    const promise = api.offBudget.addOffBudgetFact(data as any);

    promise
      .then((res) => {
        if (type === "fact")
          navigate(`${res.com_plan_fact}/${ROUTES.editFact}/`);
        if (type === "plan")
          navigate(`${res.com_plan_fact}/${ROUTES.editPlan}/`);
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <Box>
      <Box p={3}>
        <Typography
          variant="h2"
          sx={{ paddingRight: "30px" }}
        >
          Скопировать и перейти к редактированию плана?
        </Typography>
      </Box>

      <Divider />

      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button
          variant="text"
          color={"blue"}
          onClick={handleCancel}
        >
          Отмена
        </Button>

        <Button color={"green"} onClick={handleConfirm}>
          Да
        </Button>
      </Box>
    </Box>
  );
};

export default CopyPlan;
