import { IColumn } from "@/apps/Table/interfaces";
import {
  cellButtons,
  cellPointText,
  headerCellStyle
} from "../style";

import { DUTY_HEADER_LABELS, DUTY_KEYS } from "../const";

import { TDutyKeys } from "../interfaces";
import {
  NumberCell,
  NameCell,
  ResidentCell,
  NonResidentCell,
  EditingCell,
} from "../templates/Duty/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const dutyColumns: IColumn<TDutyKeys>[] = [
  {
    label: DUTY_HEADER_LABELS.number,
    key: DUTY_KEYS.number,
    contentHeader: DUTY_HEADER_LABELS.number,
    bodyCellProps: {
      render: NumberCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: DUTY_HEADER_LABELS.name,
    key: DUTY_KEYS.name,
    contentHeader: DUTY_HEADER_LABELS.name,
    bodyCellProps: {
      render: NameCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: DUTY_HEADER_LABELS.resident,
    key: DUTY_KEYS.resident,
    contentHeader: DUTY_HEADER_LABELS.resident,
    bodyCellProps: {
      render: ResidentCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: DUTY_HEADER_LABELS.non_resident,
    key: DUTY_KEYS.non_resident,
    contentHeader: DUTY_HEADER_LABELS.non_resident,
    bodyCellProps: {
      render: NonResidentCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: DUTY_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCell,
      styles: cellButtons,
    },
  },
];
