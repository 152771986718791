import React from 'react';
import { Typography, Stack } from '@mui/material';
import { EFFECTIVITY_FIELD_NAMES } from './const';
import theme from '@/theme';
import { stringWithSuffix } from '@/utils/helpers';
import { resInterface } from '@/api/interfaces';

interface IProps {
  item: resInterface.IEffectivityList;
}

const EffectivityItem: React.FC<IProps> = ({ item }) => {
  return (
    <>
      {Object.keys(item).map((elem, idx) =>
        EFFECTIVITY_FIELD_NAMES[elem] ? (
          <Stack
            key={idx}
            sx={{
              cursor: 'pointer',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              height: 'fit-content',
            }}
          >
            <Typography variant="body2">
              {EFFECTIVITY_FIELD_NAMES[elem]}
            </Typography>
            <Typography
              variant="body2"
              color={theme.palette.blue.main}
            >
              {stringWithSuffix(item[elem], 'руб', '-')}
            </Typography>
          </Stack>
        ) : null,
      )}
    </>
  );
};

export default EffectivityItem;
