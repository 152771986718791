import React from "react";
import {
  Alert,
  AlertProps,
  AlertTitle
} from "@mui/material";

interface IAlert extends AlertProps {
  message: string
  title?: string
}
const AlertToast:React.FC<IAlert> = ({ message, title, ...props }) => {
  return (
    <Alert
      severity="warning"
      variant="filled"
      sx={{ width: "100%", overflow: "auto", maxHeight: "300px" }}
      {...props}
    >
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
};

export default AlertToast;