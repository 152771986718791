import React from "react";
import { FastField, useFormikContext } from "formik";
import theme from "@/theme";
import { Box } from "@mui/system";
import { SvgIconProps } from "@mui/material";
import RegexTextField, {
  TRegexTextField
} from "@/components/RegexTextField";

interface IProps {
  name: string;
  Icon?: React.FC<SvgIconProps>;
}

const FastRegexTextField: React.FC<IProps & TRegexTextField> = ({
  name,
  Icon,
  ...props
}) => {
  const formik = useFormikContext();

  if (!formik) {
    throw new Error(
      "FastRegexTextField has to be used within <Formik>"
    );
  }
  return (
    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      {
        Icon && (
          <Icon sx={{
            color: theme.palette.blackAndWhite.gray,
            mr: 1,
            my: 1
          }}/>
        )
      }
      <FastField name={name}>
        {({ field, meta }) => (
          <RegexTextField
            {...field}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            size="small"
            fullWidth
            {...props}
          />
        )}
      </FastField>
    </Box>
  );
};

export default FastRegexTextField;