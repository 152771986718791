import React from "react"
import { IIcon } from "./interfaces"

const BalanceIcon = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4242_265577"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4242_265577)">
        <path
          d="M1.33325 14V12.6667H7.33325V5.21671C7.04436 5.11671 6.79436 4.96115 6.58325 4.75004C6.37214 4.53893 6.21659 4.28893 6.11659 4.00004H3.99992L5.99992 8.66671C5.99992 9.22226 5.77214 9.69449 5.31659 10.0834C4.86103 10.4723 4.31103 10.6667 3.66659 10.6667C3.02214 10.6667 2.47214 10.4723 2.01659 10.0834C1.56103 9.69449 1.33325 9.22226 1.33325 8.66671L3.33325 4.00004H1.99992V2.66671H6.11659C6.24992 2.27782 6.48881 1.95837 6.83325 1.70837C7.1777 1.45837 7.56659 1.33337 7.99992 1.33337C8.43325 1.33337 8.82214 1.45837 9.16659 1.70837C9.51103 1.95837 9.74992 2.27782 9.88325 2.66671H13.9999V4.00004H12.6666L14.6666 8.66671C14.6666 9.22226 14.4388 9.69449 13.9833 10.0834C13.5277 10.4723 12.9777 10.6667 12.3333 10.6667C11.6888 10.6667 11.1388 10.4723 10.6833 10.0834C10.2277 9.69449 9.99992 9.22226 9.99992 8.66671L11.9999 4.00004H9.88325C9.78325 4.28893 9.6277 4.53893 9.41659 4.75004C9.20547 4.96115 8.95547 5.11671 8.66659 5.21671V12.6667H14.6666V14H1.33325ZM11.0833 8.66671H13.5833L12.3333 5.76671L11.0833 8.66671ZM2.41659 8.66671H4.91659L3.66659 5.76671L2.41659 8.66671ZM7.99992 4.00004C8.18881 4.00004 8.34714 3.93615 8.47492 3.80837C8.6027 3.6806 8.66659 3.52226 8.66659 3.33337C8.66659 3.14449 8.6027 2.98615 8.47492 2.85837C8.34714 2.7306 8.18881 2.66671 7.99992 2.66671C7.81103 2.66671 7.6527 2.7306 7.52492 2.85837C7.39714 2.98615 7.33325 3.14449 7.33325 3.33337C7.33325 3.52226 7.39714 3.6806 7.52492 3.80837C7.6527 3.93615 7.81103 4.00004 7.99992 4.00004Z"
          fill="#1872CF"
        />
      </g>
    </svg>
  )
}

export default BalanceIcon
