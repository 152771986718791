import yup from "@/utils/yup";

export type TForm = {
  index: number;
  severity: string;
  non_field_errors: string;
};

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    indicatorId?: number;
    isEdit?: boolean;
    initialValues?: Partial<TForm>;
    detail_id?: number;
    trialType?: string;
  };
  onSubmit?: () => void;
}

export enum INDICATOR_FIELD_NAME {
  INDEX = "index",
  SEVERITY = "severity",
  TRIAL_TYPE = "trialType",
}

export const validationSchema = yup.object().shape({
  [INDICATOR_FIELD_NAME.TRIAL_TYPE]: yup.string(),
  [INDICATOR_FIELD_NAME.INDEX]: yup
    .number()
    .when(["$trialType"], ([trialType], schema) => {
      return trialType === "oos"
        ? schema.required()
        : schema.nullable();
    }),
  [INDICATOR_FIELD_NAME.SEVERITY]: yup
    .string()
    .trim()
    .required(),
});
