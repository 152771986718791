import yup from "@/utils/yup";
import { ERROR_MESSAGES } from "@/const";
import { TContextType } from "./utils";

export enum LAB_FIELD_NAME {
  YEAR_OF_TESTING = "year_of_testing", //Год испытаний
  REGIONS = "regions",
  PAYMENT_TYPE = 'payment_type'
}

export interface IFormik {
  [LAB_FIELD_NAME.YEAR_OF_TESTING]: string | Date;
  [LAB_FIELD_NAME.REGIONS]: number[];
  [LAB_FIELD_NAME.PAYMENT_TYPE]: string;
  context: TContextType;
}

export const initialValues = {
  [LAB_FIELD_NAME.YEAR_OF_TESTING]: null,
  [LAB_FIELD_NAME.REGIONS]: [],
  [LAB_FIELD_NAME.PAYMENT_TYPE]: 'budget',
};

export const validationSchema = yup.object().shape({
  [LAB_FIELD_NAME.YEAR_OF_TESTING]: yup.string().required(),
  [LAB_FIELD_NAME.REGIONS]: yup
    .array()
    .min(1, ERROR_MESSAGES.REQUIRED)
    .required(),
});