import yup from "@/utils/yup";
import dayjs, { Dayjs } from "dayjs";
import { IChemistry } from "@/api/interfaces/responses";

export interface ICultivarFieldWorkChemistry {
  chemistry: IChemistry,
  quantity_total: string,
  price: string
}


export const serializerChemistry = (payload: ICultivarFieldWorkChemistry) => ({
  chemistry: payload.chemistry.id,
  quantity_total: payload.quantity_total,
  price: payload.price
})

export const FIELDS_NAME = {
  WORK: "work",
  TECH_MAP: "tech_map",
  START_DATE: "start_date",
  END_DATE: "end_date",
  FERTILIZERS: "fertilizers",
  PESTICIDES: "pesticides"
} as const;

export const initialValues = {
  [FIELDS_NAME.WORK]: null,
  [FIELDS_NAME.TECH_MAP]: null,
  [FIELDS_NAME.START_DATE]: null,
  [FIELDS_NAME.END_DATE]: null,
  [FIELDS_NAME.FERTILIZERS]: [],
  [FIELDS_NAME.PESTICIDES]: [],
};

export interface IValue {
  id: number,
  label: string
}

export interface IFormik {
  [FIELDS_NAME.WORK]: IValue | null,
  [FIELDS_NAME.TECH_MAP]: IValue | null,
  [FIELDS_NAME.START_DATE]: Dayjs | null,
  [FIELDS_NAME.END_DATE]: Dayjs | null,
  [FIELDS_NAME.FERTILIZERS]: ICultivarFieldWorkChemistry[]
  [FIELDS_NAME.PESTICIDES]: ICultivarFieldWorkChemistry[],
}

export const serializeValues = (values): IFormik => ({
  work: values?.work ? {
    id: values.work.id,
    label: `${values.work.type.work_type}: ${values.work.tech_map_plan.name}`,
  } : null,
  tech_map: values?.work?.tech_map_plan ? {
    id: values?.work?.tech_map_plan?.id,
    label: values?.work?.tech_map_plan?.name,
  } : null,
  end_date: values.end_date ? dayjs(values.end_date) : null,
  start_date: values.start_date ? dayjs(values.start_date) : null,
  fertilizers: values.fertilizers || [],
  pesticides: values.pesticides || []
});

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.TECH_MAP]: yup.mixed().nullable(),
  [FIELDS_NAME.WORK]: yup.mixed().required(),
  [FIELDS_NAME.START_DATE]: yup.date().nullable(),
  [FIELDS_NAME.END_DATE]: yup.date().nullable(),
});