import { AxiosResponse } from "axios";
import {
  apiInterface,
  reqInterface,
  resInterface
} from "@/api/interfaces";
import AbstractApi from "../../abstract";
import {
  deleteAuthCookies,
  setCookie
} from "@/utils/cookie";
import { TOKEN_KEYS } from "@/const";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";

class AuthApi implements apiInterface.IAuthApi {
  ctx: AbstractApi;
  constructor(ctx: AbstractApi) {
    this.ctx = ctx;
  }
  createUserSession = async (): Promise<
    AxiosResponse<resInterface.IPostUserSessionRes>
  > => {
    const res = await this.ctx.post<unknown, resInterface.IPostUserSessionRes>(
      "/garpix_user/user_session/create_user_session/",
      {}
    );
    return res;
  };

  postSendCode = async (
    params: reqInterface.IPostSendCodeReq
  ): Promise<AxiosResponse> =>
    await this.ctx.post<reqInterface.IPostSendCodeReq>(
      "/garpix_user/restore_password/send_code/",
      params
    );

  postCheckCode = async (
    params: reqInterface.IPostCheckCodeReq
  ): Promise<AxiosResponse> =>
    await this.ctx.post<reqInterface.IPostCheckCodeReq>(
      "/garpix_user/restore_password/check_code/",
      params
    );

  postSetPassword = async (
    params: reqInterface.IPostSetPasswordReq
  ): Promise<AxiosResponse> =>
    await this.ctx.post<reqInterface.IPostSetPasswordReq>(
      "/garpix_user/restore_password/set_password/",
      params
    );

  postChangePassword = async (
    params: reqInterface.IPostChangePasswordReq
  ) => {
    const res = await this.ctx.post<reqInterface.IPostChangePasswordReq, AxiosResponse>(
      "/users/user/change_password/",
      params
    );

    return res.data
  }

  postLogin = async (
    params: reqInterface.IPostLoginReq
  ): Promise<AxiosResponse<resInterface.IPostLoginRes>> => {
    const res = await this.ctx.post<
      reqInterface.IPostLoginReq,
      resInterface.IPostLoginRes
    >("/token/", params);

    setCookie(TOKEN_KEYS.AUTH_TOKEN_KEY, res.data.access);
    setCookie(TOKEN_KEYS.REFRESH_TOKEN_KEY, res.data.refresh);
    setCookie(TOKEN_KEYS.ACCESS_TOKEN_EXPIRY, res.data.access_token_expiry);
    setCookie(TOKEN_KEYS.REFRESH_TOKEN_EXPIRY, res.data.refresh_token_expiry);
    return res;
  };

  postLogout = async (): Promise<void> => {
    // await this.ctx.post("/garpix_user/logout/", {});
    deleteAuthCookies()
    window.location.href = ROUTES_PATHS.login;
  };
}

export default AuthApi;
