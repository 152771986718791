import React from "react";
import { FastField, useFormikContext } from "formik";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import SubjectFormFirstStepIndividual from "./FirstStepSubjectTypes/individual";
import SubjectFormFirstStepLegalEntity from "./FirstStepSubjectTypes/legalEntity";
import {
  FIELD_NAME,
  HANDBOOK_SUBJECT_TYPE,
  initial,
  IProps,
  TForm,
} from "../../types";
import { useStores } from "@/hooks";

const SubjectFormFirstStep: React.FC<IProps> = (props) => {
  const form = useFormikContext<TForm>();
  const { modalStore } = useStores();

  const handleChange = (e) => {
    if (HANDBOOK_SUBJECT_TYPE.individual === e.target.value) {
      form.setValues({...initial, subject_type: 'individual'})
      modalStore.setModalPropsByKey(props.modalProps.key, {
        initialValues: {...initial, subject_type: 'individual'},
      });
    } else {
      form.setValues({...initial, subject_type: 'legal_entity'})
      modalStore.setModalPropsByKey(props.modalProps.key, {
        initialValues: {...initial, subject_type: 'legal_entity'},
      });
    }
  }
  
  return (
    <Stack direction={"column"} gap={2}>
      <FastField name={FIELD_NAME.SUBJECT_TYPE}>
        {({ field }) => (
          <FormControl>
            <RadioGroup {...field}
            onChange={handleChange} >
              <Stack direction="row">
                <FormControlLabel
                  value={HANDBOOK_SUBJECT_TYPE.individual}
                  control={<Radio color="secondary" />}
                  label="Физ. лицо"
                />
                <FormControlLabel
                  value={HANDBOOK_SUBJECT_TYPE.legal_entity}
                  control={<Radio color="secondary" />}
                  label="Юр. лицо"
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        )}
      </FastField>
      {form.values?.subject_type ===
      HANDBOOK_SUBJECT_TYPE.individual ? (
        <SubjectFormFirstStepIndividual {...props} />
      ) : (
        <SubjectFormFirstStepLegalEntity {...props} />
      )}
    </Stack>
  );
};

export default SubjectFormFirstStep;
