import dayjs, { Dayjs } from "dayjs";
import yup from "@/utils/yup";
import { IProductivity } from "@/api/interfaces/responses";
import { MIN_NUMBERS, MAX_NUMBERS } from "@/const";

export const FIELDS_NAME = {
  HARVEST_DATE: "harvest_date",
  RECURRENCE: "recurrence",
  WEIGHT: "weight",
  HUMIDITY: "humidity",
  STANDARD_HUMIDITY: "standard_humidity",
  THOUSAND_GRAINS_WEIGHT: "thousand_grains_weight",
  GRAIN_NATURE: "grain_nature"
} as const;
export interface IForm {
  [FIELDS_NAME.HARVEST_DATE]: Dayjs | null;
  [FIELDS_NAME.RECURRENCE]: number;
  [FIELDS_NAME.WEIGHT]: number | string;
  [FIELDS_NAME.HUMIDITY]: number | string;
  [FIELDS_NAME.STANDARD_HUMIDITY]: number | string;
  [FIELDS_NAME.THOUSAND_GRAINS_WEIGHT]: number | string;
  [FIELDS_NAME.GRAIN_NATURE]: number | string;
}

export const initialValues = {
  [FIELDS_NAME.HARVEST_DATE]: null,
  [FIELDS_NAME.RECURRENCE]: 1,
  [FIELDS_NAME.WEIGHT]: "",
  [FIELDS_NAME.HUMIDITY]: "",
  [FIELDS_NAME.STANDARD_HUMIDITY]: "",
  [FIELDS_NAME.THOUSAND_GRAINS_WEIGHT]: "",
  [FIELDS_NAME.GRAIN_NATURE]: ""
};

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.HARVEST_DATE]: yup.mixed().required(),
  [FIELDS_NAME.RECURRENCE]: yup.number().required(),
  [FIELDS_NAME.WEIGHT]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX).required(),
  [FIELDS_NAME.HUMIDITY]: yup.number().min(1).max(99).required(),
  [FIELDS_NAME.STANDARD_HUMIDITY]: yup.number().min(1).max(99).required(),
  [FIELDS_NAME.THOUSAND_GRAINS_WEIGHT]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX),
  [FIELDS_NAME.GRAIN_NATURE]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX),
});

export const serializeFormToValue = (payload: IForm) => {
  return {
    harvest_date: dayjs(payload.harvest_date).toISOString(),
    recurrence: Number(payload.recurrence),
    weight: Number(payload.weight) as number,
    humidity: Number(payload.humidity) as number,
    standard_humidity: Number(payload.standard_humidity) as number,
    thousand_grains_weight: Number(payload.thousand_grains_weight) as number,
    grain_nature: Number(payload.grain_nature) as number
  };
};

export const serializeValueToForm = (payload: IProductivity): IForm => {
  return {
    harvest_date: payload.harvest_date ? dayjs(payload.harvest_date) : null,
    recurrence: payload.recurrence || 1,
    weight: payload.weight || "",
    humidity: payload.humidity || "",
    standard_humidity: payload.standard_humidity || "",
    thousand_grains_weight: payload.thousand_grains_weight || "",
    grain_nature: payload.grain_nature || ""
  };
};
