import React from "react";
import { keyframes } from "@emotion/react";
import { styled } from "@mui/system";
import { Avatar as Av, AvatarProps } from "@mui/material";

import { useStores } from "@/hooks";
import theme from "@/theme";

import EditIcon from "@mui/icons-material/Edit";
import { THEME_MODAL, VARIANT_MODAL } from "../Modals/const";
import { useMatches, WrapperProps } from "../Respons";
import { observer } from "mobx-react";
// Анимация наведения
export const hoverAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const Wrapper = styled("div")<WrapperProps>(({ matches }) => ({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: `216px`,
  height: `210px`,
  transform: matches?.medium || matches?.small ? "scale(.8)" : "scale(1)",
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  margin: "24px",
  boxSizing: `border-box`,
  backgroundColor: theme.palette.background.default
}));

const Avatar = styled(Av)<WrapperProps>(() => ({
  cursor: "pointer",
  width: 200,
  height: 200,
  fontSize: "80px",
  backgroundColor: theme.palette.backgroundColors.avatarBg,
  "&:hover": {
    animation: `${hoverAnimation} 0.3s ease-in-out`
  }
}));

const IconEdit = styled(EditIcon)(() => ({
  width: `50px`,
  height: `50px`,
  position: `absolute`,
  left: `166px`,
  top: `160px`,
  backgroundColor: theme.palette.backgroundColors.lightGray,
  borderRadius: "60px",
  padding: "10px"
}));

type TProp = { avatarProp?: AvatarProps };

const AvatarChanger: React.FC<TProp> = ({ ...props }) => {
  const { modalStore, userStore } = useStores();
  const { matches } = useMatches();

  const handleClickOpenModal = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.CHANGE_AVATAR, { theme: THEME_MODAL.W_555 });
  };
  return (
    <Wrapper {...props} matches={matches} onClick={handleClickOpenModal}>
      <Avatar
        matches={matches}
        alt="Avatar"
        variant="rounded"
        src={userStore.avatar ?? undefined}
      >
        {userStore.first_name && userStore.last_name
          ? userStore.first_name[0] + userStore.last_name[0]
          : "ИИ"}
      </Avatar>
      <IconEdit />
    </Wrapper>
  );
};

export default observer(AvatarChanger);
