import React, { useEffect } from "react";
import { Field, Form, useFormikContext } from "formik";
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
  ColorPicker,
  CoordinatesFieldArray,
} from "@/components/Fields";
import { FIELDS_NAME, IFormik } from "../../const";
import { useStores } from "@/hooks";
import {
  CultivarAutocomplete
} from "@/components/Autocompletes";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useNavigate } from "react-router-dom";

interface IProps {
  cultivarId?: number;
  hasFieldCoordinates: boolean;
  fieldId?: number;
}

const CultivarForm: React.FC<IProps> = ({
  cultivarId,
  fieldId,
  hasFieldCoordinates
}) => {
  const { modalStore, mapStore } = useStores();
  const navigate = useNavigate();
  const formik = useFormikContext<IFormik>();

  const title = cultivarId
    ? "Редактировать культуру"
    : "Добавить культуру";

  const handleChange = (payload) => {
    formik.setFieldValue(FIELDS_NAME.COLOUR, payload);
  };

  const handleClickDrawSort = () => {
    mapStore.resetMapStore();
    mapStore.setInitialValuesForms(formik.values);
    const qs = cultivarId
      ? `?id=${cultivarId}&fieldId=${fieldId}`
      : `?fieldId=${fieldId}`;
    navigate(`${ROUTES_PATHS.mapSortDraw}/${qs}`);
    modalStore.close();
  };

  useEffect(() => {
    mapStore.setIsDrawPolygon(false);
  }, []);

  return (
    <Form noValidate>
      <Box p={3}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Box p={3}>
        <Stack direction="column" gap="20px">
          <ColorPicker
            name={FIELDS_NAME.COLOUR}
            onChange={handleChange}
          />
          <CultivarAutocomplete
            required={true}
            name={FIELDS_NAME.CULTIVAR}
          />
          <Field name={FIELDS_NAME.AREA}>
            {({ field, meta }) => (
              <TextField
                {...field}
                type="number"
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                label="Площадь, га"
                fullWidth
                required
                size="small"
              />
            )}
          </Field>
          <CoordinatesFieldArray
            disabled={!hasFieldCoordinates}
            name={FIELDS_NAME.COORDINATES}
            handleClickDraw={handleClickDrawSort}
            buttonLabel={
              cultivarId
                ? "Редактировать культуру "
                : "Нарисовать культуру"
            }
          />
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          mt={5}
        >
          <Button color="red" onClick={modalStore.close}>
            отмена
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
            disabled={formik.isSubmitting}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default React.memo(CultivarForm);
