import { Dayjs } from "dayjs";
import yup from "@/utils/yup";
import { reqInterface } from "@/api/interfaces";
// import {
//   IAgriculturalLabourWork
// } from "@/api/interfaces/responses";
import dayjs from "@/utils/dayjs";

export const FIELDS_NAME = {
  REGION: "region",
  BRANCH: "branch",
  PLOT: "plot",
  CULTIVAR: "cultivar",
  NAME: "name",
  YEAR: "year",
  WORKS: "works",
} as const;

export const initialValues = {
  [FIELDS_NAME.REGION]: null,
  [FIELDS_NAME.BRANCH]: null,
  [FIELDS_NAME.PLOT]: null,
  [FIELDS_NAME.CULTIVAR]: null,
  [FIELDS_NAME.NAME]: "",
  [FIELDS_NAME.YEAR]: null,
  [FIELDS_NAME.WORKS]: [],
};

export interface IValue {
  id: number,
  label: string
}

export interface IFormik {
  [FIELDS_NAME.REGION]: IValue | null,
  [FIELDS_NAME.BRANCH]: IValue | null,
  [FIELDS_NAME.PLOT]: IValue | null,
  [FIELDS_NAME.CULTIVAR]: IValue | null,
  [FIELDS_NAME.NAME]: string,
  [FIELDS_NAME.WORKS]: any[],
  [FIELDS_NAME.YEAR]: Dayjs | null,
}

export const serializeValues = (values): IFormik => ({
  region: values?.region ? {
    id: values.region.id,
    label: values.region.name,
  } : null,
  branch: values?.branch ? {
    id: values.branch.id,
    label: values.branch.name,
  } : null,
  plot: values?.plot ? {
    id: values.plot.id,
    label: values.plot.name,
  } : null,
  cultivar: values?.cultivar ? {
    id: values.cultivar.id,
    label: values.cultivar.name,
  } : null,
  year: values.year ? dayjs().year(values.year) : null,
  name: values.name || "",
  works: values.works || [],
});

export const serializeFormToValues = (values: IFormik): reqInterface.ITechMapPlanReq => {

  const works = values.works

  const replaceEmptyStringsWithUndefined = (obj: any): void => {
    for (const key in obj) {
      if (obj[key] === "") {
        obj[key] = undefined;
      }
    }
  }

  works.map((work) => replaceEmptyStringsWithUndefined(work))

  return ({
    plot: values.plot?.id,
    cultivar: values.cultivar?.id,
    name: values.name,
    works: works.map((i) => ({ ...i, type: i.type?.id, tech_map_plan: i.tech_map_plan?.id })),
    year: values.year?.year(),
  })
};

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.REGION]: yup.mixed().nullable(),
  [FIELDS_NAME.BRANCH]: yup.mixed().nullable(),
  [FIELDS_NAME.PLOT]: yup.mixed().required(),
  [FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
  [FIELDS_NAME.NAME]: yup.string().required(),
  [FIELDS_NAME.WORKS]: yup.mixed().nullable(),
  [FIELDS_NAME.YEAR]: yup.mixed().required(),
});