import { IValue } from "@/interfaces";
import theme from "@/theme";

export const SORTABLE_KEYS = {
  plot: "plot__name",
  cultivar: "cultivar__name",
  variety: "variety__name",
  material_type: "material_type__name",
  planned_quantity_of_seeds: "planned_quantity_of_seeds",
  planned_receipt_at: "planned_receipt_at",
  status: "status",
  editing: "editing",
} as const;

export const HEADER_LABELS = {
  plot: "ГСУ",
  cultivar: "Культура",
  variety: "Сорт",
  material_type: "Тип материала",
  planned_quantity_of_seeds: "Плановое количество",
  planned_receipt_at: 'Плановая дата',
  status: 'Статус'
};

export const EMPTY_LIST_TEXT = "Еще не создано ни одного поступления";
export const EMPTY_LIST_TEXT_WITH_FILTERS =
  "По указанным параметрам поступления не найдены";
export const ADD_TEXT = "Добавить поступление";

export const EDITING_CELL = {
  delete: "Удалить",
  editing: "Редактировать"
}

export const SEED_ARRIVAL_STATUS = {
  PLANNED: "planned",
  NOT_RECEIVED: "not_received",
  RECEIVED: "received",
  LATE_RECEIVED: "late_received",
  SHORT_DELIVERY: "short_delivery",
  LATE_SHORT_DELIVERY: "late_short_delivery"
} as const

export const SEED_ARRIVAL_STATUS_TEXT = {
  [SEED_ARRIVAL_STATUS.PLANNED]: "Запланировано",
  [SEED_ARRIVAL_STATUS.NOT_RECEIVED]: "Не поступили",
  [SEED_ARRIVAL_STATUS.LATE_RECEIVED]: "Поступили не в срок",
  [SEED_ARRIVAL_STATUS.RECEIVED]: "Поступили",
  [SEED_ARRIVAL_STATUS.SHORT_DELIVERY]: "Недопоставка",
  [SEED_ARRIVAL_STATUS.LATE_SHORT_DELIVERY]: "Недопоставка не в срок",
} as const;

export const SEED_ARRIVAL_STATUS_LIST: IValue[] = [
  {
    key: SEED_ARRIVAL_STATUS.PLANNED,
    label: SEED_ARRIVAL_STATUS_TEXT.planned
  },
  {
    key: SEED_ARRIVAL_STATUS.NOT_RECEIVED,
    label: SEED_ARRIVAL_STATUS_TEXT.not_received
  },
  {
    key: SEED_ARRIVAL_STATUS.RECEIVED,
    label: SEED_ARRIVAL_STATUS_TEXT.received
  },
  {
    key: SEED_ARRIVAL_STATUS.LATE_RECEIVED,
    label: SEED_ARRIVAL_STATUS_TEXT.late_received
  }
];

export const SEED_ARRIVAL_STATUS_COLOR = {
  [SEED_ARRIVAL_STATUS.PLANNED]: theme.palette.blackAndWhite.gray,
  [SEED_ARRIVAL_STATUS.RECEIVED]: theme.palette.green.main,
  [SEED_ARRIVAL_STATUS.LATE_RECEIVED]: theme.palette.blue.main,
  [SEED_ARRIVAL_STATUS.NOT_RECEIVED]: theme.palette.red.main,
  [SEED_ARRIVAL_STATUS.SHORT_DELIVERY]: theme.palette.blue.main,
  [SEED_ARRIVAL_STATUS.LATE_SHORT_DELIVERY]: theme.palette.blue.main,
};

export const TITLE_FOR_TABLE = "Учет поступления семян на ГСУ"