import { FiltersType } from "@/components/Filters/interfaces";
import { IFilter, IValue, TRangeDateFormatted } from "@/interfaces";

/**
 * Поля для фильтрации раздела "Количество в разнарядках"
 * @param dateRange 
 * @param cultivarTypeValue 
 * @param handleScroll
 */

interface IFilterWithParams extends IFilter {
  handleScroll?: (event: React.UIEvent<HTMLElement, UIEvent>) => void;
  handleSelectChange?: (event: any) => void
  selectItems?: any[];
  selectedType?: any;
  handleClearSelect?: () => void;
}

export const getFilters = (
  dateRange?: TRangeDateFormatted,
  cultivarTypeValue?: IValue[],
  selectedCultivarTypeValue?: IValue,
  selectItems?: any[],
  handleScroll?: (
    event: React.UIEvent<HTMLElement, UIEvent>,
  ) => void,
  handleSelectChange?: (event: any) => void,
  selectedType?: any,
  handleClearSelect?: () => void,
): IFilterWithParams[] => {
  return [
    {
      name: "updated_at",
      label: "Дата изменения",
      type: FiltersType.datesRange,
      selectedValue: dateRange,
      value: '',
      style: { minWidth: "200px", flexGrow: 1 },
    },
    {
      name: "culture",
      label: "Культура",
      type: FiltersType.autoComplete,
      selectedValue: selectedCultivarTypeValue,
      handleScroll: handleScroll,
      options: cultivarTypeValue,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
    {
      name: "type",
      label: "Тип разнарядки",
      type: FiltersType.select,
      selectedValue: selectedType,
      value: '',
      selectItems: selectItems,
      handleSelectChange: handleSelectChange,
      handleClearSelect: handleClearSelect,
    },
  ];
};
