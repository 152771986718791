import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
/**
 * хедер для филиала
 * @returns React.FC
 */

const HeaderGSU: React.FC = () => {
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);

  const title = "ГСУ";

  useEffect(() => {
    if (value !== location.pathname) {
      setValue(location.pathname);
    }
  }, [location.pathname]);

  return (
    <Box>
      <Typography
        fontSize={20}
        fontWeight={500}
        variant="h3"
        lineHeight={"24px"}
        noWrap
      >
        {title}
      </Typography>
    </Box>
  );
};

export default HeaderGSU;
