import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=86-113421&mode=design&t=wjp27yPmbYjfxv6D-0
const GmoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.16669 19.1668V18.3335C4.16669 16.4029 4.56946 14.837 5.37502 13.6356C6.18058 12.4342 7.29169 11.2224 8.70835 10.0002C7.29169 8.77794 6.18058 7.56613 5.37502 6.36475C4.56946 5.16336 4.16669 3.59738 4.16669 1.66683V0.833496H5.83335V1.66683C5.83335 1.81961 5.83683 1.96197 5.84377 2.09391C5.85071 2.22586 5.86113 2.36127 5.87502 2.50016H14.125C14.1389 2.36127 14.1493 2.22586 14.1563 2.09391C14.1632 1.96197 14.1667 1.81961 14.1667 1.66683V0.833496H15.8334V1.66683C15.8334 3.59738 15.4306 5.16336 14.625 6.36475C13.8195 7.56613 12.7084 8.77794 11.2917 10.0002C12.7084 11.2224 13.8195 12.4342 14.625 13.6356C15.4306 14.837 15.8334 16.4029 15.8334 18.3335V19.1668H14.1667V18.3335C14.1667 18.1807 14.1632 18.0384 14.1563 17.9064C14.1493 17.7745 14.1389 17.639 14.125 17.5002H5.87502C5.86113 17.639 5.85071 17.7745 5.84377 17.9064C5.83683 18.0384 5.83335 18.1807 5.83335 18.3335V19.1668H4.16669ZM7.04169 5.8335H12.9584C13.1389 5.56961 13.2952 5.30572 13.4271 5.04183C13.559 4.77794 13.6806 4.48627 13.7917 4.16683H6.20835C6.31946 4.47238 6.44099 4.76058 6.57294 5.03141C6.70488 5.30225 6.86113 5.56961 7.04169 5.8335ZM10 8.91683C10.2778 8.68072 10.5486 8.44461 10.8125 8.2085C11.0764 7.97238 11.3264 7.73627 11.5625 7.50016H8.43752C8.67363 7.73627 8.92363 7.97238 9.18752 8.2085C9.45141 8.44461 9.72224 8.68072 10 8.91683ZM8.43752 12.5002H11.5625C11.3264 12.2641 11.0764 12.0279 10.8125 11.7918C10.5486 11.5557 10.2778 11.3196 10 11.0835C9.72224 11.3196 9.45141 11.5557 9.18752 11.7918C8.92363 12.0279 8.67363 12.2641 8.43752 12.5002ZM6.20835 15.8335H13.7917C13.6806 15.5279 13.559 15.2397 13.4271 14.9689C13.2952 14.6981 13.1389 14.4307 12.9584 14.1668H7.04169C6.86113 14.4307 6.70488 14.6946 6.57294 14.9585C6.44099 15.2224 6.31946 15.5141 6.20835 15.8335Z"
          fill="#942EE4"
        />
      </svg>
    </SvgIcon>
  );
};

export default GmoIcon;
