import { styled } from "@mui/system";
import {
  CommonProps
} from "@mui/material/OverridableComponent";

interface ToastProps extends CommonProps {
  isClosing?: boolean;
}

export const StyledToast = styled("div", { shouldForwardProp: (prop) => prop !== "isClosing" })<ToastProps>(({
  theme,
  isClosing
}) => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  maxWidth: 300,
  backgroundColor: "transparent",
  zIndex: 99,
  transition: "transform 0.3s ease-out",
  "@keyframes slide-in": {
    from: {
      transform: "translateX(100%)"
    },

    to: {
      transform: "translateX(0%)"
    }
  },
  "&:not(:last-child)": {
    marginBottom: theme.spacing(2)
  },
  ...(!isClosing && {
    animation: "slide-in .3s ease-in-out"
  }),
  ...(isClosing && {
    transform: "translateX(150%)",
    flex: 0
  })
}));