import React from "react";
import Seo from "@/components/Seo";

type AuthLayoutProps = {
  children: React.ReactNode;
  title?: string;
};

const MapLayout: React.FC<AuthLayoutProps> = ({
  children,
  title
}) => {
  return (
    <React.Fragment>
      <Seo title={title}/>
      {children}
    </React.Fragment>
  );
};

export default MapLayout;
