import React from "react";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Formik, FormikHelpers } from "formik";
import AddChemistryForm from "./components/AddChemistryForm";
import {
  IFormik,
  initialValues,
  validationSchema,
} from "./const";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { SWR_KEYS } from "@/const";
import { TChemistryType } from "@/api/types";

/**
 * Компонент модалки добавления удобрения/средства защиты для внебюджета деталка культура
 * @param props
 * @param props.cultivarId - id плана внебюджета
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    cultivarId: number;
    type: TChemistryType;
    nameLabel: string;
    countLabel: string;
    title: string;
  };
}

const AddChemistry: React.FC<IProps> = (props) => {
  const { api, toastStore, swrStore } = useStores();
  const mutateTrials =
    swrStore.mutators[
      SWR_KEYS.getOffBudgetChemistry(
        props.modalProps.cultivarId,
        props.modalProps.type,
      )
    ];

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    const payload = {
      com_cultivar: props.modalProps.cultivarId,
      quantity_total: Number(values.quantity_total),
      chemistry: values.chemistry.id,
      price: values.price,
    };
    api.offBudget
      .addOffBudgetChemistry(payload)
      .then(() => {
        mutateTrials();
        props.handleClose();
      })
      .catch((error) => {
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorToString(error),
            severity: "error",
          },
        });
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  if (!props.modalProps.cultivarId) {
    console.log("Не передан id плана");
    return null;
  }

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {props.modalProps.title}
        </Typography>
      </Box>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => <AddChemistryForm {...props} />}
      />
    </Box>
  );
};

export default AddChemistry;
