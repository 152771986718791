import { TForm } from "./const";

export const mapFormToPatch = (values): any => {
  return {
    name: values.name,
    type_trial: values.type_trial,
  };
};

export const mapFormToPost = (values): Partial<TForm> => {
  const isHp = values.type_trial === "hp";
  const hpPayload = {
    ...values,
    variants: values.variants.map((el) => ({
      ...el,
      id: undefined,
      index: undefined,
    })),
  };

  if (isHp) {
    return hpPayload;
  }

  return {
    name: values.name,
    type_trial: values.type_trial,
    variants: values.variants.map((el) => ({
      ...el,
      id: undefined,
    })),
  };
};
