import yup from "@/utils/yup";

import {
  reqInterface,
  resInterface
} from "@/api/interfaces";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    cultivarId?: number;
    initialValues?: resInterface.ICultivarHandbook;
    handleAccept?: (payload: resInterface.ICultivarHandbook) => void;
  };
  onSubmit?: () => void;
}
export interface IDepartmentCultivar {
  id: number;
  phone: string;
}

export type TForm = Omit<reqInterface.ICultivar, "department"> & {
  department: IDepartmentCultivar;
};

export const LIST_TYPES = [
  { value: "list_a", label: "Перечень А" },
  { value: "list_b", label: "Перечень Б" },
  { value: "list_c", label: "Перечень В" },
];

export enum CULTIVAR_FIELD_NAME {
  IS_ACTIVE = "is_active",
  NAME = "name",
  LATIN_NAME = "latin_name",
  UPOV_CODE = "upov_code",
  IS_PROTECTED_GROUND = "is_protected_ground",
  LIST_TYPE = "list_type",
  DEPARTMENT = "department",
  RECEIPT_AT = "receipt_at",
}

export const validationSchema = yup.object().shape({
  [CULTIVAR_FIELD_NAME.NAME]: yup
    .string()
    .trim()
    .matches(/^(?! *$).+$/, "Поле не может состоять только из пробелов")
    .required(),
});
