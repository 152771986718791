import React, { useEffect, useState } from "react";

import { Field, useFormikContext } from "formik";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import theme from "@/theme";
import { CloseIcon } from "@/components/icons";
import {
  FIELD_NAME,
  ISubjectEmail,
  TForm,
} from "../../../types";

export const EmailField: React.FC = () => {
  const form = useFormikContext<TForm>();
  const [emails, setEmails] = useState<ISubjectEmail[]>(
    form.values.emails,
  );

  useEffect(() => {
    setEmails(form.values.emails);
  }, [form.values.emails]);

  const handleAdd = () => {
    form.setFieldValue(FIELD_NAME.EMAILS, [
      ...form.values.emails,
      { id: form.values.emails.length + 1, email: "" },
    ]);
  };

  const deleteEmail = (i: any) => {
    if (form.values.emails.length > 1) {
      form.setFieldValue(
        FIELD_NAME.EMAILS,
        form.values.emails.filter((a) => a.id !== i.id),
      );
    }
  };

  const handleBlur = (event, index) => {
    event.preventDefault();
    const nextEmails = form.values.emails.map((c, i) => {
      if (i === index) {
        return { id: c.id, email: event.target.value };
      } else {
        return c;
      }
    });
    form.setFieldValue(FIELD_NAME.EMAILS, nextEmails);
  };

  const handleChange = (event, index) => {
    event.stopPropagation();
    const nextEmails = form.values.emails.map((c, i) => {
      if (i === index) {
        return { id: c.id, email: event.target.value };
      } else {
        return c;
      }
    });
    setEmails(nextEmails);
  };

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        gap="10px"
        sx={{
          border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
          borderRadius: "4px",
          padding: "10px",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        {form.values.emails.map((i, index) => {
          return (
            <Grid
              key={i.email}
              item
              display="flex"
              xs={5.8}
            >
              <Field
                name={`${FIELD_NAME.EMAILS}.${index}.email`}
              >
                {({ field, meta }) => (
                  <FormControl>
                    <TextField
                      {...field}
                      value={emails[index]?.email}
                      onBlur={(event) =>
                        handleBlur(event, index)
                      }
                      onChange={(e) =>
                        handleChange(e, index)
                      }
                      error={meta.touched && !!meta.error}
                      helperText={
                        meta.touched && meta.error
                      }
                      label={`Электронная почта ${
                        index + 1
                      }`}
                      size="small"
                      type="text"
                    />
                  </FormControl>
                )}
              </Field>
              <Button
                disableRipple
                disableTouchRipple
                disabled={emails.length === 1}
                sx={{ minWidth: "45px" }}
                onClick={() => deleteEmail(i)}
                startIcon={<CloseIcon />}
              />
            </Grid>
          );
        })}
      </Box>
      {emails.length <= 1 && (
        <Button
          color="blue"
          size="large"
          startIcon={<AddOutlinedIcon />}
          variant="outlined"
          onClick={handleAdd}
          fullWidth
        >
          Добавить почту
        </Button>
      )}
    </>
  );
};
