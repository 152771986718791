import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import { IProps, TForm, mapFormToValue, validationSchema } from "./const";
import DistrictForm from "./components/DistrictForm";

/**
 * Модалка с формой создания/редактирования Региона РФ
 * @param initialValue - начальные значения
 * @param isStatement - создание из заявки
 * @param cultivarEditable - флаг для редактирования культуры
 * @param submitOnMount - сабмит после монтирования
 * @param onSubmit - калбек после успешного запроса
 * */
const Districts: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const baseInititialValues: TForm = Object.assign(
    {
        name: null,
        full_name: null,
        branch: undefined,
        postal_name: undefined,
        light_zone: undefined,
        time_zone: undefined,
        iso: undefined,
    },
    modalProps.initialValues,
  );
  
  const { api, toastStore, swrStore } = useStores();
  const {districtId} = modalProps;

  const mutateDetail = districtId ? swrStore.mutators[SWR_KEYS.getDistrictById(districtId)] :  null

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const promise = districtId
      ? api.regulatoryInfo.patchDistrictById(
        districtId,
          mapFormToValue(values),
        )
      : api.regulatoryInfo.postDistrict(mapFormToValue(values));

    promise
      .then(() => {
        mutateDetail && mutateDetail();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail()
        swrStore.mutators[SWR_KEYS.getDistrictList()]();
        swrStore.mutators[SWR_KEYS.getLightZoneForDistricts()]();
      });
  };

  const values = modalProps.initialValues
    ? {
      ...baseInititialValues,
      modalProps
    }
    : baseInititialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      component={() => (
        <DistrictForm handleClose={handleClose} modalProps={modalProps} />
      )}
    />
  );
};

export default Districts;
