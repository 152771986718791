import React from "react";
import { Stack, Typography } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import TodayIcon from "@mui/icons-material/Today";
import CloseIcon from "@mui/icons-material/Close";
import { ProgressActivityIcon, } from "@/components/icons";
import {
  SEED_ARRIVAL_STATUS,
  SEED_ARRIVAL_STATUS_COLOR,
  SEED_ARRIVAL_STATUS_TEXT
} from "@/pages/SeedAccounting/const";

interface IProps {
  status?: string;
}

const statusIcons = {
  [SEED_ARRIVAL_STATUS.RECEIVED]: <CheckIcon
    sx={{ fontSize: 20 }}/>,
  [SEED_ARRIVAL_STATUS.NOT_RECEIVED]: <CloseIcon
    sx={{ fontSize: 20 }}/>,
  [SEED_ARRIVAL_STATUS.LATE_RECEIVED]: <ProgressActivityIcon
    sx={{ fontSize: 20 }}/>,
  [SEED_ARRIVAL_STATUS.PLANNED]: <TodayIcon
    sx={{ fontSize: 20 }}/>,
  [SEED_ARRIVAL_STATUS.SHORT_DELIVERY]: <ProgressActivityIcon
    sx={{ fontSize: 20 }}/>,
  [SEED_ARRIVAL_STATUS.LATE_SHORT_DELIVERY]: <ProgressActivityIcon
    sx={{ fontSize: 20 }}/>,
  DEFAULT: null
};

export const SeedArrivalStatusBadge = ({ status }: IProps) => {
  const statusText = status ? SEED_ARRIVAL_STATUS_TEXT[status] : "";
  const statusColors = status ? SEED_ARRIVAL_STATUS_COLOR[status] : "";

  return (
    <Stack
      color={statusColors}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        border: "1px solid",
        p: "4px",
        borderRadius: 20,
        width: "fit-content"
      }}
    >
      {status && statusIcons[status]}
      <Typography
        color={"inherit"}
        sx={{
          fontSize: "12px",
          marginLeft: "3px",
          p: "3px"
        }}
      >
        {statusText || "Неизвестно"}
      </Typography>
    </Stack>
  );
};
