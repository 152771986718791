import { isEmpty } from "./helpers";

/**
 * Возвращает значение у свойства объекта c поддержкой массивов. Св-во объекта указывается через ".", напр: 'a.b[1].c'
 */
export const getValueByStringKeyWithArr = (
  object,
  path,
  defaultValue = undefined,
) => {
  if (!object || isEmpty(object) || !path) {
    return false;
  }
  return (
    path
      // eslint-disable-next-line no-useless-escape
      .split(/[\.\[\]\'\"]/)
      .filter((p) => p)
      .reduce((o, p) => (o ? o[p] : defaultValue), object)
  );
};

/**
 * Заменяет null на undefined в объекте
 * @description Может быть полезно для запросов где бэк не может обработать null
 * @param obj - объект включающий поля с null
 * @returns объект где null -> undefined
 */

type TNullToUndefined<T> = {
  [K in keyof T]: T[K] extends null ? undefined : T[K];
};

export const transformNullToUndefined = <T extends object>(
  obj: T,
): TNullToUndefined<T> => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      obj[key] = undefined;
    }
  });
  return obj as TNullToUndefined<T>;
};
