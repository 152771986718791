import yup from "@/utils/yup";
import { resInterface } from "@/api/interfaces";
import dayjs, { Dayjs } from "dayjs";
import { STATUSES } from "@/components/FileDropzone/const";
import { v4 as uuidv4 } from 'uuid';

export interface IProps {
    handleClose: () => void;
    modalProps: {
        key: string;
        agreementId?: number;
        initialValues?: resInterface.IAgreement;
    };
    onSubmit?: () => void;
}

export const FIELD_NAME = {
    AGREEMENT_NAME: "agreement_name",
    AGREEMENT_NUMBER: "agreement_number",
    AGREEMENT_TYPE: "agreement_type",
    AGREEMENT_DATE: "agreement_date",
    EXECUTIVE: "executive",
    EXPIRY_DATE: "expiry_date",
    COUNTERPARTY: "counterparty",
    COMMENT: "comment",
    FILE_LIST: "file_list",
} as const

export interface IValue {
    id: number;
}

export interface TForm {
    [FIELD_NAME.AGREEMENT_NAME]: string;
    [FIELD_NAME.AGREEMENT_DATE]: Dayjs | null;
    [FIELD_NAME.AGREEMENT_NUMBER]: string;
    [FIELD_NAME.AGREEMENT_TYPE]: number;
    [FIELD_NAME.EXECUTIVE]: IValue;
    [FIELD_NAME.EXPIRY_DATE]: Dayjs | null;
    [FIELD_NAME.COUNTERPARTY]: IValue;
    [FIELD_NAME.COMMENT]: string;
    [FIELD_NAME.FILE_LIST]: File[];
}


export const mapValueToForm = (value) => {
    return {
        [FIELD_NAME.AGREEMENT_NAME]: value?.agreement_name || '',
        [FIELD_NAME.AGREEMENT_DATE]:value?.agreement_date ? dayjs(value?.agreement_date) : null,
        [FIELD_NAME.AGREEMENT_NUMBER]: value?.agreement_number,
        [FIELD_NAME.AGREEMENT_TYPE]: value?.agreement_type ? {
            ...value?.agreement_type,
            label: value?.agreement_type?.name
        } : '',
        [FIELD_NAME.EXECUTIVE]: value?.executive ? {
            ...value?.executive,
            label: value?.executive?.full_name
        } : '',
        [FIELD_NAME.EXPIRY_DATE]: value?.expiry_date ? dayjs(value?.expiry_date) : null,
        [FIELD_NAME.COUNTERPARTY]: value?.counterparty ? {
            ...value?.counterparty,
            label: value?.counterparty?.name
        } : '',
        [FIELD_NAME.COMMENT]: value?.comment ?? '',
        [FIELD_NAME.FILE_LIST]: value.files.map(serializeFile),

    };
};

export const mapFormToValues = (values) => ({
    [FIELD_NAME.AGREEMENT_NAME]: values.agreement_name,
    [FIELD_NAME.AGREEMENT_DATE]: values.agreement_date ? dayjs(values.agreement_date).format('YYYY-MM-DD') : null,
    [FIELD_NAME.AGREEMENT_NUMBER]: values.agreement_number,
    [FIELD_NAME.AGREEMENT_TYPE]: values.agreement_type.id,
    [FIELD_NAME.EXECUTIVE]: values.executive.id,
    [FIELD_NAME.EXPIRY_DATE]: values.expiry_date ? dayjs(values.expiry_date).format('YYYY-MM-DD') : null,
    [FIELD_NAME.COUNTERPARTY]: values.counterparty.id,
    [FIELD_NAME.COMMENT]: values.comment || null,
});

export const validationSchema = yup.object().shape({
    [FIELD_NAME.AGREEMENT_NAME]: yup.string().matches(/^(?! *$).+$/, "Поле не может состоять только из пробелов").required(),
    [FIELD_NAME.AGREEMENT_DATE]: yup.date().typeError('Введите корректную дату').required(),
    [FIELD_NAME.AGREEMENT_NUMBER]: yup.string().matches(/^(?! *$).+$/, "Поле не может состоять только из пробелов").required(),
    [FIELD_NAME.AGREEMENT_TYPE]: yup.mixed().required(),
    [FIELD_NAME.EXECUTIVE]: yup.mixed().required(),
    [FIELD_NAME.COUNTERPARTY]: yup.mixed().required(),
    [FIELD_NAME.EXPIRY_DATE]: yup.date().nullable().typeError('Введите корректную дату'),
});

export const serializeFile = (file: any) => {
    return {
        uuid: uuidv4(),
        id: file.id,
        name: file.file_name,
        formatSize: file.file_size,
        preview: file.file_url,
        status: STATUSES.SUCCESS
    };
};

