import React from "react";
import { Formik, FormikHelpers } from "formik";
import Form from "./components/Form";
import {
  IFormik,
  initialValues,
  validationSchema
} from "./const";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { useParams } from "react-router-dom";
import { FIELDS_NAME } from "@/pages/AddStatement/const";

/**
 * Компонент модалки редактирования сорта и культуры для заявки
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    initialValues?: {
      cultivar?: { id: number, name: string }
      variety?: { id: number, name: string }
    }
  };
}

const EditCultivarVarietyStatement: React.FC<IProps> = ({
  modalProps,
  handleClose
}) => {
  const { api, toastStore } = useStores();
  const { id } = useParams();

  const handleSubmit = (values: IFormik, helpers: FormikHelpers<FormikValues>) => {
    const payload = {
      [FIELDS_NAME.CULTIVAR]: values.cultivar.id,
      [FIELDS_NAME.VARIETY]: values.variety.id
    };
    api.statement.patchStatementById(Number(id), payload).then(() => {
      handleClose();

    }).catch((error) => {
      toastStore.createToast({
        type: TOAST_TYPES.ALERT,
        toastProps: {
          message: errorToString(error),
          severity: "error"
        }
      });
    }).finally(() => {
      helpers.setSubmitting(false);
    });
  };

  const values = modalProps?.initialValues ? { ...initialValues, ...modalProps.initialValues } : initialValues;

  return (
    <Formik initialValues={values}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            component={() =>
              <Form modalKey={modalProps.key}/>}/>
  );
};

export default EditCultivarVarietyStatement;