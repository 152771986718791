import React from "react";
import { Box } from "@mui/material";
import qs from "qs";
import { useSearchParams } from "react-router-dom";
import { SxProps } from "@mui/material";

import {
  DateFilter,
  DateRangeFilter,
  AutocompleteFilter,
  SearchFilter,
  YearsRange,
  EditableSelectFilter,
  CheckBoxFilter
} from "./components";

import { useDebounceCallback, useQuery } from "@/hooks";

import { IFilter, IValue } from "@/interfaces";
import { IStatementQueryParams } from "@/api/interfaces/requests";

import { convertStringToDate, isNull } from "@/utils/helpers";

import { filtersListStyle } from "./style";
import { FiltersType } from "./interfaces";
import YearPicker from "./components/YearPicker";
interface FiltersProps {
  filters: IFilter[];
  showSearch?: boolean;
  defaultSearch?: string;
  searchStyle?: SxProps;
}

const Filters: React.FC<FiltersProps> = ({
  showSearch = true,
  filters,
  defaultSearch,
  searchStyle,
}) => {
  const query = useQuery<IStatementQueryParams>();
  const [, setSearchParams] = useSearchParams();

  const handleSearch = useDebounceCallback(
    (value?: string) => {
      const queryParams = qs.stringify(
        {
          ...query,
          search: value,
          page: 1,
        },
        { arrayFormat: "comma" },
      );
      setSearchParams(queryParams);
    },
    300,
  );

  const handleDateRangeChange = (
    date: [Date | null, Date | null],
  ) => {
    const formatDateAfter = convertStringToDate(
      date[0]?.toLocaleString() ?? "",
    );
    const formatDateBefore = convertStringToDate(
      date[1]?.toLocaleString() ?? "",
    );

    const queryParams = qs.stringify(
      {
        ...query,
        date_range_after: formatDateAfter,
        date_range_before: formatDateBefore,
        page: 1,
      },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleDateChange = (date: Date | null) => {
    const formatDateAfter = convertStringToDate(
      date?.toLocaleString() ?? "",
    );

    const queryParams = qs.stringify(
      {
        ...query,
        date: formatDateAfter,
        page: 1,
      },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleSelectChange = (
    value: IValue | null,
    name: string,
  ) => {
    const formattedValue = !isNull(value)
      ? value
      : undefined;
    const queryParams = qs.stringify(
      {
        ...query,
        [name]: formattedValue,
        page: 1,
      },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const generateFilters = filters.map((filter: IFilter) => {
    switch (filter.type) {
      case FiltersType.autoComplete:
        return (
          <AutocompleteFilter
            key={filter.name}
            filter={filter}
            onChange={handleSelectChange}
          />
        );
      case FiltersType.date:
        return (
          <DateFilter
            key={filter.name}
            filter={filter}
            onChange={handleDateChange}
          />
        );
      case FiltersType.datesRange:
        return (
          <DateRangeFilter
            key={filter.name}
            filter={filter}
            onChange={handleDateRangeChange}
          />
        );
      case FiltersType.yearsRange:
        return <YearsRange key={filter.name} />;
      case FiltersType.yearPicker:
        return (
          <YearPicker key={filter.name} filter={filter} />
        );
      case FiltersType.select:
        return (
          <EditableSelectFilter key={filter.name} filter={filter} />
        );
      case FiltersType.checkbox:
        return (
          <CheckBoxFilter key={filter.name} filter={filter} />
        );
      default:
        return null;
    }
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignContent: "stretch",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <Box sx={filtersListStyle()}>
        {showSearch && (
          <SearchFilter
            onSearch={handleSearch}
            defaultSearch={defaultSearch}
            searchStyle={searchStyle}
          />
        )}

        {generateFilters}
      </Box>
    </Box>
  );
};

export default Filters;
