import { IIndicator } from "@/api/interfaces/responses";
import yup from "@/utils/yup";

export const FIELDS_NAME = {
  INDICATOR: "indicator",
} as const;

export const defaultValues = {
  [FIELDS_NAME.INDICATOR]: null,
};

export interface IFormik {
  [FIELDS_NAME.INDICATOR]: IIndicator | null;
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.INDICATOR]: yup.mixed().required(),
});
