import React, {
  Fragment,
  useEffect,
  useState,
} from "react";
import { Field, FormikContextType } from "formik";
import { FormControl, FormHelperText } from "@mui/material";

import Autocomplete from "@/components/Autocomplete";

import { DISTRIBUTION_FIELD_NAME } from "../../const";
import { SWR_KEYS } from "@/const";

import { useInfiniteScroll, useStores } from "@/hooks";

/**
 * Поля формы относящиеся только к организациям
 * */
interface IProps {
  formik: FormikContextType<any>;
  idStatement: number;
}

const FieldsForOrganization: React.FC<IProps> = ({
  formik,
  idStatement,
}) => {
  const { api, swrStore } = useStores();
  const [organizationSearch, setOrganizationSearch] =
    useState("");

  const swrKeyOrganization = SWR_KEYS.getOrganizationList();
  const getKeyOrganization = (index: number) => ({
    _key: swrKeyOrganization,
    page: index + 1,
    statement: idStatement,
    search: organizationSearch,
    branch: formik.values.branch?.id ?? undefined,
  });

  const orgFetcher = (args) => {
    const { page, search } = args;
    return api.statement.getOrganizationList({
      page,
      search,
    });
  };

  const {
    data: dataOrganization,
    handleScroll: handleScrollOrganization,
    mutate: mutateOrganization,
  } = useInfiniteScroll(getKeyOrganization, orgFetcher, {
    revalidateFirstPage: false,
  });

  const handleChangeOrganization = async (event, child) => {
    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.ORGANIZATION,
      child,
    );

    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.ADDRESS,
      child,
    );
  };

  useEffect(() => {
    swrStore.addMutator(
      swrKeyOrganization,
      mutateOrganization,
    );
  }, []);

  return (
    <Fragment>
      <Field name={DISTRIBUTION_FIELD_NAME.ORGANIZATION}>
        {({ field, meta }) => (
          <FormControl required>
            <Autocomplete
              {...field}
              onChange={handleChangeOrganization}
              label="Организация"
              required={true}
              data={dataOrganization}
              handleScroll={handleScrollOrganization}
              search={setOrganizationSearch}
              error={meta.touched && !!meta.error}
            />

            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
    </Fragment>
  );
};
export default FieldsForOrganization;
