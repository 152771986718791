import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { BRANCHES_HEADER_LABELS, BRANCHES_KEYS, } from "../const";

import { TBranchesKeys } from "../interfaces";
import { RegionCell, StaffCell, PhoneCell, EditingCellsBranch } from "../templates/Branches/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const BranchesColumns: IColumn<TBranchesKeys>[] = [
  {
    label: BRANCHES_HEADER_LABELS.name,
    key: BRANCHES_KEYS.name,
    contentHeader: BRANCHES_HEADER_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: BRANCHES_HEADER_LABELS.region,
    key: BRANCHES_KEYS.region__name,
    contentHeader: BRANCHES_HEADER_LABELS.region,
    bodyCellProps: {
      render: RegionCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: BRANCHES_HEADER_LABELS.staff_director,
    key: BRANCHES_KEYS.staff_director__last_name,
    contentHeader: BRANCHES_HEADER_LABELS.staff_director,
    bodyCellProps: {
      render: StaffCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: BRANCHES_HEADER_LABELS.phones,
    key: BRANCHES_KEYS.phones,
    contentHeader: BRANCHES_HEADER_LABELS.phones,
    bodyCellProps: {
      render: PhoneCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: BRANCHES_HEADER_LABELS.editing,
    key: "editing",
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsBranch,
      styles: cellButtons,
    },
  },
];
