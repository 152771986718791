import yup from "@/utils/yup";

export const FIELDS_NAME = {
  POST: "post",
  STAFF_NUMBER: "staff_number",
  WAGE_FUND_MONTH: "wage_fund_month",
} as const;

export interface IFormik {
  [FIELDS_NAME.POST]: {
    id: number;
    name: string;
  };
  [FIELDS_NAME.STAFF_NUMBER]: number;
  [FIELDS_NAME.WAGE_FUND_MONTH]: string;
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.POST]: yup.mixed().required(),
  [FIELDS_NAME.STAFF_NUMBER]: yup.number().required(),
  [FIELDS_NAME.WAGE_FUND_MONTH]: yup.string().required(),
});
