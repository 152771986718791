import React from "react";
import { FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import {
  IProps,
  lastValidationSchema,
  mapFormToValue,
  mapValueToForm,
  TForm,
  validationSchema,
} from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import ModalStepper from "@/components/ModalStepper";
import EmployeeFormFirstStep from "./components/EmployeeFormSteps/first";
import EmployeeFormSecondStep from "./components/EmployeeFormSteps/second";

/**
 * Модалка с формой создания/редактирования сотркудника
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const Employee: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const baseInititialValues: TForm = Object.assign(
    {
      first_name: undefined,
      last_name: undefined,
      middle_name: undefined,
      dative_name: undefined,
      emails: [{ id: 0, email: '' }],
      phones: [{ id: 0, phone: '', name: '' }],
      comment: undefined,
      post: undefined,
      subdivision: undefined,
      department: undefined,
      branch: undefined,
      plot: undefined,
      right_sign: false,
    },
    modalProps.initialValues,
  );

  const { employeeId } = modalProps;
  const { api, toastStore, swrStore } = useStores();


  const mutateDetail = employeeId ? swrStore.mutators[SWR_KEYS.getEmployeeById(employeeId)] :  null

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const promise = employeeId
      ? api.regulatoryInfo.updateEmployee(
        employeeId,
        mapFormToValue(values),
      )
      : api.regulatoryInfo.postEmployee(mapFormToValue(values));


    promise
      .then(() => {
        mutateDetail && mutateDetail()
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errorMessage = errorToString(error);
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorMessage,
            severity: "error",
          },
        });
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail()
        swrStore.mutators[SWR_KEYS.getEmployeeList()]();
      });
  };

  const subtitle = modalProps.employeeId
    ? "Редактировать запись"
    : "Добавить запись";

  const values = modalProps.initialValues
    ? mapValueToForm(
      baseInititialValues,
      modalProps.initialValues,
    )
    : baseInititialValues;

  return (
    <ModalStepper
      title="Сотрудник"
      subtitle={subtitle}
      initialValues={values}
      onSubmit={handleSubmit}
      handleClose={handleClose}
    >
      <ModalStepper.Step
        validationSchema={validationSchema}
      >
        <EmployeeFormFirstStep
          modalProps={modalProps}
          handleClose={handleClose}
        />
      </ModalStepper.Step>

      <ModalStepper.Step
        validationSchema={lastValidationSchema}
      >
        <EmployeeFormSecondStep
          modalProps={modalProps}
          handleClose={handleClose}
        />
      </ModalStepper.Step>
    </ModalStepper>
  );
};

export default Employee;
