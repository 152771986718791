import React, {
  ReactElement,
  useEffect,
  useLayoutEffect
} from "react";

import { useStores } from "@/hooks";

import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { Box, Typography } from "@mui/material";
import { tableWrapperStyle } from "./style";
import theme from "@/theme";
import useRightSidebar from "@/hooks/useRightSidebar";
import AWTable from "./components/AWTable";
import { TITLE_FOR_TABLE } from "./const";

const AgriculturalWorkDocSeedAccounting = (): ReactElement => {
  const { headerStore } = useStores();
  const { handleCloseInstantly } = useRightSidebar();

  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.AGRICULTURE_WORK_DOC);
  }, []);

  // закрытие правого сайдбара при переходе на другую страницу
  useEffect(() => {
    return () => {
      handleCloseInstantly();
    };
  }, []);

  return (
    <Box sx={tableWrapperStyle(theme)}>
      <Typography 
        variant="h2" 
        pb={1}
      >
        {TITLE_FOR_TABLE}
      </Typography>
      <AWTable />
    </Box>
  );
};

export default AgriculturalWorkDocSeedAccounting;
