import React from "react";
import {
  Stack,
} from "@mui/material";
import { DatePickerField, FastTextField } from "@/components/Fields";
import { YEAR_WEATHER_FIELD_NAME } from "../const";


const YearWeatherHistoryFirstStep: React.FC<any> = () => {
  return (
    <Stack direction={"column"} gap={2}>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.SNOW_COVER_ESTABLISHMENT} label="Установление снежного покрова" fullWidth/>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.SNOW_COVER_DESCENT} label="Сход снежного покрова" fullWidth/>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.AUTUMN_FIRST_FROST} label="Первый заморозок осенью" fullWidth/>
        <DatePickerField name={YEAR_WEATHER_FIELD_NAME.SPRING_LAST_FROST} label="Последний заморозок весной" fullWidth />
        <FastTextField name={YEAR_WEATHER_FIELD_NAME.WATER_STAGNATION} label="Застой воды" fullWidth />
    </Stack>
  );
};

export default YearWeatherHistoryFirstStep;
