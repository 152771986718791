import React, { ReactNode } from "react";
import {
  SxProps,
  TableCell,
  TableCellProps,
  Theme,
  Typography,
  useTheme
} from "@mui/material";
import { isUndefined } from "@/utils/helpers";
import { useMatches } from "@/apps/Respons";
import { TStatemenKeys } from "@/pages/Statement/interfaces";
import { IColumn } from "../interfaces";

/**
 * Интерфейс для свойств компонента Cell.
 * @interface
 * @template T - Тип данных текущей строки таблицы.
 */
interface IProps<T> {
  /**
   * Функция для кастомного рендера содержимого ячейки.
   */
  render?: (
    data: ReactNode,
    currentRowData?: T,
    visibleRows?: T[],
  ) => JSX.Element;
  /**
   * Содержимое ячейки.
   */
  content?: ReactNode;
  /**
   * Данные текущей строки таблицы.
   */
  currentRowData?: T;
  /**
   * Стили для ячейки.
   */
  cellStyle: ((theme: Theme) => SxProps) | undefined;
  /**
   * параметры для ячейки ( для адаптива )
   */
  cellProp?: TableCellProps & any;

  /**
   * массив данных для таблицы
   */
  visibleRows: T[];

  sx?: SxProps<Theme>;

  columnData?: IColumn<TStatemenKeys>;
}

/**
 * Компонент, представляющий ячейку в таблице.
 * @param {IProps<T>} props - Свойства компонента.
 * @returns {JSX.Element}
 * @template T
 */
function Cell<T>({
  render,
  content,
  currentRowData,
  cellStyle,
  cellProp,
  sx = {},
  columnData,
  visibleRows
}: IProps<T>): JSX.Element {
  const theme = useTheme();
  const { matches } = useMatches();

  const resposiveStyle = () => {
    if (matches?.small) {
      return {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "nowrap",
        width: "100%"
      };
    }
    return {};
  };
  return (
    <TableCell
      sx={{ ...cellStyle?.(theme), sx, ...resposiveStyle() }}
      {...cellProp}
    >
      {matches?.small ? (
        <Typography variant="h4" fontWeight={600}>
          {columnData?.label}
        </Typography>
      ) : null}
      {!isUndefined(render) ? render(content, currentRowData, visibleRows) : content}
    </TableCell>
  );
}

export default Cell;
