import React from "react";
import { IIcon } from "./interfaces";

const FileCopy = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg
      className={className} 
      xmlns="http://www.w3.org/2000/svg"
      width="15.833496" 
      height="18.333496" 
      viewBox="0 0 15.8335 18.3335" 
      fill="none"
    >
      <path 
        id="file_copy" 
        d="M14.1665 15L5 15C4.5415 15 4.14941 14.8369 3.82275 14.5103C3.49658 14.1841 3.3335 13.7915 3.3335 13.3335L3.3335 1.6665C3.3335 1.2085 3.49658 0.815918 3.82275 0.489746C4.14941 0.163086 4.5415 0 5 0L10.8335 0L15.8335 5L15.8335 13.3335C15.8335 13.7915 15.6699 14.1841 15.3438 14.5103C15.0176 14.8369 14.625 15 14.1665 15ZM10 5.8335L10 1.6665L5 1.6665L5 13.3335L14.1665 13.3335L14.1665 5.8335L10 5.8335ZM1.6665 18.3335C1.2085 18.3335 0.815918 18.1699 0.489746 17.8438C0.163086 17.5176 0 17.125 0 16.6665L0 5L1.6665 5L1.6665 16.6665L10.8335 16.6665L10.8335 18.3335L1.6665 18.3335Z" 
        fill="#2F8DED"
        fillOpacity="1.000000"
        fillRule="nonzero" />
    </svg>
  );
};

export default FileCopy;
