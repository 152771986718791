import React from "react";
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteKeyLoader,
} from "swr/infinite";
import { Fetcher, MutatorCallback } from "swr";
import { PAGE_SIZE } from "@/const";
import { IPaginatedResult } from "@/api/interfaces/responses";

interface IUseInfiniteScrollReturn<T> {
  data: T[];
  isLoadingMore?: boolean;
  isReachingEnd?: boolean;
  isRefreshing?: boolean;
  pageSize: number;
  isLoading: boolean;
  error: any;
  isEmpty: boolean;
  handleNext: () => void;
  handleReset: () => void;
  handleScroll: (event: React.UIEvent<HTMLElement>) => void;
  mutate: MutatorCallback;
}

interface IConfig extends SWRInfiniteConfiguration {
  pageSize?: number;
}

export const useInfiniteScroll = <T>(
  key: SWRInfiniteKeyLoader,
  fetcher: Fetcher<IPaginatedResult<T>>,
  config: IConfig = {},
): IUseInfiniteScrollReturn<T> => {
  const { pageSize = PAGE_SIZE, ...restConfig } = config;
  const swr = useSWRInfinite<IPaginatedResult<T>, any, any>(
    key,
    fetcher,
    {
      ...restConfig,
    },
  );
  const {
    data,
    size,
    setSize,
    isValidating,
    isLoading,
    mutate,
    error,
  } = swr;
  const results = data
    ? data.map((res) => res.results).flat()
    : [];
  const isLoadingMore =
    isLoading ||
    (size > 0 &&
      data &&
      typeof data[size - 1] === "undefined");
  const isEmpty = results.length === 0 && !isLoadingMore;
  const isReachingEnd =
    isEmpty ||
    (data && data[data.length - 1]?.next === null);
  const isRefreshing =
    isValidating && data && data.length === size;
  const handleNext = () => setSize(size + 1);

  const handleScroll = (
    event: React.UIEvent<HTMLElement>,
  ) => {
    const { scrollHeight, scrollTop, clientHeight } =
      event.currentTarget;
    if (
      !isLoadingMore &&
      scrollHeight - scrollTop <= clientHeight * 2
    ) {
      !isReachingEnd && handleNext();
    }
  };

  const handleReset = () => setSize(1);

  return {
    data: results,
    isLoadingMore,
    isReachingEnd,
    isRefreshing,
    isLoading,
    error,
    isEmpty,
    handleNext,
    handleScroll,
    handleReset,
    pageSize,
    mutate,
  };
};
