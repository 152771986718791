import React from "react";
import { Formik, FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { FormikValues } from "formik/dist/types";
import GroupForm from "../GroupForm";
import Stepper from "@/components/Stepper";
import useStepperContext
  from "@/components/Stepper/hooks/useStepperContext";
import {
  FIELDS_NAME,
  groupValidationSchema,
  IGroupFormik,
  initialGroupValues
} from "../../const";
import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { SWR_KEYS } from "@/const";
import { IPlanPlot } from "@/api/interfaces/responses";

const Group = () => {
  const { id } = useParams();
  const { api, toastStore } = useStores();
  const { data, mutate } = useSWR<IPlanPlot>(SWR_KEYS.getPlanningPlotById(id as string))

  const { handleNext } = useStepperContext();
  const handleSubmit = (values: IGroupFormik, helpers: FormikHelpers<FormikValues>) => {
    const ids = values.groups.map(el => el.id);
    helpers.setSubmitting(true);
    return api.planning.createGroups(id as string, { indicator_methodology: ids }).then(() => {
      void mutate(prev => {
        return prev ? {...prev, indicator_methodology: values.groups} : undefined
      })
      handleNext();
    }).catch(error => {
      toastStore.createToast({
        type: TOAST_TYPES.ALERT,
        toastProps: {
          message: errorToString(error),
          severity: "error"
        }
      });
    })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const values = data ? {
    [FIELDS_NAME.GROUPS]: data.indicator_methodology
  }: initialGroupValues
  return (
    <Formik<IGroupFormik> initialValues={values}
            onSubmit={handleSubmit}
            validationSchema={groupValidationSchema}>
      {({ handleSubmit, isSubmitting, values }) => (
        <Stepper.Step handleSubmit={handleSubmit}
                      nextButtonLabel="Распределить"
                      disabled={isSubmitting || !values.groups.length}>
          <GroupForm/>
        </Stepper.Step>
      )}
    </Formik>
  );
};

export default Group;