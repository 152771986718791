import { reqInterface, resInterface } from "@/api/interfaces";
import yup from "@/utils/yup";
import { transformEmptyToUndefined } from "../Employee/util";

export interface IProps {
    handleClose: () => void;
    modalProps: {
        key: string;
        departmentId?: number;
        initialValues?: resInterface.IDepartments;
    };
    onSubmit?: () => void;
}

export interface IDepartmentEmail {
    id: number;
    email: string;
}
export interface IDepartmentPhone {
    id: number;
    phone: string;
    name?: string;
}
export interface IDeptStaffDirector {
    id: number;
    full_name: string;
}
export interface IDeptDepartmentParent {
    id: number;
    name: string;
}

export type TForm = Omit<
  reqInterface.IDepartmentsReq,
  | "emails"
  | "phones"
> & {
  emails: IDepartmentEmail[];
  phones: IDepartmentPhone[];
  staff_director?: IDeptStaffDirector;
  department_parent?: IDeptDepartmentParent;
};

export enum DEPARTMENT_FIELD_NAME {
    NAME = 'name',
    DEPARTMENT_PARENT = 'department_parent',
    STAFF_DIRECTOR = 'staff_director',
    PHONES = "phones",
    EMAILS = "emails",
}

export const mapValueToForm = (
    baseInititialValues,
    departmentData: resInterface.IDepartments,
  ): TForm => {
    return {
      ...baseInititialValues,
      ...{
        ...departmentData,
        [DEPARTMENT_FIELD_NAME.PHONES]: departmentData.phones?.length
          ? departmentData.phones.map((i, index) => ({
            id: index,
            phone: i.phone,
            name: i.name,
          }))
          : [{ id: 0, phone: "", name: "" }],
        [DEPARTMENT_FIELD_NAME.EMAILS]: departmentData.emails?.length
          ? departmentData.emails.map((i, index) => ({
            id: index,
            email: i.email,
          }))
          : [{ id: 0, email: "" }],
          [DEPARTMENT_FIELD_NAME.DEPARTMENT_PARENT]: {
            ...departmentData.department_parent,
            label: departmentData?.department_parent?.name
          },
          [DEPARTMENT_FIELD_NAME.STAFF_DIRECTOR]: {
            ...departmentData.staff_director,
            label: departmentData.staff_director?.full_name
          },
      },
    };
};

export const mapFormToValue = (values: TForm) => {
    const emails = values.emails.map((i) => ({
      email: i.email,
    }));
    const phones = values.phones.map((i) => ({
      name: i.name as string,
      phone: i.phone,
    }));
  
   
    const modificatedPhones = phones.map((phone) => transformEmptyToUndefined(phone)).filter((phone) => phone.phone !== undefined)
    const modificatedEmails = emails.map((email) => transformEmptyToUndefined(email)).filter((email) => email.email !== undefined)
  
    return {
      ...values,
      [DEPARTMENT_FIELD_NAME.PHONES]: modificatedPhones,
      [DEPARTMENT_FIELD_NAME.EMAILS]: modificatedEmails,
      [DEPARTMENT_FIELD_NAME.STAFF_DIRECTOR]: values?.staff_director?.id,
      [DEPARTMENT_FIELD_NAME.DEPARTMENT_PARENT]: values?.department_parent?.id,

    };
  };


// Validation

const phoneSchema = yup.object().shape({
    phone: yup.string()
      .matches(
        /^(\+?\d{1,3}[- ]?)?\d{10}$/,
        "Неверный формат номера телефона",
      )
      .nullable(),
  });


  const emailSchema = yup.object().shape({
    email: yup.string()
      // .email("Неверный формат электронной почты")
      .matches(/.*@.*/g,'Неверный формат электронной почты')
      .nullable(),
  });


export const validationSchema = yup.object().shape({
    [DEPARTMENT_FIELD_NAME.NAME] : yup.string().trim().required().notOneOf([''], 'Это поле не может быть пустым'),
    [DEPARTMENT_FIELD_NAME.EMAILS]: yup.array()
    .of(emailSchema)
    .nullable(),
  [DEPARTMENT_FIELD_NAME.PHONES]: yup.array()
    .of(phoneSchema)
    .nullable(),
  });
  
