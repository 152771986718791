import React, { useEffect, useState } from "react";
import { Field, FormikContextType } from "formik";
import { FormControl, FormHelperText } from "@mui/material";

import Autocomplete from "@/components/Autocomplete";

import { DISTRIBUTION_FIELD_NAME } from "../../const";
import { SWR_KEYS } from "@/const";

import { useInfiniteScroll, useStores } from "@/hooks";

import { isNull } from "@/utils/helpers";

interface IProps {
  formik: FormikContextType<any>;
  idStatement: number;
}
/**
 * Поля формы относящиеся только к ГСУ
 * */
const FieldsForPlot: React.FC<IProps> = ({
  formik,
  idStatement,
}) => {
  const { api, swrStore } = useStores();

  const [branchSearch, setBranchSearch] = useState("");
  const [plotSearch, setPlotSearch] = useState("");

  const swrKeyBranches = SWR_KEYS.getPlanBranchesList();
  const getKeyBranches = (index: number) => ({
    _key: swrKeyBranches,
    page: index + 1,
    statement: idStatement,
    search: branchSearch,
  });

  const branchFetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getPlanBranchesList({
      page,
      search,
    });
  };

  const {
    data: dataBranches,
    handleScroll: handleScrollBranches,
    mutate: mutateBranches,
  } = useInfiniteScroll(getKeyBranches, branchFetcher, {
    revalidateFirstPage: false,
  });

  const swrKeyPlot = SWR_KEYS.getPlotList();
  const getKeyPlot = (index: number) => ({
    _key: swrKeyPlot,
    page: index + 1,
    statement: idStatement,
    name: plotSearch,
    branch: formik.values.branch?.id ?? undefined,
  });

  const plotFetcher = (args) => {
    const { page, name, branch } = args;
    return api.regulatoryInfo.getPlotList({
      page,
      name,
      branch,
    });
  };

  const {
    data: dataPlot,
    handleScroll: handleScrollPlot,
    mutate: mutatePlot,
  } = useInfiniteScroll(getKeyPlot, plotFetcher, {
    revalidateFirstPage: false,
  });

  const handleChangeBranch = (event, child) => {
    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.BRANCH,
      child,
    );
  };

  const handleChangePlot = (event, child) => {
    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.PLOT,
      child,
    );

    api.regulatoryInfo.getPlotById(child.id).then((el) => {
      if (!isNull(el.actual_address)) {
        formik.setFieldValue(
          DISTRIBUTION_FIELD_NAME.ADDRESS,
          el.actual_address,
        );
      }
    });
  };

  useEffect(() => {
    swrStore.addMutator(swrKeyBranches, mutateBranches);
    swrStore.addMutator(swrKeyPlot, mutatePlot);
  }, []);

  const handleClearPlot = () => {
    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.PLOT,
      null,
    );
    formik.setFieldValue(
      DISTRIBUTION_FIELD_NAME.ADDRESS,
      undefined,
    );
  };

  return (
    <>
      <Field name={DISTRIBUTION_FIELD_NAME.BRANCH}>
        {({ field, meta }) => (
          <FormControl>
            <Autocomplete
              {...field}
              onChange={handleChangeBranch}
              label="Филиал"
              data={dataBranches}
              handleScroll={handleScrollBranches}
              search={setBranchSearch}
            />

            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>

      <Field name={DISTRIBUTION_FIELD_NAME.PLOT}>
        {({ field, meta }) => (
          <FormControl required>
            <Autocomplete
              {...field}
              onChange={handleChangePlot}
              label="Наименование ГСУ"
              data={dataPlot}
              required
              handleScroll={handleScrollPlot}
              search={setPlotSearch}
              onClear={handleClearPlot}
              error={meta.touched && !!meta.error}
            />

            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
    </>
  );
};
export default FieldsForPlot;
