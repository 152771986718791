import {
  ICultivarFieldWorkChemistry
} from "@/pages/AddCultivarFieldWork/const";
import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import theme from "@/theme";
import CloseIcon from "@mui/icons-material/Close";

interface IChemistryItem {
  item: ICultivarFieldWorkChemistry,
  handleDelete: () => void
}

const ChemistryItem: React.FC<IChemistryItem> = ({ item, handleDelete }) => {
  return (
    <Box
      sx={{
        padding: 0,
        borderBottom: "1px solid black",
        borderColor: theme.palette.blackAndWhite.stroke,
      }}
    >
      <Box sx={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(2),
        gap: theme.spacing(2),
      }}>
        <Box flexGrow={1}>
          <Typography
            variant="body2"
            color={theme.palette.blackAndWhite.black}
          >
            {item.chemistry.name}
          </Typography>
        </Box>
        <Box width={80}>
          <Typography
            color={theme.palette.blackAndWhite.black}
            variant="body2"
          >
            {item.quantity_total}
          </Typography>
        </Box>
        <Box width={140}>
          <Typography
            color={theme.palette.blackAndWhite.gray}
            variant="body2"
          >{`${item.price} руб/eд`}</Typography>
        </Box>
        <IconButton color="error" onClick={handleDelete}>
          <CloseIcon/>
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChemistryItem