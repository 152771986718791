import React from "react";
import { Typography } from "@mui/material";

import { TITLES_PAGES } from "@/pages/const";

/**
 * хедер для страницы заявок
 * @returns React.FC
 */
const HeaderReports: React.FC = () => {
  return (
    <Typography variant="h3" noWrap>
      {TITLES_PAGES.reports}
    </Typography>
  );
};

export default HeaderReports;
