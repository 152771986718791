import { IColumn } from "@/apps/Table/interfaces";
import { TMethKeys } from "../interfaces";
import { METH_KEYS, METH_LABELS } from "../const";
import {
  CultivarTextCell,
  DateTextCell,
  NumTextCell,
  PointerTextCell,
} from "../cells";
import {
  cellButtonsMaterialType,
  cellPointText,
  headerCellStyle,
} from "../style";
import { IndicatorTypeCell } from "../templates/Indicators/cells";
import { EditingCellsMethodology } from "../templates/Methodology/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const methodlogyColumns: IColumn<TMethKeys>[] = [
  {
    label: METH_LABELS.type_trial,
    key: METH_KEYS.type_trial,
    contentHeader: METH_LABELS.type_trial,
    bodyCellProps: {
      render: IndicatorTypeCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: METH_LABELS.name,
    key: METH_KEYS.name,
    contentHeader: METH_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: METH_LABELS.cultivar,
    key: METH_KEYS.cultivar,
    contentHeader: METH_LABELS.cultivar,
    bodyCellProps: {
      render: CultivarTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: METH_LABELS.number,
    key: METH_KEYS.number,
    contentHeader: METH_LABELS.number,
    bodyCellProps: {
      render: NumTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: METH_LABELS.confirmation_date,
    key: METH_KEYS.confirmation_date,
    contentHeader: METH_LABELS.confirmation_date,
    bodyCellProps: {
      render: DateTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: METH_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsMethodology,
      styles: cellButtonsMaterialType,
    },
  },
];
