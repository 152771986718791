import React, { Fragment, useEffect } from 'react'
import { useStores } from '@/hooks';
import { Field, Form, useFormikContext } from 'formik';
import { Alert, Box, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack,Typography } from '@mui/material';
import { IProps, TForm, VEGETATION_CAUSES_FIELD_NAME } from '../const';
import CheckIcon from "@mui/icons-material/Check";
import theme from '@/theme';
import FastRegexTextField from '@/components/FastRegexTextField';

/**
 * Форма создания/редактирования фазы вегетации
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * */

export const VegetationCausesForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<TForm>();

  const title = modalProps.vegetationCausesId
    ? "Редактировать запись"
    : "Добавить запись";

    useEffect(() => {
      formik.setFieldError('non_field_errors', undefined)
    }, [formik.values])

  return (
    <Fragment>
      <Box p={3}>
        <Typography fontSize={12} color={theme.palette.blackAndWhite.gray}>
            Причина
        </Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>

        <Stack direction={"column"} gap={2} p={2}>
            <FastRegexTextField
              name={VEGETATION_CAUSES_FIELD_NAME.CODE}
              label='Код'
              fullWidth
              size="small"
              required
            />
          <FastRegexTextField
            name={VEGETATION_CAUSES_FIELD_NAME.NAME}
            label='Название'
            fullWidth
            size="small"
            required
          />
          <Field name={VEGETATION_CAUSES_FIELD_NAME.TYPE_OF_REASON}>
              {({ field }) => (
                <FormControl>
                  <RadioGroup {...field} >
                    <Stack direction="column">
                      <FormControlLabel
                        sx={{width: 'fit-content'}}
                        value={'failure'}
                        control={
                          <Radio color="secondary" />
                        }
                        label="Незакладка семян"
                      />
                      <FormControlLabel
                        sx={{width: 'fit-content'}}
                        value={'rejection'}
                        control={
                          <Radio color="secondary" />
                        }
                        label="Браковка сортоопыта"
                      />
                      <FormControlLabel
                        sx={{width: 'fit-content'}}
                        value={'death'}
                        control={
                          <Radio color="secondary" />
                        }
                        label="Гибель сортоопыта"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            </Field>
        </Stack>

        <Divider />
        {formik.errors?.non_field_errors &&
          <Alert
            severity="error">
            {formik.errors?.non_field_errors}
          </Alert>
        }

        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red'
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type='submit'
            disabled={formik.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  )
}
