import React, { FC, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Container, List } from "@mui/material";

import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { Logo, LogoText } from "@/components/Logo";
import {
  AgricultureIcon,
  BookIcon,
  LabProfileIcon,
  ShoppingCartIcon,
  TodayIcon,
} from "@/components/icons";

import { TIMER_SIDEBAR } from "./const";
import { NAVBAR_LABELS } from "@/const";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import {
  Drawer,
  DrawerHeader,
  hideSidebarStyle,
  navLinkStyle,
  sidebarHeaderStyle,
  sidebarTitleStyle,
} from "./styles";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {
  PERMISSION_CRUD,
  PERMISSIONS,
} from "@/premissions";
import { useRightSidebar, useStores } from "@/hooks";

interface IProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  open: () => void;
  openStore: () => void;
  close: () => void;
}

/**
 * @component
 * Боковая панель основного лейаута
 * @param isOpen: положение боковой панели
 * @param openStore положение боковой панели
 * @param toggleSidebar: функция открытия/закрытия сайдбара
 * @returns
 */
const Navbar: FC<IProps> = ({
  isOpen,
  toggleSidebar,
  openStore,
  close,
  open,
}) => {
  const theme = useTheme();
  const { userStore } = useStores();
  const { handleCloseInstantly } = useRightSidebar();

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const mockSidebarData = [
    {
      label: NAVBAR_LABELS.main,
      href: ROUTES_PATHS.main,
      isPermission: userStore.isPermission(
        PERMISSIONS.registrationStatement,
        PERMISSION_CRUD.view,
      ),
      icon: <HomeOutlinedIcon />,
    },
    {
      label: NAVBAR_LABELS.statements,
      href: ROUTES_PATHS.statements,
      isPermission: userStore.isPermission(
        PERMISSIONS.registrationStatement,
        PERMISSION_CRUD.view,
      ),
      icon: <LabProfileIcon />,
    },
    {
      label: NAVBAR_LABELS.planning,
      href: ROUTES_PATHS.planning,
      isPermission: true,
      icon: <TodayIcon />,
    },
    {
      label: NAVBAR_LABELS.agriculturalWork,
      href: ROUTES_PATHS.agriculturalWork,
      isPermission: userStore.isPermission(
        PERMISSIONS.agriculturalLabourLocation,
        PERMISSION_CRUD.view,
      ),
      icon: <MapOutlinedIcon />,
    },
    {
      label: NAVBAR_LABELS.agriculturalWorkDoc,
      href: ROUTES_PATHS.agriculturalWorkDoc,
      isPermission: true,
      icon: <AgricultureIcon />,
    },
    {
      label: NAVBAR_LABELS.offBudget,
      href: ROUTES_PATHS.offBudget,
      isPermission: userStore.isPermission(
        PERMISSIONS.extrabudgetaryComPlanFact,
        PERMISSION_CRUD.view,
      ),
      icon: <ShoppingCartIcon />,
    },
    {
      label: NAVBAR_LABELS.handbook,
      href: `${ROUTES_PATHS.handbook}?module=1`,
      isPermission: true,
      icon: <BookIcon />,
    },
  ];

  /**
   * @function
   * метод открывающий сайдбар при наведении
   */
  const handleOpen = (): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      openStore();
    }, TIMER_SIDEBAR);

    open();
  };

  /**
   * @function
   * метод закрывающий сайдбар
   */
  const handleClose = (): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    close();
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Drawer variant="permanent" anchor="left" open={isOpen}>
      <DrawerHeader sx={sidebarHeaderStyle}>
        <NavLink to={ROUTES_PATHS.main}>
          <Container sx={sidebarTitleStyle(isOpen, theme)}>
            <Logo />

            {isOpen ? <LogoText /> : null}
          </Container>
        </NavLink>
      </DrawerHeader>

      <List
        sx={{ pt: 1 }}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        {mockSidebarData.map(
          ({ label, href, icon, isPermission }) => {
            if (!isPermission) return null;
            return (
              <ListItem
                sx={{ padding: theme.spacing(0, 2) }}
                key={label}
                title={label}
                disablePadding
              >
                <ListItemButton
                  component={NavLink}
                  to={href}
                  sx={navLinkStyle(theme)}
                  data-qa={label === NAVBAR_LABELS.agriculturalWork? "buttonMap" : null}
                  onClick={handleCloseInstantly}
               >
                  <ListItemIcon>{icon}</ListItemIcon>
                  
                  <ListItemText
                    primary={label}
                    sx={{ opacity: isOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            );
          },
        )}
      </List>

      <IconButton
        sx={hideSidebarStyle(isOpen, theme)}
        onClick={toggleSidebar}
      >
        {isOpen ? (
          <KeyboardDoubleArrowLeft />
        ) : (
          <KeyboardDoubleArrowRight />
        )}
      </IconButton>
    </Drawer>
  );
};

export default Navbar;
