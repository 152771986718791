import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import {
  METH_SIGN_FIELD_NAME,
  validationSchema,
} from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import MethodologySignForm from "./components/MethodologySignForm";
import { IProps } from "./types";
import { mapFormToValuesSerializer } from "./serializers";

/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - callback после успешного запроса
 * */

const AddMethodologySign: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const { methodologyId, initialValues } = modalProps;

  const baseInitialValues = {
    sign: [
      {
        [METH_SIGN_FIELD_NAME.IS_MANDATORY]: false,
        [METH_SIGN_FIELD_NAME.IS_GROUPED]: false,
        [METH_SIGN_FIELD_NAME.METHODOLOGY]:
          modalProps.methodologyId,
        [METH_SIGN_FIELD_NAME.INDICATOR]: null,
        [METH_SIGN_FIELD_NAME.NON_FIELD_ERRORS]: undefined,
      },
    ],
  };

  const { api, toastStore, swrStore } = useStores();

  const mutateDetail = methodologyId
    ? swrStore.mutators[
        SWR_KEYS.getMethodologyItem(methodologyId)
      ]
    : null;

  const mutateList =
    swrStore.mutators[SWR_KEYS.getMethodologyList()];

  const handleSubmit = (
    values: any,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const promise = modalProps.isEdit
      ? api.regulatoryInfo.patchIndicatorMethodologyItem(
          modalProps.indicatorId,
          {
            is_grouped: Boolean(values.sign[0].is_grouped),
            is_mandatory: Boolean(
              values.sign[0].is_mandatory,
            ),
            indicator: Number(values.sign[0].indicator?.id),
            methodology: Number(values.sign[0].methodology),
          },
        )
      : api.regulatoryInfo.postIndicatorMethodologyItem(
          mapFormToValuesSerializer(
            values,
            modalProps.trialType,
          ),
        );

    promise
      .then(() => {
        mutateDetail && mutateDetail();
        mutateList && mutateList();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
               
        if (errors.non_field_errors) {
          const errorMessage = errorToString(error);
          helpers.setFieldError('non_field_errors', errorMessage)
        }
        if (Array.isArray(errors)) {
          const errorMessages = errors.flatMap(obj => obj.non_field_errors);
          const errorMessageString = errorMessages.join(', ');
          helpers.setFieldError('non_field_errors', errorMessageString)
        }
        else {
          handleClose();
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail();
        mutateList && mutateList();
      });
  };

  const values = initialValues
    ? {
        ...baseInitialValues,
        ...initialValues,
      }
    : baseInitialValues;

  console.log(values);

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <MethodologySignForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      )}
    />
  );
};

export default AddMethodologySign;
