import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { PAGE_SIZE } from "@/const";
import { resInterface } from "@/api/interfaces";
import { IPlanning, IVariety } from "@/api/interfaces/responses";
import useToastContent, { TOAST_CONTENT_TYPE } from "@/hooks/useToastContent";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";
import { VARIETY_TYPE } from "./const";


export interface IProps {
  handleClose: () => void;
  modalProps: {
    currentCultivar: { id: number; name: string };
    plan: IPlanning;
    isStandard: boolean,
    isIndicator: boolean,
    key: string;
  };
}

/** Модалка добавления сорта для заявки */

const AddVarieties: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const [searchParams] = useSearchParams();
  const planPlotID = searchParams.get("GSUDetail_id");
  const {
    queryStringSidebarCollector,
    modalStore,
    api,
    toastStore,
    userStore
  } = useStores();
  const toastContent = useToastContent(TOAST_CONTENT_TYPE.SERVER_ERROR);
  const [isLoading, setIsLoading] = useState(false);
  const { plan, isIndicator, isStandard } = modalProps;
  const currentPlanId =
    queryStringSidebarCollector.getCurrentModuleId();

  const handleAccept = (values: resInterface.IVariety[]) => {
    setIsLoading(true);
    const payload = values.map((el) => ({
      plan: currentPlanId,
      variety: el.id,
      plan_plot: Number(planPlotID),
      type: isStandard ? VARIETY_TYPE.STANDART : VARIETY_TYPE.INDICATOR,
    }));
    setIsLoading(true);
    api.planning
      .postVarietyPlanPlot(payload)
      .then(() => {
        handleClose();
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getVarietyList({
      page,
      page_size: PAGE_SIZE,
      search,
      cultivar: plan.cultivar.id,
      is_standard: isStandard,
      is_indicator: isIndicator,
    });
  };

  const handleSubmit = (payload: IVariety[]) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      ...modalProps,
      initialValue: payload
    });
  };

  const handleAdd = () => {
    modalStore.open(VARIANT_MODAL.CREATE_VARIETY, {
      onSubmit: handleSubmit,
      initialValue: { cultivar: modalProps.currentCultivar },
      isStatement: true,
      cultivarEditable: false
    });
  };
  const getLabel = (value: IVariety): string => value.name;
  const getMeta = (value: IVariety) => String(value.code);

  const isSort = userStore.isPermission(
    PERMISSIONS.regulatory_infoVariety,
    PERMISSION_CRUD.add
  );

  const props = {
    key: uuidv4(),
    addButtonLabel: "Добавить сорт",
    isDisabledBtnAdd: isSort,
    tooltipText: "У вас недостаточно прав для добавления сорта",
    title: "Сорт",
    handleAccept,
    handleAdd,
    disabled: isLoading,
    getLabel: getLabel,
    getMeta: getMeta,
    fetcher
  };

  return (
    <AddEntities<resInterface.IVariety>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default AddVarieties;
