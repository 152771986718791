import React from "react";
import { Avatar, useTheme } from "@mui/material";

import { ISizeAvatar } from "./interfaces";

import { SIZES_AVATAR } from "./const";

import { initialsAvatar, Img } from "./style";

interface IProps {
  initials: string;
  imageUrl?: string | null;
  size?: ISizeAvatar;
}

/**
 * компонент аватара пользователя (картинка или инициалы)
 * @param initials инициалы пользователя
 * @param imageUrl ссылка на картинку профиля пользователя
 * @param size размер аватара
 * @returns
 */
const AvatarUser = ({
  initials,
  imageUrl,
  size = "medium"
}: IProps): React.ReactElement => {
  const theme = useTheme();
  return (
    <Avatar
      alt="Avatar"
      variant="rounded"
      src={imageUrl ?? undefined}
      imgProps={{
        width: SIZES_AVATAR[size].img,
        height: SIZES_AVATAR[size].img
      }}
      sx={initialsAvatar(theme, size)}
    >
      {imageUrl ? <Img src={imageUrl} /> : initials}
    </Avatar>
  );
};

export default AvatarUser;
