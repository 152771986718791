import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { SIGN_FIELD_NAME } from "../const";
import {
  IVariety,
  TForm,
  TModalProps,
  TSign,
} from "../types";
import {
  Field,
  FieldArray,
  useFormikContext,
} from "formik";
import AddChip from "@/components/Chips/AddChip";
import { StyledChip } from "./styles";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";
import theme from "@/theme";
import Autocomplete from "@/components/Autocomplete";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS, TRIAL_TYPE } from "@/const";
import { VARIANT_MODAL } from "@/apps/Modals/const";

type TPush<T> = (obj: T) => void;

interface IProps {
  modalProps: TModalProps;
}

export const AddSignFieldArray: FC<IProps> = ({
  modalProps,
}) => {
  const form = useFormikContext<TForm>();
  const { api, swrStore, modalStore } = useStores();

  const [searchSign, setSearchSign] = useState("");
  const swrKey = SWR_KEYS.getIndicatorMethodology();

  const getParameters = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    id: modalProps.indicator_methodology, // id признака
    search: searchSign,
  });

  const fetcher = (args) => {
    const { page, search, id } = args;
    return api.regulatoryInfo.getIndicatorsVariantById({
      page,
      id,
      search,
    });
  };

  const { data, handleScroll, mutate } = useInfiniteScroll(
    getParameters,
    fetcher,
    {
      revalidateFirstPage: false,
    },
  );

  const modifiedData = data?.map((el) => ({
    ...el,
    name: el.severity,
  }));

  useEffect(() => {
    swrStore.addMutator(swrKey, mutate);
  }, []);

  const handleAddSign = (push: TPush<TSign>) => {
    push({
      [SIGN_FIELD_NAME.INDICATOR_METHODOLOGY]:
        modalProps.indicator_methodology,
      [SIGN_FIELD_NAME.VARIANT]: null,
      [SIGN_FIELD_NAME.VARIETY]: [],
    });
  };

  const handleChangeVariant = (value, index) => {
    form.setFieldValue(
      `sign.${index}.${SIGN_FIELD_NAME.VARIANT}`,
      value,
    );
  };

  const handleAddReferencies = (index: number) => {
    modalStore.open(VARIANT_MODAL.SELECT_REFERENCIES, {
      onSubmit: (payload) =>
        handleSelectReferencies(payload, index),
      currentCultivar: modalProps.cultivar,
      onClose: handleCloseReferencies,
    });
  };

  const handleCloseReferencies = () => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...form.values,
      },
    });
  };

  const handleSelectReferencies = (
    payload: IVariety[],
    index: number,
  ) => {
    const updatedSign = form.values.sign.map((item, i) =>
      i === index
        ? {
            ...item,
            [SIGN_FIELD_NAME.VARIETY]: payload,
          }
        : item,
    );

    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...form.values,
        sign: updatedSign,
      },
    });
  };

  const handleDeleteChip = (id: number, index: number) => {
    const updatedVariety = form.values?.sign[index][
      SIGN_FIELD_NAME.VARIETY
    ]?.filter((item) => item.id !== id);
    form.setFieldValue(
      `sign.${index}.${SIGN_FIELD_NAME.VARIETY}`,
      updatedVariety,
    );
  };

  const isOOS = modalProps.trialType === TRIAL_TYPE.OOS

  return (
    <Box
      sx={{
        m: "30px",
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          lineHeight: "16px",
          mb: "5px",
        }}
      >
        Признак - {modalProps.indicatorName ?? modalProps.indicatorName}
      </Typography>
      <FieldArray name="sign">
        {({ remove, push }) => (
          <Box>
            <Stack direction={"column"} gap={2} pt={1}>
              {form.values.sign?.map((_, index) => (
                <Box
                  sx={{
                    border: modalProps.isEdit
                      ? "none"
                      : `1px solid ${theme.palette.blackAndWhite.stroke}`,
                    borderRadius: "4px",
                    p: modalProps.isEdit ? 0 : 1,
                  }}
                  key={index}
                >
                  <Stack
                    p={modalProps.isEdit ? 0 : 2}
                    direction="column"
                    spacing={3}
                  >
                    <Field
                      name={`sign.${index}.${SIGN_FIELD_NAME.VARIANT}`}
                    >
                      {({ field, meta }) => (
                        <FormControl required>
                          <Autocomplete
                            {...field}
                            onChange={(_, value) =>
                              handleChangeVariant(
                                value,
                                index,
                              )
                            }
                            label="Вариант признака методики"
                            data={modifiedData}
                            required
                            handleScroll={handleScroll}
                            error={
                              meta.touched && !!meta.error
                            }
                            search={setSearchSign}
                          />

                          <FormHelperText
                            error={
                              meta.touched && !!meta.error
                            }
                          >
                            {meta.touched && meta.error}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  </Stack>

                  {modalProps?.isEdit &&
                  modalProps?.isHP ? null : (
                    <Stack
                      p={modalProps?.isEdit ? 0 : 2}
                      pt={modalProps?.isEdit ? 1 : 1}
                      direction="column"
                      alignItems={"flex-start"}
                      spacing={3}
                    >
                      {
                        isOOS && (
                          <Field
                            name={`sign.${index}.${SIGN_FIELD_NAME.VARIETY}`}
                          >
                            {({ field, meta }) => {
                              return (
                                <FormControl>
                                  <FormLabel>
                                    Сорт-Эталон
                                  </FormLabel>
                                  <Stack
                                    direction={"row"}
                                    flexWrap={"wrap"}
                                    alignItems={"center"}
                                    gap={1}
                                  >
                                    {field.value
                                      ? field?.value?.map(
                                        (item) => (
                                          <StyledChip
                                            key={item.id}
                                            size="small"
                                            variant="outlined"
                                            label={item.name}
                                            onDelete={() =>
                                              handleDeleteChip(
                                                item.id,
                                                index,
                                              )
                                            }
                                          />
                                        ),
                                      )
                                      : null}

                                    <AddChip
                                      onClick={() =>
                                        handleAddReferencies(
                                          index,
                                        )
                                      }
                                    />
                                  </Stack>
                                  <FormHelperText
                                    error={!!meta.error}
                                  >
                                    {meta.error}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                        )
                      }

                      {index > 0 && (
                        <Button
                          onClick={() => remove(index)}
                          color="red"
                        >
                          Удалить
                        </Button>
                      )}
                    </Stack>
                  )}
                </Box>
              ))}
            </Stack>
            {modalProps?.isEdit ? null : (
              <Button
                sx={{
                  width: "100%",
                  margin: "5px 1px 10px 1px",
                  marginTop: "5px",
                }}
                color={"blue"}
                size={"large"}
                startIcon={<AddOutlinedIcon />}
                variant={"outlined"}
                onClick={() => handleAddSign(push)}
              >
                Добавить вариант признака методики
              </Button>
            )}
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};
