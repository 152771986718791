import React from "react";
import { Formik } from "formik";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import SelectIndicatorForm
  from "./components/SelectIndicatorForm";
import {
  IFormik,
  initialValues,
  validationSchema,
  serializeValuesToForm
} from "./const";
import {
  IIndicatorMethodologyCreateParams
} from "@/api/interfaces/requests";

/**
 * Компонент модалки выбора признака наблюдения
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    handleSubmit: (payload: IFormik) => void
    initialValues?: IFormik;
    q?: IIndicatorMethodologyCreateParams
  };
}

const SelectObservationIndicator: React.FC<IProps> = ({ modalProps, handleClose }) => {
  const handleSubmit = (
    values: IFormik,
  ) => {
    modalProps.handleSubmit && modalProps.handleSubmit(values)
    handleClose()
  };

  const title = modalProps.initialValues ? "Редактировать признак" : "Добавить признак"

  const values = modalProps.initialValues ? serializeValuesToForm(modalProps.initialValues): initialValues

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {title}
        </Typography>
      </Box>
      <Divider />
      <Formik<IFormik>
        initialValues={values}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => <SelectIndicatorForm q={modalProps.q} handleClose={handleClose} />}
      />
    </Box>
  );
};

export default SelectObservationIndicator;
