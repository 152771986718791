import { _autoAction } from 'mobx'
/**
 * ? - если наследуемся в класс с mobX
 */
export const PubSubProps = {
  subscribe: _autoAction,
  unsubscribe: _autoAction,
  broadcast: _autoAction
}
/**
 * @class
 * * Класс реализующий возможность подписки на что либо
 * ! TODO: поправить типизацию
 */
export class PubSub {
  private _observers: any[]

  constructor () {
    this._observers = []
  }

  public subscribe = (fn: (arg?: any) => void): void => {
    this._observers.push(fn)
  }

  public unsubscribe = (fn: (arg?: any) => void): void => {
    this._observers = this._observers.filter(
      (subscriber) => subscriber !== fn
    )
  }

  public broadcast = (data: any): void => {
    this._observers.forEach((subscriber) => {
      subscriber(data)
    })
  }
}
