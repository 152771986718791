import {
  ITrial,
  TOosContextRes,
} from "@/api/interfaces/responses";
import { OOS_FIELD_NAME } from "./const";
import { ISurveyReq } from "@/api/interfaces/requests";

/**
 * Метод для удобства работы с контекстом
 * @param context
 */
export const serializeContext = (context: TOosContextRes) => {
  return {
    [OOS_FIELD_NAME.REGIONS]: context.regions.sort((a, b) => a.number - b.number) || [],
  };
};

export type TContextType = ReturnType<typeof serializeContext>

/**
 * Методика из формы на бекенд
 * @param values
 */
export const surveySerializer = (values): ISurveyReq[] => {
  return values.map(el => ({
    survey_id: el.survey_id,
    indicator_methodology: el.indicator_methodology,
    index: el.variant.index,
    severity: el.variant.severity,
    varieties: el.variety.map(v => v.id)
  }));
};

/**
 * Из бекенда в форму
 * @param values
 */
export const valueToFormSerializer = (values: ITrial | undefined | null) => {
  return {
    [OOS_FIELD_NAME.YEAR_OF_TESTING]: values?.year_of_testing ? values?.year_of_testing : null,
    [OOS_FIELD_NAME.TYPE_OOS]: values?.type_oos || "",
    [OOS_FIELD_NAME.REGIONS]: values?.regions?.map(el => el.id),
    [OOS_FIELD_NAME.PAYMENT_TYPE]: values?.payment_type || '',
  };
};

/**
 * Значения из формы на бекенд
 * @param values
 */
export const formToValueSerializer = (values) => {
  return {
    type_oos: values.type_oos,
    year_of_testing: values.year_of_testing,
    regions: values.regions,
    payment_type: values.payment_type,
  };
};