import React from "react";
import { Box } from "@mui/material";
import Logo from "../Logo";
import styles from "./AuthLayout.module.scss";
import { bgjpg } from "@/assets/auth";
import { NoSSR } from "@/components/NoSSR";
import Seo from "@/components/Seo";

type AuthLayoutProps = {
  children: React.ReactNode;
  title?: string;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, title }) => {
  return (
    <NoSSR>
      <Seo title={title}/>
      <div className={styles.wrapper}>
        <img className={styles.wrapper__bg} src={bgjpg} alt="Background" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start"
          }}
        >
          <div className={styles.content}>
            <Logo />
            {children}
          </div>
        </Box>
      </div>
    </NoSSR>
  );
};

export default AuthLayout;
