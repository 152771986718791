import React from "react";
import { Formik } from "formik";
import { useStores } from "@/hooks";
import { AxiosError } from "axios";
import { TOAST_TYPES } from "@/apps/Toast";
import {
  errorToString,
  isUndefined,
} from "@/utils/helpers";
import { SWR_KEYS } from "@/const";
import { observer } from "mobx-react";
import { IAddCostProps } from "./types";
import { FIELDS_NAME } from "./const";
import { validationSchema } from "./scheme";
import AddCostForm from "./AddCostForm";


const AddCost: React.FC<IAddCostProps> = observer(
  ({ handleClose, modalProps }) => {

    const { api, toastStore, modalStore, swrStore } =
      useStores();

    const mutate =
      swrStore.mutators[SWR_KEYS.getExpenseList()];

    const convertInitialValues = (item) => {
      if (!isUndefined(item))
        return {
          ...item,
          [FIELDS_NAME.EXPENSE]: {
            ...item?.expense,
            label: item?.expense?.name,
          },
          [FIELDS_NAME.FUNDING_SOURCE]: item.funding_source,

        };
    };

    const initialValues = Object.assign(
      {
        com_cultivar: Number(modalProps.id),
        years: [
          {
            [FIELDS_NAME.YEAR]: null,
            [FIELDS_NAME.SUM]: null,
          },
        ],
        sum: "",
        expense: null,
        funding_source: undefined,
      },
      convertInitialValues(modalProps.initialValue),
    );

    const onSubmit = (values) => {
      const formattedData = [{
        [FIELDS_NAME.COM_CULTIVAR]: values.com_cultivar,
        [FIELDS_NAME.YEAR]: Number(values.year),
        [FIELDS_NAME.EXPENSE]: Number(values.expense.id),
        [FIELDS_NAME.SUM]: values.sum === ""
          ? "0"
          : String(values.sum)?.replaceAll(" ", "",) as string,
        [FIELDS_NAME.TYPE]: modalProps.type,
        [FIELDS_NAME.FUNDING_SOURCE]: values.funding_source,
      }];
      const isEdit = modalProps?.isEdit;

      if (isEdit) {
        api.offBudget
          .editExpenseItem(modalProps?.costId as number, {
            [FIELDS_NAME.YEAR]: values.year as number,
            [FIELDS_NAME.EXPENSE]: Number(
              values.expense.id,
            ),
            [FIELDS_NAME.SUM]: values.sum === ""
              ? "0"
              : String(values.sum)?.replaceAll(" ", "",) as string,
            [FIELDS_NAME.FUNDING_SOURCE]: values.funding_source,
          })
          .then(() => {
            mutate();
            modalStore.pop();
          })
          .catch((error: AxiosError) => {
            toastStore.createToast({
              type: TOAST_TYPES.ALERT,
              toastProps: {
                message: errorToString(error),
                severity: "error",
              },
            });
          });
      } else {
        api.offBudget
          .createExpense(formattedData)
          .then(() => {
            mutate();
            modalStore.pop();
          })
          .catch((error: AxiosError) => {
            toastStore.createToast({
              type: TOAST_TYPES.ALERT,
              toastProps: {
                message: errorToString(error),
                severity: "error",
              },
            });
          });
      }
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <AddCostForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      </Formik>
    );
  },
);

export default AddCost;
