import React from "react";
import { Formik } from "formik";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import SelectWeatherResistanceForm
  from "./components/SelectWeatherResistanceForm";
import {
  IFormik,
  initialValues,
  validationSchema,
  serializeValueToForm,
} from "./const";
import {
  IObservationWeatherResistance
} from "@/api/interfaces/responses";

/**
 * Компонент модалки выбора метеоусточивости наблюдения
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    handleSubmit: (payload: IFormik) => void,
    id?: number,
    initialValues?: IObservationWeatherResistance
  };
}

const SelectObservationDisease: React.FC<IProps> = ({
  modalProps,
  handleClose
}) => {
  const handleSubmit = (
    values: IFormik,
  ) => {
    modalProps.handleSubmit && modalProps.handleSubmit(values);
    handleClose();
  };

  const title = modalProps.id ? "Редактировать неблагоприятное условие" : "Добавить неблагоприятное условие";

  const values = modalProps.initialValues ? serializeValueToForm(modalProps.initialValues) : initialValues;

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {title}
        </Typography>
      </Box>
      <Divider/>
      <Formik<IFormik>
        initialValues={values}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => <SelectWeatherResistanceForm
          handleClose={handleClose}/>}
      />
    </Box>
  );
};

export default SelectObservationDisease;
