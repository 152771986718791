import React, { useState } from "react";

import HBC from "@/pages/Handbook/components";

import { Stack } from "@mui/material";

const Navigations = () => {
  const [searchValue, setSearchValue] = useState("");
  const handleSetSearch = (v: string) => {
    setSearchValue(v);
  };

  return (
    <Stack direction={"column"} width={"200px"} height={"100%"}>
      <HBC.Search onSearch={handleSetSearch} value={searchValue} />
      <HBC.ThreeModules searchValue={searchValue} />
    </Stack>
  );
};

export default Navigations;
