import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { AxiosError } from "axios";
import { useStores } from "@/hooks";
import {
  validationSchema,
  defaultValues,
  serializedValues
} from "./const";
import { SWR_KEYS } from "@/const";
import DutyForm from "./components/DutyForm";
import { IProps, IForm } from "./types";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";

/**
 * Модалка с формой создания/редактирования справочника "Пошлины"
 * @param initialValues - начальные значения формы
 * @param onSubmit - коллбэк после успешного запроса
 * */

const AddDuty: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const { api, toastStore, swrStore } = useStores();

  const mutateDetail = modalProps.id
    ? swrStore.mutators[
        SWR_KEYS.getDutyById(modalProps.id)
      ]
    : null;

  const mutateList =
    swrStore.mutators[
      SWR_KEYS.getDutyList()
    ];

  const handleSubmit = (
    values: IForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    const payload = {
      name: values.name.trim(),
      number: values.number.trim(),
      cultivars: values.cultivar.map(el => el.id),
      resident: values.resident,
      non_resident: values.non_resident,
    };

    helpers.setSubmitting(true);
    const promise = modalProps.id
      ? api.statement.patchDuty(modalProps.id as number, payload)
      : api.statement.postDuty(payload)

    promise
      .then(() => {
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errorMessage = errorToString(error);
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorMessage,
            severity: "error",
          },
        });
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail();
        mutateList && mutateList();
      });
  };


  const values  = modalProps.initialValues ? serializedValues(modalProps.initialValues) : defaultValues

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <DutyForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      )}
    />
  );
};

export default AddDuty;
