import {
  PHYTO_FIELD_NAME
} from "@/apps/EditTrial/components/PhytoTrial/const";
import { ITrial } from "@/api/interfaces/responses";

/**
 * Из бекенда в форму
 * @param values
 */
export const valueToFormSerializer = (values: ITrial | undefined | null) => {
  return {
    [PHYTO_FIELD_NAME.YEAR_OF_TESTING]: values?.year_of_testing ? values?.year_of_testing : null,
    [PHYTO_FIELD_NAME.DISEASES]: values?.diseases ? values.diseases.map(disease => ({
      id: disease.id,
      name: disease.name
    })) : [],
    [PHYTO_FIELD_NAME.REGIONS]: values?.regions ? values.regions?.map(el => el.id) : [],
    [PHYTO_FIELD_NAME.PAYMENT_TYPE]: values?.payment_type || '',
  };
};

export const serializeContext = (context) => {
  return {
    [PHYTO_FIELD_NAME.REGIONS]: context.regions.sort((a, b) => a.number - b.number) || [],
  };
};

export type TContextType = ReturnType<typeof serializeContext>