import React, { useState } from "react";

import { IconButton, InputAdornment } from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff
  from "@mui/icons-material/VisibilityOff";
import TextField, {
  TextFieldProps
} from "@mui/material/TextField";

export const PasswordField: React.FC<TextFieldProps> = ({
  ...props
}) => {
  /**
   * флаг для правильного отображения автокомплита
   * */
  const [isAutoFill, setIsAutoFill] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      {...props}
      variant="outlined"
      type={showPassword ? "text" : "password"}
      InputLabelProps={{ shrink: isAutoFill || undefined }}
      InputProps={{
        onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
          e.animationName === "mui-auto-fill" && setIsAutoFill(true);
        },
        onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) =>
          e.animationName === "mui-auto-fill-cancel" && setIsAutoFill(false),
        onFocus: () => setIsAutoFill(false),
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <Visibility/> :
                <VisibilityOff/>}
            </IconButton>
          </InputAdornment>
      }}
    />
  );
};
