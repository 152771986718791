import React, { useMemo, useCallback } from "react";
import useSWR from "swr";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import {
  FieldArray,
  Field,
  useFormikContext
} from "formik";
import { Box } from "@mui/system";
import { IOosVariant } from "@/api/interfaces/responses";
import { StyledBox } from "../../styles";
import { serializeSurvey } from "../../utils";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import Empty from "../Empty";
import { useStores, useInfiniteScroll } from "@/hooks";
import {
  isUndefined,
  errorToString,
  isNull
} from "@/utils/helpers";
import { TRIAL_TYPE, SWR_KEYS } from "@/const";
import { TOAST_TYPES } from "@/apps/Toast";
import { FIELDS_NAME } from "@/pages/AddStatement/const";
import Autocomplete from "@/components/Autocomplete";

const Survey = () => {
  const { id: statementId } = useParams();
  const { api, toastStore } = useStores();

  const formik = useFormikContext<any>();

  const contextFetcher = ([, id]: [
    string,
      string | undefined,
  ]) => {
    if (isUndefined(id)) return null;
    return api.statement.getTrialContext(id, TRIAL_TYPE.OOS);
  };

  const showError = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error",
      },
    });
  };

  const getKey = useCallback((index: number) => ({
    _key: SWR_KEYS.getSurvey(),
    page: index + 1,
    page_size: 100,
    type_trial: TRIAL_TYPE.OOS,
    statement: statementId
  }), [statementId]);

  const {
    isLoading,
    mutate
  } = useInfiniteScroll(getKey, api.regulatoryInfo.getSurveyList, {
    revalidateOnMount: true, onSuccess: (data) => {
      if (data.length && data[0].results.length) {
        const values = serializeSurvey(data[0].results);
        void formik.setFieldValue(FIELDS_NAME.SURVEYS_OOS, values);
      }
    }
  });

  const { data: contextData, isLoading: isContextLoading } =
    useSWR(
      ["oosTrialContext", statementId],
      contextFetcher,
      {
        onSuccess: (data) => {
          data && data.surveys.length && mutate();
        },
        onError: (error: AxiosError) => {
          showError(error);
        },
        revalidateOnMount: true,
      },
    );

  const indicators: Record<string, IOosVariant["variant"]> = useMemo(() => {
    if (!contextData?.surveys) {
      return {};
    }
    return contextData.surveys.reduce((acc, el) => {
      acc[el.id] = el.variant.map(variant => ({
        ...variant,
        survey_id: el.id
      }));
      return acc;
    }, {});
  }, [contextData]);

  const handleChangeVariant = (name: string) => (_, value, reason) => {
    if (reason === "selectOption") {
      formik.setSubmitting(true);
      const payload = {
        statement: Number(statementId),
        indicator_methodology: value.indicator_methodology,
        severity: value.variant.severity,
        index: value.variant.index,
        varieties: value.variety.map(variety => variety.id),
        type_trial: TRIAL_TYPE.OOS
      };

      api.regulatoryInfo.patchSurveyById(value.survey_id, payload).catch((error) => {
        showError(error);
      }).finally(() => {
        mutate();
        formik.setSubmitting(false);
        void formik.setFieldValue(name, value);

      });
    } else {
      void formik.setFieldValue(name, value);
    }
  };

  if (isContextLoading || isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80}/>
      </Box>
    );
  }

  if (
    !statementId ||
    isUndefined(contextData) ||
    isNull(contextData)
  ) {
    return null;
  }

  return (
    <Box>
      {contextData.surveys.length ? (
        <FieldArray name={FIELDS_NAME.SURVEYS_OOS}>
          {({ name }) => {
            return (
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
              >
                {(
                  formik.values[FIELDS_NAME.SURVEYS_OOS]
                ).map((el, index) => (
                  <StyledBox key={el.id}>
                    <Typography variant="p14Medium">
                      {el.indicator_name}
                    </Typography>
                    <Field
                      name={`${name}.${index}.${FIELDS_NAME.INDICATOR}`}
                    >
                      {({ field, meta }) => (
                        <Autocomplete
                          label="Степень выраженности"
                          {...field}
                          onChange={(...args) => handleChangeVariant(field.name)(...args)}
                          data={Array.isArray(indicators[el.id]) ? indicators[el.id] : []}
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                          getLabel={(indicator) => `${indicator.variant.index} - ${indicator.variant.severity}`}
                          filter={true}
                          disabled={formik.isSubmitting}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </StyledBox>
                ))}
              </Box>
            );
          }}
        </FieldArray>
      ) : (
        <Empty/>
      )}
    </Box>
  );
};

export default Survey;