import React from "react";
import { useMatchMedia } from "@/hooks/useMatchMedia";
import { Grid, Typography } from "@mui/material";
import theme from "@/theme";
import { IEntity } from "@/api/interfaces/responses";
import { SxProps } from "@mui/material/styles";
import { Box } from "@mui/system";

export interface IInfoField {
  label: string;
  content: React.JSX.Element | null | string | IEntity | number;
  canWrap?: boolean;
  labelSx?: SxProps;
  valueSx?: SxProps;
  isHasDivider?: boolean;
}
/**
 * Компонент с информацией о пользователе
 * @param label - заголовок
 * @param content - информация | аватарка
 */
export const InfoField = ({
  label = "",
  content = "",
  canWrap = true,
  labelSx = {},
  valueSx = {}
}: IInfoField) => {
  const { isMobile } = useMatchMedia()
  const width = isMobile ? "125px": "200px"
  return (
    <Grid container sx={{ 
      mt: 2, 
      flexWrap: (isMobile || canWrap) ? "wrap" : "nowrap",
    }}>
      <Box sx={{flex: `0 0 ${width}`}}>
        <Typography
          sx={{
            gap: 3,
            ...labelSx
          }}
          color={theme.palette.blackAndWhite.gray}
          variant="body2"
        >
          {label}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{ 
          fontWeight: "500", 
          maxWidth: !canWrap ? "475px" : "none",
          ...valueSx
        }}
        component='div'
      >
        <>{content}</>
      </Typography>
    </Grid>
  )
};