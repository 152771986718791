import React from "react";
import { observer } from "mobx-react";
import qs from "qs";
import { useSearchParams } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMatches } from "@/apps/Respons";

import { TRSidebarShowBlock } from "@/services/store/RightSidebarStore";
import useRightSidebar from "@/hooks/useRightSidebar";
import ExpandContentIcon from "@/components/icons/ExpandContentIcon";
import CollapseContentIcon from "@/components/icons/CollapseContentIcon";

import { useQuery } from "@/hooks";

import styles from "./styles/index.module.scss";
import { IconButton } from "./styles/index";
import theme from "@/theme";

interface IProps {
  children: React.ReactNode;
  type?: TRSidebarShowBlock;
}

const Container = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
  backgroundColor: theme.palette.blackAndWhite.white,
  display: "flex",
  flexDirection: "column",
}));
const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.blackAndWhite.white
}));

/**
 * @view
 * * вью контейнера для правого сайдбара реализующий анимацию появления
 */

const RightSidebarWrap: React.FC<IProps> = ({ children, type = "sidebar" }) => {
  const [, setSearchParams] = useSearchParams();
  const query = useQuery<any>();
  const {
    isStartClosingAnimation,
    isOpen,
    isCollapse,
    handleClose,
    handleExpand,
    handleCollapse
  } = useRightSidebar();

  // закрытие правого сайдбара с очисткой параметров
  const handleCloseWithCleanQuery = () => {
    handleClose();
    if (query.keySidebar) {
      const queryParams = qs.stringify(
        {
          ...query,
          [`${query.keySidebar}_id`]: undefined,
          keySidebar: undefined
        },
        { arrayFormat: "comma" }
      );
      setSearchParams(queryParams);
    }
  };
  const { matches } = useMatches();

  const Controls = (
    <Box
      className={styles.controls}
      sx={{
        top: matches?.small || matches?.medium ? "24px" : "12px"
      }}
    >
      {matches?.small ? null : (
        <IconButton
          arial-label="expand"
          onClick={handleExpand}
          sx={{
            display: isCollapse ? "flex" : "none",
            color: theme.palette.blackAndWhite.gray
          }}
        >
          <ExpandContentIcon />
        </IconButton>
      )}
      <IconButton
        key="collapse"
        aria-label="collapse"
        onClick={handleCollapse}
        sx={{
          display: isCollapse ? "none" : "flex",
          color: theme.palette.blackAndWhite.gray
        }}
      >
        <CollapseContentIcon />
      </IconButton>
      <IconButton
        aria-label="close"
        color="primary"
        onClick={handleCloseWithCleanQuery}
      >
        <CloseIcon sx={{ color: theme.palette.blackAndWhite.gray }} />
      </IconButton>
    </Box>
  );
  return (
    <Wrapper
      data-open={isOpen}
      data-collapse={isCollapse}
      data-animate={isStartClosingAnimation}
      className={styles.container__wrap}
    >
      <Container
        data-collapse={isCollapse}
        className={styles[`container__${type}`]}
      >
        {Controls}
        {children}
      </Container>
    </Wrapper>
  );
};

export default observer(RightSidebarWrap);
