type TEmptyToUndefined<T> = {
    [K in keyof T]: T[K] extends '' ? undefined : T[K];
  };

export const transformEmptyToUndefined = <T extends object>(
    obj: T,
  ): TEmptyToUndefined<T> => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === '') {
        obj[key] = undefined;
      }
    });
    return obj as TEmptyToUndefined<T>;
  };