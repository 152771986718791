import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtonsMaterialType, cellPointText, headerMaterialTypeCellStyle } from "../style";

import { MATERIAL_TYPE_KEYS, MATERIAL_TYPE_LABELS } from "../const";

import { TMaterialTypeKeys } from "../interfaces";
import { EditingCellsMaterialTypes } from "../templates/MaterialType/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const MaterialTypesColumns: IColumn<TMaterialTypeKeys>[] = [
  {
    label: MATERIAL_TYPE_LABELS.name,
    key: MATERIAL_TYPE_KEYS.name,
    contentHeader: MATERIAL_TYPE_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerMaterialTypeCellStyle,
    },
  },
  {
    key: "editing",
    label: MATERIAL_TYPE_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsMaterialTypes,
      styles: cellButtonsMaterialType,
    },
  },
];
