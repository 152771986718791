import React, { useRef } from "react";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { PAGE_SIZE } from "@/const";
import {
  ICultivar,
  ICultivarHandbook
} from "@/api/interfaces/responses";
import { VARIANT_MODAL } from "@/apps/Modals/const";

export interface IProps {
  handlePop: () => void;
  handleClose: () => void;
  modalProps: {
    key: string
    initialValue?: ICultivar[],
    onSubmit?: (value: ICultivar[]) => void
  };
}

/** Модалка с чекбоксами списка культур */

const SelectCultivars: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps
}) => {
  const { api, modalStore } = useStores();
  const refToInitVal = useRef<any>(null)
  
  const handleAccept = (value: ICultivar[]) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handlePop();
  };

  const handleAcceptCultivar = (payload: ICultivarHandbook) => {
    const initVal = refToInitVal.current ?
      [...refToInitVal.current, payload] :
      [payload]
    refToInitVal.current = initVal
  }

  const handleCloseCultivar = () => {
    modalStore.setModalPropsByKey(modalProps.key,
      { ...modalProps, initialValue: refToInitVal.current })
  }

  const handleAdd = (payload: ICultivar[]) => {
    const initVal = refToInitVal.current? [...new Set([...refToInitVal.current, ...payload])] : [...payload];

    refToInitVal.current = initVal
    modalStore.open(
      VARIANT_MODAL.CULTIVAR,
      {
        handleAccept: handleAcceptCultivar,
        onClose: handleCloseCultivar,
        initialValue: initVal
      }
    )
  }

  const fetcher = (args) => {
    const { page, search, first_ids } = args;
    return api.regulatoryInfo.getCultivarList({
      page,
      page_size: PAGE_SIZE,
      search,
      first_ids
    });
  };
  const getLabel = (value: ICultivar): string => value.name;

  const props = {
    isDisabledBtnAdd: true,
    ...modalProps,
    key: "GET_CULTIVAR",
    addButtonLabel: "Добавить культуру",
    title: "Культура",
    handleAccept,
    handleAdd,
    getLabel: getLabel,
    fetcher,
    q: {
      first_ids: modalProps?.initialValue
        ? modalProps.initialValue.map((el) => el.id).toString()
        : undefined
    }
  };

  return (
    <AddEntities<ICultivar>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectCultivars;