import { IColumn } from "@/apps/Table/interfaces";
import { OrderDateTextCell, PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { ORDER_HEADER_LABELS, ORDER_KEYS } from "../const";

import { TOrderKeys } from "../interfaces";
import { EditingCellOrder, OrderTypeCell } from "../templates/Order/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: ""
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить"
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const orderColumns: IColumn<TOrderKeys>[] = [
  {
    label: ORDER_HEADER_LABELS.name,
    key: ORDER_KEYS.name,
    contentHeader: ORDER_HEADER_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: ORDER_HEADER_LABELS.number,
    key: ORDER_KEYS.number,
    contentHeader: ORDER_HEADER_LABELS.number,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: ORDER_HEADER_LABELS.order_date,
    key: ORDER_KEYS.order_date,
    contentHeader: ORDER_HEADER_LABELS.order_date,
    bodyCellProps: {
      render: OrderDateTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: ORDER_HEADER_LABELS.order_type,
    key: ORDER_KEYS.order_type,
    contentHeader: ORDER_HEADER_LABELS.order_type,
    bodyCellProps: {
      render: OrderTypeCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: ORDER_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellOrder,
      styles: cellButtons,
    },
  },
];
