import React, { ReactElement, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Table from "@/apps/Table";
import { observer } from "mobx-react";
import {  useInfiniteScroll, useQuery, useStores } from "@/hooks";
import { IAgreement, IPaginatedResult} from "@/api/interfaces/responses";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { SWR_KEYS } from "@/const";
import { IErrorResponse, IValue } from "@/interfaces";
import useSWR, { SWRResponse } from "swr";
import { isUndefined } from "swr/_internal";
import { IPropsSidebar, TAgreementKey } from "../../interfaces";
import AddIcon from "@mui/icons-material/Add";
import qs from "qs";
import Filters from "@/components/Filters";
import { Box, Button } from "@mui/material";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { AgreementColumns } from "../../models/agreement";
import useDebounce from "@/hooks/useDebounce";
import { getFilters } from "./utils";


/**
 * Компонент, представляющий таблицу сортов.
 * @returns {ReactElement}
 */
const AgreementTable: React.FC<IPropsSidebar> = ({
  handleOpenRightSidebar,
}): ReactElement => {
  const { api, swrStore, modalStore } = useStores(); 
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();
  const [searchType, setSearchType] = useState("");
  const dbouncedSearchType = useDebounce(
    searchType,
    500,
  );

  // переменная которая показывает применены ли фильтры
  const isFilters: boolean = Object.keys(query).length > 0;
  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering) ? query.ordering : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] = useState<string[]>(orgeringArray);

  const {
    data,
    error,
    isLoading,
    mutate,
  }: SWRResponse<IPaginatedResult<IAgreement>, IErrorResponse> = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: query.search,
      agreement_type: query.type?.key ?? undefined,
      agreement_date_after: query?.date_range_after ?? undefined,
      agreement_date_before: query?.date_range_before ?? undefined,
      key: SWR_KEYS.getAgreementList()
    },
    api.statement.getAgreementList,
  );

  const getKey = (index: number) => {
    return {
      key: SWR_KEYS.getListOfAgreement(),
      page: index + 1,
      search: dbouncedSearchType,
    };
  };

  const fetcher = (args) => {
    const { page, search } = args;
    return api.statement.getListOfAgreement(
      {
        page,
        search,
      },
    );
  };

  const {
    data: dataType,
    handleScroll: handleScroll,
  } = useInfiniteScroll(getKey, fetcher, {
    revalidateFirstPage: false,
  });

  const typeList: IValue[] = dataType.map(
    ({ name, id }) => {
      return { label: name, key: id };
    },
  );

 
  const filters = getFilters(
    typeList,
    query.type,
    handleScroll,
    setSearchType,
    [
      query.date_range_after,
      query.date_range_before,
    ],
  );

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleOpenCreateModal = () => {
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.AGREEMENT, { 
      theme: THEME_MODAL.W_555 
    });
  };

  const handleChangeOrderBy = (value) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  swrStore.addMutator(SWR_KEYS.getAgreementList(), mutate);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters filters={filters} defaultSearch={query.search} />

        <Button
          sx={{
            whiteSpace: "nowrap",
            flexShrink: "0",
          }}
          variant='contained'
          startIcon={<AddIcon />}
          onClick={handleOpenCreateModal}
        >
          Добавить запись
        </Button>
      </Box>

      <Table<IAgreement, TAgreementKey>
        columns={AgreementColumns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={"Добавить запись"}
        textEmptyTableWithFilter={"Не найдено ни одной записи"}
        textEmptyTable={"Еще не создано ни одной записи"}
        onRowClick={handleOpenRightSidebar}
        emptyTableAddEntity={handleOpenCreateModal}
      />
    </>
  );
};

export default observer(AgreementTable);
