import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Divider,
  Chip,
} from "@mui/material";
import theme from "@/theme";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import {
  IRegionMainRegion
} from "@/api/interfaces/responses";

const RegionCard: React.FC<IRegionMainRegion> = ({
  name,
  number,
  branches,
  colour,
  staffing = "-",
  total_area = "-",
  machinery = "-"
}) => {
  const navigate = useNavigate();

  const handleClickBranch = (
    branchId: string | number,
  ): void => {
    navigate(
      `${ROUTES_PATHS.branch}/${branchId}/?tab=generalInfo`,
    );
  };

  return (
    <Card sx={{border: `2px solid ${colour}`, height: "100%"}}>
      <CardContent sx={{display:"flex", flexDirection:"column", height: "inherit"}}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography
            sx={{ color: colour }}
            variant="h4"
          >
            {name}
          </Typography>

          <Typography
            variant="h4"
            sx={{ color: colour }}
          >
            {number}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ flex: 1}}>
          <Grid container spacing={1} mt={1} mb={1}>
            {branches.map((branch, index) => (
              <Grid item xs={12} key={index}>
                <Box
                  onClick={() =>
                    handleClickBranch(Number(branch?.id))
                  }
                  sx={{
                    ":hover": {
                      cursor: "pointer",
                    },
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2">
                    {branch.name}
                  </Typography>
                  <Typography variant="body2">
                    <Chip
                      label={<Box>{branch.plot_count} ГСУ</Box>}
                    />
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 1,
          }}
        >
          <Typography
            variant="body2"
            color={theme.palette.blackAndWhite.gray}
          >
            Численность чел.:
            <Typography
              color={theme.palette.blackAndWhite.black}
              fontSize={14}
              fontWeight={400}
            >
              {staffing}
            </Typography>
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={400}
            variant="body2"
            color={theme.palette.blackAndWhite.gray}
          >
            Земельная площадь:
            <Typography
              color={theme.palette.blackAndWhite.black}
              fontSize={14}
              fontWeight={400}
            >
              {`${total_area} га`}
            </Typography>
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.blackAndWhite.gray}
          >
            Сельхоз. техника:
            <Typography
              fontSize={14}
              fontWeight={400}
              color={theme.palette.blackAndWhite.black}
            >
              {machinery}
            </Typography>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RegionCard;
