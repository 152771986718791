import React, {
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikValues,
} from "formik";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import {
  Box,
  Button,
  Divider,
  Step,
  StepIconProps,
  StepLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IFormikStepProps } from "@/pages/AddStatement/components/FormikStep";
import StepControls from "@/pages/AddStatement/components/StepControls/StepControls";
import MobileControls from "@/pages/AddStatement/components/MobileControls/MobileControls";
import {
  ROUTES,
  ROUTES_PATHS,
} from "@/apps/AppRouter/const";
import { IOrderCultivarForm } from "@/pages/AddOrderCultivar/const";
import { useCurrentUser, useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { IOrderCultivar } from "@/api/interfaces/responses";

import {
  ContentWrap,
  Root,
  StepperWrap,
} from "@/pages/AddStatement/components/FormikStepper/styles";
import styles from "@/pages/AddStatement/components/FormikStepper/styles/index.module.scss";
import { Stepper } from "./styles";
import { CircleStepIconRoot } from "@/components/FormikStepper/styles";

export interface IFormikStepper
  extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const CircleStepIcon = (props: StepIconProps) => {
  const { active, completed, className, icon } = props;

  return (
    <CircleStepIconRoot
      ownerState={{ active, completed }}
      className={className}
    >
      <Typography variant="caption">{icon}</Typography>
    </CircleStepIconRoot>
  );
};

/** Компонет с формик контекстом для странцы добавления плана.
 * Рендерит контент в зависимости от шага + валидирует в зависимости от шага */
export const FormikStepper: React.FC<IFormikStepper> = ({
  onSubmit,
  children,
  step,
  setStep,
  ...props
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [completed, setCompleted] = useState(false);
  const { api, toastStore } = useStores();
  const { id: user } = useCurrentUser();

  /** Компоненты шагов*/
  const childrenArray = React.Children.toArray(
    children,
  ) as React.ReactElement<IFormikStepProps>[];
  /** Текущий компонент шага*/
  const currentChild = childrenArray[step];

  const maxSteps = useMemo(
    () => childrenArray.length,
    [childrenArray.length],
  );
  const matches = useMediaQuery("(min-width:1024px)");
  const isLastStep = useMemo(
    () => step === childrenArray.length - 1,
    [childrenArray.length, step],
  );

  const handleBack = useCallback((): void => {
    setStep((s) => s - 1);
  }, []);

  /** Метод по шагам - если шаг последний то сабмит формы если нет то дерагем формы для валидации шага
   *  */
  const handleSubmit = async (
    values: IOrderCultivarForm,
    helpers: FormikHelpers<FormikValues>,
  ): Promise<void> => {
    if (isLastStep) {
      await onSubmit(values, helpers);
      setCompleted(true);
    } else {
      await handleStepSubmit(values, helpers);
    }
  };

  const handleStepSubmit = async (
    values: IOrderCultivarForm,
    helpers: FormikHelpers<FormikValues>,
  ): Promise<void> => {
    if (step === 0 && !id && user) {
      await api.regulatoryInfo
        .postOrderCultivar({
          order: Number(values?.order),
          cultivar: values?.cultivar?.id,
          shared_appendix: values.shared_appendix,
          user,
        })
        .then((orderCultivar: IOrderCultivar) => {
          navigate({
            pathname: `${ROUTES_PATHS.planning}/${orderCultivar.id}/${ROUTES.addCultivar}`,
          });
          setStep((s) => s + 1);
        })
        .catch((error: AxiosError) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    } else if (step === 0 && id && user) {
      await api.regulatoryInfo
        .updateOrderCultivar(+id, {
          order: values?.order?.id,
          cultivar: values?.cultivar?.id,
          shared_appendix: values.shared_appendix,
          user,
        })
        .then(() => {
          setStep((s) => s + 1);
        })
        .catch((error: AxiosError) => {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    } else {
      setStep((s) => s + 1);
      helpers.setSubmitting(false);
    }
  };

  const handleClose = () => {
    navigate({
      pathname: ROUTES_PATHS.planning_crop,
    });
  };

  const renderStepper = () => {
    return matches ? (
      <StepperWrap>
        <Box flexGrow={1} p={3}>
          <Stepper activeStep={step} orientation="vertical">
            {childrenArray.map((child, index) => (
              <Step
                key={child.props.label}
                completed={step > index || completed}
              >
                <StepLabel
                  StepIconComponent={CircleStepIcon}
                >
                  {child.props.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Divider />
        <Box
          p={3}
          pb={4}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button variant="contained" onClick={handleClose}>
            {isLastStep ? "Готово" : "Закрыть"}
          </Button>
        </Box>
      </StepperWrap>
    ) : (
      <MobileControls
        steps={maxSteps}
        activeStep={step}
        handleBack={handleBack}
        isLastStep={isLastStep}
      />
    );
  };

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className={styles.form} noValidate>
          <Root>
            {renderStepper()}
            <ContentWrap>
              <Box p={3}>
                {currentChild}
                {matches ? (
                  <StepControls
                    step={step}
                    handleBack={handleBack}
                    isLastStep={isLastStep}
                  />
                ) : null}
              </Box>
            </ContentWrap>
          </Root>
        </Form>
      )}
    </Formik>
  );
};
