import AbstractApi from "./abstract";
import {
  AuthApi,
  StatementApi,
  UserApi,
  RegulatoryInfoApi,
  TrialApi,
  AgriculturalLabourApi,
  WeatherApi
} from "./domains";
import ErrorApiOverride from "./abstract/error";
import { apiInterface } from "./interfaces";
import PlanningApi from "./domains/Planning";
import OffBudgetApi from "./domains/OffBudget";
import ExcelUploadTaskApi from "./domains/ExcelUploadTask";

class Api extends AbstractApi {
  auth: apiInterface.IAuthApi;
  user: apiInterface.IUserApi;
  statement: apiInterface.IStatementApi;
  planning: apiInterface.IPlanningApi;
  regulatoryInfo: apiInterface.IRegulatoryInfo;
  errorHandlers: ErrorApiOverride;
  trial: TrialApi;
  offBudget: OffBudgetApi;
  agriculturalLabour: AgriculturalLabourApi;
  excelUploadTask: ExcelUploadTaskApi;
  weather: WeatherApi;

  constructor(MAIN_URL: string) {
    super(MAIN_URL);
    this.errorHandlers = new ErrorApiOverride(this);
    this.auth = new AuthApi(this);
    this.user = new UserApi(this);
    this.statement = new StatementApi(this);
    this.planning = new PlanningApi(this);
    this.regulatoryInfo = new RegulatoryInfoApi(this);
    this.trial = new TrialApi(this);
    this.offBudget = new OffBudgetApi(this);
    this.agriculturalLabour = new AgriculturalLabourApi(this)
    this.excelUploadTask = new ExcelUploadTaskApi(this)
    this.weather = new WeatherApi(this)
  }
}

export { Api };
