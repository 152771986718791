import React from "react";
import { Field, useFormikContext } from "formik";
import {
  FormControl,
  Box,
  FormHelperText,
  FormLabel,
  Tooltip
} from "@mui/material";
import {
  StyledChip
} from "@/apps/Modals/ModalContent/CreateVariety/styles";
import AddChip from "@/components/Chips/AddChip";
import { styled } from "@mui/system";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.blackAndWhite.black,
  fontWeight: 500
}));

interface IProps {
  name: string;
  handleDelete?: () => void;
  handleAdd: () => void;
  disabled?: boolean;
  tooltipLabel?: string;
  label?: string
  required?: boolean
}

const FormikAddChip:React.FC<IProps> = ({
  name,
  handleDelete,
  handleAdd,
  disabled = false,
  tooltipLabel = "",
  label = "",
  required= false
}) => {
  const formik = useFormikContext();

  const onDelete = () => {
    handleDelete ? handleDelete() : formik.setFieldValue(name, "");
  };

  if (!formik) {
    throw new Error(
      "FormikAddChip has to be used within <Formik>"
    );
  }

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <FormControl required={required}>
          <StyledFormLabel>{label}</StyledFormLabel>
          <Tooltip
            followCursor
            title={tooltipLabel}
          >
            <Box>
              {field.value ? (
                <StyledChip
                  size="small"
                  variant="outlined"
                  label={field.value.name}
                  onDelete={onDelete}
                />
              ) : (
                <AddChip
                  disabled={disabled}
                  onClick={handleAdd}
                />
              )}
            </Box>
          </Tooltip>
          <FormHelperText
            error={meta.touched && !!meta.error}>
            {meta.touched && meta.error}
          </FormHelperText>
        </FormControl>
      )}
    </Field>
  );
};

export default FormikAddChip;