import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import {
  cellButtons,
  cellPointText,
  headerCellStyle,
} from "../style";

import {
  DISEASES_HEADER_LABELS,
  DISEASES_KEYS,
} from "../const";

import { TDiseasesKeys } from "../interfaces";
import { EditingCellsDisease } from "../templates/Disease/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const DiseasesColumns: IColumn<TDiseasesKeys>[] = [
  {
    label: DISEASES_HEADER_LABELS.name,
    key: DISEASES_KEYS.name,
    contentHeader: DISEASES_HEADER_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: DISEASES_HEADER_LABELS.description,
    key: DISEASES_KEYS.description,
    contentHeader: DISEASES_HEADER_LABELS.description,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: DISEASES_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsDisease,
      styles: cellButtons,
    },
  },
];
