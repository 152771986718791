import { useStores, useInfiniteScroll } from "@/hooks";
import { useState } from "react";
import useDebounce from "@/hooks/useDebounce";
import { SWR_KEYS } from "@/const";
import {
  FiltersType
} from "@/components/Filters/interfaces";

interface IParams {
  selectedValue?: {
    key: number | string;
    label: string;
  } | null
  name?: string,
  label?: string
}

export const useCultivarFilter = ({
  selectedValue,
  name = "cultivar",
  label = "Культура"
}: IParams) => {
  const { api } = useStores();
  const [searchCultivar, setSearchCultivar] = useState("");
  const dbouncedSearchCultivarValue = useDebounce(
    searchCultivar,
    500,
  );

  const getKey = (index: number) => {
    return {
      _key: SWR_KEYS.getCultivarFieldVarietyList(),
      page: index + 1,
      search: dbouncedSearchCultivarValue,
    };
  };

  const {
    data: cultivarData,
    handleScroll: handleCultivarScroll
  } =
    useInfiniteScroll(getKey, api.regulatoryInfo.getCultivarList, {});

  const cultivarOptions = cultivarData.map(({
    name,
    id
  }) => {
    return { label: name, key: id };
  });
  return {
    name: name,
    label: label,
    type: FiltersType.autoComplete,
    selectedValue: selectedValue,
    options: cultivarOptions,
    value: "",
    handleScroll: handleCultivarScroll,
    style: { minWidth: "230px", flexGrow: 1 },
    setValue: setSearchCultivar,
  };
};