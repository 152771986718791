import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";

import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import AgriculturalMachineryForm from "./components/AgriculturalMachineryForm";
import { AGRICULTURAL_MACHINERY_NAME, IProps, mapFormToValue, mapValueToForm, TForm, validationSchema } from "./const";

/**
 * Модалка с формой создания/редактирования сорта
 * @param initialValue - начальные значения
 * @param isStatement - создание из заявки
 * @param onSubmit - калбек после успешного запроса
 * */
const AgriculturalMachinery: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const initialValues = modalProps.initialValues
  const { api, toastStore, swrStore } = useStores();
  const { agrMachineryId } = modalProps;

  const baseInititialValues = {
    [AGRICULTURAL_MACHINERY_NAME.NAME]: '',
    [AGRICULTURAL_MACHINERY_NAME.IDENTIFICATION_NUMBER]: '',
    [AGRICULTURAL_MACHINERY_NAME.TYPE]: '',
    [AGRICULTURAL_MACHINERY_NAME.CONDITION]: 'operational',
    [AGRICULTURAL_MACHINERY_NAME.PRODUCTION_YEAR]: null,
    [AGRICULTURAL_MACHINERY_NAME.LOCATION]: null,
    [AGRICULTURAL_MACHINERY_NAME.FILE_LIST]: [],
  }

  const mutateDetail = agrMachineryId ? swrStore.mutators[SWR_KEYS.getAgriculturalMachineryById(agrMachineryId)] : null

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    const { file_list, ...data } = mapFormToValue(values)
    const formData = new FormData()
    formData.append(AGRICULTURAL_MACHINERY_NAME.NAME, data.name);
    formData.append(AGRICULTURAL_MACHINERY_NAME.TYPE, data.type);
    formData.append(AGRICULTURAL_MACHINERY_NAME.IDENTIFICATION_NUMBER, data.identification_number);
    formData.append(AGRICULTURAL_MACHINERY_NAME.CONDITION, data.condition);
    formData.append(AGRICULTURAL_MACHINERY_NAME.PRODUCTION_YEAR, String(data.production_year));
    formData.append(AGRICULTURAL_MACHINERY_NAME.LOCATION, String(data.location)); 

    if (!agrMachineryId) {
      file_list.forEach((file) => {
        formData.append(AGRICULTURAL_MACHINERY_NAME.FILE_LIST, file);
      });
    }

    helpers.setSubmitting(true);
    const promise = agrMachineryId ?
      api.regulatoryInfo.patchAgrCultMachineryById(agrMachineryId, formData)
      : api.regulatoryInfo.postAgrCultMachinery(formData)
      
    promise
      .then(() => {
        mutateDetail && mutateDetail();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail()
        swrStore.mutators[SWR_KEYS.getAgriculturalMachinery()]();
      });

  };

  const values = initialValues
    ? {
      ...baseInititialValues,
      ...mapValueToForm(initialValues),
    }
    : baseInititialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <AgriculturalMachineryForm handleClose={handleClose} modalProps={modalProps} />
      )}
    />
  );
};

export default AgriculturalMachinery;
