import React from "react";
import {
  Box,
  Button,
  Divider,
  Typography
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

interface IProps {
  modalProps: {
    name: string
    handleAccept: () => void
    handleDecline: () => void
  };
}

/**
 * Модалка для подтверждения уникальности сорта
 * @param modalProps
 * @param modalProps.name название сорта
 * @param modalProps.handleSubmit метод на принятие
 * @param handleClose
 * @constructor
 */

const UniqueVarietyName: React.FC<IProps> = ({
  modalProps
}) => {
  const handleAccept = () => {
    modalProps.handleAccept && modalProps.handleAccept();
  };

  const handleDecline = () => {
    modalProps.handleDecline && modalProps.handleDecline();
  };
  return (
    <Box>
      <Box p={3}>
        <Typography variant="h2" mb={1}>
          Название сорта{" "}
          <Typography variant="h2"
                      component="div"
                      display="inline-block"
                      color="primary">
            {modalProps.name}
          </Typography>{" "}
          уже существует в рамках данной культуры.
        </Typography>
        <Typography>
          Создать новый сорт с аналогичным названием?
        </Typography>
      </Box>
      <Divider/>
      <Box p={3} display="flex"
           justifyContent="space-between">
        <Button color="red" onClick={handleDecline}>
          нет
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CheckIcon/>}
          onClick={handleAccept}
        >
          да
        </Button>
      </Box>
    </Box>
  );
};

export default UniqueVarietyName;