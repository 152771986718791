export const LAYER_TYPES = {
  satellite: "satellite",
  relief: "relief",
  scheme: "scheme",
  core: "core"
} as const;

export const BASE_MAP_STATE = {
  center: { lat: 40.976839243408165, lng: 57.00119110602376 },
  zoom: {
    base: 13,
    min: 1,
    max: 18
  }
} as const;

export const TYPES_MAP_OBJECT = {
  Polygon: "Polygon",
  Point: "Point"
} as const;

export const VIEW_MOD = {
  GSU_LIST: "GSU_LIST",
  AREA_DETAIL: "AREA_DETAIL",
  FIELD_DETAIL: "FIELD_DETAIL",
  SORT_DETAIL: "SORT_DETAIL",
  PLOT_DETAIL: "PlOT_DETAIL",
  DRAW_PLOT: "DRAW_PLOT",
  DRAW_POLYGON_CONTROL_AREA: "DRAW_POLYGON_CONTROL_AREA",
  DRAW_POLYGON_CONTROL_FIELD: "DRAW_POLYGON_CONTROL_FIELD",
  DRAW_POLYGON_CONTROL_SORT: "DRAW_POLYGON_CONTROL_SORT"
} as const;

export const POLYGON_PRESET_COLORS = {
  area: "green",
  field: "red",
  draw: "blue"
};

export const VARIANTS_TABS = {
  AREA: {
    detail: "detail",
    children: "children"
  },
  CULTIVAR: {
    detail: "detail",
    coords: "coords"
  }
};
