import yup from "@/utils/yup";

export const STEP_LABELS = {
  GROUPS: 'Группы',
  DISTRIBUTION: 'Распределение'
}

export const FIELDS_NAME = {
  GROUPS: "groups"
} as const

export interface IGroupFormik {
  [FIELDS_NAME.GROUPS]: {
    id: number,
    name: string
  }[]
}

export const initialGroupValues = {
  [FIELDS_NAME.GROUPS]: []
}
export const groupValidationSchema = yup.object().shape({
  [FIELDS_NAME.GROUPS]: yup.array().min(1, 'Это поле необходимо заполнить').required()
})