import yup from "@/utils/yup";
import {
  reqInterface,
  resInterface
} from "@/api/interfaces";
import {
  IAddress,
  ISubjectListEmail,
  ISubjectListPhone
} from "@/api/interfaces/responses";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    branchId?: number;
    initialValues?: resInterface.IBranchItem;
  };
  onSubmit?: () => void;
}
export interface IBranchEmail extends ISubjectListEmail {
  id: number;
  email: string;
}
export interface IBranchPhone extends ISubjectListPhone {
  id: number;
  phone: string;
}

interface IAvailableSelectValue {
  id: number;
  label: string;
}

export type TForm = Omit<reqInterface.IBranchReq, "emails" | "phones"> & {
  emails: IBranchEmail[];
  phones: IBranchPhone[];
  region: IAvailableSelectValue;
  staff_director?: IAvailableSelectValue;
  legal_address: IAddress;
  actual_address: IAddress;
  postal_address: IAddress;
};

export enum BRANCH_FIELD_NAME {
  REGION = "region",
  NAME = "name",
  FULL_NAME = "full_name",
  SHORT_NAME = "short_name",
  STAFF_DIRECTOR = "staff_director",
  STAFFING = "staffing",
  VACANCIES = "vacancies",
  LEGAL_ADDRESS = "legal_address",
  ACTUAL_ADDRES = "actual_address",
  POSTAL_ADDRESS = "postal_address",
  COMMENT = "comment",
  PHONES = "phones",
  EMAILS = "emails",
  IS_ACTIVE = "is_active",
}

export const BRANCH_MODAL_KEY = "BranchModalKey"

const phoneSchema = yup.object().shape({
  phone: yup.string()
    .matches(
      /^(\+?\d{1,3}[- ]?)?\d{10}$/,
      "Неверный формат номера телефона",
    )
    .nullable(),
});

const emailSchema = yup.object().shape({
  email: yup.string()
    .matches(
      /.*@.*/g,
      "Неверный формат электронной почты",
    )
    .nullable(),
});

export const validationSchemaFirstStep = yup.object().shape({
  [BRANCH_FIELD_NAME.REGION]: yup.mixed().required(),
  [BRANCH_FIELD_NAME.FULL_NAME]: yup.string().required(),
  [BRANCH_FIELD_NAME.SHORT_NAME]: yup.string().required(),
  [BRANCH_FIELD_NAME.NAME]: yup.string().required(),
  [BRANCH_FIELD_NAME.STAFFING]: yup.number().max(2147483647),
  [BRANCH_FIELD_NAME.VACANCIES]: yup.number().max(2147483647),
});

export const validationSchemaSecondStep = yup.object().shape({
  [BRANCH_FIELD_NAME.EMAILS]: yup.array()
    .of(emailSchema)
    .nullable(),
  [BRANCH_FIELD_NAME.PHONES]: yup.array()
    .of(phoneSchema)
    .nullable(),
});

export const BRANCH_FORM_STEPS = {
  FIRST: "first",
  SECOND: "second",
} as const;

export const SUBDIVISION_TYPES = [
  { value: "department", label: "Отдел" },
  { value: "branch", label: "Филиал" },
  { value: "gsu", label: "ГСУ" },
];

export const SUBDIVISION_CONST = {
  DEPARTMENT: "department",
  BRANCH: "branch",
  GSU: "gsu",
} as const;

export const mapValueToForm = (
  baseInitialValues,
  branchData: resInterface.IBranchItem,
): TForm => {
  const initialPhones = branchData.phones?.length
    ? branchData.phones.map((phone, index) => ({
      id: index,
      phone: phone.phone,
      name: phone.name,
    }))
    : [{ id: 0, phone: "", name: "" }];

  const initialEmails = branchData.emails?.length
    ? branchData.emails.map((email, index) => ({
      id: index,
      email: email.email,
    }))
    : [{ id: 0, email: "" }];

  return {
    ...baseInitialValues,
      ...branchData,
      [BRANCH_FIELD_NAME.NAME]: branchData.name || "",
      [BRANCH_FIELD_NAME.FULL_NAME]: branchData.full_name || "",
      [BRANCH_FIELD_NAME.SHORT_NAME]: branchData.short_name || "",
      [BRANCH_FIELD_NAME.PHONES]: initialPhones,
      [BRANCH_FIELD_NAME.EMAILS]: initialEmails,
      [BRANCH_FIELD_NAME.REGION]: branchData.region
        ? {
          label: branchData.region?.name ?? branchData.region?.label ?? "adasdasd",
          id: branchData.region?.id,
        }
        : null,
      [BRANCH_FIELD_NAME.STAFF_DIRECTOR]: branchData.staff_director
        ? {
          label: branchData.staff_director?.full_name ?? branchData.staff_director?.label,

          id: branchData.staff_director?.id,
        }
        : null,
      [BRANCH_FIELD_NAME.STAFFING]: branchData.staffing || "",
      [BRANCH_FIELD_NAME.VACANCIES]: branchData.vacancies || "",
      [BRANCH_FIELD_NAME.LEGAL_ADDRESS]: branchData.legal_address || "",
      [BRANCH_FIELD_NAME.ACTUAL_ADDRES]: branchData.actual_address || "",
      [BRANCH_FIELD_NAME.POSTAL_ADDRESS]: branchData.postal_address || "",
      [BRANCH_FIELD_NAME.COMMENT]: branchData.comment || "",
  };
};
