import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import TextSnippetOutlinedIcon
  from "@mui/icons-material/TextSnippetOutlined";
import theme from "@/theme";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";

interface IProps {
  handleAdd: () => void;
}

const EmptyList: React.FC<IProps> = ({ handleAdd }) => {
  return (
    <Stack spacing="20px">
      <Typography variant="h3">Признаки</Typography>
      <Stack
        p={3}
        pt={5}
        spacing={3}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <TextSnippetOutlinedIcon sx={{
          color: theme.palette.blackAndWhite.stroke,
          fontSize: 45
        }}/>
        <Typography variant="h3">Заполните данные по признакам</Typography>
        <Button
          color={"blue"}
          startIcon={<AddOutlinedIcon/>}
          variant={"outlined"}
          onClick={handleAdd}
        >
          Добавить признаки
        </Button>
      </Stack>
    </Stack>
  );
};

export default EmptyList;