import yup from "@/utils/yup";
import { IChemistry } from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  CHEMISTRY: "chemistry",
  QUANTITY_TOTAL: "quantity_total",
  PRICE: "price"
} as const;

export interface IFormik {
  [FIELDS_NAME.CHEMISTRY]: IChemistry | null,
  [FIELDS_NAME.QUANTITY_TOTAL]: number | string
  [FIELDS_NAME.PRICE]: number | string
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.CHEMISTRY]: yup.mixed().required(),
  [FIELDS_NAME.QUANTITY_TOTAL]: yup.number().required(),
  [FIELDS_NAME.PRICE]: yup
    .string()
    .matches(
      /^\d+(\.\d{1,2})?$/,
      "Обратите внимание, рубли и копейки разделяются точкой",
    )
    .required(),
});

export const initialValues = {
  [FIELDS_NAME.QUANTITY_TOTAL]: "",
  [FIELDS_NAME.CHEMISTRY]: null,
  [FIELDS_NAME.PRICE]: ""
};