import React, { Suspense } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { useLocation } from "react-router";
import { appRoutes } from "./appRoutes";
import { ROUTES_PATHS } from "./const";

const AppRouter: React.FC = () => {
  const routes = appRoutes();
  const app = useRoutes(routes);
  const location = useLocation();
  const basePathname = location.pathname.split("/").slice(0, 2).join("/");

  if (!Object.values(ROUTES_PATHS).includes(basePathname)) {
    const app404 = routes.find(
      (el) => el.path === ROUTES_PATHS.Error404
    ) as RouteObject;
    return (
      <Suspense>
        <>{app404.element}</>
      </Suspense>
    );
  } else {
    return (
      <Suspense>
        <>{app}</>
      </Suspense>
    );
  }
};

export default AppRouter;
