import React from "react";
import { ILogo } from "./interfaces";

const Logo = ({ className }: ILogo): React.ReactElement => {
  return (
    <svg
      className={className}
      width="38"
      height="44"
      viewBox="0 0 38 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="logo" clipPath="url(#clip0_1299_7279)">
        <path
          id="Vector"
          d="M1.40723 32.2979V11.7022L19.0004 1.4043L36.5924 11.7022V32.2979L19.0004 42.5958L1.40723 32.2979Z"
          fill="white"
          stroke="#3C6C3D"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          id="Vector_2"
          d="M2.3457 12.4043V31.5958L19 41.1916L35.6543 31.5958V12.4043L19 2.80859L2.3457 12.4043Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M3.28418 31.127V12.8717L19.0008 3.74463L34.7163 12.8717V31.127L19.0008 40.2553L3.28418 31.127Z"
          stroke="#F7A70A"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <g id="b">
          <g id="c">
            <g id="Group">
              <path
                id="Vector_4"
                d="M14.2542 14.5989C14.4421 16.711 18.0554 16.8123 18.8965 18.6139V22.292C18.2564 20.5212 14.8419 20.2481 14.228 18.5655L14.2542 14.6011V14.5989Z"
                fill="url(#paint0_linear_1299_7279)"
              />
              <g id="Group_2">
                <g id="Vector_5">
                  <path
                    d="M14.2542 22.9749C14.4421 25.087 18.0554 25.1883 18.8965 26.9899V30.6679C18.2564 28.8972 14.8419 28.6241 14.228 26.9414L14.2542 22.9771V22.9749Z"
                    fill="url(#paint1_linear_1299_7279)"
                  />
                  <path
                    d="M14.2542 22.9749C14.4421 25.087 18.0554 25.1883 18.8965 26.9899V30.6679C18.2564 28.8972 14.8419 28.6241 14.228 26.9414L14.2542 22.9771V22.9749Z"
                    stroke="#25292C"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M14.2542 22.9749C14.4421 25.087 18.0554 25.1883 18.8965 26.9899V30.6679C18.2564 28.8972 14.8419 28.6241 14.228 26.9414L14.2542 22.9771V22.9749Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M14.2542 22.9749C14.4421 25.087 18.0554 25.1883 18.8965 26.9899V30.6679C18.2564 28.8972 14.8419 28.6241 14.228 26.9414L14.2542 22.9771V22.9749Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M14.2542 22.9749C14.4421 25.087 18.0554 25.1883 18.8965 26.9899V30.6679C18.2564 28.8972 14.8419 28.6241 14.228 26.9414L14.2542 22.9771V22.9749Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                </g>
                <path
                  id="Vector_6"
                  d="M23.9035 22.9749C23.7156 25.087 20.1023 25.1883 19.2612 26.9899V30.6679C19.9013 28.8972 23.3158 28.6241 23.9297 26.9414L23.9035 22.9771V22.9749Z"
                  fill="#E30613"
                />
              </g>
              <g id="Group_3">
                <g id="Vector_7">
                  <path
                    d="M14.2542 14.5989C14.4421 16.711 18.0554 16.8123 18.8965 18.6139V22.292C18.2564 20.5212 14.8419 20.2481 14.228 18.5655L14.2542 14.6011V14.5989Z"
                    fill="url(#paint2_linear_1299_7279)"
                  />
                  <path
                    d="M14.2542 14.5989C14.4421 16.711 18.0554 16.8123 18.8965 18.6139V22.292C18.2564 20.5212 14.8419 20.2481 14.228 18.5655L14.2542 14.6011V14.5989Z"
                    stroke="#25292C"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M14.2542 14.5989C14.4421 16.711 18.0554 16.8123 18.8965 18.6139V22.292C18.2564 20.5212 14.8419 20.2481 14.228 18.5655L14.2542 14.6011V14.5989Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M14.2542 14.5989C14.4421 16.711 18.0554 16.8123 18.8965 18.6139V22.292C18.2564 20.5212 14.8419 20.2481 14.228 18.5655L14.2542 14.6011V14.5989Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M14.2542 14.5989C14.4421 16.711 18.0554 16.8123 18.8965 18.6139V22.292C18.2564 20.5212 14.8419 20.2481 14.228 18.5655L14.2542 14.6011V14.5989Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                </g>
                <g id="Vector_8">
                  <path
                    d="M23.9035 14.5989C23.7156 16.711 20.1023 16.8123 19.2612 18.6139V22.292C19.9013 20.5212 23.3158 20.2481 23.9297 18.5655L23.9035 14.6011V14.5989Z"
                    fill="white"
                  />
                  <path
                    d="M23.9035 14.5989C23.7156 16.711 20.1023 16.8123 19.2612 18.6139V22.292C19.9013 20.5212 23.3158 20.2481 23.9297 18.5655L23.9035 14.6011V14.5989Z"
                    stroke="#25292C"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M23.9035 14.5989C23.7156 16.711 20.1023 16.8123 19.2612 18.6139V22.292C19.9013 20.5212 23.3158 20.2481 23.9297 18.5655L23.9035 14.6011V14.5989Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M23.9035 14.5989C23.7156 16.711 20.1023 16.8123 19.2612 18.6139V22.292C19.9013 20.5212 23.3158 20.2481 23.9297 18.5655L23.9035 14.6011V14.5989Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M23.9035 14.5989C23.7156 16.711 20.1023 16.8123 19.2612 18.6139V22.292C19.9013 20.5212 23.3158 20.2481 23.9297 18.5655L23.9035 14.6011V14.5989Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                </g>
              </g>
              <g id="Group_4">
                <g id="Vector_9">
                  <path
                    d="M14.2542 18.7991C14.4421 20.9112 18.0554 21.0125 18.8965 22.8141V26.4922C18.2564 24.7214 14.8419 24.4483 14.228 22.7656L14.2542 18.8013V18.7991Z"
                    fill="url(#paint3_linear_1299_7279)"
                  />
                  <path
                    d="M14.2542 18.7991C14.4421 20.9112 18.0554 21.0125 18.8965 22.8141V26.4922C18.2564 24.7214 14.8419 24.4483 14.228 22.7656L14.2542 18.8013V18.7991Z"
                    stroke="#25292C"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M14.2542 18.7991C14.4421 20.9112 18.0554 21.0125 18.8965 22.8141V26.4922C18.2564 24.7214 14.8419 24.4483 14.228 22.7656L14.2542 18.8013V18.7991Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M14.2542 18.7991C14.4421 20.9112 18.0554 21.0125 18.8965 22.8141V26.4922C18.2564 24.7214 14.8419 24.4483 14.228 22.7656L14.2542 18.8013V18.7991Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M14.2542 18.7991C14.4421 20.9112 18.0554 21.0125 18.8965 22.8141V26.4922C18.2564 24.7214 14.8419 24.4483 14.228 22.7656L14.2542 18.8013V18.7991Z"
                    stroke="black"
                    strokeOpacity="0.2"
                    strokeWidth="0.23"
                    strokeMiterlimit="10"
                  />
                </g>
                <path
                  id="Vector_10"
                  d="M23.9035 18.7991C23.7156 20.9112 20.1023 21.0125 19.2612 22.8141V26.4922C19.9013 24.7214 23.3158 24.4483 23.9297 22.7656L23.9035 18.8013V18.7991Z"
                  fill="#1961AC"
                />
              </g>
              <g id="Vector_11">
                <path
                  d="M18.9599 9.3418C18.6607 11.4936 16.6814 12.3349 16.6836 14.2312C16.6836 16.5592 17.8895 16.2794 19.0189 18.2528L18.9599 9.3418Z"
                  fill="url(#paint4_linear_1299_7279)"
                />
                <path
                  d="M18.9599 9.3418C18.6607 11.4936 16.6814 12.3349 16.6836 14.2312C16.6836 16.5592 17.8895 16.2794 19.0189 18.2528L18.9599 9.3418Z"
                  stroke="#25292C"
                  strokeWidth="0.23"
                  strokeMiterlimit="10"
                />
                <path
                  d="M18.9599 9.3418C18.6607 11.4936 16.6814 12.3349 16.6836 14.2312C16.6836 16.5592 17.8895 16.2794 19.0189 18.2528L18.9599 9.3418Z"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.23"
                  strokeMiterlimit="10"
                />
                <path
                  d="M18.9599 9.3418C18.6607 11.4936 16.6814 12.3349 16.6836 14.2312C16.6836 16.5592 17.8895 16.2794 19.0189 18.2528L18.9599 9.3418Z"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.23"
                  strokeMiterlimit="10"
                />
                <path
                  d="M18.9599 9.3418C18.6607 11.4936 16.6814 12.3349 16.6836 14.2312C16.6836 16.5592 17.8895 16.2794 19.0189 18.2528L18.9599 9.3418Z"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.23"
                  strokeMiterlimit="10"
                />
              </g>
              <g id="Vector_12">
                <path
                  d="M19.2 18.2528C20.2486 16.2354 21.5288 16.5085 21.437 14.1827C21.3606 12.2864 19.4687 11.4782 19.082 9.3418L19.2 18.2528Z"
                  fill="url(#paint5_linear_1299_7279)"
                />
                <path
                  d="M19.2 18.2528C20.2486 16.2354 21.5288 16.5085 21.437 14.1827C21.3606 12.2864 19.4687 11.4782 19.082 9.3418L19.2 18.2528Z"
                  stroke="#25292C"
                  strokeWidth="0.23"
                  strokeMiterlimit="10"
                />
                <path
                  d="M19.2 18.2528C20.2486 16.2354 21.5288 16.5085 21.437 14.1827C21.3606 12.2864 19.4687 11.4782 19.082 9.3418L19.2 18.2528Z"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.23"
                  strokeMiterlimit="10"
                />
                <path
                  d="M19.2 18.2528C20.2486 16.2354 21.5288 16.5085 21.437 14.1827C21.3606 12.2864 19.4687 11.4782 19.082 9.3418L19.2 18.2528Z"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.23"
                  strokeMiterlimit="10"
                />
                <path
                  d="M19.2 18.2528C20.2486 16.2354 21.5288 16.5085 21.437 14.1827C21.3606 12.2864 19.4687 11.4782 19.082 9.3418L19.2 18.2528Z"
                  stroke="black"
                  strokeOpacity="0.2"
                  strokeWidth="0.23"
                  strokeMiterlimit="10"
                />
              </g>
              <g id="Group_5">
                <g id="Group_6">
                  <path
                    id="Vector_13"
                    d="M6.90957 20.343C7.0319 21.7063 9.36505 21.7724 9.90683 22.9353V25.3095C9.49394 24.1664 7.28969 23.9902 6.89209 22.9044L6.90957 20.3452V20.343Z"
                    fill="url(#paint6_linear_1299_7279)"
                  />
                  <g id="Group_7">
                    <g id="Vector_14">
                      <path
                        d="M6.90957 20.343C7.0319 21.7063 9.36505 21.7724 9.90683 22.9353V25.3095C9.49394 24.1664 7.28969 23.9902 6.89209 22.9044L6.90957 20.3452V20.343Z"
                        fill="url(#paint7_linear_1299_7279)"
                      />
                      <path
                        d="M6.90957 20.343C7.0319 21.7063 9.36505 21.7724 9.90683 22.9353V25.3095C9.49394 24.1664 7.28969 23.9902 6.89209 22.9044L6.90957 20.3452V20.343Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M6.90957 20.343C7.0319 21.7063 9.36505 21.7724 9.90683 22.9353V25.3095C9.49394 24.1664 7.28969 23.9902 6.89209 22.9044L6.90957 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M6.90957 20.343C7.0319 21.7063 9.36505 21.7724 9.90683 22.9353V25.3095C9.49394 24.1664 7.28969 23.9902 6.89209 22.9044L6.90957 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M6.90957 20.343C7.0319 21.7063 9.36505 21.7724 9.90683 22.9353V25.3095C9.49394 24.1664 7.28969 23.9902 6.89209 22.9044L6.90957 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                    <g id="Vector_15">
                      <path
                        d="M13.1398 20.343C13.0175 21.7063 10.6844 21.7724 10.1426 22.9353V25.3095C10.5555 24.1664 12.7597 23.9902 13.1573 22.9044L13.1398 20.3452V20.343Z"
                        fill="url(#paint8_linear_1299_7279)"
                      />
                      <path
                        d="M13.1398 20.343C13.0175 21.7063 10.6844 21.7724 10.1426 22.9353V25.3095C10.5555 24.1664 12.7597 23.9902 13.1573 22.9044L13.1398 20.3452V20.343Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M13.1398 20.343C13.0175 21.7063 10.6844 21.7724 10.1426 22.9353V25.3095C10.5555 24.1664 12.7597 23.9902 13.1573 22.9044L13.1398 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M13.1398 20.343C13.0175 21.7063 10.6844 21.7724 10.1426 22.9353V25.3095C10.5555 24.1664 12.7597 23.9902 13.1573 22.9044L13.1398 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M13.1398 20.343C13.0175 21.7063 10.6844 21.7724 10.1426 22.9353V25.3095C10.5555 24.1664 12.7597 23.9902 13.1573 22.9044L13.1398 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                  </g>
                  <g id="Group_8">
                    <g id="Vector_16">
                      <path
                        d="M6.90957 23.0564C7.0319 24.4197 9.36505 24.4858 9.90683 25.6487V28.0229C9.49394 26.8798 7.28969 26.7036 6.89209 25.6178L6.90957 23.0586V23.0564Z"
                        fill="url(#paint9_linear_1299_7279)"
                      />
                      <path
                        d="M6.90957 23.0564C7.0319 24.4197 9.36505 24.4858 9.90683 25.6487V28.0229C9.49394 26.8798 7.28969 26.7036 6.89209 25.6178L6.90957 23.0586V23.0564Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M6.90957 23.0564C7.0319 24.4197 9.36505 24.4858 9.90683 25.6487V28.0229C9.49394 26.8798 7.28969 26.7036 6.89209 25.6178L6.90957 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M6.90957 23.0564C7.0319 24.4197 9.36505 24.4858 9.90683 25.6487V28.0229C9.49394 26.8798 7.28969 26.7036 6.89209 25.6178L6.90957 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M6.90957 23.0564C7.0319 24.4197 9.36505 24.4858 9.90683 25.6487V28.0229C9.49394 26.8798 7.28969 26.7036 6.89209 25.6178L6.90957 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                    <g id="Vector_17">
                      <path
                        d="M13.1398 23.0564C13.0175 24.4197 10.6844 24.4858 10.1426 25.6487V28.0229C10.5555 26.8798 12.7597 26.7036 13.1573 25.6178L13.1398 23.0586V23.0564Z"
                        fill="url(#paint10_linear_1299_7279)"
                      />
                      <path
                        d="M13.1398 23.0564C13.0175 24.4197 10.6844 24.4858 10.1426 25.6487V28.0229C10.5555 26.8798 12.7597 26.7036 13.1573 25.6178L13.1398 23.0586V23.0564Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M13.1398 23.0564C13.0175 24.4197 10.6844 24.4858 10.1426 25.6487V28.0229C10.5555 26.8798 12.7597 26.7036 13.1573 25.6178L13.1398 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M13.1398 23.0564C13.0175 24.4197 10.6844 24.4858 10.1426 25.6487V28.0229C10.5555 26.8798 12.7597 26.7036 13.1573 25.6178L13.1398 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M13.1398 23.0564C13.0175 24.4197 10.6844 24.4858 10.1426 25.6487V28.0229C10.5555 26.8798 12.7597 26.7036 13.1573 25.6178L13.1398 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                  </g>
                  <g id="Group_9">
                    <g id="Vector_18">
                      <path
                        d="M6.90957 25.7498C7.0319 27.1131 9.36505 27.1791 9.90683 28.342V30.7162C9.49394 29.5732 7.28969 29.397 6.89209 28.3112L6.90957 25.752V25.7498Z"
                        fill="url(#paint11_linear_1299_7279)"
                      />
                      <path
                        d="M6.90957 25.7498C7.0319 27.1131 9.36505 27.1791 9.90683 28.342V30.7162C9.49394 29.5732 7.28969 29.397 6.89209 28.3112L6.90957 25.752V25.7498Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M6.90957 25.7498C7.0319 27.1131 9.36505 27.1791 9.90683 28.342V30.7162C9.49394 29.5732 7.28969 29.397 6.89209 28.3112L6.90957 25.752V25.7498Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M6.90957 25.7498C7.0319 27.1131 9.36505 27.1791 9.90683 28.342V30.7162C9.49394 29.5732 7.28969 29.397 6.89209 28.3112L6.90957 25.752V25.7498Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M6.90957 25.7498C7.0319 27.1131 9.36505 27.1791 9.90683 28.342V30.7162C9.49394 29.5732 7.28969 29.397 6.89209 28.3112L6.90957 25.752V25.7498Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                    <g id="Vector_19">
                      <path
                        d="M13.1398 25.7498C13.0175 27.1131 10.6844 27.1791 10.1426 28.342V30.7162C10.5555 29.5732 12.7597 29.397 13.1573 28.3112L13.1398 25.752V25.7498Z"
                        fill="url(#paint12_linear_1299_7279)"
                      />
                      <path
                        d="M13.1398 25.7498C13.0175 27.1131 10.6844 27.1791 10.1426 28.342V30.7162C10.5555 29.5732 12.7597 29.397 13.1573 28.3112L13.1398 25.752V25.7498Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M13.1398 25.7498C13.0175 27.1131 10.6844 27.1791 10.1426 28.342V30.7162C10.5555 29.5732 12.7597 29.397 13.1573 28.3112L13.1398 25.752V25.7498Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M13.1398 25.7498C13.0175 27.1131 10.6844 27.1791 10.1426 28.342V30.7162C10.5555 29.5732 12.7597 29.397 13.1573 28.3112L13.1398 25.752V25.7498Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M13.1398 25.7498C13.0175 27.1131 10.6844 27.1791 10.1426 28.342V30.7162C10.5555 29.5732 12.7597 29.397 13.1573 28.3112L13.1398 25.752V25.7498Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                  </g>
                  <g id="Vector_20">
                    <path
                      d="M9.94826 16.949C9.75383 18.3387 8.47803 18.8805 8.47803 20.1073C8.47803 21.6093 9.25574 21.4309 9.9854 22.7039L9.94826 16.949Z"
                      fill="url(#paint13_linear_1299_7279)"
                    />
                    <path
                      d="M9.94826 16.949C9.75383 18.3387 8.47803 18.8805 8.47803 20.1073C8.47803 21.6093 9.25574 21.4309 9.9854 22.7039L9.94826 16.949Z"
                      stroke="#25292C"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M9.94826 16.949C9.75383 18.3387 8.47803 18.8805 8.47803 20.1073C8.47803 21.6093 9.25574 21.4309 9.9854 22.7039L9.94826 16.949Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M9.94826 16.949C9.75383 18.3387 8.47803 18.8805 8.47803 20.1073C8.47803 21.6093 9.25574 21.4309 9.9854 22.7039L9.94826 16.949Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M9.94826 16.949C9.75383 18.3387 8.47803 18.8805 8.47803 20.1073C8.47803 21.6093 9.25574 21.4309 9.9854 22.7039L9.94826 16.949Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                  </g>
                  <g id="Vector_21">
                    <path
                      d="M10.1014 22.7039C10.7786 21.4001 11.6044 21.5785 11.5454 20.0764C11.4973 18.8519 10.2761 18.3299 10.0249 16.9512L10.1014 22.7061V22.7039Z"
                      fill="url(#paint14_linear_1299_7279)"
                    />
                    <path
                      d="M10.1014 22.7039C10.7786 21.4001 11.6044 21.5785 11.5454 20.0764C11.4973 18.8519 10.2761 18.3299 10.0249 16.9512L10.1014 22.7061V22.7039Z"
                      stroke="#25292C"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M10.1014 22.7039C10.7786 21.4001 11.6044 21.5785 11.5454 20.0764C11.4973 18.8519 10.2761 18.3299 10.0249 16.9512L10.1014 22.7061V22.7039Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M10.1014 22.7039C10.7786 21.4001 11.6044 21.5785 11.5454 20.0764C11.4973 18.8519 10.2761 18.3299 10.0249 16.9512L10.1014 22.7061V22.7039Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M10.1014 22.7039C10.7786 21.4001 11.6044 21.5785 11.5454 20.0764C11.4973 18.8519 10.2761 18.3299 10.0249 16.9512L10.1014 22.7061V22.7039Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                  </g>
                </g>
                <g id="Group_10">
                  <path
                    id="Vector_22"
                    d="M24.8603 20.343C24.9826 21.7063 27.3157 21.7724 27.8575 22.9353V25.3095C27.4446 24.1664 25.2404 23.9902 24.8428 22.9044L24.8603 20.3452V20.343Z"
                    fill="url(#paint15_linear_1299_7279)"
                  />
                  <g id="Group_11">
                    <g id="Vector_23">
                      <path
                        d="M24.8603 20.343C24.9826 21.7063 27.3157 21.7724 27.8575 22.9353V25.3095C27.4446 24.1664 25.2404 23.9902 24.8428 22.9044L24.8603 20.3452V20.343Z"
                        fill="url(#paint16_linear_1299_7279)"
                      />
                      <path
                        d="M24.8603 20.343C24.9826 21.7063 27.3157 21.7724 27.8575 22.9353V25.3095C27.4446 24.1664 25.2404 23.9902 24.8428 22.9044L24.8603 20.3452V20.343Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M24.8603 20.343C24.9826 21.7063 27.3157 21.7724 27.8575 22.9353V25.3095C27.4446 24.1664 25.2404 23.9902 24.8428 22.9044L24.8603 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M24.8603 20.343C24.9826 21.7063 27.3157 21.7724 27.8575 22.9353V25.3095C27.4446 24.1664 25.2404 23.9902 24.8428 22.9044L24.8603 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M24.8603 20.343C24.9826 21.7063 27.3157 21.7724 27.8575 22.9353V25.3095C27.4446 24.1664 25.2404 23.9902 24.8428 22.9044L24.8603 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                    <g id="Vector_24">
                      <path
                        d="M31.0905 20.343C30.9682 21.7063 28.635 21.7724 28.0933 22.9353V25.3095C28.5061 24.1664 30.7104 23.9902 31.108 22.9044L31.0905 20.3452V20.343Z"
                        fill="url(#paint17_linear_1299_7279)"
                      />
                      <path
                        d="M31.0905 20.343C30.9682 21.7063 28.635 21.7724 28.0933 22.9353V25.3095C28.5061 24.1664 30.7104 23.9902 31.108 22.9044L31.0905 20.3452V20.343Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M31.0905 20.343C30.9682 21.7063 28.635 21.7724 28.0933 22.9353V25.3095C28.5061 24.1664 30.7104 23.9902 31.108 22.9044L31.0905 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M31.0905 20.343C30.9682 21.7063 28.635 21.7724 28.0933 22.9353V25.3095C28.5061 24.1664 30.7104 23.9902 31.108 22.9044L31.0905 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M31.0905 20.343C30.9682 21.7063 28.635 21.7724 28.0933 22.9353V25.3095C28.5061 24.1664 30.7104 23.9902 31.108 22.9044L31.0905 20.3452V20.343Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                  </g>
                  <g id="Group_12">
                    <g id="Vector_25">
                      <path
                        d="M24.8603 23.0564C24.9826 24.4197 27.3157 24.4858 27.8575 25.6487V28.0229C27.4446 26.8798 25.2404 26.7036 24.8428 25.6178L24.8603 23.0586V23.0564Z"
                        fill="url(#paint18_linear_1299_7279)"
                      />
                      <path
                        d="M24.8603 23.0564C24.9826 24.4197 27.3157 24.4858 27.8575 25.6487V28.0229C27.4446 26.8798 25.2404 26.7036 24.8428 25.6178L24.8603 23.0586V23.0564Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M24.8603 23.0564C24.9826 24.4197 27.3157 24.4858 27.8575 25.6487V28.0229C27.4446 26.8798 25.2404 26.7036 24.8428 25.6178L24.8603 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M24.8603 23.0564C24.9826 24.4197 27.3157 24.4858 27.8575 25.6487V28.0229C27.4446 26.8798 25.2404 26.7036 24.8428 25.6178L24.8603 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M24.8603 23.0564C24.9826 24.4197 27.3157 24.4858 27.8575 25.6487V28.0229C27.4446 26.8798 25.2404 26.7036 24.8428 25.6178L24.8603 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                    <g id="Vector_26">
                      <path
                        d="M31.0905 23.0564C30.9682 24.4197 28.635 24.4858 28.0933 25.6487V28.0229C28.5061 26.8798 30.7104 26.7036 31.108 25.6178L31.0905 23.0586V23.0564Z"
                        fill="url(#paint19_linear_1299_7279)"
                      />
                      <path
                        d="M31.0905 23.0564C30.9682 24.4197 28.635 24.4858 28.0933 25.6487V28.0229C28.5061 26.8798 30.7104 26.7036 31.108 25.6178L31.0905 23.0586V23.0564Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M31.0905 23.0564C30.9682 24.4197 28.635 24.4858 28.0933 25.6487V28.0229C28.5061 26.8798 30.7104 26.7036 31.108 25.6178L31.0905 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M31.0905 23.0564C30.9682 24.4197 28.635 24.4858 28.0933 25.6487V28.0229C28.5061 26.8798 30.7104 26.7036 31.108 25.6178L31.0905 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M31.0905 23.0564C30.9682 24.4197 28.635 24.4858 28.0933 25.6487V28.0229C28.5061 26.8798 30.7104 26.7036 31.108 25.6178L31.0905 23.0586V23.0564Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                  </g>
                  <g id="Group_13">
                    <g id="Vector_27">
                      <path
                        d="M24.8603 25.75C24.9826 27.1133 27.3157 27.1794 27.8575 28.3423V30.7165C27.4446 29.5734 25.2404 29.3972 24.8428 28.3114L24.8603 25.7522V25.75Z"
                        fill="url(#paint20_linear_1299_7279)"
                      />
                      <path
                        d="M24.8603 25.75C24.9826 27.1133 27.3157 27.1794 27.8575 28.3423V30.7165C27.4446 29.5734 25.2404 29.3972 24.8428 28.3114L24.8603 25.7522V25.75Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M24.8603 25.75C24.9826 27.1133 27.3157 27.1794 27.8575 28.3423V30.7165C27.4446 29.5734 25.2404 29.3972 24.8428 28.3114L24.8603 25.7522V25.75Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M24.8603 25.75C24.9826 27.1133 27.3157 27.1794 27.8575 28.3423V30.7165C27.4446 29.5734 25.2404 29.3972 24.8428 28.3114L24.8603 25.7522V25.75Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M24.8603 25.75C24.9826 27.1133 27.3157 27.1794 27.8575 28.3423V30.7165C27.4446 29.5734 25.2404 29.3972 24.8428 28.3114L24.8603 25.7522V25.75Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                    <g id="Vector_28">
                      <path
                        d="M31.0905 25.75C30.9682 27.1133 28.635 27.1794 28.0933 28.3423V30.7165C28.5061 29.5734 30.7104 29.3972 31.108 28.3114L31.0905 25.7522V25.75Z"
                        fill="url(#paint21_linear_1299_7279)"
                      />
                      <path
                        d="M31.0905 25.75C30.9682 27.1133 28.635 27.1794 28.0933 28.3423V30.7165C28.5061 29.5734 30.7104 29.3972 31.108 28.3114L31.0905 25.7522V25.75Z"
                        stroke="#25292C"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M31.0905 25.75C30.9682 27.1133 28.635 27.1794 28.0933 28.3423V30.7165C28.5061 29.5734 30.7104 29.3972 31.108 28.3114L31.0905 25.7522V25.75Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M31.0905 25.75C30.9682 27.1133 28.635 27.1794 28.0933 28.3423V30.7165C28.5061 29.5734 30.7104 29.3972 31.108 28.3114L31.0905 25.7522V25.75Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M31.0905 25.75C30.9682 27.1133 28.635 27.1794 28.0933 28.3423V30.7165C28.5061 29.5734 30.7104 29.3972 31.108 28.3114L31.0905 25.7522V25.75Z"
                        stroke="black"
                        strokeOpacity="0.2"
                        strokeWidth="0.15"
                        strokeMiterlimit="10"
                      />
                    </g>
                  </g>
                  <g id="Vector_29">
                    <path
                      d="M27.8989 16.949C27.7045 18.3387 26.4287 18.8805 26.4287 20.1073C26.4287 21.6093 27.2064 21.4309 27.9361 22.7039L27.8989 16.949Z"
                      fill="url(#paint22_linear_1299_7279)"
                    />
                    <path
                      d="M27.8989 16.949C27.7045 18.3387 26.4287 18.8805 26.4287 20.1073C26.4287 21.6093 27.2064 21.4309 27.9361 22.7039L27.8989 16.949Z"
                      stroke="#25292C"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M27.8989 16.949C27.7045 18.3387 26.4287 18.8805 26.4287 20.1073C26.4287 21.6093 27.2064 21.4309 27.9361 22.7039L27.8989 16.949Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M27.8989 16.949C27.7045 18.3387 26.4287 18.8805 26.4287 20.1073C26.4287 21.6093 27.2064 21.4309 27.9361 22.7039L27.8989 16.949Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M27.8989 16.949C27.7045 18.3387 26.4287 18.8805 26.4287 20.1073C26.4287 21.6093 27.2064 21.4309 27.9361 22.7039L27.8989 16.949Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                  </g>
                  <g id="Vector_30">
                    <path
                      d="M28.054 22.7039C28.7312 21.4001 29.557 21.5785 29.498 20.0764C29.45 18.8519 28.2288 18.3299 27.9775 16.9512L28.054 22.7061V22.7039Z"
                      fill="url(#paint23_linear_1299_7279)"
                    />
                    <path
                      d="M28.054 22.7039C28.7312 21.4001 29.557 21.5785 29.498 20.0764C29.45 18.8519 28.2288 18.3299 27.9775 16.9512L28.054 22.7061V22.7039Z"
                      stroke="#25292C"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M28.054 22.7039C28.7312 21.4001 29.557 21.5785 29.498 20.0764C29.45 18.8519 28.2288 18.3299 27.9775 16.9512L28.054 22.7061V22.7039Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M28.054 22.7039C28.7312 21.4001 29.557 21.5785 29.498 20.0764C29.45 18.8519 28.2288 18.3299 27.9775 16.9512L28.054 22.7061V22.7039Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M28.054 22.7039C28.7312 21.4001 29.557 21.5785 29.498 20.0764C29.45 18.8519 28.2288 18.3299 27.9775 16.9512L28.054 22.7061V22.7039Z"
                      stroke="black"
                      strokeOpacity="0.2"
                      strokeWidth="0.15"
                      strokeMiterlimit="10"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1299_7279"
          x1="14.2302"
          y1="18.4443"
          x2="18.8987"
          y2="18.4443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1299_7279"
          x1="6.87687"
          y1="26.8203"
          x2="7.09533"
          y2="26.8203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1299_7279"
          x1="14.2302"
          y1="18.4443"
          x2="18.8987"
          y2="18.4443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1299_7279"
          x1="6.87687"
          y1="22.6445"
          x2="7.09533"
          y2="22.6445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1299_7279"
          x1="16.6817"
          y1="13.8007"
          x2="19.0171"
          y2="13.7995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1299_7279"
          x1="21.417"
          y1="13.7028"
          x2="19.0202"
          y2="13.7991"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1299_7279"
          x1="6.89209"
          y1="22.8274"
          x2="9.90683"
          y2="22.8274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1299_7279"
          x1="6.89209"
          y1="22.8274"
          x2="9.90683"
          y2="22.8274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1299_7279"
          x1="13.1551"
          y1="22.8274"
          x2="10.1404"
          y2="22.8274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1299_7279"
          x1="6.89209"
          y1="25.5385"
          x2="9.90683"
          y2="25.5385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1299_7279"
          x1="13.1551"
          y1="25.5385"
          x2="10.1404"
          y2="25.5385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1299_7279"
          x1="6.89209"
          y1="28.2341"
          x2="9.90683"
          y2="28.2341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1299_7279"
          x1="13.1551"
          y1="28.2341"
          x2="10.1404"
          y2="28.2341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1299_7279"
          x1="8.47711"
          y1="19.8265"
          x2="9.98448"
          y2="19.8257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1299_7279"
          x1="11.5334"
          y1="19.7648"
          x2="9.98797"
          y2="19.8269"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1299_7279"
          x1="24.845"
          y1="22.8274"
          x2="27.8597"
          y2="22.8274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1299_7279"
          x1="24.845"
          y1="22.8274"
          x2="27.8597"
          y2="22.8274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1299_7279"
          x1="31.1058"
          y1="22.8274"
          x2="28.0933"
          y2="22.8274"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1299_7279"
          x1="24.845"
          y1="25.5385"
          x2="27.8597"
          y2="25.5385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1299_7279"
          x1="31.1058"
          y1="25.5385"
          x2="28.0933"
          y2="25.5385"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1299_7279"
          x1="24.845"
          y1="28.2343"
          x2="27.8597"
          y2="28.2343"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1299_7279"
          x1="31.1058"
          y1="28.2343"
          x2="28.0933"
          y2="28.2343"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1299_7279"
          x1="26.4278"
          y1="19.828"
          x2="27.9352"
          y2="19.8272"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1299_7279"
          x1="29.4854"
          y1="19.7633"
          x2="27.9378"
          y2="19.8255"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDD698" />
          <stop offset="1" stopColor="#F7A60F" />
        </linearGradient>
        <clipPath id="clip0_1299_7279">
          <rect width="38" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
