import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { SIGN_FIELD_NAME, validationSchema } from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import MethodologySignForm from "./components/MethodologySignForm";
import { IProps, TForm } from "./types";
import { mapFormToValues } from "./serializers";

/**
 * Модалка с формой создания/редактирования (вложенная)
 * @param initialValues - начальные значения
 * @param onSubmit - callback после успешного запроса
 * */

const AddMethodologySignVariant: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const {
    methodologyId,
    initialValues,
    indicator_methodology,
    variantIndicatorId,
  } = modalProps;

  const baseInitialValues = {
    sign: [
      {
        [SIGN_FIELD_NAME.INDICATOR_METHODOLOGY]:
          indicator_methodology,
        [SIGN_FIELD_NAME.VARIANT]: null,
        [SIGN_FIELD_NAME.VARIETY]: [],
        [SIGN_FIELD_NAME.NON_FIELD_ERRORS]: undefined,
      },
    ],
  };

  const { api, toastStore, swrStore } = useStores();

  const mutateDetail = methodologyId
    ? swrStore.mutators[
        SWR_KEYS.getMethodologyItem(methodologyId)
      ]
    : null;

  const mutateList =
    swrStore.mutators[SWR_KEYS.getMethodologyList()];

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);
    const promise = modalProps.isEdit
      ? api.regulatoryInfo.patchVariantIndicatorMethodology(
          variantIndicatorId,
          {
            indicator_methodology: modalProps.signId,
            variant: values.sign[0].variant?.id,
            variety: values.sign[0].variety?.map(
              ({ id }) => id,
            ),
          },
        )
      : api.regulatoryInfo.postVariantIndicatorMethodology(
          mapFormToValues(values, modalProps.signId),
        );

    promise
      .then(() => {
        mutateDetail && mutateDetail();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors.non_field_errors) {
          const errorMessage = errorToString(error);
          helpers.setFieldError('non_field_errors', errorMessage)
        }
        if (Array.isArray(errors)) {
          const errorMessages = errors.flatMap(obj => obj.non_field_errors);
          const errorMessageString = errorMessages.join(', ');
          helpers.setFieldError('non_field_errors', errorMessageString)
        } 
        else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail();
        mutateList && mutateList();
      });
  };

  const values = initialValues
    ? {
        ...baseInitialValues,
        ...initialValues,
      }
    : baseInitialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <MethodologySignForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      )}
    />
  );
};

export default AddMethodologySignVariant;
