import { styled } from "@mui/material/styles";
import { chipClasses } from "@mui/material";

export const ChipWrap = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  maxWidth: 500,
  [`& .${chipClasses.root}`]: {
    fontWeight: 500
  },
  [`& .${chipClasses.root}> .${chipClasses.deleteIcon}`]: {
    fontSize: 20
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: 400
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: 300
  }
}));
