import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const ProgressActivityIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_6242_3017"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <rect width="20" height="20" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_6242_3017)">
          <path
            d="M9.99996 18.3334C8.86107 18.3334 7.78468 18.1146 6.77079 17.6771C5.7569 17.2396 4.87149 16.6424 4.11454 15.8854C3.3576 15.1285 2.76038 14.2431 2.32288 13.2292C1.88538 12.2153 1.66663 11.1389 1.66663 10C1.66663 8.84724 1.88538 7.76738 2.32288 6.76044C2.76038 5.75349 3.3576 4.87155 4.11454 4.1146C4.87149 3.35766 5.7569 2.76044 6.77079 2.32294C7.78468 1.88544 8.86107 1.66669 9.99996 1.66669C10.2361 1.66669 10.434 1.74655 10.5937 1.90627C10.7534 2.06599 10.8333 2.26391 10.8333 2.50002C10.8333 2.73613 10.7534 2.93405 10.5937 3.09377C10.434 3.25349 10.2361 3.33335 9.99996 3.33335C8.15274 3.33335 6.57982 3.98266 5.28121 5.28127C3.9826 6.57988 3.33329 8.1528 3.33329 10C3.33329 11.8472 3.9826 13.4202 5.28121 14.7188C6.57982 16.0174 8.15274 16.6667 9.99996 16.6667C11.8472 16.6667 13.4201 16.0174 14.7187 14.7188C16.0173 13.4202 16.6666 11.8472 16.6666 10C16.6666 9.76391 16.7465 9.56599 16.9062 9.40627C17.0659 9.24655 17.2638 9.16669 17.5 9.16669C17.7361 9.16669 17.934 9.24655 18.0937 9.40627C18.2534 9.56599 18.3333 9.76391 18.3333 10C18.3333 11.1389 18.1145 12.2153 17.677 13.2292C17.2395 14.2431 16.6423 15.1285 15.8854 15.8854C15.1284 16.6424 14.2465 17.2396 13.2395 17.6771C12.2326 18.1146 11.1527 18.3334 9.99996 18.3334Z"
            fill="#1872CF"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default ProgressActivityIcon;