import React from "react";
import { Formik } from "formik";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import SelectVegetationCauseForm
  from "./components/SelectVegetationCauseForm";
import {
  IFormik,
  initialValues,
  validationSchema,
  valueToForm,
} from "./const";
import {
  IVegetationCauseReq
} from "@/api/interfaces/requests";

/**
 * Компонент модалки выбора удобрения/средства защиты
 * @param props
 * @param props.cultivarId - id плана внебюджета
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    initialValues?: IFormik,
    title: string
    handleSubmit: (payload: IFormik) => void
    q?: IVegetationCauseReq
    label?: string
  };
}

const SelectVegetationCause: React.FC<IProps> = (props) => {
  const handleSubmit = (
    values: IFormik,
  ) => {
    props.modalProps.handleSubmit && props.modalProps.handleSubmit(values);
    props.handleClose();
  };

  const values = props.modalProps?.initialValues ? valueToForm(props.modalProps?.initialValues) : initialValues;

  const title = props.modalProps.title || "Причина браковки/гибели сортоопыта"
  
  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {title}
        </Typography>
      </Box>
      <Divider />
      <Formik<IFormik>
        initialValues={values}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => <SelectVegetationCauseForm
          q={props.modalProps.q}
          label={props.modalProps.label}
        />}
      />
    </Box>
  );
};

export default SelectVegetationCause;
