import {
  ICultivar,
  IOrder
} from "@/api/interfaces/responses";
import yup from "@/utils/yup";
import theme from "@/theme";
import { flower, hub, microbiology } from "@/assets/common";

export const STEP_LABELS = {
  CULTIVAR: "Культура",
  PLOT: "ГСУ",
};

export const ORDER_CULTIVAR_FIELDS_NAME = {
  ORDER: "order",
  CULTIVAR: "cultivar",
  SHARED_APPENDIX: "shared_appendix"
} as const;

export const validationSchema = yup.object().shape({
  [ORDER_CULTIVAR_FIELDS_NAME.ORDER]: yup.mixed().required(),
  [ORDER_CULTIVAR_FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
});

export interface IOrderCultivarForm {
  [ORDER_CULTIVAR_FIELDS_NAME.ORDER]: IOrder | null;
  [ORDER_CULTIVAR_FIELDS_NAME.CULTIVAR]: ICultivar | null;
  [ORDER_CULTIVAR_FIELDS_NAME.SHARED_APPENDIX]: boolean;
}

export type TPlotType = 
  | "hp" 
  | "oos" 
  | "phito";

export const PLOT_TYPES_ICON: {
  [key in TPlotType]: string;
} = {
  hp: flower,
  oos: hub,
  phito: microbiology,
};

export const PLOT_LABEL_TYPES: { [key in TPlotType]: string } = {
  hp: "ХП",
  oos: "ООС",
  phito: "ЭФ",
}

export const PLOT_TEXT_TYPES: { [key in TPlotType]: string } = {
  hp: "Хоз. полезность",
  oos: "ООС",
  phito: "Фитопатология",
}

export const PLOT_TYPES_COLOR: { [key in TPlotType]: string } = {
  hp: theme.palette.green.main,
  oos: theme.palette.another.orange,
  phito: theme.palette.red.main,
}
