import React, { useState, useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { IProps, PLOT_FIELD_NAME } from "../const";
import Autocomplete from "@/components/Autocomplete";
import {
  CustomPaper
} from "../../Employee/components/EmployeeFormSteps/first";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import FastRegexTextField
  from "@/components/FastRegexTextField";
import { PhoneField } from "./phone";
import { EmailField } from "./email";
import AddChip from "@/components/Chips/AddChip";
import { StyledChip } from "../../CreateVariety/styles";
import { resInterface } from "@/api/interfaces";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { StyledBox } from "@/pages/AddField/styles";
import { YearDatePicker } from "@/components/Fields";
import { asyncComponent } from "react-async-component";
import { VIEW_MOD } from "@/apps/Map/const";
import { IPlot } from "@/api/interfaces/responses";

const AsyncMap = asyncComponent({
  resolve: () => import("@/apps/Map"),
  LoadingComponent: () => <CircularProgress/>
});

const PlotFormSecondStep: React.FC<IProps> = ({ modalProps }) => {
  const formik = useFormikContext<any>();
  const { api, modalStore, mapStore } = useStores();
  const [employeeSearch, setEmployeeSearch] = useState("");

  const getKeyEmployee = (index: number) => ({
    _key: SWR_KEYS.getEmployeeList(),
    page: index + 1,
    search: employeeSearch
  });


  const {
    data: dataEmployee,
    handleScroll: handleScrollEmployee
  } = useInfiniteScroll(
    getKeyEmployee,
    api.regulatoryInfo.getEmployeeList,
    {
      revalidateFirstPage: false,
    },
  );

  const getKeyPlot = (index: number) => ({
    _key: "GSU_LIST",
    page: index + 1,
    name: mapStore.GSU.searchValue,
    page_size: 1000,
    map_object_isnull: false
  });

  const {
    data,
  } = useInfiniteScroll<IPlot>(getKeyPlot, api.regulatoryInfo.getPlotList, {
    revalidateFirstPage: true,
    pageSize: 100
  });

  useEffect(() => {
    if(data && !mapStore.markers.length) {
      const markers = data
        .map((el) => ({
          ...el?.map_object,
          title: el.name,
          id: el.id,
          hasLocations: el.has_locations,
        })).filter(el => el.id !== modalProps.plotId)
      mapStore.setMarkers(markers);
    }
  }, [data]);

  const handleChangeEmployee = (event, value) => {
    formik.setFieldValue(PLOT_FIELD_NAME.STAFF_DIRECTOR, value);
  };

  const handleAddLegalAddress = () => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...formik.values
      },
      initialStep: 1
    });
    modalStore.open(VARIANT_MODAL.ADD_ADDRESS, {
      onSubmit: handleSelectLegalAddress
    });
  };

  const handleSelectLegalAddress = (
    payload: resInterface.IAddress,
  ) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...formik.values,
        [PLOT_FIELD_NAME.LEGAL_ADDRESS]: {
          ...payload,
          name: payload.one_line,
        },
        initialStep: 1
      },
    });
  };
  const handleDeleteLegalAddress = () => {
    formik.setFieldValue(PLOT_FIELD_NAME.LEGAL_ADDRESS, null);
  };

  const handleAddActualAddress = () => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...formik.values
      },
      initialStep: 1
    });
    modalStore.open(VARIANT_MODAL.ADD_ADDRESS, {
      onSubmit: handleSelectActualAddress
    });
  };

  const handleSelectActualAddress = (
    payload: resInterface.IAddress,
  ) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...formik.values,
        [PLOT_FIELD_NAME.ACTUAL_ADDRESS]: {
          ...payload,
          name: payload.one_line,
        },
        initialStep: 1
      },
    });
  };

  const handleDeleteActualAddress = () => {
    formik.setFieldValue(
      PLOT_FIELD_NAME.ACTUAL_ADDRESS,
      null,
    );
  };

  useEffect(() => {
    mapStore.setViewMod(VIEW_MOD.DRAW_PLOT);
    mapStore.setIsDrawPoint(true);
  }, []);


  useEffect(() => {
    if (mapStore.tempDrawPoint) {
      void formik.setFieldValue(PLOT_FIELD_NAME.LAT, mapStore.tempDrawPoint.lat);
      void formik.setFieldValue(PLOT_FIELD_NAME.LNG, mapStore.tempDrawPoint.lng);
    }
  }, [mapStore.tempDrawPoint]);

  useEffect(() => {
    if (formik.values.lng && formik.values.lat) {
      mapStore.setTempPoint({
        lat: formik.values.lat,
        lng: formik.values.lng
      });
    }
  }, [formik.values.lng, formik.values.lat]);

  return (
    <Stack direction="column" gap={2}>
      <Field name={PLOT_FIELD_NAME.STAFF_DIRECTOR}>
        {({ field, meta }) => (
          <FormControl size="small" variant="outlined"
                       fullWidth>
            <Autocomplete
              {...field}
              label="Руководитель"
              PaperComponent={CustomPaper}
              onChange={handleChangeEmployee}
              data={dataEmployee}
              getLabel={(item) => item.full_name}
              handleScroll={handleScrollEmployee}
              search={setEmployeeSearch}
              error={meta.touched && !!meta.error}
            />
          </FormControl>
        )}
      </Field>
      <Stack direction="row" gap="20px" mb="20px">
        <Grid container spacing="20px">
          <Grid item sm={6}>
            <FastRegexTextField
              regexType="number"
              name={PLOT_FIELD_NAME.STAFFING}
              label="Штатная численность"
            />
          </Grid>
          <Grid item sm={6}>
            <FastRegexTextField
              regexType="number"
              name={PLOT_FIELD_NAME.VACANCIES}
              label="Ваканты"
            />
          </Grid>
        </Grid>
      </Stack>
      <Box>
        <Typography fontWeight={500}>Телефоны</Typography>
        <PhoneField/>
      </Box>
      <Box>
        <Typography fontWeight={500}>Электронные
          почты</Typography>
        <EmailField/>
      </Box>
      <Field name={PLOT_FIELD_NAME.LEGAL_ADDRESS}>
        {({ field, meta }) => (
          <FormControl required>
            <FormLabel>Юридический адрес</FormLabel>
            <Box>
              {field.value ? (
                <StyledChip
                  size="small"
                  variant="outlined"
                  label={field.value.name}
                  onDelete={handleDeleteLegalAddress}
                />
              ) : (
                <AddChip onClick={handleAddLegalAddress}/>
              )}
            </Box>
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={PLOT_FIELD_NAME.ACTUAL_ADDRESS}>
        {({ field, meta }) => (
          <FormControl required>
            <FormLabel>Фактический адрес</FormLabel>
            <Box>
              {field.value ? (
                <StyledChip
                  size="small"
                  variant="outlined"
                  label={field.value.name}
                  onDelete={handleDeleteActualAddress}
                />
              ) : (
                <AddChip onClick={handleAddActualAddress}/>
              )}
            </Box>
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Stack direction="column" gap="20px">
        <Typography fontWeight={500}>Координаты</Typography>
        <Stack direction="row" alignItems="center">
          <StyledBox>
            <Field name={PLOT_FIELD_NAME.LAT}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  type="number"
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                  label={`Координата - широта`}
                  fullWidth
                  size="small"
                />
              )}
            </Field>
            <Field name={PLOT_FIELD_NAME.LNG}>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  type="number"
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                  label={`Координата - долгота`}
                  fullWidth
                  size="small"
                />
              )}
            </Field>
          </StyledBox>
        </Stack>
      </Stack>
      <Box height="300px" width="100%" position="relative">
        <AsyncMap/>
      </Box>
      <YearDatePicker
        name={PLOT_FIELD_NAME.YEAR_CLOSED}
        label="Год закрытия"
        fullWidth
      />
    </Stack>
  );
};

export default observer(PlotFormSecondStep);
