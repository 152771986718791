import {
    FiltersType
  } from "@/components/Filters/interfaces";
  import { IFilter } from "@/interfaces";
  
  interface IFilterWithParams extends IFilter {
    handleSelectChange?: (event: any) => void;
    selectedType?: string;
    selectItems?: React.JSX.Element[];
    handleClearSelect?: () => void;
  }
  
  export const getFilters = (
    selectedYear?: any,
    handleSelectChange?: (event: any) => void,
    handleClearSelect?: () => void,
    selectItems?: React.JSX.Element[],
    selectedType?: string,

  ): IFilterWithParams[] => {
    return [
      {
        name: "type",
        label: "Вид испытания",
        type: FiltersType.select,
        selectedValue: selectedType,
        value: "",
        selectItems: selectItems,
        handleSelectChange: handleSelectChange,
        handleClearSelect: handleClearSelect,
      },
      {
        name: "year",
        label: "Год испытания",
        type: FiltersType.yearPicker,
        selectedValue: selectedYear,
        value: "",
        style: { minWidth: "230px", flexGrow: 1 },
      },
    ];
  };