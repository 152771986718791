import React, { useEffect } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useStores } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { useFormikContext } from "formik";
import { IPlanForm, PLAN_FIELDS_NAME } from "../../const";
import theme from "@/theme";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EmptyList from "@/pages/AddPlanningHp/components/EmptyList";
import EditMenu from "@/pages/AddPlanningHp/components/EditMenu";
import { IIndicator } from "@/api/interfaces/responses";

const IndicatorFormStep = () => {
  const formik = useFormikContext<IPlanForm>();
  const { modalStore } = useStores();

  useEffect(() => {
    formik.setFieldValue(
      PLAN_FIELDS_NAME.INDICATOR_METHODOLOGY,
      [],
    );
  }, [formik.values.cultivar]);

  const handleAccept = (payload: IIndicator) => {
    void formik.setFieldValue(
      PLAN_FIELDS_NAME.INDICATOR_METHODOLOGY,
      [...formik.values.indicator_methodology, payload],
    );
  };

  const handleAddSign = () => {
    modalStore.open(
      VARIANT_MODAL.SELECT_METHODOLOGY_INDICATOR,
      {
        cultivarName: formik.values.cultivar?.label,
        cultivarId: formik.values.cultivar?.id,
        handleAccept: handleAccept,
      },
    );
  };

  const handleDelete = (id: number) => {
    const values =
      formik.values.indicator_methodology.filter(
        (el) => el.id !== id,
      );
    void formik.setFieldValue(
      PLAN_FIELDS_NAME.INDICATOR_METHODOLOGY,
      values,
    );
  };

  if (formik.values.indicator_methodology.length === 0) {
    return <EmptyList handleAdd={handleAddSign} />;
  }

  return (
    <Stack spacing="20px">
      <Typography variant="h3">Признаки</Typography>
      <List
        sx={{ display: "flex", flexDirection: "column" }}
      >
        {formik.values.indicator_methodology.map(
          (indicator) => (
            <ListItem
              key={indicator.id}
              sx={{
                display: "flex",
                gap: 2,
                borderBottom: "1px solid black",
                borderColor:
                  theme.palette.blackAndWhite.stroke,
              }}
            >
              <Box sx={{ flex: 1, maxWidth: 170 }}>
                <Tooltip title={indicator.name}>
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {indicator.name}
                  </Typography>
                </Tooltip>
              </Box>
              <Box sx={{ flex: 1, minWidth: 0 }}>
                <Tooltip
                  title={indicator.variant_indicators
                    .map((i) => i.variant.severity)
                    .join(", ")}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {indicator.variant_indicators
                      .map((i) => i.variant.severity)
                      .join(", ")}
                  </Typography>
                </Tooltip>
              </Box>
              <EditMenu
                handleDelete={() =>
                  handleDelete(indicator.id)
                }
              />
            </ListItem>
          ),
        )}
      </List>
      <Button
        sx={{
          marginTop: "24px",
          marginBottom: "24px",
          width: "100%",
        }}
        color={"blue"}
        size={"large"}
        startIcon={<AddOutlinedIcon />}
        variant={"outlined"}
        onClick={handleAddSign}
      >
        Добавить признак
      </Button>
    </Stack>
  );
};

export default IndicatorFormStep;
