import { useLayoutEffect, useState } from 'react'

const queries = [
  '(max-width: 600px)',
  '(max-width: 767.9px)',
  '(max-width: 900px)',
  '(max-width: 1023.9px)',
  '(min-width: 1024px)'
]

interface IUseMatchMediaReturn {
  isMobile: boolean
  isSmallTablet: boolean
  isTablet: boolean
  isDesktop: boolean
  isBigDesktop: boolean
}

export const useMatchMedia = (): IUseMatchMediaReturn => {
  const mediaQueryLists: MediaQueryList[] = queries.map((query) =>
    matchMedia(query)
  )

  const getValues = (): boolean[] => mediaQueryLists.map((list) => list.matches)

  const [values, setValues] = useState(getValues)

  useLayoutEffect(() => {
    const handler = (): void => setValues(getValues)

    mediaQueryLists.forEach((list) => list.addEventListener("change", handler))

    return (): void =>
      mediaQueryLists.forEach((list) =>
        list.removeEventListener("change", handler)
      )
  })

  return [
    "isMobile",
    "isSmallTablet",
    "isTablet",
    "isDesktop",
    "isBigDesktop",
  ].reduce(
    (acc, screen, index) => ({
      ...acc,
      [screen]: values[index],
    }),
    {}
  ) as {
    isMobile: boolean
    isSmallTablet: boolean
    isTablet: boolean
    isDesktop: boolean
    isBigDesktop: boolean
  }
}
