import { IColumn } from "@/apps/Table/interfaces";
import { TExpenseKey } from "../interfaces";
import { EXPENSE_KEYS, EXPENSE_LABELS, } from "../const";
import {cellButtonsMaterialType,cellPointText, headerCellStyle } from "../style";
import { PointerTextCell } from "../cells";
import { EditingCellsExpense } from "../templates/Expense/cells";

export const COLUMN_EDITING = {
    value: "editing",
    label: "",
};

export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const ExpenseColumns: IColumn<TExpenseKey>[] = [
    {
        label: EXPENSE_LABELS.name,
        key: EXPENSE_KEYS.name,
        contentHeader: EXPENSE_LABELS.name,
        bodyCellProps: {
            render: PointerTextCell,
            styles: cellPointText,
        },
        headerCellProps: {
            styles: headerCellStyle,
        },
    },
    {
        key: "editing",
        label: EXPENSE_LABELS.editing,
        contentHeader: COLUMN_EDITING.label,
        bodyCellProps: {
          render: EditingCellsExpense,
          styles: cellButtonsMaterialType,
        },
    },
];