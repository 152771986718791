import React, { useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import { useInfiniteScroll, useStores } from "@/hooks";
import { useTheme } from "@mui/material/styles";
import {

  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import { DISTRICT_FIELD_NAME, IProps } from "../const";
import Autocomplete from "@/components/Autocomplete";
// import { CustomPaper } from "../../Employee/components/EmployeeFormSteps/first";
import { SWR_KEYS } from "@/const";

/**
 * Форма создания/редактирования сорта
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * @param modalProps.key - ключ модалки
 * @param modalProps.submitOnMount - сабмит после монтирования
 * */

const DistrictForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore } = useStores();
  const { api } = useStores();
  const theme = useTheme();
  const formik = useFormikContext<any>();
  const [searchBranch, setSearchBranch] = useState("");
  const [searchLightZone, setSearchLightZone] = useState("");
  const swrKeyBranch = SWR_KEYS.getBranchList();
  const swrKeyLightZone = SWR_KEYS.getLightZones();


  const getKeyBranch = (index: number) => ({
    _key: swrKeyBranch,
    page: index + 1,
    search: searchBranch
  });
  const getKeyLightZone = (index: number) => ({
    _key: swrKeyLightZone,
    page: index + 1,
    search: searchLightZone
  });

  const branchFetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getBranchList({
      page,
      search,
    });
  };
  const lightZoneFetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getAllLightZoneList({
      page,
      search,
    });
  };

  const {
    data: dataBranch,
    handleScroll: handleScrollBranch,
  } = useInfiniteScroll(getKeyBranch, branchFetcher, {
    revalidateFirstPage: false,
  });

  const handleChangeBranch = (event, value) => {
    formik.setFieldValue(DISTRICT_FIELD_NAME.BRANCH, value);
  }

  const {
    data: dataLightZone,
    handleScroll: handleScrollLightZone,
  } = useInfiniteScroll(getKeyLightZone, lightZoneFetcher, {
    revalidateFirstPage: false,
  });

  const handleChangeLightZone = (event, value) => {
    formik.setFieldValue(DISTRICT_FIELD_NAME.LIGHT_ZONE, value);
  }

  const seriaLizeLightZoneData = dataLightZone.map(
    item => ({ name: item.rome_number, id: item.id })
  )


  const title = modalProps.districtId
    ? "Редактировать запись"
    : "Добавить запись";

  return (
    <Form noValidate>
      <Box>
        <Box p={3}>
          <Typography fontSize={12} color={theme.palette.blackAndWhite.gray}>
            Регион РФ
          </Typography>
          <Typography variant='h2'>{title}</Typography>
        </Box>
        <Divider />
        <Stack direction={"column"} gap={2} p={2}>
          <Field name={DISTRICT_FIELD_NAME.NAME}>
            {({
              field,
              meta
            }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                  label="Название"
                  required
                  fullWidth
                  size="small"
                />
              </FormControl>
            )}
          </Field>
          <Field name={DISTRICT_FIELD_NAME.FULL_NAME}>
            {({ field, meta }) => (
              <FormControl fullWidth required>
                <TextField
                  {...field}
                  label={"Полное название"}
                  inputProps={{ maxLength: 100 }}
                  fullWidth
                  required
                  onBlur={field.handleBlur}
                  disabled={meta.isSubmitting}
                  size="small"
                  error={meta.touched && !!meta.error}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={DISTRICT_FIELD_NAME.BRANCH}>
            {({ field, meta }) => (
              <FormControl required>
                <Autocomplete
                  {...field}
                  onChange={handleChangeBranch}
                  label={"Филиал"}
                  required
                  data={dataBranch}
                  handleScroll={handleScrollBranch}
                  search={setSearchBranch}
                  error={meta.touched && !!meta.error}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={DISTRICT_FIELD_NAME.POSTAL_NAME}>
            {({ field, meta }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  label={"Название для почты"}
                  inputProps={{ maxLength: 100 }}
                  fullWidth
                  size="small"
                  error={meta.touched && !!meta.error}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={DISTRICT_FIELD_NAME.REGIONAL_CAPITAL}>
            {({ field, meta }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  label={"Столица"}
                  inputProps={{ maxLength: 100 }}
                  fullWidth
                  size="small"
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={DISTRICT_FIELD_NAME.LIGHT_ZONE}>
            {({ field, meta }) => (
              <FormControl>
                <Autocomplete
                  {...field}
                  onChange={handleChangeLightZone}
                  label={"Световая зона"}
                  data={seriaLizeLightZoneData}
                  handleScroll={handleScrollLightZone}
                  search={setSearchLightZone}
                  error={meta.touched && !!meta.error}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={DISTRICT_FIELD_NAME.ISO}>
            {({ field, meta }) => (
              <FormControl fullWidth>
                <TextField
                  {...field}
                  label={"ISO"}
                  inputProps={{ maxLength: 100 }}
                  fullWidth
                  size="small"
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          </Stack>
        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red' onClick={() => modalStore.close()}>
            отмена
          </Button>
          <Button
            type="submit"
            disabled={formik.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default DistrictForm;
