import React from "react";
import { PAGE_SIZE } from "@/const";
import { useStores } from "@/hooks";
import { resInterface } from "@/api/interfaces";
import { AddEntity } from "@/apps/Modals/ModalContent";
import { IProps } from ".";

export const SelectUserSign: React.FC<IProps> = ({
  handleClose,
  modalProps
}) => {
  const { modalStore, api } = useStores();

  const openModalSign = (value: resInterface.IUserShort) => {
    modalStore.pop();
    modalProps.onAcceptModal(value);
  };

  const handleAccept = (value: resInterface.IUserShort): any => {
    openModalSign(value);
  };

  const fetcher = (args: { page: any; search: any }): any => {
    const { page, search } = args;
    return api.user.getUsers({
      page,
      page_size: PAGE_SIZE,
      search
    });
  };

  const props = {
    key: "GET_USERS",
    handleClose,
    title: "Эксперты",
    handleAccept,
    getLabel: (el: resInterface.IUserShort) => el.full_name,
    fetcher,
    ...modalProps
  };

  return (
    <AddEntity<resInterface.IUserShort>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};
