import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import {
  cellButtons,
  cellPointText,
  headerCellStyle,
} from "../style";

import {
  LIGHT_ZONE_HEADER_LABELS,
  LIGHT_ZONE_KEYS,
} from "../const";

import { TLightZoneKeys } from "../interfaces";
import { EditingCellsLightZone } from "../templates/LigthZone/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const LightZoneColumns: IColumn<TLightZoneKeys>[] = [
  {
    label: LIGHT_ZONE_HEADER_LABELS.number,
    key: LIGHT_ZONE_KEYS.number,
    contentHeader: LIGHT_ZONE_HEADER_LABELS.number,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: LIGHT_ZONE_HEADER_LABELS.rome_number,
    key: LIGHT_ZONE_KEYS.rome_number,
    contentHeader: LIGHT_ZONE_HEADER_LABELS.rome_number,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
    isSorting: false,
  },
  {
    label: LIGHT_ZONE_HEADER_LABELS.comment,
    key: LIGHT_ZONE_KEYS.comment,
    contentHeader: LIGHT_ZONE_HEADER_LABELS.comment,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
    isSorting: false,
  },
  {
    key: "editing",
    label: LIGHT_ZONE_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsLightZone,
      styles: cellButtons,
    },
  },
];
