import yup from "@/utils/yup";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    regionId?: number;
    initialValues?: {
      name?: string;
      number?: number;
      colour?: string;
    };
  };
  onSubmit?: () => void;
}

export enum FIELD_NAME {
  NAME = "name",
  NUMBER = "number",
  COLOUR ='colour'
}

export const validationSchema = yup.object().shape({
  [FIELD_NAME.NAME]: yup
    .string()
    .required()
    .trim()
    .notOneOf([""], "Имя не может быть пустым"),

  [FIELD_NAME.NUMBER]: yup
    .number()
    .required()
    .typeError("Введите число"),
    [FIELD_NAME.COLOUR]: yup.string().required(),
});
