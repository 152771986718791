import React from "react";
import { Box, Typography } from "@mui/material";

/**
 * хедер для страницы аггрокультуры
 * @returns React.FC
 */
const HeaderEmpty: React.FC = () => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="h3" noWrap>
        empty
      </Typography>
    </Box>
  );
};

export default HeaderEmpty;
