import React, { useState } from "react";
import { Field, FastField, useFormikContext } from "formik";
import {
  useTheme,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
  TextField,
  Box,
  Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useParams } from "react-router-dom";

import Autocomplete from "@/components/Autocomplete";
import FileDropzone from "@/components/FileDropzone";
import FormAccordion from "@/components/Accordions/FormAccordion";
import { PLAN_FIELDS_NAME } from "@/pages/AddPlan/const";
import { SWR_KEYS } from "@/const";
import {
  IFile,
  FILE_AND_IMAGE_ACCEPT,
  FILE_AND_IMAGE_SUBTITLE,
} from "@/components/FileDropzone/const";
import { useStores, useInfiniteScroll } from "@/hooks";
import { IOffBudget } from "@/api/interfaces/requests";
import { accordionStyles } from "@/pages/AddStatement/components/DocumentsStep/styles";

/**
 * Компонент для шага План
 * */

const PlanFormStep: React.FC = () => {
  const theme = useTheme();
  const { api } = useStores();
  const { id } = useParams();
  const formik = useFormikContext<IOffBudget>();
  const { values, setFieldValue } = formik;
  const [searchRegion, setSearchRegion] = useState("");
  const [searchBranch, setSearchBranch] = useState("");

  const swrKeyRegions = SWR_KEYS.getRegionsList();
  const swrKeyBranch = SWR_KEYS.getPlanBranchesList();

  const getParametersRegions = (index: number) => ({
    _key: swrKeyRegions,
    page: index + 1,
    search: searchRegion,
  });

  const fetcherRegion = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getRegionList({
      page,
      search,
    });
  };

  const {
    data: dataRegions,
    handleScroll: handleScrollRegions,
  } = useInfiniteScroll(
    getParametersRegions,
    fetcherRegion,
    {
      revalidateFirstPage: false,
    },
  );

  const getParametersBranch = (index: number) => ({
    _key: swrKeyBranch,
    page: index + 1,
    region: values[PLAN_FIELDS_NAME.REGION]?.id,
    search: searchBranch,
  });

  const fetcherBranch = (args) => {
    const { page, search, region } = args;
    return api.regulatoryInfo.getPlanBranchesList({
      page,
      search,
      region,
    });
  };

  const {
    data: dataBranch,
    handleScroll: handleScrollBranch,
  } = useInfiniteScroll(
    getParametersBranch,
    fetcherBranch,
    {
      revalidateFirstPage: false,
    },
  );

  const fetcherFile = (fd: FormData) =>
    api.offBudget.addOffBudgetFileById(id as string, fd);

  const deleteMutation = (fileId: number) =>
    api.offBudget.deleteOffBudgetFile(id as string, fileId);

  /**
   * Метод для изменения Года плана
   * @param value
   */

  const handleChangeDate = (value: Date) => {
    setFieldValue(PLAN_FIELDS_NAME.YEAR, value);
  };

  /**
   * Метод для изменения названия плана
   * @param event
   */

  const handleChange = (event) => {
    setFieldValue(event.target.name, event.target.value);
  };

  /**
   * Метод для изменения Региона плана
   * @param event
   * @param value
   */

  const handleChangeRegion = (event, value) => {
    setFieldValue(PLAN_FIELDS_NAME.REGION, value);
    setFieldValue(PLAN_FIELDS_NAME.BRANCH, undefined);
  };

  /**
   * Метод для изменения Филиала плана
   * @param event
   * @param value
   */

  const handleChangeBranch = (event, child) => {
    setFieldValue(PLAN_FIELDS_NAME.BRANCH, child);
  };

  /**
   * Метод для изменения документов плана
   * @param event
   */

  const handleChangeFiles = (files: IFile[]) => {
    setFieldValue(PLAN_FIELDS_NAME.FILE_LIST, files);
  };

  return (
    <Stack spacing={3}>
      <Typography variant="h3">Данные плана</Typography>
      <Stack direction="column" spacing="20px">
        <FastField name={PLAN_FIELDS_NAME.YEAR}>
          {({ field, meta }) => (
            <DatePicker
              name={field.name}
              onChange={handleChangeDate}
              value={field.value}
              label="Год плана"
              openTo="year"
              views={["year"]}
              slotProps={{
                textField: {
                  size: "small",
                  required: true,
                  error: meta.touched && !!meta.error,
                  helperText: meta.touched && meta.error,
                  onBlur: field.onBlur,
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      width: "100%",
                      maxWidth: "300px",
                    },
                  },
                },
              }}
            />
          )}
        </FastField>
        <Field name={PLAN_FIELDS_NAME.REGION}>
          {({ field, meta }) => (
            <FormControl required>
              <Autocomplete
                {...field}
                onChange={handleChangeRegion}
                label="Регион"
                data={dataRegions}
                required
                handleScroll={handleScrollRegions}
                search={setSearchRegion}
                error={meta.touched && !!meta.error}
              />

              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field name={PLAN_FIELDS_NAME.BRANCH}>
          {({ field, meta }) => (
            <FormControl required>
              <Autocomplete
                {...field}
                onChange={handleChangeBranch}
                label="Филиал"
                data={dataBranch}
                required
                handleScroll={handleScrollBranch}
                disabled={!values[PLAN_FIELDS_NAME.REGION]}
                search={setSearchBranch}
                error={meta.touched && !!meta.error}
              />

              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field name={PLAN_FIELDS_NAME.NAME}>
          {({ field, meta }) => (
            <TextField
              {...field}
              onChange={handleChange}
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              label="Название плана"
              required
              fullWidth
              size="small"
            />
          )}
        </Field>
      </Stack>
      <FormAccordion
        label="Документы"
        sx={accordionStyles(theme)}
      >
        <Box mb={1}>
          <Divider />
        </Box>
        {id ? (
          <FileDropzone
            name={PLAN_FIELDS_NAME.FILE_LIST}
            value={values[PLAN_FIELDS_NAME.FILE_LIST]}
            fetcher={fetcherFile}
            deleteMutation={deleteMutation}
            accept={FILE_AND_IMAGE_ACCEPT}
            subtitle={FILE_AND_IMAGE_SUBTITLE}
          />
        ) : (
          <FileDropzone
            name={PLAN_FIELDS_NAME.FILE_LIST}
            value={values[PLAN_FIELDS_NAME.FILE_LIST]}
            setValue={handleChangeFiles}
            accept={FILE_AND_IMAGE_ACCEPT}
            subtitle={FILE_AND_IMAGE_SUBTITLE}
          />
        )}
      </FormAccordion>
    </Stack>
  );
};
export default PlanFormStep;
