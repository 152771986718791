import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Divider, IconButton, Menu, Typography } from "@mui/material";
import { DeleteIcon, EditIcon } from "@/components/icons";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { useStores } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { observer } from "mobx-react";
import { SWR_KEYS } from "@/const";
import { resInterface } from "@/api/interfaces";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

type TProp = {
  id: number;
  comPlanFactId: number;
  item: resInterface.IInvestmentList;
};

const ActiveButtons: React.FC<TProp> = ({
  id,
  comPlanFactId,
  item,
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { api, modalStore, swrStore, userStore } = useStores();
  
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (event !== null) setIsOpen((prev) => !prev);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  const handleDelete = (resolve, reject) => {
    api.offBudget
      .deleteInvestment(id)
      .then(resolve)
      .catch(reject)
      .finally(() => onSubmitCreation());
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, {
      id: id,
      type: CELL_TYPES.offbudget,
      planFact: 'plan',
      handleDelete: handleDelete,
    });
  };

  const onSubmitCreation = () => {
    swrStore.mutators[SWR_KEYS.getInvestmentList(comPlanFactId)]();
  };

  const handleDeleteClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    handleClose(e);
    handleClickDelete(e);
  };

  const handleEdit = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    handleClose(e);

    modalStore.open(VARIANT_MODAL.ADD_INVESTMENT, {
      id: id,
      com_plan_fact: comPlanFactId,
      onSubmit: onSubmitCreation,
      title: "Редактировать инвестиции",
      isEdit: true,
      initialValues: {
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      },
    });
  };

   
  const canEditInvestment = userStore.isPermission(
    PERMISSIONS.extrabudgetaryComInvestment,
    PERMISSION_CRUD.change
  )
  const canDeleteInvestment = userStore.isPermission(
    PERMISSIONS.extrabudgetaryComInvestment,
    PERMISSION_CRUD.delete
  )

  if(!canDeleteInvestment && !canEditInvestment) {
    return <></>
  }

  return (
    <Box>
      <IconButton onClick={handleToggle}>
        <MoreVertIcon sx={{ fontSize: 20 }} />
      </IconButton>

      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        {canEditInvestment && 
        <StyledMenuItem onClick={handleEdit}>
          <EditIcon />
          Редактировать
        </StyledMenuItem>
        }

       {canDeleteInvestment && canEditInvestment && <Divider />}
        {canDeleteInvestment && 
        <StyledMenuItem onClick={handleDeleteClick}>
          <DeleteIcon color="error" />

          <Typography color="error"> Удалить</Typography>
        </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};

export default observer(ActiveButtons);
