import React, { useEffect, useState, useMemo } from "react";
import { Box, List, Typography } from "@mui/material";
import {
  MODULES,
  PERMISSIONS_MAPPING
} from "@/pages/Handbook/fixtures";
import theme from "@/theme";

import {
  ListItemTextStyled,
  ListItemStyled
} from "./styles";
import { useRightSidebar, useStores } from "@/hooks";
import { useSearchParams } from "react-router-dom";
import { PERMISSION_CRUD } from "@/premissions";

interface IThreeModulesProps {
  searchValue: string;
}

const ThreeModules: React.FC<IThreeModulesProps> = ({ searchValue }) => {
  const [, setSearchParams] = useSearchParams();
  const {
    queryStringSidebarCollector,
    userStore
  } = useStores();
  const [activeModule, setActiveModule] = useState(
    (Number(queryStringSidebarCollector.getModuleFromHandbook()) as unknown as number) ??
    MODULES.addres.id,
  );
  const [filteredModules, setFilteredModules] = useState(
    Object.values(MODULES),
  );

  // закрытие правого сайдбара при переходе на другую страницу
  const { handleCloseInstantly } = useRightSidebar();

  // закрытие правого сайдбара с очисткой параметров
  const handleCloseWithCleanQuery = (id: number) => {
    handleCloseInstantly();
    const params = new URLSearchParams();
    params.set("module", String(id));
    setSearchParams(params);
  };

  const handleClick = (module) => {
    handleCloseWithCleanQuery(module.id);
  };

  const sortedModules = useMemo(() => filteredModules.sort((a, b) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  }), [filteredModules])

  useEffect(() => {
    setActiveModule(Number(queryStringSidebarCollector.getModuleFromHandbook()) as unknown as number);
  }, [location.search])

  useEffect(() => {
    if (searchValue) {
      const newModules = Object.values(MODULES).filter((i) =>
        i.title.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setFilteredModules(newModules);
    } else {
      setFilteredModules(Object.values(MODULES));
    }
  }, [searchValue]);

  useEffect(() => {
    Object.keys(MODULES).forEach((key) => {
      const module = MODULES[key];
      const permission = PERMISSIONS_MAPPING[module.module];
      if (permission) {
        module.canView = userStore.isPermission(permission, PERMISSION_CRUD.view);
      }
    });
    setFilteredModules(Object.values(MODULES));
  }, [userStore]);

  return (
    <Box
      height={"calc(100% - 70px)"}
      width={"100%"}
      top={"70px"}
      sx={{
        borderRight: `1px solid ${theme.palette.blackAndWhite.stroke}`,
        overflowY: "scroll"
      }}
    >
      <List >
        {sortedModules.map((module) => {
          if (!module.canView) return null;

          const isActive = activeModule === module.id;

          return (
            <ListItemStyled key={module.id}>
              <ListItemTextStyled
                active={isActive ? "true" : ""}
                onClick={() => handleClick(module)}
              >
                <Typography 
                  fontSize={"14px"}
                  fontWeight={"600"}>
                  {module.title}
                </Typography>
              </ListItemTextStyled>
            </ListItemStyled>
          );
        })}
      </List>
    </Box>
  );
};

export default ThreeModules;
