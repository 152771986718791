import { SxProps } from "@mui/material";

/**
 * Функция для переопределения стилей Box.
 * @returns {SxProps} - Объект стилей для применения к FormControl.
 */

export const filtersListStyle = (): SxProps => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: "16px",
  height: "100%",
});

