import React from "react";
import { useNavigate } from "react-router-dom";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import {
  PAGE_SIZE,
  SWR_KEYS,
  MACHINERY_LABEL
} from "@/const";
import { resInterface } from "@/api/interfaces";
import {
  IAgriculturalMachinery,
} from "@/api/interfaces/responses";
import {
  PERMISSION_CRUD,
  PERMISSIONS
} from "@/premissions";
import { THEME_MODAL, VARIANT_MODAL } from "../../const";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    initialValue?: IAgriculturalMachinery[];
    onSubmit?: (value: IAgriculturalMachinery[]) => void;
  };
}

const SelectMachinery: React.FC<IProps> = ({
  handleClose,
  modalProps
}) => {
  const { api, userStore, rightSidebarStore, modalStore } = useStores();
  const navigate = useNavigate();

  const handleAccept = (value: IAgriculturalMachinery[]) => {
    modalProps.onSubmit && modalProps.onSubmit(value);
    handleClose();
  };

  const fetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getAgrCultMachineryList({
      page,
      page_size: PAGE_SIZE,
      search,
    });
  };

  const handleAddButton = () => {
    navigate(`${ROUTES_PATHS.handbook}?module=28`)
    rightSidebarStore.close()
    modalStore.close()
    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.AGRICULTURAL_MACHINERY, {
      theme: THEME_MODAL.W_555
    });
  }

  const getLabel = (value: IAgriculturalMachinery): string => value.identification_number;

  const getDescription = (value: IAgriculturalMachinery): string => value.name
  const getMeta = (value: IAgriculturalMachinery): string => MACHINERY_LABEL[value.type] || "-";

  const isAdd = userStore.isPermission(
    PERMISSIONS.regulatory_infoAgriculturalmachinery,
    PERMISSION_CRUD.add
  );

  const props = {
    ...modalProps,
    key: [SWR_KEYS.getAgriculturalMachinery()],
    title: "Сельхозтехника",
    addButtonLabel: "Добавить",
    isDisabledBtnAdd: isAdd,
    tooltipText: "У вас недостаточно прав для добавления cельхозтехники",
    handleAccept,
    fetcher,
    getLabel: getLabel,
    getMeta: getMeta,
    handleAdd: handleAddButton,
    getDescription: getDescription
  };

  return (
    <AddEntities<resInterface.IAgriculturalMachinery>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default SelectMachinery;
