import yup from "@/utils/yup";
import { IEntity } from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  TRIAL: "trial",
  STANDARD: "standard",
} as const;

interface IValue extends IEntity {
  label :string;
}

export interface IFormik {
  [FIELDS_NAME.TRIAL]: IValue | null,
  [FIELDS_NAME.STANDARD]: IValue | null,
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.TRIAL]: yup.mixed().required(),
  [FIELDS_NAME.STANDARD]: yup.mixed().nullable()
});

export const initialValues = {
  [FIELDS_NAME.STANDARD]: null,
  [FIELDS_NAME.TRIAL]: null,
};

export const valueToForm = (payload: Partial<IFormik>) => ({
  [FIELDS_NAME.TRIAL]: payload.trial ? {
    ...payload.trial,
    label: payload.trial.name
  } : null,
  [FIELDS_NAME.STANDARD]: payload.standard ? {
    ...payload.standard,
    label: payload.standard.name
  } : null,
});