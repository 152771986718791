import React, { useLayoutEffect } from "react";
import {
  Formik,
  FormikHelpers,
  FormikValues
} from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import {
  IFormik,
  initialValues,
  serializeFormToValues,
  validationSchema,
  serializeValues
} from "./const";
import { useStores } from "@/hooks";
import TechMapForm from "./components/TechMapForm";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { errorToString, pickFrom } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import useSWR from "swr";
import {
  SWR_KEYS,
  KEYS_SIDEBAR_VARIANT_MODULES,
  LOCATION_KEY,
  HANDBOOK_NAME
} from "@/const";
import Loader from "@/components/Loader";
import { useLocation } from "react-router";
import qs from "qs";

/**
 * Компонент реализующий форму добавления/редактирования плана использования земли
 */
const AddTechMap = () => {
  const {
    headerStore,
    api,
    toastStore, queryStringSidebarCollector,
  } = useStores();

  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const fetcher = ([, id]) => {
    if (!id) return null;
    return api.agriculturalLabour.getTechMapPlanById(id);
  };

  const {
    data,
    isLoading, mutate
  } = useSWR([SWR_KEYS.getTechMapPlanList(), id], fetcher);

  const handleBack = (id?: number | string) => {
    if (id) {
      const { str } = queryStringSidebarCollector.setup({
        tab: undefined,
        keyContent: KEYS_SIDEBAR_VARIANT_MODULES.TECH_MAP,
        module_id: id
      });
      const params = qs.parse(str)
      params.module = HANDBOOK_NAME.TЕCHNOLOGICAL_MAPS

      navigate({
        pathname: `${ROUTES_PATHS.handbook}`,
        search: qs.stringify(params)
      });
    } else {
      if (location.key !== LOCATION_KEY.DEFAULT) {
        navigate(-1);
      } else {
        navigate({
          pathname: `${ROUTES_PATHS.handbook}`,
          search: `?module=${HANDBOOK_NAME.TЕCHNOLOGICAL_MAPS}`,
        });
      }
    }
  };

  useLayoutEffect(() => {
    headerStore.setProps({
      title: id ? "Редактировать запись" : "Добавить запись",
      handleBack: () => handleBack(id),
    });
    headerStore.setHeader(VARIANT_HEADER.DEFAULT);
  }, []);

  const showToast = (error: AxiosError) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {

    const data = serializeFormToValues(values);
    if (id) {
       delete data.works;
    }
    const promise = id
      ? api.agriculturalLabour.patchTechMapPlanById(id, data)
      : api.agriculturalLabour.postTechMapPlan(data);
    promise
      .then((data) => {
        id && mutate();
        handleBack(data.id);
      })
      .catch((error: AxiosError) => {
        const formikErrors = pickFrom(error?.response?.data, Object.keys(initialValues));
        if (formikErrors) {
          helpers.setErrors(formikErrors);
        } else {
          showToast(error);
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  if (isLoading) {
    return <Loader/>;
  }

  const values = (id && data)
    ? serializeValues(data)
    : initialValues;

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      component={TechMapForm}
    />
  );
};

export default AddTechMap;
