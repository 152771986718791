import { IColumn } from "@/apps/Table/interfaces";
import {
  cellButtons,
  cellPointText,
  headerCellStyle
} from "../style";

import { DUTY_HEADER_LABELS, TECH_MAP_HEADER_LABELS, TECH_MAP_KEYS } from "../const";

import { TTechMapKeys } from "../interfaces";
import {
  NameCell,
  CultivarCell,
  PlotCell,
  EditingCell,
} from "../templates/TеchnologicalMaps/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const tеchnologicalMapsColumns: IColumn<TTechMapKeys>[] = [
  {
    label: TECH_MAP_HEADER_LABELS.name,
    key: TECH_MAP_KEYS.name,
    contentHeader: TECH_MAP_HEADER_LABELS.name,
    bodyCellProps: {
      render: NameCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: TECH_MAP_HEADER_LABELS.cultivar__name,
    key: TECH_MAP_KEYS.cultivar__name,
    contentHeader: TECH_MAP_HEADER_LABELS.cultivar__name,
    bodyCellProps: {
      render: CultivarCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: TECH_MAP_HEADER_LABELS.plot__name,
    key: TECH_MAP_KEYS.plot__name,
    contentHeader: TECH_MAP_HEADER_LABELS.plot__name,
    bodyCellProps: {
      render: PlotCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },  
  {
    key: "editing",
    label: DUTY_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCell,
      styles: cellButtons,
    },
  },
];
