import React, { Fragment, useMemo, useState, } from "react";
import useSWR from "swr";
import qs from "qs";
import { useSearchParams, } from "react-router-dom";
import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";
import BaseTable from "@/apps/Table";
import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";
import { IPlanningList, } from "@/api/interfaces/responses";
import {
  useQuery,
  useStores,
  useRightSidebar
} from "@/hooks";
import { columns } from "../models";
import { isUndefined } from "@/utils/helpers";
import {
  TExpertAssessmentKeys,
  IExpertAssessmentQueryParams
} from "../interfaces";
import {
  ADD_TEXT,
  EMPTY_LIST_TEXT,
  EMPTY_LIST_TEXT_WITH_FILTERS,
} from "../const";
import {
  SWR_KEYS,
  KEYS_SIDEBAR_VARIANT_MODULES,
} from "@/const";
import Filters from "@/components/Filters";
import useFilters
  from "@/pages/ExpertAssessment/hooks/useFilters";
import { ROUTES } from "@/apps/AppRouter/const";
import {
  QUERY_STRING_MICRO_ROUTS_EXPERT_ASSESSMENT
} from "@/apps/RightSidebar/const";

/**
 * Компонент, представляющий таблицу плана экспертных оценок
 */
const Table = () => {
  const {
    api,
    swrStore,
    queryStringSidebarCollector
  } =
    useStores();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const query = useQuery<IExpertAssessmentQueryParams>();
  const filters = useFilters();
  const { handleOpen } = useRightSidebar();

  // переменная принимающие параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orgeringArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);

  const [orderBy, setOrderBy] =
    useState<string[]>(orgeringArray);
  const {
    data,
    error,
    isLoading,
    mutate,
  } = useSWR(
    {
      page: query.page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      key: SWR_KEYS.getPlanningTable(),
      search: query?.search,
      status: query?.status?.key,
      year_min: query?.year_min,
      year_max: query?.year_max,
      expert_assessment: true
    },
    api.planning.getPlanning,
  );

  swrStore.addMutator(SWR_KEYS.getPlanningTable(), mutate);

  const handleChangePage = (value: number) => {
    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value: TExpertAssessmentKeys[]) => {
    const queryParams = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
    setOrderBy(value);
  };

  const isFilters: boolean = Object.keys(query).length > 0;

  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   *
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */
  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {

    const { str } = queryStringSidebarCollector.setup({
      tab: QUERY_STRING_MICRO_ROUTS_EXPERT_ASSESSMENT.INFO.path,
      keyContent: KEYS_SIDEBAR_VARIANT_MODULES.EXPERT_ASSESSMENT,
      module_id: id,
    });
    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.EXPERT_ASSESSMENT,
      modalProps: {
        id,
        keySidebar: KEYS_SIDEBAR_VARIANT_MODULES.EXPERT_ASSESSMENT,
      },
    });
    setSearchParams(str);
  };
  const handleAdd = () => {
    navigate(ROUTES.addExpertAssessment);
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
        />
        <Button
          sx={{
            whiteSpace: "nowrap",
            flexShrink: "0",
          }}
          variant="contained"
          startIcon={<AddIcon/>}
          onClick={handleAdd}
        >
          Добавить
        </Button>
      </Box>

      <BaseTable<IPlanningList, TExpertAssessmentKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={query.page ?? 1}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={isFilters}
        textAdd={ADD_TEXT}
        textEmptyTableWithFilter={
          EMPTY_LIST_TEXT_WITH_FILTERS
        }
        textEmptyTable={EMPTY_LIST_TEXT}
        onRowClick={handleOpenRightSidebar}
      />
    </Fragment>
  );
};

export default Table;
