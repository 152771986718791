import React from "react";
import { Stack, Typography } from "@mui/material";
import { FIELDS_NAME } from "@/pages/AddSeedArrival/const";
import {
  BranchAutocomplete,
  MaterialTypeAutocomplete,
  PlotAutoComplete,
  RegionAutocomplete,
  UnitAutocomplete
} from "@/components/Autocompletes";
import {
  DatePickerField,
  FastTextField
} from "@/components/Fields";
import { IFormik } from "../../const";
import { useFormikContext } from "formik";
import {
  IBranchItem,
  IRegionItem
} from "@/api/interfaces/responses";
import FastRegexTextField
  from "@/components/FastRegexTextField";

const DeliveryData = () => {
  const formik = useFormikContext<IFormik>();
  const handleRegionChange = (payload: IRegionItem) => {
    void formik.setFieldValue(FIELDS_NAME.REGION, payload);
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };
  const handleBranchChange = (payload: IBranchItem) => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, payload);
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };
  const handleRegionClear = () => {
    void formik.setFieldValue(FIELDS_NAME.REGION, "");
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };
  const handleBranchClear = () => {
    void formik.setFieldValue(FIELDS_NAME.BRANCH, "");
    void formik.setFieldValue(FIELDS_NAME.PLOT, "");
  };

  return (
    <Stack direction="column" gap="20px">
      <Typography variant="h3">
        Данные поставки
      </Typography>
      <RegionAutocomplete name={FIELDS_NAME.REGION}
                          handleChange={handleRegionChange}
                          onClear={handleRegionClear}/>
      <BranchAutocomplete name={FIELDS_NAME.BRANCH}
                          q={{ region: formik.values.region?.id }}
                          disabled={!formik.values.region}
                          handleChange={handleBranchChange}
                          onClear={handleBranchClear}/>
      <PlotAutoComplete name={FIELDS_NAME.PLOT}
                        required={true}
                        q={{ branch: formik.values.branch?.id }}
                        disabled={!formik.values.branch}/>
      <DatePickerField
        name={FIELDS_NAME.PLANNED_RECEIPT_AT}
        label="Плановая дата поступления"
        required
      />
      <DatePickerField
        name={FIELDS_NAME.RECEIPT_AT}
        label="Фактическая дата поступления"
      />
      <FastTextField name={FIELDS_NAME.SENDER}
                     label="Отправитель"
      />
      <MaterialTypeAutocomplete name={FIELDS_NAME.MATERIAL_TYPE}
                                label="Тип материала"
                                required={true}
      />
      <FastRegexTextField
        name={FIELDS_NAME.PLANNED_QUANTITY_OF_SEEDS}
        label="Плановое количество"
        regexType="decimal"
        required
      />
      <FastRegexTextField
        name={FIELDS_NAME.QUANTITY_OF_SEED}
        label="Фактическое количество"
        regexType="decimal"
      />
      <UnitAutocomplete name={FIELDS_NAME.UNIT}/>
    </Stack>
  );
};

export default DeliveryData;