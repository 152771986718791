import React from "react";
import {
  useNavigate,
  Link as RouterLink
} from "react-router-dom";
import {
  Box,
  Button,
  SxProps,
  Theme,
  Typography,
  Stack,
} from "@mui/material";
import { Map as MapIcon } from "@mui/icons-material";
import {
  ROUTES_PATHS,
  ROUTES
} from "@/apps/AppRouter/const";
import theme from "@/theme";
import {
  coordinateFormatter
} from "@/apps/RightSidebar/utils";
import { ILocationMain } from "@/api/interfaces/responses";
import {
  InfoField
} from "@/components/InfoField/InfoField";
import { useStores } from "@/hooks";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

export const mapButtonStyle = (theme: Theme): SxProps => ({
  mt: "20px",
  color: theme.palette.blue.main,
  textTransform: "capitalize",
  border: `1px solid ${theme.palette.blue.lightBlue}`,

  "&:hover": {
    color: theme.palette.blackAndWhite.white,
    backgroundColor: theme.palette.blue.lightBlue,
    border: `1px solid ${theme.palette.blue.lightBlue}`,
  },
});

interface IProps {
  renderData: ILocationMain;
}

const GeneralInfo: React.FC<IProps> = ({ renderData }) => {
  const RequestInfoPropsGeneral = {
    title: "Общие сведения",
    infoFieldElements: [
      {
        label: "Адрес фактический:",
        content: renderData.actual_address?.one_line || "-",
      },
      {
        label: "Кадастровый номер: ",
        content: renderData.cadastral_number || "-",
      },
      {
        label: "Координаты: ",
        content: renderData.map_object?.coordinates?.[0] ? (
          <Stack>
            {
              renderData.map_object?.coordinates[0].map((coordinate, index) => (
                <Typography
                  key={index}
                  variant="p14Medium"
                  whiteSpace="nowrap"
                >
                  {coordinateFormatter(coordinate)}
                </Typography>
              ))
            }
          </Stack>) : (
          <Typography variant="p14Medium">-</Typography>
        )
      },
    ],
  };

  const RequestInfoPropsSquare = {
    title: `Общая земельная площадь ${renderData.total_area} га, в том числе:`,
    infoFieldElements: [
      {
        label: "C/Х земли:",
        content: `${renderData.agricultural_land} га`,
      },
      {
        label: "Пашни:",
        content: `${renderData.arable_land} га`,
      },
      {
        label: "Под сортоопытами:",
        content: `${renderData.trials_area} га`,
      },
      {
        label: "Под производственную деятельность:",
        content: `${renderData.extrabudgetary_area} га`,
      },
      {
        label: "Неиспользуемая:",
        content: `${renderData.unused_area} га`,
      },
      {
        label: "Неугодье, неудобье:",
        content: `${renderData.unfit_land} га`,
      },
    ],
  };

  const navigate = useNavigate();

  const handleOpenMap = () => {
    navigate({
      pathname: ROUTES_PATHS.map,
    });
  };

  const { userStore } = useStores();

  const canViewMap = userStore.isPermission(
    PERMISSIONS.regulatory_infoPlot,
    PERMISSION_CRUD.view
  )

  return (
    <Box>
      <Typography mb={2} mt={2} variant="h3">
        {RequestInfoPropsGeneral.title}
      </Typography>
      {RequestInfoPropsGeneral.infoFieldElements.map(
        (infoFieldElement, index) => (
          <InfoField key={index} {...infoFieldElement} />
        ),
      )}
      {canViewMap &&
        <Button
          sx={mapButtonStyle(theme)}
          variant="outlined"
          startIcon={<MapIcon />}
          component={RouterLink}
          to={`/${ROUTES.map}/${ROUTES.areaMap}/?location_id=${renderData.id}&tab=detail`}
          onClick={handleOpenMap}
        >
          НА КАРТЕ
        </Button>}
      <Box mt={"45px"}>
        <Typography mb={2} mt={3} variant="h3">
          {RequestInfoPropsSquare.title}
        </Typography>

        {RequestInfoPropsSquare.infoFieldElements.map(
          (infoFieldElement, index) => (
            <InfoField key={index} {...infoFieldElement} />
          ),
        )}
      </Box>
    </Box>
  );
};

export default GeneralInfo;
