import React, { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import {
  DocumentFormStep,
  EfficiencyFormStep,
  FormikStepper,
  InvestmentsFormStep,
  ProductionFormStep
} from "@/pages/AddFact/components";

import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";
import { STEP_LABELS } from "./const";
import {
  FormikStep
} from "../AddStatement/components/FormikStep";
import useSWR from "swr";
import { Box, CircularProgress } from "@mui/material";
import { mapValueToForm } from "./utils";

const AddFactPage: React.FC = () => {
  const { id } = useParams();
  const { api } = useStores();
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { headerStore } = useStores();

  const fetcher = ([, id]: [string, string | undefined]) => {
    return api.offBudget.getOffBudgetPlan(Number(id));
  };

  const { data, isLoading, mutate } = useSWR(
    ["fetchOffBudgetPlan", id],
    fetcher
  );

  useLayoutEffect(() => {
    headerStore.setHeader(VARIANT_HEADER.ADD_FACT);
  }, []);

  const handleSubmit = () => {
    navigate(`${ROUTES_PATHS.offBudgetFact}?type=fact`);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80}/>
      </Box>
    );
  }

  return (
    <FormikStepper
      initialValues={mapValueToForm(data)}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      step={step}
      setStep={setStep}
      id={Number(id)}
    >
      <FormikStep label={STEP_LABELS.PRODUCTION}>
        <ProductionFormStep data={data}/>
      </FormikStep>

      <FormikStep label={STEP_LABELS.INVESTMENTS}>
        <InvestmentsFormStep data={data}/>
      </FormikStep>

      <FormikStep label={STEP_LABELS.EFFICIENCY}>
        <EfficiencyFormStep data={data}/>
      </FormikStep>

      <FormikStep label={STEP_LABELS.FILE_LIST}>
        <DocumentFormStep mutate={mutate}/>
      </FormikStep>
    </FormikStepper>
  );
};

export default observer(AddFactPage);
