import {
  FiltersType
} from "@/components/Filters/interfaces";

interface IParams {
  selectedValue?: number | null
  name?: string,
  label?: string
}

export const useYearFilter = ({
  selectedValue,
  name = "year",
  label = "Год испытания"
}: IParams) => {

  return {
    name: name,
    label: label,
    type: FiltersType.yearPicker,
    selectedValue: selectedValue,
    value: "",
    style: { minWidth: "230px", flexGrow: 1 },
  }
};