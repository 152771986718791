import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import {
  timeAutoHide,
  timeClose
} from "@/apps/Toast/const";
import { isNull } from "@/utils/helpers";
import { StyledToast } from "@/apps/Toast/ToastBase/styles";

import styles from "./styles/index.module.scss";

export interface IToastProps {
  autoHide?: boolean;
  onClose: () => void;
  closing?: boolean;
  children?: React.ReactNode;
}

/**
 * Контейнер для тостов
 */
export const createContainer = (): HTMLElement => {
  const portalId = "toastContainer";
  let element = document.getElementById(portalId);

  if (!isNull(element)) {
    return element;
  }

  element = document.createElement("div");
  element.setAttribute("id", portalId);
  element.className = styles.container;
  document.body.appendChild(element);
  return element;
};

/** Обертка с анимациями тоста */

const ToastBase: React.FC<IToastProps> = ({
  onClose,
  autoHide = false,
  closing = false,
  children
}) => {
  const [isClosing, setIsClosing] = React.useState(closing);

  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onClose, timeClose);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isClosing, onClose]);

  useEffect(() => {
    setIsClosing(closing);
  }, [closing]);

  useEffect(() => {
    if (autoHide) {
      const timeoutId = setTimeout(
        () => setIsClosing(true),
        timeAutoHide
      );

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [autoHide]);

  return createPortal(
    <StyledToast isClosing={isClosing}>
      {children}
    </StyledToast>,
    createContainer()
  );
};

export default ToastBase;
