import React from "react";
import { observer } from "mobx-react";
import { Formik, FormikHelpers } from "formik";
import dayjs from "dayjs";
import { FormikValues } from "formik/dist/types";
import { Box, Divider, Typography } from "@mui/material";
import theme from "@/theme";
import { useCurrentUser, useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import {
  errorToString,
  isUndefined,
} from "@/utils/helpers";

import {
  IOrderCultivar,
  IOrderPlot,
} from "@/api/interfaces/responses";
import AddOrderPlotForm
  from "./components/AddOrderPlotForm";
import {
  FIELDS_NAME,
  IFormik,
  validationSchema,
} from "./const";
import { formToValueSerializer } from "./utils";

/**
 * Компонент модалки добавления инвестиции для внебюджета
 * @param props
 * @param props.id - id плана внебюджета
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    orderCultivar: IOrderCultivar;
    initialValues?: IOrderPlot;
    orderPlotId?: number;
    title?: string;
    isEdit?: boolean;
    onSubmit: () => void;
  };
}

const AddOrderPlot: React.FC<IProps> = (props) => {
  const { api, toastStore, modalStore } = useStores();
  const {
    modalProps: {
      orderCultivar,
      initialValues,
      onSubmit,
      title,
    },
  } = props;
  const { id } = useCurrentUser();

  console.log(initialValues);

  const convertInitialValues = (item) => {
    if (!isUndefined(item))
      return {
        ...item,
        [FIELDS_NAME.IS_HP]: item.hp,
        [FIELDS_NAME.IS_OOS]: item.oos,
        [FIELDS_NAME.IS_PHITO]: item.phito,
        [FIELDS_NAME.ORDER_CULTIVAR]:
          item?.order_cultivar?.id,
        [FIELDS_NAME.REGION]: {
          ...item?.plot?.branch.region,
          label: item?.plot?.branch?.region?.name,
        },
        [FIELDS_NAME.BRANCH]: {
          ...item?.plot?.branch,
          label: item?.plot?.branch?.name,
        },
        [FIELDS_NAME.PLOT]: {
          ...item?.plot,
          label: item?.plot?.name,
        },
        [FIELDS_NAME.CULTIVAR_USAGE_TYPE]: {
          ...item?.cultivar_group[0]?.usage_type,
          label: item?.cultivar_group[0]?.usage_type?.name,
        },
        [FIELDS_NAME.CULTIVAR_GROUP]: {
          id: item?.cultivar_group[0]?.id,
          label: `${item?.cultivar_group[0]?.variant?.severity}`,
        },
        [FIELDS_NAME.TRIALS_FROM]: item.trials_from
          ? dayjs(item.trials_from)
          : null,
        [FIELDS_NAME.TRIALS_TO]: item.trials_to
          ? dayjs(item.trials_to)
          : null,
      };
  };

  const initValues = Object.assign(
    {
      [FIELDS_NAME.REGION]: null,
      [FIELDS_NAME.BRANCH]: null,
      [FIELDS_NAME.PLOT]: null,
      [FIELDS_NAME.ORDER_CULTIVAR]:
        orderCultivar?.id ?? null,
      [FIELDS_NAME.CULTIVAR_USAGE_TYPE]: null,
      [FIELDS_NAME.CULTIVAR_GROUP]: null,
      [FIELDS_NAME.IS_HP]: false,
      [FIELDS_NAME.IS_OOS]: false,
      [FIELDS_NAME.IS_PHITO]: false,
      [FIELDS_NAME.TRIALS_TO]: null,
      [FIELDS_NAME.TRIALS_FROM]: null,
    },
    convertInitialValues(initialValues),
  );

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    const payload = {
      ...formToValueSerializer(values),
      editor: id ?? 0,
    };

    initialValues?.id
      ? api.regulatoryInfo
          .updateOrderPlot(initialValues?.id, payload)
          .then(() => {
            onSubmit();
            modalStore.pop();
          })
          .catch((error) => {
            toastStore.createToast({
              type: TOAST_TYPES.ALERT,
              toastProps: {
                message: errorToString(error),
                severity: "error",
              },
            });
          })
          .finally(() => {
            helpers.setSubmitting(false);
          })
      : api.regulatoryInfo
          .postOrderPlot(payload as any)
          .then(() => {
            onSubmit();
            modalStore.pop();
          })
          .catch((error) => {
            toastStore.createToast({
              type: TOAST_TYPES.ALERT,
              toastProps: {
                message: errorToString(error),
                severity: "error",
              },
            });
          })
          .finally(() => {
            helpers.setSubmitting(false);
          });
  };

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography
          variant="subtitle2"
          fontSize={12}
          color={theme.palette.blackAndWhite.gray}
        >
          {orderCultivar?.cultivar?.name}
        </Typography>
        <Typography variant="h2" fontSize={24}>
          {title ?? "Добавить ГСУ"}
        </Typography>
      </Box>
      <Divider />
      <Formik
        initialValues={initValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => (
          <AddOrderPlotForm
            {...props}
            cultivarId={orderCultivar?.cultivar?.id}
          />
        )}
      />
    </Box>
  );
};

export default observer(AddOrderPlot);
