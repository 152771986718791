import React from "react";

export interface IStepContainerProps {
  children?: React.ReactNode;
  label: string;
}

const StepContainer = ({ children }: IStepContainerProps) => {
  return <>{children}</>;
};

export default StepContainer;