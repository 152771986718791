import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down(1024)]: {
    display:"flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  }
}));