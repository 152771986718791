export const PLANNING_KEYS = {
  number: "number",
  cultivarName: "cultivar__name",
  branchName: "branch__name",
  createdAt: "created_at",
  status: "status",
  editing: "editing",
} as const;

export const PLANNING_HEADER_LABELS = {
  number: "Номер",
  cultivar: "Культура",
  branch: "Филиал",
  createdAt: "Дата создания",
  status: "Статус",
};

export const EMPTY_LIST_TEXT =
  "Еще не создано ни одного плана";
export const EMPTY_LIST_TEXT_WITH_FILTERS =
  "По указанным параметрам планы не найдены";
export const ADD_TEXT = "Добавить план";