import { useEffect, useRef } from "react";
import { useStores } from "./useStores";
import { ANIMATION_TIME } from "@/const";
import { isUndefined } from "@/utils/helpers";
import { TRSidebarShowBlock } from "@/services/store/RightSidebarStore";
import { IModalProps } from "@/interfaces";

/**
 * * хук для управления правым сайдбаром
 * @hook useRightSidebar
 */

interface IRightSidebar {
  handleOpen: (T: { type: string; modalProps?: unknown }) => void;
  handleClose: () => void;
  handleCollapse: () => void;
  handleExpand: () => void;
  handleCloseInstantly: () => void;
  isOpen: boolean;
  isCollapse: boolean;
  type: string | null;
  sidebarProps: IModalProps;
  currentShowingBlock: TRSidebarShowBlock;
  isStartClosingAnimation: boolean;
}

const useRightSidebar = (): IRightSidebar => {
  const animationRef = useRef<NodeJS.Timeout>();
  const { rightSidebarStore } = useStores();

  const {
    isOpen,
    type,
    sidebarProps,
    currentShowingBlock,
    isStartClosingAnimation,
    isCollapse
  } = rightSidebarStore;

  /**
   * @function handleOpen
   * * метод для открытия модалки
   */

  const handleOpen = ({ type, modalProps }): void => {
    rightSidebarStore.open(type, modalProps);
  };

  /**
   * @function handleCollapse
   * * метод для колапса модалки
   */
  const handleCollapse = (): void => {
    rightSidebarStore.collapse();
  };

  /**
   * @function handleExpand
   * * метод для експанда модалки
   */
  const handleExpand = (): void => {
    rightSidebarStore.expand();
  };

  /**
   * @function handleClose
   * * метод для плавного закрытия модалки
   */
  const handleClose = (): void => {
    rightSidebarStore.setStartAnimationClosing();
    animationRef.current = setTimeout(() => {
      rightSidebarStore.close();
    }, ANIMATION_TIME.SMALL);
  };

  /**
   * @function handleCloseInstantly
   * * метод для мгновенного закрытия модалки
   */
  const handleCloseInstantly = () => {
    rightSidebarStore.close();
  };

  useEffect(() => {
    return () => {
      if (!isUndefined(animationRef.current)) {
        clearTimeout(animationRef.current);
      }
    };
  }, []);

  return {
    handleOpen,
    handleClose,
    handleCollapse,
    handleExpand,
    handleCloseInstantly,
    isOpen,
    type,
    sidebarProps,
    currentShowingBlock,
    isStartClosingAnimation,
    isCollapse
  };
};

export default useRightSidebar;
