import React, { useState } from "react";
import { AddEntities } from "@/apps/Modals/ModalContent";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { useStores } from "@/hooks";
import { useParams } from "react-router-dom";
import { PAGE_SIZE } from "@/const";
import { resInterface } from "@/api/interfaces";
import { FIELDS_NAME } from "@/pages/AddStatement/const";
import useToastContent, {
  TOAST_CONTENT_TYPE
} from "@/hooks/useToastContent";
import {
  PERMISSION_CRUD,
  PERMISSIONS
} from "@/premissions";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string
    initialValue?: resInterface.ISubject[]
  };
}

/**
 * Модалка добавления авторов для заявки
 * @param handleClose
 * @param modalProps
 * @component
 */
const AddAuthors: React.FC<IProps> = ({
  handleClose,
  modalProps
}) => {
  const { id } = useParams();
  const toastContent = useToastContent(TOAST_CONTENT_TYPE.SERVER_ERROR);
  const { modalStore, api, toastStore, userStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const handleAccept = (values: resInterface.ISubject[]) => {
    setIsLoading(true);
    const ids = values.map(el => el.id);
    api.statement.patchStatementById(Number(id), { [FIELDS_NAME.AUTHORS]: ids }).then(() => {
      handleClose();
    }).catch(() => {
      toastStore.createToast(toastContent);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const fetcher = (args) => {
    const { page, search } = args;
    return api.statement.getSubjects({
      page,
      page_size: PAGE_SIZE,
      search,
      first_ids: modalProps?.initialValue ? modalProps.initialValue.map(el => el.id).toString() : undefined
    });
  };
  
  const handleAdd = (payload: resInterface.ISubject[]) => {
    modalStore.open(VARIANT_MODAL.SUBJECT, { handleAccept: (value: resInterface.ISubject) => {
        modalStore.setModalPropsByKey(modalProps.key, {...modalProps, initialValue: [...payload, value]})
      } })
  }

  const getLabel = (payload: resInterface.ISubject) => payload.name;

  const isSubject = userStore.isPermission(
    PERMISSIONS.registrationSubject,
    PERMISSION_CRUD.add
  );

  const props = {
    ...modalProps,
    key: ["GET_AUTHORS", modalProps.initialValue],
    addButtonLabel: "Добавить автора",
    isDisabledBtnAdd: isSubject,
    tooltipText: "У вас недостаточно прав для добавления автора",
    title: "Авторы",
    handleAccept,
    handleAdd,
    disabled: isLoading,
    getLabel: getLabel,
    fetcher
  };
  return (
    <AddEntities<resInterface.ISubject>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default AddAuthors;