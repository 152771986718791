import React, { useEffect } from "react";
import {
  Stack,
  Typography,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";

import { VARIANT_MODAL } from "@/apps/Modals/const";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { STEP_TITLES, IEfficiencyItem } from "@/pages/AddPlan/const";
import { effectivityListToItemsSerializer } from "@/pages/AddPlan/utils";
import { IOffBudget } from "@/api/interfaces/requests";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { resInterface } from "@/api/interfaces";
import EmptyItem from "@/components/EmptyItem";
import EffectivityItem from "./EffectivityItem";

interface IProps {
  data?: IOffBudget | null;
}

/**
 * Компонет для шага эффективности
 * */

const EfficiencyFormStep: React.FC<IProps> = ({ data: offBudgetData }) => {
  const id = offBudgetData?.id ?? "";
  const { api, swrStore, modalStore } = useStores();

  const key = SWR_KEYS.getEffectivityList(id);

  const getKey = (index: number) => ({
    _key: key,
    page: index + 1,
    com_plan_fact: id,
  });

  const { data, handleScroll, isLoadingMore, isLoading, mutate } =
    useInfiniteScroll<resInterface.IEffectivityList>(
      getKey,
      api.offBudget.getEffectivityList,
      {
        revalidateFirstPage: false,
      }
    );

  useEffect(() => {
    swrStore.addMutator(key, mutate);
  }, []);

  const onSubmitCreation = () => {
    mutate();
  };

  const handleAddEffectivity = () => {
    modalStore.open(VARIANT_MODAL.ADD_EFFECTIVITY, {
      com_plan_fact: id,
      onSubmit: onSubmitCreation,
    });
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  const efficiencyData: IEfficiencyItem[] =
    effectivityListToItemsSerializer(data);

  return (
    <Stack spacing={3}>
      <Typography variant="h3">{STEP_TITLES.EFFICIENCY}</Typography>
      <Box onScroll={handleScroll}>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4">Года</Typography>
          {data.length > 0 && (
            <Button
              sx={{
                width: "40px",
                height: "40px",
                minWidth: "auto",
                padding: 0,
              }}
              color={"blue"}
              size={"medium"}
              variant={"outlined"}
              onClick={handleAddEffectivity}
            >
              <AddOutlinedIcon />
            </Button>
          )}
        </Stack>
        {data?.length > 0 ? (
          <>
            <Box pt={2}>
              {efficiencyData.map((item) => (
                <EffectivityItem key={item.id} {...item} />
              ))}
            </Box>
            {isLoadingMore ? (
              <Stack pt={2} alignItems={"center"} justifyContent={"center"}>
                <CircularProgress />
              </Stack>
            ) : (
              <Button
                sx={{
                  marginTop: "24px",
                  width: "100%",
                }}
                color={"blue"}
                size={"large"}
                startIcon={<AddOutlinedIcon />}
                variant={"outlined"}
                onClick={handleAddEffectivity}
              >
                Добавить показатели эффективности
              </Button>
            )}
          </>
        ) : (
          <EmptyItem
            title="Заполните данные по эффективности"
            buttonText="Добавить показатели эффективности"
            onClick={handleAddEffectivity}
          />
        )}
      </Box>
    </Stack>
  );
};
export default EfficiencyFormStep;
