import yup from "@/utils/yup";
import {
  IVegetationCause
} from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  VEGETATION_CAUSE: "vegetation_cause",
  STATUS: "status",
} as const;

export interface IFormik {
  [FIELDS_NAME.VEGETATION_CAUSE]: IVegetationCause | null,
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.VEGETATION_CAUSE]: yup.mixed().required()
});

export const initialValues = {
  [FIELDS_NAME.VEGETATION_CAUSE]: null,
};

export const valueToForm = (payload: Partial<IFormik>) => ({
  [FIELDS_NAME.VEGETATION_CAUSE]: payload.vegetation_cause ? {
    ...payload.vegetation_cause,
    label: payload.vegetation_cause.name
  } : null,
});