import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import {
  IPropsAddCulture,
  setValueToForm,
  TForm,
  validationSchema,
} from "./const";
import { AxiosError } from "axios";
import {
  errorToString,
  replaceNullWithZero,
} from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import CreateOBCultivarForm from "./components/CreateOBCultivar";
import { SWR_KEYS } from "@/const";
import useSWR from "swr";

/**
 * Модалка с формой создания/редактирования культуры из Внебюджета
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const AddOBCultivar: React.FC<IPropsAddCulture> = ({
  handleClose,
  modalProps,
  onSubmit,
}) => {
  const baseInititialValues = {
    cultivar: undefined,
    acreage: null,
    productivity: null,
    croppage: null,
    sales_volume: null,
    price: null,
    cost_price: null,
    seeding_rate: null,
    seeds_needs: null,
    seeds_available: null,
    seeds_price: null,
    branch: undefined,
    plot: "",
    location: "",
  };
  const { planId, cultivarId, locationID } = modalProps;
  const { api, toastStore, swrStore } = useStores();

  const mutateCultivar =
    swrStore.mutators[SWR_KEYS.getOffBudgetCultivars()];

  const mutatePlan =
    swrStore.mutators[
      SWR_KEYS.getOffBudgetById(modalProps.planId)
    ];

  const { data, mutate } = useSWR(
    cultivarId
      ? SWR_KEYS.getOffBudgetCultivar(cultivarId)
      : null,
    () =>
      api.offBudget.getOffBudgetCultivar(
        cultivarId as number,
      ),
    {
      onError: (error) => {
        const errorMessage = errorToString(error);
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorMessage,
            severity: "error",
          },
        });
        handleClose();
      },
    },
  );

  const {
    data: location,
  } = useSWR(
    {
      key: locationID ? SWR_KEYS.getAgriculturalLabourLocation(locationID) : null,      
    },
    locationID ? () => api.agriculturalLabour.getLocationById(locationID as number) : null,
  );

  const locationData = {
    location: { id: location?.id, label: location?.name },
    plot: { id: location?.plot.id, label: location?.plot?.name },
  }
   
  const branchID = location?.plot?.branch?.id;

  const handleMutates = (): void => {
    mutateCultivar && mutateCultivar();
    mutatePlan && mutatePlan();
    mutate();
  };

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);
    const formattedValues = replaceNullWithZero(values);

    const promise = cultivarId
      ? api.offBudget.editOffBudgetCultivar(cultivarId, {
          ...values,
          cultivar: values.cultivar.id,
          location: values.location.id,
        })
      : api.offBudget.createOffBudgetCultivar({
          ...formattedValues,
          cultivar: values.cultivar?.id || undefined,
          com_plan_fact: modalProps.planId,
          location: values.location.id, 
        });

    promise
      .then(() => {
        handleMutates();
        onSubmit && onSubmit();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors.non_field_errors) {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const initialValues = data
    ? setValueToForm(data, planId)
    : baseInititialValues;
  const values = modalProps?.initialValues
    ? { ...initialValues, ...locationData, ...modalProps.initialValues }
    : initialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      component={() => (
        <CreateOBCultivarForm
          modalProps={modalProps}
          handleClose={handleClose}
          branchID={branchID}
        />
      )}
    />
  );
};

export default AddOBCultivar;
