import yup from "@/utils/yup";

export enum FIELD_NAMES {
  UNIT = "unit", // ед. измерения
  QUANTITY = "quantity", // кол-во единиц
  MATERIAL_TYPE = "material_type", // тип материала
  PLOT = "plot", // ГСУ(участок)
  CULTIVAR = "cultivar", // культура,
  TYPE = "type", // тип разнарядки

  // !! фильтры для plot(ГСУ) 
  REGION = "region",// регион
  BRANCH = "branch" // филиал
}

const quantityMin = 1;
const quantityMax = 999999999;
const textValidationMinMax = `Значение поля должно быть в диапазоне от ${quantityMin} до ${quantityMax}`;


export const validationSchema = Object.keys(FIELD_NAMES).reduce((schema, key) => {
  return schema.shape({
    [FIELD_NAMES[key]]: yup.mixed().required(),
    [FIELD_NAMES.QUANTITY]: yup.number().typeError("Введите число").min(quantityMin, textValidationMinMax)
      .max(quantityMax, textValidationMinMax).required()
  });
}, yup.object());


export const QUANTITY_TYPE = {
  HP: "hp",
  OOS: "oos",
  PHYTO: "phyto",
  FROST: "frost",
  COLLECTION: "collection",
  CUSTOMS: "customs",
  GMO: "gmo",
};

export const QUANTITY_TYPE_LABEL = {
  HP: "Хоз. полезность",
  OOS: "ООС",
  PHYTO: "Фитопатология",
  FROST: "Морозоустойчивость",
  COLLECTION: "Коллекция",
  CUSTOMS: "Таможенный контроль",
  GMO: "Испытания на ГМО",
} as const;




