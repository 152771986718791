import * as Yup from "yup";
import { ICountry } from "@/api/interfaces/requests";
import { IRegion } from "@/api/interfaces/responses";

export const FIELDS_SET = {
  country: "country",
  comment: "comment",
  one_line_address: "one_line_address",
  district: "district",
  locality_name: "locality_name",
  area_name: "area_name",
  street_name: "street_name",
  index: "index",
  house: "house",
  building: "building",
  housing: "housing",
  flat: "flat",
  entrance: "entrance",
  post_office_box: "post_office_box",
};

export const ISO_RUSSIAN = 643;

export const ERROR_MESSANGES = {
  required: "Обязательное поле",
  MIN_3: "Некорректный размер строки",
};

export const isNotRussian = (() => {
  return {
    field: [FIELDS_SET.country],
    sheme: {
      is: (data) => {
        if (data.iso === ISO_RUSSIAN) return true;
        return false;
      },
      then: (schema) =>
        schema.required(ERROR_MESSANGES.required),
      otherwise: (schema) => schema.nullable(),
    },
  };
})();

const isRussian = (() => {
  return {
    field: [FIELDS_SET.country],
    sheme: {
      is: (data) => {
        if (data.iso !== ISO_RUSSIAN) return true;
        return false;
      },
      then: (schema) =>
        schema.required(ERROR_MESSANGES.required),
      otherwise: (schema) => schema.nullable(),
    },
  };
})();

export const validationSchema = Yup.object().shape({
  [FIELDS_SET.country]: Yup.object<ICountry>().required(
    ERROR_MESSANGES.required,
  ),
  [FIELDS_SET.comment]: Yup.string().nullable(),
  [FIELDS_SET.one_line_address]: Yup.string().when(
    isRussian.field,
    isRussian.sheme,
  ),
  [FIELDS_SET.district]: Yup.object<IRegion>().when(
    isNotRussian.field,
    isNotRussian.sheme,
  ),
  [FIELDS_SET.area_name]: Yup.string().nullable(),
  [FIELDS_SET.locality_name]: Yup.string().when(
    isNotRussian.field,
    isNotRussian.sheme,
  ),
  [FIELDS_SET.street_name]: Yup.string().when(
    isNotRussian.field,
    isNotRussian.sheme,
  ),
  [FIELDS_SET.index]: Yup.string().when(
    isNotRussian.field,
    isNotRussian.sheme,
  ),
  [FIELDS_SET.house]: Yup.string().when(
    isNotRussian.field,
    isNotRussian.sheme,
  ),
  [FIELDS_SET.building]: Yup.string().nullable(),
  [FIELDS_SET.housing]: Yup.string().nullable(),
  [FIELDS_SET.flat]: Yup.string().nullable(),
  [FIELDS_SET.entrance]: Yup.string().nullable(),
  [FIELDS_SET.post_office_box]: Yup.string().nullable(),
});
