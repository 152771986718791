import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Tab } from "@mui/material";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { PLANNING_NAVS } from "../const";
import { StyledTabs, tabStyles } from "./style";

/**
 * Возвращает компонент для заголовка раздела "Планирование".
 * @returns React.FC Возвращает React-элемент для отображения заголовка раздела "Планирование".
 */
const HeaderPlaning: React.FC = () => {
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(value !== location.pathname){
      setValue(location.pathname)
    }
  }, [location.pathname]);

  return (
    <StyledTabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
    >
      <Tab component={NavLink}
           value={ROUTES_PATHS.planning}
           label={PLANNING_NAVS.planning}
           to={ROUTES_PATHS.planning}
           sx={tabStyles}
      />
      <Tab component={NavLink}
           value={ROUTES_PATHS.planning_expert_assessment}
           label={PLANNING_NAVS.expertAssessment}
           to={ROUTES_PATHS.planning_expert_assessment}
           sx={tabStyles}
      />
      <Tab component={NavLink}
           value={ROUTES_PATHS.planning_crop}
           label={PLANNING_NAVS.crop}
           to={ROUTES_PATHS.planning_crop}
           sx={tabStyles}
      />
      <Tab component={NavLink}
           value={ROUTES_PATHS.planningHp}
           label={PLANNING_NAVS.plan_hp}
           to={ROUTES_PATHS.planningHp}
           sx={tabStyles}
      />
    </StyledTabs>
  );
};

export default HeaderPlaning;
