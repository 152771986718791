import { Theme } from "@mui/material/styles";
import {
  accordionClasses,
  accordionSummaryClasses
} from "@mui/material";

export const accordionStyles = (theme: Theme) => ({
  [`&.${accordionClasses.root} > .${accordionSummaryClasses.root} > .${accordionSummaryClasses.content}`]: {
    fontWeight: 500,
    color: theme.palette.blackAndWhite.black
  }
});