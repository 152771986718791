import yup from "@/utils/yup";
import { IPlot } from "@/api/interfaces/responses";
import { Dayjs } from "dayjs";

export const FIELDS_NAME = {
  PLOT: "plot",
  DATE: "date",
} as const;

export interface IFormik {
  [FIELDS_NAME.PLOT]: IPlot | null,
  [FIELDS_NAME.DATE]: [Dayjs | null, Dayjs | null],
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.PLOT]: yup.mixed().required(),
  [FIELDS_NAME.DATE]: yup.tuple([yup.date().nullable(), yup.date().nullable()])
});

export const initialValues = {
  [FIELDS_NAME.PLOT]: null,
  [FIELDS_NAME.DATE]: [null, null] as [null, null],
};