import React from "react";
import { FieldArray } from "formik";
import { Box } from "@mui/system";
import { Stack, Button, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  FIELDS_NAME,
  IFormik
} from "@/pages/AddObservation/const";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";
import {
  IndicatorMethodologyAutocomplete
} from "@/components/Autocompletes";
import IndicatorVariantMethodologyAutocomplete
  from "@/components/Autocompletes/IndicatorVariantMethodologyAutocomplete";
import theme from "@/theme";
import CloseIcon from "@mui/icons-material/Close";
import { TPlanningTrialType } from "@/api/types";

interface IProps {
  trialType?: TPlanningTrialType;
}

const Indicators: React.FC<IProps> = ({ trialType }) => {
  return (
    <Box>
      <Stack direction="column" spacing="20px">
        <Typography
          variant="h3">Признаки</Typography>
        <FieldArray
          name={FIELDS_NAME.INDICATORS}
          render={(helpers) => {
            const values = helpers.form.values[helpers.name] as IFormik["indicators"];
            return (
              <Stack direction="column" spacing={1.2}>
                {values.map((value, index) => {
                  return (
                    <Stack direction="column" key={index}
                           sx={{
                             padding: "10px",
                             border: `1px solid ${theme.palette.blackAndWhite.stroke}`
                           }}>
                      <Box mb="20px" display="flex"
                           justifyContent="space-between"
                           alignItems="center">
                        <Typography
                          variant="p14Medium">{`Признак ${index + 1}`}</Typography>
                        <IconButton
                          onClick={() => helpers.remove(index)}>
                          <CloseIcon
                            sx={{ color: theme.palette.red.main }}/>
                        </IconButton>
                      </Box>
                      <Box mb="10px">
                        <IndicatorMethodologyAutocomplete
                          name={`${helpers.name}.${index}.${FIELDS_NAME.INDICATOR}`}
                          q={{ type_trial_indicator: trialType }}
                          required/>
                      </Box>
                      <IndicatorVariantMethodologyAutocomplete
                        name={`${helpers.name}.${index}.${FIELDS_NAME.INDICATOR_VARIANT}`}
                        q={{ id: value[FIELDS_NAME.INDICATOR]?.id }}
                        disabled={!value[FIELDS_NAME.INDICATOR]}
                        required/>
                    </Stack>
                  );
                })}
                <Button
                  color={"blue"}
                  size={"large"}
                  startIcon={<AddOutlinedIcon/>}
                  variant={"outlined"}
                  onClick={() => helpers.push({
                    [FIELDS_NAME.INDICATOR]: null,
                    [FIELDS_NAME.INDICATOR_VARIANT]: null
                  })}
                >
                  Добавить признак
                </Button>
              </Stack>
            );
          }}
        />
      </Stack>
    </Box>
  );
};

export default Indicators;