export const STATUS_REQUEST = {
  loading: "ЗАГРУЗКА",
  error: "ОШИБКА ЗАГРУЗКИ",
  empty: "СПИСОК ПУСТ",
};
export const EMPTY_LIST_TEXT = "Еще не создано ни одной записи";
export const ADD_TEXT = "Добавить запись";
export const EMPTY_LIST_TEXT_WITH_FILTERS = "По указанным параметрам записи не найдены";



