import React, { useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  Input,
  Stack,
  Typography,
  styled,
  CircularProgress
} from "@mui/material";
import { Img } from "./styles";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop
} from "react-image-crop";
import { useDebounceEffect } from "@/hooks/useDebounce";
import { canvasPreview, getCroppedImageBlob } from "./components/CanvasPreview";

import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useStores } from "@/hooks";
import theme from "@/theme";
import { errorToString } from "@/utils/helpers";
import { AxiosError } from "axios";

// Импортируем стили ChangeImageWrapper из вашего файла
/**
 * @interface IChangeAvatar - Interface for the ChangeAvatar component props.
 * @property {() => void} handleClose - Callback function to handle the close action.
 */
interface IChangeAvatar {
  handleClose: () => void;
}
const RestyledReactCrop = styled(ReactCrop)`
  .ReactCrop__drag-handle {
    background-color: white !important; /* Цвет уголков */
    border: 3px solid ${theme.palette.green.main};
    width: 30px;
    height: 30px;
  }

  .ReactCrop__crop-selection {
    border: 3px solid ${theme.palette.green.main} !important; /* Цвет рамки */
    background-image: none !important; /* Заливка рамки */
  }
`;

/**
 * @function ChangeAvatar - React functional component for changing the avatar.
 * @param {IChangeAvatar} props - Props for the ChangeAvatar component.
 * @returns {JSX.Element} - JSX element representing the ChangeAvatar component.
 */
const ChangeAvatar: React.FC<IChangeAvatar> = ({ handleClose }) => {
  const { api, userStore } = useStores();
  const [error, setError] = useState<string | boolean>(false);
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [aspect] = useState<number | undefined>(8 / 8);

  /**
   * @function handleImageChange - Handles the change event when selecting an image.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];

      // Проверка типа файла
      if (!selectedFile.type.startsWith("image/")) {
        // Выбранный файл не является изображением
        console.error("Пожалуйста, выберите изображение.");
        setError("Пожалуйста, выберите изображение.");
        return;
      }

      // Проверка размера файла (в мегабайтах)
      const maxSizeInMB = 20;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (selectedFile.size > maxSizeInBytes) {
        setError(
          "Выбранный файл слишком большой. Пожалуйста, выберите файл размером до 20 МБ."
        );
        return;
      }

      setCrop(undefined);

      setError(false);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(selectedFile);
    }
  };

  /**
   * @function onImageLoad - Handles the image load event.
   * @param {React.SyntheticEvent<HTMLImageElement>} e - The synthetic event.
   */
  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const handleClickSaveNewImage = (): void => {
    setIsDisabledSaveBtn(true);
    if (imgRef.current && completedCrop)
      getCroppedImageBlob(imgRef.current, completedCrop).then((blob: Blob) => {
        const fd = new FormData();
        fd.append("avatar", blob, "avatar.jpg");
        api.user
          .postChangeAvatar(fd)
          .then(() => {
            userStore.updateCurrentUser();
            handleCancel();
            setIsDisabledSaveBtn(false);
          })
          .catch((err: AxiosError<any>) => {
            const error = errorToString(err);
            setError(error);
            setIsDisabledSaveBtn(false);
          });
      });
  };

  /**
   * @function centerAspectCrop - Centers and creates an aspect ratio crop.
   * @param {number} mediaWidth - The width of the media.
   * @param {number} mediaHeight - The height of the media.
   * @param {number} aspect - The aspect ratio.
   * @returns {Crop} - The centered aspect ratio crop.
   */
  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ): Crop => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  /**
   * @function handleCancel - Handles the cancel action.
   */
  const handleCancel = () => {
    handleClose();
  };

  /**
   * @function resetImage - Handles the reset image and back to one step.
   */
  const handleClickBackToSeletImage = () => {
    setImgSrc("");
  };

  /**
   * @function useDebouncedPreview - Debounces the preview update after cropping.
   */
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop]
  );

  /**
   * @constant VIEW_VARIANTS_BODY - Variants for the body content based on image loading.
   */
  const VIEW_VARIANTS_BODY = {
    NON_LOAD_IMAGE: (
      <Stack mt={1} spacing={1}>
        <Typography variant="body2">Поддерживаемые форматы: jpg, jpeg, png.</Typography>
        <Typography variant="body2">Изображение не должно превышать 20 MB.</Typography>
      </Stack>
    ),
    CHANGE_IMAGE: (
      <Stack mt={2} spacing={2}>
        <Typography variant="h4" fontSize={14}>
          Выбранная область будет отображаться в вашем профиле.
        </Typography>
        <Box sx={{ maxHeight: "500px", overflow: "scroll" }}>
          {imgSrc ? (
            <RestyledReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              minHeight={200}
              sx={{ width: "100%" }}
            >
              <Img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </RestyledReactCrop>
          ) : null}
        </Box>
      </Stack>
    )
  };

  /**
   * @constant VIEW_VARIANTS_FOTTER - Variants for the footer content based on image loading.
   */
  const VIEW_VARIANTS_FOTTER = {
    NON_LOAD_IMAGE: (
      <>
        <Button variant="text" color="red" onClick={handleCancel} sx={{padding:"6px 9px"}}> 
          Отмена
        </Button>
        <Stack direction="row" spacing={1}>
          <Button
            component="label"
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<FileDownloadOutlinedIcon />}
            sx={{padding:"6px 18px"}}
          >
            Выбрать
            <Input
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </Button>
        </Stack>
      </>
    ),
    CHANGE_IMAGE: (
      <>
        <Button
          onClick={handleClickBackToSeletImage}
          variant="outlined"
          color="secondary"
          size="medium"
        >
          <ArrowBackIosOutlinedIcon
            sx={{ marginRight: "8px", width: "20px", height: "20px" }}
          />
          Назад
        </Button>
        <Button
          onClick={handleClickSaveNewImage}
          variant="contained"
          color="primary"
          size="medium"
          disabled={isDisabledSaveBtn}
          startIcon={
            isDisabledSaveBtn ? (
              <CircularProgress size={10} color="inherit" />
            ) : (
              <CheckIcon />
            )
          }
        >
          Сохранить
        </Button>
      </>
    )
  };

  return (
    <Box>
      <Box p={3}>
        <Typography variant="h6" fontSize={24} component="h2">
          {imgSrc ? "Выберите изображение" : "Изменить аватар"}
        </Typography>
        {VIEW_VARIANTS_BODY[imgSrc ? "CHANGE_IMAGE" : "NON_LOAD_IMAGE"]}
      </Box>
      {error ? (
        <Box sx={{ padding: "0px 24px 24px 24px" }}>
          <Alert variant="outlined" severity="error">
            {error}
          </Alert>
        </Box>
      ) : null}
      <Divider />
      <Box p={3} display="flex" justifyContent="space-between">
        {VIEW_VARIANTS_FOTTER[imgSrc ? "CHANGE_IMAGE" : "NON_LOAD_IMAGE"]}
      </Box>
    </Box>
  );
};

export default ChangeAvatar;
