import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { POSTS_HEADER_LABELS, POSTS_KEYS } from "../const";

import { TPostsKeys } from "../interfaces";
import { EditingCellsPost } from "../templates/Post/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const PostsColumns: IColumn<TPostsKeys>[] = [
  {
    label: POSTS_HEADER_LABELS.name,
    key: POSTS_KEYS.name,
    contentHeader: POSTS_HEADER_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: POSTS_HEADER_LABELS.full_name,
    key: POSTS_KEYS.full_name,
    contentHeader: POSTS_HEADER_LABELS.full_name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: POSTS_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsPost,
      styles: cellButtons,
    },
  },
];
