import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell} from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { VARIETIES_KEYS, VARIETIES_LABELS } from "../const";

import { TVarietiesKeys } from "../interfaces";
import { EditingCellVariety, VarietyTypeCell, СultivarCell } from "../templates/Varieties/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const VarietiesColumns: IColumn<TVarietiesKeys>[] = [
  {
    label: VARIETIES_LABELS.code,
    key: VARIETIES_KEYS.code,
    contentHeader: VARIETIES_LABELS.code,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: VARIETIES_LABELS.name,
    key: VARIETIES_KEYS.name,
    contentHeader: VARIETIES_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: VARIETIES_LABELS.cultivar__name,
    key: VARIETIES_KEYS.cultivar__name,
    contentHeader: VARIETIES_LABELS.cultivar__name,
    bodyCellProps: {
      render: СultivarCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: VARIETIES_LABELS.type,
    key: VARIETIES_KEYS.type,
    contentHeader: VARIETIES_LABELS.type,
    bodyCellProps: {
      render: VarietyTypeCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: VARIETIES_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellVariety,
      styles: cellButtons,
    },
  },
];
