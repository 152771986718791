import { SxProps, Theme, styled } from "@mui/material";
import { ISizeAvatar } from "../interfaces";
import { SIZES_AVATAR } from "../const";

/**
 * Функция для переопределения стилей аватара пользователя.
 * @param {Theme} theme - Текущая тема Material-UI.
 * @param {ISizeAvatar} size - Размер аватара (medium или small).
 * @returns {SxProps} - Объект стилей для применения к аватару.
 */
export const initialsAvatar = (theme: Theme, size: ISizeAvatar): SxProps => ({
  color: theme.palette.blackAndWhite.white,
  backgroundColor: theme.palette.backgroundColors.avatarBg,
  width: `${SIZES_AVATAR[size].img}px`,
  height: `${SIZES_AVATAR[size].img}px`,
  fontSize: `${SIZES_AVATAR[size].text}px`
});

export const Img = styled("img")(({ theme }) => ({
  color: theme.palette.blackAndWhite.white,
  backgroundColor: theme.palette.backgroundColors.avatarBg,
  width: "100%",
  height: "100%"
}));
