import { reqInterface, resInterface } from "@/api/interfaces";
import yup from "@/utils/yup";

export interface IProps {
    handleClose: () => void;
    modalProps: {
      key: string;
      vegetationStageId?: number;
      initialValues?: resInterface.IVegetationStage;
    };
    onSubmit?: () => void;
  }

  export interface ICultivar {
    id: number,
    name: string,
  }

  export type TForm = Omit<reqInterface.IVegetationStageReq, "cultivar" > & {
    cultivar: {
      id: number;
    };
  };
  

  export enum VEGETATION_STAGE_FIELD_NAME {
    CULTIVAR = "cultivar", 
    NAME = "name", 
    DESCRIPTION = "description", 
  }

  export const mapValueToForm = (
    baseInititialValues,
    vegetationStageData: resInterface.IVegetationStage,
  ) => {
    return {
      ...baseInititialValues,
      ...{
        ...vegetationStageData,
        [VEGETATION_STAGE_FIELD_NAME.CULTIVAR]: {
          ...vegetationStageData.cultivar,
          label: vegetationStageData.cultivar?.name
        },
      },
    };
  };

  export const mapFormToValue = (values: TForm) => {
    return {
      ...values,
      [VEGETATION_STAGE_FIELD_NAME.CULTIVAR]: values?.cultivar?.id,
    };
  };


  /*Validation of the form*/

export const validationSchema = yup.object().shape({
    [VEGETATION_STAGE_FIELD_NAME.CULTIVAR]: yup.mixed().required(), 
    [VEGETATION_STAGE_FIELD_NAME.NAME]: yup.string()
      .required()
      .trim()
      .notOneOf([''], 'Это поле не может быть пустым'),
  
    [VEGETATION_STAGE_FIELD_NAME.DESCRIPTION]: yup.string()
    .trim()
    .notOneOf([''], 'Добавьте описание'),
  });
  