import qs from "qs";
import { useLocation } from "react-router";

// Определяем интерфейс для значений параметров запроса
interface QueryValue {
  [key: string]: string | string[] | undefined;
}

/**
 * @function
 * хук получения данных из квери параметров
 */
export function useQuery<T>() {
  const location = useLocation();
  const parseRes: T = {} as T;

  // Приводим тип queryParams к QueryValue
  const queryParams: QueryValue = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as QueryValue;

  for (const key in queryParams) {
    if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
      let value: string | string[] | undefined = queryParams[key];

      if (Array.isArray(value)) {
        // Если значение является массивом, сохраняем его как есть
        parseRes[key] = value;
      } else if (typeof value === "string") {
        if (value.includes(",")) {
          // Если значение содержит запятую, разбиваем его на массив строк
          value = value.split(",");
          parseRes[key] = value;
        } else if (value === "") {
          parseRes[key] = "";
        } else if (!isNaN(Number(value))) {
          // Если значение является числом, преобразуем его в число
          parseRes[key] = Number(value);
        } else {
          // Если значение не содержит запятую и не является числом, оставляем его как строку
          parseRes[key] = value;
        }
      } else {
        // Если значение не определено, устанавливаем пустую строку
        parseRes[key] = value;
      }
    }
  }
  return parseRes;
}
