import React from "react";
import { Field, Form, useFormikContext } from "formik";
import { TextField, Box, Button, Divider, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { FIELDS_NAME } from "../const";

interface IProps {
  modalProps: {
    id: number;
  };
  handleClose: () => void;
}

const AddInvestmentForm: React.FC<IProps> = ({ handleClose }) => {
  const formik = useFormikContext();

  return (
    <Form noValidate>
      <Grid container direction={"column"} mt={1} spacing={1} p={3}>
        <Grid p={1}>
          <Field name={FIELDS_NAME.NAME} required>
            {({ field, meta }) => (
              <TextField
                {...field}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                label="Название"
                fullWidth
                required
                size="small"
              />
            )}
          </Field>
        </Grid>
        <Grid container p={1} gap={3}>
          <Grid item xs={5.8}>
            <Field name={FIELDS_NAME.QUANTITY} required>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  type="number"
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                  label="Количество"
                  fullWidth
                  required
                  size="small"
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={5.8}>
            <Field name={FIELDS_NAME.PRICE} required>
              {({ field, meta }) => (
                <TextField
                  {...field}
                  type="number"
                  error={meta.touched && !!meta.error}
                  helperText={meta.touched && meta.error}
                  label="Цена, руб"
                  fullWidth
                  required
                  size="small"
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Box p={3} display="flex" justifyContent="space-between">
        <Button color="red" onClick={handleClose}>
          Отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon />}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default AddInvestmentForm;
