import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import {
  mapFormToValue,
  mapValueToForm,
  TForm,
  validationSchema,
  IProps,
  VARIETY_FIELD_NAME
} from "@/apps/Modals/ModalContent/CreateVariety/const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import CreateVarietyForm
  from "@/apps/Modals/ModalContent/CreateVariety/components/CreateVarietyForm";

/**
 * Модалка с формой создания/редактирования сорта
 * @param initialValue - начальные значения
 * @param isStatement - создание из заявки
 * @param cultivarEditable - флаг для редактирования культуры
 * @param submitOnMount - сабмит после монтирования
 * @param onSubmit - калбек после успешного запроса
 * */
const CreateVariety: React.FC<IProps> = ({
  handlePop,
  modalProps
}) => {
  const { onSubmit, initialValue, varietyId } = modalProps || {};
  const { api, toastStore } = useStores();
  const baseInititialValues =
  {
    [VARIETY_FIELD_NAME.NAME]: '',
    [VARIETY_FIELD_NAME.LATIN_VARIETY_NAME]: '',
    [VARIETY_FIELD_NAME.FULLNAME]: '',
    [VARIETY_FIELD_NAME.SELECTION_NUMBER]: '',
    [VARIETY_FIELD_NAME.CIPHER]: '',
    [VARIETY_FIELD_NAME.IS_STANDARD]: false,
    [VARIETY_FIELD_NAME.IS_BENCHMARK]: false,
    [VARIETY_FIELD_NAME.UPOV_CHECK]: false,
    [VARIETY_FIELD_NAME.IN_REGISTER]: false,
    [VARIETY_FIELD_NAME.CANDIDATE_FOR_STANDARD]: false,
    [VARIETY_FIELD_NAME.REGIONS]: [],
    [VARIETY_FIELD_NAME.TYPE]: null,
  }

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>
  ) => {
    helpers.setSubmitting(true);
    const promise = varietyId
      ? api.regulatoryInfo.patchVarietiesById(
        varietyId,
        mapFormToValue(values)
      )
      : api.regulatoryInfo.postVarietiesItem(mapFormToValue(values));
    promise
      .then((variety) => {
        onSubmit && onSubmit(variety);
        handlePop();
      }).catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error"
            }
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const values = initialValue
    ? {
      ...baseInititialValues,
      ...mapValueToForm(initialValue),
    }
    : baseInititialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
      component={() => <CreateVarietyForm
        handlePop={handlePop}
        modalProps={modalProps} />}
    />
  );
};

export default CreateVariety;
