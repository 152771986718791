import React, { ReactElement, useEffect, useLayoutEffect } from "react";

import { useStores } from "@/hooks";

import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { Box } from "@mui/material";
import { tableWrapperStyle } from "./style";
import theme from "@/theme";
import useRightSidebar from "@/hooks/useRightSidebar";
import AWTable from "./components/AWTable";

const AgriculturalWork = (): ReactElement => {
  const { headerStore } = useStores();
  const { handleCloseInstantly } = useRightSidebar();

  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.AGRICULTURE_WORK);
  }, []);

  // закрытие правого сайдбара при переходе на другую страницу
  useEffect(() => {
    return () => {
      handleCloseInstantly();
    };
  }, []);

  return (
    <Box sx={tableWrapperStyle(theme)}>
      <AWTable />
    </Box>
  );
};

export default AgriculturalWork;
