import React from "react";
import { FieldArray } from "formik";
import { Box } from "@mui/system";
import {
  Stack,
  Button,
  IconButton,
  Grid
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  FIELDS_NAME,
  IFormik
} from "@/pages/AddObservation/const";
import AddOutlinedIcon
  from "@mui/icons-material/AddOutlined";
import {
  DiseaseAutocomplete,
  UnitAutocomplete
} from "@/components/Autocompletes";
import theme from "@/theme";
import FastRegexTextField
  from "@/components/FastRegexTextField";
import CloseIcon from "@mui/icons-material/Close";

const Diseases = () => {
  return (
    <Box>
      <Stack direction="column" spacing="20px">
        <Typography
          variant="h3">Болезнь/Вредители</Typography>
        <FieldArray
          name={FIELDS_NAME.DISEASES}
          render={(helpers) => {
            const values = helpers.form.values[helpers.name] as IFormik["diseases"];
            return (
              <Stack direction="column" gap={1.2}>
                {values.map((_, index) => {
                  return (
                    <Stack direction="column" key={index}
                           sx={{
                             padding: "10px",
                             border: `1px solid ${theme.palette.blackAndWhite.stroke}`
                           }}>
                      <Box mb="20px" display="flex" justifyContent="space-between" alignItems="center">
                        <Typography
                          variant="p14Medium">{`Болезнь/Вредитель ${index + 1}`}</Typography>
                        <IconButton onClick={() => helpers.remove(index)}>
                          <CloseIcon sx={{color: theme.palette.red.main}}/>
                        </IconButton>
                      </Box>
                      <Box mb="10px">
                        <DiseaseAutocomplete
                          name={`${helpers.name}.${index}.${FIELDS_NAME.DISEASE}`}
                          label="Болезнь/Вредитель"
                          required/>
                      </Box>
                      <Grid container spacing={1.2}>
                        <Grid item xs={12} sm={6}>
                          <FastRegexTextField
                            name={`${helpers.name}.${index}.${FIELDS_NAME.DAMAGE}`}
                            label="Пораженность/ поврежденность"
                            regexType="number"
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <UnitAutocomplete
                            name={`${helpers.name}.${index}.${FIELDS_NAME.UNIT}`}
                            label="Единица измерения"
                            required
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  );
                })}
                <Button
                  color={"blue"}
                  size={"large"}
                  startIcon={<AddOutlinedIcon/>}
                  variant={"outlined"}
                  onClick={() => helpers.push({
                    [FIELDS_NAME.DISEASE]: null,
                    [FIELDS_NAME.DAMAGE]: "",
                    [FIELDS_NAME.UNIT]: null
                  })}
                >
                  Добавить Болезнь/Вредителя
                </Button>
              </Stack>
            );
          }}
        />
      </Stack>
    </Box>
  );
};

export default Diseases;