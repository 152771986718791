import { IEntity } from "@/api/interfaces/responses";
import yup from "@/utils/yup";

export const FIELDS_NAME = {
  SIGN: "sign", // признак
  INDICATOR: "indicator",
  METHODOLOGY: "methodology",
} as const;

export const defaultValues = {
  [FIELDS_NAME.SIGN]: null,
};

export interface IFormik {
  [FIELDS_NAME.SIGN]: IEntity;
  [FIELDS_NAME.INDICATOR]: any,
  [FIELDS_NAME.METHODOLOGY]: any,
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.SIGN]: yup.mixed().required(),
});

export const formToValue = (values: IFormik) => ({
  methodology: values.methodology,
  indicator: values.indicator,
});
