import { styled, Box } from "@mui/system";

export const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  "*": {
    flex: 1,
  },
  border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
  borderRadius: "4px",
  padding: "10px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start"
  }
}));