import React, { useState } from "react";
import { FastField, Field, useFormikContext } from "formik";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { IProps, PLOT_FIELD_NAME } from "../const";
import FastRegexTextField from "@/components/FastRegexTextField";
import Autocomplete from "@/components/Autocomplete";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { CustomPaper } from "../../Employee/components/EmployeeFormSteps/first";


const PlotFormFirstStep: React.FC<IProps> = () => {
  const formik = useFormikContext<any>();
  const { api } = useStores();
  const [plDirectionSearch, setPlDirectionSearch] = useState("");
  const [districtSearch, setDistrictSearch] = useState("");
  const [branchSearch, setBranchSearch] = useState("");

  // Plot Direction
  const getKeyPlDirection = (index: number) => ({
    _key: SWR_KEYS.getPlotDirection(),
    page: index + 1,
    search: plDirectionSearch
  });

  const { data: dataPlDirection, handleScroll: handleScrollPlDirection } = useInfiniteScroll(
    getKeyPlDirection,
    api.regulatoryInfo.getPlotDirection,
    {
      revalidateFirstPage: false,
    },
  );

  const handleChangePlDirection = (event, value) => {
    formik.setFieldValue(PLOT_FIELD_NAME.PLOT_DIRECTION, value);
  };

  // District

  const getKeyDistrict = (index: number) => ({
    _key: SWR_KEYS.getDistrictList(),
    page: index + 1,
    search: districtSearch
  });

  const { data: dataDistrict, handleScroll: handleScrollDistrict } = useInfiniteScroll(
    getKeyDistrict,
    api.regulatoryInfo.getDistrictsList,
    {
      revalidateFirstPage: false,
    },
  );

  const handleChangeDistrict = (event, value) => {
    formik.setFieldValue(PLOT_FIELD_NAME.DISTRICT, value);
    formik.setFieldValue(PLOT_FIELD_NAME.BRANCH, {...value.branch, label: value.branch.name});
  };


  // Branch

  const getKeyBranch = (index: number) => ({
    _key: SWR_KEYS.getBranchList(),
    page: index + 1,
    search: branchSearch
  });

  const { data: dataBranch, handleScroll: handleScrollBranch } = useInfiniteScroll(
    getKeyBranch,
    api.regulatoryInfo.getBranchList,
    {
      revalidateFirstPage: false,
    },
  );

  const handleChangeBranch = (event, value) => {
    formik.setFieldValue(PLOT_FIELD_NAME.BRANCH, value);
  };

  return (
    <Stack direction={"column"} gap={2}>
      <FastField name={PLOT_FIELD_NAME.STATUS}>
        {({ field }) => (
          <FormControl>
            <RadioGroup {...field}>
              <Stack direction="row">
                <FormControlLabel
                  value={'active'}
                  control={<Radio color="secondary" />}
                  label="Действующий"
                />
                <FormControlLabel
                  value={'closed'}
                  control={<Radio color="secondary" />}
                  label="Закрытый"
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        )}
      </FastField>
      <FastRegexTextField
        name={PLOT_FIELD_NAME.NAME}
        label={"Название"}
        fullWidth
        size="small"
        required
      />
      <FastRegexTextField
        name={PLOT_FIELD_NAME.FULL_NAME}
        label={"Полное название"}
        fullWidth
        size="small"
      />
      <FastRegexTextField
        name={PLOT_FIELD_NAME.SHORT_NAME}
        label={"Краткое название"}
        fullWidth
        size="small"
      />
      <Field name={PLOT_FIELD_NAME.DISTRICT}>
        {({ field, meta }) => (
          <FormControl size='small' variant='outlined' fullWidth>
            <Autocomplete
              {...field}
              label='Регион РФ'
              PaperComponent={CustomPaper}
              onChange={handleChangeDistrict}
              data={dataDistrict}
              handleScroll={handleScrollDistrict}
              search={setDistrictSearch}
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              required
            />
          </FormControl>
        )}
      </Field>
      <Field name={PLOT_FIELD_NAME.BRANCH}>
        {({ field, meta }) => (
          <FormControl size='small' variant='outlined' fullWidth>
            <Autocomplete
              {...field}
              label='Филиал'
              PaperComponent={CustomPaper}
              onChange={handleChangeBranch}
              data={dataBranch}
              handleScroll={handleScrollBranch}
              search={setBranchSearch}
              error={meta.touched && !!meta.error}
              helperText={meta.touched && meta.error}
              disabled={!formik.values.district}
              required
            />
          </FormControl>
        )}
      </Field>
      <Field name={PLOT_FIELD_NAME.PLOT_DIRECTION}>
        {({ field, meta }) => (
          <FormControl size='small' variant='outlined' fullWidth>
            <Autocomplete
              {...field}
              label='Направление'
              PaperComponent={CustomPaper}
              onChange={handleChangePlDirection}
              data={dataPlDirection}
              handleScroll={handleScrollPlDirection}
              search={setPlDirectionSearch}
              error={meta.touched && !!meta.error}

            />
          </FormControl>
        )}
      </Field>
      <FastRegexTextField
        name={PLOT_FIELD_NAME.VARIETY_ZONE_ID}
        label={"Зона сортового районирования"}
        fullWidth
        size="small"
        regexType="number"
      />
    </Stack>
  );
};

export default PlotFormFirstStep;
