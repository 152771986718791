import React, {
  Fragment,
  ReactElement,
  useMemo,
  useState,
} from "react";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import qs from "qs";
import {
  useInfiniteScroll,
  useQuery,
  useStores,
} from "@/hooks";
import useRightSidebar from "@/hooks/useRightSidebar";
import { Box, Button, useTheme } from "@mui/material";
import Table from "@/apps/Table";
import {
  Add as AddIcon,
  Map as MapIcon,
} from "@mui/icons-material";
import {
  IAWQueryParams,
  TAWPlotsKeys,
} from "../interfaces";
import { isUndefined } from "@/utils/helpers";
import {
  KEYS_SIDEBAR_VARIANT_MODULES,
  SWR_KEYS,
} from "@/const";
import { columns } from "../models";
import {
  ADD_AWPLOTS_TEXT,
  EMPTY_LIST_AWPLOTS_TEXT,
  EMPTY_LIST_AWPLOTS_TEXT_WITH_FILTERS,
  REGION_KEY,
} from "../const";
import {
  IAgriculturalLabourLocation,
  IRegion,
} from "@/api/interfaces/responses";
import {
  ROUTES,
  ROUTES_PATHS,
} from "@/apps/AppRouter/const";
import { mapButtonStyle } from "../style";
import Filters from "@/components/Filters";
import { getFilters } from "../utils";
import { IValue } from "@/interfaces";
import useSWR from "swr";
import { DEFAULT_SIZE_PAGE } from "@/components/Pagination/const";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 *
 * Компонент, представляющий таблицу СХ работ - участки.
 * @returns {ReactElement}
 */

const AWTable = (): ReactElement => {
  const {
    api,
    queryStringSidebarCollector,
    swrStore,
    userStore
  } = useStores();
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { handleOpen } = useRightSidebar();
  const query = useQuery<IAWQueryParams>();

  // переменная принимающая параметры сортировки из query параметров и преобразует их к массиву если они не равны
  const orderingArray: string[] = useMemo(() => {
    if (isUndefined(query.ordering)) return [];
    return Array.isArray(query.ordering)
      ? query.ordering
      : [query.ordering];
  }, [query.ordering]);
  const theme = useTheme();

  const [orderBy, setOrderBy] = useState<string[]>([
    ...new Set(["-created_at", ...orderingArray]),
  ]);
  const [page, setPage] = useState<number>(query.page ?? 1);

  const KEY_SWR =
    SWR_KEYS.getAgriculturalLabourLocationList();
  const { data, error, isLoading, mutate } = useSWR(
    {
      page: page,
      page_size: DEFAULT_SIZE_PAGE,
      ordering: orderBy.join(","),
      search: query.search ?? undefined,
      branch: query.branch?.key ?? undefined,
      region: query.region?.key ?? undefined,
      _key: KEY_SWR,
    },
    api.agriculturalLabour.getLocationList,
  );

  const handleChangePage = (value: number) => {
    setPage(value);

    const queryParams = qs.stringify(
      { ...query, page: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(queryParams);
  };

  const handleChangeOrderBy = (value: TAWPlotsKeys[]) => {
    const params = qs.stringify(
      { ...query, ordering: value },
      { arrayFormat: "comma" },
    );
    setSearchParams(params);
    setOrderBy(value);
  };

  const handleOpenMap = () => {
    navigate({
      pathname: ROUTES_PATHS.map,
    });
  };

  const handleOpenCreatePlot = () => {
    navigate(ROUTES.addArea);
  };

  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   *
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */

  const handleOpenRightSidebar = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {
    const { str } = queryStringSidebarCollector.setup({
      tab: undefined,
      keyContent:
        KEYS_SIDEBAR_VARIANT_MODULES.AGRICULTURAL_WORKS,
      module_id: id,
    });

    handleOpen({
      type: KEYS_SIDEBAR_VARIANT_MODULES.AGRICULTURAL_WORKS,
      modalProps: {
        id,
      },
    });

    setSearchParams(str);
  };

  const getKey = (index: number) => {
    return {
      _key: REGION_KEY,
      page: index + 1,
      search: query.search_region,
    };
  };

  const { data: regions, handleScroll } =
    useInfiniteScroll<IRegion>(
      getKey,
      api.regulatoryInfo.getRegionList,
      {
        revalidateFirstPage: false,
      },
    );

  const region: IValue[] = regions.map(({ name, id }) => {
    return { label: name, key: id };
  });

  const filters = getFilters(
    handleScroll,
    region,
    query.region,
  );

  swrStore.addMutator(KEY_SWR, mutate);

  const canAddLocation = userStore.isPermission(
    PERMISSIONS.agriculturalLabourLocation,
    PERMISSION_CRUD.add
  )
  const canViewMap = userStore.isPermission(
    PERMISSIONS.regulatory_infoPlot,
    PERMISSION_CRUD.view
  )

  return (
    <Fragment>
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        <Filters
          filters={filters}
          defaultSearch={query.search}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          {canViewMap && 
          <Button
            sx={mapButtonStyle(theme)}
            variant="outlined"
            startIcon={<MapIcon />}
            onClick={handleOpenMap}
          >
            Карта
          </Button>
          }

          {canAddLocation && 
          <Button
            sx={{
              whiteSpace: "nowrap",
              flexShrink: "0",
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpenCreatePlot}
            data-qa="buttonNewRegion"
          >
            Добавить участок
          </Button>
          }
        </Box>
      </Box>

      <Table<IAgriculturalLabourLocation, TAWPlotsKeys>
        columns={columns}
        isLoading={isLoading}
        data={data}
        error={error}
        page={page}
        setPage={handleChangePage}
        orderBy={orderBy}
        setOrderBy={handleChangeOrderBy}
        isFilters={false}
        textAdd={ADD_AWPLOTS_TEXT}
        textEmptyTableWithFilter={
          EMPTY_LIST_AWPLOTS_TEXT_WITH_FILTERS
        }
        textEmptyTable={EMPTY_LIST_AWPLOTS_TEXT}
        onRowClick={handleOpenRightSidebar}
      />
    </Fragment>
  );
};

export default AWTable;
