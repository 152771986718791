import React from "react";
import AddOrderCultivar from "@/pages/AddOrderCultivar";

/** Страница редактирования культуры приказа
 *  */

const EditOrderCultivarPage = () => {
  return <AddOrderCultivar headerTitle="Редактирование культуры" />;
};

export default EditOrderCultivarPage;
