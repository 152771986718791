import React from "react";
import { Box, Typography } from "@mui/material";
import { useStores } from "@/hooks";
import { observer } from "mobx-react";

/**
 * хедер с динамическим тайтлом
 * @returns React.FC
 */
const HeaderHandbookAuto: React.FC = () => {
  const { headerStore } = useStores();
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="h3" noWrap>
        {headerStore.getTitle()}
      </Typography>
    </Box>
  );
};

export default observer(HeaderHandbookAuto);
