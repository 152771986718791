import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Table as TableMui,
  TableContainer,
  Typography,
  useTheme
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import TableContent from "./components/TableContent";
import TableHead from "./components/TableHead";
import {
  DEFAULT_SIZE_PAGE
} from "@/components/Pagination/const";
import { LabProfileIcon } from "../../components/icons";
import Pagination from "@/components/Pagination";

import { IColumn, TEntityTable } from "./interfaces";
import {
  IPaginatedResult
} from "@/api/interfaces/responses";

import {
  ADD_TEXT,
  EMPTY_LIST_TEXT,
  EMPTY_LIST_TEXT_WITH_FILTERS,
  STATUS_REQUEST
} from "./const";

import { emptyListStyle } from "./style";
import { isUndefined } from "@/utils/helpers";

/**
 * Интерфейс свойств компонента Table.
 * @interface
 * @template T - Тип данных текущей строки таблицы.
 */
interface IProps<T, TColums> {
  /**
   * Массив колонок таблицы.
   */
  columns: IColumn<TColums>[];
  /**
   * Данные для отображения в таблице.
   */
  data?: IPaginatedResult<T>;
  /**
   * Флаг загрузки данных.
   */
  isLoading: boolean;
  /**
   * Ошибка при загрузке данных.
   * Не нашел способ типизировать ошибку из SWR
   */
  error: any;
  /**
   * номер текущей страницы
   */
  page: number;
  /**
   * сеттер текущей страницы
   */
  setPage: (value: number) => void;
  /**
   * номер текущей страницы
   */
  orderBy?: string[];
  /**
   * сеттер текущей страницы
   */
  setOrderBy?: (value: string[]) => void;
  /**
   * включены ли фильтры
   */
  isFilters: boolean;
  /**
   * текст добавления сущности
   */
  textAdd?: string;
  /**
   * текст при отсутствии елементов в таблице при активных фильтрах
   */
  textEmptyTableWithFilter?: string;
  /**
   * текст при отсутствии элементов в теблице
   */
  textEmptyTable?: string;
  /**
   * обработчик клика на строку
   */
  onRowClick?: (event: React.MouseEvent<unknown>, id: number) => void;
  /**
   * создание сущности если таблица пустая
   */
  emptyTableAddEntity?: () => void;

  /**
   * минимальная высота контейнера лоадера
   */

  minHeightLoaderBox?: string;
  /**
   * кнопка добавления установка через пермишен
   * ? пока по умолчанию МОЖНО потом надо поменять на нельзя
   */
  isPermissionAdd?: boolean;
  /**
   * метод для рендера компонента загрузки
   */
  renderLoader?: () => React.ReactElement;
}

/**
 * Компонент, представляющий таблицу с пагинацией.
 * @param {IProps<T, TColums>} props - Свойства компонента.
 * @returns {JSX.Element}
 * @template T
 */
function Table<T extends TEntityTable, TColums extends string>({
  columns,
  data = undefined,
  isLoading,
  error,
  page,
  setPage,
  orderBy = [],
  setOrderBy,
  isFilters,
  textAdd = ADD_TEXT,
  isPermissionAdd = true,
  textEmptyTable = EMPTY_LIST_TEXT,
  textEmptyTableWithFilter = EMPTY_LIST_TEXT_WITH_FILTERS,
  onRowClick,
  emptyTableAddEntity,
  minHeightLoaderBox = "700px",
  renderLoader,
}: IProps<T, TColums>): JSX.Element {
  const theme = useTheme();
  const [selected, setSelected] = useState<number>();
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    let newOrderBy = [...orderBy]; // Создаем копию массива orderBy

    switch (true) {
      case newOrderBy.includes(property):
        // Если свойство есть в массиве orderBy
        newOrderBy = newOrderBy.filter(
          (item) => item !== property && item !== `-${property}`
        );
        newOrderBy.unshift(`-${property}`); // Добавляем свойство с минусом в начало массива
        break;
      case newOrderBy.includes(`-${property}`):
        // Если отрицательное свойство есть в массиве orderBy
        newOrderBy = newOrderBy.filter((item) => item !== `-${property}`);
        break;
      default:
        // В остальных случаях добавляем свойство с минусом в начало массива
        newOrderBy.unshift(property);
        break;
    }

    setOrderBy && setOrderBy(newOrderBy); // Устанавливаем новое значение orderBy
  };

  if (isLoading || data === undefined)
    return (
      renderLoader ? renderLoader() : (
        <Box
          sx={{
            width: "100%",
            minHeight: minHeightLoaderBox,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress />
        </Box>
      )
    );
  if (error) return <Box>{STATUS_REQUEST.error}</Box>;

  if (data?.count === 0)
    return (
      <Box sx={emptyListStyle(theme)}>
        <LabProfileIcon className="empty_list_icon" />

        <Typography sx={{ textAlign: "center" }}
          className="empty_list_text">
          {isFilters ? textEmptyTableWithFilter : textEmptyTable}
        </Typography>

        {!isUndefined(emptyTableAddEntity) && isPermissionAdd ? (
          <Button onClick={emptyTableAddEntity}
            variant="contained">
            <AddIcon />
            {textAdd}
          </Button>
        ) : null}
      </Box>
    );

  return (
    <>
      <TableContainer sx={{ overflow: 'hidden' }}>
        <TableMui>
          <TableHead<TColums>
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.results.length}
            columns={columns}
          />

          <TableContent<T, TColums>
            visibleRows={data.results}
            selected={selected}
            setSelected={setSelected}
            columns={columns}
            onRowClick={onRowClick}
          />
        </TableMui>
      </TableContainer>

      <Pagination
        currentPage={page}
        total={data?.count ?? 0}
        pageSize={DEFAULT_SIZE_PAGE}
        handleChange={setPage}
      />
    </>
  );
}

export default Table;
