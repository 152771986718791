import React from "react";
import { Field, useFormikContext } from "formik";
import { TForm, EMPLOYEE_FIELD_NAME, IProps } from "../../const";

import {
  Box,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { EmailField } from "./email";
import { PhoneField } from "./phone";

const EmployeeFormSecondStep: React.FC<IProps>  = () => {
  const form = useFormikContext<TForm>();

  return (
    <>
      <Stack direction={"column"} gap={2}>
        <Box>
          <Typography>Телефоны</Typography>
          <PhoneField />
        </Box>
        <Box>
          <Typography>Электронные почты</Typography>
          <EmailField />
        </Box>
        <Field name={EMPLOYEE_FIELD_NAME.COMMENT}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                label='Комментарий'
                fullWidth
                size='small'
                multiline
                minRows={4}
                maxRows={10}
                sx={{ minHeight: "150px" }}
                onChange={form.handleChange}
                onBlur={field.handleBlur}
                value={form.values[EMPLOYEE_FIELD_NAME.COMMENT]}
                disabled={meta.isSubmitting}
              />
              <FormHelperText error={meta.touched && !!meta.error}>
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
      </Stack>
    </>
  );
};

export default EmployeeFormSecondStep;
