import React, { Fragment } from "react";
import { Form, useFormikContext, FastField } from "formik";
import {
  Box,
  Button,
  Divider,
  Stack,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME, IForm } from "../const";
import { DatePickerField } from "@/components/Fields";
import theme from "@/theme";
import FastRegexTextField
  from "@/components/FastRegexTextField";

/**
 * Форма создания/редактирования урожайности
 * */

interface IProps {
  id?: number;
  handleClose: () => void;
  subtitle?: string;
}

const requrrenceData = [1,2,3,4]

const AddProductivityForm: React.FC<IProps> = ({
  id,
  handleClose,
  subtitle = ""
}) => {
  const form = useFormikContext<IForm>();

  const title = id
    ? "Редактировать урожай"
    : "Добавить урожай";

  return (
    <Fragment>
      <Box p={3}>
        <Typography sx={{ fontWeight: 400, fontSize: 12 }}
                    color={theme.palette.blackAndWhite.gray}>{subtitle}</Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider/>
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={3}>
          <DatePickerField name={FIELDS_NAME.HARVEST_DATE} required label="Дата сбора урожая"/>
          <FastField name={FIELDS_NAME.RECURRENCE}>
            {({ field }) => (
              <FormControl>
                <FormLabel>Повторение:</FormLabel>
                <RadioGroup {...field}>
                  <Stack direction="row">
                    {requrrenceData.map(el => (
                      <FormControlLabel
                        key={el}
                        value={el}
                        control={<Radio color="secondary" disableRipple={true} />}
                        label={el}
                      />
                    ))}
                  </Stack>
                </RadioGroup>
              </FormControl>
            )}
          </FastField>
          <FastRegexTextField name={FIELDS_NAME.WEIGHT} regexType="decimal" label="Вес урожая с учетной площади делянки (для одного повторения), ц" required/>
          <FastRegexTextField name={FIELDS_NAME.HUMIDITY} regexType="decimal" label="Влажность при уборке, %" required/>
          <FastRegexTextField name={FIELDS_NAME.STANDARD_HUMIDITY} regexType="decimal" label="Стандартная влажность (для данной культуры)" required/>
          <FastRegexTextField name={FIELDS_NAME.THOUSAND_GRAINS_WEIGHT} regexType="decimal" label="Масса 1000 зерен, г"/>
          <FastRegexTextField name={FIELDS_NAME.GRAIN_NATURE} regexType="decimal" label="Натура зерна, г/л"/>
        </Stack>

        <Divider/>
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={handleClose}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={form.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon/>}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default AddProductivityForm;
