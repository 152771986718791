import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import AddExpertTrialForm
  from "./components/AddExpertTrialForm";
import {
  IFormik,
  initialValues,
  validationSchema,
  valueToForm,
} from "./const";
import { IEntity } from "@/api/interfaces/responses";
import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import { VARIETY_TYPE } from "@/const";

/**
 * Компонент модалки добавления исптыния в план экспертной оценки
 */

interface IProps {
  handlePop: () => void;
  modalProps: {
    key: string,
    planId: number,
    id: number,
    cultivar: IEntity
    initialValues?: IFormik,
    handleSubmit: () => void
  };
}

const AddExpertTrial: React.FC<IProps> = (props) => {
  const { api, toastStore } = useStores();

  const showErrorToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error",
      },
    });
  };
  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const data = {
      plan: props.modalProps.planId,
      trial: values.trial?.id,
      type: VARIETY_TYPE.TRIAL,
      standards: values.standard ? [values.standard.id] : undefined
    };
    const promise = props.modalProps.id ? api.planning.patchVarietyPlanPlot(props.modalProps.id, data) : api.planning.postVarietyPlanPlot([data])
    helpers.setSubmitting(true)
    promise.then(() => {
      props.modalProps.handleSubmit && props.modalProps.handleSubmit();
      props.handlePop();
    }).catch((error) => {
      showErrorToast(error)
    }).finally(() => {
      helpers.setSubmitting(false)
    })
  };

  const values = props.modalProps?.initialValues ? valueToForm(props.modalProps?.initialValues) : initialValues;

  const title = props.modalProps.id ? "Редактировать испытание" : "Добавить испытание";

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {title}
        </Typography>
      </Box>
      <Divider/>
      <Formik<IFormik>
        initialValues={values}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => <AddExpertTrialForm
          modalKey={props.modalProps.key}
          cultivar={props.modalProps.cultivar}/>}
      />
    </Box>
  );
};

export default AddExpertTrial;
