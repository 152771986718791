import React, { Fragment }from 'react'
import { useStores } from '@/hooks';
import { Field, Form, useFormikContext } from 'formik';
import { Box, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack,Typography } from '@mui/material';
import { CHEMISTRY_FIELD_NAME, IProps, TForm } from '../const';
import CheckIcon from "@mui/icons-material/Check";
import theme from '@/theme';
import FastRegexTextField from '@/components/FastRegexTextField';

/**
 * Форма создания/редактирования фазы вегетации
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * */

export const ChemistryForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<TForm>();

  const title = modalProps.chemistryId
    ? "Редактировать запись"
    : "Добавить запись";

  return (
    <Fragment>
      <Box p={3}>
        <Typography fontSize={12} color={theme.palette.blackAndWhite.gray}>
            Химия
        </Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>

        <Stack direction={"column"} gap={2} p={2}>
          <FastRegexTextField
            name={CHEMISTRY_FIELD_NAME.NAME}
            label='Название'
            fullWidth
            size="small"
            required
          />
          <Field name={CHEMISTRY_FIELD_NAME.TYPE}>
              {({ field }) => (
                <FormControl>
                  <RadioGroup {...field} >
                    <Stack direction="row">
                      <FormControlLabel
                        sx={{width: 'fit-content'}}
                        value={'fertilizer'}
                        control={
                          <Radio color="secondary" />
                        }
                        label="Удобрение"
                      />
                      <FormControlLabel
                        sx={{width: 'fit-content'}}
                        value={'pesticide'}
                        control={
                          <Radio color="secondary" />
                        }
                        label="Средство защиты"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            </Field>
        </Stack>

        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red'
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type='submit'
            disabled={formik.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  )
}
