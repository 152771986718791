import React from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";

/**
 * хедер для страницы добавления участка
 */
const HeaderAddArea = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`${ROUTES_PATHS.agriculturalWork}`);
  };

  const title = id ? "Редактировать участок" : "Добавить участок";

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <IconButton onClick={handleBack}>
        <NavigateBeforeIcon />
      </IconButton>
      <Box>
        <Typography fontSize={24} fontWeight={500} variant="h3" noWrap>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default HeaderAddArea;
