import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";
import theme from "@/theme";
import { MODULES } from "@/pages/Handbook/fixtures";

const Empty = () => {
  const { userStore } = useStores();

  const isViewHandbook = userStore.isPermission(
    PERMISSIONS.regulatory_infoMethodology,
    PERMISSION_CRUD.view
  );

  return (
    <>
      <Typography>
        Описание не добавлено к Культуре. Для отображения признаков внесите
        описание в справочник Методики
      </Typography>
      {isViewHandbook ? (
        <Link href={`${ROUTES_PATHS.handbook}?module=${MODULES.methodology.id}`}>К Методикам</Link>
      ) : (
        <Tooltip title={"У вас недостаточно прав"} followCursor>
          <Box>
            <Link sx={{ color: theme.palette.blackAndWhite.stroke }} href={"#"}>
              К Методикам
            </Link>
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default Empty;
