import React, { useState, PropsWithChildren } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Chip,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "@/theme";

export interface ICollapseDefault {
  count: number;
  label: string;
  defaultExpanded?: boolean;
}

const CollapseDefault = ({
  label,
  count,
  children,
  defaultExpanded = false,
}: PropsWithChildren<ICollapseDefault>) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      elevation={0}
      sx={{
        "&:before": { height: "0px" },
        padding: "0",
        marginTop: "10px",
        border: "none",
      }}
      disableGutters
      expanded={expanded}
      onChange={handleExpand}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              color: expanded
                ? theme.palette.blue.main
                : theme.palette.blackAndWhite.black,
            }}
          />
        }
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          padding: "0 10px",
          background: expanded
            ? theme.palette.backgroundColors.lightGray
            : "transparent",
          borderBottom: expanded
            ? `1px solid ${theme.palette.blue.lightBlue}`
            : `1px solid ${theme.palette.blackAndWhite.stroke}`,
          ".MuiAccordionSummary-content": {
            margin: 0,
          },
        }}
      >
        <Typography
          variant={expanded ? "p14Medium" : "caption"}
          lineHeight="inherit"
        >
          {label}
        </Typography>
        <Chip
          label={count}
          size="small"
          variant="outlined"
          sx={{
            margin: "0 10px 0 auto",
          }}
        />
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "10px 0 0 20px",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CollapseDefault;
