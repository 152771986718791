import yup from "@/utils/yup";
import {
  IObservationDisease
} from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  DISEASE: "disease",
  DAMAGE: "damage",
  UNIT: "unit",
} as const;

interface IValue {
  id: number;
  label: string;
}

export interface IFormik {
  [FIELDS_NAME.DISEASE]: IValue | null,
  [FIELDS_NAME.DAMAGE]: number | string
  [FIELDS_NAME.UNIT]: IValue | null
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.DISEASE]: yup.mixed().required(),
  [FIELDS_NAME.UNIT]: yup.mixed().required(),
  [FIELDS_NAME.DAMAGE]: yup.number().required(),
});

export const initialValues = {
  [FIELDS_NAME.DISEASE]: null,
  [FIELDS_NAME.DAMAGE]: "",
  [FIELDS_NAME.UNIT]: null,
};

export const serializeValueToForm = (values: IObservationDisease): IFormik => {
  return {
    disease: values.disease ? {
      ...values.disease,
      label: values.disease.name
    } : null,
    damage: values.damage || "",
    unit: values.unit ? {
      ...values.unit,
      label: values.unit.name
    } : null
  };
};