import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import {
  mapFormToValue,
  TForm,
  validationSchema,
  IProps,
} from "@/apps/Modals/ModalContent/Varieties/const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import VarietyForm
  from "@/apps/Modals/ModalContent/Varieties/components/VarietyForm";
import { SWR_KEYS } from "@/const";

/**
 * Модалка с формой создания/редактирования сорта
 * @param initialValue - начальные значения
 * @param isStatement - создание из заявки
 * @param cultivarEditable - флаг для редактирования культуры
 * @param submitOnMount - сабмит после монтирования
 * @param onSubmit - калбек после успешного запроса
 * */
const Variety: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const baseInititialValues: TForm = Object.assign(
    {
      name: undefined,
      latin_variety_name: undefined,
      fullname: undefined,
      selection_number: undefined,
      cultivar: undefined,
      cipher: undefined,
      is_standard: false,
      is_benchmark: false,
      UPOV_check: false,
      in_register: false,
      is_indicator: false,
      regions:[],
      type: null,
    },
    modalProps.initialValues,
  );

  const { api, toastStore, swrStore } = useStores();
  const {varietyId} = modalProps;

  const mutateDetail = varietyId ? swrStore.mutators[SWR_KEYS.getVarietyById(varietyId)] :  null

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const promise = varietyId
      ? api.regulatoryInfo.patchVarietiesById(
          varietyId,
          mapFormToValue(values),
        )
      : api.regulatoryInfo.postVarietiesItem(mapFormToValue(values));

    promise
      .then(() => {
        mutateDetail && mutateDetail();
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail()
        swrStore.mutators[SWR_KEYS.getVarietyList()]();

      });
  };

  const values = modalProps.initialValues
    ? {
      ...baseInititialValues,
      modalProps
    }
    : baseInititialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      component={() => (
        <VarietyForm handleClose={handleClose} modalProps={modalProps} />
      )}
    />
  );
};

export default Variety;
