import { styled } from "@mui/material/styles";
import {
  stepIconClasses,
  stepLabelClasses,
  Stepper as MuiStepper,
  svgIconClasses,
} from "@mui/material";

export const Stepper = styled(MuiStepper)(({ theme }) => ({
  height: 200,
  "& .MuiStepConnector-line": {
    height: "100%",
  },
  [`& .${stepIconClasses.text}`]: {
    fontSize: 12
  },
  [`& .${svgIconClasses.root}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    color: theme.palette.blackAndWhite.black,
  },
  [`& .${stepLabelClasses.label}`]: {
    fontWeight: 500
  },
  [`& .${stepLabelClasses.labelContainer} .Mui-active`]: {
    color: theme.palette.primary.main
  }
}));