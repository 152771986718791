import { SWR_KEYS } from "@/const";
import { useInfiniteScroll, useStores } from "@/hooks";
import {
  FormControl
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import React, { useState } from "react";
import { EMPLOYEE_FIELD_NAME, TForm } from "../../../const";
import Autocomplete from "@/components/Autocomplete";
import { CustomPaper } from "../first";



export const SubdivisionBranch: React.FC = () => {
  const form = useFormikContext<TForm>();
  const { api } = useStores();
  const [branchSearch, setBranchSearch] = useState("");
  const swrKeyBranch = SWR_KEYS.getBranchList();


  const getKeyBranch = (index: number) => ({
    _key: swrKeyBranch,
    page: index + 1,
    search: branchSearch
  });

  const fetcherBranch = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getBranchList({
      page,
      search,
    });
  };

  const { data, handleScroll } = useInfiniteScroll(
    getKeyBranch,
    fetcherBranch,
    {
      revalidateFirstPage: false,
    },
  );


  /**
   * Метод для изменения филиала
   * @param event
  */

  const handleChange = (event, value) => {
    form.setFieldValue(EMPLOYEE_FIELD_NAME.BRANCH, value);
  };

  return (
    <Field name={EMPLOYEE_FIELD_NAME.BRANCH}>
      {({ field, meta }) => (
        <FormControl size='small' variant='outlined' fullWidth>
          <Autocomplete
            {...field}
            PaperComponent={CustomPaper}
            label='Филиал'
            onChange={handleChange}
            data={data}
            handleScroll={handleScroll}
            search={setBranchSearch}
            error={meta.touched && !!meta.error}
          />
        </FormControl>
      )}
    </Field>
  );
};
