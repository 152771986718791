import {
  styled,
  SxProps,
  Theme
} from "@mui/material/styles";
import {
  MenuItem,
  svgIconClasses,
  Typography
} from "@mui/material";

// стили для ячеек таблицы С/Х Работы

export const tableWrapperStyle = (theme: Theme): SxProps => ({
  width: "100%",
  padding: theme.spacing(3),
});

export const headerCellStyle = (theme: Theme): SxProps => ({
  whiteSpace: "nowrap",
  color: theme.palette.blackAndWhite.black,
  fontWeight: "500",
});

export const cellGSU = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  maxWidth: "600px",
});

export const cell = (theme: Theme): SxProps => ({
  padding: theme.spacing(2),
  maxWidth: "300px",
  [theme.breakpoints.down('sm')]: {
    maxWidth: "100%",
  }
});

export const mapButtonStyle = (theme: Theme): SxProps => ({
  color: theme.palette.blue.main,
  textTransform: "capitalize",
  border: `1px solid ${theme.palette.blue.superLightBlue}`,
  backgroundColor: theme.palette.blue.superLightBlue,

  "&:hover": {
    color: theme.palette.blackAndWhite.white,
    backgroundColor: theme.palette.blue.main,
  },
});


export const StyledMenuItem = styled(MenuItem)(({
  theme,
}) => ({
  [`& .${svgIconClasses.root}`]: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.blackAndWhite.gray
  },
  fontSize: 14,
  color: theme.palette.blackAndWhite.black,
}));

export const StyledDeleteMenuItem = styled(MenuItem)(({theme}) => ({
  [`& .${svgIconClasses.root}`]: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.red.main
  },
  fontSize: 14,
  color: theme.palette.red.main,
}))

export const StyledTypography = styled(Typography)(() => ({
  display: "-webkit-box",
  "WebkitLineClamp": "1",
  "WebkitBoxOrient": "vertical",
  overflow: "hidden",
}))
