import React from "react";
import { Field, Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME } from "../const";
import {
  VarietyAutocomplete
} from "@/components/Autocompletes";

interface IProps {
  handleClose: () => void;
  cultivarId: number;
}

const SelectCultivarFieldVarietyForm: React.FC<IProps> = ({
  handleClose,
  cultivarId
}) => {
  const formik = useFormikContext();

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <VarietyAutocomplete name={FIELDS_NAME.VARIETY}
                             q={{ cultivar: cultivarId }}
                             required/>
        <Field
          type="checkbox"
          name={FIELDS_NAME.STANDARD}
        >
          {({ field }) => (
            <FormControlLabel
              {...field}
              control={<Checkbox/>}
              label="Стандарт"
            />
          )}
        </Field>
      </Stack>
      <Divider/>
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon/>}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default SelectCultivarFieldVarietyForm;
