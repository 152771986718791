import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {
  IOffBudget,
  IOffBudgetFile
} from "@/api/interfaces/requests";
import {
  IEffectivityList
} from "@/api/interfaces/responses";
import {
  IEfficiencyItem,
  IPlanForm,
  PLAN_FIELDS_NAME
} from "@/pages/AddPlan/const";
import {
  EFFECTIVITY_FIELD_NAMES
} from "@/apps/RightSidebar/templates/OffBudget/firstLevel/components/Effectivity/const";
import { IFile } from "@/components/FileDropzone/const";

const filesSerializer = (files?: IOffBudgetFile[]) =>
  files?.map((file) =>
    Object.assign(file, {
      uuid: uuidv4(),
      name: file.file_name,
      status: "success",
      formatSize: file.file_size,
    })
  );

export const mapValueToForm = (value?: IOffBudget | null): any => ({
  [PLAN_FIELDS_NAME.YEAR]: value?.year ? dayjs().year(value.year) : undefined,
  [PLAN_FIELDS_NAME.REGION]: value?.branch?.region?.id,
  [PLAN_FIELDS_NAME.BRANCH]: value?.branch?.id,
  [PLAN_FIELDS_NAME.NAME]: value?.name ?? "",
  [PLAN_FIELDS_NAME.FILE_LIST]: filesSerializer(value?.files),
});

const fileToFormData = (files: IFile[]): FormData[] =>
  files?.map((file) => {
    const formData = new FormData();

    formData.append("type", file.name);
    formData.append("file", file as Blob);

    return formData;
  });

export const formToValueSerializer = (values: IPlanForm): any => ({
  [PLAN_FIELDS_NAME.YEAR]: new Date(
    dayjs(values.year).toString()
  ).getFullYear(),
  [PLAN_FIELDS_NAME.BRANCH]: Number(values?.branch),
  [PLAN_FIELDS_NAME.NAME]: values?.name,
  [PLAN_FIELDS_NAME.FILE_LIST]: values?.file_list?.length
    ? fileToFormData(values?.file_list)
    : undefined
});

export const effectivityListToItemsSerializer = (
  data: IEffectivityList[]
): IEfficiencyItem[] => {
  const items: IEfficiencyItem[] = [];

  data.forEach((element) => {
    const effectivityArray = Object.entries(element);

    effectivityArray.forEach((effectivity, index) => {
      const item = {
        id: `${element.year}${index}`,
        year: element.year,
        label: EFFECTIVITY_FIELD_NAMES[effectivity[0]],
        value: effectivity[1]
      };

      if (item.label) {
        items.push(item);
      }
    });
  });

  return items;
};
