import {
    FiltersType
  } from "@/components/Filters/interfaces";
  import { IFilter, IValue } from "@/interfaces";
import { Dispatch, SetStateAction } from "react";
  
  interface IFilterWithParams extends IFilter {
    handleScroll?: (event: React.UIEvent<HTMLElement, UIEvent>) => void;
  }
  
 
  export const getFilters = (
    cultivarTypeValue?: IValue[],
    selectedCultivarTypeValue?: IValue,
    handleScroll?: (
      event: React.UIEvent<HTMLElement, UIEvent>,
    ) => void,
    setValue?: Dispatch<SetStateAction<string>>,
  ): IFilterWithParams[] => {
    return [
      {
        name: "culture",
        label: "Культура",
        type: FiltersType.autoComplete,
        selectedValue: selectedCultivarTypeValue,
        handleScroll: handleScroll,
        options: cultivarTypeValue,
        value: "",
        style: { minWidth: "230px", flexGrow: 1 },
        setValue: setValue
      }
    ];
  };