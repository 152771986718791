import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IFilter } from "@/interfaces";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro";

interface DateFilterProps {
  filter: IFilter;
  onChange: (date: Date | null) => void;
}

/**
 * Датапикер определяющий конкретную дату
 * @param filter - информация о фильтре (имя, тип и тд.)
 * @param onChange - коллбек работающий в момент изменения данных
 * @returns
 */
const DateFilter: React.FC<DateFilterProps> = ({ filter, onChange }) => {
  const handleDateChange = (date: Date | null) => {
    onChange(date);
  };

  return (
    <DatePicker
      label={filter.label}
      value={filter.selectedValue || null}
      onChange={handleDateChange}
      slotProps={
        {
          field: { clearable: true, size: "small" }
        } as any // свойство size отсуствует в интерфейсе, но оно позволяет уменьшить размер инпута до необходимого
      }
      slots={{
        field: SingleInputDateRangeField,
      }}
    />
  );
};

export default DateFilter;
