import React from "react"
import { IIcon } from "./interfaces"

const RentIcon = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg
      className={className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6622_2803"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="17"
      >
        <rect y="0.333374" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6622_2803)">
        <path
          d="M10.6667 13.6667V9H13.3334V13.6667H10.6667ZM6.66675 13.6667V3H9.33341V13.6667H6.66675ZM2.66675 13.6667V6.33333H5.33341V13.6667H2.66675Z"
          fill="#1872CF"
        />
      </g>
    </svg>
  )
}

export default RentIcon
