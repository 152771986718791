import { Box, Typography } from "@mui/material"
import { Formik, Form, FormikHelpers } from "formik"
import React from "react"
import { IFormik, IFormikLAB, formToValue, formToValueLAB, validationSchemaAddPlan, validationSchemaAddPlanWithoutBranch } from "../../const"
import FormAddPlan from "../FormAddPlan"
import { useStores } from "@/hooks"
import { useNavigate, useSearchParams } from "react-router-dom"
import { TPlanningTrialType } from "@/api/types"
import useToastContent, { TOAST_CONTENT_TYPE } from "@/hooks/useToastContent";
import { ROUTES_PATHS } from "@/apps/AppRouter/const"
import { VARIANT_MODAL } from "@/apps/Modals/const"
import { TRIAL_TYPE } from "@/const"
import FormAddPlanLAB from "../FormAddPlanLAB"

const FormAddPlanWrap = () => {
  const { api, toastStore, modalStore } = useStores();
  const [searchParams] = useSearchParams();
  const toastContent = useToastContent(TOAST_CONTENT_TYPE.SERVER_ERROR);
  const navigate = useNavigate()

  const trialTypeString = searchParams.get("trial_type");
  const trialTypeMapping: { [key: string]: TPlanningTrialType } = {
    hp: "hp",
    oos: "oos",
    phyto: "phyto",
    lab: "lab",
  };
  const trialType = trialTypeMapping[trialTypeString as keyof typeof trialTypeMapping] || undefined;

  const serialaizerFormData = (value: IFormik) => {
    const formValue = formToValue(value)
    return {
      ...formValue,
      trial_type: trialType
    }
  }

  const serialaizerFormDataLAB = (value: IFormikLAB) => {
    const formValue = formToValueLAB(value)
    return {
      ...formValue,
      trial_type: trialType
    }
  }

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<IFormik>
  ) => {
    api.planning
      .postGeneratePlanningPlan(serialaizerFormData(values))
      .then((res) => {
        navigate({  
          pathname: ROUTES_PATHS.planning
        });
        modalStore.open(VARIANT_MODAL.SUCCESS_ADD_PLAN, {
          title: 'Результаты создания планов',
          succesDescription: `Создан(ы) план(ы): `,
          failedDescription: `Не удалось создать план(ы): `,
          succesMesage: res.success,
          failedMesage: res.failed,
          success_count: res.success_count,
          failed_count: res.failed_count,
        });
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const handleSubmitLAB = (
    values: IFormik,
    helpers: FormikHelpers<IFormik>
  ) => {
    api.planning
      .postPlanning(serialaizerFormDataLAB(values as IFormikLAB))
      .then(() => {
        navigate({
          pathname: ROUTES_PATHS.planning
        });
        // modalStore.open(VARIANT_MODAL.SUCCESS_ADD_PLAN, {
        //   title: 'Результаты создания планов',
        //   succesDescription: `Создан(ы) план(ы): `,
        //   failedDescription: `Не удалось создать план(ы): `,
        //   succesMesage: res.success,
        //   failedMesage: res.failed,
        // });
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };
  const showFieldBranhes = trialTypeString === TRIAL_TYPE.LAB ? true : false
  const trialTypeLAB = trialTypeString === TRIAL_TYPE.LAB ? true : false

  return (
    <Box p={3}>
      <Typography mb={3} variant="h3">Данные плана</Typography>
      <Formik
        onSubmit={showFieldBranhes
          ? handleSubmitLAB
          : handleSubmit
        }
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          cultivars: null,
          region: null,
          branches: null,
          plan_date: null,
          year: null,
        }}
        enableReinitialize={true}
        validationSchema={showFieldBranhes
          ? validationSchemaAddPlan
          : validationSchemaAddPlanWithoutBranch
        }
      >
        {(formikTools) => {
          return (
            <Form
              noValidate
            >
              { trialTypeLAB ?
                <FormAddPlanLAB
                  formikProps={formikTools}
                  showFieldBranhes={showFieldBranhes}
                /> :
                <FormAddPlan
                  formikProps={formikTools}
                  showFieldBranhes={showFieldBranhes}
                />
              }
            </Form>
          )
        }}
      </Formik >
    </Box>
  )
}

export default FormAddPlanWrap
