import React from "react";
import AddPlan from "@/pages/AddPlan";

/** Страница редактирования плана
 *  */

const EditPlanPage = () => {
  return (
    <AddPlan
      headerTitle="Редактирование плана"
    />
  );
};

export default EditPlanPage;