import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { observer } from "mobx-react";

import { MediaQueries, QueryResults } from "react-media";
import { NoSSR } from "@/components/NoSSR";
import Index from "@/apps/Navbar";
import MobileNavbar from "@/apps/MobileNavbar";
import RightSidebar from "@/apps/RightSidebar";
import BaseModal from "@/apps/Modals";
import Header from "./components/Header";

import Toast from "@/apps/Toast";

import { useStores } from "@/hooks";

import { SIDEBAR_STORE_KEY } from "./const";

import { isUndefined } from "@/utils/helpers";
import { KEYS_BREAK_POINTS, useMatches } from "../Respons";
import { Content, Flex } from "./style";
import Seo from "@/components/Seo";

interface IProps {
  title?: string
}

/**
 * @component
 * Компонет основного лейаута для авторизованного пользователя с боковой закрывающейся панелью и шапкой
 */
const MainLayout:React.FC<IProps> = ({title}) => {
  const { sidebarStore } = useStores();
  const { matches } = useMatches() as { matches: QueryResults<MediaQueries> };
  // от 1024 до 1280 по дефолту закрыт
  const defaultSidebarState = !!matches?.large
  const storageValue = sidebarStore.parsedStorage?.[SIDEBAR_STORE_KEY];
  const [isOpen, setOpen] = useState(
    storageValue ? Boolean(storageValue) : defaultSidebarState
  );

  // состояние для отслеживания открытия или закрытия сайдбара при новедении
  const [tmpIsOpen, setTmpIsOpen] = useState(false);
  /**
   * @function
   * Метод открытия/закрытия сайдбара, с перезаписью в localstorage
   */
  const handleToggle = (): void => {
    setOpen(() => {
      sidebarStore.changeStorageValue(SIDEBAR_STORE_KEY, !isOpen);
      return !isOpen;
    });
  };

  /**
   * @function
   * Метод открытия сайдбара, с перезаписью в localstorage
   */
  const handleSetOpenStore = (): void => {
    setOpen(() => {
      sidebarStore.changeStorageValue(SIDEBAR_STORE_KEY, true);
      return true;
    });
  };

  /**
   * @function
   * Метод открытия сайдбара, без перезаписи в localstorage
   */
  const handleOpen = (): void => {
    setTmpIsOpen(true);
  };

  /**
   * @function
   * Метод закрытия сайдбара, без перезаписи в localstorage
   */
  const handleClose = (): void => {
    setTmpIsOpen(false);
  };

  // запись начального значения положения сайдбара в стор
  useEffect(() => {
    if (isUndefined(storageValue)) {
      sidebarStore.changeStorageValue(SIDEBAR_STORE_KEY, defaultSidebarState);
    }
  }, []);

  const VARIANTS_NAV_MENU = {
    [KEYS_BREAK_POINTS.large]: (
      <Index
        isOpen={isOpen || tmpIsOpen}
        toggleSidebar={handleToggle}
        openStore={handleSetOpenStore}
        open={handleOpen}
        close={handleClose}
      />
    ),
    [KEYS_BREAK_POINTS.medium]: (
      <Index
        isOpen={isOpen || tmpIsOpen}
        toggleSidebar={handleToggle}
        openStore={handleSetOpenStore}
        open={handleOpen}
        close={handleClose}
      />
    ),
    [KEYS_BREAK_POINTS.regular]: <MobileNavbar />,
    [KEYS_BREAK_POINTS.small]: <MobileNavbar />
  };

  const CURRENT_KEY_BREAK_POINT =
    KEYS_BREAK_POINTS[
      Object.keys(matches ?? {}).find((key) => matches[key]) as string
    ];

  const heightSmallNavigation = "65px"; //todo: сделать более адаптивно

  return (
    <NoSSR>
      <Seo title={title}/>
      <Box
        sx={{
          display: "flex",
          height:
            matches?.small || matches?.regular
              ? `calc(100% - ${heightSmallNavigation})`
              : "100%"
        }}
      >
        <CssBaseline />

        <Header isOpen={isOpen || tmpIsOpen} toggleSidebar={handleToggle} />
        {VARIANTS_NAV_MENU[CURRENT_KEY_BREAK_POINT]}
        <Flex>
          <Content>
            <Outlet />
          </Content>
          <RightSidebar />
        </Flex>
      </Box>
      <BaseModal />
      <Toast />
    </NoSSR>
  );
};

export default observer(MainLayout);
