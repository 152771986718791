import React from "react";
import { Dayjs } from "dayjs";
import { useFormikContext, FastField } from "formik";
import {
  DateRangePicker,
  DateRangePickerSlotsComponentsProps,
  DateRangePickerProps
} from "@mui/x-date-pickers-pro/DateRangePicker";
import {
  SingleInputDateRangeField,
  ruRU,
} from "@mui/x-date-pickers-pro";
import CalendarTodayOutlinedIcon
  from "@mui/icons-material/CalendarTodayOutlined";
import theme from "@/theme";
import { SxProps } from "@mui/material";

interface IProps extends DateRangePickerProps<Dayjs> {
  name: string;
  required?: boolean;
  fullWidth?: boolean;
  handleChange?: (payload: [Date | null, Date | null]) => void;
}

const sxProps: SxProps = [
  {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      flexGrow: 1,
      width: "100%",
      minWidth: "280px",
    },
    "& .clearButton": {
      visibility: "visible",
      opacity: 1,
    },
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
    },
  },
];

const DateRangeField: React.FC<IProps> = ({
  name,
  required = false,
  fullWidth = false,
  handleChange,
  ...props
}) => {

  const formik = useFormikContext();

  const onChange = (payload: [Date | null, Date | null]) => {
    handleChange ? handleChange(payload) : formik.setFieldValue(name, payload);
  };

  if (!formik) {
    throw new Error(
      "DateRangeField has to be used within <Formik>"
    );
  }

  return (
    <FastField name={name}>
      {({ field, meta }) => (<DateRangePicker
        {...field}
        label="Дата (период от и до)"
        sx={sxProps}
        localeText={
          ruRU.components.MuiLocalizationProvider.defaultProps
            .localeText
        }
        format="DD.MM.YYYY"
        slotProps={
          {
            field: { clearable: true, size: "small" },
            textField: {
              InputProps: {
                endAdornment:
                  field.value[0] ||
                  field.value[1] ? null : (
                    <CalendarTodayOutlinedIcon
                      sx={{
                        color:
                        theme.palette.blackAndWhite.black60,
                      }}
                    />
                  ),
              },
              required: required,
              error: meta.touched && !!meta.error,
              helperText: meta.touched && meta.error,
              onBlur: field.onBlur,
              fullWidth: fullWidth
            },
          } as DateRangePickerSlotsComponentsProps<Date> // свойство size отсуствует в интерфейсе, но оно позволяет уменьшить размер инпута до необходимого
        }
        slots={{
          field: SingleInputDateRangeField,
        }}
        {...props}
        onChange={onChange}
      />)}
    </FastField>
  );
};

export default DateRangeField;
