import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import {
  IProps,
  METH_FIELD_NAME,
  TForm,
  validationSchema,
} from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import MethodologyForm from "./components/MethodologyForm";
import { mapFormToValuesSerializer } from "./serializers";

/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - callback после успешного запроса
 * */

const AddMethodology: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const baseInitialValues = {
    [METH_FIELD_NAME.NAME]: null,
    [METH_FIELD_NAME.NUMBER]: null,
    [METH_FIELD_NAME.CONFIRMATION_DATE]: null,
    [METH_FIELD_NAME.ADDITIONAL_INFO]: null,
    [METH_FIELD_NAME.FILES]: [],
    [METH_FIELD_NAME.TYPE_TRIAL]: "oos",
    [METH_FIELD_NAME.CULTIVAR]: null,
  };

  const { methodologyId, initialValues, isEdit } =
    modalProps;
  const { api, toastStore, swrStore } = useStores();

  const mutateDetail = methodologyId
    ? swrStore.mutators[
        SWR_KEYS.getMethodologyItem(methodologyId)
      ]
    : null;

  const mutateList =
    swrStore.mutators[SWR_KEYS.getMethodologyList()];

  const addFiles = (id, files) => {
    files.forEach((file) => {
      const formData = new FormData();
      formData.append("type", "not_choice");
      formData.append("file", file as Blob);
      if (!file.id) {
        api.regulatoryInfo
          .methodologyAddFile(id, formData)
          .then(() => {
            mutateDetail && mutateDetail();
          });
      }
    });
  };

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);
    const promise = isEdit
      ? api.regulatoryInfo.patchMethodologyItem(
          Number(methodologyId),
          mapFormToValuesSerializer(values),
        )
      : api.regulatoryInfo.postMethodologyItem(
          mapFormToValuesSerializer(values),
        );
    promise
      .then((res) => {
        if (values.files.length) {
          addFiles(res.id, values.files);
        }
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors.non_field_errors) {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorToString(error),
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail();
        mutateList && mutateList();
        handleClose();
      });
  };

  const values = initialValues
    ? {
        ...baseInitialValues,
        ...initialValues,
      }
    : baseInitialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <MethodologyForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      )}
    />
  );
};

export default AddMethodology;
