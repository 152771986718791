import {
  ITrial,
  TOosContextRes,
} from "@/api/interfaces/responses";
import { LAB_FIELD_NAME } from "./const";
import { ISurveyReq } from "@/api/interfaces/requests";
import dayjs from "dayjs";

/**
 * Метод для удобства работы с контекстом
 * @param context
 */
export const serializeContext = (context: TOosContextRes) => {
  return {
    [LAB_FIELD_NAME.REGIONS]: context.regions.sort((a, b) => a.number - b.number) || [],
  };
};

export type TContextType = ReturnType<typeof serializeContext>


/**
 * Методика из формы на бекенд
 * @param values
 */
export const surveySerializer = (values): ISurveyReq[] => {
  return values.map(el => ({
    survey_id: el.survey_id,
    indicator_methodology: el.indicator_methodology,
    index: el.variant.index,
    severity: el.variant.severity,
    varieties: el.variety.map(v => v.id)
  }));
};

/**
 * Из бекенда в форму
 * @param values
 */
export const valueToFormSerializer = (values?: ITrial | null) => {
  return {
    [LAB_FIELD_NAME.YEAR_OF_TESTING]: values?.year_of_testing ? dayjs().year(values.year_of_testing) : null,
    [LAB_FIELD_NAME.REGIONS]: values?.regions?.map(el => el.id) || [],
    [LAB_FIELD_NAME.PAYMENT_TYPE]: values?.payment_type || '[],'
  };
};

/**
 * Значения из формы на бекенд
 * @param values
 */
export const formToValueSerializer = (values) => {
  return {
    type_oos: values.type_oos,
    year_of_testing: new Date(values.year_of_testing).getFullYear(),
    regions: values.regions,
    payment_type: values.payment_type,
  };
};