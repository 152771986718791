
import { IFilter, IValue, TRangeDateFormatted } from "@/interfaces";

import { PLANNING_STATUS_INPUT, PLANNING_TRIAL_TYPE_INPUT } from "../const";
import { FiltersType } from "@/components/Filters/interfaces";

export const getFilters = (
  dateRange: TRangeDateFormatted,
  statementTypeValue?: IValue,
  executorValue?: IValue,
): IFilter[] => {
  return [
    {
      name: "trial_type",
      label: "Вид испытаний",
      type: FiltersType.autoComplete,
      selectedValue: executorValue,
      options: PLANNING_TRIAL_TYPE_INPUT,
      value: "",
      style: { minWidth: "260px",flexGrow: 1 },
    },
    {
      name: "status",
      label: "Статус",
      type: FiltersType.autoComplete,
      selectedValue: statementTypeValue,
      options: PLANNING_STATUS_INPUT,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
    {
      name: "date_range",
      label: "Дата регистрации",
      type: FiltersType.yearsRange,
      selectedValue: dateRange,
      value: "",
      style: { minWidth: "260px",flexGrow: 1 },
    },
  ];
};
