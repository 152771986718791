import React from "react";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  Stack,
  Typography,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import { useStores } from "@/hooks";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { ROUTES } from "@/apps/AppRouter/const";
import {
  DeleteIcon,
  EditIcon,
  RevisionIcon,
} from "@/components/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { EDITING_CELL } from "../models";
import { buttonDotsStyle } from "@/apps/Table/style";
import { IOffBudget } from "@/api/interfaces/requests";
import CloseIcon from "@/components/icons/CloseIcon";
import ProgressActivityIcon from "@/components/icons/ProgressActivityIcon";
import EditDocumentIcon from "@/components/icons/EditDocumentIcon";
import { OFFBUDGET_STATUS_ICON } from "@/pages/OffBudget/const";
import { OFFBUDGET_STATUS, OFFBUDGET_STATUS_COLOR } from "../const";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { SWR_KEYS } from "@/const";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * @component DateCells
 * * компонент ячейки таблицы дата создания заявки
 * @param
 */

export const DateCells = (_: string, storage: IOffBudget): JSX.Element => {
  return (
    <Box>
      <Typography color="GrayText" variant="body2">
        {storage?.year}
      </Typography>
    </Box>
  );
};

/**
 * @component NameCells
 * * компонент ячейки таблицы наименование культуры
 * @param
 */

export const NameCells = (_: string, { name }: IOffBudget): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">{name}</Typography>
    </Box>
  );
};

/**
 * @component BranchCells
 * * компонент ячейки таблицы филиал
 * @param
 */

export const BranchCells = (_: string, { branch }: IOffBudget): JSX.Element => {
  return (
    <Box>
      <Typography variant="body2">{branch.name}</Typography>
    </Box>
  );
};

/**
 * @component StatusCells
 * * компонент ячейки таблицы статуса плана
 * @param
 */

export const StatusCells = (_: string, storage: IOffBudget): JSX.Element => {
  const { status } = storage;
  const statusText = status ? OFFBUDGET_STATUS[status] : "";
  const statusColors = status ? OFFBUDGET_STATUS_COLOR[status] : "";

  const iconByStatus = (status: string) => {
    switch (status) {
      case OFFBUDGET_STATUS_ICON.draft:
        return <EditDocumentIcon />;
      case OFFBUDGET_STATUS_ICON.agreed:
        return <ProgressActivityIcon />;
      case OFFBUDGET_STATUS_ICON.approved:
        return <CheckIcon />;
      case OFFBUDGET_STATUS_ICON.rejected:
        return <CloseIcon />;
      case OFFBUDGET_STATUS_ICON.revision:
        return <RevisionIcon />;
      default:
        return undefined;
    }
  };

  return (
    <Stack
      color={statusColors}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        border: "1px solid",
        p: "2px",
        borderRadius: 20,
        width: "fit-content",
      }}
    >
      {iconByStatus(status)}
      <Typography
        color={"inherit"}
        sx={{
          fontSize: "12px",
          marginLeft: "3px",
          p: "3px",
        }}
      >
        {statusText}
      </Typography>
    </Stack>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCells = (_: string, cell: IOffBudget): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const mutateList = swrStore.mutators[SWR_KEYS.getOffBudget()];

  const handleDeletePlan = (resolve, reject) => {
    api.offBudget.deleteOffBudgetPlan(cell.id).then(
      () => {
        mutateList && mutateList();
        resolve();
      }
      
    ).catch(reject);
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.offbudget,
      id: cell.id,
      handleDelete: handleDeletePlan,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
  };


  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigate(`/${ROUTES.offBudget}/${cell.id}/${ROUTES.editFact}/`);
  };

  const canEditFact = userStore.isPermission(
    PERMISSIONS.extrabudgetaryComPlanFact,
    PERMISSION_CRUD.change
  )
  const canDeleteFact = userStore.isPermission(
    PERMISSIONS.extrabudgetaryComPlanFact,
    PERMISSION_CRUD.delete
  )

  if(!canEditFact && !canDeleteFact) {
    return <></>
  }

  return (
    <Box>
      <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={isOpen} open={open} onClose={handleClose}>
        {canEditFact && 
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon />

          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
        {canEditFact && canDeleteFact && 
        <Divider />
        }
        {canDeleteFact && 
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color="error"/>

          <Typography color="error"> {EDITING_CELL.delete}</Typography>
        </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};
