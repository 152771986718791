import React from "react";
import { Stack, Typography } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import {
  OFFBUDGET_STATUS,
  OFFBUDGET_STATUS_COLOR,
  OFFBUDGET_STATUS_ICON
} from "@/pages/OffBudget/const";
import {
  CloseIcon,
  EditDocumentIcon,
  ProgressActivityIcon,
  RevisionIcon
} from "@/components/icons";

interface IProps {
  status?: string;
}

const statusIcons = {
  [OFFBUDGET_STATUS_ICON.draft]: <EditDocumentIcon />,
  [OFFBUDGET_STATUS_ICON.agreed]: <ProgressActivityIcon />,
  [OFFBUDGET_STATUS_ICON.approved]: <CheckIcon />,
  [OFFBUDGET_STATUS_ICON.rejected]: <CloseIcon />,
  [OFFBUDGET_STATUS_ICON.revision]: <RevisionIcon />
};

export const OffbudgetStatusBadge = ({ status }: IProps) => {
  const statusText = status ? OFFBUDGET_STATUS[status] : "";
  const statusColors = status ? OFFBUDGET_STATUS_COLOR[status] : "";

  return (
    <Stack
      color={statusColors}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      mt={1}
      sx={{
        border: "1px solid",
        p: "4px",
        borderRadius: 20,
        width: "fit-content"
      }}
    >
      {status && statusIcons[status]}
      <Typography
        color={"inherit"}
        sx={{
          fontSize: "12px",
          marginLeft: "3px",
          p: "3px"
        }}
      >
        {statusText}
      </Typography>
    </Stack>
  );
};
