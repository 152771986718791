import React, { useState } from "react";
import { IProps, FIELD_NAME } from "../const";
import { Field, useFormikContext } from "formik";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";

import {
  Box,
  FormControl,
  Stack,
} from "@mui/material";

import FastRegexTextField from "@/components/FastRegexTextField";
import { DatePickerField } from "@/components/Fields";
import SubjectAutocomplete from "@/components/Autocompletes/SubjectAutocomplete";
import { CustomPaper } from "../../Employee/components/EmployeeFormSteps/first";
import Autocomplete from "@/components/Autocomplete";

/**
 * Форма создания/редактирования приказа
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const FirstStepForm: React.FC<IProps> = () => {
  const formik = useFormikContext<any>();
  const { api } = useStores();
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [agreementSearch, setAgreementSearch] = useState("");

  const getKeyEmployee = (index: number) => ({
    _key: SWR_KEYS.getEmployeeList(),
    page: index + 1,
    search: employeeSearch
  });

  const {
    data: dataEmployee,
    handleScroll: handleScrollEmployee
  } = useInfiniteScroll(
    getKeyEmployee,
    api.regulatoryInfo.getEmployeeList,
    {
      revalidateFirstPage: false,
    },
  )

  const handleChangeEmployee = (event, value) => {
    formik.setFieldValue(FIELD_NAME.EXECUTIVE, value);
  };

  const getKeyAgreement = (index: number) => ({
    _key: SWR_KEYS.getAgreementType(),
    page: index + 1,
    search: agreementSearch
  });

  const {
    data: dataAgreement,
    handleScroll: handleScrollAgreement
  } = useInfiniteScroll(
    getKeyAgreement,
    api.statement.getAgreementType,
    {
      revalidateFirstPage: false,
    },
  )

  const handleChangeAgreement = (event, value) => {
    formik.setFieldValue(FIELD_NAME.AGREEMENT_TYPE, value);
  };

  return (
    <>
      <Stack direction={"column"} gap={2} >
        <FastRegexTextField
          name={FIELD_NAME.AGREEMENT_NAME}
          label='Название'
          required
        />

        <Field name={FIELD_NAME.AGREEMENT_TYPE}>
          {({ field, meta }) => (
            <FormControl size="small" variant="outlined"
              fullWidth>
              <Autocomplete
                {...field}
                label="Тип"
                PaperComponent={CustomPaper}
                onChange={handleChangeAgreement}
                data={dataAgreement}
                getLabel={(item) => item.name}
                handleScroll={handleScrollAgreement}
                search={setAgreementSearch}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                required
              />
            </FormControl>
          )}
        </Field>

        <FastRegexTextField
          name={FIELD_NAME.AGREEMENT_NUMBER}
          label='Номер'
          required
        />

        <Stack direction={"row"} display={'flex'} justifyContent={'space-between'}>
          <Box sx={{ width: '250px' }}>
            <DatePickerField
              name={FIELD_NAME.AGREEMENT_DATE}
              label='Дата договора'
              fullWidth 
              required />
          </Box>
          <Box sx={{ width: '250px' }}>
            <DatePickerField
              name={FIELD_NAME.EXPIRY_DATE}
              label='Срок действия'
              fullWidth
            />
          </Box>
        </Stack>

        <SubjectAutocomplete
          name={FIELD_NAME.COUNTERPARTY}
          label="Контрагент"
          required
        />

        <Field name={FIELD_NAME.EXECUTIVE}>
          {({ field, meta }) => (
            <FormControl size="small" variant="outlined" required
              fullWidth>
              <Autocomplete
                {...field}
                label="Oтветственный"
                PaperComponent={CustomPaper}
                onChange={handleChangeEmployee}
                data={dataEmployee}
                getLabel={(item) => item.full_name}
                handleScroll={handleScrollEmployee}
                search={setEmployeeSearch}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                required
              />
            </FormControl>
          )}
        </Field>

        <FastRegexTextField
          name={FIELD_NAME.COMMENT}
          label='Дополнительная информация'
          multiline
          minRows={4}
          maxRows={10}
          inputProps={{
            maxLength: 100,
          }}
        />
      </Stack>
    </>
  );
};

export default FirstStepForm;
