import React, { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { Box, SxProps, Theme } from "@mui/material";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useStores } from "@/hooks";
import theme from "@/theme";
import LandAreasView from "./components/LandAreasView";
import { SWR_KEYS } from "@/const";
import Loader from "@/components/Loader";

export const wrapperStyle = (theme: Theme): SxProps => ({
  width: "100%",
  padding: theme.spacing(3),
});

const LandAreasPage: React.FC = () => {
  const { headerStore } = useStores();

  const { id } = useParams()
  const { api } = useStores();

  const { data: renderData, error, isLoading } = useSWR(
    id ? SWR_KEYS.getLocationMainById(id) : null,
    () => api.agriculturalLabour.getLocationMainById(id as string), {}
  );

  // установление хедера страницы
  useLayoutEffect(() => {
    headerStore.setEmptyProps();
    headerStore.setHeader(VARIANT_HEADER.LAND_AREAS);
  }, []);

  if (error) {
    return <div>Ошибка загрузки данных</div>;
  }

  if (isLoading || !renderData) {
    return <Loader/>;
  }

  return (
    <Box sx={wrapperStyle(theme)}>
      <LandAreasView renderData={renderData} />
    </Box>
  );
};

export default LandAreasPage;
