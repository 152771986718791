import React, { Fragment } from 'react'
import { useStores } from '@/hooks';
import { Field, Form, useFormikContext } from 'formik';
import { Box, Button, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack,Typography } from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import theme from '@/theme';
import FastRegexTextField from '@/components/FastRegexTextField';
import { CultivarAutocomplete } from '@/components/Autocompletes';
import { CULTIVAR_USAGE_TYPE_FIELD_NAME, IProps, PRODUCT_FORM_TYPES, TForm } from '../const';

/**
 * Форма создания/редактирования фазы вегетации
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * */

export const CultivarUsageTypeForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<TForm>();

  const renderItems = PRODUCT_FORM_TYPES?.map((el) => (
    <MenuItem key={el.value} value={el.value}>
      {el.label}
    </MenuItem>
  ));

  const title = modalProps.cultivarUsageTypeId
    ? "Редактировать запись"
    : "Добавить запись";

  return (
    <Fragment>
      <Box p={3}>
        <Typography fontSize={12} color={theme.palette.blackAndWhite.gray}>
            Направление использования
        </Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>

        <Stack direction={"column"} gap={2} p={2}>
          <CultivarAutocomplete
            name={CULTIVAR_USAGE_TYPE_FIELD_NAME.CULTIVAR}
            required
          />
           <Field name={CULTIVAR_USAGE_TYPE_FIELD_NAME.PRODUCT_FORM}>
            {({ field, meta }) => (
              <FormControl size='small' variant='outlined' fullWidth required>
                <InputLabel id='select-label'>Продукция</InputLabel>
                <Select
                  {...field}
                  label='Продукция'
                  error={meta.touched && !!meta.error}
                  required
                  fullWidth
                  disabled={meta.isSubmitting}
                >
                  {renderItems}
                </Select>
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <FastRegexTextField
            name={CULTIVAR_USAGE_TYPE_FIELD_NAME.NAME}
            label='Название'
            fullWidth
            size="small"
            required
          />
        </Stack>

        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red'
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type='submit'
            disabled={formik.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  )
}
