import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AddEntity } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { FIELDS_NAME } from "@/pages/AddStatement/const";
import { PAGE_SIZE } from "@/const";
import { resInterface } from "@/api/interfaces";
import useToastContent, {
  TOAST_CONTENT_TYPE
} from "@/hooks/useToastContent";
import {
  PERMISSION_CRUD,
  PERMISSIONS
} from "@/premissions";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    initialValue?: resInterface.ISubject;
  };
}

/**
 * Модалка добавления представителя для заявки
 * @param handleClose
 * @param modalProps
 * @component
 */
const AddRepresentatives: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const { id } = useParams();
  const { modalStore, api, toastStore, userStore } = useStores();
  const toastContent = useToastContent(TOAST_CONTENT_TYPE.SERVER_ERROR);
  const [isLoading, setIsLoading] = useState(false);
  const handleAccept = (value: resInterface.ISubject) => {
    setIsLoading(true);
    api.statement
      .patchStatementById(Number(id), {
        [FIELDS_NAME.REPRESENTATIVES]: value.id
      })
      .then(() => {
        handleClose();
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAcceptRepresentative = (payload) => {
    modalStore.setModalPropsByKey(modalProps.key, {...modalProps, initialValue: payload})
  }
  const handleAdd = () => {
    modalStore.open(VARIANT_MODAL.SUBJECT, { handleAccept: handleAcceptRepresentative })
  }

  const fetcher = (args) => {
    const { page, search } = args;
    return api.statement.getSubjects({
      page,
      page_size: PAGE_SIZE,
      search,
      first_ids: modalProps.initialValue?.id
    });
  };
  const getLabel = (subject: resInterface.ISubject) => subject.name;

  const isSubject = userStore.isPermission(
    PERMISSIONS.registrationSubject,
    PERMISSION_CRUD.add
  );

  const props = {
    ...modalProps,
    key: ["GET_REPRESENTATIVES", modalProps.initialValue?.id],
    handleClose,
    addButtonLabel: "Добавить представителя",
    isDisabledBtnAdd: isSubject,
    tooltipText: "У вас недостаточно прав для добавления представителя",
    title: "Представитель",
    handleAccept,
    handleAdd,
    disabled: isLoading,
    getLabel: getLabel,
    fetcher
  };
  return (
    <AddEntity<resInterface.ISubject>
      modalProps={props}
      handleClose={handleClose}
    />
  );
};

export default AddRepresentatives;
