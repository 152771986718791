import React, { useEffect } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Box, Divider, IconButton, Menu, Typography } from "@mui/material";
import { EditIcon, DeleteIcon } from "@/components/icons";
import { buttonDotsStyle } from "../../style";
import { StyledMenuItem } from "@/components/StyledMenuItem";
import { THEME_MODAL, VARIANT_MODAL } from "@/apps/Modals/const";
import { useQuery, useStores } from "@/hooks";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { resInterface } from "@/api/interfaces";
import { SWR_KEYS } from "@/const";
import qs from "qs";
import { useSearchParams } from "react-router-dom";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";
import { IRegionItem } from "@/api/interfaces/responses";
import { StyledTypography } from "@/pages/SeedAccounting/style";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * @component NameCells
 * * компонент ячейки таблицы Название учатска
 * @param
 */

export const NameCells = (
  _: string,
  { name, colour }: IRegionItem
): JSX.Element => {
  return (
    <Box display='flex' alignItems='center' flexWrap='nowrap'>
      <Box sx={{width: 18, height: 18, borderRadius:1, background: colour}} mr={1}/>
      <StyledTypography variant="body2" title={name}>
        {name}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCellsRegions = (
  _: string,
  cell: resInterface.IRegionItem,
  itemsInPage: any[],
): JSX.Element => {
  const { api, modalStore, swrStore, userStore } = useStores();
  const [anchorEl, setAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (itemsInPage.length === 1) {
      const params = qs.stringify(
        { ...query, last: 'true' },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      const params = qs.stringify(
        { ...query, last: undefined },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    }
  }, [itemsInPage.length]);

  const mutateRegionsList =
    swrStore.mutators[
    SWR_KEYS.getRegions()
    ];

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickEdit = (
    event: React.MouseEvent<HTMLElement>,
  ) => {

    event.stopPropagation();

    modalStore.open(VARIANT_MODAL.ADD_REGION, {
      theme: THEME_MODAL.W_555,
      initialValues: { name: cell?.name, number: cell?.number, colour: cell?.colour },
      regionId: cell.id,
    });
    setAnchorEl(null);
  };

  const deleteWithCheckingLast = () => {
    // если один эл-т на странице перенаправляем на предыдущую страницу
    if (itemsInPage.length === 1 && query.page > 1) {
      const params = qs.stringify(
        { ...query, page: query.page - 1 },
        { arrayFormat: "comma" },
      );
      setSearchParams(params);
    } else {
      mutateRegionsList && mutateRegionsList();
    }
  };

  const handleDelete = (resolveFn, rejectFn) => {
    api.regulatoryInfo
      .deleteRegionByID(cell.id)
      .then(() => {
        deleteWithCheckingLast();
        resolveFn();
      })
      .catch(rejectFn);
    setAnchorEl(null);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.handbook,
      id: cell.id,
      handleDelete: handleDelete,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(
      VARIANT_MODAL.OK_OR_NOT_DELETE,
      modalProp,
    );
  };

  const isEditRegion = userStore.isPermission(
    PERMISSIONS.regulatory_infoRegion,
    PERMISSION_CRUD.change
  )
  const isDeleteRegion = userStore.isPermission(
    PERMISSIONS.regulatory_infoRegion,
    PERMISSION_CRUD.delete
  )

  if (!isEditRegion && !isDeleteRegion) {
    return <></>
  }

  return (
    <Box>
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {isEditRegion&& (
          <>
            <StyledMenuItem onClick={handleClickEdit}>
              <EditIcon />
              {EDITING_CELL.editing}
            </StyledMenuItem>
          </>
        )}
        {isEditRegion && isDeleteRegion&& (
          <Divider />
        )}
        {isDeleteRegion&& (
          <StyledMenuItem onClick={handleClickDelete}>
            <DeleteIcon color={"error"} />
            <Typography color="error">
              {EDITING_CELL.delete}
            </Typography>
          </StyledMenuItem>
        )}
      </Menu>
    </Box>
  );
};
