import React from "react";
import { IIcon } from "./interfaces";

const BookIcon = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="currentColor" />
      </mask>
      <g>
        <path
          d="M7.5 22C6.53333 22 5.70833 21.6583 5.025 20.975C4.34167 20.2917 4 19.4667 4 18.5V5.5C4 4.53333 4.34167 3.70833 5.025 3.025C5.70833 2.34167 6.53333 2 7.5 2H20V17C19.5833 17 19.2292 17.1458 18.9375 17.4375C18.6458 17.7292 18.5 18.0833 18.5 18.5C18.5 18.9167 18.6458 19.2708 18.9375 19.5625C19.2292 19.8542 19.5833 20 20 20V22H7.5ZM6 15.325C6.23333 15.2083 6.475 15.125 6.725 15.075C6.975 15.025 7.23333 15 7.5 15H8V4H7.5C7.08333 4 6.72917 4.14583 6.4375 4.4375C6.14583 4.72917 6 5.08333 6 5.5V15.325ZM10 15H18V4H10V15ZM7.5 20H16.825C16.725 19.7667 16.6458 19.5292 16.5875 19.2875C16.5292 19.0458 16.5 18.7833 16.5 18.5C16.5 18.2333 16.525 17.975 16.575 17.725C16.625 17.475 16.7083 17.2333 16.825 17H7.5C7.06667 17 6.70833 17.1458 6.425 17.4375C6.14167 17.7292 6 18.0833 6 18.5C6 18.9333 6.14167 19.2917 6.425 19.575C6.70833 19.8583 7.06667 20 7.5 20Z"
          fill="currentColor"
        />
        <path
          d="M7.5 22C6.53333 22 5.70833 21.6583 5.025 20.975C4.34167 20.2917 4 19.4667 4 18.5V5.5C4 4.53333 4.34167 3.70833 5.025 3.025C5.70833 2.34167 6.53333 2 7.5 2H20V17C19.5833 17 19.2292 17.1458 18.9375 17.4375C18.6458 17.7292 18.5 18.0833 18.5 18.5C18.5 18.9167 18.6458 19.2708 18.9375 19.5625C19.2292 19.8542 19.5833 20 20 20V22H7.5ZM6 15.325C6.23333 15.2083 6.475 15.125 6.725 15.075C6.975 15.025 7.23333 15 7.5 15H8V4H7.5C7.08333 4 6.72917 4.14583 6.4375 4.4375C6.14583 4.72917 6 5.08333 6 5.5V15.325ZM10 15H18V4H10V15ZM7.5 20H16.825C16.725 19.7667 16.6458 19.5292 16.5875 19.2875C16.5292 19.0458 16.5 18.7833 16.5 18.5C16.5 18.2333 16.525 17.975 16.575 17.725C16.625 17.475 16.7083 17.2333 16.825 17H7.5C7.06667 17 6.70833 17.1458 6.425 17.4375C6.14167 17.7292 6 18.0833 6 18.5C6 18.9333 6.14167 19.2917 6.425 19.575C6.70833 19.8583 7.06667 20 7.5 20Z"
          fill="currentColor"
          fillOpacity="0.2"
        />
        <path
          d="M7.5 22C6.53333 22 5.70833 21.6583 5.025 20.975C4.34167 20.2917 4 19.4667 4 18.5V5.5C4 4.53333 4.34167 3.70833 5.025 3.025C5.70833 2.34167 6.53333 2 7.5 2H20V17C19.5833 17 19.2292 17.1458 18.9375 17.4375C18.6458 17.7292 18.5 18.0833 18.5 18.5C18.5 18.9167 18.6458 19.2708 18.9375 19.5625C19.2292 19.8542 19.5833 20 20 20V22H7.5ZM6 15.325C6.23333 15.2083 6.475 15.125 6.725 15.075C6.975 15.025 7.23333 15 7.5 15H8V4H7.5C7.08333 4 6.72917 4.14583 6.4375 4.4375C6.14583 4.72917 6 5.08333 6 5.5V15.325ZM10 15H18V4H10V15ZM7.5 20H16.825C16.725 19.7667 16.6458 19.5292 16.5875 19.2875C16.5292 19.0458 16.5 18.7833 16.5 18.5C16.5 18.2333 16.525 17.975 16.575 17.725C16.625 17.475 16.7083 17.2333 16.825 17H7.5C7.06667 17 6.70833 17.1458 6.425 17.4375C6.14167 17.7292 6 18.0833 6 18.5C6 18.9333 6.14167 19.2917 6.425 19.575C6.70833 19.8583 7.06667 20 7.5 20Z"
          fill="currentColor"
          fillOpacity="0.2"
        />
        <path
          d="M7.5 22C6.53333 22 5.70833 21.6583 5.025 20.975C4.34167 20.2917 4 19.4667 4 18.5V5.5C4 4.53333 4.34167 3.70833 5.025 3.025C5.70833 2.34167 6.53333 2 7.5 2H20V17C19.5833 17 19.2292 17.1458 18.9375 17.4375C18.6458 17.7292 18.5 18.0833 18.5 18.5C18.5 18.9167 18.6458 19.2708 18.9375 19.5625C19.2292 19.8542 19.5833 20 20 20V22H7.5ZM6 15.325C6.23333 15.2083 6.475 15.125 6.725 15.075C6.975 15.025 7.23333 15 7.5 15H8V4H7.5C7.08333 4 6.72917 4.14583 6.4375 4.4375C6.14583 4.72917 6 5.08333 6 5.5V15.325ZM10 15H18V4H10V15ZM7.5 20H16.825C16.725 19.7667 16.6458 19.5292 16.5875 19.2875C16.5292 19.0458 16.5 18.7833 16.5 18.5C16.5 18.2333 16.525 17.975 16.575 17.725C16.625 17.475 16.7083 17.2333 16.825 17H7.5C7.06667 17 6.70833 17.1458 6.425 17.4375C6.14167 17.7292 6 18.0833 6 18.5C6 18.9333 6.14167 19.2917 6.425 19.575C6.70833 19.8583 7.06667 20 7.5 20Z"
          fill="currentColor"
          fillOpacity="0.2"
        />
      </g>
    </svg>
  );
};

export default BookIcon;
