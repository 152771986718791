import React from "react"
import { IIcon } from "./interfaces"

const CallMadeIcon = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg
      className={className}
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.60008 10.3334L0.666748 9.40004L8.40008 1.66671H4.00008V0.333374H10.6667V7.00004H9.33342V2.60004L1.60008 10.3334Z"
        fill="#4BC281"
      />
    </svg>
  )
}

export default CallMadeIcon
