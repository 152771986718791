import React, { ReactElement } from "react";
import { TableBody } from "@mui/material";

import TableRow from "./TableRow";

import { IColumn, TEntityTable } from "../interfaces";

/**
 * Свойства для компонента ProposalContent.
 */
interface IProps<T, TColums> {
  /** Видимые строки для отображения в таблице. */
  visibleRows: T[];
  /** Массив выбранных индексов строк. */
  selected?: number;
  /** Функция обратного вызова для обновления выбранных строк. */
  setSelected: (value: number) => void;
  // Данные сущности для таблицы
  columns: IColumn<TColums>[];
  /**
   * обработчик клика на строку
   */
  onRowClick?: (
    event: React.MouseEvent<unknown>,
    rowId: number,
  ) => void;
}

/**
 * Представляет содержимое таблицы предложений.
 *
 * @component
 * @param {IProps} props - Свойства компонента.
 * @returns {ReactElement} Рендеринг компонента ProposalContent.
 */
function TableContent<T extends TEntityTable, TColums>({
  visibleRows,
  selected,
  setSelected,
  onRowClick,
  columns,
}: IProps<T, TColums>): ReactElement {
  /**
   * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
   *
   * @param {React.MouseEvent<unknown>} event - Событие клика.
   * @param {number} id - ID кликнутой строки.
   */
  const handleClick = (
    event: React.MouseEvent<unknown>,
    id: number,
  ) => {
    onRowClick?.(event, id);
    setSelected(id);
  };

  return (
    <TableBody>
      {visibleRows.map((row, idx) => {
        const isItemSelected = row.id === selected;
        return (
          <TableRow<T, TColums>
            key={idx}
            row={row}
            isItemSelected={isItemSelected}
            handleClick={handleClick}
            columns={columns}
            visibleRows={visibleRows}
          />
        );
      })}
    </TableBody>
  );
}

export default TableContent;
