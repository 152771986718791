import React from "react";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useStores } from "@/hooks";
import {
  THEME_MODAL,
  VARIANT_MODAL
} from "@/apps/Modals/const";
import {
  CELL_TYPES
} from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { DeleteIcon, EditIcon } from "@/components/icons";
import {
  EDITING_CELL,
  SEED_ARRIVAL_STATUS
} from "../../const";
import {
  StyledDeleteMenuItem,
  StyledMenuItem,
  StyledTypography,
} from "../../style";
import { buttonDotsStyle } from "@/apps/Table/style";
import {
  ISeedArrivalListItem
} from "@/api/interfaces/responses";
import { SWR_KEYS } from "@/const";
import { ROUTES } from "@/apps/AppRouter/const";
import theme from "@/theme";
import {
  SeedArrivalStatusBadge
} from "@/components/SeedArrivalStatusBadge";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

/**
 * @component PlotCells
 * * компонент ячейки таблицы ГСУ
 * @param
 */

export const PlotCells = (
  _: string,
  { plot }: ISeedArrivalListItem
): JSX.Element => {
  return (
    <Box>
      <StyledTypography variant="body2" title={plot?.name}>
        {plot?.name}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component CultivarCells
 * * компонент ячейки таблицы Культура
 * @param
 */

export const CultivarCells = (
  _: string,
  { cultivar }: ISeedArrivalListItem
): JSX.Element => {
  return (
    <Box>
      <StyledTypography variant="body2" title={cultivar?.name}>
        {cultivar?.name}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component QuantityCells
 * * компонент ячейки таблицы Плановое количество
 * @param
 */

export const QuantityCells = (
  _: string,
  { planned_quantity_of_seeds }: ISeedArrivalListItem
): JSX.Element => {
  return (
    <Box>
      <StyledTypography color={theme.palette.blackAndWhite.gray} variant="body2" title={String(planned_quantity_of_seeds)}>
        {planned_quantity_of_seeds}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component DateCells
 * * компонент ячейки таблицы Плановая дата
 * @param
 */

export const DateCells = (
  _: string,
  { planned_receipt_at }: ISeedArrivalListItem
): JSX.Element => {
  const date = dayjs(planned_receipt_at).format("DD.MM.YYYY");
  return (
    <Box>
      <StyledTypography color={theme.palette.blackAndWhite.gray} variant="body2" title={date}>
        {date}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component VarietyCells
 * * компонент ячейки таблицы Сорт
 * @param
 */

export const VarietyCells = (
  _: string,
  { variety }: ISeedArrivalListItem
): JSX.Element => {
  return (
    <Box>
      <StyledTypography variant="body2" title={variety?.name}>
        {variety?.name}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component VarietyCells
 * * компонент ячейки таблицы Тип материала
 * @param
 */

export const MaterialTypeCells = (
  _: string,
  { material_type }: ISeedArrivalListItem
): JSX.Element => {
  return (
    <Box>
      <StyledTypography variant="body2" title={material_type?.name}>
        {material_type?.name}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component StatusCells
 * * компонент ячейки таблицы Сорт
 * @param
 */
export const StatusCells = (
  _: string,
  { status }: ISeedArrivalListItem
) => <SeedArrivalStatusBadge status={status} />

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCells = (
  _: string,
  cell: ISeedArrivalListItem,
): JSX.Element => {
  const { modalStore, api, rightSidebarStore, swrStore, userStore } = useStores();
  const mutate =
    swrStore.mutators[SWR_KEYS.getSeedArrivalList()];
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const handleDeleteSeedArrival = (_, reject) => {
    api.agriculturalLabour
      .deleteSeedArrivalById(cell.id)
      .then(() => {
        rightSidebarStore.close();
        mutate();
      })
      .catch(reject)
      .finally(() => {
        modalStore.close();
      });
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const modalProp = {
      handleDelete: handleDeleteSeedArrival,
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.seedsArrival,
      seedsArrival: cell,
      id: cell.id,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigate(`${cell.id}/${ROUTES.addSeedArrival}`);
  };
  const showButton = cell.status === SEED_ARRIVAL_STATUS.PLANNED

  const isEdit = userStore.isPermission(
    PERMISSIONS.agricultural_labourSeedArrival,
    PERMISSION_CRUD.change
  )
  const isDelete = userStore.isPermission(
    PERMISSIONS.agricultural_labourSeedArrival,
    PERMISSION_CRUD.delete
  )

  if (!isEdit && !(showButton && isDelete)) {
    return <></>
  }

  return (
    <Box>
      <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={isOpen} open={open} onClose={handleClose}>
        {isEdit && (
          <StyledMenuItem onClick={handleClickEdit}>
            <EditIcon />
            {EDITING_CELL.editing}
          </StyledMenuItem>)
        }
      {showButton && isDelete && (
        <>
          {isEdit && <Divider />}
          <StyledDeleteMenuItem onClick={handleClickDelete}>
            <DeleteIcon />
            <Typography color="error">{EDITING_CELL.delete}</Typography>
          </StyledDeleteMenuItem>
        </>
      )}
      </Menu>
    </Box>
  );
};
