import React, {
  useCallback,
  useMemo,
  useState
} from "react";
import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikValues
} from "formik";
import { useLocation, useNavigate } from "react-router";
import {
  Box,
  Button,
  Divider,
  Step,
  StepLabel,
  useMediaQuery
} from "@mui/material";

import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { IStatement } from "@/api/interfaces/responses";
import { LOCATION_KEY } from "@/const";
import {
  IFormikStepProps
} from "@/pages/AddStatement/components/FormikStep";
import StepControls
  from "@/pages/AddStatement/components/StepControls/StepControls";
import MobileControls
  from "@/pages/AddStatement/components/MobileControls/MobileControls";

import {
  ContentWrap,
  Root,
  Stepper,
  StepperWrap
} from "./styles";
import styles from "./styles/index.module.scss";


export interface IFormikStepper extends FormikConfig<FormikValues> {
  children: React.ReactNode;
}

/** Компонет с формик контекстом для странцы добавления заявки.
 * Рендерит контент в зависимости от шага + валидирует в зависимости от шага */
export const FormikStepper: React.FC<IFormikStepper> = ({
  children,
  ...props
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [completed, setCompleted] = useState(false);

  /** Компоненты шагов*/
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<IFormikStepProps>[];
  /** Текущий компонент шага*/
  const currentChild = childrenArray[step];

  const maxSteps = useMemo(() => childrenArray.length, [childrenArray.length]);
  const matches = useMediaQuery("(min-width:1024px)");
  const isLastStep = useMemo(
    () => step === childrenArray.length - 1,
    [childrenArray.length, step]
  );

  const handleBack = useCallback((): void => {
    setStep((s) => s - 1);
  }, []);

  /** Метод по шагам - если шаг последний то сабмит формы если нет то дерагем формы для валидации шага
   *  */
  const handleSubmit = async (
    values: IStatement,
    helpers: FormikHelpers<FormikValues>
  ): Promise<void> => {
    if (isLastStep) {
      await props.onSubmit(values, helpers);
      setCompleted(true);
    } else {
      setStep((s) => s + 1);
      // helpers.setTouched({});
    }
  };

  const handleClose = () => {
    if (location.key !== LOCATION_KEY.DEFAULT) {
      navigate(-1);
    } else
      navigate({
        pathname: ROUTES_PATHS.statements
      });
  };

  const renderStepper = () => {
    return matches ? (
      <StepperWrap>
        <Box flexGrow={1} p={3}>
          <Stepper activeStep={step} orientation="vertical">
            {childrenArray.map((child, index) => (
              <Step
                key={child.props.label}
                completed={step > index || completed}
              >
                <StepLabel>{child.props.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Divider/>
        <Box p={3} pb={4} sx={{
          display: "flex",
          justifyContent: "center"
        }}>
          <Button
            variant="contained"
            onClick={handleClose}
          >
            Закрыть
          </Button>
        </Box>
      </StepperWrap>
    ) : (
      <MobileControls
        steps={maxSteps}
        activeStep={step}
        handleBack={handleBack}
        isLastStep={isLastStep}
      />
    );
  };

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className={styles.form} noValidate>
          <Root>
            {renderStepper()}
            <ContentWrap>
              <Box p={3}>
                {currentChild}
                {matches ? (
                  <StepControls
                    step={step}
                    handleBack={handleBack}
                    isLastStep={isLastStep}
                  />
                ) : null}
              </Box>
            </ContentWrap>
          </Root>
        </Form>
      )}
    </Formik>
  );
};
