import React from "react";
import { Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Box, Button, Divider, } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME, IFormik } from "../const";
import {
  WeatherConditionsAutocomplete
} from "@/components/Autocompletes";
import { FormikSelect } from "@/components/Selects";

interface IProps {
  handleClose: () => void;
}

const ratingOptions = [
  { key: "", label: "Не выбрано" },
  { key: 1, label: "1" },
  { key: 2, label: "2" },
  { key: 3, label: "3" },
  { key: 4, label: "4" },
  { key: 5, label: "5" },
];

const SelectWeatherResistanceForm: React.FC<IProps> = ({
  handleClose,
}) => {
  const formik = useFormikContext<IFormik>();

  return (
    <Form noValidate>
      <Stack p={3} gap="20px">
        <WeatherConditionsAutocomplete
          name={FIELDS_NAME.WEATHER_CONDITION} required/>
        <FormikSelect
          options={ratingOptions}
          name={FIELDS_NAME.RATING}
          label="Оценка (балл)"
          fullWidth
          required
        />
      </Stack>
      <Divider/>
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red" onClick={handleClose}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon/>}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default SelectWeatherResistanceForm;
