import React from "react";
import { Typography } from "@mui/material";

import { TITLES_PAGES } from "@/pages/const";

/**
 * хедер для страницы справочника
 * @returns React.FC
 */
const HeaderHandbook: React.FC = () => {
  return (
    <Typography variant="h3" noWrap>
      {TITLES_PAGES.handbook}
    </Typography>
  );
};

export default HeaderHandbook;
