import React, { useState } from 'react'
import { DEPARTMENT_FIELD_NAME, IProps, TForm } from '../const'
import { Field, Form, useFormikContext } from 'formik';
import { Box, Button, Divider, FormControl, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import Autocomplete from '@/components/Autocomplete';
import CheckIcon from "@mui/icons-material/Check";
import { useInfiniteScroll, useStores } from '@/hooks';
import { EmailField } from './email';
import { PhoneField } from './phone';
import { SWR_KEYS } from '@/const';
import { CustomPaper } from '../../Employee/components/EmployeeFormSteps/first';

const DepartmentsForm: React.FC<IProps> = ({ modalProps }) => {
  const formik = useFormikContext<TForm>();
  const { modalStore } = useStores();
  const { api } = useStores();
  const [deptSearch, setDeptSearch] = useState("");
  const swrKeyDept = SWR_KEYS.getDepartmentList();
  const [employeeSearch, setEmployeeSearch] = useState("");
  const swrKeyEmployee = SWR_KEYS.getEmployeeList();

  const title = modalProps.departmentId
    ? "Редактировать запись"
    : "Добавить запись";

  const getKeyDept = (index: number) => ({
    _key: swrKeyDept,
    page: index + 1,
    search: deptSearch
  });

  const fetcherDept = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getDepartmentList({
      page,
      search,
    });
  };

  const { data: dataDept, handleScroll: handleScrollDept } = useInfiniteScroll(
    getKeyDept,
    fetcherDept,
    {
      revalidateFirstPage: false,
    },
  );
  const handleChangeDept = (event, value) => {
    formik.setFieldValue(DEPARTMENT_FIELD_NAME.DEPARTMENT_PARENT, value);
  };


  const getKeyEmployee = (index: number) => ({
    _key: swrKeyEmployee,
    page: index + 1,
    search: employeeSearch
  });

  const fetcherEmployee = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getEmployeeList({
      page,
      search,
    });
  };

  const { data: dataEmployee , handleScroll:handleScrollEmployee } = useInfiniteScroll(
    getKeyEmployee,
    fetcherEmployee,
    {
      revalidateFirstPage: false,
    },
  );

  const seriaLizeEmployeeData = dataEmployee.map(
    item => ({ name: item.full_name, id: item.id })
  )

  const handleChangeEmployee = (event, value) => {
    formik.setFieldValue(DEPARTMENT_FIELD_NAME.STAFF_DIRECTOR, value);
  };

  return (
    <>
      <Box p={3}>
        <Typography>Отдел</Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>

        <Stack direction={"column"} gap={2} p={2}>
          <Field name={DEPARTMENT_FIELD_NAME.NAME}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Название'
                  fullWidth
                  size='small'
                  required
                  onChange={formik.handleChange}
                  onBlur={field.handleBlur}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={DEPARTMENT_FIELD_NAME.DEPARTMENT_PARENT}>
            {({ field, meta }) => (
              <FormControl size='small' variant='outlined' fullWidth>
                <Autocomplete
                  {...field}
                  label='Вышестоящий отдел'
                  PaperComponent={CustomPaper}
                  onChange={handleChangeDept}
                  data={dataDept}
                  handleScroll={handleScrollDept}
                  search={setDeptSearch}
                  error={meta.touched && !!meta.error}
                />
              </FormControl>
            )}
          </Field>
    
           <Field name={DEPARTMENT_FIELD_NAME.STAFF_DIRECTOR}>
            {({ field, meta }) => (
              <FormControl size='small' variant='outlined' fullWidth>
                <Autocomplete
                  {...field}
                  label='Начальник отдела'
                  PaperComponent={CustomPaper}
                  onChange={handleChangeEmployee}
                  data={seriaLizeEmployeeData}
                  handleScroll={handleScrollEmployee}
                  search={setEmployeeSearch}
                  error={meta.touched && !!meta.error}
                />
              </FormControl>
            )}
          </Field>
          <Box>
            <Typography>Телефоны</Typography>
            <PhoneField />
          </Box>
          <Box>
            <Typography>Электронные почты</Typography>
            <EmailField />
          </Box>
        </Stack>
        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red' onClick={() => modalStore.close()}>
            отмена
          </Button>
          <Button
            type='submit'
            disabled={formik.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </>
  )
}

export default DepartmentsForm