import React, { FocusEventHandler, useState } from "react";
import {
  Autocomplete as AutocompleteMui,
  ListItem,
  SxProps,
  TextField,
} from "@mui/material";
import { useDebounceCallback } from "@/hooks";

// тип для ввода значения, чтобы точно понять в какой момент срабатала функция onInputChange
type TReason = "input" | "reset" | "clear";

export interface SearchFilterProps<T> {
  label?: string;
  onChange: (event: any, child: any, reason: any) => void;
  onBlur?: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  search?: (value: string) => void;
  data: T[];
  handleScroll?: (
    event: React.UIEvent<HTMLElement, UIEvent>,
  ) => void;
  required?: boolean;
  value:
    | {
    label: string;
    id: number;
  }
    | string
    | undefined
    | null;
  sx?: SxProps;
  getLabel?: (el: any) => string;
  disabled?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  onClear?: () => void;
  textFieldSize?: "small" | "medium";
  filter?: boolean;
}

/**
 * Селект с возможностью отфильтровать варианты
 * @param label - подпись в инпута
 * @param onChange - коллбек выбора значения из автокоплита
 * @param onBlur - коллбек срабатываемый при смещения фокуса с компонента
 * @param search - функция для фильтрации значений
 * @param data - набор данных для автокомплита
 * @param handleScroll - функция срабатываемая во время скролла элементов (для запроса новых данных)
 * @param required - обязательное/необязательное поле
 * @returns
 */
const Autocomplete = <
  T extends {
    id: number | string;
    name?: string;
  },
> ({
  search,
  handleScroll,
  data,
  label,
  onBlur,
  sx,
  required = false,
  getLabel,
  disabled = false,
  fullWidth = false,
  error = false,
  helperText = "",
  value,
  onClear,
  textFieldSize = "small",
  filter = false,
  ...props
}: SearchFilterProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = useDebounceCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: string,
      reason: TReason,
    ) => {
      if (reason === "input") search?.(value);
      if (reason === "reset") search?.("");
      if (reason === "clear") {
        search?.("");
        onClear && onClear();
      }
      if (
        event &&
        event.type === "click" &&
        reason !== "input"
      ) {
        setIsOpen(false);
      }
    },
    300,
  );

  const newOptions = data.map((el) => {
    return {
      ...el,
      label: getLabel ? getLabel(el) : el.name || "-",
    };
  });

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <AutocompleteMui
      {...props}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      freeSolo // пишем текст, видим крестик
      forcePopupIcon // всегда видим кнопки свернуть/развернуть
      autoHighlight // подсвечиваем первый вариант при нажатии enter
      noOptionsText="Результаты отсутствуют"
      options={newOptions ?? []}
      onInputChange={handleInputChange}
      fullWidth={fullWidth}
      getOptionLabel={(option) => option.label || ""}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id
      }
      sx={sx}
      disabled={disabled}
      ListboxProps={{
        sx () {
          return {
            maxHeight: "200px",
          };
        },
        onScroll: handleScroll,
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            value={
              value
                ? typeof value === "string"
                  ? value.length
                    ? value
                    : undefined
                  : value.label
                : ""
            }
            onBlur={onBlur}
            size={textFieldSize}
            label={label}
            variant="outlined"
            required={required}
            error={error}
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              'data-qa': 'autocomplite',
            }}
          />
        );
      }}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.id} value={option}>
          {option.label}
        </ListItem>
      )}
      filterOptions={filter ? undefined : options => options} // Отключаем фильтрацию
      value={value}
    />
  );
};

export default Autocomplete;
