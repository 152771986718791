import { useMemo } from "react";
import {
  ROUTES_PATHS,
  ROUTES
} from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";

export const useAgriculturalLabourPermission = () => {
  const { userStore } = useStores()
  const canViewCultivarField = userStore.isPermission(PERMISSIONS.agriculturalLabourCultivarField, PERMISSION_CRUD.view);
  const canViewSeedArrival = userStore.isPermission(PERMISSIONS.agriculturalLabourSeedArrival, PERMISSION_CRUD.view);
  const canViewSeedCultivarFieldVariety = userStore.isPermission(PERMISSIONS.agriculturalLabourCultivarFieldVariety, PERMISSION_CRUD.view);

  const redirectPath = useMemo(() => {
    switch (true){
      case canViewCultivarField:
        return ROUTES_PATHS.agriculturalWorkDoc
      case canViewSeedArrival:
        return ROUTES.seedAccounting
      case canViewSeedCultivarFieldVariety:
        return ROUTES.cultivarFieldVariety
      default:
        return "/"
    }
  },[canViewCultivarField, canViewSeedArrival, canViewSeedCultivarFieldVariety])

  return {
    canViewCultivarField,
    redirectPath
  };
};