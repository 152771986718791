import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import Autocomplete, {
  SearchFilterProps
} from "@/components/Autocomplete";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import {
  IAgriculturalLabourField
} from "@/api/interfaces/responses";
import {
  IAgriculturalLabourFieldList
} from "@/api/interfaces/requests";

interface IProps
  extends Partial<SearchFilterProps<IAgriculturalLabourField>> {
  name: string;
  q?: IAgriculturalLabourFieldList;
  handleChange: (payload: IAgriculturalLabourField) => void;
  initialSearch?: string;
}

/**
 * Автокомплит со списком полей
 * @param name - имя
 * @param q - квери параметры для запроса
 * @param handleChange - калбек при выборе
 * @param initialSearch - начальное значение поиска
 */

const FieldAutocomplete: React.FC<IProps> = ({
  name,
  initialSearch = "",
  handleChange,
  q = {},
  ...props
}) => {
  const swrKey = SWR_KEYS.getAgriculturalLabourFieldList();
  const [search, setSearch] = useState(initialSearch);
  const formik = useFormikContext();
  const { api } = useStores();
  const getKey = (index: number) => {
    return {
      _key: swrKey,
      page: index + 1,
      search: search,
      ...q
    };
  };
  const { data, handleScroll } = useInfiniteScroll(
    getKey,
    api.agriculturalLabour.getFieldList,
    {
      revalidateFirstPage: false
    }
  );
  const onChange = (_, payload: IAgriculturalLabourField) => {
    handleChange ? handleChange(payload) : formik.setFieldValue(name, payload);
  };

  if (!formik) {
    throw new Error("FieldAutocomplete has to be used within <Formik>");
  }

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <Autocomplete<IAgriculturalLabourField>
          label="Поле"
          {...field}
          {...props}
          onChange={onChange}
          search={setSearch}
          data={data}
          handleScroll={handleScroll}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
    </Field>
  );
};

export default FieldAutocomplete;
