import React, { useCallback } from "react";
import TextField, {
  TextFieldProps
} from "@mui/material/TextField";

interface IProps {
  regex?: RegExp;
  regexType?: "decimal" | "number";
}

export type TRegexTextField =  IProps & TextFieldProps

export const matchNothingRegex = /(?!)/;
const RegexTextField: React.FC<TRegexTextField> = ({
  onChange,
  regexType,
  regex = matchNothingRegex,
  ...props
}) => {

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (regexType === "number") {
        e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
      } else if (regexType === "decimal") {
        e.currentTarget.value = e.currentTarget.value
          .replace(/[^0-9.]/g, "")
          .replace(/\.{2,}/g, ".")
          .replace(/^0*([^0]\d*\.\d{1,2}).*/g, "$1");
      } else {
        e.currentTarget.value = e.currentTarget.value.replace(regex, "");
      }
      onChange && onChange(e);
    },
    [onChange, regex, regexType]
  );

  return (
    <TextField
      onChange={handleChange}
      {...props}
    />
  );
};

export default RegexTextField;