import { HEADER_LABELS, SORTABLE_KEYS } from "../const";

import { TSeedArrivalSortableKeys } from "../interfaces";

import { cell, cellGSU, headerCellStyle, } from "../style";
import {
  CultivarCells,
  DateCells,
  EditingCells,
  MaterialTypeCells,
  PlotCells,
  QuantityCells,
  StatusCells,
  VarietyCells
} from "../components/AWCellComponents";
import { IColumn } from "@/apps/Table/interfaces";
import { cellButtons } from "@/apps/Table/style";

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const columns: IColumn<TSeedArrivalSortableKeys>[] = [
  {
    label: HEADER_LABELS.plot,
    key: SORTABLE_KEYS.plot,
    contentHeader: HEADER_LABELS.plot,
    bodyCellProps: {
      render: PlotCells,
      styles: cellGSU,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: HEADER_LABELS.cultivar,
    key: SORTABLE_KEYS.cultivar,
    contentHeader: HEADER_LABELS.cultivar,
    bodyCellProps: {
      render: CultivarCells,
      styles: cell,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: HEADER_LABELS.variety,
    key: SORTABLE_KEYS.variety,
    contentHeader: HEADER_LABELS.variety,
    bodyCellProps: {
      render: VarietyCells,
      styles: cell,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: HEADER_LABELS.material_type,
    key: SORTABLE_KEYS.material_type,
    contentHeader: HEADER_LABELS.material_type,
    bodyCellProps: {
      render: MaterialTypeCells,
      styles: cell,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: HEADER_LABELS.planned_quantity_of_seeds,
    key: SORTABLE_KEYS.planned_quantity_of_seeds,
    contentHeader: HEADER_LABELS.planned_quantity_of_seeds,
    bodyCellProps: {
      render: QuantityCells,
      styles: cell,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: HEADER_LABELS.planned_receipt_at,
    key: SORTABLE_KEYS.planned_receipt_at,
    contentHeader: HEADER_LABELS.planned_receipt_at,
    bodyCellProps: {
      render: DateCells,
      styles: cell,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: HEADER_LABELS.status,
    key: SORTABLE_KEYS.status,
    contentHeader: HEADER_LABELS.status,
    isSorting: false,
    bodyCellProps: {
      render: StatusCells,
      styles: cell,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: SORTABLE_KEYS.editing,
    label: '',
    contentHeader: '',
    bodyCellProps: {
      render: EditingCells,
      styles: cellButtons,
    },
  },
]
