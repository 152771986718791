import { TForm } from "../types";

export const mapFormToValues = (
  formValues: TForm,
  indicatorMethodology: number,
): any => {
  return formValues.sign.map((el) => ({
    indicator_methodology: indicatorMethodology,
    variant: el.variant?.id,
    variety: el.variety?.map(({ id }) => id),
  }));
};
