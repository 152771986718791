import { makeAutoObservable } from "mobx";
import { RootStore } from "./Root";
import {
  THEME_MODAL,
  TThemeModal,
  TVariantModal
} from "@/apps/Modals/const";
import { v4 as uuidv4 } from "uuid";

interface IModal {
  variant: TVariantModal;
  modalProps: any;
  key: string;
  _key: string;
  isOpen: boolean;
  withoutCross?: boolean;
}

export class ModalStore {
  rootStore: RootStore;
  customDataStyles: string | null;
  theme: TThemeModal;
  modals: IModal[];
  withoutCross: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.theme = THEME_MODAL.DEFAULT; // это пресет со стилями для саммой модалки
    this.customDataStyles = null; // дата атрибут для кастомных стилей
    this.modals = [];
    this.withoutCross = false; // скрываем крестик

    makeAutoObservable(this);
  }

  setCustomDataStyles = (prop: string) => {
    this.customDataStyles = prop;
  };

  setTheme = (theme: TThemeModal): string => {
    this.theme = theme;
    return this.theme;
  };

  open = (
    variant: TVariantModal,
    modalProps?: any,
    withoutCross = false
  ): void => {
    const key = uuidv4();
    const modal = {
      variant,
      withoutCross: modalProps?.withoutCross ?
      this.setWithoutCross(modalProps.withoutCross) :
      this.setWithoutCross(withoutCross),
      modalProps: {
        key,
        _key: key,
        theme: this.theme,
        ...modalProps
      },
      key,
      _key: key,
      isOpen: true
    };
    this.modals.push(modal);
  };
  
  setWithoutCross = (withoutCross: boolean): boolean => {
    this.withoutCross = withoutCross;
    return this.withoutCross;
  };

  /**
   * Установка новых параметров для модалки по ключу
   * @param key ключ
   * @param newProps новые параметры
   */
  setModalPropsByKey = (key, newProps: any) => {
    const modals = this.modals.map((el) =>
      el.key === key || el._key === key
        ? {
          ...el,
          modalProps: { ...el.modalProps, ...newProps }
        }
        : el
    );
    this.modals = modals;
  };

  /**
   * Закрытие без эффекта
   */
  pop = (): IModal | undefined => {
    this.theme = THEME_MODAL.DEFAULT;
    return this.modals.pop();
  };

  /**
   * Закрытие с эффектом
   */
  close = (): void => {
    const modal = this.modals.pop();
    if (modal) {
      modal.modalProps?.onClose && modal.modalProps.onClose();
    }
    this.theme = THEME_MODAL.DEFAULT;
  };
}
