import { useState } from "react";
import {
  FiltersType
} from "@/components/Filters/interfaces";
import { IFilter, IValue } from "@/interfaces";
import {
  useInfiniteScroll,
  useQuery,
  useStores
} from "@/hooks";
import useDebounce from "@/hooks/useDebounce";
import { SWR_KEYS } from "@/const";
import {
  ICultivarFieldVarietyQueryParams
} from "@/pages/CultivarFieldVariety/interfaces";



const useFilters = (): IFilter[] => {
  const { api } = useStores();

  const query = useQuery<ICultivarFieldVarietyQueryParams>();

  const [searchCultivar, setSearchCultivar] = useState("");
  const dbouncedSearchCultivarValue = useDebounce(
    searchCultivar,
    500,
  );

  const getKey = (index: number) => {
    return {
      _key: SWR_KEYS.getCultivarFieldVarietyList(),
      page: index + 1,
      search: dbouncedSearchCultivarValue,
    };
  };

  const {
    data: cultivarData,
    handleScroll: handleCultivarScroll
  } =
    useInfiniteScroll(getKey, api.regulatoryInfo.getCultivarList, {
      revalidateFirstPage: false,
    });

  const cultivarOptions = cultivarData.map(({
    name,
    id
  }) => {
    return { label: name, key: id };
  });

  const [searchPlot, setSearchPlot] = useState("");

  const dbouncedSearchPlot = useDebounce(
    searchPlot,
    500,
  );

  const getPlotKey = (index: number) => {
    return {
      key: SWR_KEYS.getPlotList(),
      page: index + 1,
      search: dbouncedSearchPlot,
    };
  };

  const fetcherPlots = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getPlotList(
      {
        page,
        search,
      },
    );
  };

  const {
    data: plotsData,
    handleScroll: handleScrollPlot,
  } = useInfiniteScroll(getPlotKey, fetcherPlots, {
    revalidateFirstPage: false,
  });

  const plot: IValue[] = plotsData.map(
    ({ name, id }) => {
      return { label: name, key: id };
    },
  );

  const filters = [
    {
      name: "cultivar",
      label: "Культура",
      type: FiltersType.autoComplete,
      selectedValue: query.cultivar,
      options: cultivarOptions,
      value: "",
      handleScroll: handleCultivarScroll,
      style: { minWidth: "230px", flexGrow: 1 },
      setValue: setSearchCultivar,
    },
    {
      name: "plot",
      label: "ГСУ",
      type: FiltersType.autoComplete,
      selectedValue: query.plot,
      handleScroll: handleScrollPlot,
      options: plot,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
      setValue: setSearchPlot
    },
  ];

  return filters;
};

export default useFilters;
