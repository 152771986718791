import React from "react";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";
import { Box, IconButton, Typography } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { useStores } from "@/hooks";

interface IProps {
  title?: string;
}

/**
 * хедер для страницы добавления плана
 * @returns React.FC
 */
const HeaderAddPlan: React.FC = () => {
  const navigate = useNavigate();
  const { headerStore } = useStores();

  const { title = "Добавить план" }: IProps =
    headerStore.getProps();

  const handleBack = () => {
    navigate({
      pathname: ROUTES_PATHS.offBudget,
    });
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <IconButton onClick={handleBack}>
        <NavigateBeforeIcon />
      </IconButton>
      <Box>
        <Typography
          fontSize={24}
          fontWeight={500}
          variant="h3"
          noWrap
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default observer(HeaderAddPlan);
