import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import { MODULES } from "@/pages/Handbook/fixtures";
import {
  Addres,
  LigthZone,
  Cultivar,
  Countries,
  Posts,
  Employee,
  Disease,
  Quantity,
  Organizations,
  Units,
  MaterialType,
  Remark,
  Subject,
  Order,
  Regions,
  Reproductions,
  Indicators,
  Methodology,
  Duty,
  Branches,
  TеchnologicalMaps,
  Departments,
  Varieties,
  VegetationStages,
  AgriculturalMachinery,
} from "./templates";
import { useQuery, useStores } from "@/hooks";
import { VARIANT_HEADER } from "../HeaderContent/const";
import useRightSidebar from "@/hooks/useRightSidebar";
import { IModule } from "./interfaces";
import Districts from "./templates/Districts";
import CultivarUsageType from "./templates/CultivarUsageType";
import CultivarGroup from "./templates/CultivarGroup";
import VegetationCauses from "./templates/VegetationCauses";
import Plot from "./templates/Plot";
import Chemistry from "./templates/Chemistry";
import Expense from "./templates/Expense";
import BudgetaryTrailsCalc from "./templates/BudgetaryTrialsCalc";
import Rate from "./templates/Rate";
import Contructs from "./templates/Agreement";

const Main = () => {
  const [content, setContent] =
    useState<JSX.Element | null>(null);
  const { headerStore, queryStringSidebarCollector } =
    useStores();
  const [, setSearchParams] = useSearchParams();
  const { handleOpen, sidebarProps } = useRightSidebar();

  const query = useQuery() as any;

  const VARIANTS = {
    [MODULES.addres.id]: Addres,
    [MODULES.light_zone.id]: LigthZone,
    [MODULES.cultivars.id]: Cultivar,
    [MODULES.countries.id]: Countries,
    [MODULES.posts.id]: Posts,
    [MODULES.employee.id]: Employee,
    [MODULES.quantity.id]: Quantity,
    [MODULES.disease.id]: Disease,
    [MODULES.units.id]: Units,
    [MODULES.material_type.id]: MaterialType,
    [MODULES.remark.id]: Remark,
    [MODULES.subject.id]: Subject,
    [MODULES.organizations.id]: Organizations,
    [MODULES.order.id]: Order,
    [MODULES.regions.id]: Regions,
    [MODULES.reproductions.id]: Reproductions,
    [MODULES.indicators.id]: Indicators,
    [MODULES.methodology.id]: Methodology,
    [MODULES.duty.id]: Duty,
    [MODULES.branches.id]: Branches,
    [MODULES.districts.id]: Districts,
    [MODULES.tеchnologicalMaps.id]: TеchnologicalMaps,
    [MODULES.departments.id]: Departments,
    [MODULES.varieties.id]: Varieties,
    [MODULES.vegetation_stages.id]: VegetationStages,
    [MODULES.agricultural_machinery.id]: AgriculturalMachinery,
    [MODULES.cultivar_usage_type.id]: CultivarUsageType,
    [MODULES.cultivar_group.id]: CultivarGroup,
    [MODULES.vegetation_causes.id]: VegetationCauses,
    [MODULES.plot.id]: Plot,
    [MODULES.chemistry.id]: Chemistry,
    [MODULES.expense.id]: Expense,
    [MODULES.budgetary_trials_calc.id]: BudgetaryTrailsCalc,
    [MODULES.rate.id]: Rate,
    [MODULES.agreement.id]: Contructs,
  };
  

  useEffect(() => {
    if (query.module in VARIANTS) {
      const Component = VARIANTS[query.module];
      const CURRENT_MODULE = Object.values(MODULES).find(
        (el) => el.id === query.module,
      ) as unknown as IModule;

      // setup new header title
      if (CURRENT_MODULE?.title) {
        headerStore.setEmptyProps();
        headerStore.setHeader(VARIANT_HEADER.HANDBOOK_AUTO);
        headerStore.setTitle(CURRENT_MODULE.title);
      }
      /**
       * Обрабатывает событие клика по строке, обновляя массив выбранных строк.
       *
       * @param {React.MouseEvent<unknown>} event - Событие клика.
       * @param {number} id - ID кликнутой строки.
       */
      const handleOpenRightSidebar = (
        _: React.MouseEvent<unknown>,
        id: number,
      ) => {
        if (Number(sidebarProps.id) === id) {
          return;
        }
        const { str } = queryStringSidebarCollector.setup({
          tab: undefined,
          module: CURRENT_MODULE.id,
          keyContent: CURRENT_MODULE.module,
          module_id: id,
        });

        handleOpen({
          type: CURRENT_MODULE.module,
          modalProps: {
            detail_id: id,
          },
        });
        setSearchParams(str);
      };
      setContent(
        <Component
          handleOpenRightSidebar={handleOpenRightSidebar}
        />,
      );
    }
  }, [query.module, sidebarProps.id]);

  return <>{content}</>;
};

export default observer(Main);
