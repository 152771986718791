import { IColumn } from "@/apps/Table/interfaces";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { QUANTITY_HEADER_LABELS, QUANTITY_KEYS } from "../const";

import { TQuantityKeys } from "../interfaces";
import {
  MaterialTypeCell,
  QuantityCell,
  TypeCell,
  СultivarCell,
  EditingCell,
} from "../templates/Quantity/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: "",
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const quantityColumns: IColumn<TQuantityKeys>[] = [
  {
    label: QUANTITY_HEADER_LABELS.type,
    key: QUANTITY_KEYS.type,
    contentHeader: QUANTITY_HEADER_LABELS.type,
    bodyCellProps: {
      render: TypeCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: QUANTITY_HEADER_LABELS.cultivar,
    key: QUANTITY_KEYS.cultivar,
    contentHeader: QUANTITY_HEADER_LABELS.cultivar,
    bodyCellProps: {
      render: СultivarCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: QUANTITY_HEADER_LABELS.material_type,
    key: QUANTITY_KEYS.material_type,
    contentHeader: QUANTITY_HEADER_LABELS.material_type,
    bodyCellProps: {
      render: MaterialTypeCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: QUANTITY_HEADER_LABELS.quantity,
    key: QUANTITY_KEYS.quantity,
    contentHeader: QUANTITY_HEADER_LABELS.quantity,
    bodyCellProps: {
      render: QuantityCell,
      styles: cellPointText,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: "editing",
    label: QUANTITY_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCell,
      styles: cellButtons,
    },
  },
];
