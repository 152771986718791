import { IFilter, IValue } from "@/interfaces";
import { FiltersType } from "@/components/Filters/interfaces";
import { PLANNING_STATUS_INPUT } from "@/pages/PlanningHp/const";

export const getFilters = (
  plots: IValue[],
  handlePlotsScroll: (
    event: React.UIEvent<HTMLElement, UIEvent>,
  ) => void,
  plotValue?: IValue,
  statusValue?: any, // статус
  // приказы
  orderValue?: IValue,
  orders?: IValue[],
  handleOrderScroll?: (
    event: React.UIEvent<HTMLElement, UIEvent>,
  ) => void,
  setValue?: any,
): IFilter[] => [
  {
    name: "plot",
    label: "ГСУ",
    type: FiltersType.autoComplete,
    selectedValue: plotValue,
    options: plots,
    value: "",
    searchKey: "search_plot",
    handleScroll: handlePlotsScroll,
    style: { minWidth: "180px", flexGrow: 1 },
  },
  {
    name: "order_number",
    label: "Приказ",
    type: FiltersType.autoComplete,
    selectedValue: orderValue,
    options: orders,
    value: "",
    searchKey: "search_order_number",
    handleScroll: handleOrderScroll,
    style: { minWidth: "180px", flexGrow: 1 },
    setValue: setValue,
  },
  {
    name: "status",
    label: "Статус",
    type: FiltersType.autoComplete,
    selectedValue: statusValue,
    options: PLANNING_STATUS_INPUT,
    value: "",
    style: { minWidth: "230px", flexGrow: 1 },
  },
];
