import { useStores, useInfiniteScroll } from "@/hooks";
import { useState } from "react";
import useDebounce from "@/hooks/useDebounce";
import { SWR_KEYS } from "@/const";
import {
  FiltersType
} from "@/components/Filters/interfaces";

interface IParams {
  selectedValue?: {
    key: number | string;
    label: string;
  } | null
  name?: string,
  label?: string
}

export const useBranchFilter = ({
  selectedValue,
  name = "branch",
  label = "Филиал"
}: IParams) => {
  const { api } = useStores();
  const [search, setSearch] = useState("");
  const dbouncedValue = useDebounce(
    search,
    500,
  );

  const getKey = (index: number) => {
    return {
      _key: SWR_KEYS.getBranchList(),
      page: index + 1,
      search: dbouncedValue,
    };
  };

  const {
    data,
    handleScroll
  } =
    useInfiniteScroll(getKey, api.regulatoryInfo.getBranchList, {});

  const options = data.map(({
    name,
    id
  }) => {
    return { label: name, key: id };
  });
  return {
    name: name,
    label: label,
    type: FiltersType.autoComplete,
    selectedValue: selectedValue,
    options: options,
    value: "",
    handleScroll: handleScroll,
    style: { minWidth: "230px", flexGrow: 1 },
    setValue: setSearch,
  };
};