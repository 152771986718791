import dayjs, { Dayjs } from "dayjs";
import yup from "@/utils/yup";
import { ILabRate } from "@/api/interfaces/responses";
import { MIN_NUMBERS, MAX_NUMBERS } from "@/const";

export interface IValue {
  id: number;
  label: string;
}

export const FIELDS_NAME = {
  DATE: "date",
  RATE: "rate",
  VALUE: "value",
} as const;

export interface IForm {
  [FIELDS_NAME.DATE]: Dayjs | null;
  [FIELDS_NAME.VALUE]: number | string;
  [FIELDS_NAME.RATE]: IValue | null;
}

export const initialValues = {
  [FIELDS_NAME.DATE]: null,
  [FIELDS_NAME.VALUE]: "",
  [FIELDS_NAME.RATE]: null,
};

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.DATE]: yup.mixed().required(),
  [FIELDS_NAME.RATE]: yup.mixed().required(),
  [FIELDS_NAME.VALUE]: yup.number().min(MIN_NUMBERS.MIN).max(MAX_NUMBERS.MAX).required(),
});

export const serializeFormToValue = (payload: IForm) => {
  return {
    date: dayjs(payload.date).format("YYYY-MM-DD"),
    value: Number(payload.value),
    rate: payload.rate?.id as number,
  };
};

export const serializeValueToForm = (payload: ILabRate): IForm => {
  return {
    date: payload.date ? dayjs(payload.date) : null,
    value: payload.value || "",
    rate: payload.rate ? {
      ...payload.rate,
      label: payload.rate.name
    } : null
  };
};
