import React from "react";
import { AxiosError } from "axios";
import { FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import ModalStepper from "@/components/ModalStepper";
import SubjectFormFirstStep
  from "./components/SubjectFormSteps/first";
import SubjectFormSecondStep
  from "./components/SubjectFormSteps/second";
import SubjectFormThirdStep
  from "./components/SubjectFormSteps/third";
import {
  firstValidationSchema,
  lastValidationSchema,
  secondValidationSchema,
} from "./const";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import { IProps, TForm } from "./types";
import { mapFormToValue, mapValueToForm } from "./mappings";


/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const SubjectHandbook: React.FC<IProps> = ({
  handleClose,
  handlePop,
  modalProps,
}) => {
  const initialValues = modalProps.initialValues


  const baseInititialValues: TForm = Object.assign(
    {
      subject_type: "individual",
      emails: [{ id: 0, email: "" }],
      phones: [{ id: 0, phone: "" }],
      first_name: '',
      second_name: '',
      last_name: '',
      company_name: '',
      inn: null,
      snils: null,
      okpo: '',
      kpp: '',
      ogrn: '',
      bank: '',
      checking_account: '',
      bik: '',
      correspondent_account: '',
      address: null,
      legal_address: null,
      actual_address: null,
      bank_inn: null,
      bank_kpp: '',
    },
    modalProps.initialValues,
  );

  const { subjectId } = modalProps;
  const { api, toastStore, swrStore } = useStores();

  const mutateDetail = subjectId
    ? swrStore.mutators[SWR_KEYS.getSubjectById(subjectId)]
    : null;

  const mutateList =
    swrStore.mutators[SWR_KEYS.getSubjectList()];

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const promise = subjectId
      ? api.statement.updateSubject(
          subjectId,
          mapFormToValue(values),
        )
      : api.statement.postSubject(mapFormToValue(values));

    return promise
      .then((data) => {
        mutateList && mutateList();
        mutateDetail && mutateDetail();
        modalProps.handleAccept &&
          modalProps.handleAccept(data);
        handlePop();
      })
      .catch((error: AxiosError) => {
        const errorMessage = errorToString(error);
        toastStore.createToast({
          type: TOAST_TYPES.ALERT,
          toastProps: {
            message: errorMessage,
            severity: "error",
          },
        });
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  const subtitle = subjectId
    ? "Редактировать запись"
    : "Добавить запись";

  const values = initialValues
    ? mapValueToForm(initialValues)
    : baseInititialValues;

  return (
    <ModalStepper
      title="Субъект"
      subtitle={subtitle}
      initialValues={values}
      onSubmit={handleSubmit}
      handleClose={handleClose}
      enableReinitialize={true}
    >
      <ModalStepper.Step
        validationSchema={firstValidationSchema}
      >
        <SubjectFormFirstStep
          modalProps={modalProps}
          handlePop={handlePop}
          handleClose={handleClose}
        />
      </ModalStepper.Step>

      <ModalStepper.Step
        validationSchema={secondValidationSchema}
      >
        <SubjectFormSecondStep
          modalProps={modalProps}
          handlePop={handlePop}
          handleClose={handleClose}
        />
      </ModalStepper.Step>

      <ModalStepper.Step
        validationSchema={lastValidationSchema}
      >
        <SubjectFormThirdStep
          modalProps={modalProps}
          handlePop={handlePop}
          handleClose={handleClose}
        />
      </ModalStepper.Step>
    </ModalStepper>
  );
};

export default SubjectHandbook;
