import React, { ReactElement, useMemo } from "react";
import { Pagination as PaginationComponent } from "@garpix/pagination/react";
import { IPagination } from "@garpix/pagination/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button, Typography, useTheme } from "@mui/material";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

import {
  blockStyles,
  boxStyles,
  buttonStyles,
  currentPageStyles,
  numberPageStyles,
  pageStyles,
} from "./styles";

interface IProps {
  currentPage: number;
  total: number;
  pageSize: number;
  handleChange: (page: number) => void;
}

/**
 *
 * @param currentPage - текущая страница
 * @param total - общее число сущностей
 * @param pageSize - максимальное количество элементов на странице
 * @param handleChange - функция обработчик смены страницы
 * @returns ReactElement
 */
const Pagination = ({
  currentPage,
  total,
  pageSize,
  handleChange,
}: IProps): ReactElement => {
  const theme = useTheme();
  // высчитываемое число - номер первого элемента на странице
  const beforeCount = useMemo(
    () => (currentPage - 1) * pageSize + 1,
    [currentPage, pageSize]
  );
  // высчитываемое число - номер последнего элемента на странице
  const afterCount = useMemo(
    () => Math.min(currentPage * pageSize, total),
    [currentPage, pageSize, total]
  );
  // текст отображаемый на странице
  const textCountElementPage = useMemo(
    () => `${beforeCount} - ${afterCount} из ${total}`,
    [beforeCount, afterCount, total]
  );

  // функция перехода на следующую страницу
  const handleAddPage = (): void => {
    handleChange(currentPage + 1);
  };

  // функция перехода на прошлую страницу
  const handleMinusPage = (): void => {
    handleChange(currentPage - 1);
  };

  // функция перехода на первую страницу
  const handleSetFirstPage = (): void => {
    handleChange(1);
  };

  // функция перехода на конкретную страницу
  const handleSetPage = (value: number): void => {
    handleChange(value);
  };

  return (
    <PaginationComponent
      currentPage={currentPage}
      total={total}
      pageSize={pageSize}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        totalPages,
      }: IPagination) => {
        if (total > pageSize) {
          return (
            <Box sx={boxStyles()}>
              <Typography>{textCountElementPage}</Typography>

              <Box sx={blockStyles()}>
                <Button
                  sx={buttonStyles(theme)}
                  disabled={!hasPreviousPage}
                  onClick={handleSetFirstPage}
                >
                  <SkipPreviousIcon />
                </Button>

                <Button
                  sx={buttonStyles(theme)}
                  disabled={!hasPreviousPage}
                  onClick={handleMinusPage}
                >
                  <ChevronLeftIcon />
                </Button>

                <Box sx={numberPageStyles(theme)}>
                  {pages.map((item, i) => {
                    if (typeof item === "string") {
                      return <Typography key={`dots_${i}`}>...</Typography>;
                    }
                    return (
                      <Button
                        sx={
                          item === currentPage
                            ? currentPageStyles(theme)
                            : pageStyles(theme)
                        }
                        key={item}
                        onClick={() => handleChange(item)}
                      >
                        {item}
                      </Button>
                    );
                  })}
                </Box>

                <Button
                  sx={buttonStyles(theme)}
                  disabled={!hasNextPage}
                  onClick={handleAddPage}
                >
                  <ChevronRightIcon />
                </Button>

                <Button
                  sx={buttonStyles(theme)}
                  disabled={!hasNextPage}
                  onClick={() => handleSetPage(totalPages)}
                >
                  <SkipNextIcon />
                </Button>
              </Box>
            </Box>
          );
        }
      }}
    </PaginationComponent>
  );
};

export default Pagination;
