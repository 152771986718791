import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=86-113421&mode=design&t=NsBAwfXhny5rGRRi-0
const HpIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="16.666672"
        height="16.770813"
        viewBox="0 0 16.6667 16.7708"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          id="menstrual_health"
          d="M8.33334 0C9.01389 0.819458 9.625 1.57983 10.1667 2.28125C10.7083 2.98267 11.1944 3.65277 11.625 4.29169L16.6667 0.520813L16.6667 8.4375C16.6667 9.59027 16.4479 10.6736 16.0104 11.6875C15.5729 12.7014 14.9792 13.5833 14.2292 14.3333C13.4792 15.0833 12.5972 15.6771 11.5833 16.1146C10.5694 16.5521 9.48611 16.7708 8.33334 16.7708C7.18056 16.7708 6.09722 16.5521 5.08334 16.1146C4.06944 15.6771 3.1875 15.0833 2.4375 14.3333C1.6875 13.5833 1.09375 12.7014 0.65625 11.6875C0.21875 10.6736 0 9.59027 0 8.4375L0 0.520813L5.04166 4.29169C5.47222 3.66669 5.95834 3 6.5 2.29169C7.04166 1.58331 7.65278 0.819458 8.33334 0ZM1.66666 3.85419L1.66666 8.4375C1.66666 9.04858 1.74653 9.63544 1.90625 10.1979C2.06597 10.7604 2.28472 11.2847 2.5625 11.7708C2.53472 11.632 2.51736 11.4965 2.51041 11.3646C2.50347 11.2327 2.5 11.0903 2.5 10.9375C2.5 10.1458 2.62847 9.32983 2.88541 8.48956C3.14236 7.64929 3.5625 6.72919 4.14584 5.72919L1.66666 3.85419ZM8.33334 2.60419C6.80556 4.46527 5.72916 6.09027 5.10416 7.47919C4.47916 8.86804 4.16666 10.0208 4.16666 10.9375C4.16666 12.0903 4.57291 13.0729 5.38541 13.8854C6.19791 14.6979 7.18056 15.1042 8.33334 15.1042C9.48611 15.1042 10.4688 14.6979 11.2812 13.8854C12.0938 13.0729 12.5 12.0903 12.5 10.9375C12.5 10.0208 12.1875 8.86108 11.5625 7.45831C10.9375 6.05554 9.86111 4.4375 8.33334 2.60419ZM15 3.85419L12.5208 5.70831C13.1042 6.70831 13.5243 7.62848 13.7812 8.46875C14.0382 9.30902 14.1667 10.132 14.1667 10.9375C14.1667 11.0903 14.1632 11.2327 14.1562 11.3646C14.1493 11.4965 14.1319 11.632 14.1042 11.7708C14.382 11.2847 14.6007 10.7604 14.7604 10.1979C14.9201 9.63544 15 9.04858 15 8.4375L15 3.85419Z"
          fill="#00A049"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
};

export default HpIcon;
