import React from "react";
import { Grid, Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import { IRegionMain } from "@/api/interfaces/responses";
import { useIntl } from "@/hooks";

const StatsGrid: React.FC<Pick<IRegionMain, 'totals'>> = ({totals}) => {
  const { numberFormat } = useIntl()
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const gridData = [
    { label: "Регионы", value: totals.region || "-" },
    { label: "Филиалы", value: totals.branch || "-" },
    { label: "ГСУ", value: totals.plot || "-" },
    {
      label: "Общая численность",
      value: `${totals.staffing} чел`,
    },
    { label: "Общая площадь", value: `${numberFormat(totals.total_area)} га` },
    { label: "Техника", value: totals.machinery || "-" },
  ];

  return (
    <Box
      sx={{
        border: "1px solid #ddd",
        padding: "16px",
        borderRadius: "5px",
      }}
    >
      <Grid container spacing={2}>
        {gridData.map((item, index) => (
          <Grid
            item
            xs={4}
            sm={2}
            lg={2}
            xl={2}
            key={index}
            sx={{
              position: "relative",
              paddingLeft: index > 0 ? "16px" : "0",
            }}
          >
            {index > 0 && !(isXsScreen && index === 3) && (
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  opacity: "0.5",
                  top: "60%",
                  transform: "translateY(-50%)",
                  height: "80%",
                  borderLeft: "1px solid grey",
                }}
              />
            )}
            <Typography variant="body2">
              {item.label}:
            </Typography>
            <Typography variant="h4">
              {item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StatsGrid;
