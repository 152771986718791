import React, { Fragment } from 'react'
import { useStores } from '@/hooks';
import { Form, useFormikContext } from 'formik';
import { Box, Button, Divider,Stack, Typography } from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import theme from '@/theme';
import { IProps, RATE_FIELD_NAME, TForm } from '../const';
import FastRegexTextField from '@/components/FastRegexTextField';

/**
 * Форма создания/редактирования фазы вегетации
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * */

export const RateForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<TForm>();


  const title = modalProps.rateId
    ? "Редактировать запись"
    : "Добавить запись";

  return (
    <Fragment>
      <Box p={3}>
        <Typography fontSize={12} color={theme.palette.blackAndWhite.gray}>
        Показатели 
        </Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>

        <Stack direction={"column"} gap={2} p={2}>
          <FastRegexTextField 
            name={RATE_FIELD_NAME.NAME}
            label={"Название"}
            fullWidth
            required
            size="small"
          />
        </Stack>

        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red'
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type='submit'
            disabled={formik.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  )
}
