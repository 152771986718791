import AbstractApi from "@/api/abstract";
import { apiInterface, resInterface } from "@/api/interfaces";
import { AxiosRequestConfig } from "axios";

class UserApi implements apiInterface.IUserApi {
  ctx: AbstractApi;

  constructor(ctx: AbstractApi) {
    this.ctx = ctx;
  }

  postChangePassword = async (params = {}): Promise<any> => {
    try {
      const res = await this.ctx.post<unknown, resInterface.IGetCurrentUserRes>(
        "/users/user/change_password/",
        params
      );
      return {
        data: res.data,
        statsCode: 200
      };
    } catch (error) {
      const status = error?.response?.status;
      switch (status) {
        default:
          return {
            data: error?.response?.data || {},
            statusCode: 500
          };
      }
    }
  };
  // /api/users/user/current_user/
  getCurrentUser = async (
    params = {},
    axiosParams: AxiosRequestConfig = {}
  ): Promise<any> => {
    try {
      const res = await this.ctx.get<unknown, resInterface.IGetCurrentUserRes>(
        "/users/user/current_user/",
        params,
        axiosParams
      );
      return {
        data: res.data,
        statsCode: 200
      };
    } catch (error) {
      const status = error?.response?.status;
      switch (status) {
        default:
          return {
            data: error?.response?.data || {},
            statusCode: 500
          };
      }
    }
  };

  postChangeAvatar = async (
    params = {},
    axiosParams: AxiosRequestConfig = {}
  ): Promise<any> => {
    const res = await this.ctx.patch<unknown, resInterface.IGetUserRes>(
      "/users/user/update_avatar/",
      params,
      axiosParams
    );
    return res.data;
  };

  getUser = async (
    params = {},
    axiosParams: AxiosRequestConfig = {}
  ): Promise<any> => {
    try {
      const res = await this.ctx.get<unknown, resInterface.IGetUserRes>(
        "/users/user/",
        params,
        axiosParams
      );

      return {
        data: res.data,
        statsCode: 200
      };
    } catch (error) {
      const status = error?.response?.status;
      switch (status) {
        case 401:
          return {
            url: "/login",
            data: error.response.data,
            statusCode: 401
          };
        default:
          return {
            data: error?.response?.data || {},
            statusCode: 500
          };
      }
    }
  };
  
  getUsers = async (
    params = {},
    axiosParams: AxiosRequestConfig = {}
  ): Promise<any> => {
    const res = await this.ctx.get<unknown, resInterface.IUserShort>(
      "/users/user/",
      params,
      axiosParams
    );

    return res.data;
  };

  postSignStatement = async (
    params = {},
    axiosParams: AxiosRequestConfig = {}
  ): Promise<any> => {
    const res = await this.ctx.get<unknown, resInterface.IUserShort>(
      "/users/user/",
      params,
      axiosParams
    );

    return res.data;
  };
}

export default UserApi;
