import React from "react";
import { Button, Stack } from "@mui/material";
import NavigateBeforeIcon
  from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon
  from "@mui/icons-material/NavigateNext";
import CheckIcon from "@mui/icons-material/Check";

interface IStepControls {
  handleAccept?: () => void,
  disabled?: boolean,
  step: number,
  handleBack: () => void,
  isLastStep: boolean,
  nextButtonLabel?: string
}

const StepControls: React.FC<IStepControls> = ({
  step,
  handleBack,
  isLastStep,
  nextButtonLabel = "Далее",
  disabled = false,
  handleAccept
}) => {
  const handleClick = () => {
    handleAccept && handleAccept()
  }
  return (
    <Stack direction="row" spacing={2} mt={4}>
      {step > 0 ? (
        <Button
          disabled={disabled}
          variant="outlined"
          color="secondary"
          onClick={handleBack}
          startIcon={<NavigateBeforeIcon/>}
        >
          Назад
        </Button>
      ) : null}
      <Button
        endIcon={isLastStep ? null : <NavigateNextIcon/>}
        startIcon={isLastStep ? <CheckIcon/> : null}
        disabled={disabled}
        variant="contained"
        onClick={handleClick}
      >
        {isLastStep ? "Готово" : nextButtonLabel}
      </Button>
    </Stack>
  );
};

export default StepControls;