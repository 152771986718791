import { styled } from "@mui/material/styles";
import MuiIconButton from "@mui/material/IconButton";

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.blackAndWhite.stroke
}));