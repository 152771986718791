import { styled, Theme } from "@mui/material/styles";
import { THEME_MODAL, TThemeModal } from "@/apps/Modals/const";
import { Box, BoxProps } from "@mui/material";
import MuiIconButton from "@mui/material/IconButton";
import { defaultModalTheme, w555ModalTheme, fullscreenModalTheme } from "./thems";

interface IContainerProps extends BoxProps {
  modalTheme: TThemeModal;
}

const getCurrentTheme = (data: {
  theme: Theme;
  modalTheme: IContainerProps["modalTheme"];
}): NonNullable<unknown> => {
  const THEMS = {
    [THEME_MODAL.FULL_SCREEN]: fullscreenModalTheme(data.theme),
    [THEME_MODAL.DEFAULT]: defaultModalTheme(data.theme),
    [THEME_MODAL.W_555]: w555ModalTheme(data.theme)
  };
  return THEMS[data.modalTheme];
};

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== "modalTheme"
})<IContainerProps>((data) => getCurrentTheme(data));


export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  fontSize: 32,
  color: theme.palette.blackAndWhite.stroke
}));

export const Controls = {
  position: "absolute",
  display: "flex",
  right: '13px',
  top: '17px'
};
