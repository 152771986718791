import yup from "@/utils/yup";
import {
  IVegetationCause
} from "@/api/interfaces/responses";
import { TVarietyStatus } from "@/api/types";
import { VARIETY_STATUS } from "@/const";

export const FIELDS_NAME = {
  VEGETATION_CAUSE: "vegetation_cause",
  STATUS: "status",
} as const;

export interface IFormik {
  [FIELDS_NAME.VEGETATION_CAUSE]: IVegetationCause | null,
  [FIELDS_NAME.STATUS]: TVarietyStatus
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.STATUS]: yup.string().required(),
  [FIELDS_NAME.VEGETATION_CAUSE]: yup.mixed().nullable().when(FIELDS_NAME.STATUS, {
    is: (status: TVarietyStatus) => status === VARIETY_STATUS.NOT_SOWED,
    then: (schema) => schema.required()
  }),
});

export const initialValues = {
  [FIELDS_NAME.STATUS]: VARIETY_STATUS.AWAIT_SOWING,
  [FIELDS_NAME.VEGETATION_CAUSE]: null,
};

export const valueToForm = (payload: Partial<IFormik>) => ({
  [FIELDS_NAME.STATUS]: payload.status || VARIETY_STATUS.AWAIT_SOWING,
  [FIELDS_NAME.VEGETATION_CAUSE]: payload.vegetation_cause ? {
    ...payload.vegetation_cause,
    label: payload.vegetation_cause.name
  } : null,
});