import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import dayjs from "dayjs";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import DownloadSeedArrivalJournalForm
  from "./components/DownloadSeedArrivalJournalForm";
import {
  IFormik,
  initialValues,
  validationSchema,
} from "./const";
import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import {
  errorToString,
  downloadFile
} from "@/utils/helpers";
import { DOWNLOAD_SEED_ARRIVAL } from "@/const";
import { ValueOf } from "@/utils/types";

/**
 * Компонент модалки реализующий скачивание учет поступления семян
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    type: ValueOf<typeof DOWNLOAD_SEED_ARRIVAL>
    title?: string
  };
}

const DownloadSeedArrivalJournal: React.FC<IProps> = ({
  modalProps,
  handleClose
}) => {

  const { api, toastStore } = useStores();

  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };
  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {
    helpers.setSubmitting(true);
    const payload = {
      plot: values.plot?.id as number,
      from_date: values.date[0] ? dayjs(values.date[0]).format("YYYY-MM-DD") : undefined,
      to_date: values.date[1] ? dayjs(values.date[1]).format("YYYY-MM-DD") : undefined
    };
    const promise = modalProps.type === DOWNLOAD_SEED_ARRIVAL.DEFAULT
      ? api.agriculturalLabour.downloadSeedArrival(payload.plot, {
        from_date: payload.from_date,
        to_date: payload.to_date
      })
      : api.agriculturalLabour.downloadSeedArrivalJournal(payload.plot, {
        from_date: payload.from_date,
        to_date: payload.to_date
      });
    const name = modalProps.type === DOWNLOAD_SEED_ARRIVAL.DEFAULT
      ? "Сведения ГСУ"
      : "Журнал учета поступления семян";
    promise.then(data => {
      downloadFile(data, name);
    }).catch((error) => {
      showToast(error);
    }).finally(() => {
      handleClose();
      helpers.setSubmitting(false);
    });
  };

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {modalProps.title || "Учет поступления семян"}
        </Typography>
      </Box>
      <Divider/>
      <Formik<IFormik>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={DownloadSeedArrivalJournalForm}
      />
    </Box>
  );
};

export default DownloadSeedArrivalJournal;
