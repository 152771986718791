import React from "react";
import { Box, Grid } from "@mui/material";
import {
  QS_KEYS,
  QUERY_STRING_MICRO_ROUTES_MAIN,
} from "@/apps/RightSidebar/const";
import GeneralInfo
  from "@/pages/Branch/components/tabs/GeneralInfo";
import AgricultiralMachinery
  from "@/pages/Branch/components/tabs/AgriculturalMachinery";
import VarietyTesting from "./tabs/VarietyTesting";
import ProductionActivity from "./tabs/ProductionActivity";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import { GSUHeader } from "./GSUHeader/GSUHeader";
import GroupTabsGSU from "./GroupTabsGSU";
import LandsArea from "./tabs/LandsArea";
import { IPlotMain } from "@/api/interfaces/responses";

/**
 * Содержит список табов для раздела ГСУ
 */


interface IProps {
  renderData: IPlotMain
}
const GSUView: React.FC<IProps> = ({renderData}) => {
  const HeaderProps = {
    breadcrumbItems: [
      { id: 1, label: "Главная", href: ROUTES_PATHS.main },
      {
        id: 2,
        label: renderData.branch.region.name,
        href: ROUTES_PATHS.main
      },
      {
        id: 3,
        label: renderData.branch.name,
        href: `${ROUTES_PATHS.branch}/${renderData.branch.id}?tab=${QUERY_STRING_MICRO_ROUTES_MAIN.GSU.path}`
      },
      {
        id: 4,
        label: renderData.name,
      },
    ],
    title: renderData.name,
  };

  const tabsContent = [
    {
      name: "ОБЩИЕ СВЕДЕНИЯ",
      content: <GeneralInfo renderData={renderData} />,
      keyqs:
      QUERY_STRING_MICRO_ROUTES_MAIN.GENERAL_INFO.path,
    },
    {
      name: "ЗЕМЕЛЬНЫЕ УЧАСТКИ",
      content: <LandsArea  data={renderData.locations}/>,
      keyqs: QUERY_STRING_MICRO_ROUTES_MAIN.LOCATION.path,
    },
    {
      name: "СЕЛЬХОЗ.ТЕХНИКА",
      content: <AgricultiralMachinery data={renderData.machineries?.summary || []} />,
      keyqs:
      QUERY_STRING_MICRO_ROUTES_MAIN
          .AGRICULTIRAL_MACHINERY.path,
    },
    {
      name: "СОРТООПЫТЫ",
      content: <VarietyTesting />,
      keyqs:
      QUERY_STRING_MICRO_ROUTES_MAIN.VARIETY_TESTING
          .path,
    },
    {
      name: "ПРОИЗВОДСТВЕННАЯ ДЕЯТЕЛЬНОСТЬ",
      content: <ProductionActivity />,
      keyqs:
      QUERY_STRING_MICRO_ROUTES_MAIN.PRODUCTION_ACTIVITY
          .path,
    },
  ];

  const CollapseContent = (
    <GroupTabsGSU
      tabsData={tabsContent}
      tabType={QS_KEYS.TAB}
      baseTabValue={
        QUERY_STRING_MICRO_ROUTES_MAIN.GENERAL_INFO.path
      }
      centered={false}
      variant='scrollable'
    />
  );

  return (
    <Box>
      <GSUHeader {...HeaderProps} />
      <Grid
        container
        direction="column"
        wrap={"nowrap"}
        sx={{ width: "100%", height: "inherit" }}
      >
        <Grid
          container
          direction={"column"}
          wrap={"nowrap"}
          height={"inherit"}
        >
          {CollapseContent}
        </Grid>
      </Grid>
    </Box>
  );
};

export default GSUView;
