import React, { useEffect, useLayoutEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { AxiosError } from "axios";
import { FormikValues } from "formik/dist/types";
import { VARIANT_HEADER } from "@/apps/HeaderContent/const";
import { useRightSidebar, useStores } from "@/hooks";
import {
  IFormik,
  initialValues,
  serializeValues,
  validationSchema,
  serializeFormToValues
} from "./const";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString, pickFrom } from "@/utils/helpers";
import {
  SWR_KEYS,
  KEYS_SIDEBAR_VARIANT_MODULES
} from "@/const";
import Loader from "@/components/Loader";

import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import CultivarFieldVarietyLabForm from "./components/CultivarFieldVarietyLabForm";

/**
 * Компонент реализующий форму добавления/редактирования плана использования земли
 */
const AddCultivarFieldVarietyLab = () => {
  const { id } = useParams();
  const {
    headerStore,
    api,
    toastStore,
    swrStore, queryStringSidebarCollector
  } = useStores();
  const navigate = useNavigate();
  const { handleCloseInstantly } = useRightSidebar();
  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };
  const { data, isLoading, mutate, error } = useSWR(
    id ? SWR_KEYS.getCultivarFieldVarietyById(id) : null,
    () => api.agriculturalLabour.getCultivarFieldVarietyById(id as string),
    {
      onError: showToast
    }
  );
  const mutateList =
    swrStore.mutators[SWR_KEYS.getCultivarFieldVarietyLabList()];

  const handleBack = (id?: number | string) => {
    if (id) {
      const { str } = queryStringSidebarCollector.setup({
        tab: undefined,
        keyContent: KEYS_SIDEBAR_VARIANT_MODULES.CULTIVAR_FIELD_VARIETY_LAB,
        module_id: id,
      });
      navigate({
        pathname: ROUTES_PATHS.testingExpertReview,
        search: `?${str}`
      });
    } else {
      navigate(ROUTES_PATHS.testingExpertReview);
    }
  };
  const title = id ? "Редактировать опыты (экспертная оценка)" : "Добавить опыты (экспертная оценка)";

  useLayoutEffect(() => {
    headerStore.setProps({
      title: title,
      handleBack: () => handleBack(id)
    });
    headerStore.setHeader(VARIANT_HEADER.DEFAULT);
  }, [id, title]);

  useEffect(() => {
    return () => {
      handleCloseInstantly();
    };
  }, []);

  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const data = serializeFormToValues(values);
    const promise = id ? api.agriculturalLabour.patchCultivarFieldVarietyById(id, data) : api.agriculturalLabour.generateCultivarFieldVariety(data)
    promise
      .then((data) => {
        handleBack(data.id);
        mutateList && mutateList();
        id && mutate();
      })
      .catch((error: AxiosError) => {
        const formikErrors = pickFrom(error?.response?.data, Object.keys(initialValues));
        if (formikErrors) {
          helpers.setErrors(formikErrors);
        } else {
          showToast(error);
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  if (isLoading) {
    return <Loader/>;
  }

  if (error) {
    return null;
  }

  const values = id
    ? serializeValues(data)
    : initialValues;

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      component={CultivarFieldVarietyLabForm}
    />
  );
};

export default AddCultivarFieldVarietyLab;
