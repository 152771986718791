import React from 'react'
import { IIcon } from './interfaces'

const RevisionIcon = ({className}: IIcon) => {
  return (
    <svg
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16663 15.5C6.08329 15.5 4.31246 14.7708 2.85413 13.3125C1.39579 11.8542 0.666626 10.0833 0.666626 8C0.666626 6.95833 0.864543 5.98264 1.26038 5.07292C1.65621 4.16319 2.19093 3.37153 2.86454 2.69792C3.53815 2.02431 4.32982 1.48958 5.23954 1.09375C6.14927 0.697917 7.12496 0.5 8.16663 0.5C9.20829 0.5 10.184 0.697917 11.0937 1.09375C12.0034 1.48958 12.7951 2.02431 13.4687 2.69792C14.1423 3.37153 14.677 4.16319 15.0729 5.07292C15.4687 5.98264 15.6666 6.95833 15.6666 8V8.16667L16.7708 7.04167L17.9583 8.20833L14.8333 11.3333L11.7083 8.20833L12.8958 7.04167L14 8.14583V8C14 6.38889 13.4305 5.01389 12.2916 3.875C11.1527 2.73611 9.77774 2.16667 8.16663 2.16667C6.55552 2.16667 5.18052 2.73611 4.04163 3.875C2.90274 5.01389 2.33329 6.38889 2.33329 8C2.34718 9.61111 2.9201 10.9861 4.05204 12.125C5.18399 13.2639 6.55552 13.8333 8.16663 13.8333C8.95829 13.8333 9.70135 13.684 10.3958 13.3854C11.0902 13.0868 11.7013 12.6806 12.2291 12.1667L13.4166 13.3542C12.7361 14.0208 11.9479 14.5451 11.052 14.9271C10.1562 15.309 9.1944 15.5 8.16663 15.5Z"
        fill="#F7A70A"
      />
    </svg>
  )
}

export default RevisionIcon