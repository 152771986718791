import { IFilter } from "@/interfaces";
import { useQuery } from "@/hooks";
import {
  IExpertAssessmentQueryParams
} from "@/pages/ExpertAssessment/interfaces";
import {
  FiltersType
} from "@/components/Filters/interfaces";
import {
  PLANNING_STATUS_INPUT
} from "@/pages/Planning/const";

const useFilters = (): IFilter[] => {
  const query = useQuery<IExpertAssessmentQueryParams>();

  const filters = [
    {
      name: "status",
      label: "Статус",
      type: FiltersType.autoComplete,
      selectedValue: query.status,
      options: PLANNING_STATUS_INPUT,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
    {
      name: "date_range",
      label: "Дата регистрации",
      type: FiltersType.yearsRange,
      selectedValue: [query?.date_range_after, query?.date_range_before],
      value: "",
      style: { minWidth: "260px", flexGrow: 1 },
    },
  ];

  return filters;
};

export default useFilters;