import React from "react";
import { Formik, FormikHelpers } from "formik";
import useSWR from "swr";
import { useStores } from "@/hooks";
import {
  IFormik,
  initialValues,
  serializeValues,
  validationSchema,
} from "./const";
import { FormikValues } from "formik/dist/types";
import { TYPES_MAP_OBJECT } from "@/apps/Map/const";
import { TOAST_TYPES } from "@/apps/Toast";
import {
  errorToString,
  getRandomColor,
} from "@/utils/helpers";
import { SWR_KEYS } from "@/const";
import Loader from "@/components/Loader";
import CultivarForm from "./components/CultivarForm";
import { Box } from "@mui/material";
import { observer } from "mobx-react";

interface IProps {
  handleClose: () => void;
  modalProps: {
    cultivarId?: number;
    fieldId: number;
    locationId?: number;
    isEdit?: boolean;
    hasFieldCoordinates: boolean;
  };
}

const AddCultivarToField: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const { cultivarId, fieldId, locationId, isEdit, hasFieldCoordinates } =
    modalProps || {};
  const { api, toastStore, swrStore, mapStore } =
    useStores();
  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error",
      },
    });
  };
  const { data, isLoading, mutate, error } = useSWR(
    cultivarId
      ? SWR_KEYS.getAgriculturalLabourCultivarField(
          cultivarId,
        )
      : null,
    () =>
      api.agriculturalLabour.getCultivarFieldById(
        cultivarId as number,
      ),
    {
      onError: showToast,
    },
  );
  const mutateList =
    swrStore.mutators[
      SWR_KEYS.getAgriculturalLabourCultivarFieldList()
    ];
  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    if(values.coordinates.length){
      values.coordinates.push(
        values.coordinates[0] as unknown as any,
      );
    }

    const data = {
      cultivar: values.cultivar?.id as number,
      colour: values.colour,
      area: values.area as number,
      map_object: values.coordinates.length ? JSON.stringify({
        type: TYPES_MAP_OBJECT.Polygon,
        coordinates: [
          values.coordinates.map((el) => [el[1], el[0]]),
        ],
      }): null,
      field: fieldId,
    };

    const promise = cultivarId
      ? api.agriculturalLabour.updateCultivarField(
          cultivarId,
          data,
        )
      : api.agriculturalLabour.createCultivarField(data);
    promise
      .then(() => {
        handleClose();
        mutateList && mutateList();
        cultivarId && mutate();
      })
      .catch((error) => {
        showToast(error);
      })
      .finally(() => {
        mapStore.setIsDrawPolygon(false);
        swrStore.mutators[
          SWR_KEYS.getAllFIeldFromArea()
        ]?.();
        swrStore.mutators[
          SWR_KEYS.getAllSortFromField(fieldId)
        ]?.();
        swrStore.mutators[
          SWR_KEYS.getAgriculturalLabourField(fieldId)
        ]?.();
        if (locationId)
          swrStore.mutators[
            SWR_KEYS.getAgriculturalLabourLocation(
              locationId,
            )
          ]?.();
        helpers.setSubmitting(false);
      });
  };

  if (isLoading) {
    return (
      <Box height={300}>
        <Loader />
      </Box>
    );
  }

  if (error) {
    return null;
  }

  const values = cultivarId
    ? serializeValues(data)
    : Object.keys(mapStore.initialValuesForms).length
    ? mapStore.initialValuesForms
    : { ...initialValues, colour: getRandomColor() };

  const initValues = isEdit
    ? { ...mapStore.initialValuesForms }
    : values;

  return (
    <Formik
      initialValues={initValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      component={() => (
        <CultivarForm
          cultivarId={cultivarId}
          fieldId={fieldId}
          hasFieldCoordinates={hasFieldCoordinates}
        />
      )}
    />
  );
};

export default observer(AddCultivarToField);
