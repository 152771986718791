import React, { useMemo } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { useQuery } from "@/hooks";
import { Box } from "@mui/material";
import qs from "qs";
import { useSearchParams } from "react-router-dom";
import { IYearsRangeQueryParams } from "../interfaces";
import { isNull, isUndefined } from "@/utils/helpers";
import { IFilter } from "@/interfaces";
import dayjs from "dayjs";

interface DateRangeFilterProps {
  filter: IFilter;
  onChange?: (date: Date | null) => void;
}

const YearPicker: React.FC<DateRangeFilterProps> = ({ filter }) => {
  const [, setSearchParams] = useSearchParams();
  const query = useQuery<IYearsRangeQueryParams>();

  const formattedValue: Date | null = useMemo(() => {
    const timeAfter: any = !isUndefined(filter.selectedValue)
      ? dayjs().year(filter.selectedValue)
      : null;

    return timeAfter;
  }, [filter]);

  const handleChangeYear = (value: Date) => {
    const date = new Date(value);
    const formattedValue = !isNull(value) ? date.getFullYear() : undefined;

    const queryParams = qs.stringify(
      {
        ...query,
        year: formattedValue,
        page: 1,
      },
      { arrayFormat: "comma" }
    );
    setSearchParams(queryParams);
  };

  const label = filter.label || "Год"

  return (
    <Box maxWidth="180px">
      <DatePicker
        name={"year"}
        onChange={handleChangeYear}
        label={label}
        openTo="year"
        views={["year"]}
        value={formattedValue}
        slotProps={{
          field: { clearable: true },
          textField: {
            size: "small",
            fullWidth: true
          },
        }}
      />
    </Box>
  );
};

export default YearPicker;
