import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";
import Logo from "@/pages/auth/components/Logo";
import Layout from "./components/Layout";

const Error404 = observer(() => {
  const navigate = useNavigate();

  const handleClickRedirectToBack = () => {
    navigate(-2);
  };

  return (
    <Layout>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={2}
      >
        <Logo
          position={"relative"}
          top={"auto"}
          left={"auto"}
          transform={"none"}
          pb={2}
        />
        <Typography textAlign={"center"} variant="h1">
          Страница не найдена!
        </Typography>
        <Typography
          variant="h4"
          fontWeight={400}
          textAlign={"center"}
          lineHeight={"24px"}
        >
          Страница, которую вы ищете, не существует,
          возможно она устарела или была удалена.
        </Typography>
        <Stack direction={"row"}>
            <Button
              onClick={handleClickRedirectToBack}
              variant={"contained"}
              color={"primary"}
              size="medium"
            >
              Назад
            </Button>
        </Stack>
      </Stack>
    </Layout>
  );
});

export default Error404;
