import React, { FC, useEffect, useState } from "react";
import { SWR_KEYS } from "@/const";
import { useInfiniteScroll, useStores } from "@/hooks";
import { Field, Form, useFormikContext } from "formik";
import { FIELDS_NAME, IFormik } from "../const";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import theme from "@/theme";
import Autocomplete from "@/components/Autocomplete";

interface IProp {
  cultivarName: string;
  cultivarId: number;
}
/**
 * Форма создания заявки
 */

export const AddSignForm: FC<IProp> = ({
  cultivarName = "",
  cultivarId
}) => {
  const { api, modalStore, swrStore } = useStores();
  const formik = useFormikContext<IFormik>();
  const [searchSign, setSearchSign] = useState("");
  const swrKey = SWR_KEYS.getIndicatorMethodology();

  const getParameters = (index: number) => ({
    _key: swrKey,
    page: index + 1,
    type_trial_indicator: "hp",
    type_trial_methodology: "hp",
    search: searchSign,
    cultivar: cultivarId
  });

  const fetcher = (args) => {
    const {
      page,
      type_trial_indicator,
      type_trial_methodology,
      search,
      cultivar
    } = args;
    return api.regulatoryInfo.getIndicatorMethodologyList({
      page,
      type_trial_indicator,
      type_trial_methodology,
      search,
      cultivar
    });
  };

  const {
    data: data,
    handleScroll: handleScroll,
    mutate: mutate,
  } = useInfiniteScroll(getParameters, fetcher, {
    revalidateFirstPage: false,
  });

  const handleChangeSign = (event, child) => {
    formik.setFieldValue(FIELDS_NAME.SIGN, child);
    formik.setFieldValue(
      FIELDS_NAME.INDICATOR,
      child?.indicator,
    );
    formik.setFieldValue(
      FIELDS_NAME.METHODOLOGY,
      child?.methodology,
    );
  };

  const handleCancel = () => {
    modalStore.close();
  };

  useEffect(() => {
    swrStore.addMutator(swrKey, mutate);
  }, []);

  return (
    <Form noValidate>
      <Box>
        <Box p={3}>
          <Typography
            sx={{
              color: theme.palette.blackAndWhite.gray,
            }}
          >
            {cultivarName}
          </Typography>

          <Typography variant="h2">
            Добавить признак
          </Typography>
        </Box>

        <Divider />

        <Stack p={3} direction="column" spacing={3}>
          <Field name={FIELDS_NAME.SIGN}>
            {({ field, meta }) => (
              <FormControl required>
                <Autocomplete
                  {...field}
                  onChange={handleChangeSign}
                  label="Признак"
                  data={data}
                  required
                  handleScroll={handleScroll}
                  error={meta.touched && !!meta.error}
                  search={setSearchSign}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color="red"
            onClick={handleCancel}
          >
            Отмена
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};
