import yup from "@/utils/yup";

import { reqInterface, resInterface } from "@/api/interfaces";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    unitId?: number;
    initialValues?: resInterface.IUnit;
  };
  onSubmit?: () => void;
}

export type TForm = reqInterface.IUnit;

export enum UNITS_FIELD_NAME {
  NAME = "name",
  FULL_NAME = "full_name"
}

export const validationSchema = yup.object().shape({
  [UNITS_FIELD_NAME.NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Имя не может быть пустым'),

  [UNITS_FIELD_NAME.FULL_NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Добавьте описание'),
});
