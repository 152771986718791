import yup from "@/utils/yup";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    id: number,
    day: string | number,
    fieldId: number | string,
    initialValues: any
  };
  onSubmit?: () => void;
}

export interface TForm {
  [WEATHER_DATA_FIELD_NAME.LOCATION]: number | null,
  [WEATHER_DATA_FIELD_NAME.DATE]: string,
  [WEATHER_DATA_FIELD_NAME.MAXTEMP]: string,
  [WEATHER_DATA_FIELD_NAME.MINTEMP]: string,
  [WEATHER_DATA_FIELD_NAME.AVRTEMP]: string,
  [WEATHER_DATA_FIELD_NAME.TOTAL_PRECIP]: string,
  [WEATHER_DATA_FIELD_NAME.TOTAL_SNOW]: string,
  [WEATHER_DATA_FIELD_NAME.AVR_HUM]: string,
  [WEATHER_DATA_FIELD_NAME.CONDITION]: string,
  [WEATHER_DATA_FIELD_NAME.MAX_WIND]: string,
  [WEATHER_DATA_FIELD_NAME.SOIL]: string,
}

export enum WEATHER_DATA_FIELD_NAME {
    LOCATION = "location",
    DATE = "date",
    MAXTEMP = "maxtemp_c",
    MINTEMP = "mintemp_c",
    AVRTEMP = "avgtemp_c",
    TOTAL_PRECIP = "totalprecip_mm",
    TOTAL_SNOW = "totalsnow_cm",
    AVR_HUM = "avghumidity",
    CONDITION = "condition",
    MAX_WIND = "maxwind_kph",
    SOIL = "soil_surface_min_temp",
}


export const mapValueToForm = (value) => {
  return {
    [WEATHER_DATA_FIELD_NAME.MAXTEMP]: value?.maxtemp_c || '',
    [WEATHER_DATA_FIELD_NAME.MINTEMP]: value?.mintemp_c || '',
    [WEATHER_DATA_FIELD_NAME.AVRTEMP]: value?.avgtemp_c || '',
    [WEATHER_DATA_FIELD_NAME.TOTAL_PRECIP]: value?.totalprecip_mm || '',
    [WEATHER_DATA_FIELD_NAME.TOTAL_SNOW]: value?.totalsnow_cm || '',
    [WEATHER_DATA_FIELD_NAME.AVR_HUM]: value?.avghumidity || '',
    [WEATHER_DATA_FIELD_NAME.CONDITION]: value?.condition || '',
    [WEATHER_DATA_FIELD_NAME.MAX_WIND]: value?.maxwind_kph || '',
    [WEATHER_DATA_FIELD_NAME.SOIL]: value?.soil_surface_min_temp || '',

  }
};

export const mapFormToValue = (values: TForm) => {
  return {
    [WEATHER_DATA_FIELD_NAME.LOCATION]: values?.location || null,
    [WEATHER_DATA_FIELD_NAME.DATE]: values?.date || '',
    [WEATHER_DATA_FIELD_NAME.MAXTEMP]: values?.maxtemp_c || '',
    [WEATHER_DATA_FIELD_NAME.MINTEMP]: values?.mintemp_c || '',
    [WEATHER_DATA_FIELD_NAME.AVRTEMP]: values?.avgtemp_c || '',
    [WEATHER_DATA_FIELD_NAME.TOTAL_PRECIP]: values?.totalprecip_mm || '',
    [WEATHER_DATA_FIELD_NAME.TOTAL_SNOW]: values?.totalsnow_cm || '',
    [WEATHER_DATA_FIELD_NAME.AVR_HUM]: values?.avghumidity || '',
    [WEATHER_DATA_FIELD_NAME.CONDITION]: values?.condition || '',
    [WEATHER_DATA_FIELD_NAME.MAX_WIND]: values?.maxwind_kph || '',
    [WEATHER_DATA_FIELD_NAME.SOIL]: values?.soil_surface_min_temp || '',
  };
};


export const validationSchema = yup.object().shape({
  [WEATHER_DATA_FIELD_NAME.MAXTEMP]: yup.mixed().required(),
  [WEATHER_DATA_FIELD_NAME.MINTEMP]: yup.mixed().required(),
  [WEATHER_DATA_FIELD_NAME.AVRTEMP]:yup.mixed().required(),
  [WEATHER_DATA_FIELD_NAME.TOTAL_PRECIP]: yup.mixed(),
  [WEATHER_DATA_FIELD_NAME.TOTAL_SNOW]: yup.mixed(),
  [WEATHER_DATA_FIELD_NAME.AVR_HUM]: yup.mixed().required(),
  [WEATHER_DATA_FIELD_NAME.CONDITION]: yup.mixed().required(),
  [WEATHER_DATA_FIELD_NAME.MAX_WIND]: yup.mixed().required(),
  [WEATHER_DATA_FIELD_NAME.SOIL]:yup.mixed().required(),
});

