import React from "react";
import { Chip } from "@mui/material";

interface IProps {
  text?: string;
  fontSize?: string;
  textColor?: string;
  bgColor?: string;
}

/**
 * Компонент Выводит фишку с изменяемыми параметрами цвета текста и бекграунда
 * @param text - содержимое для пользователя
 * @param textColor цвет текста,
 * @param bgColor бекграунда.
 */
export const ColorChip = ({
  text = "",
  fontSize = "13px",
  textColor = "black",
  bgColor = "transparent"
}: IProps) => (
  <Chip
    className="color-chip"
    label={text}
    variant={bgColor === "transparent" ? "outlined" : undefined}
    sx={{
      fontSize: fontSize,
      color: textColor,
      backgroundColor: bgColor,
      height: 22,
      mt: "6px",
      mr: "5px"
    }}
  />
);
