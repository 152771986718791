import React from "react";
import { Field, useFormikContext } from "formik";
import { IValue } from "@/interfaces";
import {
  FormHelperText,
  SelectProps,
  SelectChangeEvent,
  FormControl
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

interface IProps extends SelectProps {
  name: string
  handleChange?: (payload: string) => void,
  options: IValue[]
}

/**
 * Автокомплит с опциями
 * @param name - имя
 * @param handleChange - калбек при выборе
 */

const OPTIONS: IValue[] = [
  {
    key: "hp",
    label: "Хоз. полезность",
  },
  {
    key: "oos",
    label: "ООС",
  },
];

const FormikSelect: React.FC<IProps> = ({
  name,
  handleChange,
  options = OPTIONS,
  fullWidth,
  required,
  ...props
}) => {
  const formik = useFormikContext();
  const onChange = (event: SelectChangeEvent) => {
    handleChange ? handleChange(event.target.value) : formik.setFieldValue(name, event.target.value);
  };

  if (!formik) {
    throw new Error(
      "OptionsAutocomplete has to be used within <Formik>"
    );
  }

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <FormControl variant="outlined"
                     fullWidth={fullWidth}
                     size="small"
                     required={required}
        >
          <InputLabel
            id="demo-simple-select-label">{props.label || "Age"}</InputLabel>
          <Select
            id="demo-simple-select-label"
            label="Age"
            fullWidth={fullWidth}
            {...field}
            {...props}
            onChange={onChange}
          >
            {
              options.map(option => (
                <MenuItem key={option.key}
                          value={option.key}>{option.label}</MenuItem>
              ))
            }
          </Select>
          <FormHelperText
            error={meta.touched && !!meta.error}
          >
            {meta.touched && meta.error}
          </FormHelperText>
        </FormControl>
      )}
    </Field>
  );
};

export default FormikSelect;