import {
  FILE_ACCEPT, IMAGE_ACCEPT,
  TDropzoneVariant,
  DROPZONE_VARIANT,
  SUBTITLE,
  IMAGE_SUBTITLE
} from "@/components/FileDropzone/const";

export const getAccept = (variant: TDropzoneVariant): Record<string, any> => {
  return variant === DROPZONE_VARIANT.FILE ? FILE_ACCEPT : IMAGE_ACCEPT
}

export const getSubtitle = (variant: TDropzoneVariant): string => {
  return variant === DROPZONE_VARIANT.FILE ? SUBTITLE : IMAGE_SUBTITLE
}