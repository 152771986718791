import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { UNITS_HEADER_LABELS, UNITS_KEYS } from "../const";

import { TUnitsKeys } from "../interfaces";
import { EditingCellsUnits } from "../templates/Units/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: ""
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить"
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const UnitColumns: IColumn<TUnitsKeys>[] = [
  {
    label: UNITS_HEADER_LABELS.name,
    key: UNITS_KEYS.name,
    contentHeader: UNITS_HEADER_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: UNITS_HEADER_LABELS.full_name,
    key: UNITS_KEYS.full_name,
    contentHeader: UNITS_HEADER_LABELS.full_name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    key: "editing",
    label: UNITS_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsUnits,
      styles: cellButtons
    }
  }
];
