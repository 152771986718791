import React from "react";
import { observer } from "mobx-react";
import { NoSSR } from "@/components/NoSSR";
import { useStores } from "@/hooks";
import ToastBase from "@/apps/Toast/ToastBase";
import { AlertToast } from "@/apps/Toast/ToastContent";

/**
 * набор тостов
 * key- тип уведомления
 * value - компонента контента уведомления
 *
 */

export const TOAST_TYPES = {
  ALERT: "ALERT",
  EMPTY: "EMPTY"
};

const TOASTS = {
  [TOAST_TYPES.ALERT]: AlertToast,
  [TOAST_TYPES.EMPTY]: () => null
};

const Toast = observer(() => {
  const { toastStore } = useStores();

  return (
    <NoSSR>
      {toastStore.toasts.map(
        ({ type, key, toastProps, ...props }, index) => {
          const CurrentToast =
            TOASTS[type ?? TOAST_TYPES.EMPTY];
          if (
            CurrentToast === null ||
            CurrentToast === undefined
          ) {
            return null;
          }
          return (
            <ToastBase
              {...props}
              key={key}
              onClose={() =>
                toastStore.deleteToast(index)}
            >
              <CurrentToast
                {...toastProps}
                onClose={() =>
                  toastStore.setClosing(index)}
              />
            </ToastBase>
          );
        }
      )}
    </NoSSR>
  );
});

export default Toast;
