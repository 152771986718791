import React from "react";
import { Field, useFormikContext } from "formik";
import { TForm, BRANCH_FIELD_NAME } from "../../const";

import {
  Box,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { EmailField } from "./email";
import { PhoneField } from "./phone";
import theme from "@/theme";

const BranchFormSecondStep: React.FC = () => {
  const form = useFormikContext<TForm>();

  return (
    <>
      <Stack direction={"column"} gap={2} p={0}>
        <Box>
          <Typography>Телефоны</Typography>
          <PhoneField />
        </Box>
        <Box>
          <Typography>Электронные почты</Typography>
          <EmailField />
        </Box>
        <Field name={BRANCH_FIELD_NAME.COMMENT}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                inputProps={{
                  maxLength: 100,
                }}
                label='Комментарий'
                fullWidth
                size='small'
                multiline
                minRows={4}
                maxRows={10}
                sx={{ minHeight: "100px" }}
                onChange={form.handleChange}
                onBlur={field.handleBlur}
                value={form.values[BRANCH_FIELD_NAME.COMMENT]}
                disabled={meta.isSubmitting}
              />

              <Box
                sx={{
                  color: theme.palette.text.secondary,
                }}
              >
                {form.values.comment?.length ?? 0}/100
              </Box>

              <FormHelperText error={meta.touched && !!meta.error}>
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
      </Stack>
    </>
  );
};

export default BranchFormSecondStep;
