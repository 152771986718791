import React from "react";
import { FastField, Form, Formik } from "formik";
import {
  Box,
  Button,
  Divider,
  TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  defaultValues,
  FIELDS_NAME,
  IFormik,
  validationSchema
} from "./const";

interface IProps {
  handleClose: () => void,
  modalProps: {
    handleAccept: (payload: string) => void
  };
}

const AddCommentary: React.FC<IProps> = ({
  modalProps,
  handleClose
}) => {

  const handleSubmit = (values: IFormik) => {
    modalProps.handleAccept && modalProps.handleAccept(values.commentary.trim());
    handleClose()
  };

  return (
    <Formik initialValues={defaultValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
    >
      {() => (
        <Form noValidate>
          <Box>
            <Box p={3}>
              <Typography variant="h2">Добавить
                комментарий</Typography>
            </Box>
            <Divider/>

            <Box p={3}>
              <FastField name={FIELDS_NAME.COMMENTARY}>
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    multiline
                    size="medium"
                    minRows={5}
                    label="Комментарий"
                    fullWidth={true}
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </FastField>
            </Box>

            <Divider/>

            <Box p={3} display="flex"
                 justifyContent="space-between">
              <Button variant="text" color="red"
                      onClick={handleClose}>
                Отмена
              </Button>
              <Button type="submit" variant="contained"
                      color="primary">
                Сохранить
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddCommentary;
