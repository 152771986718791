import {
  createContext,
  Dispatch,
  SetStateAction
} from "react";

interface IStepContext {
   maxSteps: number,
   step: number
   isLastStep: boolean,
   handleBack: () => void,
   handleNext: () => void
   setCompleted: Dispatch<SetStateAction<boolean>>
 }

export const StepperContext = createContext<IStepContext | null>(
  null
);
