
import { IFilter, IValue } from "@/interfaces";
import { FiltersType } from "@/components/Filters/interfaces";

export const getFilters = (
  handleScroll: (event: React.UIEvent<HTMLElement, UIEvent>) => void,
  region: IValue[],
  regionValue?: IValue,
): IFilter[] => {
  return [
    {
      name: "region",
      label: "Регион",
      type: FiltersType.autoComplete,
      selectedValue: regionValue,
      searchKey: 'search_region',
      options: region,
      handleScroll: handleScroll,
      value: "",
      style: { minWidth: "230px", flexGrow: 1 },
    },
  ]
}
