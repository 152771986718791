import React from "react";
import { SvgIcon } from "@mui/material";

const ExpandContentIcon = () => {
  return (
    <SvgIcon>
      <svg width="24" height="24" viewBox="0 0 24 24"
           fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1950_15335)">
          <mask id="mask0_1950_15335"
                maskUnits="userSpaceOnUse" x="0" y="0"
                width="24" height="24">
            <rect width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_1950_15335)">
            <path
              d="M5 19V13H7V17H11V19H5ZM17 11V7H13V5H19V11H17Z"
              fill="currentColor"/>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1950_15335">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default ExpandContentIcon;