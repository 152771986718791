import { List } from "@mui/material";
import { Box, styled } from "@mui/system";

export const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  "*": {
    flex: 1,
  },
  border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
  borderRadius: "4px",
  padding: "10px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start"
  }
}));

export const StyledRoot = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1)
  }
}));

export const StyledList = styled(List)(({ theme }) => ({
  columns: 2,
  "li": {
    padding: 0,
  },
  "li + li": {
    marginTop: "10px"
  },
  [theme.breakpoints.down("sm")]: {
    columns: 1
  }
}));