import { makeAutoObservable } from "mobx";
import { RootStore } from "./Root";
import { TVariantHeader, VARIANT_HEADER } from "@/apps/HeaderContent/const";

/**
 * стор для хранения данных о текущем хедере на странице
 */
export class HeaderStore {
  rootStore: RootStore;
  headerProps: any;
  variant: TVariantHeader; // вариант контента
  title: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.headerProps = null;
    this.variant = VARIANT_HEADER.EMPTY;
    this.title = '';
    makeAutoObservable(this);
  }

  getTitle = () => this.title;

  setTitle = (title: string) => {
    this.title = title;
  };

  setProps = (props: any): void => {
    this.headerProps = props;
  };

  setEmptyProps = (): void => {
    this.headerProps = null;
  };

  getProps = (): any => {
    return { ...this.headerProps };
  };

  setHeader = (variant: TVariantHeader) => {
    this.variant = variant;
  };

  getHeader = (): TVariantHeader => {
    return this.variant;
  };
}
