import yup from "@/utils/yup";

import {
  reqInterface,
  resInterface,
} from "@/api/interfaces";

export interface IProps {
  handleClose: () => void;
  handlePop: () => void;
  modalProps: {
    key: string;
    remarkId?: number;
    initialValues?: resInterface.IRemarkHandbook;
    handleAccept: (
      payload: resInterface.IRemarkHandbook,
    ) => void;
    fromAdd?: boolean;
    fromCancel?: boolean;
  };
  onSubmit?: () => void;
}

export type TForm = reqInterface.IRemarkHandbook;

export enum FIELD_NAME {
  NAME = "name",
  REMARK_TYPE = "remark_type",
  DESCRIPTION = "description",
}

export const validationSchema = yup.object().shape({
  [FIELD_NAME.NAME]: yup.string().required(),
});

export const HANDBOOK_REMARK_TYPE = {
  revision: "revision",
  refusal: "refusal",
};

export const mapFormToValues = (values: TForm) => {
  return {
    [FIELD_NAME.NAME]: values.name,
    [FIELD_NAME.REMARK_TYPE]: values.remark_type,
    [FIELD_NAME.DESCRIPTION]: values.description,
    is_active: values.is_active,
  };
};
