import React, {
  useCallback,
  useMemo,
  useState
} from "react";
import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikValues
} from "formik";
import FormikStep, {
  IFormikStepProps
} from "./components/FormikStep";

import MobileControls from "./components/MobileControls";
import { Root } from "./styles";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";

export interface IModalStepper extends FormikConfig<FormikValues> {
  children?: React.ReactNode;
  title: string;
  subtitle?: string;
  handleClose: () => void;
  initialStep?: number;
}

/**
 * Рендерит контент в зависимости от шага + валидирует в зависимости от шага */
const ModalStepper = ({
  title,
  subtitle,
  handleClose,
  children,
  initialStep=0,
  ...props
}: IModalStepper) => {
  const [step, setStep] = useState(initialStep);

  /** Компоненты шагов*/
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<IFormikStepProps>[];
  /** Текущий компонент шага*/
  const currentChild = childrenArray[step];

  const maxSteps = useMemo(() => childrenArray.length, [childrenArray.length]);
  const isLastStep = useMemo(
    () => step === childrenArray.length - 1,
    [childrenArray.length, step]
  );

  const handleBack = useCallback((): void => {
    setStep((s) => s - 1);
  }, []);

  const handleSubmit = (
    values: any,
    helpers: FormikHelpers<FormikValues>
  ) => {
    if (isLastStep) {
      props.onSubmit(values, helpers)
    } else {
      helpers.setSubmitting(false)
      setStep((s) => s + 1);
    }
  };

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Root>
            <Box p={3}>
              <Typography>{title}</Typography>
              {
                subtitle && (
                  <Typography
                    variant="h2">{subtitle}</Typography>
                )
              }
            </Box>
            <Divider/>
            <Box p={3}>
              {currentChild}
            </Box>
            <Divider/>
            <Box p={3}>
              <MobileControls
                isSubmitting={isSubmitting}
                steps={maxSteps}
                handleClose={handleClose}
                activeStep={step}
                handleBack={handleBack}
                isLastStep={isLastStep}
              />
            </Box>
          </Root>
        </Form>
      )}
    </Formik>
  );
};

ModalStepper.Step = FormikStep;
export default ModalStepper;
