import React, { useState } from "react";
import { Box, Stack, } from "@mui/material";
import { useStores } from "@/hooks";
import WorkHeadList from "../WorkHeadList";
import WorkEmptyList from "../WorkEmtyList";
import WorkContentList from "../WorkContentList";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import {
  IAgriculturalLabourWork
} from "@/api/interfaces/responses";
import { useFormikContext } from "formik";
import { FIELDS_NAME, IFormik } from "../../const";

interface IProps {
  isCollapseRS?: boolean;
  // handleChangeWorks: (works: IAgriculturalLabourWork[] | null) => void;
}

const WorkList: React.FC<IProps> = () => {
  const { modalStore } = useStores();
  const [, setSearch] = useState("");

  const formik = useFormikContext<IFormik>();
  const dataWork = formik.values.works;

  const handleSubmit = (work: IAgriculturalLabourWork) => {
    modalStore.close();
    const works = [...formik.values.works, work];
    void formik.setFieldValue(FIELDS_NAME.WORKS, works);
  };

  const handleAddField = () => {
    modalStore.open(
      VARIANT_MODAL.ADD_AL_WORK,
      {
        handleSubmit
      }
    );
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const collectContent = () => {

    if (dataWork) {
      return (
        <Stack
          sx={{
            maxHeight: "50vh",
            overflowX: "auto",
            paddingBottom: "10px",
          }}
          gap={2}
        >
          <WorkContentList
            data={dataWork}
            handleAddField={handleAddField}
            isDisabledBtnAdd={true}
          />
        </Stack>
      );
    }
    if (!dataWork) {
      return <WorkEmptyList
        handleAddField={handleAddField}
        isDisabledBtnAdd={true}
      />;
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: "inherit",
        width: "100%",
      }}
    >
      <WorkHeadList
        handleAddField={handleAddField}
        handleSearch={handleSearch}
        isDisabledBtnAdd={true}
      />
      {collectContent()}
    </Box>
  );
};

export default WorkList;
