import React from "react";
import { useFormikContext } from "formik";
import {
  Button,
  CircularProgress,
  MobileStepper,
} from "@mui/material";

interface IMobileControls {
  steps: number;
  activeStep: number;
  handleBack: () => void;
  isLastStep: boolean;
}

/** Степпер с кнопками для мобилки */
const MobileControls: React.FC<IMobileControls> = ({
  steps,
  activeStep,
  handleBack,
  isLastStep
}) => {
  const { isSubmitting } = useFormikContext();

  return (
    <MobileStepper
      variant="text"
      steps={steps}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Button
          startIcon={
            isSubmitting
              ? <CircularProgress size="1rem"/>
              : null
          }
          disabled={isSubmitting}
          variant="contained"
          color="primary"
          type="submit"
        >
          {isLastStep ? "Готово" : "Далее"}
        </Button>
      }
      backButton={
        <Button
          disabled={activeStep === 0 || isSubmitting}
          variant="contained"
          color="secondary"
          onClick={handleBack}
        >
          Назад
        </Button>
      }
    />
  );
};

export default MobileControls;