import React from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useStores } from "@/hooks";
import {
  THEME_MODAL,
  VARIANT_MODAL
} from "@/apps/Modals/const";
import {
  CELL_TYPES
} from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { DeleteIcon, EditIcon } from "@/components/icons";
import PlaceOutlinedIcon
  from "@mui/icons-material/PlaceOutlined";
import { EDITING_CELL } from "../../const";
import {
  StyledDeleteMenuItem,
  StyledMenuItem,
  StyledTypography,
} from "../../style";
import { buttonDotsStyle } from "@/apps/Table/style";
import {
  IAgriculturalLabourLocation
} from "@/api/interfaces/responses";
import { SWR_KEYS } from "@/const";
import { AW_NAVS } from "@/apps/HeaderContent/const";
import { ROUTES } from "@/apps/AppRouter/const";
import { TOAST_TYPES } from "@/apps/Toast";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

/**
 * @component GSUCells
 * * компонент ячейки таблицы ГСУ
 * @param
 */

export const GSUCells = (
  _: string,
  { plot }: IAgriculturalLabourLocation
): JSX.Element => {
  return (
    <Box>
      <StyledTypography variant="body2" title={plot.name}>
        {plot.name}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component NameCells
 * * компонент ячейки таблицы Название учатска
 * @param
 */

export const NameCells = (
  _: string,
  { name, colour }: IAgriculturalLabourLocation
): JSX.Element => {
  return (
    <Box display='flex' alignItems='center' flexWrap='nowrap'>
      <Box sx={{width: 18, height: 18, borderRadius:1, background: colour}} mr={1}/>
      <StyledTypography 
        variant="body2" 
        title={name}
        data-qa="checkLocation"
        >
        {name}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component RegionCells
 * * компонент ячейки таблицы ГСУ
 * @param
 */

export const RegionCells = (
  _: string,
  { plot }: IAgriculturalLabourLocation
): JSX.Element => {
  return (
    <Box>
      <StyledTypography variant="body2" title={plot.branch.region.name}>
        {plot.branch.region.name}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component BranchCells
 * * компонент ячейки таблицы филиал
 * @param
 */

export const BranchCells = (
  _: string,
  { plot }: IAgriculturalLabourLocation
): JSX.Element => {
  return (
    <Box>
      <StyledTypography variant="body2" title={plot.branch.name}>
        {plot.branch.name}
      </StyledTypography>
    </Box>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия
 * @param
 */
export const EditingCells = (
  _: string,
  cell: IAgriculturalLabourLocation
): JSX.Element => {
  const { modalStore, api, rightSidebarStore, swrStore, toastStore, userStore } = useStores();
  const [searchParam, setSearchParam] = useSearchParams();
  const mutate =
    swrStore.mutators[SWR_KEYS.getAgriculturalLabourLocationList()];
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const handleDeleteWork = (_, reject) => {
    api.agriculturalLabour
      .deleteLocation(cell.id)
      .then(() => {
        if (String(cell.id) === searchParam.get("agricultural_works_id")) {
          const type = searchParam.get("type") || AW_NAVS.location;
          setSearchParam({ type });
          rightSidebarStore.close();
        }
        mutate();
      })
      .catch(reject)
      .finally(() => {
        modalStore.close();
      });
  };

  const showWarningToast = (msg: string) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: msg,
        severity: "warning",
      },
    });
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.agriculturalWorkLocation,
      id: cell.id,
      handleDelete: handleDeleteWork,
    };

    modalStore.setTheme(THEME_MODAL.W_555);
    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
  };
  const handleClickToMap = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if(!cell.map_object){
      showWarningToast(`Участок - ${cell.name}, не имеет координат`);
      return
    }
    navigate(`/${ROUTES.map}/${ROUTES.areaMap}/?location_id=${cell.id}&?tab=detail`)
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigate(`${cell.id}/${ROUTES.addArea}`);
  };

  const canEditLocation = userStore.isPermission(
    PERMISSIONS.agriculturalLabourLocation,
    PERMISSION_CRUD.change
  )
  const canDeleteLocation = userStore.isPermission(
    PERMISSIONS.agriculturalLabourLocation,
    PERMISSION_CRUD.delete
  )
  const canViewLocation = userStore.isPermission(
    PERMISSIONS.agriculturalLabourLocation,
    PERMISSION_CRUD.view
  )

  if (!canEditLocation && !canDeleteLocation && !canViewLocation) {
    return <></>
  }

  return (
    <Box>
      <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={isOpen} open={open} onClose={handleClose}>
        {canViewLocation && 
        <StyledMenuItem onClick={handleClickToMap}>
          <PlaceOutlinedIcon />
          {EDITING_CELL.showOnMap}
        </StyledMenuItem>
        }
        {canEditLocation && 
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon />
          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
        {(canDeleteLocation && canEditLocation || canDeleteLocation && canViewLocation) &&
        <Divider />
        }
        {canDeleteLocation && 
        <StyledDeleteMenuItem onClick={handleClickDelete}>
          <DeleteIcon />
          
          <Typography color="error">{EDITING_CELL.delete}</Typography>
        </StyledDeleteMenuItem>
        }
      </Menu>
    </Box>
  );
};
