import React from "react"
import { IIcon } from "./interfaces"

const CallReceivedIcon = ({ className }: IIcon): React.ReactElement => {
  return (
    <svg
      className={className}
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333252 10.6666V3.99996H1.66659V8.39996L9.39992 0.666626L10.3333 1.59996L2.59992 9.33329H6.99992V10.6666H0.333252Z"
        fill="#FF636C"
      />
    </svg>
  )
}

export default CallReceivedIcon
