import React, { useState } from "react";
import { useFormik } from "formik";
import { observer } from "mobx-react";
import { AxiosError } from "axios";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import Yup from "@/utils/yup";
import { checkFieldError } from "@/utils/formikHelpers";
import { useStores } from "@/hooks";
import {
  flattenData,
  errorToString
} from "@/utils/helpers";
import theme from "@/theme";

import {
  THEME_MODAL,
  TVariantModal,
  VARIANT_MODAL
} from "@/apps/Modals/const";
import AddChip from "@/components/Chips/AddChip";
import {
  ChipWrap
} from "@/pages/AddStatement/components/SubjectsFormStep/styles";
import { resInterface } from "@/api/interfaces";
import {
  STATEMENT_STATUS_NAMES
} from "@/pages/Statement/const";
import { SWR_KEYS } from "@/const";
import {
  PERMISSIONS,
  PERMISSION_CRUD
} from "@/premissions";

/**
 * Validation schema using Yup for form validation.
 */
const validationSchema = Yup.object().shape({
  user: Yup.object<resInterface.IUserShort>().required("Выберите эксперта")
});

/**
 * Interface for the SignStatement component's props.
 */
interface ISignStatement {
  handleClose: () => void;
  modalProps: {
    key: string;
    statement_id: number;
    initialValues: {
      user: null | resInterface.IUserShort;
      comment: null | string;
    };
  };
}

interface IValues {
  user: resInterface.IUserShort | null;
  comment: string | null;
}

/**
 * SignStatement component for updating the password.
 * @param {ISignStatement} props - Component props.
 * @returns {React.FC<ISignStatement>} - Rendered component.
 */
const SignStatement: React.FC<ISignStatement> = ({ modalProps }) => {
  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false);
  const { api, modalStore, swrStore, userStore } = useStores();
  const [errors, setErrors] = useState<{ value: string; path: string }[]>([]);
  const [signErrors, setSignErrors] = useState<{ value: string; path: string }[]>([]);
  /**
   * Formik hook for managing form state and validation.
   */
  const formik = useFormik<IValues>({
    initialValues: {
      user: modalProps?.initialValues?.user,
      comment: modalProps?.initialValues?.comment
    },
    validationSchema,
    onSubmit: (values, formikHelpers) => {
      setIsDisabledSaveBtn(true);
      api.statement
        .checkStatementValidityById(modalProps.statement_id)
        .then(() => {
          handleSuccessSignStatement(values);
        })
        .catch(
          (
            e: AxiosError<{
              change_password?: boolean;
            }>
          ) => {
            const error = Array.isArray(e.response?.data) ?  [{value: errorToString(e), path: ""}] : flattenData(e.response?.data)
            setSignErrors(error);
          }
        )
        .finally(() => {
          formikHelpers.setSubmitting(false);
          setIsDisabledSaveBtn(false);
        });
    }
  });

  const handleCancel = () => {
    modalStore.pop();
  };

  const handleSuccessSignStatement = (values: IValues) => {
    api.statement
      .patchStatementById(modalProps.statement_id, {
        executor: values.user?.id,
        comment: values.comment,
        status: STATEMENT_STATUS_NAMES.review
      })
      .then(() => {
        modalStore.close();
        modalStore.setTheme(THEME_MODAL.W_555);
        modalStore.open(VARIANT_MODAL.SUCCESS_SING_STATEMENT, {});
      })
      .catch(
        (
          e: AxiosError<{
            change_password?: boolean;
          }>
        ) => {
          const error = Array.isArray(e.response?.data) ? [{value: errorToString(e), path: ""}]:  flattenData(e.response?.data)
          setErrors(error);
        }
      )
      .finally(() => {
        formik.setSubmitting(false);
        setIsDisabledSaveBtn(false);
        swrStore.mutators[
          SWR_KEYS.getStatementByIdKey(modalProps.statement_id)
        ]();
        swrStore.mutators[SWR_KEYS.getStatements()]();
      });
  };

  const handleAcceptModal = (user: resInterface.IUserShort): void => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...formik.values,
        user: user
      }
    });
    formik.setFieldValue("user", user);
  };

  const removeSelectedUser = () => {
    formik.setFieldValue("user", null);
  };

  const handleOpenModal = (modalVariant: TVariantModal, initialValues: any) => {
    modalStore.setTheme(THEME_MODAL.DEFAULT);
    modalStore.open(modalVariant, {
      onAcceptModal: handleAcceptModal,
      initialValues,
      statement_id: modalProps.statement_id
    });
  };

  const isEmploye = userStore.isPermission(
    PERMISSIONS.regulatory_infoEmployee,
    PERMISSION_CRUD.view
  );

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Box>
        <Box p={3}>
          <Typography variant="h2" sx={{ lineHeight: "32px" }} component="h2">
            Экспертиза
          </Typography>
        </Box>

        <Divider />
        <Box p={3}>
          <Stack spacing={3}>
            {checkFieldError(formik, "error") && (
              <Box sx={{ color: theme.palette.error.darkRed }}>
                {checkFieldError(formik, "error")}
              </Box>
            )}
          </Stack>

          <Typography variant="h4" letterSpacing={"0.4px"}>
            Выберите эксперта, к которому заявка перейдет на обработку:
          </Typography>
          <Tooltip
            title={!isEmploye ? "У вас недостаточно прав для изменения" : ""}
            followCursor
          >
            <ChipWrap sx={{ paddingTop: "24px" }}>
              {formik.values.user && (
                <Chip
                  key={formik.values.user?.id}
                  size="small"
                  variant="outlined"
                  label={formik.values.user?.full_name}
                  onDelete={!isEmploye ? undefined : removeSelectedUser}
                />
              )}
              {!formik.values.user && (
                <AddChip
                  disabled={!isEmploye}
                  onClick={() =>
                    handleOpenModal(
                      VARIANT_MODAL.SELECT_USER_SIGN,
                      formik.values
                    )
                  }
                />
              )}
            </ChipWrap>
          </Tooltip>
        </Box>
        <Box p={3}>
          <TextField
            label={"Комментарий"}
            name="comment"
            fullWidth
            multiline
            minRows={4}
            maxRows={10}
            sx={{ minHeight: "150px" }}
            error={checkFieldError(formik, "comment")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.comment}
            disabled={formik.isSubmitting}
          />
        </Box>
        {Object.values(formik.errors).map((err) => {
          return (
            <Alert
              variant={"outlined"}
              severity="error"
              sx={{ margin: "24px" }}
            >
              {err}
            </Alert>
          );
        })}

        {signErrors.length > 0 ? (
          <Alert variant={"outlined"} severity="error" sx={{margin: theme.spacing(3), marginTop: 0}}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography fontSize={14}>
                В Заявке не заполнены следующие обязательные поля:
              </Typography>
              {signErrors.map((el) => {
                return <Typography fontSize={14}>{el.value}</Typography>;
              })}
            </Box>
          </Alert>
        ) : null}
        {errors.length > 0 ? (
          <Alert variant={"outlined"} severity="error" sx={{margin: theme.spacing(3), marginTop: 0}}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {errors.map((el) => {
                return <Typography fontSize={14}>{el.value}</Typography>;
              })}
            </Box>
          </Alert>
        ) : null}

        <Divider />

        <Box p={3} display="flex" justifyContent="space-between">
          <Button
            variant="text"
            color="red"
            onClick={handleCancel}
            disabled={formik.isSubmitting}
          >
            Отмена
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            disabled={!formik.isValid || isDisabledSaveBtn}
            startIcon={
              formik.isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <CheckIcon />
              )
            }
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default observer(SignStatement);
