import {
  createTheme,
  Theme as MUITheme
} from "@mui/material/styles";

import {
  checkboxClasses,
  formLabelClasses,
  inputBaseClasses,
  radioClasses
} from "@mui/material";
import {
  TypographyStyleOptions
} from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface Palette {
    backgroundColors: IBackgroundColors;
    blackAndWhite: IBlackAndWhiteColors;
    blue: IBlueColors;
    yellow: IYellowColors;
    red: Palette["primary"] & IRedColors;
    green: IGreenColors;
    another: IAnotherColors;
  }

  interface PaletteOptions {
    backgroundColors?: IBackgroundColors;
    blackAndWhite: IBlackAndWhiteColors;
    blue?: IBlueColors;
    yellow?: IYellowColors;
    red?: PaletteOptions["primary"] & IRedColors;
    green?: IGreenColors;
    another?: IAnotherColors;
  }

  interface IBackgroundColors {
    avatarBg: string;
    backdrop: string;
    breadcrumbBg: string;
    darkGray: string;
    grey: string;
    lightGray: string;
    superLightGray: string;
    white: string;
  }

  interface IBlackAndWhiteColors {
    black: string;
    black60: string;
    black56: string;
    gray: string;
    black38: string;
    black30: string;
    black23: string;
    scroll: string;
    black12: string;
    grayLight: string;
    stroke: string;
    white: string;
  }

  interface IBlueColors {
    main: string;
    darkBlue: string;
    superDarkBlue: string;
    lightBlue: string;
    hoverBlue: string;
    superLightBlue: string;
    superLightBlue_2: string;
    lightBlue40: string;
  }

  interface IYellowColors {
    main: string;
    darkYellow: string;
    lightYellow: string;
    superLightYellow: string;
  }

  interface IRedColors {
    main: string;
    darkRed: string;
    lightRed: string;
    superLightRed: string;
  }

  interface IGreenColors {
    main: string;
    darkGreen: string;
    lightGreen: string;
    superLightGreen: string;
  }

  interface IAnotherColors {
    pink: string;
    lime: string;
    brown: string;
    orange: string;
    violet: string;
    lightViolet: string;
    tableItem: string;
  }

  interface TypographyOptions {
    p14Medium: TypographyStyleOptions;
    p16: TypographyStyleOptions;
  }

  // interface TypographyOptions {
  //   p14Medium: {
  //      fontFamily: string;
  //      fontSize: string;
  //      fontStyle: string;
  //      fontWeight: number;
  //      lineHeight: string;
  //      [key: string]: string | number | { fontSize: string };
  //   };
  //  }

  /**
   * @interface
   * возможные имена поля у цвета в кастомных цветах
   */
  interface PaletteColorOptions {
    main?: string;
    light?: string;
    dark?: string;
    contrastText?: string;

    // оттенки background
    avatarBg?: string;
    backdrop?: string;
    breadcrumbBg?: string;
    darkGray?: string;
    grey?: string;
    lightGray?: string;
    superLightGray?: string;
    white?: string;

    // оттенки белого и черного
    black?: string;
    black60?: string;
    black56?: string;
    gray?: string;
    black38?: string;
    black30?: string;
    black23?: string;
    scroll?: string;
    black12?: string;
    grayLight?: string;
    stroke?: string;

    // оттенки blue
    darkBlue?: string;
    superDarkBlue?: string;
    lightBlue?: string;
    hoverBlue?: string;
    superLightBlue?: string;
    superLightBlue_2?: string;

    // оттенки yellow
    darkYellow?: string;
    lightYellow?: string;
    superLightYellow?: string;

    // оттенки red
    darkRed?: string;
    lightRed?: string;
    superLightRed?: string;

    // оттенки red
    darkGreen?: string;
    lightGreen?: string;
    superLightGreen?: string;

    // оттенки another
    pink?: string;
    lime?: string;
    brown?: string;
    orange?: string;
    violet?: string;
    lightViolet?: string;
    tableItem?:string;
  }

  /**
   * @interface
   * расширение полей палитры
   */
  interface PaletteColor {
    // оттенки background
    avatarBg?: string;
    backdrop?: string;
    breadcrumbBg?: string;
    darkGray?: string;
    grey?: string;
    lightGray?: string;
    superLightGray?: string;
    white?: string;

    // оттенки белого и черного
    black30?: string;
    scroll?: string;
    stroke?: string;

    // оттенки blue
    darkBlue?: string;
    lightBlue?: string;
    hoverBlue?: string;
    superLightBlue?: string;
    superLightBlue_2?: string;

    // оттенки yellow
    darkYellow?: string;
    lightYellow?: string;
    superLightYellow?: string;

    // оттенки red
    darkRed?: string;
    lightRed?: string;
    superLightRed?: string;

    // оттенки red
    darkGreen?: string;
    lightGreen?: string;

    // оттенки another
    pink?: string;
    lime?: string;
    brown?: string;
    orange?: string;
    violet?: string;
    tableItem?: string;
  }

  interface TypographyVariants {
    p14Medium:  TypographyStyleOptions;
    p16:  TypographyStyleOptions;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    p14Medium?:  TypographyStyleOptions;
    p16?:  TypographyStyleOptions;
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    rg: true;
    lg: true;
    xl: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    blue: true;
    gray: true;
    red: true;
    black: true;
    green: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    blue: true;
    gray: true;
  }
}
declare module "@emotion/react" {
  export type ThemeEm = MUITheme;
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    p14Medium: true;
    p16:true;
  }
}

const theme = createTheme({
  spacing: 8,
  palette: {
    /**
     * переопределение базовых цветов палитры Mui
     */
    primary: {
      light: "#82DAC0",
      main: "#00A049",
      dark: "#86A099",
      contrastText: "#FFFFFF"
    },
    secondary: {
      light: "#F9FAFB",
      main: "#F3F7F8",
      dark: "#E5EEF0",
      contrastText: "#49454F"
    },
    error: {
      main: "#F92C38"
    },
    text: {
      primary: "#49454F",
      disabled: "#49454F"
    },
    action: {
      hover: "#EDEDFB"
    },
    /**
     * цвета из дизайна
     */
    backgroundColors: {
      avatarBg: "#A6D4BB",
      backdrop: "#00000080",
      breadcrumbBg: "#F5F5F5",
      darkGray: "#E6E6F4",
      grey: "#EDEDFB",
      lightGray: "#F6F6FD",
      superLightGray: "#FAFAFF",
      white: "#FFFFFF"
    },
    blackAndWhite: {
      black: "#25292C",
      black60: "#00000099",
      black56: "#0000008F",
      gray: "#8392A1",
      black38: "#00000061",
      black30: "#25292C4D",
      black23: "#0000003B",
      scroll: "#BDBDBD",
      black12: "#0000001F",
      grayLight: "#E3EAF2",
      stroke: "#D7D7E1",
      white: "#FFFFFF"
    },
    blue: {
      main: "#1872CF",
      darkBlue: "#1860AA",
      superDarkBlue: "#1310C4",
      lightBlue: "#2F8DED",
      hoverBlue: "#CDE6FF",
      superLightBlue: "#DAECFF",
      superLightBlue_2: "#E7F0FA",
      lightBlue40: "#2F8DED66"
    },
    yellow: {
      main: "#F7A70A",
      darkYellow: "#E89900",
      lightYellow: "#FFC146",
      superLightYellow: "#FCF0D9"
    },
    red: {
      main: "#F92C38",
      darkRed: "#E20613",
      lightRed: "#FF636C",
      superLightRed: "#F8E1E2"
    },
    green: {
      main: "#00A049",
      darkGreen: "#00713B",
      lightGreen: "#4BC281",
      superLightGreen: "#DEF8E9"
    },
    another: {
      pink: "#C10882",
      lime: "#5FC510",
      brown: "#B44B10",
      orange: "#FA7800",
      violet: "#942EE4",
      lightViolet: "#F0E4FF",
      tableItem: "#1860AA4D"
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: 300,
          [`&.${inputBaseClasses.fullWidth}`]: {
            width: "100%"
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "outlined" &&
            ownerState.color === "primary" && {
            "&:hover": {
              color: theme.palette.blackAndWhite.white,
              backgroundColor: theme.palette.primary.main
            }
          }),
          ...(ownerState.variant === "text" && {
            padding: 0,
            "&:hover": {
              color: theme.palette.primary.main,
              backgroundColor: "transparent"
            }
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
            "&:hover": {
              backgroundColor: theme.palette.primary.main
            }
          }),
          ...(ownerState.variant === "text" &&
            ownerState.color === "red" && {
            "&:hover": {
              color: theme.palette.red.main
            }
          }),
          ...(ownerState.variant === "outlined" && ownerState.color === "blue" && {
            borderColor: theme.palette.blue.lightBlue,
            color: theme.palette.blue.lightBlue,
            '&:hover': {
              backgroundColor: theme.palette.blue.hoverBlue,
              borderColor: theme.palette.blue.hoverBlue,
            },
          })
        }),
        outlinedSecondary: () => ({
          color: theme.palette.blue.main,
          backgroundColor: theme.palette.blue.superLightBlue,
          "&:hover": {
            backgroundColor: theme.palette.blue.hoverBlue
          }
        }),
        containedSecondary: () => ({
          color: theme.palette.blue.main,
          backgroundColor: theme.palette.blue.superLightBlue,
          "&:hover": {
            backgroundColor: theme.palette.blue.superLightBlue
          }
        })
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: () => ({
          color: theme.palette.blackAndWhite.gray,
          [`&.${checkboxClasses.checked}`]: {
            color: theme.palette.blue.lightBlue
          }
        })
      }
    },
    MuiRadio: {
      styleOverrides: {
        colorSecondary: () => ({
          color: theme.palette.blackAndWhite.gray,
          [`&.${radioClasses.checked}`]: {
            color: theme.palette.blue.lightBlue
          }
        })
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: () => ({
          fontSize: 16,
          fontWeight: 400,
          lineHeight: "24px",
          color: theme.palette.blackAndWhite.gray,
          [`&.${formLabelClasses.focused}`]: {
            //цвет лейбла по фокусу
            color: theme.palette.blackAndWhite.gray
          }
        })
      }
    },
    MuiLink: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.blue.lightBlue,
          textDecorationColor: theme.palette.blue.lightBlue40
        })
      }
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: () => ({
          fontSize: 14,
          fontWeight: 500,
          height: "28px",
          padding: "2px 4px"
        }),
        outlined: () => ({
          border: `1px solid ${theme.palette.blackAndWhite.stroke}`
        })
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: () => ({
          "& ul": {
            padding: theme.spacing(1, 0)
          }
        })
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.blackAndWhite.black,

          h1: {
            fontFamily: "Roboto",
            fontSize: "44px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            letterSpacing: "0.88px"
          },
          h2: {
            fontFamily: "Roboto",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "120%",
            letterSpacing: "0.88px"
          },
          h3: {
            fontFamily: "Roboto",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "120%",
            letterSpacing: "0.88px"
          },
          h4: {
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "120%",
            letterSpacing: "0.88px"
          },
          h5: {
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "120%",
            letterSpacing: "0.88px"
          },
          h6: {
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "14.4px",
            letterSpacing: "0.88px"
          },
          p16: {
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
          },
          p14Medium: {
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "16.8px"
          },
          body1: {
            fontSize: 16
          },
          caption: {
            fontSize: 14
          },

          fontFamily: ["Roboto", "sans-serif"].join(",")
        })
      }
    },
    //ЛОМАЕТ Timepicker
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: () => ({
    //       minWidth: "185px",
    //       gap: "12px",
    //       color: theme.palette.blackAndWhite.black,
    //       "& svg": {
    //         color: theme.palette.blackAndWhite.stroke
    //       }
    //     })
    //   }
    // },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#F9FAFB",
          width: "100%",
          borderRadius: "10px 10px 0 0"
        }
      }
    },
    // MuiTextField: {
    //   variants: [
    //     {
    //       props: (props) => props.variant === "empty",
    //       style: {
    //         border: "none"
    //       }
    //     }
    //   ],
    //   styleOverrides: {
    //     root: (props) => ({
    //       ...(props.variant === "empty" && {
    //         backgroundColor: "#F9FAFB",
    //         width: "100%",
    //         border: "none"
    //       })
    //     })
    //   }
    // },
    MuiPaper: {
      styleOverrides: {
        root: {
          ".MuiPickersDay-root.Mui-disabled": {
            opacity: 0.5
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: () => ({
          ".MuiInputLabel-root.Mui-disabled": {
            color: theme.palette.blackAndWhite.black30,
          }
        })
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: () => ({
          margin: 0,
          fontSize: "14px",
          [`.${checkboxClasses.root}.${checkboxClasses.checked}`]: {
            color: theme.palette.blue.lightBlue
          }
        })
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: () => ({
          borderRadius: "4px",
          display: "flex",
          gap: "12px",
          color: theme.palette.blackAndWhite.black
        })
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.blackAndWhite.black,
          minWidth: "24px"
        })
      }
    }
  },
  typography: {
    h1: {
      fontFamily: "Roboto",
      fontSize: "44px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      '@media screen and (max-width: 600px)': {
        fontSize: "34px",
      }
    },
    h2: {
      fontFamily: "Roboto",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "120%;",
      '@media screen and (max-width: 600px)': {
        fontSize: "18px",
      }
    },
    h3: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "120%",
      '@media screen and (max-width: 600px)': {
        fontSize: "16px",
      }
    },
    h4: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "120%",
      '@media screen and (max-width: 600px)': {
        fontSize: "14px",
      }
    },
    h5: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "120%",
      '@media screen and (max-width: 600px)': {
        fontSize: "12px",
      }
    },
    h6: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "14.4px",
      '@media screen and (max-width: 600px)': {
        fontSize: "10px",
      }
    },
    p16: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      '@media screen and (max-width: 600px)': {
        fontSize: "14px",
      }
    },
    p14Medium: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "16.8px",
      '@media screen and (max-width: 600px)': {
        fontSize: "12px",
      }
    },
    body1: {
      fontSize: 16,
      '@media screen and (max-width: 600px)': {
        fontSize: "14px",
      }
    },
    body2: {
      '@media screen and (max-width: 600px)': {
        fontSize: "12px",
      }
    },
    caption: {
      fontSize: 14,
      '@media screen and (max-width: 600px)': {
        fontSize: "12px",
      }
    },

    fontFamily: ["Roboto", "sans-serif"].join(",")
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      rg: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
