import React from "react";
import { Field, useFormikContext } from "formik";

import {
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FIELD_NAME, IProps, TForm } from "../../types";

const SubjectFormSecondStep: React.FC<IProps> = () => {
  const form = useFormikContext<TForm>();
  return (
    <Stack direction="column" gap={2}>
      <Typography variant="h3">Реквизиты счета</Typography>
      <Field name={FIELD_NAME.BANK}>
        {({ field, meta }) => (
          <FormControl>
            <TextField
              {...field}
              label="Банк"
              fullWidth
              size="small"
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={form.values[FIELD_NAME.BANK]}
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={FIELD_NAME.BIK}>
        {({ field, meta }) => (
          <FormControl>
            <TextField
              {...field}
              label="БИК"
              fullWidth
              size="small"
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={form.values[FIELD_NAME.BIK]}
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={FIELD_NAME.BANK_INN}>
        {({ field, meta }) => (
          <FormControl>
            <TextField
              {...field}
              label="ИНН"
              fullWidth
              size="small"
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={form.values[FIELD_NAME.BANK_INN]}
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={FIELD_NAME.BANK_KPP}>
        {({ field, meta }) => (
          <FormControl>
            <TextField
              {...field}
              label="КПП"
              fullWidth
              size="small"
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={form.values[FIELD_NAME.BANK_KPP]}
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={FIELD_NAME.CHECKING_ACCOUNT}>
        {({ field, meta }) => (
          <FormControl>
            <TextField
              {...field}
              label="Расчетный счет"
              fullWidth
              size="small"
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={
                form.values[FIELD_NAME.CHECKING_ACCOUNT]
              }
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={FIELD_NAME.CORRESPONDENT_ACCOUNT}>
        {({ field, meta }) => (
          <FormControl>
            <TextField
              {...field}
              label="Корреспондентский счет"
              fullWidth
              size="small"
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={
                form.values[
                  FIELD_NAME.CORRESPONDENT_ACCOUNT
                ]
              }
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
    </Stack>
  );
};

export default SubjectFormSecondStep;
