import React from "react";
import yup from "@/utils/yup";

import { IDistribution } from "@/api/interfaces/requests";
import {
  CollectionIcon,
  CustomIcon,
  FrostIcon,
  GmoIcon,
  HpIcon,
  OSIcon,
  PhytoIcon,
} from "@/components/icons";
import { IDistributionStatement } from "@/api/interfaces/responses";

import {
  DISTRIBUTION_ORGANOZATIONS,
  DISTRIBUTION_ORGANOZATIONS_LABEL,
  DISTRIBUTION_TYPE,
  DISTRIBUTION_TYPE_LABEL,
} from "@/const";

import theme from "@/theme";

import {
  convertDateForForm,
  convertStringToDate,
  formatDateForDistribution,
} from "./utils";
import { isNull, isUndefined } from "@/utils/helpers";

export interface IProps {
  modalProps: {
    key: string;
    initialValue?: IDistributionStatement;
    idStatement: number;
    dateCulture: string;
    onSubmit?: (distribution: IDistribution) => void;
    submitOnMount?: boolean;
  };
}

// Данные для селекта с выбором типа
export const DISTRIBUTION_TYPES_ARRAY = [
  {
    name: DISTRIBUTION_TYPE.HP,
    label: DISTRIBUTION_TYPE_LABEL.HP,
    icon: <HpIcon sx={{ width: "20px", height: "20px" }} />,
    textColor: theme.palette.green.main,
  },
  {
    name: DISTRIBUTION_TYPE.OOS,
    label: DISTRIBUTION_TYPE_LABEL.OOS,
    icon: <OSIcon sx={{ width: "20px", height: "20px" }} />,
    textColor: theme.palette.another.orange,
  },
  {
    name: DISTRIBUTION_TYPE.PHYTO,
    label: DISTRIBUTION_TYPE_LABEL.PHYTO,
    icon: (
      <PhytoIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.red.main,
  },
  {
    name: DISTRIBUTION_TYPE.FROST,
    label: DISTRIBUTION_TYPE_LABEL.FROST,
    icon: (
      <FrostIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.blue.main,
  },
  {
    name: DISTRIBUTION_TYPE.COLLECTION,
    label: DISTRIBUTION_TYPE_LABEL.COLLECTION,
    icon: (
      <CollectionIcon
        sx={{ width: "20px", height: "20px" }}
      />
    ),
    textColor: theme.palette.yellow.main,
  },
  {
    name: DISTRIBUTION_TYPE.CUSTOMS,
    label: DISTRIBUTION_TYPE_LABEL.CUSTOMS,
    icon: (
      <CustomIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.blue.superDarkBlue,
  },
  {
    name: DISTRIBUTION_TYPE.GMO,
    label: DISTRIBUTION_TYPE_LABEL.GMO,
    icon: (
      <GmoIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.another.violet,
  },
] as const;

// Варианты радио кнопок для разнарядки (в организации или в гсу)
export const DISTRIBUTION_ORGANIZATION_ARRAY = [
  {
    type: DISTRIBUTION_ORGANOZATIONS.plot,
    label: DISTRIBUTION_ORGANOZATIONS_LABEL.plot,
  },
  {
    type: DISTRIBUTION_ORGANOZATIONS.organization,
    label: DISTRIBUTION_ORGANOZATIONS_LABEL.organization,
  },
];

// поля в форме создания/редактирования разнарядки
export enum DISTRIBUTION_FIELD_NAME {
  TYPE = "type", // Тип разнарядки
  PLOT = "plot", // Наименование ГСУ
  ORGANIZATION = "organization", // Наименование ГСУ
  ADDRESS = "address", // Адрес ГСУ
  MATERIAL_TYPE = "material_type", // Тип материала
  QUANTITY = "quantity", // Количество
  UNIT = "unit", // Единицы измерения
  STATEMENT = "statement", // Заявка
  DATE = "delivery_time_up_to", // Срок поставки
  BRANCH = "branch", // Филиал
  ORGANIZATION_OR_PLOT = "organization_or_plot", // Вариант формы (для ГСУ или организации)
}
const ERROR_MESSANGES = {
  required: "Обязательное поле",
};

const isPlot = (() => {
  return {
    field: [DISTRIBUTION_FIELD_NAME.ORGANIZATION_OR_PLOT],
    sheme: {
      is: (data) => {
        if (data === DISTRIBUTION_ORGANOZATIONS.plot)
          return true;
        return false;
      },
      then: (schema) =>
        schema.required(ERROR_MESSANGES.required),
      otherwise: (schema) => schema.nullable(),
    },
  };
})();

const isOrganization = (() => {
  return {
    field: [DISTRIBUTION_FIELD_NAME.ORGANIZATION_OR_PLOT],
    sheme: {
      is: (data) => {
        if (
          data === DISTRIBUTION_ORGANOZATIONS.organization
        )
          return true;
        return false;
      },
      then: (schema) =>
        schema.required(ERROR_MESSANGES.required),
      otherwise: (schema) => schema.nullable(),
    },
  };
})();

const quantityMin = 1;
const quantityMax = 999999999;
const textValidationMinMax = `Значение поля должно быть в диапазоне от ${quantityMin} до ${quantityMax}`;

// схема валидации разнарядки
export const validationSchema = yup.object().shape({
  [DISTRIBUTION_FIELD_NAME.TYPE]: yup.string().required(),
  [DISTRIBUTION_FIELD_NAME.PLOT]: yup
    .mixed()
    .when(isPlot.field, isPlot.sheme),
  [DISTRIBUTION_FIELD_NAME.ORGANIZATION]: yup
    .mixed()
    .when(isOrganization.field, isOrganization.sheme),
  [DISTRIBUTION_FIELD_NAME.ADDRESS]: yup.mixed().required(),
  [DISTRIBUTION_FIELD_NAME.MATERIAL_TYPE]: yup
    .mixed()
    .required(),
  [DISTRIBUTION_FIELD_NAME.QUANTITY]: yup
    .number()
    .min(quantityMin, textValidationMinMax)
    .max(quantityMax, textValidationMinMax)
    .required(),
  [DISTRIBUTION_FIELD_NAME.UNIT]: yup.mixed().required(),
  [DISTRIBUTION_FIELD_NAME.DATE]: yup
    .string()
    .trim()
    .required(),
});

// поля необходимые для создания разнарядки
export type TDistributionFields = Pick<
  IDistribution,
  | "address"
  | "quantity"
  | "unit"
  | "material_type"
  | "branch"
  | "statement"
  | "plot"
  | "type"
  | "organization"
  | "delivery_time_up_to"
>;

export interface TForm extends TDistributionFields {
  organization_or_plot: string;
  material_type: any;
  plot: any;
}

const convertDefaultValues = (item, dateCulture) => {
  if (item === undefined)
    return {
      [DISTRIBUTION_FIELD_NAME.DATE]: dateCulture
        ? formatDateForDistribution(dateCulture)
        : null,
      [DISTRIBUTION_FIELD_NAME.ORGANIZATION_OR_PLOT]:
        DISTRIBUTION_ORGANOZATIONS.plot,
    };
  return {
    ...item,
    [DISTRIBUTION_FIELD_NAME.PLOT]:
      !isUndefined(item?.plot) && !isNull(item?.plot)
        ? {
            ...item?.plot,
            label: item?.plot?.name,
          }
        : null,
    [DISTRIBUTION_FIELD_NAME.ORGANIZATION]:
      !isUndefined(item?.organization) &&
      !isNull(item?.organization)
        ? {
            ...item?.organization,
            label: item?.organization?.name,
          }
        : null,
    [DISTRIBUTION_FIELD_NAME.MATERIAL_TYPE]:
      item?.material_type
        ? {
            ...item?.material_type,
            label: item?.material_type?.name,
          }
        : null,
    [DISTRIBUTION_FIELD_NAME.UNIT]: item?.unit
      ? {
          ...item?.unit,
          label: item?.unit?.name,
        }
      : null,
    [DISTRIBUTION_FIELD_NAME.DATE]: !isUndefined(
      item?.delivery_time_up_to,
    )
      ? convertStringToDate(item?.delivery_time_up_to)
      : formatDateForDistribution(dateCulture),
    [DISTRIBUTION_FIELD_NAME.ORGANIZATION_OR_PLOT]:
      !isUndefined(item) && !isNull(item?.plot)
        ? DISTRIBUTION_ORGANOZATIONS.plot
        : !isUndefined(item) && !isNull(item?.organization)
        ? DISTRIBUTION_ORGANOZATIONS.organization
        : DISTRIBUTION_ORGANOZATIONS.plot,
  };
};

// установка начальных значений в форму
export const mapValueToForm = (
  defaultValues,
  dateCulture,
) => {
  const convertInitValue = convertDefaultValues(
    defaultValues,
    dateCulture,
  );
  return Object.assign(
    {
      [DISTRIBUTION_FIELD_NAME.TYPE]: null,
      [DISTRIBUTION_FIELD_NAME.BRANCH]: null,
      [DISTRIBUTION_FIELD_NAME.PLOT]: null,
      [DISTRIBUTION_FIELD_NAME.ORGANIZATION]: null,
      [DISTRIBUTION_FIELD_NAME.ADDRESS]: null,
      [DISTRIBUTION_FIELD_NAME.MATERIAL_TYPE]: null,
      [DISTRIBUTION_FIELD_NAME.QUANTITY]: null,
      [DISTRIBUTION_FIELD_NAME.UNIT]: null,
      [DISTRIBUTION_FIELD_NAME.DATE]: null,
      [DISTRIBUTION_FIELD_NAME.ORGANIZATION_OR_PLOT]: null,
    },
    convertInitValue,
  );
};

// приведение данных формы к необходимым к отправке
export const mapFormToValue = (
  values,
  idStatement,
): Partial<
  Omit<TForm, "organization_or_plot" | "branch">
> => {
  const {
    address,
    delivery_time_up_to,
    material_type,
    plot,
    quantity,
    type,
    unit,
    organization_or_plot,
    organization,
  } = values;

  const convertedDate =
    convertDateForForm(delivery_time_up_to) ?? "";

  return {
    [DISTRIBUTION_FIELD_NAME.TYPE]: type,
    [DISTRIBUTION_FIELD_NAME.ADDRESS]:
      address?.address?.id ?? address?.id,
    [DISTRIBUTION_FIELD_NAME.MATERIAL_TYPE]:
      material_type.id,
    [DISTRIBUTION_FIELD_NAME.QUANTITY]: quantity,
    [DISTRIBUTION_FIELD_NAME.UNIT]: unit.id,
    [DISTRIBUTION_FIELD_NAME.DATE]: convertedDate,
    [DISTRIBUTION_FIELD_NAME.STATEMENT]: idStatement,
    [DISTRIBUTION_FIELD_NAME.PLOT]:
      organization_or_plot ===
      DISTRIBUTION_ORGANOZATIONS.plot
        ? plot.id
        : undefined,
    [DISTRIBUTION_FIELD_NAME.ORGANIZATION]:
      organization_or_plot ===
      DISTRIBUTION_ORGANOZATIONS.organization
        ? organization.id
        : undefined,
  };
};
