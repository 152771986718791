import React from "react";
import GeneralRouter from "./router";
import "./styles/index.scss";

// eslint-disable-next-line
import "react-image-crop/dist/ReactCrop.css";

// eslint-disable-next-line
import "react-alice-carousel/lib/alice-carousel.css";

// eslint-disable-next-line
import "leaflet/dist/leaflet.css";

const App: React.FC<{
  context?: any;
}> = () => {
  return <GeneralRouter />;
};

export default App;
