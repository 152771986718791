import { styled } from "@mui/system";
import {
  avatarClasses,
  Box,
  circularProgressClasses,
  List as MuiList,
  listClasses,
  ListItem as MuiListItem,
  listItemAvatarClasses,
  listItemClasses,
  ListItemProps,
  listItemTextClasses
} from "@mui/material";

interface IListItem extends ListItemProps {
  error: boolean;
}

export const UploadContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: 4,
  border: `1px dashed ${theme.palette.blackAndWhite.stroke}`,
  cursor: 'pointer'
}));

export const List = styled(MuiList)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  [`&.${listClasses.root}`]: {
    marginBottom: theme.spacing(1)
  },
}));

export const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== "error",
})<IListItem>(({ theme, error }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
  borderRadius: 4,
  [`&.${listItemClasses.root} > .${listItemTextClasses.root}`]: {
    width: "100%",
  },
  [`&.${listItemClasses.root} > .${listItemAvatarClasses.root}`]: {
    color: theme.palette.blue.lightBlue
  },
  [`&.${listItemClasses.root} > .${listItemAvatarClasses.root} > .${avatarClasses.root}`]: {
    backgroundColor: theme.palette.blue.hoverBlue
  },
  [`&.${listItemClasses.root} > .${circularProgressClasses.root}`]: {
    color: theme.palette.blue.main
  },
  [`&.${listItemClasses.root} > .${listItemTextClasses.root} > .${listItemTextClasses.secondary}`]: {
    color: theme.palette.blackAndWhite.black60
  },
  [`&.${listItemClasses.root} > .${listItemTextClasses.root} > .${listItemTextClasses.primary}`]: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  ...(error && {
    [`&.${listItemClasses.root} > .${listItemAvatarClasses.root}`]: {
      color: theme.palette.red.darkRed
    },
    [`&.${listItemClasses.root} > .${listItemTextClasses.root} > .${listItemTextClasses.secondary}`]: {
      color: theme.palette.red.darkRed
    },
    [`&.${listItemClasses.root} > .${listItemTextClasses.root} > .${listItemTextClasses.primary}`]: {
      color: theme.palette.red.darkRed
    }
  }),
}));