import yup from "@/utils/yup";
import {
  IVegetationCause
} from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  CULTIVAR: "cultivar",
  YEAR: "year",
} as const;

export interface IFormik {
  [FIELDS_NAME.CULTIVAR]: IVegetationCause | null,
  [FIELDS_NAME.YEAR]: number | null,
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
  [FIELDS_NAME.YEAR]: yup.number().required()
});

export const initialValues = {
  [FIELDS_NAME.CULTIVAR]: null,
  [FIELDS_NAME.YEAR]: null,
};