import { ListItem, ListItemText } from "@mui/material";
import theme from "@/theme";
import styled from "@emotion/styled";

interface IPropsListItemText {
  active: string;
}

export const ListItemTextStyled = styled(ListItemText)<IPropsListItemText>(
  ({ active }) => ({
    width: "100%",
    margin: '2px 0',
    padding: "8px 20px",
    verticalAlign: "center",
    gap: "10px",
    backgroundColor: active ? theme.palette.backgroundColors.lightGray : "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
    ":hover": {
      backgroundColor: theme.palette.backgroundColors.superLightGray
    },
    p: active
      ? {
          fontSize: "14px",
          fontWeight: "600",
          color: theme.palette.blue.main,
          '@media screen and (max-width: 600px)': {
            fontSize: "16px",
          }
        }
      : {
          fontSize: "14px",
          fontWeight: "400",
          color: theme.palette.blackAndWhite.gray,
          '@media screen and (max-width: 600px)': {
            fontSize: "16px",
            fontWeight: "500",
          }
        }
  })
);

export const ListItemStyled = styled(ListItem)(() => ({
  padding: 0
}));
