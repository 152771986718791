export const AW_PLOTS_KEYS = {
  gsu: "plot__name",
  region: "plot__branch__region__name",
  branch: "plot__branch__name",
  editing: "editing",
  name: "name"
} as const;

export const AW_FIELDS_KEYS = {
  purpose: "purpose",
  name: "name",
  gsu: "gsu",
  cultivar: "cultivar",
  year: "year",
  editing: "editing",
} as const;

export const BRANCH_KEY = "getBranchInfinite";

export const AWPLOTS_HEADER_LABELS = {
  gsu: "ГСУ",
  region: "Регион",
  branch: "Филиал",
  name: "Название участка"
};

export const EMPTY_LIST_AWPLOTS_TEXT = "Еще не создано ни одного участка";
export const EMPTY_LIST_AWPLOTS_TEXT_WITH_FILTERS =
  "По указанным параметрам участки не найдены";
export const ADD_AWPLOTS_TEXT = "Добавить участок";

export const AWPLOTS_SIDEBAR_TABS_NAMES = {
  plan: "plan",
  production: "production",
  costs: "costs",
  investment: "investment",
  finance: "finance",
  efficiency: "efficiency",
};

export const EDITING_CELL = {
  showOnMap: "На карте",
  delete: "Удалить",
  editing: "Редактировать"
}

export const REGION_KEY = "getRegionInfinnite"