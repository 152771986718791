import yup from "@/utils/yup";

import { reqInterface, resInterface } from "@/api/interfaces";
import { convertStringToDate } from "../AddDistribution/utils";
import dayjs from "dayjs";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    orderId?: number;
    initialValues?: resInterface.IOrder;
  };
  onSubmit?: () => void;
}

export type TForm = reqInterface.IOrder & {
  files: File[];
};

export enum FIELD_NAME {
  NAME = "name",
  NUMBER = "number",
  ORDER_DATE = "order_date",
  ORDER_TYPE = "order_type",
  FILES = "files",
}

export const validationSchema = yup.object().shape({
  [FIELD_NAME.NAME]: yup
    .string()
    .trim()
    .matches(/^(?! *$).+$/, "Поле не может состоять только из пробелов")
    .required(),
  [FIELD_NAME.NUMBER]: yup.number().required(),
  [FIELD_NAME.ORDER_DATE]: yup.mixed().required(),
});

export const HANDBOOK_ORDER_TYPE = {
  cultivar: "cultivar",
  hp_grouping: "hp_grouping",
};

export const mapValueToForm = (value) => {
  return {
    ...value,
    [FIELD_NAME.ORDER_DATE]: value.order_date
      ? convertStringToDate(value.order_date)
      : undefined,
    [FIELD_NAME.FILES]: value.files?.map((i) => ({
      ...i,
      name: i.file_name,
      formatSize: i.file_size,
    })),
  };
};

export const mapFormToValues = (value) => ({
  [FIELD_NAME.NAME]: value.name,
  [FIELD_NAME.NUMBER]: value.number,
  [FIELD_NAME.ORDER_DATE]: dayjs(value.order_date).local().format("YYYY-MM-DD"),
  [FIELD_NAME.ORDER_TYPE]: value.order_type,
  is_active: value.is_active,
});
