export const FIELD_NAME = {
  COM_PLAN_FACT: 'com_plan_fact',
  YEAR: 'year',
  REVENUE: 'revenue',
  DIRECT_EXPENSES: 'direct_expenses',
  PRODUCTION_EXPENSES: 'production_expenses',
  ADMINISTRATIVE_EXPENSES: 'administrative_expenses',
  TAXES: 'taxes',
  CAPITAL_INVESTMENTS: 'capital_investments',
} as const;

export const VALIDATION_MESSAGE_NUMBER = {
  regEx: /^(\d+(\.\d{1,2})?)$/,
  text: 'Должно быть не более двух знаков после запятой'
} as const;
