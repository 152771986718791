import React from "react";
import { Formik, FormikHelpers} from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { IProps, TForm, mapFormToValue, mapValueToForm, validationSchema } from "./const";
import { SWR_KEYS } from "@/const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import DepartmentsForm from "./components/DepartmentsForm";

const Department: React.FC<IProps> = ({ handleClose, modalProps }) => {
    const baseInititialValues: TForm = Object.assign(
        {
            name: null,
            department_parent: null,
            staff_director: null,
            emails: [{ id: 0, email: '' }],
            phones: [{ id: 0, phone: '', name: '' }],
        },
        modalProps.initialValues,
    );

    const { departmentId } = modalProps;
    const { api, toastStore, swrStore } = useStores();

    const mutateDetail = departmentId ? swrStore.mutators[SWR_KEYS.getDepartmentById(departmentId)] :  null

    const handleSubmit = (
        values: TForm,
        helpers: FormikHelpers<FormikValues>,
      ) => {
        helpers.setSubmitting(true);
    
        const promise = departmentId
          ? api.regulatoryInfo.patchDepartmentById(
            departmentId,
            mapFormToValue(values),
          )
          : api.regulatoryInfo.postDepartment(mapFormToValue(values));
    
    
        promise
          .then(() => {
            mutateDetail && mutateDetail()
            handleClose();
          })
          .catch((error: AxiosError) => {
            const errorMessage = errorToString(error);
            toastStore.createToast({
              type: TOAST_TYPES.ALERT,
              toastProps: {
                message: errorMessage,
                severity: "error",
              },
            });
          })
          .finally(() => {
            helpers.setSubmitting(false);
            mutateDetail && mutateDetail()
            swrStore.mutators[SWR_KEYS.getDepartmentList()]();
          });
      };


        const values = modalProps.initialValues
        ? mapValueToForm(
          baseInititialValues,
          modalProps.initialValues,
        )
        : baseInititialValues;

    return (
        <Formik
            initialValues={values}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
            component={() => (
                <DepartmentsForm modalProps={modalProps} handleClose={handleClose}/>
            )}
        />
    )
}

export default Department