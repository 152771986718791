import React from "react";
import { FormikConfig, FormikValues } from "formik";

export interface IFormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label?:string;
}

/**
 * Компонент обертка шага формы
 * @param validationSchema - схема для валидации шага
 * */
const FormikStep = ({ children }: IFormikStepProps)  => {
  return <>{children}</>;
}

export default FormikStep