import React from "react";
import AddStatement from "@/pages/AddStatement";

/** Страница редактирования заявки
 *  */

const EditStatementPage = () => {
  return (
    <AddStatement
      headerTitle="Редактирование заявки"
    />
  );
};

export default EditStatementPage;