import React from "react";
import { Formik, FormikHelpers, FormikValues } from "formik";
import { AxiosError } from "axios";

import {
  mapFormToValue,
  mapValueToForm,
  TForm,
  validationSchema,
} from "./const";
import CreateFactForm from "./CreateFactForm";
import { ROUTES } from "@/apps/AppRouter/const";
import { useNavigate } from "react-router-dom";
import { useStores } from "@/hooks";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { IPlanForm } from "@/pages/AddPlan/const";

interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    initialValue?: Partial<IPlanForm>;
    onSubmit?: () => void;
  };
}

/**
 * Компонент формы создания/редактирования факта
 * @param modalProps - пропсы формы
 * @returns
 */
const AddFact: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const { api, toastStore } = useStores();
  const { initialValue, onSubmit } = modalProps;
  const navigate = useNavigate();

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>
  ) => {
    helpers.setSubmitting(true);
    const serializedData = mapFormToValue(values);
    const promise = api.offBudget.addOffBudgetFact(serializedData);

    promise
      .then((res) => {
        onSubmit && onSubmit();
        navigate(`${res.com_plan_fact}/${ROUTES.editFact}/`);
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        handleClose();
        helpers.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={mapValueToForm(initialValue)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      component={() => <CreateFactForm modalProps={modalProps} />}
    />
  );
};

export default AddFact;
