import React, { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import useRightSidebar from "@/hooks/useRightSidebar";
import { CulturesItem } from "@/apps/RightSidebar/templates/OffBudget/firstLevel/components/Culture/CulturesItem";
import { TMenuProp } from "@/apps/RightSidebar/templates/OffBudget/firstLevel/interfaces";
import CollapseDefault from "@/apps/RightSidebar/templates/OffBudget/firstLevel/components/Culture/CollapseDefault";
import { useStores } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { observer } from "mobx-react";
import { SWR_KEYS } from "@/const";
import theme from "@/theme";
import { ScienceOutlined } from "@mui/icons-material";
import { translatedStatus } from "@/apps/RightSidebar/const";
import { OFFBUDGET_STATUS_NAMES } from "@/pages/OffBudget/const";
import useSWR from "swr";

export const CulturesContainer: React.FC<TMenuProp> =
  observer(({ renderData }) => {
    const { isCollapse } = useRightSidebar();
    const { api } = useStores();
    const { modalStore, swrStore } = useStores();
    const swrKey = SWR_KEYS.getOffBudgetCultivars();
    const planId = renderData?.id;
    const status = renderData?.status;
    const branch = renderData?.branch.id;
    const { approved, rejected, agreed } =
      OFFBUDGET_STATUS_NAMES;

    const isHidden =
      status == approved ||
      status === rejected ||
      status === agreed;

    const statusWarningText = `Статус ${translatedStatus[status as string]
      }: План в статусе
      ${translatedStatus[status as string]
      }, изменения невозможны.`;

    const {
      data: groupData,
      error,
      isLoading,
      mutate,
    } = useSWR(
      {
        key: SWR_KEYS.getOffBudgetCultivarsGroupPlotLocation(planId),
        com_plan_fact: planId,
      },
      api.offBudget.getOffBudgetCultivarsGroupPlotLocation,
    );

    const onSubmitCreation = () => {
      mutate();
    };

    useEffect(() => {
      swrStore.addMutator(swrKey, mutate);
    }, []);

    const handleAddCulture = () => {
      modalStore.open(
        VARIANT_MODAL.ADD_OFFBUDGET_CULTIVAR,
        {
          planId,
          branch,
          onSubmit: onSubmitCreation,
        },
      );
    };

    if (isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress size={80} />
        </Box>
      );
    }

    if (error) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          Произошла ошибка...
        </Box>
      );
    }

    if (!groupData?.length && isHidden) {
      return (
        <Stack
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "100%" }}
        >
          {statusWarningText}
        </Stack>
      );
    }

    return (
      <>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={1}
          sx={{
            zIndex: 2,
            paddingRight: !isCollapse ? "3px" : "3px",
          }}
        >
          <Typography
            variant="h4"
            ml={3}
            mt={isHidden ? 1 : 0}
          >
            Культуры
          </Typography>
          {isHidden ? null : (
            <Stack mt={1} mr={4} direction={"row"} gap={1}>
              <Button
                sx={{
                  width: "40px",
                  height: "40px",
                  minWidth: "auto",
                  padding: 0,
                }}
                color={"blue"}
                size={"medium"}
                variant={"outlined"}
                onClick={handleAddCulture}
              >
                <AddOutlinedIcon />
              </Button>
            </Stack>
          )}
        </Stack>
        <Box
          p={1}
          pl={3}
          pr={4}
          sx={{
            height: "calc(100% - 120px)",
            overflowY: "scroll",
            width: "100%",
            maxHeight: "500px",
          }}
        >
          <Box pt={2}>
            {groupData?.map((item) => (
              <CollapseDefault
                key={item.plot.id}
                label={item?.plot?.name}
                count={item?.locations?.length}
              >
                {item?.locations?.map((location) => (
                  <CollapseDefault
                    key={location.location.id}
                    label={location.location.name}
                    count={location?.com_cultivars?.length}
                  >
                    {location?.com_cultivars?.map((com_cultivar) => (
                      <CulturesItem
                        key={com_cultivar.id}
                        planId={planId}
                        mutate={mutate}
                        renderData={com_cultivar}
                        locationID={location.location.id}
                        isHidden={isHidden}
                        isRenderInTheForm={true}
                      />
                    ))}
                  </CollapseDefault>
                ))}
              </CollapseDefault>
            ))}
          </Box>

          {groupData?.length === 0 && (
            <Stack
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ height: "100%" }}
            >
              <ScienceOutlined
                sx={{
                  fontSize: 60,
                  color: theme.palette.blackAndWhite.stroke,
                }}
              />
              <Typography variant="h3">
                Заполните данные по культурам
              </Typography>
              <Button
                sx={{
                  marginLeft: "24px",
                  marginTop: "24px",
                }}
                color={"blue"}
                size={"large"}
                startIcon={<AddOutlinedIcon />}
                variant={"outlined"}
                onClick={handleAddCulture}
              >
                Добавить культуру
              </Button>
            </Stack>
          )}

          {groupData?.length && !isHidden ? (
            <Button
              sx={{
                marginTop: "24px",
                width: "100%",
              }}
              color={"blue"}
              size={"large"}
              startIcon={<AddOutlinedIcon />}
              variant={"outlined"}
              onClick={handleAddCulture}
            >
              Добавить культуру
            </Button>
          ) : null}
        </Box>
      </>
    );
  });

