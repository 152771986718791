import * as yup from "yup";
import { FieldErrors, FieldValues } from "react-hook-form";
import { isValidPhoneNumber } from "libphonenumber-js/max";

import { inputUnmaskNumber } from "@/utils/masks";

import { isEmpty } from "./helpers";

const notTypeHash = {
  number: "Введите число"
};

yup.setLocale({
  mixed: {
    default: "Значение введено неверно",
    required: "Это поле необходимо заполнить",
    oneOf: "Необходимо ввести одно из следующих значений: ${values}",
    notOneOf: "Возможно ввести любые значения, кроме: ${values}",
    notType: ({ type }) => notTypeHash[type] || `Введите значение типа ${type}`
  },
  string: {
    length: "Введите точно ${length} символов",
    min: "Введите минимум ${min} символов",
    max: "Введите не более ${max} символов",
    matches: '${path} must match the following: "${regex}"',
    email: "Введите корректный адрес электронной почты",
    url: "Введите корректный URL"
  },
  number: {
    min: "Введите число большее или равное ${min}",
    max: "Введите число меньше или равное ${max}",
    positive: "Введите положительное число",
    negative: "Введите отрицательное число",
    integer: "Введите целое число"
  },
  array: {},
  date: {
    min: "Введите дату позднее чем ${min}",
    max: "Введите дату раньше чем ${max}"
  }
});

export const timestampSchema = yup.date().transform(function (value, original) {
  const date = new Date(original);
  if (this.isType(date)) {
    return date;
  }

  return value;
});

export const passwordSchema = yup
  .string()
  .test("isMatch", "Пароли должны совпадать", function (value) {
    return value === this.parent.password;
  });

export const mobilePhoneSchema = yup
  .string()
  .test(
    "isMatch",
    "Введите корректный номер мобильного телефона +7(9__) ___-__-__",
    function (value) {
      if (!value) {
        return true;
      }
      const formattedPhone = inputUnmaskNumber(value);
      return (
        formattedPhone.startsWith("+79") && isValidPhoneNumber(value, "RU")
      );
    }
  );

export const shortPhoneSchema = yup
  .string()
  .test(
    "isMatch",
    "Введите корректный номер телефона +7(___) ___-__-__",
    function (value) {
      if (!value) {
        return true;
      }
      return isValidPhoneNumber(value, "RU");
    }
  );

export const addressSchema = yup.object().shape({
  region: yup.mixed().required(),
  area: yup.mixed(),
  city: yup.mixed().test({
    test: function (city) {
      const { settlement } = this.parent;
      return city || settlement;
    },
    message: "Это поле необходимо заполнить"
  }),
  settlement: yup.mixed(),
  street: yup.mixed(),
  house: yup.mixed(),
  comment: yup.string().max(255)
});

export const addressContractSchema = yup.object().shape({
  region: yup.mixed().required(),
  area: yup.mixed(),
  city: yup.mixed()
});

export const scheduleItemSchema = yup.object().shape({
  from: yup
    .number()
    .nullable()
    .test({
      test: function (from) {
        return this.parent.to === null || from !== null;
      },
      message: "Должно быть указано время начала"
    }),
  to: yup
    .number()
    .nullable()
    .test({
      test: function (to) {
        return this.parent.from === null || to !== null;
      },
      message: "Должно быть указано время окончания работы"
    })
});

export const widgetAddressSchema = yup.object().shape({
  region: yup.mixed().required(),
  area: yup.mixed().test({
    test: function () {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { region, ...value } = this.parent;
      return isEmpty(value);
    },
    message: "Это поле необходимо заполнить"
  }),
  city: yup.mixed(),
  settlement: yup.mixed(),
  street: yup.mixed(),
  house: yup.mixed()
});

export const imageSchema = yup.object().shape({
  source: yup.string().required(),
  description: yup.string().trim(),
  author: yup.string().required(),
  file: yup.mixed(),
  averageColor: yup.string(),
  path: yup.string().trim()
});

export const contentSchema = yup.array().required();

export const seoSchema = yup.object().shape({
  title: yup.string().max(256),
  description: yup.string().trim().max(256)
});

export const validateDomain = yup.string().url().required();

export const getCollapseTabsWithError = <T extends FieldValues>(
  errors: FieldErrors<T>,
  collapseFieldsErrors: Record<string, string>
) =>
  Object.keys(errors).reduce<string[]>((acc, item) => {
    if (collapseFieldsErrors[item]) {
      acc.push(collapseFieldsErrors[item]);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

export const applicationsMaterialsFileSchema = yup.object().shape({
  type: yup.string().required(),
  file: yup.object().shape({
    path: yup.string().required("Загрузите файл"),
    displayName: yup.string().required()
  })
});

export const applicationsMaterialsUrlSchema = yup.object().shape({
  type: yup.string().required(),
  url: yup.object().shape({
    url: yup.string().url().required(),
    displayName: yup.string().required()
  })
});

export const applicationsMaterialsSchema = yup.array().of(
  yup.lazy((material) => {
    if (material.type === "file") {
      return applicationsMaterialsFileSchema.required();
    } else {
      return applicationsMaterialsUrlSchema;
    }
  }) as any
);

export const additionalPhoneNumberSchema = yup.string().test({
  test: function (additionalValue) {
    if (additionalValue) {
      return additionalValue.length >= 3 && additionalValue.length <= 4;
    }

    return true;
  },
  message: "Введите от 3-x до 4-x символов"
});

/** Проверка на то, что поле содержит только пробелы **/
export const notRequiredStringValidationSchema = yup
  .string()
  .notRequired()
  .test("notEmpty", "Это поле необходимо заполнить", (value) => {
    return !(value && /^\s*$/.test(value));
  });

export const seoValidationSchema = yup.object().shape({
  metaTitle: notRequiredStringValidationSchema.max(100),
  metaDescription: notRequiredStringValidationSchema.max(150),
  ogTitle: notRequiredStringValidationSchema.max(100),
  ogDescription: notRequiredStringValidationSchema.max(150),
  metaKeywords: notRequiredStringValidationSchema.max(150)
});

export const preferencesValidationSchema = yup.array().of(
  yup.object().shape({
    icon: yup.mixed().required("Загрузите изображение"),
    title: yup
      .string()
      .trim()
      .min(1, "Введите от 1 до 40 символов")
      .max(40, "Введите от 1 до 40 символов")
      .required(),
    description: yup
      .string()
      .trim()
      .min(1, "Введите от 1 до 160 символов")
      .max(160, "Введите от 1 до 160 символов")
      .required(),
    alt: yup.string().trim().max(160, "Введите не более 160 символов")
  })
);

export default yup;
