import React from "react";
import { Typography, ListItem } from "@mui/material";
import theme from "@/theme";
import { stringWithSuffix } from "@/utils/helpers";
import { IEfficiencyItem } from "@/pages/AddPlan/const";

const EffectivityItem: React.FC<IEfficiencyItem> = ({ year, label, value }) => (
  <ListItem
    sx={{
      gap: 1,
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "fit-content",
      padding: "12px 15px",
      borderBottom: "1px solid black",
      borderColor: theme.palette.blackAndWhite.stroke,
    }}
  >
    <Typography
      width="10%"
      variant="body2"
      color={theme.palette.blackAndWhite.gray}
    >
      {year}
    </Typography>
    <Typography width="75%" variant="body2">
      {label}
    </Typography>
    <Typography
      width="15%"
      textAlign="end"
      variant="body2"
      color={theme.palette.blue.main}
    >
      {stringWithSuffix(value, "руб")}
    </Typography>
  </ListItem>
);

export default EffectivityItem;
