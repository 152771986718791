import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import DownloadPlanningForm
  from "./components/DownloadPlanningForm";
import {
  IFormik,
  initialValues,
  validationSchema,
} from "./const";
import { useStores } from "@/hooks";
import { TOAST_TYPES } from "@/apps/Toast";
import {
  errorToString,
  downloadFile
} from "@/utils/helpers";
import { DOWNLOAD_TRIALS_PLAN_HP_TYPES } from "@/const";
import { ValueOf } from "@/utils/types";

/**
 * Компонент модалки реализующий скачивание сводного плана по испытаниям
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    type: ValueOf<typeof DOWNLOAD_TRIALS_PLAN_HP_TYPES>
  };
}

const fileName = {
  [DOWNLOAD_TRIALS_PLAN_HP_TYPES.BRANCH] : "Сводный план по испытаниям на ХП по филиалам",
  [DOWNLOAD_TRIALS_PLAN_HP_TYPES.REGION] : "Сводный план по испытаниям на ХП по регионам",
  [DOWNLOAD_TRIALS_PLAN_HP_TYPES.CULTIVAR] : "План по культуре",
}

const DownloadPlanning: React.FC<IProps> = ({ modalProps, handleClose }) => {

  const { api, toastStore } = useStores();

  const fetcher = {
    [DOWNLOAD_TRIALS_PLAN_HP_TYPES.BRANCH] : api.planning.downloadTrialsBranchPlanHpSummary,
    [DOWNLOAD_TRIALS_PLAN_HP_TYPES.REGION] : api.planning.downloadTrialsRegionPlanHpSummary,
    [DOWNLOAD_TRIALS_PLAN_HP_TYPES.CULTIVAR] : api.planning.downloadCultivarPlans,
  }

  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };
  const handleSubmit = (
    values: IFormik,
    helpers: FormikHelpers<FormikValues>
  ) => {
    helpers.setSubmitting(true);
    const payload = {
      cultivar: values.cultivar?.id as number,
      year: values.year as number
    };
    const promise = fetcher[modalProps.type]
    const name = fileName[modalProps.type]
    promise(payload).then(data => {
      downloadFile(data, name);
    }).catch((error) => {
      showToast(error);
    }).finally(() => {
      handleClose()
      helpers.setSubmitting(false);
    });
  };

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {fileName[modalProps.type]}
        </Typography>
      </Box>
      <Divider/>
      <Formik<IFormik>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={DownloadPlanningForm}
      />
    </Box>
  );
};

export default DownloadPlanning;
