import React, { useEffect } from "react";
import { FastField, Form, useFormikContext, } from "formik";
import { Box } from "@mui/system";
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  IFormik,
  OOS_FIELD_NAME,
  OOS_TYPE_VALUES,
} from "./const";
import { StyledList, } from "@/apps/EditTrial/styles";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation, useParams } from "react-router-dom";
import theme from "@/theme";
import WarningIcon from "@/components/icons/WarningIcon";
import { SWR_KEYS } from "@/const";
import { useStores } from "@/hooks";
import useSWR from "swr";
import { YearDatePicker } from "@/components/Fields";

/**
 * Компонент формы испытания на ООС
 * @сomponent
 */
const OosTrialForm = ({ statementId }) => {
  const formik = useFormikContext<IFormik>();
  const { api } = useStores();
  const { values } = formik;
  const { trialId } = useParams();
  const location = useLocation();

  const { data } = useSWR(
    formik.values.year_of_testing && statementId ? SWR_KEYS.getAmountsContextStatmentById(statementId, 'hp', formik.values.year_of_testing) : null,
    () => api.statement.getAmountsContextStatmentById(statementId, 'oos', formik.values.year_of_testing)
  );

  /**
   * Метод для изменений региона
   * @param event
   */
  const handleRegionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const id = Number(event.target.name);
    const checkedRegions =
      formik.values[OOS_FIELD_NAME.REGIONS];

    if (event.target.checked) {
      formik.setFieldValue(OOS_FIELD_NAME.REGIONS, [
        ...checkedRegions,
        id,
      ]);
    } else {
      formik.setFieldValue(
        OOS_FIELD_NAME.REGIONS,
        checkedRegions.filter((val) => val !== id),
      );
    }
  };

  /**
   * Список регионов
   */
  const renderRegionItems = values.context.regions?.map(
    (el: any) => {
      return (
        <Box key={el.id}>
          {el?.is_deleted ? null : (
            <ListItem>
              <FormControlLabel
                name={String(el.id)}
                checked={values[
                  OOS_FIELD_NAME.REGIONS
                ].includes(el.id)}
                control={<Checkbox color="secondary" />}
                label={
                  <Typography
                    fontSize={14}
                  >{`${el.number} - ${el.name}`}</Typography>
                }
              />
            </ListItem>
          )}
        </Box>
      );
    },
  );

  /**
   * Если создание испытания (не редактирование), то по умолчанию сетим все регионы в формик
   */

  useEffect(() => {
    if (!trialId) {
      const regionIds =
        values.context.regions?.map(
          (region) => region.id,
        ) || [];
      formik.setFieldValue(
        OOS_FIELD_NAME.REGIONS,
        regionIds,
      );
    }
  }, [location]);

  return (
    <Box p={3}>
      <Box sx={{ maxWidth: 762 }}>
        <Form noValidate>
          <Stack direction="column" spacing="20px">
            {formik.values.year_of_testing && data !== undefined &&
              <Box sx={{ border: `1px solid ${theme.palette.blackAndWhite.stroke}`, padding: '16px 17px', borderRadius: "6px", display: 'flex', gap: '10px' }}>
                {data !== undefined && data?.result <= 0 && <WarningIcon />}
                <Typography fontSize={'14px'} textAlign={'center'}>
                  {typeof data?.result === 'number' ? (
                    <>
                      Осталось{' '}
                      <Box
                        component="span"
                        sx={{
                          color: data?.result <= 0 ? theme.palette.error.main : theme.palette.primary.main,
                          fontWeight: 700,
                        }}
                      >
                        {data.result}
                      </Box>
                      {' '} бюджетных сортоопытов на ООС
                    </>
                  ) : (
                    <Box
                      component="span"
                      sx={{
                        color: theme.palette.error.main,
                        fontWeight: 700,
                      }}
                    >
                      {data?.result}  бюджетных сортоопытов на ООС
                    </Box>
                  )}
                </Typography>
              </Box>
            }
            <Typography variant="h3">
              Данные испытания
            </Typography>
            <YearDatePicker
              name={OOS_FIELD_NAME.YEAR_OF_TESTING}
              label="Год испытания"
              required
            />
            <FastField name={OOS_FIELD_NAME.TYPE_OOS}>
              {({ field, meta }) => (
                <FormControl required>
                  <FormLabel>Подвид испытаний</FormLabel>
                  <RadioGroup {...field}>
                    <Stack direction="column" spacing="5px">
                      {OOS_TYPE_VALUES.map((el) => {
                        return (
                          <FormControlLabel
                            key={el.value}
                            value={el.value}
                            control={
                              <Radio color="secondary" />
                            }
                            label={el.label}
                          />
                        );
                      })}
                    </Stack>
                  </RadioGroup>
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </FastField>
            <FastField name={OOS_FIELD_NAME.PAYMENT_TYPE}>
              {({ field }) => (
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Тип оплаты
                  </FormLabel>
                  <RadioGroup {...field}>
                    <Stack direction="row">
                      <FormControlLabel
                        value="budget"
                        control={<Radio color="secondary" />}
                        label="Бюджет"
                      />
                      <FormControlLabel
                        value="paid"
                        control={<Radio color="secondary" />}
                        label="Платные"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            </FastField>
            <FastField name={OOS_FIELD_NAME.REGIONS}>
              {({ meta }) => (
                <FormControl required>
                  <FormLabel>Регионы допуска</FormLabel>
                  <FormGroup onChange={handleRegionChange}>
                    <StyledList>
                      {renderRegionItems}
                    </StyledList>
                  </FormGroup>
                  <FormHelperText
                    error={meta.touched && !!meta.error}
                  >
                    {meta.touched && meta.error}
                  </FormHelperText>
                </FormControl>
              )}
            </FastField>
          </Stack>
          {formik.errors?.errors &&
            <Alert
              severity="error" sx={{ marginTop: '10px' }}>
              {formik.errors?.errors}
            </Alert>
          }
          <Box mt={5}>
            <Button
              startIcon={<CheckIcon />}
              disabled={formik.isSubmitting}
              variant="contained"
              type="submit"
            >
              Готово
            </Button>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default OosTrialForm;
