import { FIELDS_NAME, IFormik } from "./const";
import dayjs from "dayjs";

export const formToValueSerializer = (values: IFormik) => ({
  [FIELDS_NAME.PLOT]: values.plot.id,
  [FIELDS_NAME.ORDER_CULTIVAR]: values.order_cultivar,
  [FIELDS_NAME.CULTIVAR_GROUP]: values.cultivar_group.id ? [values.cultivar_group.id] : undefined,
  [FIELDS_NAME.IS_HP]: values.is_hp,
  [FIELDS_NAME.IS_OOS]: values.is_oos,
  [FIELDS_NAME.IS_PHITO]: values.is_phyto,
  [FIELDS_NAME.TRIALS_FROM]: values.trials_from ? dayjs(values.trials_from).format("YYYY-MM-DD") : null,
  [FIELDS_NAME.TRIALS_TO]: values.trials_to ? dayjs(values.trials_to).format("YYYY-MM-DD"): null,
});