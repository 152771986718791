export const SIDEBAR_STORE_KEY = "main";

/**
 * @constant
 * Значения дропдауна пользователя в шапке
 */
export const HEADER_LABELS = {
  profile: "Профиль",
  statements: "Заявки",
  exit: "Выйти",
};