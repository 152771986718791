import yup from "@/utils/yup";
import { IEntity } from "@/api/interfaces/responses";
import { ERROR_MESSAGES } from "@/const";
import { TContextType } from "./utils";

export enum PHYTO_FIELD_NAME {
  YEAR_OF_TESTING = "year_of_testing", //Год испытаний
  DISEASES = "diseases", //усточивость на болезнь
  REGIONS = 'regions',
  PAYMENT_TYPE = 'payment_type',
}

export const initialValues = {
  [PHYTO_FIELD_NAME.YEAR_OF_TESTING]: null,
  [PHYTO_FIELD_NAME.DISEASES] : [],
  [PHYTO_FIELD_NAME.REGIONS]: [],
  [PHYTO_FIELD_NAME.PAYMENT_TYPE]: 'budget',
};

export  interface IFormik {
  [PHYTO_FIELD_NAME.YEAR_OF_TESTING]: string,
  [PHYTO_FIELD_NAME.DISEASES] : IEntity[],
  [PHYTO_FIELD_NAME.REGIONS]: number[],
  [PHYTO_FIELD_NAME.PAYMENT_TYPE]: string,

  context: TContextType;
}
export const validationSchema = yup.object().shape({
  [PHYTO_FIELD_NAME.YEAR_OF_TESTING]: yup.string().required(),
  [PHYTO_FIELD_NAME.DISEASES]: yup.array().min(1, 'Это поле необходимо заполнить').required(),
  [PHYTO_FIELD_NAME.REGIONS]: yup
    .array()
    .min(1, ERROR_MESSAGES.REQUIRED)
    .required(),
});

export const formToValue = (value) => {
  return {
    [PHYTO_FIELD_NAME.YEAR_OF_TESTING]: value.year_of_testing,
    [PHYTO_FIELD_NAME.DISEASES]: value.diseases.map(el => el.id),
    [PHYTO_FIELD_NAME.REGIONS]:value.regions,
    [PHYTO_FIELD_NAME.PAYMENT_TYPE]: value.payment_type,
  }
}