import { IColumn } from "@/apps/Table/interfaces";
import {
  CensusTakerCells,
  CodeCells,
  SortNameCells,
  VarietyTypeCells,
  EditingCells,
} from "../components/VarietyCellComponents";

import { VARIETY_KEYS } from "../const";

import { TVarietyKeys } from "../interfaces";

import {
  cellAuthor,
  cellDate,
  cellNumber,
  headerCellStyle,
  trialTypeCellsStyle,
} from "../style";
import { cellButtons } from "@/apps/Table/style";

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const columns: IColumn<TVarietyKeys>[] = [
  {
    label: "",
    key: VARIETY_KEYS.type,
    contentHeader: "",
    bodyCellProps: {
      render: VarietyTypeCells,
      styles: cellNumber,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: "",
    key: VARIETY_KEYS.code,
    contentHeader: "",
    bodyCellProps: {
      render: CodeCells,
      styles: trialTypeCellsStyle,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: "",
    key: VARIETY_KEYS.name,
    contentHeader: "",
    bodyCellProps: {
      render: SortNameCells,
      styles: cellAuthor,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    label: "",
    key: VARIETY_KEYS.censusTaker,
    contentHeader: "",
    bodyCellProps: {
      render: CensusTakerCells,
      styles: cellDate,
    },
    headerCellProps: {
      styles: headerCellStyle,
    },
  },
  {
    key: VARIETY_KEYS.editing,
    label: "",
    contentHeader: "",
    bodyCellProps: {
      render: EditingCells,
      styles: cellButtons,
    },
  },
];
