import React from "react";
import {
  CollectionIcon,
  CustomIcon,
  FrostIcon,
  GmoIcon,
  HpIcon,
  OSIcon,
  PhytoIcon,
} from "@/components/icons";
import {
  DISTRIBUTION_TYPE,
  DISTRIBUTION_TYPE_LABEL,
} from "@/const";
import theme from "@/theme";
import {
  OFFBUDGET_STATUS_NAMES
} from "@/pages/OffBudget/const";

export const RIGHT_SIDEBAR_TYPES = {
  EMPTY: "EMPTY",
  STATEMENT: "statement",
  PLANNING_SIDEBAR: "planning",
  PLANNING_HP: "planning_hp",
  OFFBUDGET_SIDEBAR: "offbudget",
  OFFBUDGET_SIDEBAR_FACT: "offbudget_fact",
  CULTIVAR: "cultivar",
  AGRICULTURAL_WORKS: "agricultural_works",
  AGRICULTURAL_WORKS_FIELDS: "agricultural_works_fields",
  ORDER_CULTIVAR: "order_cultivar",
  SEED_ARRIVAL: "seed_arrival",
  CULTIVAR_FIELD: "cultivar_field",
  CULTIVAR_FIELD_VARIETY: "cultivar_field_variety",
  CULTIVAR_FIELD_VARIETY_LAB: "cultivar_field_variety_lab",
  CULTIVAR_FIELD_VARIETY_LAB_TESTING_EXPERT_REVIEW: "cultivar_field_variety_lab_testing_expert_review",
  HANDBOOK: "handbook",
  EXPERT_ASSESSMENT: "expert_assessment",
  PRODUCTIVITY_TESTING_EXPERT_REVIEW:"ProductivityTestingExpertReview",
} as const;

export const RIGHT_SIDEBAR_TYPES_LEVELS = {
  [RIGHT_SIDEBAR_TYPES.STATEMENT]: {
    TRIAL: "trial",
    DISTRIBUTION: "distribution",
  },
  [RIGHT_SIDEBAR_TYPES.PLANNING_SIDEBAR]: {
    GSU_DETAIL: "GSUDetail",
  },
  [RIGHT_SIDEBAR_TYPES.HANDBOOK]: {
    TECH_WORK_SIDEBAR: "tech_work_sidebar",
  },
  [RIGHT_SIDEBAR_TYPES.OFFBUDGET_SIDEBAR]: {
    PLAN: "plan",
    PRODUCTION: "production",
    COST: "cost",
    INVESTMENT: "investment",
    FINANCE: "finance",
    EFFICIENCY: "efficiency",
  },
  [RIGHT_SIDEBAR_TYPES.CULTIVAR]: {
    PRODUCTION: "production",
    FINANCE: "finance",
  },
  [RIGHT_SIDEBAR_TYPES.AGRICULTURAL_WORKS_FIELDS]: {
    VARIETY: "variety",
  },
  [RIGHT_SIDEBAR_TYPES.ORDER_CULTIVAR]: {
    PLOT: "plot_detail",
  },
  [RIGHT_SIDEBAR_TYPES.CULTIVAR_FIELD]: {
    CULTIVAR_FIELD_WORK: "cultivar_field_work",
  },
  [RIGHT_SIDEBAR_TYPES.CULTIVAR_FIELD_VARIETY]: {
   OBSERVATION: "observation",
    PRODUCTIVITY: "productivity"
  },
  [RIGHT_SIDEBAR_TYPES.EXPERT_ASSESSMENT]: {
    TRIAL: "expertAssessmentTrial"
  }
};

export const REMARK_TYPE = {
  REVISION: "revision",
  REFUSAL: "refusal",
};

export const RIGHT_SIDEBAR_LEVELS = {
  FIRST: "FIRST",
  TWO: "TWO",
} as const;

export const QS_KEYS = {
  TAB: "tab",
  INNER_TAB: "inner_tab",
  LEVEL: "level",
  TYPE: "type",
  KEY_SIDEBAR_TYPE: "keySidebar",
  KEY_HANDBOOCK_MODULE: "module",
  KEY_SIDEBAR_DETAIL_TYPE: "keyDetail",
  TRIAL_TYPE: "trial_type",
  TYPE_TESTING: "typeTesting",
} as const;

export const QUERY_STRING_MICRO_ROUTS = {
  REGISTRATION: {
    path: "reg",
  },
  TRIALS: {
    path: "trial",
  },

  REMARKS: { path: "remark" },
  DISTRIBUTION: { path: "distib" },
  DUTIES: { path: "duties" },
} as const;

export const QUERY_STRING_MICRO_ROUTS_AGRICULTURAL_WORK =
  {
    FIELDS: {
      path: "fields",
    },
    WEATHER: {
      path: "weather",
    },
  } as const;

export const QUERY_STRING_MICRO_ROUTS_OFF_BUDGET = {
  PLAN: {
    path: "plan",
  },
  PRODUCTION: {
    path: "production",
  },
  COST: { path: "cost" },
  INVESTMENT: { path: "investment" },
  FINANCE: { path: "finance" },
  EFFICIENCY: { path: "efficiency" },
  REMARKS: { path: "remarks" },
} as const;

export const QUERY_STRING_MICRO_ROUTS_CULTIVAR_FIELD = {
  DATA_PLAN: {
    path: "data_plan",
  },
  SOWING_LIST: {
    path: "sowing_list",
  },
  TECHNICAL_MAP: { path: "technical_map" },
  WEATHER: {path: 'weather'},
  BOOKMARK: { path: "bookmark" },
} as const;

export const QUERY_STRING_MICRO_ROUTS_CULTIVAR_FIELD_WORK =
  {
    DATA_WORK: {
      path: "data_work",
    },
    FERTILIZER: {
      path: "fertilizer",
    },
    PESTICIDE: { path: "pesticide" },
    WEATHER_WORK: {path: 'weather_work'},
  } as const;

export const QUERY_STRING_MICRO_ROUTS_CULTIVAR_FIELD_VARIETY =
  {
    OBSERVATION: {
      path: "observation",
    },
    PRODUCTIVITY: {
      path: "productivity",
    },
  } as const;

export const QUERY_STRING_MICRO_ROUTS_EXPERT_ASSESSMENT =
  {
    INFO: {
      path: "info",
    },
    TRIAL_LIST: {
      path: "trialList",
    },
  } as const;

export const QUERY_STRING_MICRO_ROUTS_OBSERVATION =
  {
    INDICATORS: {
      path: "indicators",
    },
    WEATHER_RESISTANCES: {
      path: "weather_resistances",
    },
    DISEASES: { path: "diseases" },
  } as const;

export const QUERY_STRING_MICRO_ROUTS_CULTIVAR = {
  PRODUCTION: { path: "production" },
  FINANCE: { path: "finance" },
  SEEDS: { path: "seeds" },
  FERTILIZERS: { path: "fertilizers" },
  REMEDIES: { path: "remedies" },
  OPERATIONS: { path: "operations" },
  COST: { path: "cost"},
} as const;

export const QUERY_STRING_MICRO_ROUTS_ORDER_CULTIVAR = {
  CULTIVAR_DATA: {
    path: "cultivar_data",
  },
  REGIONS: {
    path: "regions",
  },
  COMMENTS: { path: "cost" },
} as const;

export const DISTRIBUTION_TYPES = {
  [DISTRIBUTION_TYPE.HP]: {
    type: DISTRIBUTION_TYPE.HP,
    label: DISTRIBUTION_TYPE_LABEL.HP,
    icon: <HpIcon sx={{ width: "20px", height: "20px" }} />,
    textColor: theme.palette.green.main,
  },
  [DISTRIBUTION_TYPE.OOS]: {
    type: DISTRIBUTION_TYPE.OOS,
    label: DISTRIBUTION_TYPE_LABEL.OOS,
    icon: <OSIcon sx={{ width: "20px", height: "20px" }} />,
    textColor: theme.palette.another.orange,
  },
  [DISTRIBUTION_TYPE.PHYTO]: {
    type: DISTRIBUTION_TYPE.PHYTO,
    label: DISTRIBUTION_TYPE_LABEL.PHYTO,
    icon: (
      <PhytoIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.red.main,
  },
  [DISTRIBUTION_TYPE.FROST]: {
    type: DISTRIBUTION_TYPE.FROST,
    label: DISTRIBUTION_TYPE_LABEL.FROST,
    icon: (
      <FrostIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.blue.main,
  },
  [DISTRIBUTION_TYPE.COLLECTION]: {
    type: DISTRIBUTION_TYPE.COLLECTION,
    label: DISTRIBUTION_TYPE_LABEL.COLLECTION,
    icon: (
      <CollectionIcon
        sx={{ width: "20px", height: "20px" }}
      />
    ),
    textColor: theme.palette.yellow.main,
  },
  [DISTRIBUTION_TYPE.CUSTOMS]: {
    type: DISTRIBUTION_TYPE.CUSTOMS,
    label: DISTRIBUTION_TYPE_LABEL.CUSTOMS,
    icon: (
      <CustomIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.blue.superDarkBlue,
  },
  [DISTRIBUTION_TYPE.GMO]: {
    type: DISTRIBUTION_TYPE.GMO,
    label: DISTRIBUTION_TYPE_LABEL.GMO,
    icon: (
      <GmoIcon sx={{ width: "20px", height: "20px" }} />
    ),
    textColor: theme.palette.another.violet,
  },
};

export const translatedStatus = {
  [OFFBUDGET_STATUS_NAMES.agreed]: "Согласовано",
  [OFFBUDGET_STATUS_NAMES.approved]: "Утверждено",
  [OFFBUDGET_STATUS_NAMES.rejected]: "Отклонено",
};

export const QUERY_STRING_MICRO_ROUTES_MAIN = {
  GENERAL_INFO: {
    path: "generalInfo",
  },
  GSU: {
    path: "gsu",
  },
  LOCATION: {
    path: "location",
  },
  AGRICULTIRAL_MACHINERY: {
    path: "agricultural_machinery",
  },
  VARIETY_TESTING: { path: "variety_testing" },
  PRODUCTION_ACTIVITY: { path: "production_activity" },
} as const;

