import React from "react";
import { Field, Form, useFormikContext } from "formik";
import { useStores } from "@/hooks";

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { REPRODUCTION_FIELD_NAME, IProps , TForm } from "../const";

/**
 * Форма создания/редактирования 
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const ReproductionForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore } = useStores();
  const form = useFormikContext<TForm>();

  const title = modalProps.reproductionId
    ? "Редактировать запись"
    : "Добавить запись";


  return (
    <>
      <Box p={3}>
        <Typography>Репродукция</Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <Field name={REPRODUCTION_FIELD_NAME.NAME}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Название'
                  fullWidth
                  size='small'
                  required
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[REPRODUCTION_FIELD_NAME.NAME]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={REPRODUCTION_FIELD_NAME.DESCRIPTION}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label='Описание'
                  fullWidth
                  size='small'
                  required
                  multiline
                  minRows={4}
                  maxRows={10}
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[REPRODUCTION_FIELD_NAME.DESCRIPTION]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText error={meta.touched && !!meta.error}>
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Stack>

        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red' onClick={() => modalStore.close()}>
            отмена
          </Button>
          <Button
            type='submit'
            disabled={form.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </>
  );
};

export default ReproductionForm;
