import React, { Fragment } from 'react'
import { useStores } from '@/hooks';
import { Form, useFormikContext } from 'formik';
import { Box, Button, Divider,Stack, Typography } from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import { TForm, WEATHER_DATA_FIELD_NAME } from '../const';
import FastRegexTextField from '@/components/FastRegexTextField';
import dayjs from 'dayjs';
// import FastRegexTextField from '@/components/FastRegexTextField';

/**
 * Форма создания/редактирования фазы вегетации
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * */

export const WeatherDataForm: React.FC<any> = ({ modalProps }) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<TForm>();
  const date = dayjs(modalProps.day).format('DD.MM.YYYY')


  const title = modalProps.id
    ? `Редактировать метеоданные за ${date}`
    :  `Добавить метеоданные за ${date}`;

    console.log(formik.values)
  return (
    <Fragment>
      <Box p={3}>
        <Typography variant='h2' sx={{textWrap:'balance', maxWidth: '450px'}}>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>

        <Stack direction={"column"} gap={2} p={2}>
          <FastRegexTextField 
            name={WEATHER_DATA_FIELD_NAME.MAXTEMP}
            label={"Температура воздуха максимальная"}
            fullWidth
            required
            size="small"
          />
          <FastRegexTextField 
            name={WEATHER_DATA_FIELD_NAME.MINTEMP}
            label={"Температура воздуха минимальная"}
            fullWidth
            required
            size="small"
          />
          <FastRegexTextField 
            name={WEATHER_DATA_FIELD_NAME.AVRTEMP}
            label={"Температура воздуха средняя"}
            fullWidth
            required
            size="small"
          />
          <FastRegexTextField 
            name={WEATHER_DATA_FIELD_NAME.TOTAL_PRECIP}
            label={"Сумма осадков за сутки, мм"}
            fullWidth
            required
            size="small"
          />
            <FastRegexTextField 
            name={WEATHER_DATA_FIELD_NAME.TOTAL_SNOW}
            label={"Высота снежн. покрова, см"}
            fullWidth
            required
            size="small"
            />
          <FastRegexTextField 
            name={WEATHER_DATA_FIELD_NAME.AVR_HUM}
            label={"Относительная минимальная влажность воздуха, %"}
            fullWidth
            required
            size="small"
          />

          <FastRegexTextField 
            name={WEATHER_DATA_FIELD_NAME.CONDITION}
            label={"Общая облачность"}
            fullWidth
            required
            size="small"
          />
          <FastRegexTextField 
            name={WEATHER_DATA_FIELD_NAME.MAX_WIND}
            label={"Максимальная скорость ветра, км/ч"}
            fullWidth
            required
            size="small"
          />
          <FastRegexTextField 
            name={WEATHER_DATA_FIELD_NAME.SOIL}
            label={"Минимальная температура на поверхности почвы"}
            fullWidth
            required
            size="small"
          />
        </Stack>

        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red'
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type='submit'
            disabled={formik.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  )
}
