import { IColumn } from "@/apps/Table/interfaces";
import { TDistrictsKeys } from "../interfaces";
import { DISTRICTS_KEYS, DISTRICTS_LABELS } from "../const";
import { PointerTextCell } from "../cells";
import {cellButtonsMaterialType,cellPointText, headerCellStyle } from "../style";
import { BranchCell, EditingCellsDistricts, LightZoneCell } from "../templates/Districts/cells";

export const COLUMN_EDITING = {
    value: "editing",
    label: "",
};

export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const DistrictsColumns: IColumn<TDistrictsKeys>[] = [
    {
        label:DISTRICTS_LABELS.name,
        key: DISTRICTS_KEYS.name,
        contentHeader: DISTRICTS_LABELS.name,
        bodyCellProps: {
            render: PointerTextCell,
            styles: cellPointText,
        },
        headerCellProps: {
            styles: headerCellStyle,
        },
    },
    {
        label: DISTRICTS_LABELS.light_zone,
        key: DISTRICTS_KEYS.light_zone__number,
        contentHeader: DISTRICTS_LABELS.light_zone,
        bodyCellProps: {
          render: LightZoneCell,
          styles: cellPointText,
        },
        headerCellProps: {
          styles: headerCellStyle,
        },
      },
    {
        label: DISTRICTS_LABELS.branch,
        key: DISTRICTS_KEYS.branch,
        contentHeader: DISTRICTS_LABELS.branch,
        bodyCellProps: {
          render: BranchCell,
          styles: cellPointText,
        },
        headerCellProps: {
          styles: headerCellStyle,
        },
      },
      {
        key: "editing",
        label: DISTRICTS_LABELS.editing,
        contentHeader: COLUMN_EDITING.label,
        bodyCellProps: {
          render: EditingCellsDistricts,
          styles: cellButtonsMaterialType,
        },
      },
];