import { IColumn } from "@/apps/Table/interfaces";
import { PointerTextCell } from "@/apps/Handboock/cells";
import { cellButtons, cellPointText, headerCellStyle } from "../style";

import { REMARK_HEADER_LABELS, REMARK_KEYS } from "../const";

import { TRemarkKeys } from "../interfaces";
import { EditingCellsRemarks, RemarkTypeCell } from "../templates/Remark/cells";

export const COLUMN_EDITING = {
  value: "editing",
  label: ""
};

export const EDITING_CELL = {
  editing: "Редактировать",
  duplicate: "Копировать",
  delete: "Удалить"
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */
export const RemarkColumns: IColumn<TRemarkKeys>[] = [
  {
    label: REMARK_HEADER_LABELS.name,
    key: REMARK_KEYS.name,
    contentHeader: REMARK_HEADER_LABELS.name,
    bodyCellProps: {
      render: PointerTextCell,
      styles: cellPointText
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    label: REMARK_HEADER_LABELS.remark_type,
    key: REMARK_KEYS.remark_type,
    contentHeader: REMARK_HEADER_LABELS.remark_type,
    bodyCellProps: {
      render: RemarkTypeCell,
      styles: cellPointText
    },
    headerCellProps: {
      styles: headerCellStyle
    }
  },
  {
    key: "editing",
    label: REMARK_HEADER_LABELS.editing,
    contentHeader: COLUMN_EDITING.label,
    bodyCellProps: {
      render: EditingCellsRemarks,
      styles: cellButtons
    }
  }
];
