import { SIDEBAR_STORE_KEY } from "@/apps/MainLayout/const";
import { makeAutoObservable } from "mobx";
import { RootStore } from "./Root";
import { isNull } from "@/utils/helpers";

interface IParsedStorage {
  open?: boolean;
  parsedStorage?: Record<string, unknown>;
}

const isLocalStorageAvailable = () =>
  typeof window !== "undefined" && window.localStorage;

export class Sidebar {
  localStorageKey: string;
  open: boolean;
  parsedStorage: IParsedStorage;
  rootStore: RootStore; // Ссылка на RootStore

  constructor(rootStore: RootStore, key: string) {
    this.localStorageKey = `sidebar_${key}`;
    const parsedStorage = this.getParsedStorage();
    this.parsedStorage = parsedStorage;
    this.open = isNull(parsedStorage) || parsedStorage?.open !== false;
    this.rootStore = rootStore; // Сохраняем ссылку на RootStore

    makeAutoObservable(this);
  }

  getParsedStorage = (): IParsedStorage => {
    if (isLocalStorageAvailable()) {
      const sidebarStorage = window.localStorage.getItem(this.localStorageKey);

      if (isNull(sidebarStorage)) {
        return {};
      }

      return JSON.parse(sidebarStorage);
    } else {
      return {};
    }
  };

  changeStorageValue = (key: string, value: unknown): void => {
    if (isLocalStorageAvailable()) {
      this.parsedStorage[key] = value;
      const stringifiedStorage = JSON.stringify(this.parsedStorage);
      window.localStorage.setItem(this.localStorageKey, stringifiedStorage);
    }
  };

  toggle = (): void => {
    this.open = !this.open;
    this.changeStorageValue(SIDEBAR_STORE_KEY, this.open);
  };
}
