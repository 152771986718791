/**
 * Тут лежат утилиты для преобразований данных сервер/форма или форма/сервер
 * DTO если приводим данные формы к виду контракта
 * Serializer если преобразовываем данные (например, не отправляем null)
 */

import dayjs from "dayjs";
import { TForm } from "../const";
import { transformNullToUndefined } from "@/utils/objects";

/**
 * @param formValues объект из формы
 * @returns объект для сервера
 **/

export const mapFormToValuesSerializer = (
  formValues: TForm,
): any => {
  const values =
    transformNullToUndefined<Partial<TForm>>(formValues); // null ломает запросы, меняем на undefined

  const payload = {
    ...values,
    additional_info: values.additional_info,
    confirmation_date: dayjs(
      values.confirmation_date,
    ).format("YYYY-MM-DD"),
    cultivar: values?.cultivar?.id as number,
    files: undefined,
  };

  return payload;
};
