import { IColumn } from "@/apps/Table/interfaces";
import { TCultivarGroup } from "../interfaces";
import { CULTIVAR_GROUP_KEYS, CULTIVAR_GROUP_LABELS, } from "../const";
import {cellButtonsMaterialType,cellPointText, headerCellStyle } from "../style";
import { EditingCellsCultivarGroup, IndicatorNameCell, UsageTypeCell } from "../templates/CultivarGroup/cells";
import { PointerTextCell } from "../cells";

export const COLUMN_EDITING = {
    value: "editing",
    label: "",
};

export const EDITING_CELL = {
    editing: "Редактировать",
    duplicate: "Копировать",
    delete: "Удалить",
};

/**
 * Массив колонок таблицы, схема.
 * @type {IColumn[]}
 */

export const CultivarGroupColumns: IColumn<TCultivarGroup>[] = [
    {
        label: CULTIVAR_GROUP_LABELS.id,
        key: CULTIVAR_GROUP_KEYS.id,
        contentHeader: CULTIVAR_GROUP_LABELS.id,
        bodyCellProps: {
            render: PointerTextCell,
            styles: cellPointText,
        },
        headerCellProps: {
            styles: headerCellStyle,
        },
    },
    {
        label: CULTIVAR_GROUP_LABELS.usage_type,
        key: CULTIVAR_GROUP_KEYS.usage_type__name,
        contentHeader: CULTIVAR_GROUP_LABELS.usage_type,
        bodyCellProps: {
            render: UsageTypeCell,
            styles: cellPointText,
        },
        headerCellProps: {
            styles: headerCellStyle,
        },
    },
    {
        label: CULTIVAR_GROUP_LABELS.variant,
        key: CULTIVAR_GROUP_KEYS.variant,
        contentHeader: CULTIVAR_GROUP_LABELS.variant,
        bodyCellProps: {
          render: IndicatorNameCell,
          styles: cellPointText,
        },
        headerCellProps: {
          styles: headerCellStyle,
        },
      },
      {
        key: "editing",
        label: CULTIVAR_GROUP_LABELS.editing,
        contentHeader: COLUMN_EDITING.label,
        bodyCellProps: {
          render: EditingCellsCultivarGroup,
          styles: cellButtonsMaterialType,
        },
      },
];