import yup from "@/utils/yup";

import { reqInterface, resInterface } from "@/api/interfaces";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    key: string;
    postId?: number;
    initialValues?: resInterface.IPost;
  };
  onSubmit?: () => void;
}

export type TForm = reqInterface.IPost;

export enum COUNTRY_FIELD_NAME {
  NAME = "name",
  DATIVE_NAME = "dative_name",
  FULL_NAME = "full_name",
}

export const validationSchema = yup.object().shape({

  [COUNTRY_FIELD_NAME.NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Это поле не может быть пустым'),

  [COUNTRY_FIELD_NAME.FULL_NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Это поле не может быть пустым'),

  [COUNTRY_FIELD_NAME.DATIVE_NAME]: yup.string()
    .required()
    .trim()
    .notOneOf([''], 'Это поле не может быть пустым'),

  });
