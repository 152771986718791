import yup from "@/utils/yup";
import {
  ICultivar,
  IVariety
} from "@/api/interfaces/responses";

export const FIELDS_NAME = {
  CULTIVAR: "cultivar",
  VARIETY: "variety"
} as const;

export interface IFormik {
  [FIELDS_NAME.CULTIVAR]: IVariety,
  [FIELDS_NAME.VARIETY]: ICultivar
}

export const validationSchema = yup.object().shape({
  [FIELDS_NAME.CULTIVAR]: yup.mixed().required(),
  [FIELDS_NAME.VARIETY]: yup.mixed().required(),
});

export const initialValues = {
  [FIELDS_NAME.CULTIVAR]: "",
  [FIELDS_NAME.VARIETY]: ""
};