import React from "react";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Formik } from "formik";
import SelectChemistryForm
  from "./components/SelectChemistryForm";
import {
  IFormik,
  initialValues,
  validationSchema,
} from "./const";

import { TChemistryType } from "@/api/types";

/**
 * Компонент модалки выбора удобрения/средства защиты
 * @param props
 * @param props.cultivarId - id плана внебюджета
 */

interface IProps {
  handleClose: () => void;
  modalProps: {
    type: TChemistryType;
    handleSubmit: (payload: IFormik) => void
    title: string;
  };
}

const SelectChemistry: React.FC<IProps> = (props) => {
  const handleSubmit = (
    values: IFormik,
  ) => {
    props.modalProps.handleSubmit && props.modalProps.handleSubmit(values)
    props.handleClose()
  };

  const title = props.modalProps.title || "Добавить удобрения"

  return (
    <Box>
      <Box p={3} marginRight={6}>
        <Typography variant="h2" fontSize={24}>
          {title}
        </Typography>
      </Box>
      <Divider />
      <Formik<IFormik>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        component={() => <SelectChemistryForm {...props} />}
      />
    </Box>
  );
};

export default SelectChemistry;
