import { useEffect, useMemo } from 'react';
import { useEvent } from "@/hooks";

/**
 * Обычный дебаунс с методом для отмены
 * @param fn
 * @param ms
 */

const debounce = <T extends (...args: any[]) => any>(
  fn: T,
  ms: number,
): {
  (...args: Parameters<T>): void;
  cancel: () => void;
} => {
  let timeoutId: number | null = null;

  /**
   *
   */
  function debounced(...args: Parameters<T>): void {
    if (typeof timeoutId === 'number') {
      clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, ms);
  }

  debounced.cancel = () => {
    if (typeof timeoutId !== 'number') {
      return;
    }
    clearTimeout(timeoutId);
  };
  return debounced;
};
/**
 * Хук для дебаунса / не нужно беспокиться о мемоизации колбека и дальнейшей отписки
 * @param fn - колбек
 * @param ms - время
 */
export const useDebounceCallback = <Fn extends (...args: any[]) => any>(
  fn: Fn,
  ms: number,
): ((...args: Parameters<Fn>) => void) => {
  const memoizedFn = useEvent(fn);

  const debouncedFn = useMemo(
    () =>
      debounce((...args: Parameters<Fn>) => {
        memoizedFn(...args);
      }, ms),
    [ms],
  );

  useEffect(
    () => () => {
      debouncedFn.cancel();
    },
    [debouncedFn],
  );

  return debouncedFn;
};


