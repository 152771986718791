import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface IMapLocationProps extends SvgIconProps {
  active?: boolean;
}

//cсылка на иконку в фигме https://www.figma.com/file/JKHBXXX2Qxs5MINsb5TBzU/%D0%93%D0%BE%D1%81%D1%81%D0%BE%D1%80%D1%82?type=design&node-id=86-113421&mode=design&t=NsBAwfXhny5rGRRi-0
const MapLocation = ({
  active = false,
  ...props
}: IMapLocationProps) => {
  const isActive = active ? 'green' : '#1C1B1F';

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.000000"
        height="20.000000"
        viewBox="0 0 16 20"
        fill="none"
      >
        <path
          id="location_on"
          d="M8 10C8.5498 10 9.021 9.8042 9.4126 9.4126C9.8042 9.021 10 8.5498 10 8C10 7.4502 9.8042 6.979 9.4126 6.5874C9.021 6.1958 8.5498 6 8 6C7.4502 6 6.979 6.1958 6.5874 6.5874C6.1958 6.979 6 7.4502 6 8C6 8.5498 6.1958 9.021 6.5874 9.4126C6.979 9.8042 7.4502 10 8 10ZM8 17.3501C10.0332 15.4834 11.5415 13.7876 12.5249 12.2627C13.5083 10.7373 14 9.3833 14 8.2002C14 6.3833 13.4209 4.896 12.2627 3.7373C11.104 2.5791 9.68311 2 8 2C6.31689 2 4.896 2.5791 3.7373 3.7373C2.5791 4.896 2 6.3833 2 8.2002C2 9.3833 2.4917 10.7373 3.4751 12.2627C4.4585 13.7876 5.9668 15.4834 8 17.3501ZM8 20C5.31689 17.7168 3.3125 15.5957 1.9873 13.6377C0.662598 11.6792 0 9.8667 0 8.2002C0 5.7002 0.804199 3.7085 2.4126 2.2251C4.021 0.741699 5.8833 0 8 0C10.1167 0 11.979 0.741699 13.5874 2.2251C15.1958 3.7085 16 5.7002 16 8.2002C16 9.8667 15.3374 11.6792 14.0127 13.6377C12.6875 15.5957 10.6831 17.7168 8 20Z"
          fill={isActive}
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
};

export default MapLocation;
