import React from "react";
import { Box } from "@mui/material";
import { bgError } from "@/assets/common";
import { NoSSR } from "@/components/NoSSR";
import styles from "../styles/index.module.scss";

type ErrorLayotProps = {
  children: React.ReactNode;
};

const ErrorLayot: React.FC<ErrorLayotProps> = ({ children }) => {
  return (
    <NoSSR>
      <div className={styles.wrapper}>
        <img className={styles.wrapper__bg} src={bgError} alt="Background" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start"
          }}
        >
          <div className={styles.content}>{children}</div>
        </Box>
      </div>
    </NoSSR>
  );
};

export default ErrorLayot;
