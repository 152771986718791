import React, { Fragment, useEffect, useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import { useInfiniteScroll, useStores } from "@/hooks";

import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemIcon,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import { IProps, TForm } from "../types";
import theme from "@/theme";
import {
  FIELD_NAMES,
  QUANTITY_TYPE,
  QUANTITY_TYPE_LABEL,
} from "../const";
import { SWR_KEYS } from "@/const";
import Autocomplete from "@/components/Autocomplete";
import {
  CollectionIcon,
  CustomIcon,
  FrostIcon,
  GmoIcon,
  HpIcon,
  OSIcon,
  PhytoIcon,
} from "@/components/icons";

/**
 * Форма создания/редактирования элемента для справочника "Количество в разнарядках"
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const QuantityForm: React.FC<IProps> = ({ modalProps }) => {
  const { api, modalStore } = useStores();
  const form = useFormikContext<TForm>();

  // search для autocompletes
  const [search, setSearch] = useState("");
  const [searchGSU, setSearchGSU] = useState("");
  const [searchUnit, setSearchUnit] = useState("");
  const [searchCultivar, setSearchCultivar] = useState("");
  const [searchRegion, setSearchRegion] = useState("");
  const [searchBranch, setSearchBranch] = useState("");

  const title = modalProps.quantityId
    ? "Редактировать запись"
    : "Добавить запись";

  const swrKeyMaterialType = SWR_KEYS.getMaterialTypeList();
  const swrKeyGSU = SWR_KEYS.getPlotList();
  const swrKeyUnits = SWR_KEYS.getRegulatoryInfoUnitList();
  const swrKeyRegions = SWR_KEYS.getRegionsList();
  const swrKeyBranch = SWR_KEYS.getBranchList();

  // material type
  const getKeyMaterialType = (index: number) => ({
    _key: swrKeyMaterialType,
    page: index + 1,
    search: search,
  });

  const materialTypeFetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getMaterialTypeList({
      page,
      search,
    });
  };

  const {
    data: dataMaterialType,
    handleScroll: handleScrollMaterialType,
  } = useInfiniteScroll(
    getKeyMaterialType,
    materialTypeFetcher,
    {
      revalidateFirstPage: false,
    },
  );

  // gsu
  const getKeyGSU = (index: number) => ({
    _key: swrKeyGSU,
    page: index + 1,
    name: searchGSU,
    branch:
      form.values[FIELD_NAMES.BRANCH]?.id ?? undefined,
  });

  const gsuFetcher = (args) => {
    const { page, name, branch } = args;
    return api.regulatoryInfo.getPlotList({
      page,
      name,
      branch,
    });
  };

  const { data: dataGSU, handleScroll: handleScrollGSU } =
    useInfiniteScroll(getKeyGSU, gsuFetcher, {
      revalidateFirstPage: false,
    });

  // units
  const getKeyUnits = (index: number) => ({
    _key: swrKeyUnits,
    page: index + 1,
    search: searchUnit,
  });

  const unitsFetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getUnitList({
      page,
      search,
    });
  };

  const {
    data: dataUnits,
    handleScroll: handleScrollUnits,
  } = useInfiniteScroll(getKeyUnits, unitsFetcher, {
    revalidateFirstPage: false,
  });

  const getCultivarKey = (index: number) => {
    return {
      key: SWR_KEYS.getCultivar(),
      page: index + 1,
      search: searchCultivar,
    };
  };

  const fetcherCultivars = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getCultivarList({
      page,
      search,
    });
  };

  const {
    data: cultivarsData,
    handleScroll: handleCultivarsScroll,
  } = useInfiniteScroll(getCultivarKey, fetcherCultivars, {
    revalidateFirstPage: false,
  });

  // регион
  const getKeyRegion = (index: number) => ({
    _key: swrKeyRegions,
    page: index + 1,
    search: searchRegion,
  });

  const regionsFetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getRegionList({
      page,
      search,
    });
  };

  const {
    data: dataRegions,
    handleScroll: handleScrollRegions,
  } = useInfiniteScroll(getKeyRegion, regionsFetcher, {
    revalidateFirstPage: false,
  });

  // branch
  const getKeyBranch = (index: number) => ({
    _key: swrKeyBranch,
    page: index + 1,
    search: searchBranch,
    region:
      form.values[FIELD_NAMES.REGION]?.id ?? undefined,
  });

  const branchFetcher = (args) => {
    const { page, search, region } = args;
    return api.regulatoryInfo.getBranchList({
      page,
      search,
      region,
    });
  };

  const {
    data: dataBranch,
    handleScroll: handleScrollBranch,
  } = useInfiniteScroll(getKeyBranch, branchFetcher, {
    revalidateFirstPage: false,
  });

  const handleChangePlot = (event, child) => {
    form.setFieldValue(FIELD_NAMES.PLOT, child);
  };

  const handleChangeMaterialType = (event, child) => {
    form.setFieldValue(FIELD_NAMES.MATERIAL_TYPE, child);
  };

  const handleChangeUnit = (event, child) => {
    form.setFieldValue(FIELD_NAMES.UNIT, child);
  };

  const handleChangeCultivar = (event, child) => {
    form.setFieldValue(FIELD_NAMES.CULTIVAR, child);
  };

  const handleChangeRegion = (event, child) => {
    form.setFieldValue(FIELD_NAMES.REGION, child);
    form.setFieldValue(FIELD_NAMES.BRANCH, undefined);
    form.setFieldValue(FIELD_NAMES.PLOT, undefined);
  };

  const handleClearRegion = () => {
    form.setFieldValue(FIELD_NAMES.REGION, undefined);
    form.setFieldValue(FIELD_NAMES.BRANCH, undefined);
  };

  const handleChangeBranch = (event, child) => {
    form.setFieldValue(FIELD_NAMES.BRANCH, child);
  };

  const TYPES_ARRAY = [
    {
      name: QUANTITY_TYPE.HP,
      label: QUANTITY_TYPE_LABEL.HP,
      icon: (
        <HpIcon sx={{ width: "20px", height: "20px" }} />
      ),
      textColor: theme.palette.green.main,
    },
    {
      name: QUANTITY_TYPE.OOS,
      label: QUANTITY_TYPE_LABEL.OOS,
      icon: (
        <OSIcon sx={{ width: "20px", height: "20px" }} />
      ),
      textColor: theme.palette.another.orange,
    },
    {
      name: QUANTITY_TYPE.PHYTO,
      label: QUANTITY_TYPE_LABEL.PHYTO,
      icon: (
        <PhytoIcon sx={{ width: "20px", height: "20px" }} />
      ),
      textColor: theme.palette.red.main,
    },
    {
      name: QUANTITY_TYPE.FROST,
      label: QUANTITY_TYPE_LABEL.FROST,
      icon: (
        <FrostIcon sx={{ width: "20px", height: "20px" }} />
      ),
      textColor: theme.palette.blue.main,
    },
    {
      name: QUANTITY_TYPE.COLLECTION,
      label: QUANTITY_TYPE_LABEL.COLLECTION,
      icon: (
        <CollectionIcon
          sx={{ width: "20px", height: "20px" }}
        />
      ),
      textColor: theme.palette.yellow.main,
    },
    {
      name: QUANTITY_TYPE.CUSTOMS,
      label: QUANTITY_TYPE_LABEL.CUSTOMS,
      icon: (
        <CustomIcon
          sx={{ width: "20px", height: "20px" }}
        />
      ),
      textColor: theme.palette.blue.superDarkBlue,
    },
    {
      name: QUANTITY_TYPE.GMO,
      label: QUANTITY_TYPE_LABEL.GMO,
      icon: (
        <GmoIcon sx={{ width: "20px", height: "20px" }} />
      ),
      textColor: theme.palette.another.violet,
    },
  ] as const;

  const renderTypeItem = TYPES_ARRAY.map((el) => {
    return (
      <MenuItem key={el.name} value={el.name}>
        <Box
          display={"flex"}
          gap={"10px"}
          alignItems={"center"}
        >
          <ListItemIcon>{el.icon}</ListItemIcon>
          <Typography color={el.textColor}>
            {el.label}
          </Typography>
        </Box>
      </MenuItem>
    );
  });

  const CustomPaper = (props) => {
    return (
      <Paper
        sx={{
          marginBottom: "7px",
        }}
        elevation={1}
        {...props}
      />
    );
  };

  useEffect(() => {
    form.setFieldError('non_field_errors', undefined)
  }, [form.values])


  return (
    <Fragment>
      <Box p={3}>
        <Typography
          color={theme.palette.blackAndWhite.gray}
        >
          Количество в разнарядке
        </Typography>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>
        <Stack direction={"column"} gap={2} p={2}>
          <Field name={FIELD_NAMES.TYPE}>
            {({ field, meta }) => (
              <FormControl size="small" fullWidth required>
                <InputLabel id="select-label">
                  Тип разнарядки
                </InputLabel>
                <Select
                  {...field}
                  label="Тип разнарядки"
                  fullWidth
                  required
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[FIELD_NAMES.TYPE]}
                  disabled={meta.isSubmitting}
                >
                  {renderTypeItem}
                </Select>

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
          <Field name={FIELD_NAMES.REGION}>
            {({ field, meta }) => (
              <FormControl required>
                <Autocomplete
                  {...field}
                  onChange={handleChangeRegion}
                  label={"Регион"}
                  required
                  onClear={handleClearRegion}
                  data={dataRegions}
                  handleScroll={handleScrollRegions}
                  search={setSearchRegion}
                  error={meta.touched && !!meta.error}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>

          <Field name={FIELD_NAMES.BRANCH}>
            {({ field, meta }) => (
              <FormControl required>
                <Autocomplete
                  {...field}
                  onChange={handleChangeBranch}
                  label={"Филиал"}
                  required
                  data={dataBranch}
                  handleScroll={handleScrollBranch}
                  search={setSearchBranch}
                  error={meta.touched && !!meta.error}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>

          <Field name={FIELD_NAMES.PLOT}>
            {({ field, meta }) => (
              <FormControl required>
                <Autocomplete
                  {...field}
                  onChange={handleChangePlot}
                  label={"ГСУ"}
                  disabled={
                    !form.values[FIELD_NAMES.BRANCH]
                  }
                  required
                  data={dataGSU}
                  handleScroll={handleScrollGSU}
                  search={setSearchGSU}
                  error={meta.touched && !!meta.error}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>

          <Field name={FIELD_NAMES.CULTIVAR}>
            {({ field, meta }) => (
              <FormControl required>
                <Autocomplete
                  {...field}
                  onChange={handleChangeCultivar}
                  label={"Культура"}
                  required
                  data={cultivarsData}
                  handleScroll={handleCultivarsScroll}
                  search={setSearchCultivar}
                  error={meta.touched && !!meta.error}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>

          <Field name={FIELD_NAMES.MATERIAL_TYPE}>
            {({ field, meta }) => (
              <FormControl required>
                <Autocomplete
                  {...field}
                  onChange={handleChangeMaterialType}
                  label={"Тип материала"}
                  required
                  data={dataMaterialType}
                  handleScroll={handleScrollMaterialType}
                  search={setSearch}
                  error={meta.touched && !!meta.error}
                />

                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Field name={FIELD_NAMES.QUANTITY}>
                {({ field, meta }) => (
                  <FormControl fullWidth required>
                    <TextField
                      {...field}
                      label="Количество"
                      fullWidth
                      size="small"
                      required
                      onChange={form.handleChange}
                      onBlur={field.handleBlur}
                      value={
                        form.values[FIELD_NAMES.QUANTITY]
                      }
                      disabled={meta.isSubmitting}
                      error={meta.touched && !!meta.error}
                    />
                    <FormHelperText
                      error={meta.touched && !!meta.error}
                    >
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name={FIELD_NAMES.UNIT}>
                {({ field, meta }) => (
                  <FormControl fullWidth required>
                    <Autocomplete
                      {...field}
                      PaperComponent={CustomPaper}
                      onChange={handleChangeUnit}
                      label={"Единицы измерения"}
                      required
                      data={dataUnits}
                      handleScroll={handleScrollUnits}
                      search={setSearchUnit}
                      error={meta.touched && !!meta.error}
                    />

                    <FormHelperText
                      error={meta.touched && !!meta.error}
                    >
                      {meta.touched && meta.error}
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
            </Grid>
          </Grid>
        </Stack>

        <Divider />
        {form.errors?.non_field_errors &&
          <Alert
            severity="error">
            {form.errors?.non_field_errors}
          </Alert>
        }
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            color="red"
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type="submit"
            disabled={form.isSubmitting}
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  );
};

export default QuantityForm;
