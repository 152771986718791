import * as Yup from 'yup';
import { VALIDATION_MESSAGE_NUMBER } from './const';

const typeErrText = 'Введите число'
const positiveErrText = 'Число должно быть положительным';
const reqText = 'Это поле необходимо заполнить';



export const validationSchema = Yup.object().shape({
  year: Yup.mixed().required(),
  revenue: Yup.number()
    .typeError(typeErrText)
    .required(reqText)
    .positive(positiveErrText)
    .test('is-decimal', VALIDATION_MESSAGE_NUMBER.text, (value) =>
      VALIDATION_MESSAGE_NUMBER.regEx.test(value.toString())
    ),

  direct_expenses: Yup.number()
    .typeError(typeErrText)
    .required(reqText)
    .positive(positiveErrText)
    .test('is-decimal', VALIDATION_MESSAGE_NUMBER.text, (value) =>
      VALIDATION_MESSAGE_NUMBER.regEx.test(value.toString())
    ),

  production_expenses: Yup.number()
    .typeError(typeErrText)
    .required(reqText)
    .positive(positiveErrText)
    .test('is-decimal', VALIDATION_MESSAGE_NUMBER.text, (value) =>
      VALIDATION_MESSAGE_NUMBER.regEx.test(value.toString())
    ),

  administrative_expenses: Yup.number()
    .typeError(typeErrText)
    .required(reqText)
    .positive(positiveErrText)
    .test('is-decimal', VALIDATION_MESSAGE_NUMBER.text, (value) =>
      VALIDATION_MESSAGE_NUMBER.regEx.test(value.toString())
    ),
  taxes: Yup.number()
    .typeError(typeErrText)
    .required(reqText)
    .positive(positiveErrText)
    .test('is-decimal', VALIDATION_MESSAGE_NUMBER.text, (value) =>
      VALIDATION_MESSAGE_NUMBER.regEx.test(value.toString())
    ),
  capital_investments: Yup.number()
    .typeError(typeErrText)
    .required(reqText)
    .positive(positiveErrText)
    .test('is-decimal', VALIDATION_MESSAGE_NUMBER.text, (value) =>
      VALIDATION_MESSAGE_NUMBER.regEx.test(value.toString())
    ),
});
