import React, {
  useCallback,
  useMemo,
  useState
} from "react";
import {
  Form,
  Formik,
  FormikConfig,
  FormikHelpers,
  FormikValues
} from "formik";
import { useLocation, useNavigate } from "react-router";
import {
  Box,
  Button,
  Divider,
  Step,
  StepIconProps,
  StepLabel,
  Typography,
  useMediaQuery
} from "@mui/material";
import { ROUTES_PATHS } from "@/apps/AppRouter/const";
import FormikStep, {
  IFormikStepProps
} from "./components/FormikStep";
import StepControls from "./components/StepControls";

import MobileControls from "./components/MobileControls";
import {
  CircleStepIconRoot,
  ContentWrap,
  Root,
  Stepper,
  StepperWrap
} from "./styles";
import { LOCATION_KEY } from "@/const";

const CircleStepIcon = (props: StepIconProps) => {
  const { active, completed, className, icon } = props;

  return (
    <CircleStepIconRoot ownerState={{ active, completed }}
                        className={className}>
      <Typography variant="caption">
        {icon}
      </Typography>
    </CircleStepIconRoot>
  );
};

export interface IFormikStepper extends FormikConfig<FormikValues> {
  children?: React.ReactNode;
  nextButtonLabel?: string;
  handleClose?: () => void;
}

/** Компонет с формик контекстом для странцы добавления заявки.
 * Рендерит контент в зависимости от шага + валидирует в зависимости от шага */
const FormikStepper = ({
  children,
  nextButtonLabel,
  handleClose,
  ...props
}: IFormikStepper) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  /** Компоненты шагов*/
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<IFormikStepProps>[];
  /** Текущий компонент шага*/
  const currentChild = childrenArray[step];

  const maxSteps = useMemo(() => childrenArray.length, [childrenArray.length]);
  const matches = useMediaQuery("(min-width:1024px)");
  const isLastStep = useMemo(
    () => step === childrenArray.length - 1,
    [childrenArray.length, step]
  );

  const handleBack = useCallback((): void => {
    setStep((s) => s - 1);
  }, []);

  /** Метод по шагам - если шаг последний то сабмит формы если нет то дерагем формы для валидации шага
   *  */
  const handleSubmit = (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => {
    if (currentChild.props.handleSubmit) {
      currentChild.props.handleSubmit(values, helpers, setStep);
    } else {
      if (isLastStep) {
        props.onSubmit(values, helpers);
      } else {
        helpers.setSubmitting(false);
        setStep((s) => s + 1);
        // helpers.setTouched({});
      }
    }
  };

  const onClose = () => {
    if (handleClose) {
      handleClose();
    } else {
      if (location.key !== LOCATION_KEY.DEFAULT) {
        navigate(-1);
      } else
        navigate({
          pathname: ROUTES_PATHS.statements
        });
    }
  };

  const renderStepper = () => {
    return matches ? (
      <StepperWrap>
        <Box flexGrow={1} p={3}>
          <Stepper activeStep={step} orientation="vertical">
            {childrenArray.map((child, index) => (
              <Step
                key={child.props.label}
                completed={step > index}

              >
                <StepLabel
                  StepIconComponent={CircleStepIcon}>{child.props.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Divider/>
        <Box p={3} pb={4} sx={{
          display: "flex",
          justifyContent: "center"
        }}>
          <Button
            variant="contained"
            onClick={onClose}
          >
            Закрыть
          </Button>
        </Box>
      </StepperWrap>
    ) : (
      <MobileControls
        steps={maxSteps}
        activeStep={step}
        handleBack={handleBack}
        isLastStep={isLastStep}
      />
    );
  };

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form style={{ width: "100%", height: "100%" }}
              noValidate>
          <Root>
            {renderStepper()}
            <ContentWrap>
              <Box p={3}>
                {currentChild}
                {matches ? (
                  <StepControls
                    nextButtonLabel={nextButtonLabel}
                    step={step}
                    handleBack={handleBack}
                    isLastStep={isLastStep}
                  />
                ) : null}
              </Box>
            </ContentWrap>
          </Root>
        </Form>
      )}
    </Formik>
  );
};

FormikStepper.FormikStep = FormikStep;
export default FormikStepper;
