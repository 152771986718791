import React, { useState } from "react";
import { AddEntity } from "@/apps/Modals/ModalContent";
import { useStores } from "@/hooks";
import { PAGE_SIZE } from "@/const";
import { ICultivar } from "@/api/interfaces/responses";
import { FIELDS_NAME } from "@/pages/AddStatement/const";
import { useParams } from "react-router-dom";
import useToastContent, { TOAST_CONTENT_TYPE } from "@/hooks/useToastContent";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

export interface IProps {
  handleClose: () => void;
  modalProps: {
    initialValue?: ICultivar;
    onSubmit?: (value: ICultivar) => void;
  };
}

/** Модалка с радио списком культур для добавления в заявку */

const AddCultivarToStatement: React.FC<IProps> = ({
  handleClose,
  modalProps
}) => {
  const { id } = useParams();
  const { api, toastStore, userStore } = useStores();
  const toastContent = useToastContent(TOAST_CONTENT_TYPE.SERVER_ERROR);
  const [isLoading, setIsLoading] = useState(false);
  const handleAccept = (value: ICultivar) => {
    setIsLoading(true);
    api.statement
      .patchStatementById(Number(id), { [FIELDS_NAME.CULTIVAR]: value.id })
      .then(() => {
        handleClose();
      })
      .catch(() => {
        toastStore.createToast(toastContent);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetcher = (args) => {
    const { page, search } = args;
    return api.regulatoryInfo.getCultivarList({
      page,
      page_size: PAGE_SIZE,
      search
    });
  };
  const getLabel = (value: ICultivar): string => value.name;

  const isCultivar = userStore.isPermission(
    PERMISSIONS.regulatory_infoСultivar,
    PERMISSION_CRUD.add
  );

  const props = {
    ...modalProps,
    key: "GET_CULTIVAR",
    title: "Культура",
    isDisabledBtnAdd: isCultivar,
    tooltipText: "У вас недостаточно прав для добавления культуры",
    handleAccept,
    getLabel: getLabel,
    fetcher,
    disabled: isLoading
  };

  return <AddEntity<ICultivar> modalProps={props} handleClose={handleClose} />;
};

export default AddCultivarToStatement;
