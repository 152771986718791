import React, { Fragment } from "react";
import {
  TableCell,
  TableHead as TableRowMui,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";

import { IColumn, TOrder } from "../interfaces";

import { SORT } from "@/const";
import { useMatches } from "@/apps/Respons";

/**
 * Интерфейс свойств компонента TableHead.
 * @interface
 */
interface IProps<TColums> {
  /**
   * Обработчик события сортировки.
   */
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => void;
  /**
   * Свойство, по которому производится сортировка.
   */
  orderBy: string[];
  /**
   * Общее количество строк в таблице.
   */
  rowCount: number;
  /**
   * Массив колонок таблицы.
   */
  columns: IColumn<TColums>[];
}

/**
 * Компонент, представляющий заголовок таблицы.
 * @param {EnhancedTableProps} props - Свойства компонента.
 * @returns {JSX.Element}
 */
function TableHead<TColums extends string>({
  orderBy,
  columns,
  onRequestSort,
}: IProps<TColums>): JSX.Element {
  const theme = useTheme();
  const { matches } = useMatches();

  if (matches?.small) {
    return <></>;
  }

  return (
    <TableRowMui>
      <TableRow>
        {columns.map((headCell, index) => {
          let sortValue: TOrder | undefined = undefined;
          let isActive = false;
          if (orderBy.includes(headCell.key)) {
            isActive = true;
            sortValue = SORT.asc;
          }
          if (orderBy.includes(`-${headCell.key}`)) {
            isActive = true;
            sortValue = SORT.desc;
          }
          return (
            <Fragment key={headCell.key as string}>
              {headCell.contentHeader !== "" ? (
                headCell.isSorting === false ? (
                  <TableCell key={index}>
                    {headCell.contentHeader}
                  </TableCell>
                ) : (
                  <TableCell
                    sx={headCell.headerCellProps?.styles?.(
                      theme,
                    )}
                  >
                    <TableSortLabel
                      onClick={(e) =>
                        onRequestSort(e, headCell.key)
                      }
                      active={isActive}
                      direction={sortValue}
                    >
                      {headCell.contentHeader}
                    </TableSortLabel>
                  </TableCell>
                )
              ) : (
                <TableCell key={index} />
              )}
            </Fragment>
          );
        })}
      </TableRow>
    </TableRowMui>
  );
}

export default TableHead;
