import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { DeleteIcon } from "@/components/icons";
import theme from "@/theme";
import {
  STATEMENT_STATUS,
  STATEMENT_STATUS_COLORS,
} from "@/pages/Statement/const";
import { CELL_TYPES } from "./const";
import {
  errorToString,
  isNull,
  stringWithSuffix,
} from "@/utils/helpers";
import {
  IDistributionStatement,
  IOffBudgetWageFund,
  ISeedArrival,
  ITrial,
} from "@/api/interfaces/responses";
import { DISTRIBUTION_TYPES } from "@/apps/RightSidebar/const";
import TrialItemView
  from "@/apps/RightSidebar/components/TrialItem/components/TrialItemView";
import dayjs from "dayjs";

const DeleteButton = styled(Button)(({ theme }) => ({
  padding: "5px 15px",
  borderRadius: 4,
  border: "1px solid",
  color: theme.palette.blackAndWhite.white,
  borderColor: theme.palette.red.main,
}));

type TStatusStyleProp = {
  textcolor: string;
  backgroundColor: string;
};

const Status = styled(Box)<TStatusStyleProp>(
  ({ textcolor, backgroundColor }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    padding: "3px 5px",
    color: textcolor,
    backgroundColor: backgroundColor,
    borderRadius: "100px",
    minWidth: "100px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  }),
);

/**
 * Interface for the OkOrNotDelete component's props.
 */
interface IOkOrNotDelete {
  handleClose: () => void;
  modalProps: {
    type: keyof typeof CELL_TYPES;
    status: keyof typeof STATEMENT_STATUS;
    trialItem: ITrial;
    distribution: IDistributionStatement;
    id: string;
    statement_type: string;
    planFact: "plan" | "fact";
    entityName?: string;
    handleDelete: (
      RS: () => void,
      RJ: (E: any) => void,
    ) => void;
    wageFund?: IOffBudgetWageFund;
    seedsArrival: ISeedArrival;
  };
}

/**
 * @param {IOkOrNotDelete} props - Component props.
 * @returns {React.FC<IOkOrNotDelete>} - Rendered component.
 */
const OkOrNotDelete: React.FC<IOkOrNotDelete> = ({
  handleClose,
  modalProps,
}) => {
  const {
    type,
    status,
    id,
    statement_type,
    distribution,
    wageFund,
    planFact,
    entityName = "",
    handleDelete,
  } = modalProps;

  
  
  const seedsArrival = modalProps?.seedsArrival
  const trialItem = modalProps?.trialItem
  const dateFormat = dayjs(
    distribution?.delivery_time_up_to,
  ).format("DD.MM.YYYY");
  const addresseeDistribution = !isNull(distribution?.plot)
    ? distribution?.plot?.full_name
    : !isNull(distribution.organization)
      ? distribution?.organization.name
      : "";
  const [error, setError] = useState<string | boolean>(
    false,
  );
  const [isDelete, setIsDelete] = useState(false);
  const handleCancel = () => {
    handleClose();
  };
  const handleClickDelete = () => {
    setIsDelete(true);
    const resolve = () => {
      handleClose();
    };
    const reject = (err) => {
      setIsDelete(false);
      if (err?.response?.data)
        setError(errorToString(err?.response?.data));
    };
    handleDelete(resolve, reject);
  };

  const backgroundColor =
    STATEMENT_STATUS_COLORS[status]?.background;
  const textColor = STATEMENT_STATUS_COLORS[status]?.text;

  const VARIAN_CELL = {
    [CELL_TYPES.statement]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить заявку?
          </Typography>
        </Box>
        <Stack
          direction={"row"}
          gap={1}
          p={"16px 6px"}
          alignItems={"center"}
          sx={{
            border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
            margin: "20px 24px",
            borderRadius: "6px",
          }}
        >
          <Status
            textcolor={textColor}
            backgroundColor={backgroundColor}
          >
            {STATEMENT_STATUS[status]}
          </Status>
          <Typography
            variant="h4"
            ml={"17px"}
            color={theme.palette.blackAndWhite.gray}
          >
            {id}
          </Typography>
          <Typography
            variant="h5"
            color={theme.palette.blackAndWhite.black}
          >
            {statement_type}
          </Typography>
        </Stack>
        {error ? (
          <Box sx={{ padding: "0px 24px 24px 24px" }}>
            <Alert variant="outlined" severity="error">
              {error}
            </Alert>
          </Box>
        ) : null}
        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>
          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.dutyStatement]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить пошлину?
          </Typography>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.trial]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить испытание?
          </Typography>
        </Box>

        <Box p={3}>
          <TrialItemView
            trialItem={trialItem}
            isActive={false}
          />
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.remark]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить замечание?
          </Typography>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.universal]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить {entityName}?
          </Typography>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
            data-qa="confirmDelete"
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.offbudget]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить{" "}
            {planFact === "plan" ? "план" : "факт"}?
          </Typography>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.cultivar]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить культуру?
          </Typography>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.agriculturalWorkLocation]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить участок?
          </Typography>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.distribution]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить разнарядку?
          </Typography>
        </Box>
        <Stack
          padding={"0 24px 24px 24px"}
          direction={"column"}
        >
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              borderRadius: "4px",
              padding: "10px 15px",
              border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
            }}
          >
            <Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={1}
              >
                {
                  DISTRIBUTION_TYPES[distribution?.type]
                    ?.icon
                }
                <Typography
                  color={
                    DISTRIBUTION_TYPES[distribution?.type]
                      ?.textColor
                  }
                  variant="h6"
                >
                  {
                    DISTRIBUTION_TYPES[distribution?.type]
                      ?.label
                  }
                </Typography>
              </Stack>
            </Stack>

            <Stack mt={1}>
              <Typography>
                {`${distribution?.material_type?.name ?? ""} ${distribution?.quantity ?? ""} ${distribution?.unit?.full_name ?? ""}`}
              </Typography>

              <Typography
                mb={1.5}
                color={theme.palette.blackAndWhite.gray}
              >
                {`${distribution?.address?.one_line ?? ""}`}
              </Typography>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                gap={1}
              >
                <Typography mb={1.5}>
                  <Typography
                    component={"span"}
                    fontSize={14}
                    color={theme.palette.blackAndWhite.gray}
                  >
                    {`Куда: `}
                  </Typography>

                  <Typography
                    fontSize={16}
                    component={"span"}
                  >
                    {addresseeDistribution}
                  </Typography>
                </Typography>

                <Typography
                  display={"flex"}
                  flexWrap={"nowrap"}
                  mb={1.5}
                >
                  <Typography
                    component={"span"}
                    lineHeight={1.5}
                    color={theme.palette.blackAndWhite.gray}
                  >
                    {`До:`}
                  </Typography>

                  <Typography
                    component={"span"}
                    fontSize={16}
                    fontWeight={400}
                    ml={1}
                  >
                    {dateFormat}
                  </Typography>
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.wageFund]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить расходы?
          </Typography>
        </Box>

        <Box p={3}>
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "100%",
              height: "fit-content",
              padding: "18px 15px",
              border: `1px solid ${theme.palette.blackAndWhite.stroke}`,
            }}
          >
            <Box width="150px">
              <Typography variant="body2">
                {wageFund?.post?.name}
              </Typography>
            </Box>
            <Box width="150px">
              <Typography variant="body2">
                {wageFund?.staff_number}
              </Typography>
            </Box>
            <Box width="150px">
              <Typography
                variant="body2"
                color={theme.palette.blackAndWhite.gray}
              >
                {stringWithSuffix(
                  wageFund?.wage_fund_month ?? "",
                  "руб",
                  "-",
                )}
              </Typography>
            </Box>
            <Box width="150px">
              <Typography
                variant="body2"
                color={theme.palette.blue.main}
              >
                {stringWithSuffix(
                  wageFund?.wage_fund_year ?? "",
                  "руб",
                  "-",
                )}
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.handbook]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить запись из
            справочника?
          </Typography>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.plot]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить ГСУ?
          </Typography>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
    [CELL_TYPES.seedsArrival]: (
      <Box>
        <Box p={3}>
          <Typography
            variant="h2"
            sx={{ paddingRight: "30px" }}
          >
            Вы действительно хотите удалить {seedsArrival?.cultivar.name} {seedsArrival?.variety.name}?
          </Typography>
        </Box>

        <Divider />

        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color={"blue"}
            onClick={handleCancel}
          >
            Отмена
          </Button>

          <DeleteButton
            color={"red"}
            variant={"contained"}
            onClick={handleClickDelete}
            startIcon={<DeleteIcon />}
            disabled={isDelete}
          >
            Удалить
          </DeleteButton>
        </Box>
      </Box>
    ),
  };

  return VARIAN_CELL[type] ?? null;
};

export default OkOrNotDelete;
