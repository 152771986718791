import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import theme from "@/theme";
/**
 * Interface for the SuccessReworkOffbudget component's props.
 */
interface ISuccessRejectStatement {
  handleClose: () => void;
}

/**
 * SuccessReworkOffbudget component.
 * @param {ISuccessRejectStatement} props - Component props.
 * @returns {React.FC<ISuccessRejectStatement>} - Rendered component.
 */
const SuccessReworkOffbudget: React.FC<ISuccessRejectStatement> = ({
  handleClose
}) => {
  return (
    <Box>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        p={"24px 24px 0px 24px"}
      >
        <CheckCircleIcon
          sx={{
            width: "100px",
            height: "100px",
            color: theme.palette.backgroundColors.darkGray
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        p={"0px 24px"}
      >
        <Typography variant="h2">План отправлен на доработку!</Typography>
      </Box>
      <Box alignItems={"center"} p={3} display="flex" justifyContent="center">
        <Button
          onClick={handleClose}
          type="submit"
          variant="contained"
          color="primary"
          size="medium"
        >
          Продолжить
        </Button>
      </Box>
    </Box>
  );
};

export default SuccessReworkOffbudget;
