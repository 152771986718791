import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import theme from "@/theme";
import { Stack } from "@mui/material";
import { useQuery } from "@/hooks";

/**
 * Группа табов для сайдбара Внебюджет
 *
 * @param {Object} tabsData - данные для отрисовки
 * @param {string} tabType - тип таба
 * @param {string} baseTabValue - базовое значение таба
 * @return {JSX.Element}
 */

interface ITabData {
  name: string;
  content: React.JSX.Element;
  keyqs: string;
}

interface IProps {
  tabsData?: ITabData[];
  tabType: string;
  baseTabValue: string;
  variant?: TabsProps["variant"];
  centered?: TabsProps["centered"];
}

const GroupTabsBranch: React.FC<IProps> = ({
  tabsData,
  tabType,
  baseTabValue,
  variant = "scrollable",
  centered = false,
}) => {
  const query = useQuery<any>();
  const [, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<string>(baseTabValue);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: React.SetStateAction<string>,
  ) => {
    setValue(newValue);
    const parsedQuery = qs.parse(query);

    // удаляем параметр для внутреннего таба, если кликаем на внешний
    if (
      parsedQuery.tab &&
      parsedQuery.inner_tab &&
      newValue !== parsedQuery.tab
    ) {
      delete parsedQuery.inner_tab;
    }

    const newQs = {
      ...parsedQuery,
      [tabType]: newValue,
    };

    setSearchParams(qs.stringify(newQs));
  };

  useEffect(() => {
    const checkCurrentTab = qs.parse(query) as any;
    const tabKeys = tabsData?.map((tab) => tab.keyqs);

    if (
      tabType in checkCurrentTab &&
      tabKeys?.includes(checkCurrentTab[tabType])
    ) {
      setValue(checkCurrentTab[tabType]);
    }
  }, [query]);

  return (
    <Stack
      direction={"column"}
      flexWrap={"nowrap"}
      sx={{
        width: "100%",
        height: "inherit",
      }}
    >
      <Tabs
        variant={variant}
        value={value}
        onChange={handleChange}
        centered={centered}
      >
        {tabsData?.map(
          (
            tab: {
              name: string;
              keyqs: string;
            },
            index: React.Key | null | undefined,
          ) => (
            <Tab
              key={index}
              label={tab.name}
              value={tab.keyqs}
              aria-selected={
                value === tab.keyqs ? "true" : "false"
              }
              sx={{
                borderBottom: `1px solid ${theme.palette.blackAndWhite.black12}`,
              }}
            />
          ),
        )}
      </Tabs>
      {tabsData?.map(
        (
          tab: { content: any; keyqs: string },
          index: React.Key | null | undefined,
        ) => (
          <TabPanel
            key={index}
            keyqs={tab.keyqs}
            value={value}
          >
            {tab.content}
          </TabPanel>
        ),
      )}
    </Stack>
  );
};

const TabPanel = ({ children, keyqs, value }) => {
  if (keyqs !== value) return null;

  return (
    <Stack
      hidden={keyqs !== value}
      direction={"column"}
      flexWrap={"nowrap"}
      sx={{
        position: "relative",
        height: "inherit",
      }}
    >
      {keyqs === value && (
        <Box
          sx={{
            position: "relative",
            height: "inherit",
          }}
        >
          {children}
        </Box>
      )}
    </Stack>
  );
};

export default GroupTabsBranch;
