import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import { useStores } from "@/hooks";
import { IProps, TForm, FIELD_NAME } from "../const";

import {
    FormControl,
    FormHelperText,
    FormLabel,
    Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { FILE_AND_IMAGE_ACCEPT, FILE_AND_IMAGE_SUBTITLE, IFile } from "@/components/FileDropzone/const";

import FileDropzone from "@/components/FileDropzone";

/**
 * Форма создания/редактирования приказа
 * @param modalProps пропсы
 * @param modalProps.initialValues - начальные значения
 * @param modalProps.key - ключ модалки
 * */

const SecondStepForm: React.FC<IProps> = ({ modalProps }) => {
    const { api } = useStores();
    const [isFileAdd, setIsFileAdd] = useState(true);
    const form = useFormikContext<TForm>();

    const handleChangeFiles = (files: IFile[]) => {
        if (files.length) {
            setIsFileAdd(true);
        }
        void form.setFieldValue(FIELD_NAME.FILE_LIST, files);
    };

    const deleteMutation = (fileId: number) => {
        let promise;
        if (modalProps.agreementId) {
            promise = api.statement.deleteFileAgreement(modalProps.agreementId, fileId);
        }
        return promise;
    };

    return (
        <>
                <Stack direction={"column"} gap={2} >
                    <Field name={FIELD_NAME.FILE_LIST}>
                        {({ field, meta }) => (
                            <FormControl>
                                <FormLabel>
                                    <Typography variant='p14Medium'>Документы</Typography>
                                </FormLabel>
                                <FileDropzone
                                    name={field.name}
                                    value={field.value}
                                    isFileAdd={isFileAdd}
                                    setValue={handleChangeFiles}
                                    deleteMutation={deleteMutation}
                                    accept={FILE_AND_IMAGE_ACCEPT}
                                    subtitle={FILE_AND_IMAGE_SUBTITLE}
                                />
                                <FormHelperText error={meta.touched && !!meta.error}>
                                    {meta.touched && meta.error}
                                </FormHelperText>
                            </FormControl>
                        )}
                    </Field>
                </Stack>
        </>
    );
};

export default SecondStepForm;
