import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { KeyedMutator } from "swr";
import { Stack, Typography, } from "@mui/material";
import FileDropzone from "@/components/FileDropzone";
import {
  PLAN_FIELDS_NAME,
  STEP_TITLES
} from "@/pages/AddPlan/const";
import { IFile } from "@/components/FileDropzone/const";
import { useStores } from "@/hooks";
import { IOffBudget } from "@/api/interfaces/requests";

interface IProps {
  mutate: KeyedMutator<IOffBudget>
}
const DocumentFormStep: React.FC<IProps> = ({mutate}) => {
  const { api } = useStores();
  const { id } = useParams();
  const formik = useFormikContext<IOffBudget>();
  const fetcherFile = (fd: FormData) =>
    api.offBudget.addOffBudgetFileById(id as string, fd);

  const deleteMutation = (fileId: number) =>
    api.offBudget.deleteOffBudgetFile(id as string, fileId);

  const handleChangeFiles = (files: IFile[]) => {
    void formik.setFieldValue(PLAN_FIELDS_NAME.FILE_LIST, files);
  };

  useEffect(() => () => {
    void mutate()
  }, []);

  return (
    <Stack spacing={3}>
      <Typography
        variant="h3">{STEP_TITLES.PLAN}</Typography>
      <Stack direction="column" spacing="20px">
        {id ? (
          <FileDropzone
            name={PLAN_FIELDS_NAME.FILE_LIST}
            value={formik.values[PLAN_FIELDS_NAME.FILE_LIST]}
            fetcher={fetcherFile}
            deleteMutation={deleteMutation}
          />
        ) : (
          <FileDropzone
            name={PLAN_FIELDS_NAME.FILE_LIST}
            value={formik.values[PLAN_FIELDS_NAME.FILE_LIST]}
            setValue={handleChangeFiles}
          />
        )}
      </Stack>
    </Stack>
  );
};
export default DocumentFormStep;
