import React, { Fragment } from 'react'
import { useStores } from '@/hooks';
import { Form, useFormikContext } from 'formik';
import { Box, Button, Divider, Stack,Typography } from '@mui/material';
import { IProps, TForm, VEGETATION_STAGE_FIELD_NAME } from '../const';
import CheckIcon from "@mui/icons-material/Check";
import theme from '@/theme';
import FastRegexTextField from '@/components/FastRegexTextField';
import { CultivarAutocomplete } from '@/components/Autocompletes';

/**
 * Форма создания/редактирования фазы вегетации
 * @param modalProps пропсы
 * @param modalProps.initialValue - начальные значения
 * */

export const VegetationStageForm: React.FC<IProps> = ({ modalProps }) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<TForm>();

  const title = modalProps.vegetationStageId
    ? "Редактировать запись"
    : "Добавить запись";

  return (
    <Fragment>
      <Box p={3}>
        <Typography fontSize={12} color={theme.palette.blackAndWhite.gray}>
          Фаза вегетации
        </Typography>
        <Typography variant='h2'>{title}</Typography>
      </Box>
      <Divider />
      <Form noValidate>

        <Stack direction={"column"} gap={2} p={2}>
          <CultivarAutocomplete
            name={VEGETATION_STAGE_FIELD_NAME.CULTIVAR}
            required
          />
          <FastRegexTextField
            name={VEGETATION_STAGE_FIELD_NAME.NAME}
            label='Название'
            fullWidth
            size="small"
            required
          />
          <FastRegexTextField
            name={VEGETATION_STAGE_FIELD_NAME.DESCRIPTION}
            label='Описание'
            fullWidth
            size="small"
            multiline
            minRows={4}
            maxRows={10}
          />
        </Stack>

        <Divider />
        <Box p={3} display='flex' justifyContent='space-between'>
          <Button color='red'
            onClick={() => modalStore.close()}
          >
            отмена
          </Button>
          <Button
            type='submit'
            disabled={formik.isSubmitting}
            variant='contained'
            color='primary'
            startIcon={<CheckIcon />}
          >
            Сохранить
          </Button>
        </Box>
      </Form>
    </Fragment>
  )
}
