import React from "react";
import { FastField, useFormikContext } from "formik";
import {
  DatePicker,
  DatePickerProps
} from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

interface IProps extends DatePickerProps<Dayjs> {
  name: string;
  required?: boolean;
  fullWidth?: boolean;
  handleChange?: (payload: number) => void
}

const YearDatePicker: React.FC<IProps> = ({
  name,
  required = false,
  fullWidth = false,
  handleChange,
  ...props
}) => {
  const formik = useFormikContext();

  if (!formik) {
    throw new Error(
      "YearDatePicker has to be used within <Formik>"
    );
  }

  const onChange = (payload) => {
    const value = payload ? payload.$y : '';
    if (!isNaN(value) && value.toString().length === 4) {
      handleChange ? handleChange(value) : formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(name, '');
    }
  }

  return (
    <FastField
      name={name}>
      {({ field, meta }) => (
        <DatePicker
          name={field.name}
          value={field.value ? dayjs().year(field.value) : null}
          label="Год посева"
          openTo="year"
          views={["year"]}
          slotProps={{
            textField: {
              size: "small",
              required: required,
              error: meta.touched && !!meta.error,
              helperText: meta.touched && meta.error,
              onBlur: field.onBlur,
              fullWidth: fullWidth
            }
          }}
          {...props}
          onChange={onChange}
        />
      )}
    </FastField>
  );
};

export default YearDatePicker;