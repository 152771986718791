import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import { SWR_KEYS } from "@/const";
import { VegetationCausesForm } from "./components/VegetationCausesFoem";
import { IProps, mapFormToValue, TForm, validationSchema, VEGETATION_CAUSES_FIELD_NAME } from "./const";

/**
 * Модалка с формой создания/редактирования
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const VegetationCauses: React.FC<IProps> = ({ handleClose, modalProps }) => {
  const baseInititialValues = {
    [VEGETATION_CAUSES_FIELD_NAME.CODE]: null,
    [VEGETATION_CAUSES_FIELD_NAME.NAME]: null,
    [VEGETATION_CAUSES_FIELD_NAME.TYPE_OF_REASON]: 'failure',
  }

  const { api, toastStore, swrStore } = useStores();
  const { vegetationCausesId, initialValues } = modalProps;

  const mutateDetail = vegetationCausesId ?
    swrStore.mutators[SWR_KEYS.getVegetationCausesById(vegetationCausesId)] : null

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);

    const promise = vegetationCausesId
      ? api.regulatoryInfo.patchVegetationCauses(
        vegetationCausesId,
        mapFormToValue(values),
      )
      : api.regulatoryInfo.postVegetationCauses(mapFormToValue(values));

    promise
      .then(() => {
        mutateDetail && mutateDetail()
        handleClose();
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors.non_field_errors) {
          const errorMessage = errorToString(error);
          helpers.setFieldError('non_field_errors', errorMessage)
        }
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        mutateDetail && mutateDetail();
        swrStore.mutators[SWR_KEYS.getVegetationCausesList()]();
      });
  };

  const values = initialValues
    ? {
      ...baseInititialValues,
      ...initialValues,
    }
    : baseInititialValues;

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <VegetationCausesForm modalProps={modalProps} handleClose={handleClose} />
      )}
    />
  );
};

export default VegetationCauses;


