import React from "react";
import FileDropzone from "@/components/FileDropzone";
import { Box, Divider, Stack } from "@mui/material";
import FormAccordion from "@/components/Accordions/FormAccordion";
import { useTheme } from "@mui/material/styles";
import { accordionStyles } from "./styles";
import Typography from "@mui/material/Typography";
import { DOCUMENT_FIELDS, STEP_LABELS } from "@/pages/AddStatement/const";
import { useParams } from "react-router-dom";
import { useStores } from "@/hooks";
import { useFormikContext } from "formik";
import { IStatement } from "@/api/interfaces/responses";
import { serializeStatementFile } from "@/pages/AddStatement/utils";
import { PERMISSIONS, PERMISSION_CRUD } from "@/premissions";

const DocumentsStep = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { api, userStore } = useStores();

  const { values } = useFormikContext<IStatement>();

  const fileMap = values.files.reduce((acc, el) => {
    if (el.type in acc) {
      acc[el.type].push(serializeStatementFile(el));
    } else {
      acc[el.type] = [serializeStatementFile(el)];
    }
    return acc;
  }, {});
  const fetcher = (fd: FormData) =>
    api.statement.addStatementFileById(id as string, fd);
  const deleteMutation = (fileId: number) =>
    api.statement.deleteStatementFile(id as string, fileId);

  const isFileAdd = userStore.isPermission(
    PERMISSIONS.regulatory_infoFile,
    PERMISSION_CRUD.add
  );

  const isFileDelete = userStore.isPermission(
    PERMISSIONS.regulatory_infoFile,
    PERMISSION_CRUD.delete
  );
  
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h3">{STEP_LABELS.DOCUMENTS}</Typography>
      {DOCUMENT_FIELDS.map((field) => {
        const files = fileMap[field.name] || [];
        return (
          <FormAccordion
            key={field.name}
            label={field.title}
            sx={accordionStyles(theme)}
          >
            <Box mb={1}>
              <Divider />
            </Box>
            <FileDropzone
              fetcher={fetcher}
              name={field.name}
              value={files}
              isFileAdd={isFileAdd}
              isFileDelete={isFileDelete}
              deleteMutation={deleteMutation}
            />
          </FormAccordion>
        );
      })}
    </Stack>
  );
};

export default DocumentsStep;
