import React from "react";
import { Field, useFormikContext } from "formik";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import { StyledChip } from "@/apps/Modals/ModalContent/CreateVariety/styles";
import AddChip from "@/components/Chips/AddChip";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { useStores } from "@/hooks";
import { resInterface } from "@/api/interfaces";
import { FIELD_NAME, IProps, TForm } from "../../../types";

const SubjectFormFirstStepIndividual: React.FC<IProps> = ({
  modalProps,
}) => {
  const form = useFormikContext<TForm>();
  const { modalStore } = useStores();

  const handleAddAddress = () => {
    modalStore.open(VARIANT_MODAL.ADD_ADDRESS, {
      onSubmit: handleSelectAddress,
      onClose: handleCloseAddress,
    });
  };

  const handleSelectAddress = (
    payload: resInterface.IAddress,
  ) => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...form.values,
        [FIELD_NAME.ADDRESS]: {
          ...payload,
          name: payload.one_line,
        },
      },
    });
  };

  const handleCloseAddress = () => {
    modalStore.setModalPropsByKey(modalProps.key, {
      initialValues: {
        ...form.values
      },
    });
  };

  const handleDeleteAddress = () => {
    form.setFieldValue(FIELD_NAME.ADDRESS, null);
  };

  return (
    <>
      <Field name={FIELD_NAME.FIRST_NAME}>
        {({ field, meta }) => (
          <FormControl>
            <TextField
              {...field}
              label="Имя"
              fullWidth
              size="small"
              required
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values[FIELD_NAME.FIRST_NAME]}
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={FIELD_NAME.LAST_NAME}>
        {({ field, meta }) => (
          <FormControl>
            <TextField
              {...field}
              label="Фамилия"
              fullWidth
              size="small"
              required
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values[FIELD_NAME.LAST_NAME]}
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={FIELD_NAME.SECOND_NAME}>
        {({ field, meta }) => (
          <FormControl>
            <TextField
              {...field}
              label="Отчество"
              fullWidth
              size="small"
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={form.values[FIELD_NAME.SECOND_NAME]}
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Grid container wrap="nowrap" gap="20px">
        <Grid item display="flex" xs={6}>
          <Field name={FIELD_NAME.INN}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label="ИНН"
                  fullWidth
                  size="small"
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[FIELD_NAME.INN]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>
        <Grid item display="flex" xs={6}>
          <Field name={FIELD_NAME.SNILS}>
            {({ field, meta }) => (
              <FormControl>
                <TextField
                  {...field}
                  label="СНИЛС"
                  fullWidth
                  size="small"
                  onChange={form.handleChange}
                  onBlur={field.handleBlur}
                  value={form.values[FIELD_NAME.SNILS]}
                  disabled={meta.isSubmitting}
                />
                <FormHelperText
                  error={meta.touched && !!meta.error}
                >
                  {meta.touched && meta.error}
                </FormHelperText>
              </FormControl>
            )}
          </Field>
        </Grid>
      </Grid>
      <Field name={FIELD_NAME.ADDRESS}>
        {({ field, meta }) => (
          <FormControl required>
            <FormLabel>Адрес</FormLabel>
            <Box>
              {field.value ? (
                <StyledChip
                  size="small"
                  variant="outlined"
                  label={field.value.name}
                  onDelete={handleDeleteAddress}
                />
              ) : (
                <AddChip onClick={handleAddAddress} />
              )}
            </Box>
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
    </>
  );
};

export default SubjectFormFirstStepIndividual;
