export const CELL_TYPES = {
  statement: "statement",
  dutyStatement: "dutyStatement",
  trial: "trial",
  remark: "remark",
  offbudget: "offbudget",
  distribution: "distribution",
  cultivar: "cultivar",
  universal: "universal",
  agriculturalWorkLocation: "agriculturalWorkLocation",
  wageFund: "wageFund",
  handbook: "handbook",
  plot: "plot",
  seedsArrival: 'seedsArrival',
};
