import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { SearchFilterProps } from "@/components/Autocomplete";
import { useInfiniteScroll, useStores } from "@/hooks";
import { SWR_KEYS } from "@/const";
import { IOrderList } from "@/api/interfaces/requests";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import dayjs from "dayjs";

interface IProps
  extends Partial<SearchFilterProps<IOrderList>> {
  name: string;
  q?: IOrderList;
  required?: boolean;
  initialSearch?: string;
}

/**
 * Селект со списком приказов
 * @param name - имя
 * @param q - квери параметры для запроса
 * @param handleChange - калбек при выборе
 * @param initialSearch - начальное значение поиска
 */

const OrderSelect: React.FC<IProps> = ({
  name,
  q = {},
  required = false,
  ...props
}) => {
  const formik = useFormikContext();
  const { api } = useStores();

  const swrKeyOrders = SWR_KEYS.getOrderList();

  const getParametersOrders = (index: number) => ({
    _key: swrKeyOrders,
    page: index + 1,
    ...q,
  });

  const fetcher = (args) => {
    const { page, search, order_type } = args;
    return api.regulatoryInfo.getOrderList({
      page,
      search,
      order_type,
    });
  };

  const {
    data: dataOrders,
    handleScroll: handleScrollOrders,
    mutate: mutateOrders,
  } = useInfiniteScroll(getParametersOrders, fetcher, {
    revalidateFirstPage: false,
  });

  const renderOrder = dataOrders.map((el, idx) => (
    <MenuItem key={idx} value={el.id}>
      {`${el.number} - ${dayjs(el.order_date).format(
        "DD.MM.YYYY",
      )}`}
    </MenuItem>
  ));

  useEffect(() => {
    mutateOrders();
  }, []);

  const onChange = (e) => {
    formik.setFieldValue(name, e.target.value);
  };

  if (!formik) {
    throw new Error(
      "OrderSelect has to be used within <Formik>",
    );
  }

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <FormControl
          size="small"
          variant="outlined"
          required={required}
        >
          <InputLabel>Приказ</InputLabel>
          <Select
            {...props}
            onScroll={handleScrollOrders}
            {...field}
            onChange={onChange}
            label="Приказ"
            fullWidth
          >
            {renderOrder}
          </Select>
          <FormHelperText
            error={meta.touched && !!meta.error}
          >
            {meta.touched && meta.error}
          </FormHelperText>
        </FormControl>
      )}
    </Field>
  );
};

export default OrderSelect;
