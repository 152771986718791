import { VIEW_MOD } from "@/apps/Map/const";
import { useStores } from "@/hooks";
import React, { Suspense } from "react";
import BaseModal from "@/apps/Modals";
import Loader from "@/components/Loader";
import { Box, CircularProgress } from "@mui/material";
import { asyncComponent } from "react-async-component";
import Toast from "@/apps/Toast";
import MapLayout from "@/components/MapLayout";
import { SEO_TITLES } from "@/const";

const AsyncMap = asyncComponent({
  resolve: () => import("@/apps/Map"),
  LoadingComponent: () => <CircularProgress />
});

const MapAreaDetail = () => {
  const { mapStore } = useStores();

  mapStore.setViewMod(VIEW_MOD.AREA_DETAIL);

  return (
    <MapLayout title={SEO_TITLES.MAP_AREA}>
      <Suspense
        fallback={
          <Box height={"100vh"}>
            <Loader />
          </Box>
        }
      >
        <AsyncMap />
        <BaseModal />
        <Toast />
      </Suspense>
    </MapLayout>
  );
};

export default React.memo(MapAreaDetail);
