import { SxProps, Theme } from "@mui/material/styles";

export const buttonDotsStyle = (): SxProps => ({
  width: "24px",
  height: "24px",
});

export const cellButtons = (theme: Theme): SxProps => ({
  color: theme.palette.primary.main,
  width: "min-content",
});

export const emptyListStyle = (theme: Theme): SxProps => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(10),

  '& .empty_list_text':{
    fontSize: '20px',
    fontWeight: '500'
  },

  '& .empty_list_icon':{
    width: "60px",
    height: "60px",
    color: theme.palette.blackAndWhite.stroke
  }
});
