import yup from "@/utils/yup";

export const METH_SIGN_FIELD_NAME = {
  IS_MANDATORY: "is_mandatory",
  IS_GROUPED: "is_grouped",
  METHODOLOGY: "methodology",
  INDICATOR: "indicator",
  NON_FIELD_ERRORS: "non_field_errors",
} as const;

const signSchema = yup.array().of(
  yup.object().shape({
    [METH_SIGN_FIELD_NAME.INDICATOR]: yup
      .mixed()
      .required(),
    [METH_SIGN_FIELD_NAME.IS_GROUPED]: yup
      .string()
      .nullable(),
    [METH_SIGN_FIELD_NAME.IS_MANDATORY]: yup
      .string()
      .nullable(),
  }),
);

export const validationSchema = yup.object().shape({
  sign: signSchema,
});
