import React, { useState } from "react";
import {
  FilledTextFieldProps,
  FormControl,
  InputAdornment,
  InputProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from "@mui/material";

import { SearchIcon } from "@/components/icons";
import TextField from "@/components/TextField";

import { searchWrapper } from "./style";
import { useDebounceCallback } from "@/hooks";

type MuiInputProps =
  | FilledTextFieldProps
  | OutlinedTextFieldProps
  | StandardTextFieldProps;

export type InputSearchProps = InputProps &
  MuiInputProps & {
  id?: string;
  label?: string;
  defaultValue?: string 
  onSearch: (value: string) => void;
  width?: string;
};

export const Search: React.FC<InputSearchProps> = ({
  id = "",
  label = "",
  onSearch,
  width = "100%",
  ...props
}) => {
  const [search, setSearch] = useState("");
  const debouncedOnSearch = useDebounceCallback(onSearch, 500);
  const onChange = (e) => {
    setSearch(e.target.value);
    debouncedOnSearch(e.target.value);
  };


  return (
    <FormControl sx={searchWrapper(width)}>
      <TextField
        placeholder="Поиск"
        {...props}
        label={label}
        autoComplete="off"
        id={id}
        value={search}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon/>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
