import React from "react";
import { Alert, Button, Typography, Box } from "@mui/material";

import AuthLayout from "../components/AuthLayout/AuthLayout";

export const WaitingForRole = () => {
  // const handleSignOut = () => {
  //   api.user.signOut();
  //   navigate("/login");
  // };
  return (
    <AuthLayout>
      <Box style={{ width: "100%", marginTop: "24px" }}>
        <Alert
        // message="Доступ в кабинет администратора запрещен"
        // description={
        //   <div>
        //     Для получения доступа к кабинету администратора обратитесь,
        //     пожалуйста, к администратору портала по адресу{" "}
        //     <Typography.Link href="mailto:info@ncrc.ru">
        //       info@ncrc.ru
        //     </Typography.Link>
        //   </div>
        // }
        // type="warning"
        // showIcon
        />
        <Box style={{ padding: "24px 36px 32px" }}>
          <Box>
            <Typography
              style={{ fontSize: "12px", lineHeight: "20px", color: "#8C8C8C" }}
            >
              Данные пользователя
            </Typography>
          </Box>
          <Box style={{ marginTop: "4px" }}>
          </Box>
          <Box style={{ marginTop: "24px" }}>
            <Button
            //onClick={handleSignOut}
            >
              Выйти
            </Button>
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};
