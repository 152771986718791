import * as Yup from 'yup';
import { FIELDS_NAME, MAX_NUMBERS, MAX_NUMBERS_MESSAGE } from './const';

const validationText = 'Обязательное поле';

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.EXPENSE]: Yup.mixed().required(validationText),
  [FIELDS_NAME.SUM]: Yup.number().max(MAX_NUMBERS.MAX, MAX_NUMBERS_MESSAGE).nullable(),
  [FIELDS_NAME.FUNDING_SOURCE]: Yup.mixed().required(),
});
