import { FormikValues } from "formik/dist/types";

export const checkFieldError = (formik: FormikValues, field: string): boolean =>
  formik.errors[field] !== undefined && formik.touched[field] !== undefined;

export const checkTextErrorField = (
  formik: FormikValues,
  field: string
): string | boolean =>
  formik.touched[field] !== undefined &&
  formik.errors[field] !== undefined &&
  formik.errors[field];
