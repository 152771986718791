import React from "react";
import { Form, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import { Box, Button, Divider, } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { FIELDS_NAME, IFormik } from "../const";
import { useStores } from "@/hooks";
import {
  TrialAutocomplete
} from "@/components/Autocompletes";
import FormikAddChip
  from "@/components/Chips/FormikAddChip";
import { VARIANT_MODAL } from "@/apps/Modals/const";
import { IVariety } from "@/api/interfaces/responses";
import { TRIAL_TYPE, HP_TYPE } from "@/const";
import {
  STATEMENT_STATUS_NAMES
} from "@/pages/Statement/const";

interface IProps {
  modalKey: string;
  cultivar: {
    id: number;
    name: string;
  };
}

const AddExpertTrialForm: React.FC<IProps> = ({
  modalKey,
  cultivar
}) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<IFormik>();

  const handleAccept = (variety: IVariety) => {
    const values = {
      ...formik.values,
      [FIELDS_NAME.STANDARD]: variety
    };
    modalStore.setModalPropsByKey(modalKey, {
      initialValues: values
    });
  };

  const handleAdd = () => {
    modalStore.setModalPropsByKey(modalKey, {
      initialValues: formik.values
    });
    modalStore.open(VARIANT_MODAL.SELECT_STANDARD, {
      currentCultivar: {
        id: cultivar.id,
        name: cultivar.name,
      }, onSubmit: handleAccept
    });
  };

  return (
    <Form noValidate>
      <Stack p={3} spacing={2.5}>
        <TrialAutocomplete
          name={FIELDS_NAME.TRIAL} required q={{
          trial_type: TRIAL_TYPE.HP,
          type_hp: HP_TYPE.ACCORDING_EXPERT_ASSESSMENT,
          cultivar: cultivar.id,
          statement_status: STATEMENT_STATUS_NAMES.accepted
        }}/>
        <FormikAddChip name={FIELDS_NAME.STANDARD}
                       handleAdd={handleAdd}
                       label="Стандарт"
        />
      </Stack>
      <Divider/>
      <Box
        p={3}
        display="flex"
        justifyContent="space-between"
      >
        <Button color="red"
                onClick={() => modalStore.close()}>
          отмена
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<CheckIcon/>}
          disabled={formik.isSubmitting}
        >
          Сохранить
        </Button>
      </Box>
    </Form>
  );
};

export default AddExpertTrialForm;
