import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikValues } from "formik/dist/types";
import { useStores } from "@/hooks";
import { IProps, TForm, validationSchema } from "./const";
import { AxiosError } from "axios";
import { errorToString } from "@/utils/helpers";
import { TOAST_TYPES } from "@/apps/Toast";
import MaterialTypeForm from "./components/MaterialTypeForm";
import { SWR_KEYS } from "@/const";

/**
 * Модалка с формой создания/редактирования должностей
 * @param initialValues - начальные значения
 * @param onSubmit - калбек после успешного запроса
 * */
const MaterialType: React.FC<IProps> = ({
  handleClose,
  modalProps,
}) => {
  const initialValues = Object.assign(
    {
      name: null,
    },
    modalProps?.initialValues,
  );

  const { api, toastStore, swrStore } = useStores();

  const mutateDetail = modalProps?.materialTypeId
    ? swrStore.mutators[
        SWR_KEYS.getMaterialTypeById(
          Number(modalProps?.materialTypeId) as number,
        )
      ]
    : null;

  const mutateList =
    swrStore.mutators[SWR_KEYS.getMaterialTypeList()];

  const handleSubmit = (
    values: TForm,
    helpers: FormikHelpers<FormikValues>,
  ) => {
    helpers.setSubmitting(true);
    const promise = modalProps?.materialTypeId
      ? api.regulatoryInfo.patchMaterialType(
          modalProps?.materialTypeId,
          values,
        )
      : api.regulatoryInfo.postMaterialType(values);

    promise
      .then(() => {
        modalProps?.materialTypeId
          ? mutateDetail && mutateDetail()
          : null;
      })
      .catch((error: AxiosError) => {
        const errors = error.response?.data;
        if (errors) {
          helpers.setTouched(errors).then(() => {
            helpers.setErrors(errors);
          });
        } else {
          const errorMessage = errorToString(error);
          toastStore.createToast({
            type: TOAST_TYPES.ALERT,
            toastProps: {
              message: errorMessage,
              severity: "error",
            },
          });
        }
      })
      .finally(() => {
        helpers.setSubmitting(false);
        modalProps?.materialTypeId
          ? mutateDetail && mutateDetail()
          : null;
        mutateList && mutateList();
        handleClose();
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
      component={() => (
        <MaterialTypeForm
          modalProps={modalProps}
          handleClose={handleClose}
        />
      )}
    />
  );
};

export default MaterialType;
