import yup from "@/utils/yup";

import { IEntity, IFact } from "@/api/interfaces/responses";
import { TPlanFuct } from "@/interfaces";
import { IPlanForm } from "@/pages/AddPlan/const";
import { convertStringToDate } from "./utils";
import { isUndefined } from "@/utils/helpers";

export interface IProps {
  modalProps: {
    key: string;
    initialValue?: Partial<IPlanForm>;
    onSubmit?: (AddFact: IFact) => void;
    submitOnMount?: boolean;
  };
}

export enum FACT_FIELD_NAME {
  YEAR = "year", // Год факта
  BRANCH = "branch", // Филиал
  REGION = "region", // Регион
  PLAN = "plan", // План
  NAME = "name", // Название факта
}

// схема валидации факта
const nameMin = 1;
const nameMax = 255;
const textValidationMinMax = `Количество символов должно быть в диапазоне от ${nameMin} до ${nameMax}`;

export const validationSchema = yup.object().shape({
  [FACT_FIELD_NAME.NAME]: yup
    .string()
    .trim()
    .required()
    .min(nameMin, textValidationMinMax)
    .max(nameMax, textValidationMinMax),
  [FACT_FIELD_NAME.PLAN]: yup
    .mixed()
    .required("Поле план является обязательным для выбора"),
});

// поля необходимые для создания факта
export type TFactFields = Pick<IFact, "name">;

export interface TForm extends TFactFields {
  region?: IEntity;
  source_plan: number;
  type: TPlanFuct;
}

const convertDefaultValues = (item) => {
  if (!isUndefined(item))
    return {
      ...item,
      [FACT_FIELD_NAME.YEAR]: convertStringToDate(item?.year),
      [FACT_FIELD_NAME.REGION]: {
        ...item?.region,
        label: item?.region?.name,
      },
      [FACT_FIELD_NAME.BRANCH]: {
        ...item?.branch,
        label: item?.branch?.name,
      },
      [FACT_FIELD_NAME.PLAN]: {
        ...item?.plan,
        label: item?.plan?.name,
      },
    };
};

// установка начальных значений в форму
export const mapValueToForm = (value?: any) => {
  const convertInitValue = convertDefaultValues(value);
  return Object.assign(
    {
      [FACT_FIELD_NAME.YEAR]: null,
      [FACT_FIELD_NAME.BRANCH]: null,
      [FACT_FIELD_NAME.REGION]: null,
      [FACT_FIELD_NAME.PLAN]: null,
      [FACT_FIELD_NAME.NAME]: null,
    },
    convertInitValue
  );
};

// приведение данных формы к необходимым к отправке
export const mapFormToValue = (values): TForm => {
  const { plan, name } = values;

  return {
    name: name,
    source_plan: plan.id,
    type: "fact",
  };
};
