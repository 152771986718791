import React from "react";
import { Box, Divider, IconButton, Menu, Typography } from "@mui/material";
import { useStores } from "@/hooks";
import { VARIANT_MODAL } from "@/apps/Modals/const";

import { StyledMenuItem } from "@/components/StyledMenuItem";
import { DeleteIcon, EditIcon } from "@/components/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CELL_TYPES } from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { buttonDotsStyle } from "@/apps/Table/style";
import { MutatorCallback } from "swr/_internal";
import { IOBCultivar } from "@/api/interfaces/responses";
import { PERMISSION_CRUD, PERMISSIONS } from "@/premissions";

const EDITING_CELL = {
  editing: "Редактировать",
  delete: "Удалить",
};

interface IProps {
  planId: number;
  data: IOBCultivar;
  mutate: MutatorCallback;
}

export const CultureMenu: React.FC<IProps> = ({ planId, data, mutate }) => {
  const { api, modalStore, userStore } = useStores();
  const [isOpen, setIsOpen] = React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const handleDeleteCultivar = (resolve, reject) => {
    api.offBudget
      .deleteOffBudgetCultivars(data.id)
      .then(resolve)
      .catch(reject)
      .finally(onSubmitUpdate);
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const modalProp = {
      type: CELL_TYPES.cultivar,
      id: data.id,
      handleDelete: handleDeleteCultivar,
    };
    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
  };

  const onSubmitUpdate = () => {
    mutate();
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);

    modalStore.open(VARIANT_MODAL.ADD_OFFBUDGET_CULTIVAR, {
      cultivarId: data.id,
      planId: planId,
      onSubmit: onSubmitUpdate,
    });
  };

  const canEditCultivar = userStore.isPermission(
    PERMISSIONS.extrabudgetaryComCultivar,
    PERMISSION_CRUD.change
  )
  const canDeleteCultivar = userStore.isPermission(
    PERMISSIONS.extrabudgetaryComCultivar,
    PERMISSION_CRUD.delete
  )

  if(!canEditCultivar && !canDeleteCultivar) {
    return <></>
  }

  return (
    <Box>
      <IconButton sx={buttonDotsStyle()} onClick={handleToggle}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={isOpen} open={open} onClose={handleClose}>
        {canEditCultivar && 
        <StyledMenuItem onClick={handleClickEdit}>
          <EditIcon />

          {EDITING_CELL.editing}
        </StyledMenuItem>
        }
        {canEditCultivar && canDeleteCultivar && <Divider />}
        {canDeleteCultivar && 
        <StyledMenuItem onClick={handleClickDelete}>
          <DeleteIcon color="error" />

          <Typography color="error"> {EDITING_CELL.delete}</Typography>
        </StyledMenuItem>
        }
      </Menu>
    </Box>
  );
};
