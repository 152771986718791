import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const CopyIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_5885_239300)'>
          <mask
            id='mask0_5885_239300'
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width='24'
            height='24'
          >
            <rect width='24' height='24' fill='#D9D9D9' />
          </mask>
          <g mask='url(#mask0_5885_239300)'>
            <path
              d='M19 19H8C7.45 19 6.97917 18.8042 6.5875 18.4125C6.19583 18.0208 6 17.55 6 17V3C6 2.45 6.19583 1.97917 6.5875 1.5875C6.97917 1.19583 7.45 1 8 1H15L21 7V17C21 17.55 20.8042 18.0208 20.4125 18.4125C20.0208 18.8042 19.55 19 19 19ZM14 8V3H8V17H19V8H14ZM4 23C3.45 23 2.97917 22.8042 2.5875 22.4125C2.19583 22.0208 2 21.55 2 21V7H4V21H15V23H4Z'
              fill='#2F8DED'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_5885_239300'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default CopyIcon;
