import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Menu,
  menuClasses,
  Stack,
  Typography,
} from "@mui/material";
import {
  IPlanPlot,
  IVarietyPlanPlot
} from "@/api/interfaces/responses";
import { nameAuthorCellsStyle } from "../style";
import {
  StyledMenuItem
} from "@/components/StyledMenuItem";
import { buttonDotsStyle } from "@/apps/Table/style";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DeleteIcon } from "@/components/icons";
import {
  EDITING_CELL,
  VARIETY_ENTITY_NAME,
  VARIETY_TYPES,
  VARIETY_TYPES_BG_COLOR,
  VARIETY_TYPES_COLOR
} from "../const";
import {
  ColorChip
} from "@/components/Chips/ColorChip/ColorChip";
import theme from "@/theme";
import { useStores } from "@/hooks";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import { SWR_KEYS } from "@/const";
import { TOAST_TYPES } from "@/apps/Toast";
import { errorToString } from "@/utils/helpers";
import useSWR, { SWRResponse } from "swr";
import { IErrorResponse } from "@/interfaces";
import {
  useLocation,
  useSearchParams
} from "react-router-dom";
import { AxiosError } from "axios";
import {
  THEME_MODAL,
  VARIANT_MODAL
} from "@/apps/Modals/const";
import {
  CELL_TYPES
} from "@/apps/Modals/ModalContent/OkOrNotDelete/const";
import { ROUTES } from "@/apps/AppRouter/const";
import { PLANNING_STATUS_NAME } from "@/pages/Planning/const";

const StyledMenu = styled(Menu)(() => ({
  [`& .${menuClasses.list}`]: {
    width: "220px"
  }
}));

/**
 * @component TrialTypeCells
 * * компонент ячейки таблицы Типа Испытаний
 * @param
 */

export const VarietyTypeCells = (_: string, storage: IVarietyPlanPlot): JSX.Element => {
  const varietyType = storage.type;
  const varietyTypeText = varietyType ? VARIETY_TYPES[varietyType] : "";
  const trialTypeColors = varietyType
    ? VARIETY_TYPES_COLOR[varietyType]
    : "";
  const trialTypeBGColors = VARIETY_TYPES_BG_COLOR;

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
    >
      <ColorChip
        text={varietyTypeText}
        textColor={trialTypeColors}
        bgColor={trialTypeBGColors}
      ></ColorChip>
    </Stack>
  );
};

/**
 * @component CodeCells
 * * компонент ячейки таблицы код сорта
 * @param
 */

export const CodeCells = (_: string, { variety }: IVarietyPlanPlot
): JSX.Element => {
  return (
    <Box sx={nameAuthorCellsStyle()}>
      <Typography
        color={theme.palette.blackAndWhite.gray}
        variant="body2"
      >
        {variety.code}
      </Typography>
    </Box>
  );
};

/**
 * @component SortNameCells
 * * компонент ячейки таблицы дата создания заявки
 * @param
 */

export const SortNameCells = (_: string, storage: IVarietyPlanPlot): JSX.Element => {

  return (
    <Box>
      <Typography
        variant="body2">{storage.variety.name}</Typography>
    </Box>
  );
};

/**
 * @component CensusTakerCells
 * * компонент ячейки таблицы переписчик
 * @param
 */

export const CensusTakerCells = (_: string, storage: IVarietyPlanPlot): JSX.Element => {

  return (
    <Box>
      <Typography
        variant="body2">{storage.census_taker?.name ?? "-"}</Typography>
    </Box>
  );
};

/**
 * @component EditingCells
 * * компонент ячейки таблицы c кнопками действия для страницы реда
 * @param
 */
export const EditingCells = (_: string, value: IVarietyPlanPlot): JSX.Element => {

  const location = useLocation();

  const collectContent = () => {
    if (location.pathname.includes(ROUTES.editPlanningGroup)) {
      return EditingCellsFromAddPage(_, value);
    } else {
      return EditingCellsFromDetail(_, value);
    }
  };

  return (
    collectContent()
  );
};

/**
 * @component EditingCellsFromAddPage
 * * компонент ячейки таблицы c кнопками действия для страницы редактирования
 * @param
 */
export const EditingCellsFromAddPage = (_: string, value: IVarietyPlanPlot): JSX.Element => {
  const {
    planPlotStore,
    api,
    swrStore,
    toastStore
  } = useStores();
  const [groupId, setGroupId] = useState<number | undefined | string>('');
  const [isLoading, setIsLoading] = useState(false);

  const mutateGroup = (groupId: number | null) => {
    const mutate = swrStore.mutators[SWR_KEYS.getVarietyPlanPlotList(groupId)]
    mutate && mutate()
  }

  const results = planPlotStore.planPlotGroups;
  const grousID = results ? results?.map(res => res.id) : [];

  const [isOpen,
    setIsOpen
  ] = React.useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    !isLoading && setIsOpen(null);
  };

  const showToast = (error) => {
    toastStore.createToast({
      type: TOAST_TYPES.ALERT,
      toastProps: {
        message: errorToString(error),
        severity: "error"
      }
    });
  };

  const handleClickDelete = () => {
    setIsLoading(true);
    api.planning.deleteVarietyPlanPlot(value.id)
      .then(() => {
        mutateGroup(value.variety_plan_plot_group);
      })
      .catch(showToast)
      .finally(() => {
        setIsLoading(false);
        setIsOpen(null);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupId(Number(e.target.value));
  };

  const handleAccept = () => {
    if (groupId) {
      setIsLoading(true);
      api.planning.patchVarietyPlanPlot(value.id, {
        variety_plan_plot_group: groupId as number,
        variety: value.variety.id
      })
        .then(() => {
          mutateGroup(groupId as number);
          mutateGroup(value.variety_plan_plot_group);
        })
        .catch(showToast)
        .finally(() => {
          setIsLoading(false);
          setIsOpen(null);
        });
    }
  };

  return (
    <Box
      sx={{
        textAlign: "end"
      }}
    >
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>

      <StyledMenu
        anchorEl={isOpen}
        open={open}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={handleClickDelete}
          disabled={isLoading}
        >
          <DeleteIcon color="error" />

          <Typography color="error">
            {EDITING_CELL.delete}
          </Typography>
        </StyledMenuItem>
        <Divider />
        <Box display="flex" flexDirection="column" gap={1.2}
          padding="6px 16px">
          <FormControl size="small" variant="outlined"
            fullWidth>
            <InputLabel id="select-label">Переместить в
              группу</InputLabel>
            <Select
              value={groupId}
              label="Переместить в группу"
              onChange={handleChange}
              fullWidth
            >
              {
                grousID.map((id, index) => (
                  <MenuItem key={id} value={id}>
                    {`Группа ${index + 1}`}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <Box>
            <Button
              startIcon={<CheckIcon />}
              disabled={!groupId || isLoading}
              variant="contained"
              onClick={handleAccept}
            >
              Применить
            </Button>
          </Box>
        </Box>

      </StyledMenu>
    </Box>
  );
};

/**
 * @component EditingCellsFromDetail
 * * компонент ячейки таблицы c кнопками действия для таблицы в деталке
 * @param
 */
export const EditingCellsFromDetail = (_: string, storage: IVarietyPlanPlot): JSX.Element => {

  const [isOpen, setIsOpen] = useState<null | HTMLElement>(null);
  const open = Boolean(isOpen);
  const { api, modalStore, swrStore } = useStores();

  const [queryString] = useSearchParams();
  const planPlotId = Number(queryString.get("GSUDetail_id"));

  const {
    data,
  }: SWRResponse<IPlanPlot, IErrorResponse> = useSWR(
    planPlotId ? SWR_KEYS.getPlanningPlotById(planPlotId) : null,
  );

  const statusPlan = data?.plan.status

  // функция открытия/закрытия компонента дропдауна
  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(event.currentTarget);
  };

  // функция закрытия компонента дропдауна (для клика вне попапа)
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(null);
  };

  const varietyPlanPlotMutator = swrStore.mutators[SWR_KEYS.getVarietyPlanPlot(storage.variety_plan_plot_group)]
  const varietyPlanPlotNoGroupMutator = swrStore.mutators[SWR_KEYS.getVarietyPlanPlotNoGroup(planPlotId)]

  const handleDeletePlanning = (
    resolve: () => void,
    reject: (error: AxiosError) => void
  ) => {
    api.planning.deleteVarietyPlanPlotById(storage.id)
      .then(() => {
        resolve();
      })
      .catch(reject)
      .finally(() => {
        storage.variety_plan_plot_group ?
          varietyPlanPlotMutator() :
          varietyPlanPlotNoGroupMutator()
      });
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    handleClose(event);

    const modalProp = {
      theme: THEME_MODAL.W_555,
      type: CELL_TYPES.universal,
      id: storage.id,
      entityName: VARIETY_ENTITY_NAME,
      handleDelete: handleDeletePlanning,
    };

    modalStore.open(VARIANT_MODAL.OK_OR_NOT_DELETE, modalProp);
  };

  if (statusPlan === PLANNING_STATUS_NAME.approved)
    return (
      <></>
    )

  return (
    <Box
      sx={{
        textAlign: "end"
      }}
    >
      <IconButton
        sx={buttonDotsStyle()}
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={isOpen}
        open={open}
        onClose={handleClose}
      >
        <StyledMenuItem
          onClick={handleClickDelete}
        >
          <DeleteIcon color={"error"} />
          <Typography color="error">
            {EDITING_CELL.delete}
          </Typography>
        </StyledMenuItem>
      </Menu>
      </Box>
  );
};

