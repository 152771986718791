import {
  Box,
  Typography,
  Divider,
  Grid,
  FormControl,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Form, useFormikContext } from "formik";
import React, { Fragment } from "react";
import { FIELD_NAME } from "../utils/const";
import { IInitialValues } from "../utils/types";
import dayjs from "dayjs";
import { useStores } from "@/hooks";
import FastRegexTextField from "@/components/FastRegexTextField";

interface IProps {
  modalProps: any;
}
export const AddEffectivityForm: React.FC<IProps> = ({
  modalProps,
}) => {
  const { modalStore } = useStores();
  const formik = useFormikContext<IInitialValues>();
  const { title = "Добавить показатели эффективности" } =
    modalProps || {};

  const handleCancel = () => {
    modalStore.close();
  };

  const addYearField = (
    selectedYear: Record<string, any>,
  ) => {
    formik.setFieldValue(FIELD_NAME.YEAR, selectedYear.$y);
  };

  const serializeYear = (date: number) => {
    return date ? dayjs().year(date) : null;
  };

  return (
    <Form onSubmit={formik.handleSubmit} noValidate>
      <Box>
        <Box p={3}>
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Divider />

        <Grid container mt={1} spacing={1} p={3}>
          <Grid item xs={12}>
            <DatePicker
              name={FIELD_NAME.YEAR}
              onChange={addYearField}
              label="Год"
              openTo="year"
              value={serializeYear(
                formik.values[FIELD_NAME.YEAR],
              )}
              views={["year"]}
              slotProps={{
                textField: {
                  size: "medium",
                  fullWidth: true,
                  error:
                    formik.touched[FIELD_NAME.YEAR] &&
                    Boolean(formik.errors[FIELD_NAME.YEAR]),
                  helperText:
                    formik.touched[FIELD_NAME.YEAR] &&
                    formik.errors[FIELD_NAME.YEAR],
                },
              }}
            />
            <Typography mt={3} mb={3} variant="h3">
              Показатели
            </Typography>
          </Grid>

          <Box
            style={{
              border: "1px solid #C5C7CA",
              padding: "16px",
              marginBottom: "16px",
              width: "100%",
              display: "inline-flex",
              borderRadius: "4px",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Fragment>
              <Grid item xs={6}>
                <Typography variant="h4">
                  Выручка от реализации от продукции
                  растениеводства
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <FastRegexTextField
                    name={FIELD_NAME.REVENUE}
                    label="Сумма, руб"
                    regexType="decimal"
                  />
                </FormControl>
              </Grid>
            </Fragment>
          </Box>

          <Box
            style={{
              border: "1px solid #C5C7CA",
              padding: "16px",
              marginBottom: "16px",
              width: "100%",
              display: "inline-flex",
              borderRadius: "4px",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Fragment>
              <Grid item xs={6}>
                <Typography variant="h4">
                  Размер прямых затрат
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <FastRegexTextField
                    name={FIELD_NAME.DIRECT_EXPENSES}
                    label="Сумма, руб"
                    regexType="decimal"
                  />
                </FormControl>
              </Grid>
            </Fragment>
          </Box>
          <Box
            style={{
              border: "1px solid #C5C7CA",
              padding: "16px",
              marginBottom: "16px",
              width: "100%",
              display: "inline-flex",
              borderRadius: "4px",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Fragment>
              <Grid item xs={6}>
                <Typography variant="h4">
                  Производственные расходы
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <FastRegexTextField
                    name={FIELD_NAME.PRODUCTION_EXPENSES}
                    label="Сумма, руб"
                    regexType="decimal"
                  />
                </FormControl>
              </Grid>
            </Fragment>
          </Box>
          <Box
            style={{
              border: "1px solid #C5C7CA",
              padding: "16px",
              marginBottom: "16px",
              width: "100%",
              display: "inline-flex",
              borderRadius: "4px",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Fragment>
              <Grid item xs={6}>
                <Typography variant="h4">
                  Административные расходы
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <FastRegexTextField
                    name={
                      FIELD_NAME.ADMINISTRATIVE_EXPENSES
                    }
                    label="Сумма, руб"
                    regexType="decimal"
                  />
                </FormControl>
              </Grid>
            </Fragment>
          </Box>
          <Box
            style={{
              border: "1px solid #C5C7CA",
              padding: "16px",
              marginBottom: "16px",
              width: "100%",
              display: "inline-flex",
              borderRadius: "4px",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Fragment>
              <Grid item xs={6}>
                <Typography variant="h4">Налоги</Typography>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <FastRegexTextField
                    name={FIELD_NAME.TAXES}
                    label="Сумма, руб"
                    regexType="decimal"
                  />
                </FormControl>
              </Grid>
            </Fragment>
          </Box>
          <Box
            style={{
              border: "1px solid #C5C7CA",
              padding: "16px",
              marginBottom: "16px",
              width: "100%",
              display: "inline-flex",
              borderRadius: "4px",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Fragment>
              <Grid item xs={6}>
                <Typography variant="h4">
                  Величина капитальных вложений
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <FastRegexTextField
                    name={FIELD_NAME.CAPITAL_INVESTMENTS}
                    label="Сумма, руб"
                    regexType="decimal"
                  />
                </FormControl>
              </Grid>
            </Fragment>
          </Box>
        </Grid>

        <Divider />
        <Box
          p={3}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            color="red"
            onClick={handleCancel}
          >
            Отмена
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Form>
  );
};
