import dayjs, { Dayjs } from "dayjs";

/**
 * Утилитка которая преобразует дату из культуры (берет день и месяц), добавляет к текущему году 1, создает новую дату, для разнарядки
 * @param dateCulture Дата из культуры (корректными считать только месяц и день)
 * @returns
 */
export const formatDateForDistribution = (
  dateCulture: string,
): Dayjs | undefined => {
  const date = dayjs(dateCulture);
  if (!date.isValid()) return undefined;
  const month = date.month() + 1; // Месяцы в dayjs начинаются с 0, поэтому добавляем 1
  const day = date.date();
  const currentYear = dayjs().year();

  const newDate = dayjs(
    `${day}.${month}.${currentYear}`,
    "DD.M.YYYY",
  );
  return newDate;
};

/**
 * Утилитка которая преобразует строку с датой в объект для формы
 * @param date Дата из культуры
 * @returns
 */
export const convertStringToDate = (
  dateString?: string,
): Dayjs | undefined => {
  const date = dayjs(dateString);
  if (!date.isValid()) return undefined;

  return date;
};

/**
 * Утилитка которая возвращает строку из Dayjs
 * @param date объект Dayjs
 * @returns
 */
export const convertDateForForm = (
  date: Dayjs,
): string | undefined => {
  if (!date.isValid()) return undefined;
  return date.format("YYYY-MM-DD");
};
