import {
  FormControl,
  FormHelperText,
  TextField,
  Stack,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import React, { FC, Fragment } from "react";
import { FIELDS_SET } from "../utils";
import Autocomplete from "@/components/Autocomplete";

interface IProps {
  dataRegions: any;
  handleScrollRegions: (event) => void;
  setRegionSearch: (event) => void;
}

export const RussianAddressForm: FC<IProps> = ({
  dataRegions,
  handleScrollRegions,
  setRegionSearch,
}) => {
  const form = useFormikContext<any>();

  const handleChangeRegion = (event, child) => {
    form.setFieldValue(FIELDS_SET.district, child);
  };

  return (
    <Fragment>
      <Field name={FIELDS_SET.district}>
        {({ field, meta }) => (
          <FormControl fullWidth required>
            <Autocomplete
              {...field}
              onChange={handleChangeRegion}
              label="Регион РФ"
              required
              data={dataRegions}
              handleScroll={handleScrollRegions}
              search={setRegionSearch}
              error={meta.touched && !!meta.error}
            />

            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={FIELDS_SET.locality_name}>
        {({ field, meta }) => (
          <FormControl fullWidth>
            <TextField
              {...field}
              label={"Населенный пункт"}
              fullWidth
              size="small"
              required={true}
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={form.values[FIELDS_SET.locality_name]}
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={FIELDS_SET.area_name}>
        {({ field, meta }) => (
          <FormControl fullWidth>
            <TextField
              {...field}
              label={"Район (Городской округ)"}
              fullWidth
              size="small"
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={form.values[FIELDS_SET.area_name]}
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={FIELDS_SET.street_name}>
        {({ field, meta }) => (
          <FormControl fullWidth>
            <TextField
              {...field}
              label={"Улица"}
              fullWidth
              size="small"
              required={true}
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={form.values[FIELDS_SET.street_name]}
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Field name={FIELDS_SET.index}>
        {({ field, meta }) => (
          <FormControl fullWidth>
            <TextField
              {...field}
              label={"Индекс"}
              fullWidth
              size="small"
              required={true}
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={form.values[FIELDS_SET.index]}
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
      <Stack
        gap={2}
        direction={"row"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
      >
        <Field name={FIELDS_SET.house}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                label={"Дом"}
                fullWidth
                size="small"
                required={true}
                onChange={form.handleChange}
                onBlur={field.handleBlur}
                value={form.values[FIELDS_SET.house]}
                disabled={meta.isSubmitting}
                sx={{
                  width: "155px",
                }}
              />
              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field name={FIELDS_SET.building}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                label={"Корпус"}
                fullWidth
                size="small"
                onChange={form.handleChange}
                onBlur={field.handleBlur}
                value={form.values[FIELDS_SET.building]}
                disabled={meta.isSubmitting}
                sx={{
                  width: "155px",
                }}
              />
              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field name={FIELDS_SET.housing}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                label={"Строение"}
                fullWidth
                size="small"
                onChange={form.handleChange}
                onBlur={field.handleBlur}
                value={form.values[FIELDS_SET.housing]}
                disabled={meta.isSubmitting}
                sx={{
                  width: "155px",
                }}
              />
              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
      </Stack>
      <Stack
        gap={2}
        direction={"row"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
      >
        <Field name={FIELDS_SET.flat}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                label={"Квартира/ офис"}
                fullWidth
                size="small"
                onChange={form.handleChange}
                onBlur={field.handleBlur}
                value={form.values[FIELDS_SET.flat]}
                disabled={meta.isSubmitting}
                sx={{
                  width: "245px",
                }}
              />
              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
        <Field name={FIELDS_SET.entrance}>
          {({ field, meta }) => (
            <FormControl>
              <TextField
                {...field}
                label={"Подъезд"}
                fullWidth
                size="small"
                onChange={form.handleChange}
                onBlur={field.handleBlur}
                value={form.values[FIELDS_SET.entrance]}
                disabled={meta.isSubmitting}
                sx={{
                  width: "245px",
                }}
              />
              <FormHelperText
                error={meta.touched && !!meta.error}
              >
                {meta.touched && meta.error}
              </FormHelperText>
            </FormControl>
          )}
        </Field>
      </Stack>
      <Field name={FIELDS_SET.post_office_box}>
        {({ field, meta }) => (
          <FormControl fullWidth>
            <TextField
              {...field}
              label={"Абонентский ящик"}
              fullWidth
              size="small"
              onChange={form.handleChange}
              onBlur={field.handleBlur}
              value={
                form.values[FIELDS_SET.post_office_box]
              }
              disabled={meta.isSubmitting}
            />
            <FormHelperText
              error={meta.touched && !!meta.error}
            >
              {meta.touched && meta.error}
            </FormHelperText>
          </FormControl>
        )}
      </Field>
    </Fragment>
  );
};
