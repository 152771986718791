import React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  Typography
} from "@mui/material";
import { flattenData } from "@/utils/helpers";
/**
 * Interface for the Error component's props.
 */
interface IError {
  handleClose: () => void;
  modalProps: { err: any };
}

/**
 * Error component .
 * @param {IError} props - Component props.
 * @returns {React.FC<IError>} - Rendered component.
 */
const Error: React.FC<IError> = ({ handleClose, modalProps }) => {
  if (modalProps.err) {
    const { err } = modalProps;

    const data = flattenData(err);

    return (
      <Box>
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          p={"24px"}
          pb={0}
          pt={"70px"}
        >
          <Alert severity="error">
            <AlertTitle>Что-то пошло не так!</AlertTitle>
            {data.map((el) => {
              return (
                <Stack direction={"row"}>
                  <Typography>{el.value}</Typography>
                </Stack>
              );
            })}
          </Alert>
        </Box>
        <Box alignItems={"center"} p={3} display="flex" justifyContent="center">
          <Button
            onClick={handleClose}
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
          >
            Продолжить
          </Button>
        </Box>
      </Box>
    );
  }
  return null;
};

export default Error;
